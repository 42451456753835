import { ORG_PAYMENT_TIER_DISPLAY_NAMES, OrgPaymentTier } from '@kindo/universal';
import { useState } from 'react';
import { tv } from 'tailwind-variants';
import { PAYMENT_TIER_COSTS, PAYMENT_TIER_FEATURES } from '../PaymentTiers.consts';
import { SelectPaymentTierButton } from '../SelectPaymentTierButton';
import { TextIconColor, Typography } from '~/components/core';
import { TypographySize, TypographyWeight } from '~/components/core/Typography';
import { cn } from '~/utils/tailwind.utils';
export const paymentTierCardStyles = tv({
  slots: {
    container: 'bg-primary-panel relative flex flex-1 flex-col justify-between gap-4 rounded-xl p-6 shadow-md',
    header: 'border-b-1 border-gray pb-2',
    headerAndFeatures: 'flex flex-col gap-4',
    featuresList: 'flex flex-col',
    featuresListItem: 'text-primary-highlight relative mb-1 pl-3 before:absolute before:left-0 before:top-2 before:h-1 before:w-1 before:rounded-full before:bg-current before:content-[""]',
    footer: 'flex flex-row justify-between gap-6 pt-12',
    cardBorder: 'to-primary-highlight from-primary-bright absolute left-[5%] top-0 flex w-[90%] bg-gradient-to-r p-1'
  },
  variants: {
    isHovering: {
      true: {
        cardBorder: 'opacity-75'
      }
    }
  }
});
interface PaymentTierProps {
  activeTier: OrgPaymentTier;
  onSelect: () => void;
  tier: OrgPaymentTier;
  userCanManageOrganization: boolean | undefined;
}
const PaymentTierCard: React.FC<PaymentTierProps> = ({
  activeTier,
  tier,
  onSelect,
  userCanManageOrganization
}) => {
  // state
  const [isHovering, setIsHovering] = useState(false);
  const cost = PAYMENT_TIER_COSTS[tier];
  const tierFeatures = PAYMENT_TIER_FEATURES[tier];
  const {
    container,
    header,
    headerAndFeatures,
    featuresList,
    featuresListItem,
    footer,
    cardBorder
  } = paymentTierCardStyles();
  return <div className={container()} data-testid={`payment-tier-card-${tier}`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <div className={headerAndFeatures()}>
        <div className={header()}>
          <div className={cn(cardBorder(), isHovering && 'opacity-75')} />
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3} weight={TypographyWeight.MEDIUM}>
            {ORG_PAYMENT_TIER_DISPLAY_NAMES[tier]}
          </Typography>
        </div>
        <ul className={featuresList()} data-testid={`features-list-${tier}`}>
          {tierFeatures.map(feature => <li className={featuresListItem()} data-testid={`feature-${feature.replace(/\s+/g, '-').toLowerCase()}`} key={feature}>
              <Typography key={feature} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                {feature}
              </Typography>
            </li>)}
        </ul>
      </div>
      <div className={footer()}>
        {cost !== null ? <Typography size={TypographySize.H3} weight={TypographyWeight.MEDIUM}>
            {`$${cost}/mo`}
          </Typography> : <div />}
        {userCanManageOrganization && <SelectPaymentTierButton activeTier={activeTier} onSelect={onSelect} tier={tier} />}
      </div>
    </div>;
};
export default PaymentTierCard;