export enum CerbosResource {
  API_KEY = 'api_key',
  AUDIT_LOG = 'audit_log',
  CHAT = 'chat',
  CONTENT = 'content',
  FILE = 'file',
  MODEL = 'model',
  ORG = 'org',
  ORG_INVITE = 'org_invite',
  SECRET = 'secret',
  USER = 'user',
  USER_GROUP = 'user_group',
  WORKFLOW = 'workflow'
}

export enum CerbosRole {
  'USER' = 'user'
}

// Actions
export enum CerbosApiKeyAction {
  MANAGE_API_KEY = 'MANAGE_API_KEY'
}

export enum CerbosAuditLogAction {
  LIST = 'LIST'
}

export enum CerbosChatAction {
  READ_CHAT = 'READ_CHAT',
  SHARE_CHAT_WITH_ORG = 'SHARE_CHAT_WITH_ORG',
  SHARE_CHAT_WITH_USER_GROUP = 'SHARE_CHAT_WITH_USER_GROUP',
  WRITE_CHAT = 'WRITE_CHAT'
}

export enum CerbosContentAction {
  DELETE_CONTENT = 'DELETE_CONTENT',
  GET_CONTENT = 'GET_CONTENT'
}

export enum CerbosFileAction {
  DELETE_FILE = 'DELETE_FILE',
  GET_FILE = 'GET_FILE'
}

export enum CerbosWorkflowAction {
  DELETE_WORKFLOW = 'DELETE_WORKFLOW',
  EDIT_WORKFLOW = 'EDIT_WORKFLOW',
  EDIT_WORKFLOW_AS_CREATOR = 'EDIT_WORKFLOW_AS_CREATOR',
  SHARE_WORKFLOW_WITH_ORG = 'SHARE_WORKFLOW_WITH_ORG',
  SHARE_WORKFLOW_WITH_PUBLIC = 'SHARE_WORKFLOW_WITH_PUBLIC',
  SHARE_WORKFLOW_WITH_USER = 'SHARE_WORKFLOW_WITH_USER',
  SHARE_WORKFLOW_WITH_USER_GROUP = 'SHARE_WORKFLOW_WITH_USER_GROUP',
  VIEW_WORKFLOW_WITH_STATIC_CONTENT = 'VIEW_WORKFLOW_WITH_STATIC_CONTENT'
}

export enum CerbosOrgAction {
  MANAGE_ORG = 'MANAGE_ORG',
  VIEW_ORG = 'VIEW_ORG'
}

export enum CerbosOrgInviteAction {
  DELETE_INVITE = 'DELETE_INVITE',
  UPDATE_INVITE = 'UPDATE_INVITE'
}

export enum CerbosUserGroupAction {
  MANAGE_USER_GROUP = 'MANAGE_USER_GROUP',
  MANAGE_USER_GROUP_USERS = 'MANAGE_USER_GROUP_USERS',
  SHARE_CHAT_WITH_USER_GROUP = 'SHARE_CHAT_WITH_USER_GROUP',
  SHARE_WORKFLOW_WITH_USER_GROUP = 'SHARE_WORKFLOW_WITH_USER_GROUP',
  VIEW_USER_GROUP = 'VIEW_USER_GROUP'
}

export enum CerbosSecretAction {
  MANAGE_SECRET = 'MANAGE_SECRET'
}

export enum CerbosModelAction {
  MANAGE_MODEL = 'MANAGE_MODEL',
  VIEW_MODEL = 'VIEW_MODEL'
}

export const CERBOS_WORKFLOW_SHARE_ACTIONS = [
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_ORG,
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_PUBLIC,
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_USER,
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_USER_GROUP
] as const satisfies CerbosWorkflowAction[];

export type CerbosWorkflowShareAction = {
  [K in (typeof CERBOS_WORKFLOW_SHARE_ACTIONS)[number]]: boolean;
};
