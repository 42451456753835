import { AuditEventType } from '@kindo/universal';

// ALL the constants defined here are temporary.
// These are implemented for milestone 1&2 only.

export const AUDIT_LOG_EVENT_TYPE_DESCRIPTIONS: Record<AuditEventType, string> = {
  [AuditEventType.API_REQUEST_EXECUTED]: 'executed API request',
  [AuditEventType.API_STEP_GENERATION]: 'generated API step',
  [AuditEventType.AUDIO_TRANSCRIPTION_REQUEST]: 'made audio transcription request',
  [AuditEventType.CHAT_CREATED]: 'created chat',
  [AuditEventType.CHAT_MESSAGE_SENT]: 'sent chat message',
  [AuditEventType.CHAT_ORG_ACCESS_UPDATED]: "updated chat's organization role",
  [AuditEventType.CHAT_SHARED_TO_USER_GROUP]: 'updated chat user group role',
  [AuditEventType.CHAT_UNSHARED_FROM_USER_GROUP]: 'removed chat user group role',
  [AuditEventType.CODE_EXECUTED]: 'executed code',
  [AuditEventType.COPILOT_CHAT_MESSAGE]: 'sent copilot chat message',
  [AuditEventType.COPILOT_CODE_COMPLETION]: 'used copilot code completion',
  [AuditEventType.DLP_FILTER_APPLIED]: 'DLP filter was applied',
  [AuditEventType.EMBEDDING_REQUEST]: 'made embedding request',
  [AuditEventType.FILE_ADDED]: 'File was added',
  [AuditEventType.INTEGRATION_ACTION_FAILED]: "'s integration action failed",
  [AuditEventType.INTEGRATION_ACTION_SUCCEEDED]: "'s integration action succeeded",
  [AuditEventType.KINDO_PUBLIC_API_REQUEST_MADE]: 'Kindo public API request was made',
  [AuditEventType.LLM_REQUEST_SUCCEEDED]: 'LLM request succeeded',
  [AuditEventType.LLM_REQUEST_FAILED]: 'LLM request failed',
  [AuditEventType.MODEL_ADDED]: 'added a model',
  [AuditEventType.MODEL_DELETED]: 'deleted a model',
  [AuditEventType.ORG_INVITE_DELETED]: 'deleted invite to organization',
  [AuditEventType.ORG_INVITE_ACCEPTED]: 'accepted invite to organization',
  [AuditEventType.ORG_INVITE_SENT]: 'sent invite to organization',
  [AuditEventType.ORG_INVITE_UPDATED]: 'updated pending invite to organization',
  [AuditEventType.ORG_MEMBER_ADDED]: 'added a member',
  [AuditEventType.ORG_MEMBER_REMOVED]: 'removed a member',
  [AuditEventType.ORG_MEMBER_ROLE_CHANGED]: "changed a member's role",
  [AuditEventType.ORG_NAME_CHANGED]: "changed organization's name",
  [AuditEventType.ORG_SETTINGS_UPDATED]: "updated organization's settings",
  [AuditEventType.USER_API_KEY_CREATED]: 'created API key',
  [AuditEventType.USER_API_KEY_DISABLED]: 'disabled API key',
  [AuditEventType.USER_API_KEY_ENABLED]: 'enabled API key',
  [AuditEventType.USER_API_KEY_REMOVED]: 'deleted API key',
  [AuditEventType.USER_SETTING_CHANGED]: 'changed user setting',
  [AuditEventType.USER_GROUP_CREATED]: 'created user group',
  [AuditEventType.USER_GROUP_DELETED]: 'deleted user group',
  [AuditEventType.USER_GROUP_USER_ADDED]: 'added user to user group',
  [AuditEventType.USER_GROUP_USER_REMOVED]: 'removed user from user group',
  [AuditEventType.USER_GROUP_UPDATED]: 'updated user group',
  [AuditEventType.WORKFLOW_TRIGGERED]: "'s agent was triggered",
  [AuditEventType.WORKFLOW_CREATED]: 'created an agent',
  [AuditEventType.WORKFLOW_DUPLICATED]: 'duplicated an agent',
  [AuditEventType.WORKFLOW_DELETED]: 'deleted an agent',
  [AuditEventType.WORKFLOW_SHARED_TO_USER]: 'shared an agent',
  [AuditEventType.WORKFLOW_UNSHARED_FROM_USER]: 'unshared an agent',
  [AuditEventType.WORKFLOW_SHARED_TO_USER_GROUP]: 'updated agent user group role',
  [AuditEventType.WORKFLOW_UNSHARED_FROM_USER_GROUP]: 'removed agent user group role',
  [AuditEventType.WORKFLOW_UPDATED]: 'updated an agent',
  [AuditEventType.WORKFLOW_ORG_ROLE_UPDATED]: 'updated an agent org sharing',
  [AuditEventType.WORKFLOW_PUBLIC_ROLE_UPDATED]: 'updated an agent public sharing',
  [AuditEventType.SECURITY_CONFIG_UPDATED]: 'updated security configuration',
  [AuditEventType.SECURITY_CUSTOM_DLP_CREATED]: 'created custom DLP filter',
  [AuditEventType.SECURITY_CUSTOM_DLP_DELETED]: 'deleted custom DLP filter',
  [AuditEventType.SECRET_CREATED]: 'created secret',
  [AuditEventType.SECRET_DELETED]: 'deleted secret',
  [AuditEventType.SECRET_UPDATED]: 'updated secret'
};
export const AUDIT_LOG_EVENT_TYPE_DISPLAY_NAMES: Record<AuditEventType, string> = {
  [AuditEventType.API_REQUEST_EXECUTED]: 'API Request Executed',
  [AuditEventType.API_STEP_GENERATION]: 'API Step Generation',
  [AuditEventType.AUDIO_TRANSCRIPTION_REQUEST]: 'Audio Transcription Request',
  [AuditEventType.CHAT_CREATED]: 'Chat Created',
  [AuditEventType.CHAT_MESSAGE_SENT]: 'Chat Message',
  [AuditEventType.CHAT_ORG_ACCESS_UPDATED]: 'Chat Org Share',
  [AuditEventType.CHAT_SHARED_TO_USER_GROUP]: 'Chat User Group Share',
  [AuditEventType.CHAT_UNSHARED_FROM_USER_GROUP]: 'Chat User Group Unshare',
  [AuditEventType.CODE_EXECUTED]: 'Code Execution',
  [AuditEventType.COPILOT_CHAT_MESSAGE]: 'Copilot Chat Message',
  [AuditEventType.COPILOT_CODE_COMPLETION]: 'Copilot Code Completion',
  [AuditEventType.DLP_FILTER_APPLIED]: 'DLP Filter Applied',
  [AuditEventType.EMBEDDING_REQUEST]: 'Embedding Request',
  [AuditEventType.FILE_ADDED]: 'File Upload',
  [AuditEventType.KINDO_PUBLIC_API_REQUEST_MADE]: 'Kindo Public API Request',
  [AuditEventType.INTEGRATION_ACTION_FAILED]: 'Integration Action (Failure)',
  [AuditEventType.INTEGRATION_ACTION_SUCCEEDED]: 'Integration Action (Success)',
  [AuditEventType.LLM_REQUEST_SUCCEEDED]: 'LLM Request (Success)',
  [AuditEventType.LLM_REQUEST_FAILED]: 'LLM Request (Failure)',
  [AuditEventType.MODEL_ADDED]: 'Model Added',
  [AuditEventType.MODEL_DELETED]: 'Model Deleted',
  [AuditEventType.ORG_INVITE_DELETED]: 'Org Invite Deleted',
  [AuditEventType.ORG_INVITE_ACCEPTED]: 'Org Invite Accepted',
  [AuditEventType.ORG_INVITE_SENT]: 'Org Invite Sent',
  [AuditEventType.ORG_INVITE_UPDATED]: 'Org Invite Updated',
  [AuditEventType.ORG_MEMBER_ADDED]: 'Org Member Added',
  [AuditEventType.ORG_MEMBER_REMOVED]: 'Org Member Removed',
  [AuditEventType.ORG_MEMBER_ROLE_CHANGED]: 'Org Member Role Change',
  [AuditEventType.ORG_NAME_CHANGED]: 'Org Name Change',
  [AuditEventType.ORG_SETTINGS_UPDATED]: 'Org Settings Updated',
  [AuditEventType.USER_API_KEY_CREATED]: 'API Key Created',
  [AuditEventType.USER_API_KEY_DISABLED]: 'API Key Disabled',
  [AuditEventType.USER_API_KEY_ENABLED]: 'API Key Enabled',
  [AuditEventType.USER_API_KEY_REMOVED]: 'API Key Deleted',
  [AuditEventType.USER_SETTING_CHANGED]: 'User Setting Change',
  [AuditEventType.USER_GROUP_CREATED]: 'User Group Created',
  [AuditEventType.USER_GROUP_DELETED]: 'User Group Deleted',
  [AuditEventType.USER_GROUP_USER_ADDED]: 'User Group User Added',
  [AuditEventType.USER_GROUP_USER_REMOVED]: 'User Group User Removed',
  [AuditEventType.USER_GROUP_UPDATED]: 'User Group Updated',
  [AuditEventType.WORKFLOW_TRIGGERED]: 'Agent Triggered',
  [AuditEventType.WORKFLOW_CREATED]: 'Agent Created',
  [AuditEventType.WORKFLOW_DUPLICATED]: 'Agent Duplicated',
  [AuditEventType.WORKFLOW_DELETED]: 'Agent Deleted',
  [AuditEventType.WORKFLOW_SHARED_TO_USER]: 'Agent User Added',
  [AuditEventType.WORKFLOW_UNSHARED_FROM_USER]: 'Agent User Removed',
  [AuditEventType.WORKFLOW_SHARED_TO_USER_GROUP]: 'Agent User Group Role Added',
  [AuditEventType.WORKFLOW_UNSHARED_FROM_USER_GROUP]: 'Agent User Group Role Removed',
  [AuditEventType.WORKFLOW_UPDATED]: 'Agent Update',
  [AuditEventType.WORKFLOW_ORG_ROLE_UPDATED]: 'Agent Org Share',
  [AuditEventType.WORKFLOW_PUBLIC_ROLE_UPDATED]: 'Agent Public Share',
  [AuditEventType.SECURITY_CONFIG_UPDATED]: 'Security configuration updated',
  [AuditEventType.SECURITY_CUSTOM_DLP_CREATED]: 'Custom DLP filter created',
  [AuditEventType.SECURITY_CUSTOM_DLP_DELETED]: 'Custom DLP filter deleted',
  [AuditEventType.SECRET_CREATED]: 'Secret Created',
  [AuditEventType.SECRET_DELETED]: 'Secret Deleted',
  [AuditEventType.SECRET_UPDATED]: 'Secret Updated'
};