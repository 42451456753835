import _styled from "styled-components";
import { GiantButtonSize, GiantButtonType } from './GiantButton.consts';
import { StyledGiantButton } from './GiantButton.styles';
import { TextIconColor, Icon, Icons, ToolTip, Typography, TypographyAlignment, TypographySize, TypographyCasing, TypographyFont, Size, LoadingSpinner, LoadingSpinnerSize } from '~/components/core';
const StartIconAndTextContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem"
});
const IconContainer = _styled.div({
  "marginLeft": "0.25rem"
});
interface GiantButtonProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: GiantButtonType;
  disabled?: boolean;
  endIcon?: Icon | undefined;
  loading?: boolean;
  size?: GiantButtonSize;
  startIcon?: Icon | undefined;
  tooltip?: string;
}
const GiantButton: React.FC<GiantButtonProps> = ({
  onClick,
  label,
  type,
  disabled = false,
  tooltip,
  endIcon,
  startIcon,
  loading = false,
  size = GiantButtonSize.DEFAULT
}) => <ToolTip content={tooltip ?? ''} offset={[0, 8]}>
    {/* Wrap tooltip in span so it works while button is disabled */}
    <span>
      <StyledGiantButton $size={size} $type={type} disabled={disabled || loading} onClick={disabled || loading ? undefined : onClick}>
        {!loading && <>
            <StartIconAndTextContainer>
              {startIcon && <IconContainer>
                  <Icons color={TextIconColor.PRIMARY} icon={startIcon} size={Size.SMALL} />
                </IconContainer>}
              <Typography alignment={TypographyAlignment.CENTER} casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
                {label}
              </Typography>
            </StartIconAndTextContainer>
            {endIcon && <IconContainer>
                <Icons color={TextIconColor.PRIMARY} icon={endIcon} />
              </IconContainer>}
          </>}
        {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
      </StyledGiantButton>
    </span>
  </ToolTip>;
export default GiantButton;