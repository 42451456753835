import { css as _css } from "styled-components";
import _styled from "styled-components";
import { RuleSet } from 'styled-components';
import { Size } from '../constants';
import { HUGE_ICON_SVGS, HugeIcon } from './HugeIcon.consts';
export type HugeIconSize = Size.MEDIUM | Size.LARGE;
export interface HugeIconProps {
  icon: HugeIcon;
  gridBackground?: boolean;
  invert?: boolean;
  size?: HugeIconSize;
}
const hugeIconSize: Record<HugeIconSize, RuleSet<Object>> = {
  [Size.MEDIUM]: _css({
    svg: {
      "height": "4rem",
      "width": "4rem"
    } // 5x larger than Icon's h-5 w-5
  }),
  [Size.LARGE]: _css({
    svg: {
      "height": "5rem",
      "width": "5rem"
    }
  })
};
export const getHugeIconSize = (size: HugeIconSize | number) => {
  if (typeof size === 'number') {
    return _css({
      svg: {
        height: `${size * 5}px`,
        // 5x larger than Icon
        width: `${size * 5}px`
      }
    });
  }
  return hugeIconSize[size];
};
export const getHugeIconBackground = (size: HugeIconSize) => {
  if (size === Size.LARGE) {
    return _css`
      background-position: -12px -12px;
    `;
  }
  return _css`
    background-position: -6px -6px;
  `;
};
export const HugeIconContainer = _styled.span<{
  $gridBackground: boolean;
  $invert: boolean;
  $size: HugeIconSize;
}>(({
  $size,
  $invert,
  $gridBackground
}) => [{
  "position": "relative"
}, getHugeIconSize($size), $invert && {
  "--tw-rotate": "180deg",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}, $gridBackground && [{
  "padding": "1rem"
}, _css`
      background-image: linear-gradient(
          90deg,
          rgb(26 26 26) 1px,
          transparent 1px
        ),
        linear-gradient(180deg, rgb(26 26 26) 1px, transparent 1px);
      background-size: 9px 9px;
      ${getHugeIconBackground($size)}
    `]]);
const HugeIcons: React.FC<HugeIconProps> = ({
  icon,
  size = Size.MEDIUM,
  invert = false,
  gridBackground = true
}) => {
  const IconSVG = HUGE_ICON_SVGS[icon];
  return <HugeIconContainer $gridBackground={gridBackground} $invert={invert} $size={size}>
      <IconSVG />
    </HugeIconContainer>;
};
export default HugeIcons;