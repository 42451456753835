import { WORKFLOWS_FACTORY_ROUTE, WORKFLOWS_LIBRARY_ROUTE } from '~/constants';
export enum WorkflowNavTab {
  CREATE = 'FACTORY',
  LIBRARY = 'LIBRARY',
  // TODO: [ENG-4792] awaiting input on implementation
  // SHOWCASE = 'SHOWCASE'
}
export const WORKFLOWS_NAV_TAB_ROUTES: Record<WorkflowNavTab, string> = {
  [WorkflowNavTab.CREATE]: WORKFLOWS_FACTORY_ROUTE,
  [WorkflowNavTab.LIBRARY]: WORKFLOWS_LIBRARY_ROUTE
};
export const WORKFLOWS_NAV_TAB_DISPLAY_NAMES: Record<WorkflowNavTab, string> = {
  [WorkflowNavTab.CREATE]: 'Create an agent',
  [WorkflowNavTab.LIBRARY]: 'My agents'
};