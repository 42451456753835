interface ProgressBarProps {
  value: number;
}
const ProgressBar: React.FC<ProgressBarProps> = ({
  value
}) => <div css={{
  "height": "0.25rem",
  "width": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(68 68 96 / var(--tw-bg-opacity))"
}} data-testid="progress-container">
    <div css={{
    "height": "0.25rem",
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)"
  }} data-testid="progress-bar" style={{
    width: `${Math.min(value, 100)}%`
  }} />
  </div>;
export default ProgressBar;