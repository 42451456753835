import { ContentType } from '@kindo/universal';

import { useAppDispatch } from './typedReduxHooks';
import useToast, { ToastType } from './useToast';

import { FileResource } from '~/components/Chat/AddFileModal';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { Workflow } from '~/types';

interface UseWorkflowHydrationArgs {
  workflow: Workflow | undefined;
}

/**
 * Hydrates the redux store with saved workflow state.
 */
const useWorkflowHydration = ({ workflow }: UseWorkflowHydrationArgs) => {
  const dispatch = useAppDispatch();
  const { enqueueToast } = useToast();

  const hydrateWorkflowConfigAndKnowledgeStore = () => {
    if (!workflow) return false;

    try {
      dispatch(
        workflowBuilderActions.hydrateWorkflowConfigAndKnowledgeStore({
          workflowConfig: {
            description: workflow.description ?? '',
            instructions: workflow.systemPrompt ?? '',
            name: workflow.displayName ?? ''
          },
          knowledgeStore: {
            content:
              workflow.knowledgeStoreContent?.map(
                (content): FileResource => ({
                  id: content.id,
                  name: content.fileName ?? 'Untitled',
                  createdAt: content.createdAt,
                  fileType: content.fileType,
                  source:
                    content.type === ContentType.FILE
                      ? 'Library'
                      : content.integrationName
                })
              ) ?? []
          }
        })
      );

      return true;
    } catch (error) {
      console.error('Failed to hydrate workflow config:', error);
      enqueueToast({
        message: 'Failed to populate workflow configuration.',
        type: ToastType.ERROR
      });
      return false;
    }
  };

  return {
    hydrateWorkflowConfigAndKnowledgeStore
  };
};

export default useWorkflowHydration;
