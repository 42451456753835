import { ROOT_ROUTE, WORKFLOWS_ROUTE } from '~/constants';
export enum NavSegment {
  AGENTS = 'AGENTS',
  CHAT = 'CHAT',
}
export const NAV_SEGMENT_DISPLAY_NAMES: Record<NavSegment, string> = {
  [NavSegment.CHAT]: 'Chat',
  [NavSegment.AGENTS]: 'Agents'
};
export const NAV_SEGMENT_ROUTES: Record<NavSegment, string> = {
  [NavSegment.CHAT]: ROOT_ROUTE,
  [NavSegment.AGENTS]: WORKFLOWS_ROUTE
};