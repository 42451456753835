/**
 * Common utilities for working with tailwind and tailwind-variants
 */

/**
 * Creates a type-safe map of enum values to style strings
 * This ensures all enum values are covered in the variant mappings
 */
export type EnumVariantMap<TEnum extends Record<string, string | number>> = {
  [K in keyof TEnum as TEnum[K] extends string | number
    ? TEnum[K]
    : never]: string;
};

/**
 * Type for a subset of enum variants
 * Maps a subset of enum values to class strings
 */
export type SubsetVariantMap<
  TEnum extends Record<string, string | number>,
  TSubset extends TEnum[keyof TEnum]
> = {
  [K in TSubset]: string;
};

/**
 * Type for enum variants with slots
 * Maps enum values to an object with slot names and their class strings
 */
export type EnumVariantWithSlotsMap<
  TEnum extends Record<string, string | number>,
  TSlots extends string
> = {
  [K in keyof TEnum as TEnum[K] extends string | number
    ? TEnum[K]
    : never]: Partial<Record<TSlots, string>>;
};

/**
 * Type for a subset of enum variants with slots
 * Maps a subset of enum values to an object with slot names and their class strings
 */
export type SubsetVariantWithSlotsMap<
  TEnum extends Record<string, string | number>,
  TSubset extends TEnum[keyof TEnum],
  TSlots extends string
> = {
  [K in TSubset]: Partial<Record<TSlots, string>>;
};

/**
 * A unified function to create type-safe enum variant mappings for tailwind-variants
 *
 * Supports four different use cases:
 * 1. Full enum mappings to string classes
 * 2. Subset enum mappings to string classes
 * 3. Full enum mappings to slotted classes
 * 4. Subset enum mappings to slotted classes
 *
 * Type parameters are automatically inferred based on usage.
 *
 * @example - Full enum mapping:
 * createEnumVariants({
 *   [ButtonType.PRIMARY]: 'bg-primary text-white',
 *   [ButtonType.SECONDARY]: 'bg-secondary text-black',
 * })
 *
 * @example - Subset enum mapping:
 * createEnumVariants<typeof Size, ButtonSize>({
 *   [Size.SMALL]: 'text-sm',
 *   [Size.MEDIUM]: 'text-base',
 *   [Size.LARGE]: 'text-lg'
 * })
 *
 * @example - Full enum mapping with slots:
 * createEnumVariants<typeof Size, never, 'root' | 'inner'>({
 *   [Size.SMALL]: { root: 'text-sm', inner: 'p-1' },
 *   [Size.MEDIUM]: { root: 'text-base', inner: 'p-2' },
 *   [Size.LARGE]: { root: 'text-lg', inner: 'p-3' }
 * })
 *
 * @example - Subset enum mapping with slots:
 * createEnumVariants<typeof Size, ButtonSize, 'root' | 'inner'>({
 *   [Size.SMALL]: { root: 'text-sm', inner: 'p-1' },
 *   [Size.MEDIUM]: { root: 'text-base', inner: 'p-2' },
 *   [Size.LARGE]: { root: 'text-lg', inner: 'p-3' }
 * })
 */

// Overload 1: Full enum to string mapping
export function createEnumVariants<
  TEnum extends Record<string, string | number>
>(mappings: EnumVariantMap<TEnum>): EnumVariantMap<TEnum>;

// Overload 2: Full enum mapping with slots
export function createEnumVariants<
  TEnum extends Record<string, string | number>,
  _TSubset = never, // Unused, just for overload matching (prefixed with _ to avoid lint errors)
  TSlots extends string = never // Used to determine if slots are needed
>(
  mappings: EnumVariantWithSlotsMap<TEnum, TSlots>
): EnumVariantWithSlotsMap<TEnum, TSlots>;

// Overload 3: Subset enum to string mapping
export function createEnumVariants<
  TEnum extends Record<string, string | number>,
  TSubset extends TEnum[keyof TEnum]
>(mappings: SubsetVariantMap<TEnum, TSubset>): SubsetVariantMap<TEnum, TSubset>;

// Overload 4: Subset enum with slots
export function createEnumVariants<
  TEnum extends Record<string, string | number>,
  TSubset extends TEnum[keyof TEnum],
  TSlots extends string
>(
  mappings: SubsetVariantWithSlotsMap<TEnum, TSubset, TSlots>
): SubsetVariantWithSlotsMap<TEnum, TSubset, TSlots>;

// Implementation that handles all overloads
export function createEnumVariants<
  TEnum extends Record<string, string | number>,
  TSubset extends TEnum[keyof TEnum] = TEnum[keyof TEnum],
  TSlots extends string = string
>(
  mappings:
    | EnumVariantMap<TEnum>
    | SubsetVariantMap<TEnum, TSubset>
    | EnumVariantWithSlotsMap<TEnum, TSlots>
    | SubsetVariantWithSlotsMap<TEnum, TSubset, TSlots>
):
  | EnumVariantMap<TEnum>
  | SubsetVariantMap<TEnum, TSubset>
  | EnumVariantWithSlotsMap<TEnum, TSlots>
  | SubsetVariantWithSlotsMap<TEnum, TSubset, TSlots> {
  return mappings;
}
