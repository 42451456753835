import { IntegrationCategory } from '@kindo/universal';

const INTEGRATION_CATEGORY_TO_LABEL: Record<IntegrationCategory, string> = {
  [IntegrationCategory.ACCOUNTING]: 'Accounting',
  [IntegrationCategory.CUSTOMER_MANAGEMENT]: 'Customer Management',
  [IntegrationCategory.FILE_STORAGE]: 'File Storage',
  [IntegrationCategory.HIRING]: 'Hiring',
  [IntegrationCategory.HR]: 'HR',
  [IntegrationCategory.MARKETING]: 'Marketing',
  [IntegrationCategory.OTHER]: 'Other',
  [IntegrationCategory.TICKETING]: 'Ticketing'
};

export const getIntegrationCategoryLabel = (
  category: IntegrationCategory
): string => INTEGRATION_CATEGORY_TO_LABEL[category];
