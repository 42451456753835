import {
  CHAT_AGENT_TOOLS,
  CYBERSECURITY_FOCUSED_LLMS,
  CybersecurityFocusedLlm,
  GENERAL_PURPOSE_LLMS,
  GeneralPurposeLlm,
  isModelCategory,
  Llm,
  LONG_CONTEXT_LLMS,
  LongContextLlm,
  ModelCategory,
  MULTIMODAL_LLMS,
  MultimodalLlm,
  REASONING_LLMS,
  ReasoningLlm,
  Tool
} from '@kindo/universal';

// Prepend with 'kindo:' to avoid collisions with other apps
export enum LocalStorageKey {
  CHAT_MODEL = 'kindo:chat-modal',
  CYBERSECURITY_MODEL = 'kindo:cybersecurity-model',
  ENABLED_TOOLS = 'kindo:enabled-tools',
  GENERAL_PURPOSE_MODEL = 'kindo:general-purpose-model',
  HISTORY_MENU_ACTIVE_TAB = 'kindo:history-menu-active-tab',
  LONG_CONTEXT_MODEL = 'kindo:long-context-model',
  MODEL_CATEGORY = 'kindo:model-category',
  MULTIMODAL_MODEL = 'kindo:multimodal-model',
  REASONING_MODEL = 'kindo:reasoning-model'
}

export enum WorkstationHistorySidebarActiveTab {
  AGENTS = 'AGENTS',
  CHATS = 'CHATS',
  CLOSED = ''
}

// Defines key/value pair of LocalStorage items
export interface LocalStorageItems {
  [LocalStorageKey.CHAT_MODEL]: string; // DO NOT REMOVE COMMENT: SupportedLlm
  [LocalStorageKey.MODEL_CATEGORY]: ModelCategory;
  [LocalStorageKey.ENABLED_TOOLS]: string;
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]: WorkstationHistorySidebarActiveTab;
  [LocalStorageKey.GENERAL_PURPOSE_MODEL]: GeneralPurposeLlm;
  [LocalStorageKey.LONG_CONTEXT_MODEL]: LongContextLlm;
  [LocalStorageKey.REASONING_MODEL]: ReasoningLlm;
  [LocalStorageKey.CYBERSECURITY_MODEL]: CybersecurityFocusedLlm;
  [LocalStorageKey.MULTIMODAL_MODEL]: MultimodalLlm;
}

export const DEFAULT_LOCAL_STORAGE_VALUES: LocalStorageItems = {
  [LocalStorageKey.CHAT_MODEL]: Llm.CLAUDE_3_5_SONNET,
  [LocalStorageKey.MODEL_CATEGORY]: ModelCategory.GENERAL_PURPOSE,
  [LocalStorageKey.ENABLED_TOOLS]: JSON.stringify([]),
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]:
    WorkstationHistorySidebarActiveTab.CLOSED,
  [LocalStorageKey.GENERAL_PURPOSE_MODEL]: GENERAL_PURPOSE_LLMS[0],
  [LocalStorageKey.LONG_CONTEXT_MODEL]: LONG_CONTEXT_LLMS[0],
  [LocalStorageKey.REASONING_MODEL]: REASONING_LLMS[0],
  [LocalStorageKey.CYBERSECURITY_MODEL]: CYBERSECURITY_FOCUSED_LLMS[0],
  [LocalStorageKey.MULTIMODAL_MODEL]: MULTIMODAL_LLMS[0]
};

export const DEFAULT_LOCAL_STORAGE_VALIDATORS: {
  [K in LocalStorageKey]: (value: any) => value is LocalStorageItems[K];
} = {
  // Selected LLMs
  [LocalStorageKey.CHAT_MODEL]: (value): value is string =>
    value && typeof value === 'string',
  [LocalStorageKey.MODEL_CATEGORY]: (value): value is ModelCategory =>
    isModelCategory(value),
  [LocalStorageKey.GENERAL_PURPOSE_MODEL]: (
    value
  ): value is GeneralPurposeLlm =>
    Object.values(GENERAL_PURPOSE_LLMS).includes(value),
  [LocalStorageKey.LONG_CONTEXT_MODEL]: (value): value is LongContextLlm =>
    Object.values(LONG_CONTEXT_LLMS).includes(value),
  [LocalStorageKey.REASONING_MODEL]: (value): value is ReasoningLlm =>
    Object.values(REASONING_LLMS).includes(value),
  [LocalStorageKey.CYBERSECURITY_MODEL]: (
    value
  ): value is CybersecurityFocusedLlm =>
    Object.values(CYBERSECURITY_FOCUSED_LLMS).includes(value),
  [LocalStorageKey.MULTIMODAL_MODEL]: (value): value is MultimodalLlm =>
    Object.values(MULTIMODAL_LLMS).includes(value),

  // Other
  [LocalStorageKey.ENABLED_TOOLS]: (value): value is string => {
    const enabledTools: Tool[] = JSON.parse(value);
    return (
      Array.isArray(enabledTools) &&
      (enabledTools.length === 0 ||
        enabledTools.every((tool: Tool) => CHAT_AGENT_TOOLS.includes(tool)))
    );
  },
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]: (
    value
  ): value is WorkstationHistorySidebarActiveTab =>
    Object.values(WorkstationHistorySidebarActiveTab).includes(value)
};
