import { API_AUTHORIZATION_DISPLAY_NAME_MAP, ApiActionHeaderValue, ApiAuthorizationType, Secret } from '@kindo/universal';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Select, Size, TextIconColor } from '../../../../core';
import WorkflowApiActionHeaderValueInput from './WorkflowApiActionHeaderValueInput';
import { KeyValueContainer, KeyValuePairContainer } from './WorkflowApiActionStepEditorKeyValueSection';
import { TooltipPlacement } from '~/components/core/ToolTip/ToolTip';
export interface KeyValueAuthSectionProps {
  authEntry: {
    authorizationType: ApiAuthorizationType;
    value: ApiActionHeaderValue;
  } | null;
  disabled: boolean;
  onAdd: () => void;
  onRemove: () => void;
  onUpdate: (value: {
    authorizationType: ApiAuthorizationType;
    value: ApiActionHeaderValue;
  }) => void;
  secrets: Pick<Secret, 'id' | 'label'>[];
  showWarning: boolean;
}
const getPlaceholder = (authorizationType: ApiAuthorizationType) => {
  switch (authorizationType) {
    case ApiAuthorizationType.BASIC:
      return 'username:password';
    case ApiAuthorizationType.BEARER:
      return 'token value';
    default:
      return '';
  }
};
const WorkflowApiActionStepEditorAuthSection = ({
  disabled,
  authEntry,
  secrets,
  showWarning,
  onUpdate,
  onRemove,
  onAdd
}: KeyValueAuthSectionProps) => <KeyValuePairContainer>
    {authEntry && <KeyValueContainer>
        <Select disabled={disabled} onChange={selectedValue => onUpdate({
      authorizationType: selectedValue,
      value: authEntry.value
    })} options={Object.values(ApiAuthorizationType).map(type => ({
      label: API_AUTHORIZATION_DISPLAY_NAME_MAP[type],
      value: type
    }))} placeholderLabel="Select Authorization Type" value={authEntry.authorizationType} />
        <WorkflowApiActionHeaderValueInput disabled={disabled} onChange={value => onUpdate({
      authorizationType: authEntry.authorizationType,
      value
    })} placeholder={getPlaceholder(authEntry.authorizationType)} prefix={authEntry?.authorizationType ? API_AUTHORIZATION_DISPLAY_NAME_MAP[authEntry.authorizationType] : ''} secrets={secrets || []} value={authEntry.value} />
        <IconButton color={TextIconColor.PRIMARY} disabled={disabled} icon={Icon.TRASH} onClick={onRemove} size={Size.SMALL} type={IconButtonType.OUTLINED} />
      </KeyValueContainer>}
    {!authEntry && <Button disabled={disabled} endIcon={Icon.PLUS} fullWidth label="Add Authorization" onClick={onAdd} tooltip={showWarning ? 'Adding an authorization in this section will override the Authorization header in Headers section' : undefined} tooltipPlacement={TooltipPlacement.AUTO} type={ButtonType.SOLID} />}
  </KeyValuePairContainer>;
export default WorkflowApiActionStepEditorAuthSection;