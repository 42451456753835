import { FiltersValues } from '~/components/shared/Filters/Filters';
export enum OrgUserFilter {
  ROLE = 'ROLE',
  STATUS = 'STATUS',
}
export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}
export type OrgUserFiltersValues = FiltersValues<OrgUserFilter>;
export interface OrgMember {
  email: string;
  id: string;
  name: string | null;
  orgRole: string;
  userGroups?: string[];
}