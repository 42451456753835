import {
  CustomDlpFilterType,
  DlpFilter,
  DlpFilterPolicy,
  Provider
} from '@kindo/universal';
import { useState } from 'react';

import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';

interface BaseDlpConfigParams {
  model: string | null; // DO NOT REMOVE COMMENT: was Llm | null
  provider: Provider | null;
  userGroupId: string | null;
  onMutate?: (filter: DlpFilter) => void;
  onSettled?: () => void;
}

interface UpdateDlpFiltersConfigParams extends BaseDlpConfigParams {
  filter: DlpFilter;
  policy: Extract<
    DlpFilterPolicy,
    DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH
  >;
}

interface CreateCustomDlpFilterParams extends BaseDlpConfigParams {
  policy: DlpFilterPolicy;
  type: CustomDlpFilterType;
  value: string;
}

interface DeleteCustomDlpFilterParams extends BaseDlpConfigParams {
  value: string;
}

enum ToastMessage {
  FAILED_TO_CREATE_DLP_FILTER = 'Failed to create data loss prevention filter.',
  FAILED_TO_DELETE_DLP_FILTER = 'Failed to delete data loss prevention filter.',
  FAILED_TO_UPDATE_DLP_FILTER = 'Failed to update data loss prevention filter.',
  SUCCESSFULLY_CREATED_DLP_FILTER = 'Successfully created data loss prevention filter.',
  SUCCESSFULLY_DELETED_DLP_FILTER = 'Successfully deleted data loss prevention filter.',
  SUCCESSFULLY_UPDATED_DLP_FILTER = 'Successfully updated data loss prevention filter.'
}

export const useMutateDlpConfigs = ({
  model,
  provider,
  userGroupId,
  onMutate,
  onSettled
}: BaseDlpConfigParams) => {
  const [updatingFilters, setUpdatingFilters] = useState<DlpFilter[]>([]);
  const { enqueueToast } = useToast();
  const utils = nextTrpc.useContext();

  const isForUserGroupProviderSecurityConfigs = userGroupId && provider;
  const isForUserGroupModelSecurityConfigs = userGroupId && model;
  const isForOrgProviderSecurityConfigs = !userGroupId && provider;
  const isForOrgModelSecurityConfigs = !userGroupId && model;

  // Helper function to handle onSettled for all mutations
  const handleSettled = () => {
    setUpdatingFilters([]);

    // Invalidate relevant queries based on resource and target types
    if (isForUserGroupProviderSecurityConfigs) {
      void utils.adminSecurityConfig.listUserGroupProviderSecurityConfigs.refetch();
    } else if (isForUserGroupModelSecurityConfigs) {
      void utils.adminSecurityConfig.listUserGroupModelSecurityConfigs.refetch();
    } else if (isForOrgProviderSecurityConfigs) {
      void utils.adminSecurityConfig.listOrgProviderSecurityConfigs.refetch();
    } else if (isForOrgModelSecurityConfigs) {
      void utils.adminSecurityConfig.listOrgModelSecurityConfigs.refetch();
    }

    onSettled?.();
  };

  // Update DLP Filters Config Mutations
  const updateOrgModelDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.updateOrgModelDlpFiltersConfig.useMutation({
      onMutate: ({ filter }) => {
        setUpdatingFilters((prev) => [...prev, filter]);
        onMutate?.(filter);
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_UPDATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const updateOrgProviderDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.updateOrgProviderDlpFiltersConfig.useMutation({
      onMutate: ({ filter }) => {
        setUpdatingFilters((prev) => [...prev, filter]);
        onMutate?.(filter);
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_UPDATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const updateUserGroupModelDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.updateUserGroupModelDlpFiltersConfig.useMutation({
      onMutate: ({ filter }) => {
        setUpdatingFilters((prev) => [...prev, filter]);
        onMutate?.(filter);
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_UPDATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const updateUserGroupProviderDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.updateUserGroupProviderDlpFiltersConfig.useMutation(
      {
        onMutate: ({ filter }) => {
          setUpdatingFilters((prev) => [...prev, filter]);
          onMutate?.(filter);
        },
        onSettled: handleSettled,
        onError: () => {
          enqueueToast({
            message: ToastMessage.FAILED_TO_UPDATE_DLP_FILTER,
            type: ToastType.ERROR
          });
        }
      }
    );

  // Create Custom DLP Filter Mutations
  const createOrgModelCustomDlpFilter =
    nextTrpc.customDlpFilters.createOrgModelCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_CREATED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_CREATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const createOrgProviderCustomDlpFilter =
    nextTrpc.customDlpFilters.createOrgProviderCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_CREATED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_CREATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const createUserGroupModelCustomDlpFilter =
    nextTrpc.customDlpFilters.createUserGroupModelCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_CREATED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_CREATE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const createUserGroupProviderCustomDlpFilter =
    nextTrpc.customDlpFilters.createUserGroupProviderCustomDlpFilter.useMutation(
      {
        onSuccess: () => {
          enqueueToast({
            message: ToastMessage.SUCCESSFULLY_CREATED_DLP_FILTER,
            type: ToastType.SUCCESS
          });
        },
        onSettled: handleSettled,
        onError: () => {
          enqueueToast({
            message: ToastMessage.FAILED_TO_CREATE_DLP_FILTER,
            type: ToastType.ERROR
          });
        }
      }
    );

  // Delete model or provider DLP filters config Mutations
  const deleteOrgModelDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.deleteOrgModelDlpFiltersConfig.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteOrgProviderDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.deleteOrgProviderDlpFiltersConfig.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteUserGroupModelDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.deleteUserGroupModelDlpFiltersConfig.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteUserGroupProviderDlpFiltersConfigMutation =
    nextTrpc.dlpFiltersConfig.deleteUserGroupProviderDlpFiltersConfig.useMutation(
      {
        onSuccess: () => {
          enqueueToast({
            message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
            type: ToastType.SUCCESS
          });
        },
        onSettled: handleSettled,
        onError: () => {
          enqueueToast({
            message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
            type: ToastType.ERROR
          });
        }
      }
    );

  // Delete Custom DLP Filter Mutations
  const deleteOrgModelCustomDlpFilter =
    nextTrpc.customDlpFilters.deleteOrgModelCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteOrgProviderCustomDlpFilter =
    nextTrpc.customDlpFilters.deleteOrgProviderCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteUserGroupModelCustomDlpFilter =
    nextTrpc.customDlpFilters.deleteUserGroupModelCustomDlpFilter.useMutation({
      onSuccess: () => {
        enqueueToast({
          message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
          type: ToastType.SUCCESS
        });
      },
      onSettled: handleSettled,
      onError: () => {
        enqueueToast({
          message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
          type: ToastType.ERROR
        });
      }
    });

  const deleteUserGroupProviderCustomDlpFilter =
    nextTrpc.customDlpFilters.deleteUserGroupProviderCustomDlpFilter.useMutation(
      {
        onSuccess: () => {
          enqueueToast({
            message: ToastMessage.SUCCESSFULLY_DELETED_DLP_FILTER,
            type: ToastType.SUCCESS
          });
        },
        onSettled: handleSettled,
        onError: () => {
          enqueueToast({
            message: ToastMessage.FAILED_TO_DELETE_DLP_FILTER,
            type: ToastType.ERROR
          });
        }
      }
    );

  // Function to update DLP filters config
  const updateDlpFiltersConfig = ({
    filter,
    policy
  }: Omit<UpdateDlpFiltersConfigParams, keyof BaseDlpConfigParams>) => {
    if (isForOrgModelSecurityConfigs) {
      updateOrgModelDlpFiltersConfigMutation.mutate({
        model,
        filter,
        policy
      });
    } else if (isForOrgProviderSecurityConfigs) {
      updateOrgProviderDlpFiltersConfigMutation.mutate({
        provider,
        filter,
        policy
      });
    } else if (isForUserGroupProviderSecurityConfigs) {
      updateUserGroupProviderDlpFiltersConfigMutation.mutate({
        provider,
        userGroupId,
        filter,
        policy
      });
    } else if (isForUserGroupModelSecurityConfigs) {
      updateUserGroupModelDlpFiltersConfigMutation.mutate({
        userGroupId,
        model,
        filter,
        policy
      });
    } else if (isForUserGroupProviderSecurityConfigs) {
      updateUserGroupProviderDlpFiltersConfigMutation.mutate({
        userGroupId,
        provider,
        filter,
        policy
      });
    }
  };

  // Function to create custom DLP filter
  const createCustomDlpFilter = ({
    policy,
    type,
    value
  }: Omit<CreateCustomDlpFilterParams, keyof BaseDlpConfigParams>) => {
    if (isForOrgModelSecurityConfigs) {
      createOrgModelCustomDlpFilter.mutate({
        model,
        policy,
        type,
        value
      });
    } else if (isForOrgProviderSecurityConfigs) {
      createOrgProviderCustomDlpFilter.mutate({
        provider,
        policy,
        type,
        value
      });
    } else if (isForUserGroupModelSecurityConfigs) {
      createUserGroupModelCustomDlpFilter.mutate({
        userGroupId,
        model,
        policy,
        type,
        value
      });
    } else if (isForUserGroupProviderSecurityConfigs) {
      createUserGroupProviderCustomDlpFilter.mutate({
        userGroupId,
        provider,
        policy,
        type,
        value
      });
    }
  };

  // Function to delete custom DLP filter
  const deleteCustomDlpFilter = ({
    value
  }: Omit<DeleteCustomDlpFilterParams, keyof BaseDlpConfigParams>) => {
    if (isForOrgModelSecurityConfigs) {
      deleteOrgModelCustomDlpFilter.mutate({
        model,
        value
      });
    } else if (isForOrgProviderSecurityConfigs) {
      deleteOrgProviderCustomDlpFilter.mutate({
        provider,
        value
      });
    } else if (isForUserGroupModelSecurityConfigs) {
      deleteUserGroupModelCustomDlpFilter.mutate({
        model,
        userGroupId,
        value
      });
    } else if (isForUserGroupProviderSecurityConfigs) {
      deleteUserGroupProviderCustomDlpFilter.mutate({
        provider,
        userGroupId,
        value
      });
    }
  };

  const deleteDlpFiltersConfig = () => {
    if (isForOrgModelSecurityConfigs) {
      deleteOrgModelDlpFiltersConfigMutation.mutate({
        model
      });
    } else if (isForOrgProviderSecurityConfigs) {
      deleteOrgProviderDlpFiltersConfigMutation.mutate({
        provider
      });
    } else if (isForUserGroupModelSecurityConfigs) {
      deleteUserGroupModelDlpFiltersConfigMutation.mutate({
        model,
        userGroupId
      });
    } else if (isForUserGroupProviderSecurityConfigs) {
      deleteUserGroupProviderDlpFiltersConfigMutation.mutate({
        provider,
        userGroupId
      });
    }
  };

  return {
    updatingFilters,
    updateDlpFiltersConfig,
    createCustomDlpFilter,
    deleteCustomDlpFilter,
    deleteDlpFiltersConfig
  };
};

export default useMutateDlpConfigs;
