import { ApiKeyManagement } from './ApiKeyManagement';
import { ApiReference } from './ApiReference';
import { AvailableModels } from './AvailableModels';
export enum ApiTab {
  API_KEY_MANAGEMENT = 'API_KEY_MANAGEMENT',
  API_REFERENCE = 'API_REFERENCE',
  AVAILABLE_MODELS = 'AVAILABLE_MODELS',
}
export const API_TAB_DISPLAY_NAMES: Record<ApiTab, string> = {
  [ApiTab.API_KEY_MANAGEMENT]: 'API Keys',
  [ApiTab.API_REFERENCE]: 'API Reference',
  [ApiTab.AVAILABLE_MODELS]: 'Available Models'
};
export const API_SUB_TABS_COMPONENTS: Record<ApiTab, React.ReactNode> = {
  [ApiTab.API_KEY_MANAGEMENT]: <ApiKeyManagement />,
  [ApiTab.API_REFERENCE]: <ApiReference />,
  [ApiTab.AVAILABLE_MODELS]: <AvailableModels />
};
export const ORDERED_API_TABS: ApiTab[] = [ApiTab.API_KEY_MANAGEMENT, ApiTab.AVAILABLE_MODELS, ApiTab.API_REFERENCE];