import { triggerActions } from '../redux/reducers/workflowBuilderTriggerSlice';

import { useAppDispatch, useAppSelector } from './typedReduxHooks';
import useToast, { ToastType } from './useToast';

import { useGetWorkflowFromUrlSlug } from '~/hooks';
import { nextTrpc } from '~/trpc';
import {
  BuilderWorkflowStatus,
  isExistingWorkflowTrigger,
  WorkflowTrigger,
  WorkflowTriggerBuilder
} from '~/types';

type WorkflowRefetchResult = Awaited<
  ReturnType<ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch']>
>;

interface UseWorkflowTriggerArgs {
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflowId: string;
}

interface UseWorkflowTriggerReturnObject {
  deleteTrigger: (trigger: WorkflowTrigger) => Promise<void>;
  isDeletingTrigger: boolean;
  isSavingTrigger: boolean;
  saveTrigger: (trigger: WorkflowTriggerBuilder) => Promise<void>;
}

const useWorkflowTrigger = ({
  workflowId,
  refetchWorkflow
}: UseWorkflowTriggerArgs): UseWorkflowTriggerReturnObject => {
  const dispatch = useAppDispatch();
  const { enqueueToast } = useToast();
  const { activeTriggerCondition } = useAppSelector(
    (state) => state.workflowBuilderTrigger
  );

  /** MUTATIONS */
  // Save a new trigger
  const createTriggerMutation = nextTrpc.workflows.createTrigger.useMutation({
    onSuccess: () => {
      refetchWorkflow()
        .then((result: WorkflowRefetchResult) => {
          if (result.data?.item.triggers[0]) {
            dispatch(
              triggerActions.setTriggerAfterRefetch(
                result.data.item.triggers[0]
              )
            );
          }
          enqueueToast({
            message: 'Trigger created successfully.',
            type: ToastType.SUCCESS
          });
        })
        .catch((error: Error) => {
          console.error('Failed to refetch workflow:', error);
          enqueueToast({
            message:
              'Trigger created but failed to refresh. Please refresh the page.',
            type: ToastType.WARNING
          });
        });
    },
    onError: (error) => {
      console.error('Failed to create trigger:', error);
      enqueueToast({
        message: 'Failed to create trigger.',
        type: ToastType.ERROR
      });
    }
  });

  // Update an existing trigger
  const updateTriggerMutation = nextTrpc.workflows.updateTrigger.useMutation({
    onSuccess: () => {
      refetchWorkflow()
        .then((result: WorkflowRefetchResult) => {
          if (result.data?.item.triggers[0]) {
            dispatch(
              triggerActions.setTriggerAfterRefetch(
                result.data.item.triggers[0]
              )
            );
          }
          enqueueToast({
            message: 'Trigger updated successfully.',
            type: ToastType.SUCCESS
          });
        })
        .catch((error: Error) => {
          console.error('Failed to refetch workflow:', error);
          enqueueToast({
            message:
              'Trigger updated but failed to refresh. Please refresh the page.',
            type: ToastType.WARNING
          });
        });
    },
    onError: (error) => {
      console.error('Failed to update trigger:', error);
      enqueueToast({
        message: 'Failed to update trigger.',
        type: ToastType.ERROR
      });
    }
  });

  // Delete a trigger
  const deleteTriggerMutation = nextTrpc.workflows.deleteTrigger.useMutation({
    onSuccess: () => {
      refetchWorkflow()
        .then(() => {
          dispatch(triggerActions.clearTrigger());
          enqueueToast({
            message: 'Trigger deleted successfully.',
            type: ToastType.SUCCESS
          });
        })
        .catch((error: Error) => {
          console.error('Failed to refetch workflow:', error);
          enqueueToast({
            message:
              'Trigger deleted but failed to refresh. Please refresh the page.',
            type: ToastType.WARNING
          });
        });
    },
    onError: (error) => {
      enqueueToast({
        message: error.data?.kindoErrorMessage || 'Failed to delete trigger.',
        type: ToastType.ERROR
      });
    }
  });

  /** ACTIONS */
  // Triggers

  const saveTrigger = async (trigger: WorkflowTriggerBuilder) => {
    // Combine existing conditions with active condition if it exists and has a value
    const conditions = activeTriggerCondition?.value
      ? [
          ...trigger.conditions.filter(
            (_, index) => index !== activeTriggerCondition.index
          ),
          {
            ...activeTriggerCondition,
            index: trigger.conditions.length
          }
        ]
      : trigger.conditions;

    try {
      // Create a new trigger
      if (trigger.status === BuilderWorkflowStatus.NEW) {
        await createTriggerMutation.mutateAsync({
          workflowId,
          enabled: trigger.enabled,
          integrationId: trigger.integrationId,
          integrationInputType: trigger.integrationInputType,
          operator: trigger.operator,
          conditions: conditions
            .filter((condition) => condition.field && condition.type)
            .map((condition) => ({
              field: condition.field!,
              type: condition.type!,
              value: condition.value
            }))
        });
        return;
      }

      // Update an existing trigger
      if (isExistingWorkflowTrigger(trigger)) {
        await updateTriggerMutation.mutateAsync({
          triggerId: trigger.id,
          integrationId: trigger.integrationId,
          integrationInputType: trigger.integrationInputType,
          operator: trigger.operator,
          enabled: trigger.enabled,
          conditions: conditions
            .filter((condition) => condition.field && condition.type)
            .map((condition) => ({
              field: condition.field!,
              type: condition.type!,
              value: condition.value
            }))
        });
      }
    } catch (error) {
      console.error('Failed to save trigger:', error);
    }
  };

  const deleteTrigger = async (trigger: WorkflowTrigger) => {
    try {
      await deleteTriggerMutation.mutateAsync({
        triggerId: trigger.id
      });
    } catch (error) {
      // Error is already handled by the onError callback in the mutation
      // This prevents the unhandled runtime error from bubbling up
      console.error('Failed to delete trigger:', error);
    }
  };

  const isSavingTrigger =
    createTriggerMutation.isLoading || updateTriggerMutation.isLoading;

  return {
    isSavingTrigger,
    isDeletingTrigger: deleteTriggerMutation.isLoading,
    saveTrigger,
    deleteTrigger
  };
};

export default useWorkflowTrigger;
