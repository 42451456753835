import _styled from "styled-components";
import { ApiActionHeaders, ApiActionHeaderValue, ApiAuthorizationType, isRequestBodySupportedHttpMethod, SUPPORTED_HTTP_METHODS, SupportedHttpMethod } from '@kindo/universal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { workflowBuilderActions } from '../../../../../redux/reducers/workflowBuilderSlice';
import { BuilderWorkflowApiActionStep } from '../../../../../types';
import { CalloutType, Icon, Icons, Select, Size, TextField, TextFieldType, TextIconColor, Typography, TypographyFont } from '../../../../core';
import WorkflowApiActionHeaderValueInput from './WorkflowApiActionHeaderValueInput';
import WorkflowApiActionStepEditorAuthSection from './WorkflowApiActionStepEditorAuthSection';
import WorkflowApiActionStepEditorKeyValueSection from './WorkflowApiActionStepEditorKeyValueSection';
import { Markdown } from '~/components/Markdown';
import { nextTrpc } from '~/trpc';
const ApiActionEditorContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem"
});

// Collapsible section styles
const SectionContainer = _styled.div<{
  $isLast?: boolean;
}>(({
  $isLast
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
}, !$isLast && {
  "borderBottomWidth": "1px",
  "borderColor": "rgb(255 255 255 / 0.1)",
  "paddingBottom": "1rem"
}]);
const SectionHeader = _styled.div({
  "display": "flex",
  "width": "100%",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "space-between",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
});
const SectionContent = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const NonExpandableSection = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const BODY_PLACEHOLDER = `{
  "id": "123",
  "name": "Example Item",
  "description": "This is a sample request body",
  "properties": {
    "color": "blue",
    "size": "medium"
  }
}`;
interface WorkflowApiActionStepEditorProps {
  disabled: boolean;
  step: BuilderWorkflowApiActionStep;
}
const WorkflowApiActionStepEditor = ({
  disabled,
  step
}: WorkflowApiActionStepEditorProps) => {
  const dispatch = useDispatch();
  const {
    data: secrets
  } = nextTrpc.secrets.list.useQuery({});

  // Section expansion states
  const [isParametersExpanded, setIsParametersExpanded] = useState(true);
  const [isHeadersExpanded, setIsHeadersExpanded] = useState(true);
  const [isAuthExpanded, setIsAuthExpanded] = useState(true);
  const [isBodyExpanded, setIsBodyExpanded] = useState(true);

  // Method handler
  const handleMethodChange = (value: SupportedHttpMethod) => {
    dispatch(workflowBuilderActions.updateApiActionStepMethod(value));
  };

  // Endpoint handler
  const handleEndpointChange = (value: string) => {
    dispatch(workflowBuilderActions.updateApiActionStepEndpoint(value));
  };

  // Body handler
  const handleBodyChange = (value: string) => {
    dispatch(workflowBuilderActions.updateApiActionStepBody(value || null));
  };

  // Header management
  const handleAddHeader = () => {
    dispatch(workflowBuilderActions.addApiActionStepHeader());
  };
  const handleUpdateHeader = (index: number, key: string, value: ApiActionHeaders[string]) => {
    dispatch(workflowBuilderActions.updateApiActionStepHeader({
      index,
      key,
      value
    }));
  };
  const handleRemoveHeader = (index: number) => {
    dispatch(workflowBuilderActions.removeApiActionStepHeader(index));
  };

  // Auth management
  const handleAddAuth = () => {
    dispatch(workflowBuilderActions.addApiActionStepAuth());
  };
  const handleUpdateAuth = ({
    authorizationType,
    value
  }: {
    authorizationType: ApiAuthorizationType;
    value: ApiActionHeaderValue;
  }) => {
    dispatch(workflowBuilderActions.updateApiActionStepAuth({
      authorizationType,
      value
    }));
  };
  const handleRemoveAuth = () => {
    dispatch(workflowBuilderActions.removeApiActionStepAuth());
  };

  // Parameter management
  const handleAddParameter = () => {
    dispatch(workflowBuilderActions.addApiActionStepParameter());
  };
  const handleUpdateParameter = (index: number, key: string, value: string) => {
    dispatch(workflowBuilderActions.updateApiActionStepParameter({
      index,
      key,
      value
    }));
  };
  const handleRemoveParameter = (index: number) => {
    dispatch(workflowBuilderActions.removeApiActionStepParameter(index));
  };

  // Helper render functions
  const renderSection = ({
    label,
    isExpandable = true,
    isExpanded = false,
    onToggle,
    isLast = false,
    children
  }: {
    children: React.ReactNode;
    label: string;
    isExpandable?: boolean;
    isExpanded?: boolean;
    isLast?: boolean;
    onToggle?: () => void;
  }) => <SectionContainer $isLast={isLast}>
      {isExpandable ? <>
          <SectionHeader onClick={onToggle}>
            <Typography font={TypographyFont.HEADING}>{label}</Typography>
            <Icons color={TextIconColor.PRIMARY} icon={isExpanded ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} size={Size.MEDIUM} />
          </SectionHeader>
          {isExpanded && <SectionContent>{children}</SectionContent>}
        </> : <NonExpandableSection>
          <Typography fitContent font={TypographyFont.HEADING}>
            {label}
          </Typography>
          {children}
        </NonExpandableSection>}
    </SectionContainer>;
  return <ApiActionEditorContainer>
      {disabled && <Markdown calloutType={CalloutType.WARNING}>
          {`> Only the creator of the agent can edit API Action Steps. Any references to the creator's secrets have been hidden.`}
        </Markdown>}

      {/* Method Section */}
      {renderSection({
      label: 'METHOD',
      isExpandable: false,
      children: <Select buttonSize={Size.SMALL} disabled={disabled} onChange={handleMethodChange} options={SUPPORTED_HTTP_METHODS.map(supportedMethod => ({
        label: supportedMethod,
        value: supportedMethod
      }))} placeholderLabel="Select Method" value={step.method} />
    })}

      {/* Endpoint Section */}
      {renderSection({
      label: 'URL',
      isExpandable: false,
      children: <TextField disabled={disabled} onChange={handleEndpointChange} placeholder="https://api.example.com/endpoint" type={!step.endpoint ? TextFieldType.HIGHLIGHT : TextFieldType.DEFAULT} value={step.endpoint} width={{
        "width": "60%"
      }} />
    })}

      {/* Parameters Section */}
      {renderSection({
      label: 'PARAMETERS',
      isExpanded: isParametersExpanded,
      onToggle: () => setIsParametersExpanded(!isParametersExpanded),
      children: <WorkflowApiActionStepEditorKeyValueSection disabled={disabled} entries={Object.entries(step.parameters || {})} onAdd={handleAddParameter} onRemove={handleRemoveParameter} onUpdate={handleUpdateParameter} renderValueInput={({
        value,
        onChange
      }) => <TextField disabled={disabled} fullWidth onChange={onChange} placeholder="Value" type={value ? TextFieldType.DEFAULT : TextFieldType.HIGHLIGHT} value={value} />} />
    })}

      {/* Headers Section */}
      {renderSection({
      label: 'HEADERS',
      isExpanded: isHeadersExpanded,
      onToggle: () => setIsHeadersExpanded(prev => !prev),
      children: <WorkflowApiActionStepEditorKeyValueSection disabled={disabled} entries={Object.entries(step.headers).map(([key, value]) => [key, value || {
        text: ''
      }])} onAdd={handleAddHeader} onRemove={handleRemoveHeader} onUpdate={handleUpdateHeader} renderValueInput={({
        value,
        onChange
      }) => <WorkflowApiActionHeaderValueInput disabled={disabled} onChange={onChange} secrets={secrets || []} value={value} />} />
    })}

      {/* Auth Section */}
      {renderSection({
      label: 'AUTH',
      isExpanded: isAuthExpanded,
      onToggle: () => setIsAuthExpanded(prev => !prev),
      children: <WorkflowApiActionStepEditorAuthSection authEntry={step.auth} disabled={disabled} onAdd={handleAddAuth} onRemove={handleRemoveAuth} onUpdate={handleUpdateAuth} secrets={secrets || []} showWarning={!!step.headers['Authorization']} />
    })}

      {/* Body Section - only show for supported methods */}
      {isRequestBodySupportedHttpMethod(step.method) && renderSection({
      label: 'BODY',
      isExpanded: isBodyExpanded,
      onToggle: () => setIsBodyExpanded(prev => !prev),
      isLast: true,
      children: <TextField fullWidth multiline onChange={handleBodyChange} placeholder={BODY_PLACEHOLDER} type={TextFieldType.DEFAULT} value={step.body || ''} />
    })}
    </ApiActionEditorContainer>;
};
export default WorkflowApiActionStepEditor;