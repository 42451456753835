import { useState } from 'react';
import ChatLoadingIndicator from '../../Chat/ChatLoadingIndicator';
import { Icon, Icons, Size, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '../../core';
export type ThinkContentSectionProps = {
  thinkContent: string;
  isStreaming?: boolean;
};
const ThinkContentSection: React.FC<ThinkContentSectionProps> = ({
  thinkContent,
  isStreaming
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return <div css={{
    "marginBottom": "0.5rem",
    "display": "flex",
    "width": "100%",
    "flex": "1 1 0%",
    "flexDirection": "column",
    "alignSelf": "stretch",
    "borderRadius": "0.25rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(22 18 61 / var(--tw-bg-opacity))"
  }} data-testid="think-content-section">
      <div css={{
      "display": "flex",
      "width": "100%",
      "cursor": "pointer",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      ">div>span:hover": {
        "--tw-text-opacity": "1",
        "color": "rgb(84 254 209 / var(--tw-text-opacity))"
      }
    }} data-testid="think-header" onClick={() => setIsExpanded(!isExpanded)}>
        {isStreaming ? <ChatLoadingIndicator displayLoadingText loadingText="Thinking..." /> : <Typography color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
            Thoughts
          </Typography>}
        <div css={{
        "display": "flex",
        "alignItems": "center"
      }} data-testid="icon-wrapper">
          <Icons color={TextIconColor.PRIMARY} icon={isExpanded ? Icon.UP : Icon.DOWN} size={Size.LARGE} />
        </div>
      </div>
      {isExpanded && <>
          <div css={{
        "marginLeft": "1rem",
        "marginRight": "1rem",
        "marginBottom": "-0.5rem",
        "borderBottomWidth": "1px",
        "borderColor": "rgb(255 255 255 / 0.1)"
      }} data-testid="think-header-border" />
          <div css={{
        "paddingLeft": "1rem",
        "paddingRight": "1rem",
        "paddingTop": "18px",
        "paddingBottom": "18px"
      }} data-testid="think-content">
            <Typography color={TextIconColor.SECONDARY} font={TypographyFont.PARAGRAPH} size={TypographySize.MEDIUM} weight={TypographyWeight.NORMAL}>
              {thinkContent}
            </Typography>
          </div>
        </>}
    </div>;
};
export default ThinkContentSection;