import { ORG_PAYMENT_TIER_DISPLAY_NAMES, OrgPaymentTier } from '@kindo/universal';
import { signOut, useSession } from 'next-auth/react';
import { Button, ButtonType, Icon, Icons, Size, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
import { SettingsTab } from '~/components/SettingsModal/SettingsModal.types';
import { LOGIN_ROUTE } from '~/constants';
import { useSettingsModal } from '~/contexts/SettingsModalContext';
import { useAppSelector } from '~/hooks';
import useGetCredits from '~/hooks/useGetOrgCredits';
const ProfileButton: React.FC = () => {
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const userName = user?.name;
  const userEmail = user?.email;
  const {
    orgCredits
  } = useGetCredits();

  // Redux
  const {
    org
  } = useAppSelector(state => state.user);
  const activeTier = org?.paymentTier || OrgPaymentTier.FREE;
  const isEnterprise = activeTier === OrgPaymentTier.ENTERPRISE;
  const {
    openSettingsModal
  } = useSettingsModal();
  return <Dropdown trigger={<div css={{
    "display": "flex",
    "height": "fit-content",
    "width": "fit-content",
    "flexShrink": "0",
    "alignItems": "center",
    "justifyContent": "center",
    "borderRadius": "9999px",
    "padding": "9px",
    "&:hover svg": {
      "--tw-text-opacity": "1",
      "color": "rgb(141 118 255 / var(--tw-text-opacity))"
    }
  }} data-testid="profile-icon-container">
          <Icons color={TextIconColor.PRIMARY} icon={Icon.PROFILE} size={Size.LARGE} />
        </div>}>
      <div css={{
      "display": "flex",
      "width": "max-content",
      "flexDirection": "column",
      "gap": "0.25rem"
    }} data-testid="profile-dropdown-container">
        {/* User Name + Email */}
        <div css={{
        "paddingLeft": "0.75rem",
        "paddingRight": "0.75rem"
      }} data-testid="user-info-wrapper">
          <div css={{
          "display": "flex",
          "flexDirection": "column",
          "borderBottomWidth": "0.063rem",
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        }} data-testid="user-info-container">
            <Typography color={TextIconColor.PRIMARY} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
              {userName || userEmail}
            </Typography>
            {userName && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL} weight={TypographyWeight.LIGHT}>
                {userEmail}
              </Typography>}
          </div>
          {/* Payment Tier + Credits Remaining */}
          <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.25rem",
          "borderBottomWidth": "0.063rem",
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        }} data-testid="account-info-container">
            <div css={{
            "display": "flex",
            "gap": "0.5rem"
          }} data-testid="tier-upgrade-container">
              <div css={{
              "display": "flex",
              "gap": "0.25rem"
            }} data-testid="tier-container">
                <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  Current plan:
                </Typography>
                <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                  {ORG_PAYMENT_TIER_DISPLAY_NAMES[activeTier]}
                </Typography>
              </div>
              {!isEnterprise && <Button label="Upgrade" onClick={() => {
              openSettingsModal(SettingsTab.MANAGE_SUBSCRIPTION);
            }} size={Size.SMALL} type={ButtonType.OUTLINED} />}
            </div>
            {orgCredits !== undefined && <div css={{
            "display": "flex",
            "gap": "0.25rem"
          }} data-testid="account-info-details">
                <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  Credits Remaining:
                </Typography>
                <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                  {/* Show commas */}
                  {orgCredits.toLocaleString()}
                </Typography>
              </div>}
          </div>
        </div>
        {/* Sign Out */}
        <Button label="Sign Out" onClick={() => {
        void signOut({
          callbackUrl: LOGIN_ROUTE
        });
      }} startIcon={Icon.LOGOUT} type={ButtonType.TEXT} />
      </div>
    </Dropdown>;
};
export default ProfileButton;