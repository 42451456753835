import { OrgPaymentTier } from '@kindo/universal';
export const PAYMENT_TIER_COSTS: Record<OrgPaymentTier, number | null> = {
  [OrgPaymentTier.FREE]: 0,
  [OrgPaymentTier.ENTERPRISE]: null,
  [OrgPaymentTier.PRO]: 20
};
export const PAYMENT_TIER_FEATURES: Record<OrgPaymentTier, string[]> = {
  [OrgPaymentTier.FREE]: ['All Integrated AI Models', 'SaaS Integrations (with 50 item cap)', 'Storage Integration (Drive, One Drive, Box)', 'Monthly Credit Rollover', 'Standard Chat Support'],
  [OrgPaymentTier.ENTERPRISE]: ['All Integrated AI Models', 'Customized, Shared Credit Pool', 'SaaS Integrations (unlimited items)', 'Storage Integration (Drive, One Drive, Box)', 'Monthly Credit Rollover', 'Enhanced Support, with Customer Success', 'Org Management, with Agent, Chat Sharing', 'IT Security Access and DLP Controls', 'Enterprise Audit Logs'],
  [OrgPaymentTier.PRO]: ['All Integrated AI Models', '100,000 Credits (per month, with daily refresh)', 'SaaS Integrations (unlimited items)', 'Storage Integration (Drive, One Drive, Box)', 'Monthly Credit Rollover', 'Standard Chat Support']
};