import { RequireOnlyOne } from '@kindo/universal';
import React from 'react';
import { cn } from '../../../utils/tailwind.utils';
import { TextIconColor } from '../constants';
import { Size } from '../constants/size.consts';
import { withLink, withTooltip } from '../hocs';
import { Icon, Icons } from '../Icon';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight, TypographyWrap } from '../Typography';
import { BUTTON_SIZE_ICON_SIZES, BUTTON_SIZE_TEXT_SIZES, ButtonType } from './Button.consts';
import { ButtonSize } from './Button.styles';
import { buttonStyles } from './Button.styles';
const getTextColor = (disabled: boolean) => {
  if (disabled) return TextIconColor.DISABLED;
  return TextIconColor.PRIMARY;
};
interface ButtonCustomProps {
  href: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: ButtonType;
  casing?: TypographyCasing | undefined;
  className?: string | undefined;
  'data-id'?: string | undefined;
  disabled?: boolean | undefined;
  endIcon?: Icon | undefined;
  font?: TypographyFont | undefined;
  fullWidth?: boolean | undefined;
  invertEndIcon?: boolean | undefined;
  loading?: boolean | undefined;
  prefix?: string | undefined;
  size?: ButtonSize | undefined;
  startIcon?: Icon | undefined;
  suffix?: string | undefined;
  textIconColor?: TextIconColor | undefined;
  tooltip?: string | undefined;
  tooltipPlacement?: TooltipPlacement | undefined;
  typographySize?: TypographySize | undefined;
  weight?: TypographyWeight | undefined;
}

// Ensures that either onClick or href is provided
export type ButtonProps = RequireOnlyOne<ButtonCustomProps, 'onClick' | 'href'>;
const Button: React.FC<ButtonProps> = ({
  label,
  type,
  onClick,
  href,
  'data-id': dataId,
  disabled = false,
  endIcon,
  fullWidth = false,
  font = TypographyFont.INTERACTIVE,
  loading = false,
  size = Size.MEDIUM,
  startIcon,
  prefix,
  suffix,
  textIconColor,
  tooltip,
  tooltipPlacement = TooltipPlacement.TOP_START,
  typographySize,
  weight,
  invertEndIcon = false,
  casing = TypographyCasing.UPPERCASE
}) => {
  const iconTextColor = textIconColor ?? getTextColor(disabled);
  const disableOnClick = disabled || loading;
  const buttonWeight = weight || (type === ButtonType.TEXT ? TypographyWeight.SEMI_BOLD : TypographyWeight.MEDIUM);
  const iconSizeValue = BUTTON_SIZE_ICON_SIZES[size] || Size.MEDIUM;
  const buttonTextSize = typographySize || BUTTON_SIZE_TEXT_SIZES[size] || TypographySize.SMALL;
  const buttonClasses = buttonStyles({
    buttonType: type,
    size,
    fullWidth,
    disabled,
    hasHoverBorder: !disabled,
    hasStartIcon: !!startIcon,
    hasEndIcon: !!endIcon
  });
  const buttonElement = <span className={cn(fullWidth && 'w-full')} id="button-wrapper">
      <button className={buttonClasses} data-id={dataId} disabled={disabled} onClick={disableOnClick ? undefined : onClick}>
        {!loading && <>
            {prefix && <Typography casing={casing} color={TextIconColor.HIGHLIGHT} font={font} size={buttonTextSize} weight={buttonWeight}>
                {prefix + ' '}
              </Typography>}
            {startIcon && <Icons color={iconTextColor} icon={startIcon} size={iconSizeValue} />}
            <Typography casing={casing} color={iconTextColor} font={font} size={buttonTextSize} weight={buttonWeight} wrap={TypographyWrap.NO_WRAP}>
              {label}
              {suffix && <Typography casing={casing} color={TextIconColor.SECONDARY} size={buttonTextSize} weight={TypographyWeight.LIGHT}>
                  {' ' + suffix}
                </Typography>}
            </Typography>
            {endIcon && <Icons color={iconTextColor} icon={endIcon} invert={invertEndIcon} size={iconSizeValue} />}
          </>}
        {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
      </button>
    </span>;
  return withLink(withTooltip(buttonElement, {
    content: tooltip,
    placement: tooltipPlacement,
    offset: [0, 8]
  }), {
    href
  });
};
export default Button;