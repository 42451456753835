import _styled from "styled-components";
import { useState } from 'react';
import { ButtonType } from '../Button';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import SelectOptionComponent from './SelectOption';
import { ButtonSize, Icon, Size, TextField, TextFieldType, TextIconColor, ToolTip, TypographySize } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
const TextFieldContainer = _styled.div({
  "padding": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
export interface SelectOptionType<Value extends string | null = string> {
  label: string;
  value: Value;
  description?: string;
  destructive?: boolean;
  disabled?: boolean;
  endIcon?: {
    icon: Icon;
    color?: TextIconColor;
  };
  onClick?: (value: Value) => void;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: TextIconColor;
  };
  suffix?: {
    text: string;
    color?: TextIconColor;
    size?: TypographySize;
  };
  tooltip?: string;
}
export type SelectProps<Value extends string | null = string> = {
  onChange: (value: Value) => void;
  options: SelectOptionType<Value>[];
  placeholderLabel: string;
  value: Value | null;
  allowInput?: boolean;
  buttonSize?: ButtonSize;
  className?: string;
  'data-id'?: string | undefined;
  disabled?: boolean;
  hideChevron?: boolean;
  highlightIfNull?: boolean;
  inputPlaceholder?: string;
  prefix?: string;
  reservedValues?: string[];
  startIcon?: Icon | undefined;
  tooltip?: string | undefined;
  type?: ButtonType;
  typographySize?: TypographySize;
};
const Select = <Value extends string | null = string,>({
  onChange,
  options,
  value,
  placeholderLabel,
  allowInput = false,
  'data-id': dataId,
  disabled,
  hideChevron = false,
  highlightIfNull = false,
  inputPlaceholder,
  startIcon,
  prefix,
  reservedValues,
  type = ButtonType.OUTLINED,
  tooltip,
  buttonSize = Size.MEDIUM,
  typographySize,
  className
}: SelectProps<Value>) => {
  const [inputValue, setInputValue] = useState<string>('');
  const selectedOption = options.find(option => option.value === value);

  // If the value was typed as the input value and does not exist as an option,
  // use the typed value as the label
  const label = value && !selectedOption ? value : selectedOption?.label || placeholderLabel;
  const handleClickSelect = (newValue: Value) => {
    setInputValue('');
    onChange(newValue);
  };
  const handleInputOnEnter = () => {
    // If Enter is pressed and new values are allowed, select the input value
    if (allowInput && !reservedValues?.includes(inputValue)) {
      onChange(inputValue as Value);
    }
  };
  const buttonType = highlightIfNull && value === null ? ButtonType.OUTLINED_COLOR : type;
  return <Dropdown
  // The dropdown will automatically close when a SelectOption is clicked
  // because SelectOption uses role="menuitem" and closeOnClick is true
  closeOnClick disabled={!!disabled} trigger={{
    buttonProps: {
      'data-id': dataId,
      label: (prefix ?? '') + label,
      startIcon,
      tooltip: tooltip ?? '',
      typographySize,
      type: buttonType,
      fullWidth: true,
      className,
      size: buttonSize
    },
    hideChevron
  }}>
      {allowInput && <TextFieldContainer>
          <TextField autoFocus fullWidth onChange={v => setInputValue(v)} onEnter={() => handleInputOnEnter()} placeholder={inputPlaceholder ?? ''} size={TypographySize.SMALL} type={TextFieldType.SIMPLE} value={inputValue} />
        </TextFieldContainer>}
      {options.map(option => <ToolTip content={option.tooltip ?? ''} key={option.value} offset={[0, 8]} placement={TooltipPlacement.RIGHT}>
          <SelectOptionComponent<Value> description={option.description} destructive={option.destructive ?? false} disabled={option.disabled ?? false} endIcon={option.endIcon} isSelected={option.value === value} key={option.value} label={option.label} onClick={option.onClick ?? handleClickSelect} prefix={option.prefix ?? ''} startIcon={option.startIcon} suffix={option.suffix ?? {
        text: ''
      }} value={option.value} />
        </ToolTip>)}
    </Dropdown>;
};
export default Select;