import { useState } from 'react';
import { UserGroupSettings } from './UserGroupSettings';
import UserGroupsReference from './UserGroupsReference';
import { AllUserGroupsTable, MyUserGroupsTable } from './UserGroupsTable';
import PaywallText from '~/components/shared/PaywallText/PaywallText';
import { useCanAccessSecuritySettings } from '~/hooks';
const UserGroups: React.FC = () => {
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<string | null>(null);

  // Custom hooks
  const {
    userCanManageOrganization,
    userCanAccessSecuritySettings,
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const handleSelectUserGroupId = (groupId: string | null) => {
    setSelectedUserGroupId(groupId);
  };
  if (selectedUserGroupId) {
    return <UserGroupSettings handleSelectUserGroupId={handleSelectUserGroupId} userGroupId={selectedUserGroupId} />;
  }
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "3rem"
  }} data-testid="user-groups-container">
      {!orgCanAccessSecuritySettings && <PaywallText />}
      {userCanManageOrganization && <AllUserGroupsTable handleSelectUserGroupId={handleSelectUserGroupId} />}
      <MyUserGroupsTable handleSelectUserGroupId={handleSelectUserGroupId} />
      {userCanAccessSecuritySettings && <UserGroupsReference />}
    </div>;
};
export default UserGroups;