import { useState } from 'react';
import { AddModel } from './AddModel';
import { ModelChoice } from './ModelChoice';
const ModelSettings = () => {
  const [isAddingModel, setIsAddingModel] = useState(false);
  const handleShowAddModel = () => {
    setIsAddingModel(true);
  };
  const handleCloseAddModel = () => {
    setIsAddingModel(false);
  };
  return <div css={{
    "display": "flex",
    "paddingLeft": "0.25rem",
    "paddingRight": "0.25rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem"
  }} data-testid="model-settings-container">
      {!isAddingModel && <ModelChoice onShowAddModel={handleShowAddModel} />}
      {isAddingModel && <AddModel onCloseAddModel={handleCloseAddModel} />}
    </div>;
};
export default ModelSettings;