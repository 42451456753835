import { TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES, TicketingIntegrationTicketTriggerConditionField, TicketingIntegrationTicketTriggerConditionValue, TicketingPriority, TicketingStatus, WorkflowTriggerConditionType } from '@kindo/universal';
import React, { useState } from 'react';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Select, TextField, TextFieldType, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize } from '~/components/core';
import { getFieldPlaceholder, TICKETING_INTEGRATION_TICKET_TRIGGER_CONDITION_FIELD_ORDER } from '~/constants';
import { useAppDispatch } from '~/hooks';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { WorkflowTriggerConditionBuilder, WorkflowTriggerConditionBuilderDraft } from '~/types';

// TODO: Generalize this component to show conditions for other types of Integrations
// besides ticketing
const CONDITION_FIELD_SELECT_OPTIONS = {
  [TicketingIntegrationTicketTriggerConditionField.PRIORITY]: {
    options: Object.values(TicketingPriority),
    placeholder: 'Select Priority'
  },
  [TicketingIntegrationTicketTriggerConditionField.STATUS]: {
    options: Object.values(TicketingStatus),
    placeholder: 'Select Status'
  }
} as const;
const InputRowLabel = (label: string) => <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.SMALL}>
    {label}
  </Typography>;
interface WorkflowTriggerConditionEditorProps {
  activeCondition: WorkflowTriggerConditionBuilderDraft;
  isSaving: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onSaveCondition: (condition: WorkflowTriggerConditionBuilderDraft) => Promise<void>;
  savedCondition: WorkflowTriggerConditionBuilder | null;
}
const WorkflowTriggerConditionEditor: React.FC<WorkflowTriggerConditionEditorProps> = ({
  activeCondition,
  savedCondition,
  isSaving,
  onCancel,
  onDelete,
  onSaveCondition
}) => {
  const [editedCondition, setEditedCondition] = useState(activeCondition);
  const dispatch = useAppDispatch();
  const updateConditionState = (updatedCondition: WorkflowTriggerConditionBuilderDraft) => {
    setEditedCondition(updatedCondition);
    dispatch(triggerActions.updateActiveCondition(updatedCondition));
  };
  const handleFieldChange = (fieldValue: TicketingIntegrationTicketTriggerConditionField) => {
    // If the field is changed, clear the value,
    // but if it's being set for the first time, don't clear the value
    const shouldClearValue = !!editedCondition.field;
    updateConditionState({
      ...editedCondition,
      field: fieldValue,
      value: shouldClearValue ? '' : editedCondition.value
    });
  };
  const handleTypeChange = (value: WorkflowTriggerConditionType) => {
    updateConditionState({
      ...editedCondition,
      type: value
    });
  };
  const handleValueChange = (value: TicketingIntegrationTicketTriggerConditionValue<NonNullable<typeof editedCondition.field>>) => {
    updateConditionState({
      ...editedCondition,
      value
    });
  };
  const isConditionIncomplete = !editedCondition.field || !editedCondition.type || !editedCondition.value || !editedCondition.value.trim();
  const isConditionSameAsSaved = !!savedCondition && savedCondition.field === editedCondition.field && savedCondition.type === editedCondition.type && savedCondition.value.trim() === editedCondition.value.trim();
  const isSaveDisabled = isConditionIncomplete || isConditionSameAsSaved;
  const handleSave = async () => {
    if (!isSaveDisabled) {
      await onSaveCondition(editedCondition);
    }
  };
  const renderConditionSelect = <Value extends string,>({
    onChange,
    options,
    placeholder,
    value
  }: {
    onChange: (value: Value) => void;
    options: {
      label: string;
      value: Value;
    }[];
    placeholder: string;
    value: Value | null;
  }) => <Select highlightIfNull onChange={onChange} options={options} placeholderLabel={placeholder} typographySize={TypographySize.X_SMALL} value={value} />;
  const renderTriggerConditionTypeAndValue = () => {
    const fieldConfig = CONDITION_FIELD_SELECT_OPTIONS[editedCondition.field as keyof typeof CONDITION_FIELD_SELECT_OPTIONS];
    const conditionTypeOptions = fieldConfig ? Object.values(WorkflowTriggerConditionType).filter(type => type === WorkflowTriggerConditionType.EQUALS || type === WorkflowTriggerConditionType.NOT_EQUALS) : Object.values(WorkflowTriggerConditionType);
    return <>
        <div css={{
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "0.25rem",
        "paddingRight": "0.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }} data-testid="trigger-input-row-conditional">
          {InputRowLabel('Conditional')}
          {renderConditionSelect({
          onChange: handleTypeChange,
          options: conditionTypeOptions.map(type => ({
            value: type,
            label: type
          })),
          placeholder: 'Condition type',
          value: editedCondition.type ?? null
        })}
        </div>
        <div css={{
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "0.25rem",
        "paddingRight": "0.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }} data-testid="trigger-input-row-value">
          {InputRowLabel('Value')}
          {fieldConfig ? renderConditionSelect({
          onChange: handleValueChange,
          options: fieldConfig.options.map(optionValue => ({
            value: optionValue,
            label: optionValue
          })),
          placeholder: fieldConfig.placeholder,
          value: editedCondition.value
        }) : <TextField iconColor={TextIconColor.HIGHLIGHT} onChange={handleValueChange} placeholder={getFieldPlaceholder(editedCondition.field)} size={TypographySize.SMALL} startIcon={editedCondition.value ? Icon.CHECK : undefined} type={!editedCondition.value ? TextFieldType.HIGHLIGHT : TextFieldType.DEFAULT} value={editedCondition.value} width={{
          "width": "500px"
        }} />}
        </div>
      </>;
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "gap": "1.5rem",
    "paddingBottom": "1.5rem",
    "paddingTop": "0.25rem"
  }} data-testid="trigger-condition-edit-container">
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexGrow": "1",
      "flexDirection": "column",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": "0",
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "borderColor": "rgb(255 255 255 / 0.2)"
      },
      "borderRadius": "0.5rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))",
      "paddingLeft": "1rem",
      "paddingRight": "1rem"
    }} data-testid="trigger-inputs-container">
        <div css={{
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "0.25rem",
        "paddingRight": "0.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }} data-testid="trigger-input-row-field">
          {InputRowLabel('Field')}
          {renderConditionSelect({
          onChange: handleFieldChange,
          options: TICKETING_INTEGRATION_TICKET_TRIGGER_CONDITION_FIELD_ORDER.filter(field => field !== TicketingIntegrationTicketTriggerConditionField.PRIORITY && field !== TicketingIntegrationTicketTriggerConditionField.STATUS).map(field => ({
            value: field,
            label: TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES[field]
          })),
          placeholder: 'Field',
          value: editedCondition.field ?? null
        })}
        </div>
        {renderTriggerConditionTypeAndValue()}
      </div>
      <div css={{
      "display": "flex",
      "width": "100%",
      "justifyContent": "space-between",
      "gap": "0.25rem"
    }} data-testid="trigger-condition-buttons-container">
        <IconButton color={TextIconColor.PRIMARY} icon={Icon.TRASH} onClick={onDelete} type={IconButtonType.OUTLINED} />
        <div css={{
        "display": "flex"
      }} data-testid="trigger-condition-save-cancel-buttons-container">
          <Button label="Cancel" onClick={onCancel} type={ButtonType.TEXT} />
          <Button disabled={isSaveDisabled} label="Save Condition" loading={isSaving} onClick={handleSave} type={ButtonType.SOLID_COLOR} />
        </div>
      </div>
    </div>;
};
export default WorkflowTriggerConditionEditor;