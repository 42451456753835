import { WorkflowTriggerOperator } from '@kindo/universal';
import React from 'react';
import { SegmentedControl } from '~/components/core';
import { SegmentedControlType } from '~/components/core/SegmentedControl/SegmentedControl.consts';
interface WorkflowTriggerConditionOperatorSelectProps {
  operator: WorkflowTriggerOperator;
  updateOperator: (operator: WorkflowTriggerOperator) => void;
}
const WorkflowTriggerConditionOperatorSelect: React.FC<WorkflowTriggerConditionOperatorSelectProps> = ({
  operator,
  updateOperator
}) => <SegmentedControl onClick={value => updateOperator(value)} segmentOptions={Object.values(WorkflowTriggerOperator).map(triggerOperator => ({
  value: triggerOperator,
  label: triggerOperator === WorkflowTriggerOperator.AND ? 'All conditions must be true' : 'At least one condition must be true'
}))} type={SegmentedControlType.SOLID} value={operator} />;
export default WorkflowTriggerConditionOperatorSelect;