import { INTEGRATION_DISPLAY_NAMES, WorkflowInputType, WorkflowTriggerConditionType, WorkflowTriggerOperator } from '@kindo/universal';
import { WorkflowTrigger, WorkflowTriggerBuilder } from '~/types';
export const WORKFLOW_TRIGGER_CONDITION_TYPE_DISPLAY_NAMES: Record<WorkflowTriggerConditionType, string> = {
  [WorkflowTriggerConditionType.EQUALS]: 'Equals',
  [WorkflowTriggerConditionType.NOT_EQUALS]: 'Not Equals',
  [WorkflowTriggerConditionType.CONTAINS]: 'Contains',
  [WorkflowTriggerConditionType.NOT_CONTAINS]: 'Does Not Contain',
  [WorkflowTriggerConditionType.REGEX]: 'Matches Regex'
};
export const getTriggerExplanation = (trigger: WorkflowTrigger | WorkflowTriggerBuilder) => {
  if (!trigger.integrationName) {
    return null;
  }
  switch (trigger.integrationInputType) {
    case WorkflowInputType.TICKETING_INTEGRATION_TICKET:
      {
        const operatorText = trigger.operator === WorkflowTriggerOperator.AND ? 'all of the' : 'one of the';
        if (!trigger.conditions.length) {
          return <>
            {`Triggers this agent when any ${INTEGRATION_DISPLAY_NAMES[trigger.integrationName]} ticket is created.`}
            <br />
            Add conditions to specify which tickets activate the trigger.
          </>;
        }
        if (trigger.conditions.length === 1) {
          return <>
            {`Triggers this agent when a ${INTEGRATION_DISPLAY_NAMES[trigger.integrationName]} ticket is created that`}
            <br />
            matches the following condition:
          </>;
        }
        return <>
          {`Triggers this agent when a ${INTEGRATION_DISPLAY_NAMES[trigger.integrationName]} ticket is created that`}
          <br />
          matches {operatorText} following conditions:
        </>;
      }
    // TODO: Add other integration input types
    default:
      return null;
  }
};