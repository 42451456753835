import { FeatureFlags, Integration, INTEGRATION_DISPLAY_NAMES, isBaseDisplayedSupportedIntegrationAndCategory, isSupportedIntegration, isSupportedIntegrationAndCategory, OrgPaymentTier } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs/client';
import { useEffect, useState } from 'react';
import SettingsSection from '../SettingsSection';
import { AvailableIntegrationWithDisplayName, ConnectedIntegrationWithDisplayName, isAvailableIntegration, isConnectedIntegration } from './IntegrationSettings.types';
import { IntegrationsTable } from './IntegrationsTable';
import { CalloutType } from '~/components/core';
import Markdown from '~/components/Markdown/Markdown';
import { Filters, IntegrationFilter } from '~/components/shared';
import type { Filter, FiltersValues } from '~/components/shared/Filters/Filters';
import { getIntegrationCategoryLabel } from '~/constants/integrations.consts';
import { useAppDispatch, useAppSelector, useIntegrationConnect } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { trpc } from '~/trpc';
const IntegrationsTab = () => {
  const [connectedIntegrations, setConnectedIntegrations] = useState<ConnectedIntegrationWithDisplayName[]>([]);
  const [availableIntegrations, setAvailableIntegrations] = useState<AvailableIntegrationWithDisplayName[]>([]);
  const [filters, setFilters] = useState<FiltersValues<IntegrationFilter>>({
    [IntegrationFilter.CATEGORY]: undefined
  });
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useAppDispatch();
  const {
    connect,
    refresh
  } = useIntegrationConnect();
  const {
    org
  } = useAppSelector(state => state.user);
  const isFreeTier = org?.paymentTier === OrgPaymentTier.FREE;

  // Fetch connected merge accounts and available integrations
  // IMPORTANT: Cannot use ReactQuery hooks,
  // as it will cause infinite re-renders with useMergeIntegrationPopUp
  // If you change it to use hooks, ensure that it does not cause infinite re-renders
  const fetchIntegrationData = () => {
    const fetchConnectedIntegrations = async () => {
      const connectedIntegrationsRes = await trpc.integrations.listConnectedIntegrations.query();
      const transformedConnectedIntegrations = connectedIntegrationsRes.map(connectedIntegration => ({
        ...connectedIntegration,
        displayName: INTEGRATION_DISPLAY_NAMES[connectedIntegration.integrationName]
      }));
      setConnectedIntegrations(transformedConnectedIntegrations ?? []);
    };
    const fetchAvailableIntegrations = async () => {
      const availableIntegrationsRes = await trpc.integrations.listAvailableIntegrations.query();
      const supportedIntegrations = availableIntegrationsRes.filter(integration => isSupportedIntegration(integration.integrationName));
      const transformedAvailableIntegrations = supportedIntegrations.map(availableIntegration => ({
        ...availableIntegration,
        displayName: INTEGRATION_DISPLAY_NAMES[availableIntegration.integrationName]
      }));
      setAvailableIntegrations(transformedAvailableIntegrations.sort((a, b) => a.displayName.localeCompare(b.displayName)));
    };
    void fetchConnectedIntegrations();
    void fetchAvailableIntegrations();
  };
  const openDeleteIntegrationModal = (integrationId: string, integrationName: string) => dispatch(modalActions.openModal({
    type: Modal.DELETE_INTEGRATION,
    integrationId,
    integrationName,
    onSuccess: fetchIntegrationData
  }));
  useEffect(() => {
    void fetchIntegrationData();
  }, [refresh]);
  const availableIntegrationCategories = [...new Set(availableIntegrations.flatMap(integration => integration.integrationCategory))];
  const canAccessSlackIntegration = useFlag(FeatureFlags.SLACK_INTEGRATION);
  const canAccessFeatureFlaggedIntegrations = useFlag(FeatureFlags.CONNECTABLE_INTEGRATIONS);
  const isConnectableIntegration = canAccessFeatureFlaggedIntegrations ? isSupportedIntegrationAndCategory // Supported includes base and feature flag integrations
  : isBaseDisplayedSupportedIntegrationAndCategory;
  const filteredAvailableIntegrations = availableIntegrations.filter(integration => integration.enabled).filter(integration => !filters[IntegrationFilter.CATEGORY] || integration.integrationCategory === filters[IntegrationFilter.CATEGORY]).filter(integration => !searchFilter || integration.displayName.toLowerCase().includes(searchFilter.toLowerCase())).filter(integration => !connectedIntegrations.some(account => account.integrationName === integration.integrationName)).filter(integration => !(integration.integrationName === Integration.SLACK && !canAccessSlackIntegration))
  // Sort integrations: connectable ones first, then others
  .sort((a, b) => +isConnectableIntegration(b.integrationName, b.integrationCategory) - +isConnectableIntegration(a.integrationName, a.integrationCategory));
  const filteredConnectedIntegrations = connectedIntegrations.filter(account => !filters[IntegrationFilter.CATEGORY] || account.integrationCategory === filters[IntegrationFilter.CATEGORY]).filter(account => !searchFilter || account.displayName.toLowerCase().includes(searchFilter.toLowerCase()));
  const categoryFilterOptions = availableIntegrationCategories.map(category => ({
    label: getIntegrationCategoryLabel(category),
    value: category
  }));
  const filterConfigs: Filter<IntegrationFilter>[] = [{
    defaultLabel: 'All Categories',
    filterKey: IntegrationFilter.CATEGORY,
    options: categoryFilterOptions,
    placeholder: 'Category',
    prefix: 'Category: '
  }];
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1.5rem"
  }} data-testid="integrations-tab">
      <SettingsSection title="Integrations">
        <Filters<IntegrationFilter> filters={filterConfigs} filtersValues={filters} searchPlaceholder="Search for an app" searchValue={searchFilter} setFilters={setFilters} setSearchValue={setSearchFilter} />
      </SettingsSection>
      <div css={{
      "display": "flex",
      "borderRadius": "0.5rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "padding": "2rem"
    }} data-testid="connected-integrations-container">
        <SettingsSection title="Connected Integrations">
          <IntegrationsTable integrations={filteredConnectedIntegrations} onAction={integration => {
          if (!isConnectedIntegration(integration)) {
            console.error('Expected connected integration');
            return;
          }
          openDeleteIntegrationModal(integration.id, integration.integrationName);
        }} />
        </SettingsSection>
      </div>
      <div css={{
      "display": "flex",
      "borderRadius": "0.5rem",
      "padding": "2rem"
    }} data-testid="available-integrations-container">
        <SettingsSection title="Available Integrations">
          <Markdown calloutType={CalloutType.INFO}>
            {'> Notice: For new Google Drive integration connections, please use the "Read only for admins" option. The other options are temporarily unavailable.'}
          </Markdown>
          <IntegrationsTable disableConnectButton={isFreeTier} integrations={filteredAvailableIntegrations} onAction={async integration => {
          if (!isAvailableIntegration(integration)) {
            console.error('Expected available integration');
            return;
          }
          await connect(integration);
        }} />
        </SettingsSection>
      </div>
    </div>;
};
export default IntegrationsTab;