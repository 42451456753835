export enum TranscriptionModel {
  INTERNAL_TRANSCRIPTION_GENERATOR = 'INTERNAL_TRANSCRIPTION_GENERATOR'
}

export function isTranscriptionModel(
  value: string
): value is TranscriptionModel {
  return Object.values(TranscriptionModel).includes(
    value as TranscriptionModel
  );
}
