import _styled from "styled-components";
import { AuditEventType, insertIf, ORG_SETTING_DISPLAY_NAMES, typedEntries } from '@kindo/universal';
import { AUDIT_LOG_EVENT_TYPE_DESCRIPTIONS } from './AuditLog.consts';
import { AuditEventSummaryRow, AuditLogLinkType } from './AuditLog.types';
import { JsonViewer } from '~/components/shared/JsonViewer';
import { getWorkflowBuilderRoute, getWorkstationRoute } from '~/constants';
import { stringToCapitalCase } from '~/utils';
const AuditLogCodeBlock = _styled.code({
  "whiteSpace": "pre-wrap",
  "overflowWrap": "break-word",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem"
});

// All the log formulating functions are subject to change as we determine
// the exact phrases we want to use

// TODO: Use the correct interface for auditEvent
// eslint-disable-next-line import/prefer-default-export
export function getAuditEventDisplayMessage(data: {
  type: string;
  // TODO: Type userMetadata better after zod schema is established
  userMetadata: Record<string, any> | null;
}) {
  const {
    type,
    userMetadata
  } = data;
  const displayedUsername = userMetadata ? userMetadata.name || userMetadata.email : '';

  // TODO: Remove type casting after zod schema is established
  const eventTypeDisplayName = AUDIT_LOG_EVENT_TYPE_DESCRIPTIONS[type as AuditEventType] || type;
  switch (type) {
    case AuditEventType.DLP_FILTER_APPLIED:
      return `DLP filter was applied to ${displayedUsername ? displayedUsername + "'s" : ''} message`;
    case AuditEventType.LLM_REQUEST_SUCCEEDED:
      return `${displayedUsername ? displayedUsername + "'s " : ''}LLM request succeeded`;
    case AuditEventType.LLM_REQUEST_FAILED:
      return `${displayedUsername ? displayedUsername + "'s " : ''}LLM request failed`;
    default:
      return `${displayedUsername} ${eventTypeDisplayName}`;
  }
}

// TODO: Type auditEvent better after zod schema is established
export function getAuditEventSummary<T extends AuditEventType>({
  eventMetadata,
  userMetadata,
  modelIdentifierToDisplayName
}: {
  eventMetadata: Record<string, any>;
  modelIdentifierToDisplayName: Record<string, string>;
  type: T;
  userMetadata: Record<string, any> | null;
}): AuditEventSummaryRow[] {
  /**
   * Note that all the values should be using optional chaining,
   * because the second parameter of insertIf is evaluated
   * at the time of the function call (i.e. even if the value
   * is null or undefined, JS will still evaluate the value).
   */

  const settingsArray = eventMetadata.settings ? typedEntries(eventMetadata.settings).map(([settingKey, settingValue]) => ({
    key: 'Setting',
    value: `${ORG_SETTING_DISPLAY_NAMES[settingKey as keyof typeof ORG_SETTING_DISPLAY_NAMES]} changed from ${settingValue.previousValue} to ${settingValue.newValue}`
  })) : [];
  const getResourceMetadata = () => {
    if (!eventMetadata.resource) {
      return null;
    }
    if (typeof eventMetadata.resource === 'string' && eventMetadata.resource !== null) {
      return eventMetadata.resource;
    }
    if (eventMetadata.resource.model) {
      return eventMetadata.resource.model;
    }
    if (eventMetadata.resource.provider) {
      return eventMetadata.resource.provider;
    }
    if (eventMetadata.resource.integration) {
      return eventMetadata.resource.integration;
    }
    if (eventMetadata.resource.value) {
      return eventMetadata.resource.value;
    }
    return null;
  };
  const resourceMetadata = getResourceMetadata();
  return [...insertIf(!!eventMetadata.userObjectiveDescription, {
    key: 'API Description',
    value: eventMetadata.userObjectiveDescription
  }, {
    key: 'Generated API Request',
    value: <JsonViewer data={eventMetadata.generatedApiValues} />
  }), ...insertIf(!!userMetadata, {
    key: 'User',
    value: userMetadata?.name ?? ''
  }), ...insertIf(!!eventMetadata.request?.method || !!eventMetadata.request?.endpoint, {
    key: 'API Request',
    value: `${eventMetadata.request?.method} ${eventMetadata.request?.endpoint}`
  }), ...insertIf(!!eventMetadata.secret, {
    key: 'Secret ID',
    value: eventMetadata.secret?.id
  }), ...insertIf(!!eventMetadata.secret?.label, {
    key: 'Secret Label',
    value: eventMetadata.secret?.label
  }), ...insertIf(eventMetadata.chat, {
    key: 'Chat ID',
    link: {
      label: eventMetadata.chat?.id,
      route: getWorkstationRoute({
        chatId: eventMetadata.chat?.id,
        workflowId: eventMetadata.chat?.workflow?.id
      }),
      type: AuditLogLinkType.CHAT
    }
  }), ...insertIf(!!eventMetadata.chat?.title, {
    key: 'Chat Title',
    value: eventMetadata.chat?.title
  }), ...insertIf(!!eventMetadata?.workflow?.id, {
    key: 'Agent ID',
    link: {
      label: eventMetadata?.workflow?.id,
      route: getWorkflowBuilderRoute(eventMetadata?.workflow?.id),
      type: AuditLogLinkType.AGENT_BUILDER
    }
  }), ...insertIf(!!eventMetadata.workflow?.displayName, {
    key: 'Agent Title',
    value: eventMetadata.workflow?.displayName
  }), ...insertIf(eventMetadata.transformedMessage, {
    key: 'Original Message',
    value: eventMetadata.originalMessage
  }, {
    key: 'Transformed Message',
    value: eventMetadata.transformedMessage
  }), ...insertIf(eventMetadata.userMessage && typeof eventMetadata.userMessage?.message === 'string', {
    key: 'User Message',
    value: eventMetadata.userMessage?.message
  }), ...insertIf(eventMetadata.responseMessage && typeof eventMetadata.responseMessage?.message === 'string', {
    key: 'Response Message',
    value: eventMetadata.responseMessage?.message
  }), ...insertIf(eventMetadata.userMessage?.model, {
    key: 'Model',
    value: modelIdentifierToDisplayName[eventMetadata.userMessage?.model]
  }), ...insertIf(eventMetadata.tokenTransactionType, {
    key: 'Token Transaction Type',
    value: eventMetadata.tokenTransactionType
  }), ...insertIf(eventMetadata.creditTransaction?.inputUsage, {
    key: 'Input Kindo Credits Used',
    value: eventMetadata.creditTransaction?.inputUsage
  }), ...insertIf(eventMetadata.creditTransaction?.outputUsage, {
    key: 'Output Kindo Credits Used',
    value: eventMetadata.creditTransaction?.outputUsage
  }), ...insertIf(eventMetadata.apiKey, {
    key: 'API Key Name',
    value: eventMetadata.apiKey?.name
  }), ...insertIf(eventMetadata.apiKey, {
    key: 'API Key Last 4 Characters',
    value: eventMetadata.apiKey?.apiKeyEndingChars
  }), ...insertIf(eventMetadata.settings, ...settingsArray), ...insertIf(eventMetadata.orgRole, {
    key: 'Organization Role',
    value: `Changed from ${stringToCapitalCase(eventMetadata.orgRole?.previousOrgRole ?? 'None')} to ${stringToCapitalCase(eventMetadata.orgRole?.newOrgRole ?? 'None')}`
  }), ...insertIf(eventMetadata.publicRole, {
    key: 'Public Role',
    value: `Changed from ${stringToCapitalCase(eventMetadata.publicRole?.previousPublicRole) ?? 'None'} to ${stringToCapitalCase(eventMetadata.publicRole?.newPublicRole) ?? 'None'}`
  }), ...insertIf(eventMetadata.sharedEmail, ...[{
    key: 'Recipient Email',
    value: eventMetadata.sharedEmail
  }, ...insertIf(eventMetadata.role, {
    key: 'Recipient Role',
    value: stringToCapitalCase(eventMetadata.role)
  })]), ...insertIf(eventMetadata.code, {
    key: 'Code',
    metadataDisplay: <AuditLogCodeBlock>{eventMetadata.code}</AuditLogCodeBlock>
  }), ...insertIf(eventMetadata.codeIndex, {
    key: 'Code Index',
    value: eventMetadata.codeIndex
  }), ...insertIf(eventMetadata.stdout, {
    key: 'Standard Output',
    metadataDisplay: <AuditLogCodeBlock>{eventMetadata.stdout}</AuditLogCodeBlock>
  }), ...insertIf(eventMetadata.stderr, {
    key: 'Standard Error',
    metadataDisplay: <AuditLogCodeBlock>{eventMetadata.stderr}</AuditLogCodeBlock>
  }), ...insertIf(eventMetadata.exception, {
    key: 'Exception',
    value: eventMetadata.exception
  }), ...insertIf(eventMetadata.actionStepType, {
    key: 'Action Step Type',
    value: eventMetadata.actionStepType
  }), ...insertIf(eventMetadata.userGroup, ...[{
    key: 'User Group Id',
    value: eventMetadata.userGroup?.id
  }, {
    key: 'User Group Name',
    value: eventMetadata.userGroup?.name
  }]), ...insertIf(eventMetadata.target, {
    key: 'Target',
    value: eventMetadata.target?.type
  }), ...insertIf(resourceMetadata, {
    key: 'Resource',
    value: resourceMetadata
  }), ...insertIf(eventMetadata.modelDisplayName, {
    key: 'Model',
    value: eventMetadata.modelDisplayName
  }), ...insertIf(eventMetadata.litellmModelName, {
    key: 'API Model Name',
    value: eventMetadata.litellmModelName
  })];
}
export function renderObjectValue(value: any) {
  const valueType = typeof value;
  switch (valueType) {
    case 'boolean':
      return value.toString();
    default:
      return value;
  }
}