import { WorkflowType } from '@kindo/universal';
import WorkflowCatalogueCard from './WorkflowCatalogueCard';
interface WorkflowCatalogueProps {
  selectWorkflowType: (type: WorkflowType) => void;
}
const WorkflowCatalogue = ({
  selectWorkflowType
}: WorkflowCatalogueProps) => <div css={{
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "gap": "1.5rem"
}} data-testid="workflow-builders-container">
    {[WorkflowType.TRIGGERED, WorkflowType.MANUAL, WorkflowType.CHATBOT].map((type: WorkflowType) => <WorkflowCatalogueCard key={type} onBuildClick={selectWorkflowType} workflowType={type} />)}
  </div>;
export default WorkflowCatalogue;