import { DEFAULT_WORKFLOW_NAME } from '@kindo/universal';
import { useRouter } from 'next/router';
import { Button, ButtonType, Icon, IconButton, IconButtonType, TextIconColor, Toggle } from '~/components/core';
import { WORKFLOW_ID_QUERY_PARAM, WORKFLOWS_LIBRARY_ROUTE, WORKSTATION_ROUTE } from '~/constants';
import { ToastType, useActiveBuilderStep, useAppDispatch, useAppSelector, useCanShareWorkflow, useGetWorkflowFromUrlSlug, useKnowledgeStore, useToast, useWorkflowConfig, useWorkflowTrigger } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
import { checkIntegrationRequirements } from '~/utils/workflow.utils';
type WorkflowBuilderHeaderProps = {
  hasPlaceholderSecrets: boolean;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
};
const WorkflowBuilderHeader: React.FC<WorkflowBuilderHeaderProps> = ({
  refetchWorkflow,
  workflow,
  hasPlaceholderSecrets
}) => {
  const {
    id: workflowId,
    displayName: workflowName,
    steps: workflowSteps,
    triggers
  } = workflow;
  const router = useRouter();
  const {
    enqueueToast
  } = useToast();

  // Get user's connected integrations
  const {
    data: connectedIntegrations,
    isLoading: isLoadingIntegrations
  } = nextTrpc.integrations.listConnectedIntegrations.useQuery();

  // Redux
  const {
    activeStep,
    knowledgeStore,
    workflowConfig
  } = useAppSelector(state => state.workflowBuilder);
  const {
    activeTrigger
  } = useAppSelector(state => state.workflowBuilderTrigger);
  const workflowTrigger = activeTrigger ?? triggers[0];
  const dispatch = useAppDispatch();
  const openDeleteWorkflowModal = () => dispatch(modalActions.openModal({
    type: Modal.DELETE_WORKFLOW,
    workflowId,
    workflowTitle: workflowName || DEFAULT_WORKFLOW_NAME,
    redirectRoute: WORKFLOWS_LIBRARY_ROUTE
  }));
  const openShareWorkflowModal = () => dispatch(modalActions.openModal({
    type: Modal.SHARE_WORKFLOW,
    workflowId
  }));

  // Custom hooks
  const {
    canSave: canSaveActiveStep,
    isSavingActiveStep,
    saveActiveStep
  } = useActiveBuilderStep({
    workflow,
    refetchWorkflow,
    activeStep
  });
  const {
    canShareWorkflow
  } = useCanShareWorkflow(workflowId);
  const {
    canSaveWorkflowConfig,
    isSavingWorkflowConfig,
    saveWorkflowConfig
  } = useWorkflowConfig({
    workflowConfig,
    refetchWorkflow,
    workflowId: workflow.id
  });
  const {
    canSaveKnowledgeStore,
    isSavingKnowledgeStore,
    saveKnowledgeStoreContent
  } = useKnowledgeStore({
    knowledgeStore,
    refetchWorkflow,
    workflow
  });
  const {
    saveTrigger,
    isSavingTrigger
  } = useWorkflowTrigger({
    workflowId,
    refetchWorkflow
  });

  // Check if the user has all required integrations using the centralized utility function
  const hasRequiredIntegrations = checkIntegrationRequirements(workflow, connectedIntegrations, isLoadingIntegrations);

  // Get duplicate tooltip text
  const getDuplicateTooltipText = () => {
    if (isLoadingIntegrations) return 'Loading integration information...';
    if (!hasRequiredIntegrations) {
      return "You don't have all the integrations required by this agent. Please connect the missing integrations in your settings.";
    }
    return 'Create a copy of this agent';
  };

  // Show the duplicate workflow modal
  const handleDuplicateButtonClick = () => {
    dispatch(modalActions.openModal({
      type: Modal.DUPLICATE_WORKFLOW,
      workflowId,
      workflowTitle: workflowName || DEFAULT_WORKFLOW_NAME
    }));
  };
  const isTriggeredWorkflow = triggers.length > 0 || activeTrigger !== null;
  const canRunWorkflow = (workflowSteps.length > 0 || canSaveActiveStep) && !hasPlaceholderSecrets;
  const willSaveActiveStepBeforeRunning = canRunWorkflow && canSaveActiveStep;
  const willSaveWorkflowConfigBeforeRunning = canSaveWorkflowConfig;
  const willSaveKnowledgeStoreBeforeRunning = canSaveKnowledgeStore;
  const willSaveBeforeRunning = willSaveActiveStepBeforeRunning || willSaveWorkflowConfigBeforeRunning || willSaveKnowledgeStoreBeforeRunning;

  // Header buttons
  const handleSaveWorkflow = async () => {
    if (willSaveActiveStepBeforeRunning) {
      await saveActiveStep();
    }
    if (willSaveKnowledgeStoreBeforeRunning) {
      await saveKnowledgeStoreContent();
    }
    if (willSaveWorkflowConfigBeforeRunning) {
      await saveWorkflowConfig();
    }
  };
  const handleRunWorkflow = async () => {
    if (willSaveBeforeRunning) {
      await handleSaveWorkflow();
    }
    await refetchWorkflow();
    void router.push({
      pathname: WORKSTATION_ROUTE,
      query: {
        [WORKFLOW_ID_QUERY_PARAM]: workflowId
      }
    });
  };

  // If we don't need to save, we can just navigate to the workstation as a link
  // If we do need to save, we need to run the save mutation and then navigate (so use onClick)
  const runButtonOnClickOrHref: {
    onClick: () => Promise<void>;
  } | {
    href: string;
  } = willSaveBeforeRunning ? {
    onClick: handleRunWorkflow
  } : {
    href: `${WORKSTATION_ROUTE}?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}`
  };
  const handleTriggerEnabledToggle = async (enabled: boolean) => {
    if (!activeTrigger) {
      console.error('Failed to save condition: No active trigger found');
      enqueueToast({
        message: 'Failed to save condition.',
        type: ToastType.ERROR
      });
      return;
    }
    await saveTrigger({
      ...activeTrigger,
      enabled
    });
    dispatch(triggerActions.updateTriggerEnabled(enabled));
  };

  // Wrapper for the toggle handler that doesn't return a promise
  const onToggleTrigger = () => {
    if (workflowTrigger) {
      void handleTriggerEnabledToggle(!workflowTrigger.enabled);
    }
  };
  return <div css={{
    "display": "flex",
    "width": "100%",
    "gap": "0.5rem"
  }}>
      <div css={{
      "display": "flex",
      "width": "100%",
      "alignItems": "center",
      "justifyContent": "flex-end",
      "gap": "0.5rem"
    }}>
        {/* Duplicate */}
        <IconButton color={TextIconColor.PRIMARY} data-id="workflow_builder_duplicate" disabled={!hasRequiredIntegrations} icon={Icon.COPY} onClick={handleDuplicateButtonClick} tooltip={getDuplicateTooltipText()} type={IconButtonType.SOLID} />
        {/* Trash */}
        <IconButton color={TextIconColor.PRIMARY} data-id="workflow_builder_delete" icon={Icon.TRASH} onClick={openDeleteWorkflowModal} type={IconButtonType.SOLID} />
        {/* Share */}
        {canShareWorkflow && <IconButton data-id="workflow_builder_share" icon={Icon.SHARE} onClick={openShareWorkflowModal} type={IconButtonType.SOLID} />}
        {/* if triggered workflow, show control toggle for trigger */}
        {isTriggeredWorkflow && workflowTrigger ? <Toggle checked={workflowTrigger.enabled} loading={isSavingTrigger} onToggle={onToggleTrigger} /> :
      // if not triggered workflow, show run button
      <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...runButtonOnClickOrHref} data-id="workflow_builder_run" endIcon={Icon.PLAY} label={willSaveBeforeRunning ? 'Save and run' : 'Run'} loading={isSavingActiveStep || isSavingKnowledgeStore || isSavingWorkflowConfig} type={willSaveBeforeRunning ? ButtonType.SOLID_COLOR : ButtonType.SOLID} />}
      </div>
    </div>;
};
export default WorkflowBuilderHeader;