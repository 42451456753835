import _styled from "styled-components";
import { ApiActionHeaderValue, isSecretHeaderValue, isTextHeaderValue, Secret } from '@kindo/universal';
import { Button, ButtonType, Select, Size, TextField, TextFieldType, Typography, TypographySize } from '../../../../core';
interface WorkflowApiActionHeaderValueInputProps {
  disabled: boolean;
  onChange: (value: ApiActionHeaderValue) => void;
  secrets: Pick<Secret, 'id' | 'label'>[];
  value: ApiActionHeaderValue | undefined;
  placeholder?: string;
  prefix?: string;
}
const HeaderValueContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "1rem"
});
const ButtonGroup = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});
enum HeaderValueType {
  SECRET = 'SECRET',
  TEXT = 'TEXT',
}

// Split from enum to avoid reliance on enum order
const HEADER_VALUE_TYPES = [HeaderValueType.TEXT, HeaderValueType.SECRET];
const WorkflowApiActionHeaderValueInput = ({
  disabled,
  value,
  onChange,
  secrets = [],
  placeholder = 'Value',
  prefix = ''
}: WorkflowApiActionHeaderValueInputProps) => {
  const isSecret = isSecretHeaderValue(value);
  const valueType = isSecret ? HeaderValueType.SECRET : HeaderValueType.TEXT;
  const handleValueTypeChange = (newType: HeaderValueType) => {
    if (newType === HeaderValueType.TEXT) {
      onChange({
        text: ''
      });
    } else {
      onChange({
        secretId: ''
      });
    }
  };
  const handleTextChange = (newValue: string) => {
    onChange({
      text: newValue
    });
  };
  const handleSecretChange = (newValue: string) => {
    onChange({
      secretId: newValue
    });
  };
  const getSecretLabel = () => {
    if (disabled) {
      return "CREATOR'S SECRET";
    }
    if (isSecretHeaderValue(value)) {
      return value.secretId;
    }
    return '';
  };
  return <HeaderValueContainer>
      <ButtonGroup>
        {HEADER_VALUE_TYPES.map(type => <Button disabled={disabled} key={type} label={type} onClick={() => handleValueTypeChange(type)} size={Size.SMALL} type={valueType === type ? ButtonType.SOLID : ButtonType.OUTLINED} />)}
      </ButtonGroup>
      {valueType === HeaderValueType.TEXT ? <TextField disabled={disabled} fullWidth onChange={handleTextChange} placeholder={placeholder} prefix={prefix} type={!isSecret && isTextHeaderValue(value) ? TextFieldType.DEFAULT : TextFieldType.HIGHLIGHT} value={isTextHeaderValue(value) ? value.text : ''} /> : <>
          {prefix && <Typography size={TypographySize.SMALL}>{prefix}</Typography>}
          <Select buttonSize={Size.SMALL} disabled={disabled || !secrets || secrets.length === 0} onChange={handleSecretChange} options={secrets.map(secret => ({
        label: secret.label,
        value: secret.id
      }))} placeholderLabel="Select Secret" tooltip={!disabled && (!secrets || secrets.length === 0) ? 'Create a secret in Settings > Secrets Vault to reference here' : undefined} type={isSecret && value.secretId ? ButtonType.OUTLINED : ButtonType.OUTLINED_COLOR} value={getSecretLabel()} />
        </>}
    </HeaderValueContainer>;
};
export default WorkflowApiActionHeaderValueInput;