import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { useState } from 'react';
import { DlpFiltersConfig } from '../DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '../DlpFiltersConfig/DlpFilters.types';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface OrgBaseDlpFiltersProps {
  canAccessSecuritySettings: boolean;
}
const OrgBaseDlpFiltersConfig: React.FC<OrgBaseDlpFiltersProps> = ({
  canAccessSecuritySettings
}) => {
  // States
  const [updatingFilters, setUpdatingFilters] = useState<DlpFilter[]>([]);

  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listOrgBaseCustomDlpFilters.useQuery();
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getOrgBaseDlpFiltersConfig.useQuery(undefined, {
    select: transformDlpFiltersConfigResponse
  });

  // Mutations
  const createOrgBaseCustomDlpFilter = nextTrpc.customDlpFilters.createOrgBaseCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully created custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const deleteOrgBaseCustomDlpFilterMutation = nextTrpc.customDlpFilters.deleteOrgBaseCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully deleted custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const updateOrgBaseDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.updateOrgBaseDlpFiltersConfig.useMutation({
    onMutate: ({
      filter
    }) => setUpdatingFilters(prev => [...prev, filter]),
    onSettled: (_, __, {
      filter
    }) => {
      refetchDlpFiltersConfig();
      setUpdatingFilters(prev => prev.filter(updatingFilter => updatingFilter !== filter));
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to update data loss prevention filter.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => updateOrgBaseDlpFiltersConfigMutation.mutate({
    filter: dlpFilter,
    policy: newPolicy
  });
  const createOrgCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createOrgBaseCustomDlpFilter.mutate({
      policy,
      type,
      value
    });
  };
  const deleteCustomDlpFilter = (value: string) => {
    deleteOrgBaseCustomDlpFilterMutation.mutate({
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={createOrgCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={deleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} targetResourceLabel="your organization's models that have DLP enabled above, and do not have a custom configuration at the provider or model level" updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} updatingFilters={updatingFilters} />;
};
export default OrgBaseDlpFiltersConfig;