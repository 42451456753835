import { WorkflowType } from '@kindo/universal';
import { useRouter } from 'next/router';
import { Button, ButtonType, Icon, Size, TextField, TextFieldType, TextIconColor, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { WORKFLOW_TYPE_TO_LABEL } from '~/constants';
interface WorkflowViewLayoutProps {
  children: React.ReactElement | React.ReactElement[];
  headerChildren?: React.ReactElement[] | undefined;
  name?: string | undefined;
  onBack?: () => void;
  onNameBlur?: () => void;
  onNameChange?: (title: string) => void;
  workflowType?: WorkflowType | undefined;
}
const WorkflowViewLayout = ({
  children,
  workflowType = WorkflowType.MANUAL,
  onNameChange,
  onNameBlur,
  name,
  onBack,
  headerChildren
}: WorkflowViewLayoutProps) => {
  const router = useRouter();
  const defaultOnBack = () => {
    void router.back();
  };
  return <div css={{
    "display": "flex",
    "height": "100%",
    "flexDirection": "column",
    "gap": "1rem",
    "overflow": "hidden",
    "padding": "1px",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  }} // 1px padding needed to show DarkGlowWorkflowCardContainer border
  data-testid="workflow-view-layout-container">
      <div css={{
      "display": "flex",
      "flexShrink": "0",
      "flexDirection": "column",
      "gap": "0.75rem"
    }} data-testid="header-container">
        <div css={{
        "display": "flex",
        "flexShrink": "0",
        "flexDirection": "row",
        "gap": "0.25rem"
      }} data-testid="header-row-container">
          <Badge active={false} label={WORKFLOW_TYPE_TO_LABEL[workflowType]} />
          <Button label="back" onClick={onBack || defaultOnBack} size={Size.SMALL} startIcon={Icon.CHEVRON_LEFT} textIconColor={TextIconColor.HIGHLIGHT} type={ButtonType.TEXT} />
        </div>
        <div css={{
        "display": "flex",
        "flexDirection": "row",
        "justifyContent": "space-between",
        "gap": "0.75rem"
      }} data-testid="title-and-actions-container">
          <div css={{
          "display": "flex",
          "width": "100%",
          "flexDirection": "row",
          "gap": "0.25rem",
          "borderBottomWidth": "0.5px",
          "borderColor": "rgb(255 255 255 / 0.1)",
          "paddingBottom": "0.25rem"
        }} data-testid="title-container">
            <TextField disabled={!onNameChange} font={TypographyFont.HEADING} fullWidth onBlur={onNameBlur} onChange={onNameChange ?? (() => {})} placeholder={`${WORKFLOW_TYPE_TO_LABEL[workflowType]} Agent`} showEndIcon={false} size={TypographySize.H3} startIcon={onNameChange ? Icon.EDIT : undefined} startIconFocusColor={TextIconColor.HIGHLIGHT} textWeight={TypographyWeight.MEDIUM} type={TextFieldType.SIMPLE} value={name ?? ''} />
          </div>
          <div css={{
          "display": "flex",
          "flexDirection": "row",
          "alignItems": "flex-end",
          "gap": "0.5rem"
        }} data-testid="actions-container">
            {headerChildren}
          </div>
        </div>
      </div>
      {children}
    </div>;
};
export default WorkflowViewLayout;