import { isMobileOnly } from 'react-device-detect';
import { Background } from '../Background';
import { BackgroundVariant } from '../Background/Background';
import ActiveModal from '../modals/ActiveModal/ActiveModal';
import { Nav } from '../Nav';
import useAcceptOrgInvite from '~/hooks/useAcceptOrgInvite';
import useCheckClientVersionOutdated from '~/hooks/useCheckClientVersionOutdated';
import { Toast } from '~/hooks/useToast';
interface LayoutProps {
  children: React.ReactElement;
  backgroundVariant?: BackgroundVariant;
}
const Layout = ({
  children,
  backgroundVariant = 'default'
}: LayoutProps) => {
  // Hooks
  useCheckClientVersionOutdated();
  // Hook that monitors the URL for an org invite and accepts it
  useAcceptOrgInvite();
  return <div css={{
    "display": "flex",
    "height": "100dvh",
    "width": "100vw",
    "overflow": "hidden",
    "padding": "0.5rem"
  }} data-testid="layout-container">
      <div css={{
      "display": "flex",
      "flex": "1 1 0%",
      "overflow": "hidden",
      "borderRadius": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "#6761fa5e"
    }} data-testid="main-container">
        <div css={{
        "display": "flex",
        "flex": "1 1 0%",
        "flexDirection": "column"
      }} data-testid="content-wrapper">
          {!isMobileOnly && <div css={{
          "display": "flex"
        }} data-testid="nav-wrapper">
              <Nav />
              <Toast />
            </div>}
          <Background variant={backgroundVariant} />
          <div css={{
          "display": "flex",
          "flex": "1 1 0%",
          "flexDirection": "column",
          "overflowY": "auto"
        }} data-testid="active-modal-container">
            {children}
            <ActiveModal />
          </div>
        </div>
      </div>
    </div>;
};
export default Layout;