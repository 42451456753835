import { FeatureFlags } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { CodeSandboxSetting } from './CodeSandboxSetting';
import { OrgInfoAndUsers } from './OrgInfoAndUsers';
import { useCanAccessSecuritySettings } from '~/hooks';
const OrgMemberSettings = () => {
  const canUseCodeSandbox = useFlag(FeatureFlags.CODE_SANDBOX);
  const {
    userCanManageOrganization
  } = useCanAccessSecuritySettings();
  const canChangeCodeSandboxSetting = canUseCodeSandbox && userCanManageOrganization;
  return <div css={{
    "marginBottom": "0.5rem",
    "display": "flex",
    "height": "100%",
    "flexDirection": "column",
    "gap": "1rem"
  }} data-testid="org-member-settings-container">
      <OrgInfoAndUsers />
      {canChangeCodeSandboxSetting && <CodeSandboxSetting />}
    </div>;
};
export default OrgMemberSettings;