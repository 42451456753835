import React from 'react';
import WorkflowTriggerCondition from './WorkflowTriggerConditionComponents/WorkflowTriggerCondition';
import { GiantButton, GiantButtonType, Icon } from '~/components/core';
import { ToastType, useAppDispatch, useAppSelector, useGetWorkflowFromUrlSlug, useToast } from '~/hooks';
import useWorkflowTrigger from '~/hooks/useWorkflowTrigger';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { BuilderWorkflowStatus, isWorkflowTriggerConditionBuilder, WorkflowTriggerBuilder, WorkflowTriggerConditionBuilder, WorkflowTriggerConditionBuilderDraft } from '~/types';
interface WorkflowTriggerConditionsListProps {
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflowId: string;
  activeTrigger?: WorkflowTriggerBuilder | undefined;
}
const WorkflowTriggerConditionsList: React.FC<WorkflowTriggerConditionsListProps> = ({
  activeTrigger,
  refetchWorkflow,
  workflowId
}) => {
  // Redux
  const dispatch = useAppDispatch();
  const {
    activeTriggerCondition
  } = useAppSelector(state => state.workflowBuilderTrigger);

  // Hooks
  const {
    saveTrigger,
    isSavingTrigger
  } = useWorkflowTrigger({
    workflowId,
    refetchWorkflow
  });
  const {
    enqueueToast
  } = useToast();
  const handleAddCondition = () => {
    dispatch(triggerActions.createNewTriggerCondition());
  };
  const handleEditCondition = (condition: WorkflowTriggerConditionBuilder) => {
    dispatch(triggerActions.setTriggerConditionActive(condition));
  };
  const handleSaveCondition = async (condition: WorkflowTriggerConditionBuilderDraft) => {
    if (!activeTrigger) {
      console.error('Failed to save condition: No active trigger found');
      enqueueToast({
        message: 'Failed to save condition.',
        type: ToastType.ERROR
      });
      return;
    }
    dispatch(triggerActions.saveOrUpdateTriggerCondition(condition));
    await saveTrigger(activeTrigger);
  };
  const handleCancelEdit = () => {
    dispatch(triggerActions.clearActiveTriggerCondition());
  };
  const handleDeleteCondition = async (condition: WorkflowTriggerConditionBuilder) => {
    // If it's a saved condition, we need to remove it from the trigger
    if (isWorkflowTriggerConditionBuilder(condition) && activeTrigger) {
      // Create updated trigger with condition removed
      const updatedTrigger = {
        ...activeTrigger,
        conditions: activeTrigger.conditions.filter(c => c.index !== condition.index),
        status: BuilderWorkflowStatus.MODIFIED
      };
      try {
        // First save to backend
        await saveTrigger(updatedTrigger);
        // Then update Redux state
        dispatch(triggerActions.removeTriggerCondition(condition));
      } catch (error) {
        console.error('Failed to delete condition:', error);
      }
    }
    dispatch(triggerActions.clearActiveTriggerCondition());
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.5rem"
  }} data-testid="trigger-conditions-container">
      {activeTrigger?.conditions.map(condition => <WorkflowTriggerCondition activeTriggerCondition={activeTriggerCondition} condition={condition} isDeleting={isSavingTrigger} isSaving={isSavingTrigger} key={condition.index} onCancelEdit={handleCancelEdit} onDelete={() => {
      void handleDeleteCondition(condition);
    }} onEdit={handleEditCondition} onSaveCondition={handleSaveCondition} savedCondition={condition} />)}
      {/* For new draft conditions */}
      {activeTriggerCondition && activeTriggerCondition.index === null && <WorkflowTriggerCondition activeTriggerCondition={activeTriggerCondition} condition={activeTriggerCondition} isDeleting={isSavingTrigger} isSaving={isSavingTrigger} key="draft" onCancelEdit={handleCancelEdit} onDelete={handleCancelEdit} onEdit={handleEditCondition} onSaveCondition={handleSaveCondition} savedCondition={null} />}
      <div css={{
      "width": "100%"
    }} data-testid="trigger-add-condition-button-container">
        <GiantButton disabled={activeTriggerCondition !== null || !activeTrigger?.integrationName} endIcon={Icon.PLUS} label="Add Condition" onClick={handleAddCondition} tooltip={!activeTrigger?.integrationName ? 'Select an integration to add a condition' : ''} type={activeTrigger?.conditions && activeTrigger?.conditions.length > 0 ? GiantButtonType.OUTLINED : GiantButtonType.SOLID} />
      </div>
    </div>;
};
export default WorkflowTriggerConditionsList;