import useDefaultOrgSettingRows from './DefaultOrgControls.utils';
import { Table } from '~/components/core';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
const DefaultOrgControls = () => {
  const {
    rows,
    isLoading
  } = useDefaultOrgSettingRows();
  return <SettingsSection description="Control how newly added Providers & Models are configured by default for your organization. New models for disabled providers will not be enabled by default." title="Default Settings">
      <Table columns={[{
      title: 'Resource Type',
      width: {
        "width": "25%"
      }
    }, {
      title: 'Enabled',
      width: {
        "width": "12.5%"
      } // 1/8
    }, {
      title: 'DLP Filters',
      width: {
        "width": "12.5%"
      } // 1/8
    }]} data-testid="default-org-controls-table" loading={isLoading} noRowsText="No provider settings available at this time." rows={rows} />
    </SettingsSection>;
};
export default DefaultOrgControls;