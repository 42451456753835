import { Button, ButtonType, Table, TableRow, TypographyCasing, TypographyFont, TypographyWeight } from '~/components/core';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { useCanAccessSecuritySettings } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface MyUserGroupsTableProps {
  handleSelectUserGroupId: (groupId: string | null) => void;
}
const MyUserGroupsTable: React.FC<MyUserGroupsTableProps> = ({
  handleSelectUserGroupId
}) => {
  // Queries
  const {
    data: userGroupsData,
    isLoading: loadingUserGroups
  } = nextTrpc.userGroups.list.useQuery();
  const userGroups = userGroupsData?.items ?? [];

  // Custom Hooks
  const {
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const rows: TableRow[] = userGroups?.map(userGroup => ({
    key: `user-group-row-${userGroup.id}`,
    cells: [<Button casing={TypographyCasing.NONE} disabled={!orgCanAccessSecuritySettings} font={TypographyFont.PARAGRAPH} key={`user-group-name-${userGroup.id}`} label={userGroup.name} onClick={() => {
      handleSelectUserGroupId(userGroup.id);
    }} type={ButtonType.TEXT} weight={TypographyWeight.NORMAL} />, userGroup.count]
  })) ?? [];
  return <SettingsSection description="This is a list of user groups you are a member of" title="My User Groups">
      <Table columns={[{
      title: 'GROUP NAME'
    }, {
      title: 'HEADCOUNT'
    }]} loading={loadingUserGroups} noRowsText="No user groups found." rows={rows} />
    </SettingsSection>;
};
export default MyUserGroupsTable;