import { tv } from 'tailwind-variants';
import { Size } from '../constants';
export type ChipSize = Extract<Size, Size.SMALL | Size.MEDIUM>;
export const chipStyles = tv({
  base: 'relative inline-flex cursor-default bg-white/10 hover:bg-white/20 transition-colors duration-200 flex min-w-0 items-center gap-2.5 rounded px-2',
  variants: {
    size: {
      [Size.SMALL]: 'h-[27px]',
      [Size.MEDIUM]: 'h-8'
    },
    hasClose: {
      true: '',
      false: 'pr-3'
    }
  }
});