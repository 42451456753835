import useToast, { ToastType } from './useToast';

import { nextTrpc } from '~/trpc';

interface UseWorkflowReturnObject {
  deleteWorkflow: () => Promise<void>;
  isDeleting: boolean;
}

/**
 * Custom hook for deleting a workflow given an ID
 */
const useDeleteWorkflow = (
  workflowId: string,
  showToast = true
): UseWorkflowReturnObject => {
  // Custom hooks
  const { enqueueToast } = useToast();

  // Mutations
  const nextTrpcUtils = nextTrpc.useUtils();

  const deleteWorkflowMutation = nextTrpc.workflows.delete.useMutation({
    onSuccess: () => {
      if (showToast) {
        enqueueToast({
          message: 'Agent deleted successfully.',
          type: ToastType.SUCCESS
        });
      }
    }
  });

  // Returned functions
  const deleteWorkflow = async (): Promise<void> => {
    try {
      await deleteWorkflowMutation.mutateAsync({
        workflowId
      });
      // Invalidate cache to re-query and remove deleted workflow from list immediately
      void nextTrpcUtils.workflows.list.invalidate();
    } catch (error) {
      console.error(error);
      enqueueToast({
        message: 'Agent deletion failed.',
        type: ToastType.ERROR
      });
    }
  };

  return {
    isDeleting: deleteWorkflowMutation.isLoading,
    deleteWorkflow
  };
};

export default useDeleteWorkflow;
