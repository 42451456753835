import { ChatUserGroupRole, WorkflowUserRole } from '@kindo/universal';
import { Select, SelectOptionType, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
interface SharedPermissionRowProps<Role extends WorkflowUserRole | ChatUserGroupRole> {
  disabled: boolean;
  onChange: (permission: Role | null) => void;
  options: SelectOptionType<Role | null>[];
  permission: Role;
  subtitle: string;
  title: string | null;
  tooltip?: string;
}
const SharedPermissionRow = <Role extends WorkflowUserRole | ChatUserGroupRole,>({
  disabled,
  onChange,
  options,
  permission,
  subtitle,
  title,
  tooltip
}: SharedPermissionRowProps<Role>) => <div css={{
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "paddingTop": "0.75rem",
  "paddingBottom": "0.75rem"
}} data-testid="shared-permission-row-container">
    <div css={{
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "center"
  }} data-testid="name-and-email-container">
      <Typography size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
        {title ?? subtitle}
      </Typography>
      {!!title && title !== subtitle && <Typography color={TextIconColor.SECONDARY} size={TypographySize.X_SMALL}>
          {subtitle}
        </Typography>}
    </div>

    <Select<Role | null> disabled={disabled} onChange={value => onChange(value)} options={options} placeholderLabel="Select Access Level" tooltip={tooltip} value={permission} />
  </div>;
export default SharedPermissionRow;