import _styled from "styled-components";
import { useState } from 'react';
import { TextIconColor } from '../constants';
import { Icon, Icons } from '../Icon';
const ExpandContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ExpandTriggerContainer = _styled.div<{
  $disabled?: boolean;
}>`
  ${{
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.5rem"
}}
  ${({
  $disabled
}) => !$disabled && {
  "cursor": "pointer"
}}
`;
interface ExpandProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  chevronIconColor?: TextIconColor;
  disabled?: boolean;
  startExpanded?: boolean;
}
const Expand: React.FC<ExpandProps> = ({
  children,
  trigger,
  startExpanded = false,
  chevronIconColor = TextIconColor.PRIMARY,
  disabled = false
}) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);
  return <ExpandContainer>
      <ExpandTriggerContainer $disabled={disabled} onClick={() => setIsExpanded(!isExpanded)}>
        {!disabled && <Icons color={chevronIconColor} icon={isExpanded ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} />}
        {trigger}
      </ExpandTriggerContainer>
      {!disabled && isExpanded && children}
    </ExpandContainer>;
};
export default Expand;