import {
  WorkflowIntegrationActionStepType,
  WorkflowActionStepConfig,
  WORKFLOW_INTEGRATION_ACTIONS_REQUIRING_ADDITIONAL_CONTEXT
} from '@kindo/universal';

import { useAppDispatch } from '../typedReduxHooks';
import useToast, { ToastType } from '../useToast';

import { ValidationResult } from './useActiveApiActionStep';

import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import {
  BuilderWorkflowIntegrationActionStep,
  BuilderWorkflowStatus,
  isIntegrationWorkflowBuilderStepInput
} from '~/types';

export interface UseActiveIntegrationActionStepReturn {
  canSave: (step: BuilderWorkflowIntegrationActionStep) => ValidationResult;
  isSaving: boolean;
  saveIntegrationActionStep: (
    step: BuilderWorkflowIntegrationActionStep
  ) => Promise<void>;
  updateAction: (value: WorkflowIntegrationActionStepType) => void;
  updateActionConfig: (
    config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>
  ) => void;
}

const useActiveIntegrationActionStep = (
  workflowId: string
): UseActiveIntegrationActionStepReturn => {
  const dispatch = useAppDispatch();
  const { enqueueToast } = useToast();

  const addIntegrationActionStepMutation =
    nextTrpc.workflows.addIntegrationActionStep.useMutation();
  const editIntegrationActionStepMutation =
    nextTrpc.workflows.editIntegrationActionStep.useMutation();

  const saveIntegrationActionStep = async (
    step: BuilderWorkflowIntegrationActionStep
  ) => {
    const { action, integration, status, stepNumber, inputs, config } = step;

    if (!action || !integration || inputs.length === 0) {
      throw new Error('Action, integration, and inputs are required');
    }

    const inputIds = inputs
      .filter(isIntegrationWorkflowBuilderStepInput)
      .map((input) => input.id);

    try {
      if (status === BuilderWorkflowStatus.NEW) {
        await addIntegrationActionStepMutation.mutateAsync({
          type: action,
          integrationName: integration,
          stepNumber,
          inputIds,
          workflowId,
          config
        });
      } else if (status === BuilderWorkflowStatus.MODIFIED) {
        await editIntegrationActionStepMutation.mutateAsync({
          type: action,
          integrationName: integration,
          stepNumber,
          inputIds,
          id: step.id,
          config
        });
      }
    } catch (error) {
      console.error('Failed to save integration action step:', error);
      enqueueToast({
        message: 'Failed to save integration action step. Please try again.',
        type: ToastType.ERROR
      });
      throw error;
    }
  };

  const updateAction = (value: WorkflowIntegrationActionStepType) => {
    dispatch(
      workflowBuilderActions.updateActiveStepAction({
        integrationAction: value
      })
    );

    if (
      WORKFLOW_INTEGRATION_ACTIONS_REQUIRING_ADDITIONAL_CONTEXT.includes(value)
    ) {
      dispatch(
        workflowBuilderActions.updateActiveStepActionConfig({
          config: {
            additionalContext: '',
            includeChatHistory: false,
            commentActionOutput: true
          }
        })
      );
    }

    if (value === WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET) {
      dispatch(
        workflowBuilderActions.updateActiveStepActionConfig({
          config: null
        })
      );
    }
  };

  const updateActionConfig = (
    config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>
  ) => {
    dispatch(
      workflowBuilderActions.updateActiveStepActionConfig({
        config
      })
    );
  };

  const canSave = (
    step: BuilderWorkflowIntegrationActionStep
  ): ValidationResult => {
    if (!step.action) {
      return { isValid: false, message: 'Action type must be selected' };
    }
    if (!step.integration) {
      return { isValid: false, message: 'Integration must be selected' };
    }
    if (step.inputs.length === 0) {
      return { isValid: false, message: 'At least one input is required' };
    }
    return { isValid: true };
  };

  return {
    saveIntegrationActionStep,
    updateAction,
    updateActionConfig,
    canSave,
    isSaving:
      addIntegrationActionStepMutation.isLoading ||
      editIntegrationActionStepMutation.isLoading
  };
};

export default useActiveIntegrationActionStep;
