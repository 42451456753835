import _styled from "styled-components";
import { AuditEventSummaryRow } from '../AuditLog.types';
import { getAuditEventSummary } from '../AuditLog.utils';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Table, Typography } from '~/components/core';
import useGetEnabledModels from '~/hooks/useGetEnabledModels';
const AuditEventSummaryContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1.5rem"
});
const AuditEventSummaryItemContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  "whiteSpace": "pre-wrap"
});
const AuditEventSummary = ({
  auditEvent,
  handleSetUserFilter
}: {
  auditEvent: any;
  handleSetUserFilter: (userId: string) => void;
}) => {
  const {
    modelIdentifierToDisplayName
  } = useGetEnabledModels();
  const summary: AuditEventSummaryRow[] = getAuditEventSummary({
    ...auditEvent,
    modelIdentifierToDisplayName
  });
  return <AuditEventSummaryContainer>
      <Table columns={[]} noRowsText="No audit log events found." rows={summary.map(({
      key,
      value,
      link,
      metadataDisplay
    }) => ({
      key,
      cells: [key, <AuditEventSummaryItemContainer key={key}>
              {!!value && <Typography>{value}</Typography>}
              {link && <Button href={link.route} label={link.label} tooltip={`Navigate to ${link.type}`} type={ButtonType.OUTLINED_COLOR} />}
              {metadataDisplay}
              {key === 'User' && <IconButton icon={Icon.SEARCH} onClick={() => handleSetUserFilter(auditEvent.userId)} tooltip="View all events for this user" type={IconButtonType.SIMPLE} />}
            </AuditEventSummaryItemContainer>]
    }))} />
    </AuditEventSummaryContainer>;
};
export default AuditEventSummary;