import { INTEGRATION_DISPLAY_NAMES, IntegrationWorkflowInputType, TicketingIntegrationTicketField, WorkflowInputType } from '@kindo/universal';
import { INTEGRATION_INPUT_TYPE_DISPLAY_NAMES, INTEGRATION_INPUT_TYPE_TO_FIELDS, TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES } from './WorkflowIntegrationInput.consts';
import { IntegrationWorkflowInput } from '~/types';

// TODO: [ENG-3778] Need to clean up/reorganize once necessary updates to
// integrations, workflow input integrations are made kindo universal

export const getIntegrationInputFieldDisplayName = (integrationInputType: WorkflowInputType, field: string) => {
  switch (integrationInputType) {
    case WorkflowInputType.TICKETING_INTEGRATION_TICKET:
      return TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES[field as TicketingIntegrationTicketField];
    default:
      throw new Error(`Invalid integration input type: ${integrationInputType}`);
  }
};
export const getIntegrationInputFields = (integrationInputType: IntegrationWorkflowInputType | null) => integrationInputType !== null ? INTEGRATION_INPUT_TYPE_TO_FIELDS[integrationInputType] : [];
export const getAddIntegrationInputButtonLabel = (integrationInput: IntegrationWorkflowInput) => `${INTEGRATION_DISPLAY_NAMES[integrationInput.integrationName]} ${INTEGRATION_INPUT_TYPE_DISPLAY_NAMES[integrationInput.type]} input`;