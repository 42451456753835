import React, { createContext, ReactNode, useContext, useState } from 'react';

import { SettingsModal } from '~/components/SettingsModal/SettingsModal';
import { SettingsTab } from '~/components/SettingsModal/SettingsModal.types';

type SettingsModalContextType = {
  closeSettingsModal: () => void;
  initialTab: string | undefined;
  isOpen: boolean;
  openSettingsModal: (initialTab?: SettingsTab) => void;
};

const SettingsModalContext = createContext<
  SettingsModalContextType | undefined
>(undefined);

export const SettingsModalProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialTab, setInitialTab] = useState<SettingsTab | undefined>(
    undefined
  );

  const openSettingsModal = (tab?: SettingsTab) => {
    setInitialTab(tab);
    setIsOpen(true);
  };

  const closeSettingsModal = () => {
    setIsOpen(false);
    setInitialTab(undefined);
  };

  return (
    <SettingsModalContext.Provider
      value={{ isOpen, initialTab, openSettingsModal, closeSettingsModal }}
    >
      {children}
      {isOpen && (
        <SettingsModal
          initialTab={initialTab || SettingsTab.MODELS}
          onClose={closeSettingsModal}
        />
      )}
    </SettingsModalContext.Provider>
  );
};

export const useSettingsModal = () => {
  const context = useContext(SettingsModalContext);
  if (context === undefined) {
    throw new Error(
      'useSettingsModal must be used within a SettingsModalProvider'
    );
  }
  return context;
};
