import { useState } from 'react';
import { Button, ButtonType, Icon, Size, TextIconColor } from '~/components/core';
import { useOrgSettings } from '~/hooks';
import { nextTrpc } from '~/trpc';
type RunCodeButtonProps = {
  disabled: boolean;
  chatMessageId?: string | undefined;
  codeIndex?: number | undefined;
  textIconColor?: TextIconColor;
};
const RunCodeButton: React.FC<RunCodeButtonProps> = ({
  chatMessageId,
  codeIndex,
  disabled,
  textIconColor
}) => {
  const {
    orgSettings
  } = useOrgSettings();
  const showButton = chatMessageId && codeIndex !== undefined && orgSettings?.codeSandboxEnabled;
  const [isRunning, setIsRunning] = useState(false);
  const executeCodeMutation = nextTrpc.chatMessage.executeCode.useMutation({
    onMutate: () => {
      setIsRunning(true);
    },
    onSettled: () => {
      setIsRunning(false);
    }
  });
  if (!showButton) {
    return null;
  }
  return <div css={{
    "display": "flex",
    "gap": "0.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem"
  }} data-testid="run-code-button-container" id="run-code-button-container">
      <Button disabled={disabled || isRunning} endIcon={Icon.PLAY} label="Run code" onClick={() => {
      executeCodeMutation.mutateAsync({
        messageId: chatMessageId,
        codeIndex
      });
    }} size={Size.SMALL} textIconColor={textIconColor} type={ButtonType.TEXT} />
    </div>;
};
export default RunCodeButton;