import useToast, { ToastType } from './useToast';

import { trpc } from '~/trpc';

const useSlackIntegrationConnect = () => {
  const { enqueueToast } = useToast();

  const openSlack = async () => {
    if (
      !process.env.NEXT_PUBLIC_API_BASE_URL ||
      !process.env.NEXT_PUBLIC_SLACK_CLIENT_ID
    ) {
      console.error(
        'Missing Slack client ID or API base URL',
        process.env.NEXT_PUBLIC_API_BASE_URL,
        process.env.NEXT_PUBLIC_SLACK_CLIENT_ID
      );
      enqueueToast({
        message:
          'Environment not configured to support Slack integration, please contact support.',
        type: ToastType.ERROR
      });
      return;
    }

    const kindoUser = await trpc.users.getSelf.query();

    // TODO: Find automatic solution for ngrok port forwarding and remove comments
    // To run redirectUri locally, you need to port forward using ngrok. Do the following:
    // 1. Run `ngrok http http://localhost:8000` in console
    // 2. Copy the https URL and set it as the redirectUri below
    // 3. Add this redirectURI to the Slack App Configuration in the OAuth & Permissions section
    // 4. Check the same redirectURI exists in slack.ts

    // const ngrokUrl = 'https://81d3-47-147-243-3.ngrok-free.app';
    // const redirectUri = ngrokUrl + '/slack-auth';

    const redirectUri = process.env.NEXT_PUBLIC_API_BASE_URL + '/slack-auth';

    const botScopes = [
      'channels:history',
      'channels:join',
      'channels:read',
      'chat:write',
      'chat:write.public',
      'commands',
      'users:read'
    ];

    const userScopes = ['channels:history'];

    const SLACK_CLIENT_ID = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID ?? '';

    const generateOAuthURL = (): string => {
      const baseUrl = 'https://slack.com/oauth/v2/authorize';
      const params = new URLSearchParams({
        client_id: SLACK_CLIENT_ID,
        scope: botScopes.join(','),
        user_scope: userScopes.join(','),
        redirect_uri: redirectUri,
        state: kindoUser?.userId ?? '',
        granular_bot_scope: '1',
        single_channel: '0', // Set to '0' or remove to allow multiple channels
        install_redirect: '',
        tracked: '1',
        team: '1'
      });

      return `${baseUrl}?${params.toString()}`;
    };

    const url = generateOAuthURL();
    window.open(url, '_blank');
  };

  return {
    openSlack
  };
};

export default useSlackIntegrationConnect;
