import { ChipSize, chipStyles } from './Chip.styles';
import { Icon, Icons, Size, TextIconColor, ToolTip, Typography } from '~/components/core';
import { TypographyFont, TypographySize, TypographyWeight, TypographyWrap } from '~/components/core/Typography/Typography.consts';
interface ChipProps {
  label: string;
  onClick?: () => void;
  onClose?: () => void;
  size?: ChipSize;
  startIcon?: Icon;
  tooltip?: string;
}
export const Chip = ({
  label,
  onClick,
  onClose,
  size = Size.SMALL,
  startIcon,
  tooltip
}: ChipProps) => {
  const chipClasses = chipStyles({
    size,
    hasClose: !!onClose
  });
  return <ToolTip content={tooltip}>
      <div className={chipClasses} id="chip-container" onClick={onClick}>
        {startIcon && <div css={{
        "marginRight": "-3px",
        "display": "flex",
        "height": "1rem",
        "width": "1rem",
        "alignItems": "center",
        "justifyContent": "center"
      }} id="chip-icon-container">
            <Icons color={TextIconColor.PRIMARY} icon={startIcon} />
          </div>}
        <Typography color={TextIconColor.PRIMARY} font={TypographyFont.PARAGRAPH} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM} wrap={TypographyWrap.NO_WRAP}>
          {label}
        </Typography>
        {onClose && <button css={{
        "display": "flex",
        "height": "0.875rem",
        "width": "0.875rem",
        "alignItems": "center",
        "justifyContent": "center",
        "borderRadius": "0.125rem",
        "backgroundColor": "rgb(255 255 255 / 0.3)",
        "padding": "1px",
        "transitionProperty": "all",
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "200ms",
        "&:hover": {
          "backgroundColor": "rgb(255 255 255 / 0.4)"
        }
      }} onClick={e => {
        e.stopPropagation();
        onClose();
      }}>
            <Icons color={TextIconColor.PRIMARY} icon={Icon.CLOSE} />
          </button>}
      </div>
    </ToolTip>;
};
export default Chip;