export enum BadgeType {
  OUTLINE = 'OUTLINE',
  SOLID = 'SOLID',
}
export enum BadgeColor {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  HIGHLIGHT = 'HIGHLIGHT',
  PRIMARY_BRIGHT = 'PRIMARY_BRIGHT',
  PRIMARY_HIGHLIGHT = 'PRIMARY_HIGHLIGHT',
  WARNING = 'WARNING',
}