import { getKindoErrorMessage, isUserGroupErrorCode, KINDO_ERROR_MESSAGES } from '@kindo/universal';
import { useState } from 'react';
import { Button, ButtonType, Icon, IconButton, IconButtonType, InputButton, Select, Size, Table, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { nextTrpc, trpc } from '~/trpc';
interface UserGroupMembersProps {
  userGroupId: string;
}
export type User = Awaited<ReturnType<(typeof trpc)['userGroups']['get']['query']>>['users'][number];
const UserGroupMembers: React.FC<UserGroupMembersProps> = ({
  userGroupId
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserToRemove, setSelectedUserToRemove] = useState<User | null>(null);
  const [multipleEmails, setMultipleEmails] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    enqueueToast
  } = useToast();
  const {
    userCanManageOrganization
  } = useCanAccessSecuritySettings();
  const {
    data: users
  } = nextTrpc.orgs.listUsers.useQuery();
  const {
    data: userGroup,
    refetch: refetchUserGroup
  } = nextTrpc.userGroups.get.useQuery({
    userGroupId
  });
  const {
    mutate: addUserToUserGroup
  } = nextTrpc.userGroups.addUser.useMutation<{
    name: string;
  }>({
    onSuccess: user => {
      enqueueToast({
        message: `${user.name} was added to ${userGroup?.name}`,
        type: ToastType.SUCCESS
      });
      refetchUserGroup();
    },
    onError: error => {
      enqueueToast({
        message: isUserGroupErrorCode(error.message) ? getKindoErrorMessage(error.message) : 'Error adding users to group',
        type: ToastType.ERROR
      });
    }
  });
  const {
    mutate: addUserByEmail
  } = nextTrpc.userGroups.addUserByEmail.useMutation<{
    name: string;
  }>({
    onSuccess: user => {
      enqueueToast({
        message: `${user.email} was added to ${userGroup?.name}`,
        type: ToastType.SUCCESS
      });
      refetchUserGroup();
    },
    onError: error => {
      enqueueToast({
        message: error.message === KINDO_ERROR_MESSAGES.USER_GROUP_USER_NOT_ALLOWED_TO_ADD ? KINDO_ERROR_MESSAGES.USER_GROUP_USER_NOT_ALLOWED_TO_ADD : 'Error adding user to group',
        type: ToastType.ERROR
      });
    }
  });
  const {
    mutate: removeUserFromUserGroup
  } = nextTrpc.userGroups.removeUser.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'User removed from group',
        type: ToastType.SUCCESS
      });
      refetchUserGroup();
    },
    onError: () => {
      enqueueToast({
        message: 'Error removing user from group',
        type: ToastType.ERROR
      });
    }
  });
  const handleAddMembersByEmail = async (emails: string[]) => {
    await Promise.all(emails.map(email => addUserByEmail({
      userGroupId,
      email: email.trim()
    })));
  };
  const handleRemoveUserFromUserGroup = () => {
    if (selectedUserToRemove) {
      removeUserFromUserGroup({
        userGroupId,
        userId: selectedUserToRemove.id
      });
      setShowDeleteModal(false);
      setSelectedUserToRemove(null);
    }
  };
  const addableUsers = users?.items.filter(user => !userGroup?.users.some(u => u.id === user.id)) || [];
  return <>
      {userCanManageOrganization && <div css={{
      "borderRadius": "0.5rem",
      "backgroundColor": "#25224575",
      "padding": "1.5rem"
    }} data-testid="user-group-add-members-container">
          <div css={{
        "marginBottom": "1.5rem"
      }} data-testid="user-group-add-members-title">
            <Typography font={TypographyFont.HEADING} size={TypographySize.H4}>
              Add Members
            </Typography>
          </div>
          <div css={{
        "display": "flex",
        "gap": "3rem"
      }} data-testid="user-group-add-members-options-container">
            <div css={{
          "display": "flex",
          "minWidth": "6rem",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="user-group-add-member-option-container">
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
                Single Member
              </Typography>
              <div css={{
            "display": "flex",
            "gap": "0.5rem"
          }} data-testid="user-group-add-member-input-container">
                <Select buttonSize={Size.SMALL} data-testid="user-group-member-select" onChange={setSelectedUserId} options={addableUsers.length > 0 ? addableUsers.sort((a, b) => a.name.localeCompare(b.name)).map(user => ({
              label: user.name,
              value: user.id
            })) : [{
              label: 'No available members to select',
              value: null
            }]} placeholderLabel="Select Member" typographySize={TypographySize.X_SMALL} value={selectedUserId} />
                <Button data-testid="user-group-add-member-button" disabled={!selectedUserId || addableUsers.length === 0} label="ADD" onClick={() => {
              if (selectedUserId) {
                addUserToUserGroup({
                  userGroupId,
                  userId: selectedUserId
                });
                setSelectedUserId(null);
              }
            }} size={Size.SMALL} type={ButtonType.SOLID} typographySize={TypographySize.X_SMALL} />
              </div>
            </div>
            <div css={{
          "display": "flex",
          "minWidth": "16rem",
          "flex": "1",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="user-groupadd-members-by-email-container">
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
                Multiple Members
              </Typography>
              <div css={{
            "display": "flex",
            "gap": "0.5rem"
          }} data-testid="user-group-add-multiple-members-input-container">
                <InputButton buttonLabel="Add" data-testid="multiple-emails-input" disabled={!userCanManageOrganization} onChange={setMultipleEmails} onSubmit={() => {
              if (multipleEmails) {
                void handleAddMembersByEmail(multipleEmails.split(','));
              }
            }} placeholder={userCanManageOrganization ? 'Insert multiple emails separated by commas' : 'You do not have permission to invite...'} value={multipleEmails} width="w-3/4" />
              </div>
            </div>
          </div>
        </div>}
      <Table columns={[{
      title: 'NAME'
    }, {
      title: 'EMAIL'
    }]} data-testid="user-group-members-table" noRowsText="No members in this user group" rows={userGroup?.users.map(user => ({
      key: user.id,
      cells: [user.name ?? '', user.email, userCanManageOrganization && <IconButton data-testid={`remove-user-button-${user.id}`} highlightOnHover icon={Icon.TRASH} key={user.id} onClick={() => {
        setShowDeleteModal(true);
        setSelectedUserToRemove(user);
      }} type={IconButtonType.NONE} />]
    })) || []} />
      <ConfirmationModal confirmButtonLabel="Remove user from group" data-testid="remove-user-confirmation-modal" header={`Are you sure you want to remove ${selectedUserToRemove?.name} from the group?`} onCancel={() => {
      setShowDeleteModal(false);
      setSelectedUserToRemove(null);
    }} onConfirm={handleRemoveUserFromUserGroup} open={showDeleteModal} subtext="You can't undo this action." />
    </>;
};
export default UserGroupMembers;