import { Integration, INTEGRATION_DISPLAY_NAMES } from '@kindo/universal';
import { ConfirmationModal } from '../../shared/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface DeleteIntegrationModalProps {
  integration: Integration;
  integrationId: string;
  onClose: () => void;
  onSuccess: () => void;
}
const DeleteIntegrationModal: React.FC<DeleteIntegrationModalProps> = ({
  integrationId,
  integration,
  onClose,
  onSuccess
}) => {
  // Merge Integration Deletion:
  const {
    enqueueToast
  } = useToast();
  const deleteIntegration = nextTrpc.integrations.deleteIntegrationAccount.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Integration deleted.',
        type: ToastType.SUCCESS
      });
      onSuccess();
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Integration deletion failed.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDeleteIntegration = () => {
    deleteIntegration.mutate({
      id: integrationId
    });
    onClose();
  };
  return <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={deleteIntegration.isLoading} header={`Are you sure you want to delete ${INTEGRATION_DISPLAY_NAMES[integration]}?`} onCancel={onClose} onConfirm={handleDeleteIntegration} open subtext="All data from this integration will be deleted." />;
};
export default DeleteIntegrationModal;