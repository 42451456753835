import { tv, VariantProps } from 'tailwind-variants';
export enum LoadingSpinnerSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  X_LARGE = 'X_LARGE',
}
const spinnerStyles = tv({
  base: 'animate-spin text-gray-200 dark:text-gray-600',
  variants: {
    size: {
      [LoadingSpinnerSize.SMALL]: 'w-4 h-4',
      [LoadingSpinnerSize.MEDIUM]: 'w-6 h-6',
      [LoadingSpinnerSize.LARGE]: 'w-8 h-8',
      [LoadingSpinnerSize.X_LARGE]: 'w-10 h-10'
    }
  }
});
type LoadingSpinnerProps = VariantProps<typeof spinnerStyles>;
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = LoadingSpinnerSize.MEDIUM
}) => <div id="loading-spinner-container" role="status">
    <svg aria-hidden="true" className={spinnerStyles({
    size
  })} fill="none" viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" fill="transparent" r="38" stroke="#8CAEBA33" strokeWidth={16} />

      <circle cx="50" cy="50" fill="transparent" r="38" stroke="#8D76FF" strokeDasharray="157" strokeDashoffset="75" strokeLinecap="round" strokeWidth={16} />
    </svg>
    <span css={{
    "position": "absolute",
    "width": "1px",
    "height": "1px",
    "padding": "0",
    "margin": "-1px",
    "overflow": "hidden",
    "clip": "rect(0, 0, 0, 0)",
    "whiteSpace": "nowrap",
    "borderWidth": "0"
  }}>Loading...</span>
  </div>;
export default LoadingSpinner;