import SettingsSection from '../../SettingsSection';
import { TextIconColor } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import { useMarkdownFile } from '~/hooks';
const UserGroupsReference = () => {
  const {
    markdown: userGroupsOverview
  } = useMarkdownFile('/docs/UserGroups/UserGroupsOverview.md');
  const {
    markdown: userGroupsIntegrationAccess
  } = useMarkdownFile('/docs/UserGroups/UserGroupsIntegrationAccess.md');
  const {
    markdown: userGroupsModelAndProviderAccess
  } = useMarkdownFile('/docs/UserGroups/UserGroupsModelAndProviderAccess.md');
  const {
    markdown: userGroupsDlpConfig
  } = useMarkdownFile('/docs/UserGroups/UserGroupsDlpConfig.md');
  const markdowns = [userGroupsOverview, userGroupsIntegrationAccess, userGroupsModelAndProviderAccess, userGroupsDlpConfig];
  return <div css={{
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  }} data-testid="user-groups-reference-container">
      <SettingsSection title="User Groups Reference">
        {markdowns.map((markdown, index) => <div css={{
        "marginTop": "0.75rem",
        "marginBottom": "0.75rem",
        "display": "flex",
        "width": "100%",
        "flexDirection": "column",
        "gap": "0.5rem",
        "borderRadius": "0.375rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
        "padding": "1.75rem"
      }} data-testid={`user-groups-reference-section-${index}`} key={index}>
            <Markdown styleDocsComponents textColor={TextIconColor.SECONDARY}>
              {markdown}
            </Markdown>
          </div>)}
      </SettingsSection>
    </div>;
};
export default UserGroupsReference;