import { assertUnreachable, isUserGroupErrorCode, KINDO_ERROR_MESSAGES } from '@kindo/universal';
import { useState } from 'react';
import { UserGroupBaseDlpFiltersConfig } from './UserGroupDlpFilters/UserGroupBaseDlpFiltersConfig';
import { UserGroupIntegrationAccess } from './UserGroupIntegrationAccess';
import { UserGroupMembers } from './UserGroupMembers';
import { UserGroupModelAccess } from './UserGroupModelAccess';
import { Button, ButtonType, CalloutType, Icon, IconButton, IconButtonType, Size, TabNavigator, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import type { TabOption } from '~/components/core';
import { TextField, TextFieldType } from '~/components/core/TextField';
import { Markdown } from '~/components/Markdown';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants';
import { ToastType, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { nextTrpc, trpc } from '~/trpc';
export enum UserGroupTab {
  INTEGRATION_ACCESS = 'INTEGRATION_ACCESS',
  MEMBERS = 'MEMBERS',
  MODEL_ACCESS = 'MODEL_ACCESS',
}
const USER_GROUP_TAB_DISPLAY_NAMES: Record<UserGroupTab, string> = {
  [UserGroupTab.INTEGRATION_ACCESS]: 'Integration Access',
  [UserGroupTab.MEMBERS]: 'Members',
  [UserGroupTab.MODEL_ACCESS]: 'Model access + DLP'
};
const USER_GROUP_TAB_HEADER_NAMES: Record<UserGroupTab, string> = {
  [UserGroupTab.INTEGRATION_ACCESS]: 'Integration Access',
  [UserGroupTab.MEMBERS]: 'Group Members',
  [UserGroupTab.MODEL_ACCESS]: 'Model Access and DLP Controls'
};
const USER_GROUP_TAB_DESCRIPTIONS: Record<UserGroupTab, string> = {
  [UserGroupTab.INTEGRATION_ACCESS]: 'Control which integrations can be used by your group.',
  [UserGroupTab.MEMBERS]: '',
  [UserGroupTab.MODEL_ACCESS]: 'Control which providers and models can be used by your group and are affected by data loss prevention filters.'
};
const USER_GROUP_STATIC_TAB_OPTIONS: TabOption<UserGroupTab>[] = [UserGroupTab.MODEL_ACCESS, UserGroupTab.INTEGRATION_ACCESS].map(tab => ({
  label: USER_GROUP_TAB_DISPLAY_NAMES[tab],
  value: tab
}));
export type UserGroup = Awaited<ReturnType<(typeof trpc)['userGroups']['get']['query']>>;
interface UserGroupSettingsProps {
  handleSelectUserGroupId: (groupId: string | null) => void;
  userGroupId: string;
}
const UserGroupSettings: React.FC<UserGroupSettingsProps> = ({
  userGroupId,
  handleSelectUserGroupId
}) => {
  const [userGroupNameInput, setUserGroupNameInput] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState<UserGroupTab>(UserGroupTab.MEMBERS);
  const {
    userCanManageOrganization,
    userCanAccessSecuritySettings,
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const {
    enqueueToast
  } = useToast();
  const {
    data: userGroup,
    refetch: refetchUserGroup
  } = nextTrpc.userGroups.get.useQuery({
    userGroupId
  }, {
    onSuccess: data => {
      setUserGroupNameInput(data.name);
    }
  });
  const updateUserGroupMutation = nextTrpc.userGroups.update.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'User group name updated.',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      enqueueToast({
        message: isUserGroupErrorCode(error.message) ? KINDO_ERROR_MESSAGES[error.message] : 'Failed to update user group.',
        type: ToastType.ERROR
      });
      void refetchUserGroup();
    }
  });
  const deleteGroupMutation = nextTrpc.userGroups.delete.useMutation({
    onSuccess: () => {
      handleSelectUserGroupId(null);
      enqueueToast({
        message: 'User group deleted.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete user group.',
        type: ToastType.ERROR
      });
    }
  });
  const handleUpdateUserGroupName = () => {
    if (userGroupNameInput === userGroup?.name) {
      return;
    }
    updateUserGroupMutation.mutate({
      userGroupId,
      name: userGroupNameInput
    });
  };
  const handleDeleteUserGroup = () => {
    deleteGroupMutation.mutate({
      userGroupId
    });
  };
  const calloutAccessibleText = orgCanAccessSecuritySettings ? `> ${USER_GROUP_TAB_DISPLAY_NAMES[activeTab]} settings are only available to account admins` : '> This feature is limited for Free and Pro users. To inquire about upgrading, please contact sales.';
  const renderActiveTab = () => {
    switch (activeTab) {
      case UserGroupTab.MODEL_ACCESS:
        return <>
            <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "1.5rem",
            "borderRadius": "0.5rem",
            "--tw-bg-opacity": "0.3",
            "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
            "padding": "1.5rem"
          }} data-testid="user-group-model-access-panel">
              <SettingsSection description={USER_GROUP_TAB_DESCRIPTIONS[activeTab]} title={USER_GROUP_TAB_HEADER_NAMES[activeTab]}>
                <UserGroupModelAccess userGroupId={userGroupId} />
              </SettingsSection>
            </div>
            <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "1.5rem",
            "borderRadius": "0.5rem",
            "--tw-bg-opacity": "0.3",
            "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
            "padding": "1.5rem"
          }} data-testid="user-group-dlp-config-panel">
              <UserGroupBaseDlpFiltersConfig canAccessSecuritySettings={userCanAccessSecuritySettings} userGroupId={userGroupId} />
            </div>
          </>;
      case UserGroupTab.INTEGRATION_ACCESS:
        return <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1.5rem",
          "borderRadius": "0.5rem",
          "--tw-bg-opacity": "0.3",
          "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
          "padding": "1.5rem"
        }} data-testid="user-group-integration-access-panel">
            <SettingsSection description={USER_GROUP_TAB_DESCRIPTIONS[activeTab]} title={USER_GROUP_TAB_HEADER_NAMES[activeTab]}>
              <UserGroupIntegrationAccess userGroupId={userGroupId} />
            </SettingsSection>
          </div>;
      case UserGroupTab.MEMBERS:
        return <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1.5rem",
          "borderRadius": "0.5rem",
          "--tw-bg-opacity": "0.3",
          "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
          "padding": "1.5rem"
        }} data-testid="user-group-members-panel">
            <SettingsSection description={USER_GROUP_TAB_DESCRIPTIONS[activeTab]} title={USER_GROUP_TAB_HEADER_NAMES[activeTab]}>
              <UserGroupMembers userGroupId={userGroupId} />
            </SettingsSection>
          </div>;
      default:
        return assertUnreachable(activeTab);
    }
  };
  return <>
      <div css={{
      "marginBottom": "0.5rem",
      "marginLeft": "-16px"
    }} data-testid="breadcrumb-container">
        <Button data-testid="back-button" label="Back to user groups" onClick={() => {
        handleSelectUserGroupId(null);
      }} size={Size.MEDIUM} startIcon={Icon.CHEVRON_LEFT} type={ButtonType.TEXT} typographySize={TypographySize.X_SMALL} />
      </div>
      <SettingsSection data-testid="user-group-settings-section" title="User Group Settings">
        <div css={{
        "display": "flex",
        "flexDirection": "column",
        "gap": "1.5rem"
      }} data-testid="user-group-settings-container">
          <div css={{
          "marginTop": "0.5rem",
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="user-group-header-container">
            <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} data-testid="group-name-label" font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
              Group Name
            </Typography>
            {userCanManageOrganization && <div css={{
            "display": "flex",
            "alignItems": "flex-end",
            "gap": "0.5rem"
          }} data-testid="user-group-name-editor">
                <div css={{
              "marginLeft": "-4px",
              "flex": "1 1 0%",
              "borderBottomWidth": "0.1px",
              "borderBottomColor": "#e6c2ff1a"
            }} data-testid="user-group-name-field-container">
                  <TextField animateStartIcon data-testid="user-group-name-field" font={TypographyFont.INTERACTIVE} onBlur={() => handleUpdateUserGroupName()} onChange={newValue => setUserGroupNameInput(newValue)} placeholder="Name your group" size={TypographySize.H3} startIcon={Icon.EDIT} textWeight={TypographyWeight.MEDIUM} type={TextFieldType.SIMPLE} value={userGroupNameInput} />
                </div>
                <IconButton data-testid="delete-user-group-button" icon={Icon.TRASH} onClick={() => setShowDeleteModal(true)} type={IconButtonType.OUTLINED} />
              </div>}
            {!userCanManageOrganization && <Typography color={TextIconColor.PRIMARY} data-testid="user-group-name-display" font={TypographyFont.HEADING} fullWidth size={TypographySize.H3}>
                {userGroup?.name}
              </Typography>}
            <div css={{
            "marginTop": "1rem"
          }} data-testid="tab-navigator-container">
              <TabNavigator activeTab={activeTab} data-testid="user-group-tab-navigator" onClick={setActiveTab} size={Size.SMALL} tabOptions={[{
              label: `Members (${userGroup?.users.length})`,
              value: UserGroupTab.MEMBERS
            }, ...USER_GROUP_STATIC_TAB_OPTIONS]} />
            </div>
          </div>
          {!userCanAccessSecuritySettings && <Markdown calloutType={orgCanAccessSecuritySettings ? CalloutType.SECURITY : CalloutType.INFO} data-testid="access-restriction-callout" endElement={!orgCanAccessSecuritySettings ? <Button data-testid="contact-sales-button" href={MARKETING_WEBSITE_CONTACT_URL} label="Contact Sales" type={ButtonType.SOLID} /> : undefined}>
              {calloutAccessibleText}
            </Markdown>}
          {renderActiveTab()}
        </div>
        <ConfirmationModal confirmButtonLabel="Delete user group" data-testid="delete-user-group-confirmation-modal" header={`Are you sure you want to delete the user group ${userGroup?.name}?`} onCancel={() => setShowDeleteModal(false)} onConfirm={handleDeleteUserGroup} open={showDeleteModal} subtext="You can't undo this action." />
      </SettingsSection>
    </>;
};
export default UserGroupSettings;