import {
  WorkflowInputType,
  WorkflowSupportedIntegration,
  WorkflowIntegrationActionStepType,
  Integration,
  TicketingIntegrationTicketTriggerConditionField,
  WorkflowType
} from '@kindo/universal';

export const DEFAULT_WORKFLOW_CATEGORY = 'Other';

export const UNTITLED_WORKFLOW_NAME = 'Untitled Agent';

export const AUTO_GENERATE_AGENT_STEPS_PLACEHOLDER =
  "Generate a workflow that takes a candidate's resume file as input and summarizes their experience. According to their experience, outputs questions to ask them.";

export enum ApiActionStepSegment {
  BODY = 'BODY',
  HEADERS = 'HEADERS',
  PARAMETERS = 'PARAMETERS'
}

export const API_ACTION_STEP_SEGMENT_OPTIONS = [
  {
    label: 'Headers',
    value: ApiActionStepSegment.HEADERS
  },
  {
    label: 'Parameters',
    value: ApiActionStepSegment.PARAMETERS
  },
  {
    label: 'Body',
    value: ApiActionStepSegment.BODY
  }
];
/** Workflow types */
export const WORKFLOW_TYPE_TO_ABBREVIATION: Record<WorkflowType, string> = {
  [WorkflowType.TRIGGERED]: 'TRIG',
  [WorkflowType.MANUAL]: 'WKFL',
  [WorkflowType.CHATBOT]: 'CHTB'
};

export const WORKFLOW_TYPE_TO_LABEL: Record<WorkflowType, string> = {
  [WorkflowType.TRIGGERED]: 'Triggered',
  [WorkflowType.MANUAL]: 'Workflow',
  [WorkflowType.CHATBOT]: 'Chatbot'
};

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
export const WORKFLOW_INTEGRATION_TO_ACTION: Record<
  WorkflowSupportedIntegration,
  WorkflowIntegrationActionStepType[]
> = {
  // TODO: Find a way to update "Priority" based on updating "Impact" and "Urgency" for ServiceNow
  // Related to [ENG-4551] https://linear.app/kindo/issue/ENG-4551/update-servicenow-priority-by-updating-impact-and-urgency
  [Integration.SERVICENOW]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
  ],
  [Integration.LINEAR]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
  ],
  [Integration.JIRA]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
  ],
  [Integration.AZURE_DEVOPS]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY,
    WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
  ],
  [Integration.GITHUB_ISSUES]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET,
    // Exclude UPDATE_TICKET_PRIORITY because field not supported by GitHub Issues
    WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
  ]
};

export const WORKFLOW_ACTION_LABELS: Record<
  WorkflowIntegrationActionStepType,
  string
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]:
    'Create a comment on ticket',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY]:
    'Update ticket priority',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE]:
    'Update ticket assignee'
};

export const WORKFLOW_ACTION_DESCRIPTIONS: Record<
  WorkflowIntegrationActionStepType,
  string
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]:
    'Takes the output from the previous step and writes it as a comment on the ticket',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY]:
    'Determine and update the priority of the ticket',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE]:
    'Determine and update the assignee of the ticket'
};

export const ACTION_STEP_TO_COMMENT_EXAMPLE: Record<
  WorkflowIntegrationActionStepType,
  string
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]: '',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY]:
    'Priority updated to HIGH by Kindo.\n\nReason: Based on the analysis of the ticket content and user impact, this issue requires immediate attention. The reported bug is affecting core functionality and multiple users have reported experiencing this problem.',
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE]:
    "Ticket assignee updated to John Doe by Kindo.\n\nReason: The ticket is marked as high priority due to a major functionality issue, and according to the user's instructions, all tickets marked as high priority should be assigned to John."
};

export const FIELD_PLACEHOLDERS: Record<
  TicketingIntegrationTicketTriggerConditionField,
  string
> = {
  [TicketingIntegrationTicketTriggerConditionField.STATUS]:
    'Status Value (e.g. Triage, in progress, todo)',
  [TicketingIntegrationTicketTriggerConditionField.TITLE]:
    'Title Value (e.g. .*urgent.*, Security, login issue)',
  [TicketingIntegrationTicketTriggerConditionField.DESCRIPTION]:
    'Description Value (e.g. .*urgent.*, Security, login issue)',
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_EMAIL]:
    'Assignee Email Value (e.g. jane@example.com, \\@kindo.ai$)',
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_NAME]:
    'Assignee Name Value',
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_EMAIL]:
    'Creator Email Value (e.g. jane@example.com, \\@kindo.ai$)',
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_NAME]:
    'Creator Name Value',
  [TicketingIntegrationTicketTriggerConditionField.PRIORITY]: 'Priority Value'
};

export function getFieldPlaceholder(
  field: TicketingIntegrationTicketTriggerConditionField | undefined
) {
  return field ? FIELD_PLACEHOLDERS[field] : 'Target value';
}

export const WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES: Record<
  WorkflowIntegrationActionStepType,
  WorkflowInputType[]
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]: [
    WorkflowInputType.TICKETING_INTEGRATION_TICKET
  ],
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY]: [
    WorkflowInputType.TICKETING_INTEGRATION_TICKET
  ],
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE]: [
    WorkflowInputType.TICKETING_INTEGRATION_TICKET
  ]
};

export const TICKETING_INTEGRATION_TICKET_TRIGGER_CONDITION_FIELD_ORDER = [
  TicketingIntegrationTicketTriggerConditionField.TITLE,
  TicketingIntegrationTicketTriggerConditionField.DESCRIPTION,
  TicketingIntegrationTicketTriggerConditionField.PRIORITY,
  TicketingIntegrationTicketTriggerConditionField.STATUS,
  TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_EMAIL,
  TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_NAME,
  TicketingIntegrationTicketTriggerConditionField.CREATOR_EMAIL,
  TicketingIntegrationTicketTriggerConditionField.CREATOR_NAME
] as const;

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
export const INTEGRATION_TO_WORKFLOW_INPUT_TYPES: Record<
  WorkflowSupportedIntegration,
  WorkflowInputType[]
> = {
  [Integration.SERVICENOW]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.LINEAR]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.JIRA]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.AZURE_DEVOPS]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.GITHUB_ISSUES]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET]
};

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
export function isIntegrationInWorkflowIntegrationToActionMap(
  integration: Integration
): integration is keyof typeof WORKFLOW_INTEGRATION_TO_ACTION {
  return Object.keys(WORKFLOW_INTEGRATION_TO_ACTION).includes(integration);
}
