import { Icon } from '~/components/core';
import { ButtonProps } from '~/components/core/Button/Button';

/**
 * DropdownTriggerButtonProps extends ButtonProps with the following modifications:
 * - Removes onClick/href (click handling done by Dropdown)
 * - Adds a hideChevron flag
 *
 * Note: hideChevron and any other non-ButtonProps should be stripped when rendering
 */
export type DropdownTriggerButtonProps = {
  buttonProps: Omit<ButtonProps, 'onClick' | 'href'>;
  hideChevron: boolean;
};
export const isIcon = (trigger: Icon | DropdownTriggerButtonProps | React.ReactNode): trigger is Icon => Object.values(Icon).includes(trigger as Icon);
export const isButton = (trigger: Icon | DropdownTriggerButtonProps | React.ReactNode): trigger is DropdownTriggerButtonProps => {
  const buttonLike = trigger as DropdownTriggerButtonProps;
  return typeof buttonLike === 'object' && buttonLike !== null && buttonLike.buttonProps !== undefined && typeof buttonLike.buttonProps === 'object' && typeof buttonLike.buttonProps.label === 'string' && typeof buttonLike.buttonProps.type === 'string' && typeof buttonLike.hideChevron === 'boolean';
};