import { PROVIDER_DISPLAY_NAMES } from '@kindo/universal';
import { useState } from 'react';
import { OrgModelsDlpFiltersConfig } from '../../DlpFilters/OrgModelsDlpFiltersConfig';
import { OrgProvidersDlpFiltersConfig } from '../../DlpFilters/OrgProvidersDlpFiltersConfig';
import { Icon, IconButton, IconButtonType, Modal, ModalWidth, TextIconColor, Typography, TypographySize } from '~/components/core';
import { UserGroupModelsDlpFiltersConfig } from '~/components/SettingsModal/OrgSettings/UserGroups/UserGroupSettings/UserGroupDlpFilters/UserGroupModelsDlpFiltersConfig';
import { UserGroupProvidersDlpFiltersConfig } from '~/components/SettingsModal/OrgSettings/UserGroups/UserGroupSettings/UserGroupDlpFilters/UserGroupProvidersDlpFiltersConfig';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { useGetAllAvailableOrgModels } from '~/hooks';
import { useMutateDlpConfigs } from '~/hooks/useMutateDlpConfigs';
import { ModelOrProvider } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
interface DlpFiltersConfigModalProps {
  canAccessSecuritySettings: boolean;
  modelOrProvider: ModelOrProvider;
  onClose: () => void;
  userGroupId: string | null;
}
const DlpFiltersConfigModal: React.FC<DlpFiltersConfigModalProps> = ({
  canAccessSecuritySettings,
  modelOrProvider,
  userGroupId,
  onClose
}) => {
  const {
    model,
    provider
  } = modelOrProvider;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const {
    adminSecurityConfig
  } = nextTrpc.useUtils();
  const {
    modelIdentifierToDisplayName
  } = useGetAllAvailableOrgModels();
  const {
    deleteDlpFiltersConfig
  } = useMutateDlpConfigs({
    model: model ?? null,
    provider: provider ?? null,
    userGroupId,
    onSettled: () => {
      if (!userGroupId && provider) {
        void adminSecurityConfig.listOrgProviderSecurityConfigs.refetch();
      } else if (!userGroupId && model) {
        void adminSecurityConfig.listOrgModelSecurityConfigs.refetch();
      } else if (userGroupId && provider) {
        void adminSecurityConfig.listUserGroupProviderSecurityConfigs.refetch();
      } else if (userGroupId && model) {
        void adminSecurityConfig.listUserGroupModelSecurityConfigs.refetch();
      }
    }
  });
  const renderDlpModalContent = () => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (true) {
      case provider && !userGroupId:
        return <OrgProvidersDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} provider={provider} />;
      case model && !userGroupId:
        return <OrgModelsDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} model={model} />;
      case provider && !!userGroupId:
        return <UserGroupProvidersDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} provider={provider} userGroupId={userGroupId} />;
      case model && !!userGroupId:
        return <UserGroupModelsDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} model={model} userGroupId={userGroupId} />;
      default:
        return null;
    }
  };
  const handleDeleteDlpFiltersConfig = () => {
    deleteDlpFiltersConfig();
    setShowDeleteConfirmation(false);
    onClose();
  };
  const displayName = model ? modelIdentifierToDisplayName[model] : provider && PROVIDER_DISPLAY_NAMES[provider];
  return <>
      <Modal onClose={onClose} open width={ModalWidth['3XL']}>
        <div css={{
        "maxHeight": "70vh",
        "overflowY": "scroll"
      }} data-testid="dlp-filters-modal-container">
          <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }} data-testid="dlp-filters-content-container">
            <div css={{
            "display": "flex",
            "gap": "1rem"
          }} data-testid="dlp-filters-header">
              <Typography color={TextIconColor.PRIMARY} size={TypographySize.H2}>
                {displayName} Custom Configuration
              </Typography>
              <IconButton color={TextIconColor.DESTRUCTIVE} data-testid="delete-config-button" icon={Icon.TRASH} onClick={() => setShowDeleteConfirmation(true)} type={IconButtonType.OUTLINED} />
            </div>
            {renderDlpModalContent()}
          </div>
        </div>
      </Modal>
      {showDeleteConfirmation && <ConfirmationModal confirmButtonLabel="Delete" data-testid="delete-confirmation-modal" header={`Delete ${displayName} Custom Configuration?`} onCancel={() => setShowDeleteConfirmation(false)} onConfirm={handleDeleteDlpFiltersConfig} open subtext="This action cannot be undone." />}
    </>;
};
export default DlpFiltersConfigModal;