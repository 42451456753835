import { Size } from '../constants';
import { TypographySize } from '../Typography';
import type { ButtonSize } from './Button.styles';
export enum ButtonType {
  INPUT_BUTTON = 'INPUT_BUTTON',
  NONE = 'NONE',
  OUTLINED = 'OUTLINED',
  OUTLINED_COLOR = 'OUTLINED_COLOR',
  SOLID = 'SOLID',
  SOLID_COLOR = 'SOLID_COLOR',
  SOLID_GRADIENT = 'SOLID_GRADIENT',
  TEXT = 'TEXT',
}
export const BUTTON_SIZE_TEXT_SIZES: Record<ButtonSize, TypographySize> = {
  [Size.X_SMALL]: TypographySize.X_SMALL,
  [Size.SMALL]: TypographySize.X_SMALL,
  [Size.MEDIUM]: TypographySize.SMALL,
  [Size.LARGE]: TypographySize.MEDIUM
};
export const BUTTON_SIZE_ICON_SIZES: Record<ButtonSize, Size> = {
  [Size.X_SMALL]: Size.X_SMALL,
  [Size.SMALL]: Size.SMALL,
  [Size.MEDIUM]: Size.MEDIUM,
  [Size.LARGE]: Size.MEDIUM
};