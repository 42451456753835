import { assertUnreachable, ChatAccessLevel, ChatUserGroupRole, FeatureFlags } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { CopyButton } from '../../shared';
import SharedPermissionRow from '../shared/SharedPermissionRow';
import { Button, ButtonType, Expand, Icon, Modal, Select, SelectOptionType, Size, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { BASE_NEXT_URL, getWorkstationRoute } from '~/constants';
import { ToastType, useCanShareChat, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { capitalizeFirstLetter } from '~/utils';
interface ShareChatModalProps {
  chatId: string;
  onClose: () => void;
}
const getChatOrgAccessLevelDisplayName = (accessLevel: ChatAccessLevel): string => {
  switch (accessLevel) {
    case ChatAccessLevel.RESTRICTED:
      return 'None';
    case ChatAccessLevel.EDIT:
      return 'Editor';
    case ChatAccessLevel.VIEW:
      return 'Viewer';
    default:
      return assertUnreachable(accessLevel);
  }
};
const getChatAccessLevelOrRoleDescription = (accessLevel: ChatAccessLevel | ChatUserGroupRole): string => {
  switch (accessLevel) {
    case ChatAccessLevel.EDIT:
    case ChatUserGroupRole.EDITOR:
      return 'Can send new and view all chat messages.';
    case ChatAccessLevel.VIEW:
    case ChatUserGroupRole.VIEWER:
      return 'Can view new and existing chat messages.';
    case ChatAccessLevel.RESTRICTED:
      return '';
    default:
      return assertUnreachable(accessLevel);
  }
};
const getChatOrgAccessLevelDescription = (accessLevel: ChatAccessLevel): string => {
  switch (accessLevel) {
    case ChatAccessLevel.RESTRICTED:
      return 'No organization access has been set for this chat.';
    case ChatAccessLevel.EDIT:
      return 'Anyone in the organization can edit this chat.';
    case ChatAccessLevel.VIEW:
      return 'Anyone in the organization can view this chat.';
    default:
      return assertUnreachable(accessLevel);
  }
};
const ShareChatModal: React.FC<ShareChatModalProps> = ({
  onClose,
  chatId
}) => {
  // Feature flags
  const isUserGroupsEnabled = useFlag(FeatureFlags.USER_GROUPS);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    canShareChatWithOrg,
    canShareChatWithUserGroup
  } = useCanShareChat(chatId);

  // Queries
  const {
    data: chatData,
    refetch: refetchChat
  } = nextTrpc.chats.get.useQuery({
    chatId
  });
  const chat = chatData?.item;
  const {
    data: usersUserGroups
  } = nextTrpc.userGroups.list.useQuery();
  const {
    data: userGroupShareOptionsData
  } = nextTrpc.chats.getChatUserGroupShareOptions.useQuery({
    chatId
  }, {
    enabled: isUserGroupsEnabled
  });
  const userGroupShareOptions = userGroupShareOptionsData?.items ?? [];
  const orgAccessLevel = chat?.orgAccess as ChatAccessLevel ?? ChatAccessLevel.RESTRICTED;
  const updateChatOrgAccessMutation = nextTrpc.chats.updateOrgAccess.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated chat access',
        type: ToastType.SUCCESS
      });
      await refetchChat();
    }
  });
  const updateChatUserGroupRoleMutation = nextTrpc.chats.updateUserGroupRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated user group chat access',
        type: ToastType.SUCCESS
      });
      await refetchChat();
    }
  });
  const removeChatUserGroupRoleMutation = nextTrpc.chats.removeUserGroupRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully removed user group chat access',
        type: ToastType.SUCCESS
      });
      await refetchChat();
    }
  });

  // Handlers
  const handleUpdateOrgAccess = async (accessLevel: ChatAccessLevel) => {
    try {
      await updateChatOrgAccessMutation.mutateAsync({
        chatId,
        orgAccess: accessLevel
      });
    } catch (error) {
      enqueueToast({
        message: 'Failed to update chat access',
        type: ToastType.ERROR
      });
      console.error('Failed to update chat access', error);
    }
  };
  const handleUpdateUserGroupRole = ({
    userGroupId,
    role
  }: {
    role: ChatUserGroupRole | null;
    userGroupId: string;
  }) => {
    // Remove user group if role is null
    if (role === null) {
      removeChatUserGroupRoleMutation.mutate({
        chatId,
        userGroupId
      });
      return;
    }
    updateChatUserGroupRoleMutation.mutate({
      chatId,
      userGroupId,
      role
    });
  };
  const renderSectionTitle = (title: string) => <Typography color={TextIconColor.PRIMARY} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
      {title}
    </Typography>;

  // List of ChatUserGroupRoles currently associated with this workflow
  const existingChatUserGroupRoles = chat?.userGroupRoles ?? [];
  const availableUserGroups = userGroupShareOptions.filter(group => !existingChatUserGroupRoles.some(role => role.userGroupId === group.id));
  // Chat.userGroupRole options
  const userGroupRoleOptions: SelectOptionType<ChatUserGroupRole | null>[] = [...[ChatUserGroupRole.VIEWER, ChatUserGroupRole.EDITOR].map(role => ({
    label: capitalizeFirstLetter(role.toLowerCase()),
    value: role,
    description: getChatAccessLevelOrRoleDescription(role)
  })), {
    label: 'Remove',
    value: null,
    destructive: true
  }];
  const showCopyChatLinkButton = orgAccessLevel !== ChatAccessLevel.RESTRICTED || existingChatUserGroupRoles?.length > 0 && isUserGroupsEnabled;
  return <Modal onClose={onClose} open>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "1rem"
    }} data-testid="share-chat-modal-container">
        <>
          {/* Title */}
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3}>
            Share Chat{chat?.title ? ` "${chat?.title}"` : ''}
          </Typography>
          {/* WorkflowUserGroupRole section */}
          {isUserGroupsEnabled && <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="share-chat-user-permissions-container">
              <div css={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "space-between",
            "borderTopWidth": "1px",
            "borderColor": "rgb(255 255 255 / 0.2)",
            "paddingTop": "1rem"
          }} data-testid="share-modal-access-title-action-container">
                {renderSectionTitle('User Group Permissions')}
                {/* Select user group */}
                <Dropdown closeOnClick data-id="chat_user_group_select" trigger={<Button disabled={availableUserGroups.length === 0} endIcon={Icon.PLUS} label="Add Group" onClick={() => {}} size={Size.MEDIUM} tooltip={availableUserGroups.length === 0 ? 'All possible user groups are already added' : ''} type={ButtonType.SOLID_COLOR} typographySize={TypographySize.X_SMALL} />}>
                  {availableUserGroups.length > 0 ? availableUserGroups.map(userGroup => <div data-testid={`user-group-item-${userGroup.id}`} key={userGroup.id} role="menuitem">
                          <DropdownItem key={userGroup.id} onClick={() => handleUpdateUserGroupRole({
                  userGroupId: userGroup.id,
                  role: ChatUserGroupRole.VIEWER
                })} title={userGroup.name} />
                        </div>) : null}
                </Dropdown>
              </div>
              {/* List of shared user groups */}
              <div css={{
            "display": "flex",
            "width": "100%",
            "paddingTop": "0.25rem",
            "paddingBottom": "0.25rem"
          }} data-testid="expand-container">
                <Expand disabled={existingChatUserGroupRoles?.length === 0} trigger={<Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                      {existingChatUserGroupRoles?.length === 0 ? 'This agent has not been shared with any user groups' : `User groups this agent has been shared with (${existingChatUserGroupRoles?.length})`}
                    </Typography>}>
                  <div css={{
                "display": "flex",
                "flexDirection": "column",
                "> :not([hidden]) ~ :not([hidden])": {
                  "--tw-divide-y-reverse": "0",
                  "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
                  "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
                  "borderColor": "rgb(255 255 255 / 0.1)"
                },
                "paddingLeft": "1.5rem"
              }} data-testid="share-workflow-user-permission-rows-container">
                    {!!existingChatUserGroupRoles && existingChatUserGroupRoles.map(userGroupRole => {
                  const isMember = !!usersUserGroups?.items?.find(ug => ug.id === userGroupRole.userGroupId);
                  return <SharedPermissionRow<ChatUserGroupRole> disabled={!canShareChatWithUserGroup} key={userGroupRole.userGroupId} onChange={newRole => {
                    handleUpdateUserGroupRole({
                      userGroupId: userGroupRole.userGroupId,
                      role: newRole
                    });
                  }} options={userGroupRoleOptions} permission={userGroupRole.role as ChatUserGroupRole} subtitle={isMember ? 'You are a member of this user group.' : ''} title={userGroupRole.userGroup.name} />;
                })}
                  </div>
                </Expand>
              </div>
            </div>}
          {/* Org Access */}
          <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0px"
        }} data-testid="section-title-and-description">
            <div css={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "space-between"
          }} data-testid="share-modal-general-access-title-select">
              {renderSectionTitle('Organization Access')}
              <Select data-id="chat_access_status" disabled={!canShareChatWithOrg} onChange={value => {
              void handleUpdateOrgAccess(value as ChatAccessLevel);
            }} options={[ChatAccessLevel.EDIT, ChatAccessLevel.VIEW, ChatAccessLevel.RESTRICTED].map(accessLevel => ({
              label: getChatOrgAccessLevelDisplayName(accessLevel),
              value: accessLevel,
              description: getChatAccessLevelOrRoleDescription(accessLevel)
            }))} placeholderLabel="Select Access Level" value={orgAccessLevel} />
            </div>
            <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
              {getChatOrgAccessLevelDescription(orgAccessLevel)}
            </Typography>
          </div>
          {showCopyChatLinkButton && <CopyButton data-id="chat_link_copy" label="Copy Chat Link" text={BASE_NEXT_URL + getWorkstationRoute({
          chatId
        })} tooltip="Share links only work for users who already have access to this agent via the above controls" />}
        </>
      </div>
    </Modal>;
};
export default ShareChatModal;