import { NonEmptyArray } from '../utils';

export enum Integration {
  AZURE_DEVOPS = 'AZURE_DEVOPS',
  BOX = 'BOX',
  DROPBOX = 'DROPBOX',
  GITHUB_ISSUES = 'GITHUB_ISSUES',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  JIRA = 'JIRA',
  LINEAR = 'LINEAR',
  ONEDRIVE = 'ONEDRIVE',
  SERVICENOW = 'SERVICENOW',
  SLACK = 'SLACK'
}

export function isIntegration(value: unknown): value is Integration {
  return Object.values(Integration).includes(value as Integration);
}

export const INTEGRATION_DISPLAY_NAMES = {
  [Integration.AZURE_DEVOPS]: 'Azure DevOps',
  [Integration.BOX]: 'Box',
  [Integration.DROPBOX]: 'Dropbox',
  [Integration.GITHUB_ISSUES]: 'GitHub Issues',
  [Integration.GOOGLE_DRIVE]: 'Google Drive',
  [Integration.JIRA]: 'Jira',
  [Integration.LINEAR]: 'Linear',
  [Integration.ONEDRIVE]: 'OneDrive',
  [Integration.SERVICENOW]: 'ServiceNow',
  [Integration.SLACK]: 'Slack'
} as const satisfies Record<Integration, string>;

const NON_MERGE_INTEGRATIONS = [
  Integration.SLACK
] as const satisfies readonly Integration[];

type NonMergeIntegration = (typeof NON_MERGE_INTEGRATIONS)[number];

export type MergeIntegration = Exclude<Integration, NonMergeIntegration>;

export const INTEGRATION_TO_MERGE_INTEGRATION_SLUG = {
  [Integration.AZURE_DEVOPS]: 'azure-devops',
  [Integration.BOX]: 'box',
  [Integration.DROPBOX]: 'dropbox',
  [Integration.GITHUB_ISSUES]: 'github-issues',
  [Integration.GOOGLE_DRIVE]: 'google-drive',
  [Integration.JIRA]: 'jira',
  [Integration.LINEAR]: 'linear',
  [Integration.ONEDRIVE]: 'onedrive',
  [Integration.SERVICENOW]: 'servicenow'
} as const satisfies Record<MergeIntegration, string>;

// Based on Merge's categories, but decoupled
export enum IntegrationCategory {
  ACCOUNTING = 'ACCOUNTING',
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  FILE_STORAGE = 'FILE_STORAGE',
  HIRING = 'HIRING',
  HR = 'HR',
  MARKETING = 'MARKETING',
  OTHER = 'OTHER',
  TICKETING = 'TICKETING'
}

export function isIntegrationCategory(
  category: string
): category is IntegrationCategory {
  return Object.values(IntegrationCategory).includes(
    category as IntegrationCategory
  );
}

// MergeIntegrationCategory is the subset of IntegrationCategory that Merge supports.
// It is NOT Merge's values for their categories.
export type MergeIntegrationCategory = Exclude<
  IntegrationCategory,
  IntegrationCategory.OTHER
>;

// Note: These values map to the values in MergeCategory in node-utils
// TODO: Consolidate and use that const/enum/type here
export const INTEGRATION_CATEGORY_TO_MERGE_CATEGORY = {
  [IntegrationCategory.CUSTOMER_MANAGEMENT]: 'crm',
  [IntegrationCategory.MARKETING]: 'mktg',
  [IntegrationCategory.HR]: 'hris',
  [IntegrationCategory.TICKETING]: 'ticketing',
  [IntegrationCategory.FILE_STORAGE]: 'filestorage',
  [IntegrationCategory.ACCOUNTING]: 'accounting',
  [IntegrationCategory.HIRING]: 'ats'
} as const satisfies Record<MergeIntegrationCategory, string>;

export const INTEGRATION_TO_CATEGORIES = {
  [Integration.AZURE_DEVOPS]: [IntegrationCategory.TICKETING],
  [Integration.BOX]: [IntegrationCategory.FILE_STORAGE],
  [Integration.DROPBOX]: [IntegrationCategory.FILE_STORAGE],
  [Integration.GITHUB_ISSUES]: [IntegrationCategory.TICKETING],
  [Integration.GOOGLE_DRIVE]: [IntegrationCategory.FILE_STORAGE],
  [Integration.JIRA]: [IntegrationCategory.TICKETING],
  [Integration.LINEAR]: [IntegrationCategory.TICKETING],
  [Integration.ONEDRIVE]: [IntegrationCategory.FILE_STORAGE],
  [Integration.SERVICENOW]: [IntegrationCategory.TICKETING],
  [Integration.SLACK]: [IntegrationCategory.OTHER]
} as const satisfies Record<Integration, NonEmptyArray<IntegrationCategory>>;

export const INTEGRATION_TO_SQUARE_IMAGE = {
  [Integration.AZURE_DEVOPS]:
    'https://merge-api-production.s3.amazonaws.com/media/Azure_DevOps_Square_Logo.png',
  [Integration.BOX]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformBox_square.png',
  [Integration.DROPBOX]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDropbox_square_mLnVhny.png',
  [Integration.GITHUB_ISSUES]:
    'https://merge-api-production.s3.amazonaws.com/media/Github_Issues_Square_Logo.png',
  [Integration.GOOGLE_DRIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformGoogle_Drive_square.png',
  [Integration.JIRA]:
    'https://merge-api-production.s3.amazonaws.com/media/Jira_Square_Logo.png',
  [Integration.LINEAR]:
    'https://merge-api-production.s3.amazonaws.com/media/Linear_Square_Logo.png',
  [Integration.ONEDRIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformOnedrive_square.png',
  [Integration.SERVICENOW]:
    'https://merge-api-production.s3.amazonaws.com/media/ServiceNow_Square_Logo.png',
  [Integration.SLACK]: 'https://kindo-assets.s3.amazonaws.com/slack.png'
} as const satisfies Record<Integration, string>;

export const getIntegrationFromMergeIntegrationSlug = (
  mergeIntegrationSlug: string | null
): Integration | undefined =>
  Object.entries(INTEGRATION_TO_MERGE_INTEGRATION_SLUG).find(
    ([, value]) => value === mergeIntegrationSlug
  )?.[0] as Integration;

export const getMergeIntegrationSlugFromIntegration = (
  integration: MergeIntegration
): string => INTEGRATION_TO_MERGE_INTEGRATION_SLUG[integration];

export const getIntegrationDisplayName = (integration: Integration): string =>
  INTEGRATION_DISPLAY_NAMES[integration];

/**
 * Integrations listed here will allow any user to connect,
 * otherwise it will encourage users to contact sales to request
 * the enablement of a particular integration.
 *
 * The isSupportedIntegrationAndCategory function should always
 * be used to determine which integrations are supported.
 */
const BASE_DISPLAYED_INTEGRATIONS = [
  Integration.BOX,
  // We need to determine why we haven't been supporting Dropbox before we display it.
  // Integration.DROPBOX,
  Integration.GOOGLE_DRIVE,
  Integration.ONEDRIVE,
  Integration.SLACK,
  Integration.SERVICENOW,
  Integration.LINEAR,
  Integration.JIRA,
  Integration.AZURE_DEVOPS
] as const satisfies readonly Integration[];

// For testing new integrations that are not yet available to all users
const FEATURE_FLAG_DISPLAYED_INTEGRATIONS: readonly Integration[] = [];

export type BaseDisplayedIntegration =
  (typeof BASE_DISPLAYED_INTEGRATIONS)[number];

export type FeatureFlagDisplayedIntegration =
  (typeof FEATURE_FLAG_DISPLAYED_INTEGRATIONS)[number];

const SUPPORTED_INTEGRATION_CATEGORIES = [
  IntegrationCategory.FILE_STORAGE,
  IntegrationCategory.TICKETING,
  IntegrationCategory.OTHER
] as const satisfies readonly IntegrationCategory[];

export function isBaseDisplayedSupportedIntegrationAndCategory(
  integration: Integration | undefined,
  category: IntegrationCategory
): integration is BaseDisplayedIntegration {
  return (
    BASE_DISPLAYED_INTEGRATIONS.includes(integration as any) &&
    SUPPORTED_INTEGRATION_CATEGORIES.includes(category as any)
  );
}

export function isFeatureFlagDisplayedIntegration(
  integration: Integration | undefined,
  category: IntegrationCategory
): integration is FeatureFlagDisplayedIntegration {
  return (
    FEATURE_FLAG_DISPLAYED_INTEGRATIONS.includes(integration as any) &&
    SUPPORTED_INTEGRATION_CATEGORIES.includes(category as any)
  );
}

export function isSupportedIntegration(
  integration: Integration | undefined
): integration is BaseDisplayedIntegration | FeatureFlagDisplayedIntegration {
  return (
    BASE_DISPLAYED_INTEGRATIONS.includes(integration as any) ||
    FEATURE_FLAG_DISPLAYED_INTEGRATIONS.includes(integration as any)
  );
}

// Supported integrations is a union of prod displayed integrations and
// feature flag displayed integrations
export function isSupportedIntegrationAndCategory(
  integration: Integration | undefined,
  category: IntegrationCategory
) {
  return (
    integration !== undefined &&
    (isBaseDisplayedSupportedIntegrationAndCategory(integration, category) ||
      isFeatureFlagDisplayedIntegration(integration, category))
  );
}

// TODO: [ENG-3778] Need to clean up/reorganize how we are relating
// merge categories to integrations to input types
export const WORKFLOW_SUPPORTED_INTEGRATIONS = [
  Integration.SERVICENOW,
  Integration.LINEAR,
  Integration.JIRA,
  Integration.AZURE_DEVOPS,
  Integration.GITHUB_ISSUES
] as const satisfies readonly (
  | BaseDisplayedIntegration
  | FeatureFlagDisplayedIntegration
)[];

export type WorkflowSupportedIntegration =
  (typeof WORKFLOW_SUPPORTED_INTEGRATIONS)[number];

export function isWorkflowSupportedIntegration(
  integration: Integration | string
): integration is WorkflowSupportedIntegration {
  return (
    WORKFLOW_SUPPORTED_INTEGRATIONS.includes(integration as any) ||
    Object.values(INTEGRATION_DISPLAY_NAMES).includes(integration as any)
  );
}

export function isMergeIntegration(
  integration: Integration
): integration is MergeIntegration {
  return !NON_MERGE_INTEGRATIONS.includes(integration as any);
}

export function getSquareImageFromIntegrationName(integrationName: string) {
  const integration = Object.entries(
    INTEGRATION_TO_MERGE_INTEGRATION_SLUG
  ).find(([, value]) => value === integrationName)?.[0] as Integration;
  return integration ? INTEGRATION_TO_SQUARE_IMAGE[integration] : undefined;
}

export function getIntegrationFromIntegrationDisplayName(
  integrationName: string
) {
  return Object.entries(INTEGRATION_DISPLAY_NAMES).find(
    ([, value]) => value === integrationName
  )?.[0] as Integration;
}

export function getIntegrationCategoryFromMergeCategory(
  mergeIntegrationCategory: string
): IntegrationCategory | undefined {
  return Object.entries(INTEGRATION_CATEGORY_TO_MERGE_CATEGORY).find(
    ([, value]) => value === mergeIntegrationCategory
  )?.[0] as IntegrationCategory;
}

// The Merge API times out for files larger than roughly 0.4 GB. Until they've fixed
// this issue, we skip files larger than 0.4 GB.
export const MERGE_DOWNLOAD_FILE_SIZE_LIMIT = 4 * 100 * 1000 * 1000; // 0.4 GB

export const getIntegrationCategoriesFromIntegration = (
  integration: Integration
): IntegrationCategory[] => INTEGRATION_TO_CATEGORIES[integration];

export const integrationIsInCategory = (
  integration: Integration,
  category: IntegrationCategory
) => getIntegrationCategoriesFromIntegration(integration).includes(category);
