import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { DlpFiltersConfig } from '../DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '../DlpFiltersConfig/DlpFilters.types';
import { useGetAllAvailableOrgModels } from '~/hooks';
import { useMutateDlpConfigs } from '~/hooks/useMutateDlpConfigs';
import { nextTrpc } from '~/trpc';
interface OrgModelsDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  model: string; // DO NOT REMOVE COMMENT: was Llm
}
const OrgModelsDlpFiltersConfig: React.FC<OrgModelsDlpFiltersProps> = ({
  canAccessSecuritySettings,
  model
}) => {
  const {
    modelIdentifierToDisplayName
  } = useGetAllAvailableOrgModels();
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listOrgModelCustomDlpFilters.useQuery({
    model
  });
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getOrgModelDlpFiltersConfig.useQuery({
    model
  }, {
    select: transformDlpFiltersConfigResponse
  });

  // Custom Hooks
  const {
    updatingFilters,
    updateDlpFiltersConfig,
    createCustomDlpFilter,
    deleteCustomDlpFilter
  } = useMutateDlpConfigs({
    model,
    provider: null,
    userGroupId: null,
    onSettled: () => {
      refetchDlpFiltersConfig();
      refetchCustomFilters();
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => {
    updateDlpFiltersConfig({
      filter: dlpFilter,
      policy: newPolicy
    });
  };
  const handleCreateCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createCustomDlpFilter({
      policy,
      type,
      value
    });
  };
  const handleDeleteCustomDlpFilter = (value: string) => {
    deleteCustomDlpFilter({
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={handleCreateCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={handleDeleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} targetResourceLabel={modelIdentifierToDisplayName[model] ?? ''} updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} updatingFilters={updatingFilters} />;
};
export default OrgModelsDlpFiltersConfig;