/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { DecoratorNode, LexicalNode, NodeKey, SerializedLexicalNode } from 'lexical';
import { ReactElement } from 'react';
import { WorkflowLexicalNodeType } from '../workflowLexicalNodes.consts';
import WorkflowStaticContentNodeComponent from './WorkflowStaticContentNodeComponent';
export interface SerializedWorkflowStaticContentNode extends SerializedLexicalNode {
  contentId: string;
  contentName: string;
}

// https://github.com/facebook/lexical/blob/main/examples/decorators.md
// https://lexical.dev/docs/concepts/nodes#extending-decoratornode
export class WorkflowStaticContentNode extends DecoratorNode<ReactElement> {
  private __contentId: string;
  private __contentName: string;
  public constructor(contentId: string, contentName: string, key?: NodeKey) {
    super(key);
    this.__contentId = contentId;
    this.__contentName = contentName;
  }
  public override createDOM(): HTMLElement {
    const span = document.createElement('span');
    span.style.display = 'inline-flex';
    span.style.verticalAlign = 'middle';
    span.style.marginInline = '4px';
    return span;
  }
  public override decorate(): ReactElement {
    return <WorkflowStaticContentNodeComponent contentId={this.__contentId} contentName={this.__contentName} setContentIdAndName={(contentId, contentName) => this.setContentIdAndName(contentId, contentName)} />;
  }
  public override exportJSON(): SerializedWorkflowStaticContentNode {
    return {
      contentId: this.__contentId,
      contentName: this.__contentName,
      type: this.getType(),
      version: 1
    };
  }
  public getContentId(): string {
    return this.__contentId;
  }
  public importJSON(json: SerializedWorkflowStaticContentNode): void {
    this.__contentId = json.contentId;
  }
  public setContentIdAndName(contentId: string, contentName: string): void {
    const writable = this.getWritable();
    writable.__contentId = contentId;
    writable.__contentName = contentName;
  }
  public override updateDOM(): boolean {
    return false;
  }
  public static override getType(): string {
    return WorkflowLexicalNodeType.WORKFLOW_STATIC_CONTENT;
  }
  public static override clone(node: WorkflowStaticContentNode): WorkflowStaticContentNode {
    return new WorkflowStaticContentNode(node.__contentId, node.__contentName, node.__key);
  }
}
export function $createWorkflowStaticContentNode({
  contentId,
  contentName
}: {
  contentId: string;
  contentName: string;
}): WorkflowStaticContentNode {
  return new WorkflowStaticContentNode(contentId, contentName);
}
export function $isWorkflowStaticContentNode(node: LexicalNode | null | undefined): node is WorkflowStaticContentNode {
  return node instanceof WorkflowStaticContentNode;
}