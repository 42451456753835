import SettingsSection from '../SettingsSection';
import { SecretCreation } from './SecretCreation';
import SecretTable from './SecretTable/SecretTable';
import { nextTrpc } from '~/trpc';
const SecretVaultSettingsTab = () => {
  const {
    data: secretsData,
    refetch: refetchSecrets,
    isLoading: isLoadingSecrets
  } = nextTrpc.secrets.list.useQuery({});
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1.5rem"
  }} data-testid="secret-vault-settings-container">
      <SettingsSection description="A secure location to manage all of your secrets." title="Secrets Vault">
        <SecretCreation refetchSecrets={refetchSecrets} secrets={secretsData?.map(secret => ({
        secretId: secret.id,
        secretLabel: secret.label
      })) ?? []} />
      </SettingsSection>

      <SecretTable isLoadingSecrets={isLoadingSecrets} refetchSecrets={refetchSecrets} secrets={secretsData?.map(secret => ({
      ...secret,
      createdAt: new Date(secret.createdAt),
      updatedAt: new Date(secret.updatedAt)
    })) ?? []} />
    </div>;
};
export default SecretVaultSettingsTab;