import SettingsSection from '../../../SettingsSection';
import { Size, Table, Toggle } from '~/components/core';
import { ToastType, useOrgSettings, useToast } from '~/hooks';
const CodeSandboxSetting = () => {
  const {
    orgSettings,
    updateOrgSetting,
    isLoading,
    refetchOrgSettings
  } = useOrgSettings();
  const {
    enqueueToast
  } = useToast();
  return <SettingsSection title="Chat Settings">
      <Table columns={[{
      title: 'Feature',
      width: {
        "flexGrow": "1"
      }
    }, {
      title: 'Enabled',
      width: {
        "width": "16.666667%"
      }
    }]} loading={isLoading} noRowsText="No data" rows={[{
      key: 'code-sandbox',
      cells: ['Code Sandbox', <Toggle checked={!!orgSettings?.codeSandboxEnabled} disabled={isLoading} key="code-sandbox-toggle" onToggle={() => {
        updateOrgSetting('codeSandboxEnabled', !orgSettings?.codeSandboxEnabled, () => {
          refetchOrgSettings();
          enqueueToast({
            message: 'Code sandbox updated.',
            type: ToastType.SUCCESS
          });
        }, error => {
          enqueueToast({
            message: error.data?.kindoErrorMessage || 'Failed to update code sandbox.',
            type: ToastType.ERROR
          });
        });
      }} size={Size.SMALL} />]
    }]} />
    </SettingsSection>;
};
export default CodeSandboxSetting;