import { tv } from 'tailwind-variants';
import { createEnumVariants } from '../../../utils/commonTailwindVariants.utils';
import { TEXT_COLOR_CLASSES } from '../constants/colors.consts';
import { TypographyAlignment, TypographyCasing, TypographyFont, TypographyLetterSpacing, TypographySize, TypographyTransition, TypographyWeight, TypographyWrap } from './Typography.consts';
export const typographyStyles = tv({
  base: 'overflow-hidden text-ellipsis',
  variants: {
    alignment: createEnumVariants<typeof TypographyAlignment>({
      [TypographyAlignment.CENTER]: 'text-center',
      [TypographyAlignment.LEFT]: 'text-left',
      [TypographyAlignment.RIGHT]: 'text-right',
      [TypographyAlignment.JUSTIFY]: 'text-justify'
    }),
    casing: createEnumVariants<typeof TypographyCasing>({
      [TypographyCasing.LOWERCASE]: 'lowercase',
      [TypographyCasing.NONE]: '',
      [TypographyCasing.UPPERCASE]: 'uppercase',
      [TypographyCasing.CAPITALIZE]: 'capitalize'
    }),
    color: TEXT_COLOR_CLASSES,
    fitContent: {
      true: 'min-w-fit'
    },
    font: createEnumVariants<typeof TypographyFont>({
      [TypographyFont.INTERACTIVE]: 'font-oxanium',
      [TypographyFont.HEADING]: 'font-ibmPlexMono',
      [TypographyFont.PARAGRAPH]: 'font-ibmPlexSans'
    }),
    fullWidth: {
      true: 'w-full'
    },
    isLink: {
      true: 'hover:opacity-50'
    },
    italic: {
      true: 'italic'
    },
    letterSpacing: createEnumVariants<typeof TypographyLetterSpacing>({
      [TypographyLetterSpacing.NORMAL]: 'tracking-normal',
      [TypographyLetterSpacing.WIDE]: 'tracking-wide'
    }),
    size: createEnumVariants<typeof TypographySize>({
      [TypographySize.XX_SMALL]: 'text-[10px]',
      [TypographySize.X_SMALL]: 'text-xs',
      [TypographySize.SMALL]: 'text-sm',
      [TypographySize.MEDIUM]: 'text-base',
      [TypographySize.LARGE]: 'text-lg',
      [TypographySize.H4]: 'text-xl',
      [TypographySize.H3]: 'text-2xl',
      [TypographySize.H2]: 'text-3xl',
      [TypographySize.H1]: 'text-4xl'
    }),
    underline: {
      true: 'underline'
    },
    weight: createEnumVariants<typeof TypographyWeight>({
      [TypographyWeight.BOLD]: 'font-bold',
      [TypographyWeight.LIGHT]: 'font-light',
      [TypographyWeight.MEDIUM]: 'font-medium',
      [TypographyWeight.NORMAL]: 'font-normal',
      [TypographyWeight.SEMI_BOLD]: 'font-semibold',
      [TypographyWeight.SEMI_LIGHT]: 'font-semilight',
      [TypographyWeight.SEMI_NORMAL]: 'font-seminormal'
    }),
    wrap: createEnumVariants<typeof TypographyWrap>({
      [TypographyWrap.NO_WRAP]: 'whitespace-nowrap',
      [TypographyWrap.NORMAL]: 'whitespace-normal',
      [TypographyWrap.PRE_WRAP]: 'whitespace-pre-wrap'
    }),
    transition: createEnumVariants<typeof TypographyTransition>({
      [TypographyTransition.ALL]: 'transition-all',
      [TypographyTransition.COLORS]: 'transition-colors',
      [TypographyTransition.NONE]: 'transition-none',
      [TypographyTransition.OPACITY]: 'transition-opacity'
    })
  }
});