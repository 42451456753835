import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { DlpFiltersConfig } from '~/components/SettingsModal/SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '~/components/SettingsModal/SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig/DlpFilters.types';
import { useGetAllAvailableOrgModels } from '~/hooks';
import { useMutateDlpConfigs } from '~/hooks/useMutateDlpConfigs';
import { nextTrpc } from '~/trpc';
interface UserGroupModelsDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  model: string; // DO NOT REMOVE COMMENT: was Llm
  userGroupId: string;
}
const UserGroupModelsDlpFiltersConfig: React.FC<UserGroupModelsDlpFiltersProps> = ({
  canAccessSecuritySettings,
  model,
  userGroupId
}) => {
  // Custom Hook
  const {
    modelIdentifierToDisplayName
  } = useGetAllAvailableOrgModels();
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listUserGroupModelCustomDlpFilters.useQuery({
    userGroupId,
    model
  });
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getUserGroupModelDlpFiltersConfig.useQuery({
    userGroupId,
    model
  }, {
    select: transformDlpFiltersConfigResponse
  });

  // Custom Hooks
  const {
    updatingFilters,
    updateDlpFiltersConfig,
    createCustomDlpFilter,
    deleteCustomDlpFilter
  } = useMutateDlpConfigs({
    model,
    userGroupId,
    provider: null,
    onSettled: () => {
      refetchDlpFiltersConfig();
      refetchCustomFilters();
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => {
    updateDlpFiltersConfig({
      filter: dlpFilter,
      policy: newPolicy
    });
  };
  const handleCreateCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createCustomDlpFilter({
      policy,
      type,
      value
    });
  };
  const handleDeleteCustomDlpFilter = (value: string) => {
    deleteCustomDlpFilter({
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={handleCreateCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={handleDeleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} targetResourceLabel={modelIdentifierToDisplayName[model] ?? ''} updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} updatingFilters={updatingFilters} />;
};
export default UserGroupModelsDlpFiltersConfig;