import { DlpFiltersConfig } from '@prisma/client';

import { OmitStrict } from '../utils';

export enum DlpFilterPolicy {
  PASSTHROUGH = 'PASSTHROUGH',
  REDACT = 'REDACT'
}

export enum CustomDlpFilterType {
  LITERAL = 'LITERAL',
  REGEX = 'REGEX'
}

export type DlpFilter = keyof OmitStrict<DlpFiltersConfig, 'id'>;

export const DLP_FILTER_DISPLAY_NAMES: Record<DlpFilter, string> = {
  creditCard: 'Credit Card',
  crypto: 'Crypto',
  dateTime: 'Date or Time',
  emailAddress: 'Email Address',
  ibanCode: 'International Banking Account Number',
  ipAddress: 'IP Address',
  nrp: 'NRP',
  location: 'Location',
  person: 'Person',
  phoneNumber: 'Phone Number',
  medicalLicense: 'Medical License',
  url: 'URL',
  usBankNumber: 'US Bank Number',
  usDriverLicense: 'US Driver License',
  usITIN: 'US ITIN',
  usPassport: 'US Passport',
  usSSN: 'US Social Security Number'
};

export const DLP_FILTER_FIELDS: DlpFilter[] = [
  'creditCard',
  'crypto',
  'dateTime',
  'emailAddress',
  'ibanCode',
  'ipAddress',
  'nrp',
  'location',
  'person',
  'phoneNumber',
  'medicalLicense',
  'url',
  'usBankNumber',
  'usDriverLicense',
  'usITIN',
  'usPassport',
  'usSSN'
];

export const DEFAULT_DLP_FILTER_FIELDS: Record<DlpFilter, DlpFilterPolicy> = {
  creditCard: DlpFilterPolicy.PASSTHROUGH,
  crypto: DlpFilterPolicy.PASSTHROUGH,
  dateTime: DlpFilterPolicy.PASSTHROUGH,
  emailAddress: DlpFilterPolicy.PASSTHROUGH,
  ibanCode: DlpFilterPolicy.PASSTHROUGH,
  ipAddress: DlpFilterPolicy.PASSTHROUGH,
  nrp: DlpFilterPolicy.PASSTHROUGH,
  location: DlpFilterPolicy.PASSTHROUGH,
  person: DlpFilterPolicy.PASSTHROUGH,
  phoneNumber: DlpFilterPolicy.PASSTHROUGH,
  medicalLicense: DlpFilterPolicy.PASSTHROUGH,
  url: DlpFilterPolicy.PASSTHROUGH,
  usBankNumber: DlpFilterPolicy.PASSTHROUGH,
  usDriverLicense: DlpFilterPolicy.PASSTHROUGH,
  usITIN: DlpFilterPolicy.PASSTHROUGH,
  usPassport: DlpFilterPolicy.PASSTHROUGH,
  usSSN: DlpFilterPolicy.PASSTHROUGH
};
