import _styled from "styled-components";
import { Integration, INTEGRATION_DISPLAY_NAMES, isSupportedIntegration } from '@kindo/universal';
import React from 'react';
import { Size, Table, Toggle, ToolTip } from '~/components/core';
import { useCanAccessSecuritySettings } from '~/hooks';
type IntegrationAccessTableProps = {
  handleIntegrationToggle: (integration: Integration, isChecked: boolean) => void;
  integrationConfigs: {
    accessEnabled: boolean;
    integration: Integration;
  }[];
};
const ToolTipSpanWrapper = _styled.span({
  "width": "fit-content"
});
const IntegrationAccessTable: React.FC<IntegrationAccessTableProps> = ({
  integrationConfigs,
  handleIntegrationToggle
}) => {
  const {
    userCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  return <Table columns={[{
    title: 'Name'
  }, {
    title: 'Status',
    width: {
      "width": "16.666667%"
    }
  }]} noRowsText="No integration settings available at this time." rows={integrationConfigs.map(({
    accessEnabled,
    integration
  }) => {
    const isSupported = isSupportedIntegration(integration);
    return {
      key: integration,
      cells: [INTEGRATION_DISPLAY_NAMES[integration], <ToolTip key={`user-group-integration-access-toggle-${integration}`} content="Contact sales to enable this integration" disabled={isSupported}>
              <ToolTipSpanWrapper>
                <Toggle checked={accessEnabled && isSupported} disabled={!userCanAccessSecuritySettings || !isSupported} onToggle={(isChecked: boolean) => handleIntegrationToggle(integration, isChecked)} size={Size.SMALL} />
              </ToolTipSpanWrapper>
            </ToolTip>]
    };
  })} />;
};
export default IntegrationAccessTable;