import { tv } from 'tailwind-variants';
export const configurationHeaderStyles = tv({
  base: 'bg-gray-dark flex h-10 cursor-pointer items-center backdrop-blur-sm transition-all duration-500',
  variants: {
    isExpanded: {
      true: 'rounded-t-lg',
      false: 'rounded-lg'
    }
  }
});
export const contentContainerStyles = tv({
  base: 'bg-blue-chat-background border-gray-dark flex flex-col gap-4 overflow-hidden overflow-y-auto rounded-b-lg border border-t-0 p-4 transition-all duration-300 ease-in-out',
  variants: {
    isExpanded: {
      true: 'max-h-[calc(70vh-2.5rem)] opacity-100',
      false: 'max-h-0 border-0 p-0 opacity-0'
    }
  }
});