import { OrgConfig } from './OrgConfig';
import { SetupInstructions } from './SetupInstructions';
export enum PluginsTab {
  ORG_CONFIG = 'ORG_CONFIG',
  SETUP_INSTRUCTIONS = 'SETUP_INSTRUCTIONS',
}
export const PLUGINS_TAB_DISPLAY_NAMES: Record<PluginsTab, string> = {
  [PluginsTab.SETUP_INSTRUCTIONS]: 'Setup Instructions',
  [PluginsTab.ORG_CONFIG]: 'Your Configuration'
};
export const PLUGINS_TAB_COMPONENTS: Record<PluginsTab, React.ReactNode> = {
  [PluginsTab.SETUP_INSTRUCTIONS]: <SetupInstructions />,
  [PluginsTab.ORG_CONFIG]: <OrgConfig />
};
export const ORDERED_PLUGINS_TABS: PluginsTab[] = [PluginsTab.ORG_CONFIG, PluginsTab.SETUP_INSTRUCTIONS];