import { WORKFLOW_TRIGGER_ENTERPRISE_TOOLTIP } from './WorkflowBuilderCanvas.utils';
import { Icon } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
import { DropdownItem } from '~/components/Dropdown/DropdownItem';
import { WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES } from '~/constants';
import { useAppDispatch } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { Workflow } from '~/types';
interface AddWorkflowStepButtonProps {
  disabled: boolean;
  isEnterpriseOrg: boolean;
  newStepNumber: number;
  workflow: Workflow;
}
const AddWorkflowStepButton = ({
  workflow,
  newStepNumber,
  isEnterpriseOrg,
  disabled
}: AddWorkflowStepButtonProps) => {
  const dispatch = useAppDispatch();
  const {
    user
  } = useUserInfo();
  const isCreator = user?.id === workflow.creator.id;
  const createNewLlmStep = (stepNumber: number) => dispatch(workflowBuilderActions.createNewLlmStep({
    stepNumber
  }));
  const createNewActionStep = (stepNumber: number) => dispatch(workflowBuilderActions.createNewActionStep({
    stepNumber
  }));
  const createNewApiActionStep = (stepNumber: number) => dispatch(workflowBuilderActions.createNewApiActionStep({
    stepNumber
  }));

  // Check if action steps can be added based on:
  // 1. The workflow having a trigger
  // 2. The workflow's inputs having matching input types for at least one action
  // (meaning every input type required by that action has a matching workflow input)
  const canAddIntegrationActionStep = workflow.triggers.length > 0 && isEnterpriseOrg && Object.entries(WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES).some(([, inputTypes]) => inputTypes.every(inputType => workflow?.inputs.some(input => input.type === inputType)));
  const canAddApiActionStep = isCreator && isEnterpriseOrg;
  const getDisabledActionStepTooltip = () => {
    if (!isEnterpriseOrg) {
      return WORKFLOW_TRIGGER_ENTERPRISE_TOOLTIP;
    }
    if (!(workflow.triggers.length > 0)) {
      return 'Add a trigger to create an action step';
    }
    return undefined;
  };
  const getDisabledApiActionStepTooltip = () => {
    if (!isCreator) {
      return 'Only the agent creator can add API Action steps';
    }
    if (!isEnterpriseOrg) {
      return 'Upgrade to Enterprise to add API Action steps';
    }
    return undefined;
  };
  return <div css={{
    "display": "flex",
    "width": "100%",
    "alignItems": "center"
  }} data-testid="step-button-container">
      <div css={{
      "height": "1px",
      "flex": "1 1 0%",
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }} data-testid="horizontal-line-left" />
      <div css={{
      "marginLeft": "1rem",
      "marginRight": "1rem"
    }} data-testid="button-wrapper">
        <Dropdown closeOnClick disabled={disabled} highlightTriggerOnHover trigger={Icon.PLUS}>
          <DropdownItem onClick={() => createNewLlmStep(newStepNumber)} title="Add LLM step" />
          <DropdownItem disabled={!canAddIntegrationActionStep} onClick={() => createNewActionStep(newStepNumber)} title="Add Action step" tooltip={!canAddIntegrationActionStep ? getDisabledActionStepTooltip() : undefined} />
          <DropdownItem disabled={!canAddApiActionStep} onClick={() => createNewApiActionStep(newStepNumber)} title="Add API Action step" tooltip={!canAddApiActionStep ? getDisabledApiActionStepTooltip() : undefined} />
        </Dropdown>
      </div>
      <div css={{
      "height": "1px",
      "flex": "1 1 0%",
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }} data-testid="horizontal-line-right" />
    </div>;
};
export default AddWorkflowStepButton;