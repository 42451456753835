import _styled from "styled-components";
import { TextIconColor, Icon, Icons, Size, Typography } from '~/components/core';
import { TypographyFont, TypographySize, TypographyWeight, TypographyWrap } from '~/components/core/Typography';
const SelectOptionContainer = _styled.li<{
  $destructive: boolean;
  $disabled: boolean;
  $isSelectedValue: boolean;
}>(({
  $destructive,
  $disabled,
  $isSelectedValue
}) => [{
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))"
  }
}, $destructive && {
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(254 226 226 / var(--tw-bg-opacity))"
  }
}, $disabled && {
  "cursor": "not-allowed",
  "opacity": "0.5"
}, $isSelectedValue && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(72 38 253 / var(--tw-bg-opacity))",
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))"
  }
}]);
const TextContainer = _styled.div<{
  $startIcon: boolean;
}>`
  ${({
  $startIcon
}) => $startIcon ? {
  "marginLeft": "0.5rem"
} : {}}
`;
const OptionContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "justifyContent": "space-between"
});
const SelectedContentContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem"
});
const LabelStartIconAndDescriptionContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "flex-start"
});
const LabelAndStartIconContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start"
});
const SuffixAndEndIconContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.25rem",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.25rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "paddingLeft": "1.25rem"
});
export interface SelectOptionProps<Value extends string | null = string> {
  destructive: boolean;
  disabled: boolean;
  isSelected: boolean;
  label: string;
  onClick: (value: Value) => void;
  value: Value;
  description?: string | undefined;
  endIcon?: {
    icon: Icon;
    color?: TextIconColor;
    size?: Size;
  } | undefined;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: TextIconColor;
    size?: Size;
  } | undefined;
  suffix?: {
    text: string;
    color?: TextIconColor;
    size?: TypographySize;
  };
}
export const SelectOption = <Value extends string | null = string,>({
  label,
  onClick,
  value,
  isSelected,
  disabled,
  startIcon,
  endIcon,
  prefix,
  suffix,
  destructive,
  description
}: SelectOptionProps<Value>) => {
  const iconAndTextColor = destructive ? TextIconColor.DESTRUCTIVE : TextIconColor.PRIMARY;
  return <SelectOptionContainer $destructive={destructive} $disabled={disabled} $isSelectedValue={isSelected} data-value={value} onClick={() => !disabled && onClick(value)} role="menuitem">
      <OptionContainer>
        <SelectedContentContainer>
          <LabelStartIconAndDescriptionContainer>
            <LabelAndStartIconContainer>
              {!!prefix && <Typography color={iconAndTextColor} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  {prefix}
                </Typography>}
              {!!startIcon && <Icons color={startIcon.color} icon={startIcon.icon} size={startIcon.size} />}
              <TextContainer $startIcon={!!startIcon}>
                <Typography color={iconAndTextColor} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  {label}
                </Typography>
              </TextContainer>
            </LabelAndStartIconContainer>
            {description && <Typography color={TextIconColor.SECONDARY} size={TypographySize.X_SMALL} weight={TypographyWeight.NORMAL}>
                {description}
              </Typography>}
          </LabelStartIconAndDescriptionContainer>
        </SelectedContentContainer>
        <SuffixAndEndIconContainer>
          {!!suffix && <Typography color={suffix.color ? suffix.color : TextIconColor.PRIMARY} size={suffix.size ? suffix.size : TypographySize.SMALL} wrap={TypographyWrap.NO_WRAP}>
              {suffix.text}
            </Typography>}
          {!!endIcon && <Icons color={endIcon.color} icon={endIcon.icon} size={endIcon.size} />}
        </SuffixAndEndIconContainer>
      </OptionContainer>
    </SelectOptionContainer>;
};
export default SelectOption;