import { isMobileOnly } from 'react-device-detect';
import { IntercomProvider } from 'react-use-intercom';
const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';
const IntercomProviderWrapper = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  if (!INTERCOM_APP_ID) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <IntercomProvider appId={INTERCOM_APP_ID} autoBoot initializeDelay={5000} shouldInitialize={!isMobileOnly}>
      {children}
    </IntercomProvider>;
};
export default IntercomProviderWrapper;