import _styled from "styled-components";
import { IntegrationWorkflowInputType } from '@kindo/universal';
import { getIntegrationInputFieldDisplayName } from '../WorkflowIntegrationInput.utils';
import { Table, TableColumn, TableRow, TextIconColor } from '~/components/core';

// Table container
const IntegrationInputFieldSelectorContainer = _styled.div({
  "display": "flex",
  "maxHeight": "15rem",
  "width": "100%",
  "overflowY": "auto",
  "borderRadius": "0.375rem",
  "backgroundColor": "#25224575",
  "--tw-drop-shadow": "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});
interface IntegrationInputFieldSelectorProps<InputField extends string> {
  integrationInputFields: InputField[];
  integrationInputType: IntegrationWorkflowInputType | null;
  selectedInputFields: InputField[];
  setSelectedInputFields: (selected: InputField[]) => void;
}
const IntegrationInputFieldSelector = <InputField extends string,>({
  integrationInputFields,
  integrationInputType,
  selectedInputFields,
  setSelectedInputFields
}: IntegrationInputFieldSelectorProps<InputField>) => {
  const columns: TableColumn<string>[] = [{
    title: ''
  }];
  const rows: TableRow<InputField>[] = integrationInputFields.map(row => ({
    key: row,
    height: {
      "height": "2.75rem"
    },
    textColor: TextIconColor.PRIMARY,
    cells: integrationInputType ? [getIntegrationInputFieldDisplayName(integrationInputType, row)] : []
  }));
  return <IntegrationInputFieldSelectorContainer>
      <Table<InputField> columns={columns} noRowsText="" rows={rows} selectionProps={{
      selectedItems: selectedInputFields.map(val => val),
      setSelectedItems: setSelectedInputFields
    }} showHeader={false} />
    </IntegrationInputFieldSelectorContainer>;
};
export default IntegrationInputFieldSelector;