import { WorkflowType } from '@kindo/universal';
import { HugeIcon } from '../../core';
export const WORKFLOW_TYPE_TO_DESCRIPTION = {
  [WorkflowType.TRIGGERED]: 'Agents that sit idle until activated by an event',
  [WorkflowType.MANUAL]: 'Agents that follow a linear timeline of actions',
  [WorkflowType.CHATBOT]: 'Agents that are dedicated to chat'
} as const satisfies Record<WorkflowType, string>;
interface UseCase {
  description: string;
  title: string;
}
export const WORKFLOW_TYPE_TO_USE_CASES = {
  [WorkflowType.TRIGGERED]: [{
    title: 'High-Priority Ticket Escalation: ',
    description: 'This agent can keep a watchful eye on critical security tickets. For instance, if a ticket is flagged as "high severity", you can assign a pre-defined set of initial actions, ensuring a swift response to potential crises.'
  }, {
    title: 'Automated Ticket Enrichment: ',
    description: 'Imagine a new security ticket arriving with minimal information. This agent can spring into action, querying threat intelligence platforms, vulnerability databases, and internal logs based on keywords in the ticket. It then automatically adds valuable context, like CVE details or affected system information, directly to the ticket.'
  }, {
    title: 'Security Information and Event Management (SIEM) Alerting: ',
    description: "This agent acts as your SIEM's right hand, sifting through mountains of security logs and events to identify critical threats. When it spots something suspicious, it can immediately take a pre-defined action step, allowing your security team to respond swiftly."
  }],
  [WorkflowType.MANUAL]: [{
    title: 'Automated Incident Response: ',
    description: 'Streamline your incident response process. This agent can orchestrate a series of actions, from gathering evidence and isolating compromised systems to creating potential remediation options.'
  }, {
    title: 'Security Orchestration, Automation & Response (SOAR): ',
    description: 'This agent can act as the conductor of your security tools, automating repetitive tasks like malware analysis, phishing investigations, and vulnerability remediation. This frees up your team to focus on more complex threats.'
  }, {
    title: 'Compliance Automation: ',
    description: 'Maintain a strong security posture and meet regulatory requirements. This agent can automate compliance tasks like user access reviews, vulnerability scans, and security audits, ensuring continuous compliance.'
  }],
  [WorkflowType.CHATBOT]: [{
    title: 'Security Awareness Training: ',
    description: 'Train your employees on cybersecurity best practices in an engaging and interactive way. This agent can deliver bite-sized training modules, simulate phishing attacks, and answer security-related questions through a conversational interface.'
  }, {
    title: 'Security Operations Center (SOC) Assistant: ',
    description: 'Provide your SOC analysts with a helpful assistant. This agent can answer common queries, retrieve relevant security information, and even assist with basic incident triage, allowing analysts to focus on more critical tasks.'
  }, {
    title: 'Security Self-Service: ',
    description: 'Empower employees to resolve their own security issues. This agent can assist with password resets, multi-factor authentication setup, and security policy guidance, reducing the burden on your security team.'
  }]
} as const satisfies Record<WorkflowType, UseCase[]>;
export enum WorkflowFeature {
  AI_STEPS = 'AI_STEPS',
  AUTO_GENERATION = 'AUTO_GENERATION',
  CHAT_FUNCTIONALITY = 'CHAT_FUNCTIONALITY',
  DATA_INTEGRATIONS = 'DATA_INTEGRATIONS',
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  MULTI_MODEL_SELECT = 'MULTI_MODEL_SELECT',
  URL_SCRAPING = 'URL_SCRAPING',
  USER_INPUT = 'USER_INPUT',
  WEB_SEARCH = 'WEB_SEARCH',
}
export const WORKFLOW_FEATURE_TO_DISPLAY_NAME = {
  [WorkflowFeature.AI_STEPS]: 'AI Steps',
  [WorkflowFeature.AUTO_GENERATION]: 'Auto Generation',
  [WorkflowFeature.CHAT_FUNCTIONALITY]: 'Chat Functionality',
  [WorkflowFeature.DATA_INTEGRATIONS]: 'Data Integrations',
  [WorkflowFeature.KNOWLEDGE_STORE]: 'Knowledge Store',
  [WorkflowFeature.MULTI_MODEL_SELECT]: 'Multi Model Select',
  [WorkflowFeature.URL_SCRAPING]: 'URL Scraping',
  [WorkflowFeature.USER_INPUT]: 'User Input',
  [WorkflowFeature.WEB_SEARCH]: 'Web Search'
} as const satisfies Record<WorkflowFeature, string>;
export const WORKFLOW_TYPE_TO_SUPPORTED_FEATURES = {
  [WorkflowType.TRIGGERED]: [WorkflowFeature.DATA_INTEGRATIONS, WorkflowFeature.KNOWLEDGE_STORE, WorkflowFeature.AI_STEPS, WorkflowFeature.CHAT_FUNCTIONALITY, WorkflowFeature.MULTI_MODEL_SELECT],
  [WorkflowType.MANUAL]: [WorkflowFeature.AUTO_GENERATION, WorkflowFeature.KNOWLEDGE_STORE, WorkflowFeature.AI_STEPS, WorkflowFeature.USER_INPUT, WorkflowFeature.CHAT_FUNCTIONALITY, WorkflowFeature.MULTI_MODEL_SELECT],
  [WorkflowType.CHATBOT]: [WorkflowFeature.KNOWLEDGE_STORE, WorkflowFeature.CHAT_FUNCTIONALITY, WorkflowFeature.MULTI_MODEL_SELECT, WorkflowFeature.USER_INPUT, WorkflowFeature.WEB_SEARCH, WorkflowFeature.URL_SCRAPING]
} as const satisfies Record<WorkflowType, WorkflowFeature[]>;
export const WORKFLOW_TYPE_TO_ICON = {
  [WorkflowType.TRIGGERED]: HugeIcon.TRIGGER,
  [WorkflowType.MANUAL]: HugeIcon.WORKFLOW,
  [WorkflowType.CHATBOT]: HugeIcon.CHAT
} as const satisfies Record<WorkflowType, HugeIcon>;
export function getFeatureStrListByWorkflowType(workflowType: WorkflowType): {
  supported: WorkflowFeature[];
  unsupported: WorkflowFeature[];
} {
  const supportedFeatures: WorkflowFeature[] = WORKFLOW_TYPE_TO_SUPPORTED_FEATURES[workflowType];
  return {
    supported: supportedFeatures,
    unsupported: Object.values(WorkflowFeature).filter(feature => !supportedFeatures.includes(feature))
  };
}