import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { useState } from 'react';
import { DlpFiltersConfig } from '~/components/SettingsModal/SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig';
import { transformDlpFiltersConfigResponse } from '~/components/SettingsModal/SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig/DlpFilters.types';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface UserGroupBaseDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  userGroupId: string;
}
const UserGroupBaseDlpFiltersConfig: React.FC<UserGroupBaseDlpFiltersProps> = ({
  canAccessSecuritySettings,
  userGroupId
}) => {
  // States
  const [updatingFilters, setUpdatingFilters] = useState<DlpFilter[]>([]);

  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchCustomFilters
  } = nextTrpc.customDlpFilters.listUserGroupBaseCustomDlpFilters.useQuery({
    userGroupId
  });
  const customDlpFilters = data?.items ?? [];
  const {
    data: dlpFiltersConfig,
    refetch: refetchDlpFiltersConfig,
    isLoading
  } = nextTrpc.dlpFiltersConfig.getUserGroupBaseDlpFiltersConfig.useQuery({
    userGroupId
  }, {
    select: transformDlpFiltersConfigResponse
  });

  // Mutations
  const createUserGroupBaseCustomDlpFilter = nextTrpc.customDlpFilters.createUserGroupBaseCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully created custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const deleteUserGroupBaseCustomDlpFilterMutation = nextTrpc.customDlpFilters.deleteUserGroupBaseCustomDlpFilter.useMutation({
    onSuccess: () => {
      refetchCustomFilters();
      enqueueToast({
        message: 'Successfully deleted custom filter.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete custom filter.',
        type: ToastType.ERROR
      });
    }
  });
  const updateUserGroupBaseDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.updateUserGroupBaseDlpFiltersConfig.useMutation({
    onMutate: ({
      filter
    }) => setUpdatingFilters(prev => [...prev, filter]),
    onSettled: () => {
      refetchDlpFiltersConfig();
      setUpdatingFilters([]);
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to update data loss prevention filter.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDlpFilterOptionToggle = (dlpFilter: DlpFilter, newPolicy: Extract<DlpFilterPolicy, DlpFilterPolicy.REDACT | DlpFilterPolicy.PASSTHROUGH>) => updateUserGroupBaseDlpFiltersConfigMutation.mutate({
    userGroupId,
    filter: dlpFilter,
    policy: newPolicy
  });
  const createUserGroupCustomDlpFilter = (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => {
    createUserGroupBaseCustomDlpFilter.mutate({
      userGroupId,
      policy,
      type,
      value
    });
  };
  const deleteCustomDlpFilter = (value: string) => {
    deleteUserGroupBaseCustomDlpFilterMutation.mutate({
      userGroupId,
      value
    });
  };
  return <DlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={createUserGroupCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={deleteCustomDlpFilter} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={isLoading} targetResourceLabel="your user group's models that have DLP enabled above, and do not have a custom configuration at the provider or model level" updateStandardDlpFilterConfig={handleDlpFilterOptionToggle} updatingFilters={updatingFilters} />;
};
export default UserGroupBaseDlpFiltersConfig;