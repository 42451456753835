import { OrgSettingToggle } from './OrgSettingToggle';
import { Typography, TypographySize, TypographyWeight } from '~/components/core';
import { useCanAccessSecuritySettings, useOrgSettings } from '~/hooks';
const useDefaultOrgSettingRows = () => {
  const {
    orgSettings,
    isLoading
  } = useOrgSettings();
  const {
    userCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const rows = orgSettings ? [
  // Row for new models of enabled providers
  {
    key: 'orgSettings-model',
    cells: [<div css={{
      "display": "flex",
      "alignItems": "center",
      "gap": "0.5rem"
    }} data-testid="model-cell-container" key="model">
              <Typography size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD}>
                New Models of Enabled Providers
              </Typography>
            </div>, <OrgSettingToggle disabled={!userCanAccessSecuritySettings} fieldName="defaultModelAccessEnabled" key="defaultModelAccessEnabled" orgSettings={orgSettings} />, <OrgSettingToggle disabled={!userCanAccessSecuritySettings} fieldName="defaultModelDlpEnabled" key="defaultModelDlpEnabled" orgSettings={orgSettings} />]
  },
  // Row for new providers
  {
    key: 'orgSettings-provider',
    cells: [<div css={{
      "display": "flex",
      "alignItems": "center",
      "gap": "0.5rem"
    }} data-testid="provider-cell-container" key="provider">
              <Typography size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD}>
                New Providers
              </Typography>
            </div>, <OrgSettingToggle disabled={!userCanAccessSecuritySettings} fieldName="defaultProviderAccessEnabled" key="defaultProviderAccessEnabled" orgSettings={orgSettings} />, <OrgSettingToggle disabled={!userCanAccessSecuritySettings} fieldName="defaultProviderDlpEnabled" key="defaultProviderDlpEnabled" orgSettings={orgSettings} />]
  }] : [];
  return {
    rows,
    isLoading
  };
};
export default useDefaultOrgSettingRows;