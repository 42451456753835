import { EmbeddingModel } from './embedding.consts';
import { INTERNAL_LLMS, Llm, SupportedLlm } from './llm.consts';
import { TranscriptionModel } from './transcription.consts';

export type Model = Llm | EmbeddingModel | TranscriptionModel;

export type SupportedModel = SupportedLlm | EmbeddingModel | TranscriptionModel;

export const INTERNAL_MODELS = [
  ...INTERNAL_LLMS,
  EmbeddingModel.INTERNAL_EMBEDDING_GENERATOR,
  TranscriptionModel.INTERNAL_TRANSCRIPTION_GENERATOR
] as const satisfies readonly Model[];

export type InternalModel = (typeof INTERNAL_MODELS)[number];

export function isInternalModel(value: string): value is InternalModel {
  return Object.values(INTERNAL_MODELS).includes(value as InternalModel);
}
