import { ToolTip, ToolTipColor, TooltipPlacement } from '../ToolTip';
interface WithTooltipConfig {
  content: string | undefined;
  color?: ToolTipColor;
  disabled?: boolean;
  interactive?: boolean;
  offset?: [number, number];
  onClick?: () => void;
  placement?: TooltipPlacement;
}

/**
 * Higher-order component that wraps a component with a tooltip
 * Returns the original component if content is falsy or disabled is true
 */
const withTooltip = (Component: React.ReactElement, config: WithTooltipConfig): React.ReactElement => {
  const {
    content,
    disabled = false,
    interactive = false,
    offset = [0, 8],
    onClick,
    placement = TooltipPlacement.TOP_START,
    color = ToolTipColor.BLACK
  } = config;
  return content && !disabled ? <ToolTip color={color} content={content} disabled={disabled} interactive={interactive} offset={offset} onClick={onClick} placement={placement}>
      {Component}
    </ToolTip> : Component;
};
export default withTooltip;