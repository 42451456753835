import { z } from 'zod';

import {
  ApiAuthorizationType,
  HttpMethod,
  isSupportedHttpMethod,
  zApiActionBody,
  zApiActionHeaders,
  zApiActionHeaderValue,
  zApiActionParameters
} from './apiRequest.types';

/** Zod schema definitions for validating generated API values */

export const zGeneratedApiHeaders = z.record(z.string(), z.string());
export const zGeneratedApiAuth = z.object({
  authorizationType: z.nativeEnum(ApiAuthorizationType),
  value: z.string()
});
export const zFormattedApiAuth = z.object({
  authorizationType: z.nativeEnum(ApiAuthorizationType),
  value: zApiActionHeaderValue
});
export const zGeneratedApiValues = z.object({
  endpoint: z.string(),
  method: z.nativeEnum(HttpMethod).refine(isSupportedHttpMethod),
  headers: zGeneratedApiHeaders.optional(),
  parameters: zApiActionParameters.optional(),
  body: zApiActionBody.optional()
});

export const zGeneratedApiValuesWithFormattedHeaders =
  zGeneratedApiValues.extend({
    headers: zApiActionHeaders.optional(),
    auth: zFormattedApiAuth.optional()
  });

export type GeneratedApiHeaders = z.infer<typeof zGeneratedApiHeaders>;
export type FormattedApiAuth = z.infer<typeof zFormattedApiAuth>;
export type GeneratedApiValues = z.infer<typeof zGeneratedApiValues>;
export type GeneratedApiValuesWithFormattedHeaders = z.infer<
  typeof zGeneratedApiValuesWithFormattedHeaders
>;
