import { CerbosChatAction } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useCanShareChat = (chatId: string | undefined) => {
  const result = [
    CerbosChatAction.SHARE_CHAT_WITH_ORG,
    CerbosChatAction.SHARE_CHAT_WITH_USER_GROUP
  ]
    .map((action) => {
      const { data } = nextTrpc.chats.isAllowed.useQuery(
        {
          action,
          id: chatId ?? ''
        },
        {
          enabled: !!chatId,
          refetchOnWindowFocus: false
        }
      );
      return {
        [action]: data ?? false
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return {
    canShareChatWithOrg: result[CerbosChatAction.SHARE_CHAT_WITH_ORG],
    canShareChatWithUserGroup:
      result[CerbosChatAction.SHARE_CHAT_WITH_USER_GROUP]
  };
};

export default useCanShareChat;
