import { isUserGroupErrorCode, KINDO_ERROR_MESSAGES, UserGroupErrorCode } from '@kindo/universal';
import { useState } from 'react';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size, Table, TableRow, TextIconColor, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { BadgeColor } from '~/components/core/Badge/Badge.consts';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface AllUserGroupsTableProps {
  handleSelectUserGroupId: (groupId: string | null) => void;
}
const AllUserGroupsTable: React.FC<AllUserGroupsTableProps> = ({
  handleSelectUserGroupId
}) => {
  const [userGroupIdToDelete, setUserGroupIdToDelete] = useState<string | null>(null);
  const [userGroupNameToDelete, setUserGroupNameToDelete] = useState<string | null>(null);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    userCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();

  // Queries
  const {
    data: userGroupsData,
    refetch: refetchUserGroups,
    isLoading: loadingUserGroups
  } = nextTrpc.userGroups.list.useQuery();
  const userGroups = userGroupsData?.items ?? [];

  // Mutations
  const createGroupMutation = nextTrpc.userGroups.create.useMutation({
    onSuccess: newGroup => {
      enqueueToast({
        message: 'User group created successfully.',
        type: ToastType.SUCCESS
      });
      handleSelectUserGroupId(newGroup.id);
    },
    onError: error => {
      enqueueToast({
        message: isUserGroupErrorCode(error.message) ? KINDO_ERROR_MESSAGES[error.message] : KINDO_ERROR_MESSAGES[UserGroupErrorCode.USER_GROUP_FAILED_TO_CREATE],
        type: ToastType.ERROR
      });
    }
  });
  const deleteGroupMutation = nextTrpc.userGroups.delete.useMutation({
    onSuccess: () => {
      refetchUserGroups();
      enqueueToast({
        message: 'User group deleted.',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to delete user group.',
        type: ToastType.ERROR
      });
    }
  });
  const handleDeleteGroup = () => {
    if (!userGroupIdToDelete) {
      console.error('No user group to delete');
      return;
    }
    deleteGroupMutation.mutate({
      userGroupId: userGroupIdToDelete
    });
    setUserGroupIdToDelete(null);
    setUserGroupNameToDelete(null);
  };
  const rows: TableRow[] = userGroups?.map(userGroup => ({
    key: `user-group-row-${userGroup.id}`,
    cells: [<Button casing={TypographyCasing.NONE} data-testid={`user-group-button-${userGroup.id}`} disabled={!userCanAccessSecuritySettings} font={TypographyFont.PARAGRAPH} key={`user-group-name-${userGroup.id}`} label={userGroup.name} onClick={() => {
      handleSelectUserGroupId(userGroup.id);
    }} type={ButtonType.TEXT} weight={TypographyWeight.NORMAL} />, userGroup.isUserGroupMember ? <Badge color={BadgeColor.DEFAULT} data-testid={`user-group-member-badge-${userGroup.id}`} key={`user-group-status-${userGroup.id}`} label="MEMBER" /> : null, userGroup.count, <div css={{
      "display": "flex",
      "gap": "0.5rem"
    }} data-testid={`user-group-buttons-${userGroup.id}`} key={`user-group-buttons-${userGroup.id}`}>
          <IconButton color={TextIconColor.PRIMARY} data-testid={`user-group-settings-button-${userGroup.id}`} disabled={!userCanAccessSecuritySettings} highlightOnHover icon={Icon.SETTINGS} onClick={() => {
        handleSelectUserGroupId(userGroup.id);
      }} type={IconButtonType.NONE} />
          <IconButton color={TextIconColor.PRIMARY} data-testid={`user-group-delete-button-${userGroup.id}`} disabled={!userCanAccessSecuritySettings} highlightOnHover icon={Icon.TRASH} onClick={() => {
        setUserGroupIdToDelete(userGroup.id);
        setUserGroupNameToDelete(userGroup.name);
      }} type={IconButtonType.NONE} />
        </div>]
  })) ?? [];
  return <SettingsSection data-testid="all-user-groups-table-section" description="This is a list of all user groups in your organization" headerEndElement={userCanAccessSecuritySettings ? <Button data-testid="create-user-group-button" endIcon={Icon.PLUS} label="Create User Group" onClick={() => {
    createGroupMutation.mutate({
      name: generateRandomAlphabets()
    });
  }} size={Size.MEDIUM} type={ButtonType.SOLID_COLOR} typographySize={TypographySize.X_SMALL} /> : null} title="All User Groups">
      <Table columns={[{
      title: 'GROUP NAME'
    }, {
      title: 'STATUS'
    }, {
      title: 'HEADCOUNT'
    }, {
      title: ''
    }]} data-testid="all-user-groups-table" loading={loadingUserGroups} noRowsText="No user groups found." rows={rows} />
      <ConfirmationModal confirmButtonLabel="Delete user group" data-testid="delete-user-group-confirmation-modal" header={`Are you sure you want to delete the user group ${userGroupNameToDelete}?`} onCancel={() => setUserGroupIdToDelete(null)} onConfirm={handleDeleteGroup} open={!!userGroupIdToDelete} subtext="You can't undo this action." />
    </SettingsSection>;
};
// TODO: Replace this with a better random name generator
// This is more of a placeholder
function generateRandomAlphabets() {
  const alphabets = 'BCDFGHJKLMNPQRSTVWXYZbcdfghjklmnpqrstvwxyz';
  let result = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * alphabets.length);
    result += alphabets[randomIndex];
  }
  return result;
}
export default AllUserGroupsTable;