import { EmbeddingModel } from './embedding.consts';
import { Llm } from './llm.consts';
import { SupportedModel } from './model.consts';
import { TranscriptionModel } from './transcription.consts';

/**
 * These maps are used to convert between the Kindo Llm enum and the LiteLLM model name.
 *
 * IMPORTANT:
 * 1. Add litellm model strings (model_name value in litellm config) to LITELLM_MODEL_STRINGS
 *     a. In the litellm repo, model_name is the name to pass to litellm from external clients
 * 2. Add a mapping from the Llm enum to the litellm model string in LLM_TO_LITELLM_MODELS
 * 3. Any updates to LLM_TO_LITELLM_MODELS must be mirrored in the LITELLM_MODELS_TO_LLM map below.
 * 4. Some Llm's map to multiple LiteLLM models due to the need to support backwards compatibility
 *    for older Kindo versions that may use the old LiteLLM model names.
 */
export enum LitellmModel {
  CLAUDE_3_5_HAIKU = 'claude-3-5-haiku',
  CLAUDE_3_5_SONNET = 'claude-3-5-sonnet',
  CLAUDE_3_5_SONNET_20240620 = 'claude-3-5-sonnet-20240620',
  CLAUDE_3_7_SONNET = 'claude-3-7-sonnet',
  CLAUDE_3_HAIKU_20240307 = 'claude-3-haiku-20240307',
  CLAUDE_3_OPUS_20240229 = 'claude-3-opus-20240229',
  CLAUDE_3_SONNET_20240229 = 'claude-3-sonnet-20240229',
  COMMAND_R = 'command-r',
  DEEPSEEK_R1 = 'deepseek-r1',
  GEMINI_1_5_FLASH = 'gemini-1.5-flash',
  GEMINI_1_5_PRO = 'gemini-1.5-pro',
  GEMINI_2_0_FLASH = 'gemini-2.0-flash',
  GEMMA_2_9B_IT = 'gemma2-9b-it',
  GPT_35_TURBO_0125 = 'azure/gpt-35-turbo-0125',
  GPT_4O = 'azure/gpt-4o',
  GPT_4O_MINI = 'azure/gpt-4o-mini',
  GPT_4_TURBO = 'azure/gpt-4-turbo',
  GRANITE_13B_CHAT_V2 = 'watsonx/ibm/granite-13b-chat-v2',
  GRANITE_3_8B_INSTRUCT = 'watsonx/ibm/granite-3-8b-instruct',
  /**
   * Start internal LLMs
   *
   * Notes:
   * INTERNAL_LARGE_WORKER, INTERNAL_SMALL_WORKER: In the production cluster (us-west1), we have the following self-hosted models: Llama 3.1 8B and 3.1 70B.
   *    In the staging cluster (us-central1) and local development, we use the Azure GPT-4o mini model
   *    because self-hosting the model in these environments is not cost effective.
   * INTERNAL_AUTO_GENERATION: In the production cluster (us-west1), we have a self-hosted Llama 3.1 8B model.
   *    In the staging cluster (us-central1) and local development, we use the Azure GPT-3.5 Turbo model
   *    because self-hosting the model in these environments is not cost effective.
   */
  INTERNAL_AUTO_GENERATION = 'auto-generation-llm',
  INTERNAL_EMBEDDING_GENERATOR = 'embedding-generator',
  INTERNAL_INGESTION_EXTRACTION = 'ingestion-extraction-llm',
  INTERNAL_LARGE_WORKER = 'large-worker-llm',
  INTERNAL_SMALL_WORKER = 'small-worker-llm',
  INTERNAL_TRANSCRIPTION_GENERATOR = 'transcription-generator',
  /** End internal LLMs */
  LLAMA_3_1_8B_INSTANT = 'llama-3.1-8b-instant',
  LLAMA_3_3_70B_VERSATILE = 'llama-3.3-70b-versatile',
  LLAMA_3_70B_8192 = 'groq/llama3-70b-8192',
  META_LLAMA_3_1_70B_INSTRUCT = 'neuralmagic/Meta-Llama-3.1-70B-Instruct-quantized.w4a16',
  META_LLAMA_3_1_8B_INSTRUCT = 'meta-llama/Meta-Llama-3.1-8B-Instruct',
  O1 = 'o1',
  O1_MINI = 'o1-mini',
  O3_MINI = 'o3-mini',
  QWEN2_5_72B_INSTRUCT_TURBO = 'qwen/qwen2.5-72b-instruct-turbo',
  QWEN2_72B_INSTRUCT = 'qwen/qwen2-72b-instruct',
  SAUL_INSTRUCT_V1 = 'huggingface/Saul-Instruct-v1',
  WHITERABBITNEO_2_5_QWEN_2_5_32B = 'WhiteRabbitNeo/WhiteRabbitNeo-2.5-Qwen-2.5-32B',
  WHITERABBITNEO_33B_DEEPSEEK_CODER = '/models/WhiteRabbitNeo-33B-DeepSeekCoder',
  WHITERABBITNEO_R1_32B = 'WhiteRabbitNeo-R1-32B'
}

export const isLitellmModel = (value: string): value is LitellmModel =>
  Object.values(LitellmModel).includes(value as LitellmModel);

export const MODEL_TO_LITELLM_MODELS: Record<SupportedModel, LitellmModel> = {
  [Llm.CLAUDE_3_5_SONNET]: LitellmModel.CLAUDE_3_5_SONNET,
  [Llm.CLAUDE_3_7_SONNET]: LitellmModel.CLAUDE_3_7_SONNET,
  [Llm.CLAUDE_3_HAIKU]: LitellmModel.CLAUDE_3_HAIKU_20240307,
  [Llm.CLAUDE_3_OPUS]: LitellmModel.CLAUDE_3_OPUS_20240229,
  [Llm.CLAUDE_3_5_HAIKU]: LitellmModel.CLAUDE_3_5_HAIKU,
  [Llm.CLAUDE_3_SONNET]: LitellmModel.CLAUDE_3_SONNET_20240229,
  [Llm.COMMAND_R]: LitellmModel.COMMAND_R,
  [Llm.DEEPSEEK_R1]: LitellmModel.DEEPSEEK_R1,
  [Llm.GEMINI_1_5_FLASH]: LitellmModel.GEMINI_1_5_FLASH,
  [Llm.GEMINI_1_5_PRO]: LitellmModel.GEMINI_1_5_PRO,
  [Llm.GEMINI_2_0_FLASH]: LitellmModel.GEMINI_2_0_FLASH,
  [Llm.GEMMA_2_9B_IT]: LitellmModel.GEMMA_2_9B_IT,
  [Llm.GPT_3_5_TURBO]: LitellmModel.GPT_35_TURBO_0125,
  [Llm.GPT_4O]: LitellmModel.GPT_4O,
  [Llm.GPT_4O_MINI]: LitellmModel.GPT_4O_MINI,
  [Llm.GPT_4_TURBO]: LitellmModel.GPT_4_TURBO,
  [Llm.GRANITE_13B_CHAT_V2]: LitellmModel.GRANITE_13B_CHAT_V2,
  [Llm.GRANITE_3_8B_INSTRUCT]: LitellmModel.GRANITE_3_8B_INSTRUCT,
  /** Start internal LLMs */
  [EmbeddingModel.INTERNAL_EMBEDDING_GENERATOR]:
    LitellmModel.INTERNAL_EMBEDDING_GENERATOR,
  [Llm.INTERNAL_AUTO_GENERATION]: LitellmModel.INTERNAL_AUTO_GENERATION,
  [Llm.INTERNAL_INGESTION_EXTRACTION]:
    LitellmModel.INTERNAL_INGESTION_EXTRACTION,
  [Llm.INTERNAL_LARGE_WORKER]: LitellmModel.INTERNAL_LARGE_WORKER,
  [Llm.INTERNAL_SMALL_WORKER]: LitellmModel.INTERNAL_SMALL_WORKER,
  [TranscriptionModel.INTERNAL_TRANSCRIPTION_GENERATOR]:
    LitellmModel.INTERNAL_TRANSCRIPTION_GENERATOR,
  /** End internal LLMs */
  [Llm.LLAMA_3_3_70B]: LitellmModel.LLAMA_3_3_70B_VERSATILE,
  [Llm.LLAMA_3_70B]: LitellmModel.LLAMA_3_70B_8192,
  [Llm.LLAMA_3_1_70B_INTERNAL]: LitellmModel.META_LLAMA_3_1_70B_INSTRUCT,
  [Llm.LLAMA_3_1_8B]: LitellmModel.META_LLAMA_3_1_8B_INSTRUCT,
  [Llm.LLAMA_3_1_8B_INSTANT]: LitellmModel.LLAMA_3_1_8B_INSTANT,
  [Llm.O1]: LitellmModel.O1,
  [Llm.O1_MINI]: LitellmModel.O1_MINI,
  [Llm.O3_MINI]: LitellmModel.O3_MINI,
  [Llm.QWEN2_72B_INSTRUCT]: LitellmModel.QWEN2_72B_INSTRUCT,
  [Llm.QWEN2_5_72B_INSTRUCT_TURBO]: LitellmModel.QWEN2_5_72B_INSTRUCT_TURBO,
  [Llm.SAUL_INSTRUCT_V1]: LitellmModel.SAUL_INSTRUCT_V1,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]:
    LitellmModel.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  [Llm.WHITERABBITNEO_33B]: LitellmModel.WHITERABBITNEO_33B_DEEPSEEK_CODER,
  [Llm.WHITERABBITNEO_R1_32B]: LitellmModel.WHITERABBITNEO_R1_32B
};

// Some Llm's map to multiple LiteLLM models due to the need to support backwards compatibility
// for older Kindo versions that may use the old LiteLLM model names.
export const LITELLM_MODELS_TO_MODEL: Record<LitellmModel, SupportedModel> = {
  [LitellmModel.CLAUDE_3_5_SONNET]: Llm.CLAUDE_3_5_SONNET,
  [LitellmModel.CLAUDE_3_7_SONNET]: Llm.CLAUDE_3_7_SONNET,
  [LitellmModel.CLAUDE_3_5_HAIKU]: Llm.CLAUDE_3_5_HAIKU,
  [LitellmModel.CLAUDE_3_5_SONNET_20240620]: Llm.CLAUDE_3_5_SONNET,
  [LitellmModel.CLAUDE_3_HAIKU_20240307]: Llm.CLAUDE_3_HAIKU,
  [LitellmModel.CLAUDE_3_OPUS_20240229]: Llm.CLAUDE_3_OPUS,
  [LitellmModel.CLAUDE_3_SONNET_20240229]: Llm.CLAUDE_3_SONNET,
  [LitellmModel.COMMAND_R]: Llm.COMMAND_R,
  [LitellmModel.DEEPSEEK_R1]: Llm.DEEPSEEK_R1,
  [LitellmModel.GEMINI_1_5_FLASH]: Llm.GEMINI_1_5_FLASH,
  [LitellmModel.GEMINI_1_5_PRO]: Llm.GEMINI_1_5_PRO,
  [LitellmModel.GEMINI_2_0_FLASH]: Llm.GEMINI_2_0_FLASH,
  [LitellmModel.GEMMA_2_9B_IT]: Llm.GEMMA_2_9B_IT,
  [LitellmModel.GPT_35_TURBO_0125]: Llm.GPT_3_5_TURBO,
  [LitellmModel.GPT_4O]: Llm.GPT_4O,
  [LitellmModel.GPT_4O_MINI]: Llm.GPT_4O_MINI,
  [LitellmModel.GPT_4_TURBO]: Llm.GPT_4_TURBO,
  [LitellmModel.GRANITE_13B_CHAT_V2]: Llm.GRANITE_13B_CHAT_V2,
  [LitellmModel.GRANITE_3_8B_INSTRUCT]: Llm.GRANITE_3_8B_INSTRUCT,
  /** Start internal LLMs */
  [LitellmModel.INTERNAL_AUTO_GENERATION]: Llm.INTERNAL_AUTO_GENERATION,
  [LitellmModel.INTERNAL_EMBEDDING_GENERATOR]:
    EmbeddingModel.INTERNAL_EMBEDDING_GENERATOR,
  [LitellmModel.INTERNAL_INGESTION_EXTRACTION]:
    Llm.INTERNAL_INGESTION_EXTRACTION,
  [LitellmModel.INTERNAL_LARGE_WORKER]: Llm.INTERNAL_LARGE_WORKER,
  [LitellmModel.INTERNAL_SMALL_WORKER]: Llm.INTERNAL_SMALL_WORKER,
  [LitellmModel.INTERNAL_TRANSCRIPTION_GENERATOR]:
    TranscriptionModel.INTERNAL_TRANSCRIPTION_GENERATOR,
  /** End internal LLMs */
  [LitellmModel.LLAMA_3_1_8B_INSTANT]: Llm.LLAMA_3_1_8B_INSTANT,
  [LitellmModel.LLAMA_3_3_70B_VERSATILE]: Llm.LLAMA_3_3_70B,
  [LitellmModel.LLAMA_3_70B_8192]: Llm.LLAMA_3_70B,
  [LitellmModel.META_LLAMA_3_1_70B_INSTRUCT]: Llm.LLAMA_3_1_70B_INTERNAL,
  [LitellmModel.META_LLAMA_3_1_8B_INSTRUCT]: Llm.LLAMA_3_1_8B,
  [LitellmModel.O1]: Llm.O1,
  [LitellmModel.O1_MINI]: Llm.O1_MINI,
  [LitellmModel.O3_MINI]: Llm.O3_MINI,
  [LitellmModel.QWEN2_72B_INSTRUCT]: Llm.QWEN2_72B_INSTRUCT,
  [LitellmModel.QWEN2_5_72B_INSTRUCT_TURBO]: Llm.QWEN2_5_72B_INSTRUCT_TURBO,
  [LitellmModel.SAUL_INSTRUCT_V1]: Llm.SAUL_INSTRUCT_V1,
  [LitellmModel.WHITERABBITNEO_2_5_QWEN_2_5_32B]:
    Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  [LitellmModel.WHITERABBITNEO_33B_DEEPSEEK_CODER]: Llm.WHITERABBITNEO_33B,
  [LitellmModel.WHITERABBITNEO_R1_32B]: Llm.WHITERABBITNEO_R1_32B
};

export function getLitellmModelFromSupportedModel(
  model: SupportedModel
): LitellmModel {
  return MODEL_TO_LITELLM_MODELS[model];
}
