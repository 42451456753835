import { ConnectedWorkflowSupportedIntegration, INTEGRATION_DISPLAY_NAMES, WorkflowSupportedIntegration } from '@kindo/universal';
import React, { useState } from 'react';
import { tv } from 'tailwind-variants';
import WorkflowTriggerEditor from './WorkflowTriggerEditor/WorkflowTriggerEditor';
import { ButtonType, Icon, IconButton, IconButtonType, Select, Size, TextIconColor, ToolTip, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useAppDispatch, useAppSelector, useGetWorkflowFromUrlSlug, useToast } from '~/hooks';
import useWorkflowTrigger from '~/hooks/useWorkflowTrigger';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { BuilderWorkflowStatus, WorkflowTrigger as WorkflowTriggerType } from '~/types';
const triggerHeaderClickableContainerStyles = tv({
  base: 'flex w-full flex-row items-center justify-between',
  variants: {
    disabled: {
      true: 'cursor-not-allowed',
      false: 'cursor-pointer'
    }
  }
});
interface WorkflowTriggerProps {
  canEditTriggers: boolean;
  connectedIntegrations: ConnectedWorkflowSupportedIntegration[];
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  savedTrigger: WorkflowTriggerType | undefined;
  workflowId: string;
}
const WorkflowTrigger: React.FC<WorkflowTriggerProps> = ({
  canEditTriggers,
  connectedIntegrations,
  savedTrigger,
  workflowId,
  refetchWorkflow
}) => {
  // State
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(canEditTriggers);
  const [isDeleting, setIsDeleting] = useState(false);

  // Custom hooks
  const {
    saveTrigger,
    isDeletingTrigger,
    deleteTrigger,
    isSavingTrigger
  } = useWorkflowTrigger({
    workflowId,
    refetchWorkflow
  });

  // Redux
  const dispatch = useAppDispatch();
  const {
    activeTrigger
  } = useAppSelector(state => state.workflowBuilderTrigger);
  const {
    enqueueToast
  } = useToast();
  const handleDeleteConfirm = async () => {
    if (!savedTrigger) return;
    setIsDeleting(true);
    await deleteTrigger(savedTrigger);
    setShowConfirmDeleteModal(false);
  };

  // Only set the trigger active if we have a trigger and no active trigger
  if (!isDeleting && !activeTrigger && savedTrigger) {
    dispatch(triggerActions.setExistingTriggerActive({
      ...savedTrigger
    }));
  }
  const handleSaveTrigger = async () => {
    if (!activeTrigger) {
      console.error('Failed to save trigger: No active trigger found');
      enqueueToast({
        message: 'Failed to save trigger.',
        type: ToastType.ERROR
      });
      return;
    }
    await saveTrigger(activeTrigger);
    setIsExpanded(false);
  };
  const handleClose = () => {
    dispatch(triggerActions.clearTrigger());
    setIsExpanded(false);
  };
  const toggleExpanded = () => {
    if (isExpanded) {
      handleClose();
    } else {
      setIsExpanded(true);
    }
  };
  const setNewIntegration = (integrationId: string, integrationName: WorkflowSupportedIntegration) => {
    dispatch(triggerActions.updateTriggerIntegration({
      integrationId,
      integrationName
    }));
  };
  const workflowTrigger = activeTrigger ?? savedTrigger;
  const activeIntegration = connectedIntegrations.find(integration => integration.id === workflowTrigger?.integrationId);
  const getTriggerTitle = () => {
    if (!savedTrigger && !activeTrigger) {
      return 'Unknown Trigger';
    }
    if (!isExpanded && savedTrigger) {
      return `${INTEGRATION_DISPLAY_NAMES[savedTrigger.integrationName]} Trigger`;
    }
    if (!activeTrigger) {
      return 'Unknown Trigger';
    }
    const activeTriggerIntegrationDisplayName = activeTrigger.integrationName ? INTEGRATION_DISPLAY_NAMES[activeTrigger.integrationName] + ' ' : '';
    if (activeTrigger.status !== BuilderWorkflowStatus.NEW) {
      return `Edit ${activeTriggerIntegrationDisplayName}Trigger`;
    }
    return `Create New ${activeTriggerIntegrationDisplayName}Trigger`;
  };
  const getTriggerEndIcon = () => {
    if (!canEditTriggers) {
      return Icon.LOCK;
    }
    return isExpanded ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT;
  };
  return <div css={{
    "display": "flex",
    "width": "100%",
    "flexDirection": "column",
    "justifyContent": "center",
    "gap": "0.5rem",
    "borderRadius": "0.5rem",
    "borderWidth": "1px",
    "borderColor": "rgb(255 255 255 / 0.05)",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))",
    "padding": "1.5rem"
  }} data-testid="workflow-trigger-container">
      {/* Header */}
      <div css={{
      "display": "flex",
      "width": "100%",
      "alignItems": "center",
      "gap": "0.75rem"
    }} data-testid="trigger-header-container">
        <Select disabled={!canEditTriggers} hideChevron onChange={value => {
        const integration = connectedIntegrations.find(i => i.id === value);
        if (integration) {
          setNewIntegration(integration.id, integration.integrationName);
        }
      }} options={connectedIntegrations.map(integration => ({
        label: INTEGRATION_DISPLAY_NAMES[integration.integrationName],
        value: integration.id
      }))} placeholderLabel="Select an integration" startIcon={canEditTriggers ? Icon.EDIT : undefined} type={ButtonType.SOLID} value={activeIntegration?.id ?? null} />
        <ToolTip content={!canEditTriggers ? 'Only the creator of the agent can edit the trigger' : undefined}>
          <div className={triggerHeaderClickableContainerStyles({
          disabled: !canEditTriggers
        })} data-testid="trigger-header-clickable-container" onClick={canEditTriggers ? toggleExpanded : undefined}>
            <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.INTERACTIVE} size={TypographySize.MEDIUM} weight={TypographyWeight.NORMAL}>
              {getTriggerTitle()}
            </Typography>
            <IconButton color={TextIconColor.PRIMARY} disabled={!canEditTriggers} highlightOnHover icon={getTriggerEndIcon()} onClick={toggleExpanded} size={Size.LARGE} type={IconButtonType.SIMPLE} />
          </div>
        </ToolTip>
      </div>
      {isExpanded && activeTrigger && <WorkflowTriggerEditor activeTrigger={activeTrigger} isSavingTrigger={isSavingTrigger} onClose={handleClose} onDelete={() => setShowConfirmDeleteModal(true)} onSaveTrigger={handleSaveTrigger} refetchWorkflow={refetchWorkflow} trigger={savedTrigger} workflowId={workflowId} />}
      <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={isDeletingTrigger} header="Are you sure you want to delete this trigger?" onCancel={() => setShowConfirmDeleteModal(false)} onConfirm={handleDeleteConfirm} open={showConfirmDeleteModal} subtext="A deleted trigger cannot be recovered." />
    </div>;
};
export default WorkflowTrigger;