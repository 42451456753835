import { getIntegrationFromIntegrationDisplayName, INTEGRATION_TO_SQUARE_IMAGE } from '@kindo/universal';
import Image from 'next/image';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size, Table, Typography, TypographyCasing, TypographyFont, TypographySize } from '~/components/core';
import { AvailableIntegrationWithDisplayName, ConnectedIntegrationWithDisplayName, isConnectedIntegration } from '~/components/SettingsModal/IntegrationSettings/IntegrationSettings.types';
import { getIntegrationCategoryLabel } from '~/constants/integrations.consts';
export const INTEGRATION_SORT_KEYS = {
  CATEGORY: 'category',
  NAME: 'title'
};
type IntegrationsTableProps = {
  integrations: Array<AvailableIntegrationWithDisplayName | ConnectedIntegrationWithDisplayName>;
  onAction: (integration: AvailableIntegrationWithDisplayName | ConnectedIntegrationWithDisplayName) => void | Promise<void>;
  disableConnectButton?: boolean;
  workflowBuilderPage?: boolean;
};
const IntegrationsTable = ({
  integrations,
  onAction,
  workflowBuilderPage = false,
  disableConnectButton = false
}: IntegrationsTableProps) => {
  const renderActionButton = (integration: AvailableIntegrationWithDisplayName | ConnectedIntegrationWithDisplayName, integrationKey: string) => {
    if (isConnectedIntegration(integration)) {
      // Is connected integration and workflowBuilderPage, show "Select" button
      if (workflowBuilderPage) {
        return <Button fullWidth key={`${integrationKey}-connect`} label="Select" onClick={() => onAction(integration)} size={Size.SMALL} type={ButtonType.SOLID_COLOR} typographySize={TypographySize.X_SMALL} />;
      }
      // Is connected integration and settings modal, show "delete" icon button
      return <IconButton highlightOnHover icon={Icon.TRASH} key={`${integrationKey}-delete`} onClick={() => onAction(integration)} type={IconButtonType.NONE} />;
    }
    // Is not connected integration and is workflowBuilderPage, show "Connect" button
    return <Button disabled={disableConnectButton} fullWidth key={`${integrationKey}-connect`} label={disableConnectButton ? 'Contact Sales' : 'Connect'} onClick={() => onAction(integration)} size={Size.SMALL} type={ButtonType.OUTLINED} typographySize={TypographySize.X_SMALL} />;
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column"
  }} data-testid="integrations-table">
      <Table columns={[{
      title: 'NAME',
      sortKey: INTEGRATION_SORT_KEYS.NAME,
      width: {
        "width": "40%"
      }
    }, {
      title: 'TYPE',
      sortKey: INTEGRATION_SORT_KEYS.CATEGORY,
      width: {
        "width": "50%"
      }
    }, {
      title: '',
      width: {
        "width": "10%"
      }
    }]} noRowsText="No integrations" rows={integrations.map(integration => {
      const integrationKey = isConnectedIntegration(integration) ? integration.id : `${integration.integrationCategory}_${integration.integrationName}`;
      const integrationEnum = getIntegrationFromIntegrationDisplayName(integration.displayName);
      const squareImage = integrationEnum ? INTEGRATION_TO_SQUARE_IMAGE[integrationEnum] : undefined;
      return {
        key: integrationKey,
        cells: [
        // Name
        <div css={{
          "display": "flex",
          "flexDirection": "row",
          "alignItems": "center",
          "gap": "0.75rem"
        }} data-testid={`integration-name-${integrationKey}`} key={`${integrationKey}-square-image`}>
                {squareImage && <Image alt="Icon" height={28} src={squareImage} width={28} />}
                <Typography font={TypographyFont.HEADING} size={TypographySize.SMALL}>
                  {integration.displayName}
                </Typography>
              </div>,
        // Category
        <Typography casing={TypographyCasing.UPPERCASE} data-testid={`integration-category-${integrationKey}`} font={TypographyFont.HEADING} key={`${integrationKey}-integration-category`} size={TypographySize.X_SMALL}>
                {getIntegrationCategoryLabel(integration.integrationCategory)}
              </Typography>,
        // Action Button
        renderActionButton(integration, integrationKey)]
      };
    })} showColumnHeaderBorder />
    </div>;
};
export default IntegrationsTable;