import { WorkflowErrorCode, WorkflowInputType, WorkflowType } from '@kindo/universal';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { WorkflowCatalogue } from './WorkflowCatalogue';
import { WorkflowChatbotToolbox } from './WorkflowChatbotToolbox';
import { WORKFLOW_TYPE_TO_BUILDER_SLUG } from './WorkflowFactory.consts';
import { WorkflowIntegrationSelect } from './WorkflowIntegrationSelect';
import { ToastType, useParseWorkflowUrlSlug, useToast } from '~/hooks';
import { Modal, modalActions, PaywallModalType } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
const WorkflowFactory = () => {
  const {
    workflowType
  } = useParseWorkflowUrlSlug();

  // Custom hooks
  const router = useRouter();
  const {
    enqueueToast
  } = useToast();

  // Redux
  const dispatch = useDispatch();
  const displayWorkflowsPaywall = (existingWorkflowCount: number | undefined) => dispatch(modalActions.openModal({
    type: Modal.PAYWALL,
    existingWorkflowCount,
    paywallModalType: PaywallModalType.WORKFLOWS
  }));

  // Queries
  const {
    data: {
      count: orgWorkflowCount
    } = {}
  } = nextTrpc.orgs.getOrgWorkflowCount.useQuery(undefined, {
    onError: () => {
      enqueueToast({
        message: 'Failed to validate existing workflow count',
        type: ToastType.ERROR,
        autoClose: false
      });
    }
  });

  // Mutations
  const createWorkflowMutation = nextTrpc.workflows.create.useMutation({
    onSuccess: newWorkflow => {
      const currentPath = router.asPath;
      // Remove any workflow type slug from the path before adding workflow ID
      const basePath = currentPath.split('/').filter(segment => !Object.values(WORKFLOW_TYPE_TO_BUILDER_SLUG).includes(segment)).join('/');
      const newPath = `${basePath}/${newWorkflow.item.id}`;
      void router.replace(newPath, undefined, {
        shallow: true
      });
    },
    onError: error => {
      console.error(error);
      if (error.data?.kindoErrorCode === WorkflowErrorCode.ORG_WORKFLOW_LIMIT_REACHED) {
        displayWorkflowsPaywall(orgWorkflowCount);
        return;
      }
      enqueueToast({
        message: error.data?.kindoErrorMessage || 'Failed to create new workflow',
        type: ToastType.ERROR,
        autoClose: false
      });
    }
  });
  const createWorkflow = ({
    triggerIntegrationId,
    knowledgeStoreContentIds,
    systemPrompt
  }: {
    knowledgeStoreContentIds?: string[];
    systemPrompt?: string;
    triggerIntegrationId?: string;
  }) => {
    createWorkflowMutation.mutate({
      ...(triggerIntegrationId ? {
        triggerRequest: {
          integrationId: triggerIntegrationId,
          integrationInputType: WorkflowInputType.TICKETING_INTEGRATION_TICKET
        }
      } : {}),
      knowledgeStoreContentIds,
      systemPrompt
    });
  };
  const handleWorkflowTypeSelect = (type: WorkflowType) => {
    switch (type) {
      case WorkflowType.TRIGGERED:
        void router.push(`${router.asPath}/${WORKFLOW_TYPE_TO_BUILDER_SLUG[WorkflowType.TRIGGERED]}`);
        break;
      case WorkflowType.CHATBOT:
        void router.push(`${router.asPath}/${WORKFLOW_TYPE_TO_BUILDER_SLUG[WorkflowType.CHATBOT]}`);
        break;
      case WorkflowType.MANUAL:
        void router.push(`${router.asPath}/${WORKFLOW_TYPE_TO_BUILDER_SLUG[WorkflowType.MANUAL]}`);
        createWorkflow({});
        break;
      default:
        console.error('Unknown workflow type:', type);
    }
  };
  const renderActiveView = () => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (true) {
      case workflowType === WorkflowType.TRIGGERED:
        return <WorkflowIntegrationSelect onIntegrationSelect={integrationId => createWorkflow({
          triggerIntegrationId: integrationId
        })} />;
      case workflowType === WorkflowType.CHATBOT:
        return <WorkflowChatbotToolbox onCreateWorkflow={({
          systemPrompt,
          knowledgeStoreContent
        }) => createWorkflow({
          systemPrompt,
          knowledgeStoreContentIds: knowledgeStoreContent.map(file => file.id)
        })} />;
      default:
        return <WorkflowCatalogue selectWorkflowType={handleWorkflowTypeSelect} />;
    }
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1rem"
  }} data-testid="workflow-factory-container">
      {renderActiveView()}
    </div>;
};
export default WorkflowFactory;