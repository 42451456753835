import { getPromptTemplateTagTypeAndValue, getStaticContentPromptTemplateTag, getUserInputPromptTemplateTag, PromptTemplateTagType } from '@kindo/universal';
import { $createParagraphNode, $createTextNode, $getRoot, EditorState, SerializedElementNode, SerializedLexicalNode, SerializedRootNode, SerializedTextNode } from 'lexical';
import { $createWorkflowStaticContentNode, $createWorkflowUserInputNode, SerializedWorkflowStaticContentNode, SerializedWorkflowUserInputNode, WorkflowLexicalNodeType } from './WorkflowLexicalNodes';
import { NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE, NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE } from '~/redux/reducers/workflowBuilderSlice';
import { BuilderWorkflowStepStaticContent, PromptTemplateWorkflowBuilderStepInput } from '~/types';
const stepUserInputNodeToMustacheTemplateValue = (node: SerializedWorkflowUserInputNode): string => node.templateResolutionName ? node.templateResolutionName : NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE;
const staticContentNodeToMustacheTemplateValue = (node: SerializedWorkflowStaticContentNode): string => node.contentId ? node.contentId : NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE;
export type SerializedWorkflowStepPromptEditorLeafNode = SerializedTextNode | SerializedWorkflowUserInputNode | SerializedWorkflowStaticContentNode;

/**
 * Type of root node for the WorkflowStepPromptEditor.
 * Currently describes a tree of type:
 * RootNode -> Paragraph Node -> Text and Input nodes
 *
 *                               RootNode
 *                                   |
 *                             ParagraphNode
 *                                   |
 * [TextNode | WorkflowUserInputNode | WorkflowStaticContentNode
 */
export type WorkflowStepPromptEditorRoot = SerializedRootNode<SerializedElementNode<SerializedWorkflowStepPromptEditorLeafNode>>;
export const isSerializedWorkflowUserInputNode = (node: SerializedLexicalNode): node is SerializedWorkflowUserInputNode => node.type === WorkflowLexicalNodeType.WORKFLOW_USER_INPUT;
export const isSerializedWorkflowStaticContentNode = (node: SerializedLexicalNode): node is SerializedWorkflowStaticContentNode => node.type === WorkflowLexicalNodeType.WORKFLOW_STATIC_CONTENT;
export const isSerializedTextNode = (node: SerializedLexicalNode): node is SerializedTextNode => node.type === 'text';

/**
 * Given a prompt string with mustache template variables, return void but updates the editor state.
 * This function is used to initialize the editor state as a function
 *
 * https://lexical.dev/docs/concepts/editor-state
 *
 * Mustache values for the following variables are supported:
 *   User Input - {{<userInputName>}}
 *   Step Output - {{output:<stepId>}}
 *   Static Content - {{content:<contentId>}}
 */
export const parsePromptMustacheTemplateToEditorState = (prompt: string, userInputs: PromptTemplateWorkflowBuilderStepInput[], staticContent: BuilderWorkflowStepStaticContent[]): void => {
  // Get the RootNode from the EditorState
  const root = $getRoot();
  root.clear();

  // Create a new ParagraphNode to wrap all nodes
  const paragraphNode = $createParagraphNode();

  // Split the prompt into chunks by '{{' and '}}'
  const promptChunks = prompt.split(/({{[^}]*}})/g).filter(Boolean);

  // Loop through text and create Text or Input nodes
  promptChunks.forEach(chunk => {
    // Mustache variable
    if (chunk.startsWith('{{') && chunk.endsWith('}}')) {
      const tagTypeAndValue = getPromptTemplateTagTypeAndValue(chunk);
      if (!tagTypeAndValue) {
        console.error(`Invalid prompt template tag: ${chunk}`);
        return;
      }
      const {
        tagType,
        value
      } = tagTypeAndValue;

      // User Input
      if (tagType === PromptTemplateTagType.USER_INPUT) {
        const resolvedUserInput = userInputs.find(input => input.templateResolutionName === value);
        if (!resolvedUserInput) {
          console.error(`Could not find user input with identifying name '${value}' in step user inputs`);
          return;
        }
        const {
          templateResolutionName,
          displayName
        } = resolvedUserInput;
        const userInputNode = $createWorkflowUserInputNode({
          templateResolutionName,
          displayName
        });
        paragraphNode.append(userInputNode);
      }

      // Static Content
      else if (tagType === PromptTemplateTagType.STATIC_CONTENT) {
        const resolvedStaticContent = staticContent.find(content => content.id === value);
        if (!resolvedStaticContent) {
          console.error(`Could not find matching static content with id '${value}' in step static content `);
          return;
        }
        const {
          id: contentId,
          fileName
        } = resolvedStaticContent;
        const staticContentNode = $createWorkflowStaticContentNode({
          contentId,
          contentName: fileName || 'Unknown File'
        });
        paragraphNode.append(staticContentNode);
      }
    } else {
      // Regular text
      const textNode = $createTextNode(chunk);
      paragraphNode.append(textNode);
    }
  });
  root.append(paragraphNode);
};

/**
 * Given an editor state, return a prompt string with mustache template variables.
 *
 *  Create the following mustache values for variables:
 *   User Input - {{<userInputName>}}
 *   Static Content - {{content:<contentId>}}
 */
export const parseEditorStateToPromptMustacheTemplate = (editorState: EditorState): string => {
  const root = editorState.toJSON().root as WorkflowStepPromptEditorRoot;

  // Expect the root's only child to be a paragraph that contains all the text and inputs
  const paragraphNode = root.children[0];
  if (paragraphNode?.type !== 'paragraph') {
    console.error('Unexpected root child type, expected paragraph', paragraphNode);
    return '';
  }

  // Loop through all nodes in the prompt
  const prompt: string = paragraphNode.children.map((node: SerializedWorkflowStepPromptEditorLeafNode): string => {
    switch (node.type) {
      case WorkflowLexicalNodeType.WORKFLOW_USER_INPUT:
        return getUserInputPromptTemplateTag(stepUserInputNodeToMustacheTemplateValue(node as SerializedWorkflowUserInputNode));
      case WorkflowLexicalNodeType.WORKFLOW_STATIC_CONTENT:
        return getStaticContentPromptTemplateTag(staticContentNodeToMustacheTemplateValue(node as SerializedWorkflowStaticContentNode));
      case 'linebreak':
        return '\n';
      case 'text':
      default:
        return (node as SerializedTextNode).text;
    }
  }).join('');
  return prompt;
};

// TODO: Add tests