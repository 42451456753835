import { ApiTab } from './ApiSettings';
import { PluginsTab } from './Plugins';
import { OrgSecuritySettingsTabs } from './SecuritySettings';
export enum SettingsTab {
  API = 'API',
  INTEGRATIONS = 'INTEGRATIONS',
  MANAGE_SUBSCRIPTION = 'MANAGE_SUBSCRIPTION',
  MODELS = 'MODELS',
  ORG = 'ORG',
  PLUGINS = 'PLUGINS',
  SECRETS_VAULT = 'SECRETS_VAULT',
  SECURITY = 'SECURITY',
  USER_GROUPS = 'USER_GROUPS',
}

// Note: This type (tab: any) is refined in the importing files
export type SettingsStructureSubItem = {
  displayName: string;
  tab: ApiTab | PluginsTab | OrgSecuritySettingsTabs;
};
export type SettingsStructureItem = {
  displayName: string;
  hasComponent: boolean;
  subTabs: SettingsStructureSubItem[];
  tab: SettingsTab;
};