import { OrgPaymentTier } from '@kindo/universal';
import { useState } from 'react';
import SettingsSection from '../../SettingsSection';
import { ApiKeyModal } from '../ApiKeyModal';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size, Table, TextField, TextFieldType, TextIconColor, Toggle } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useAppSelector, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const ApiKeyManagement: React.FC = () => {
  // State
  const [newKeyName, setNewKeyName] = useState<string>('');
  const [isCreatingNewApiKey, setIsCreatingNewApiKey] = useState<boolean>(false);
  const [keyToDelete, setKeyToDelete] = useState<string>('');
  const [apiKeyToCopy, setApiKeyToCopy] = useState<string>('');

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    org
  } = useAppSelector(state => state.user);
  const shouldHaveAPIAccess = org?.paymentTier === OrgPaymentTier.ENTERPRISE || org?.paymentTier === OrgPaymentTier.PRO;

  // Queries
  const {
    data,
    refetch: refetchApiKeys
  } = nextTrpc.apiKeys.list.useQuery({});
  const apiKeys = data?.items || [];

  // Mutations
  const createApiKeyMutation = nextTrpc.apiKeys.create.useMutation({
    onSuccess: newApiKey => {
      refetchApiKeys();
      setNewKeyName('');
      setIsCreatingNewApiKey(false);
      setApiKeyToCopy(newApiKey.apiKey);
      enqueueToast({
        message: 'API key successfully created',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to create API key', error);
      enqueueToast({
        message: 'Failed to create API key',
        type: ToastType.ERROR
      });
    }
  });
  const toggleApiKeyMutation = nextTrpc.apiKeys.setEnabled.useMutation({
    onSuccess: () => {
      refetchApiKeys();
    },
    onError: error => {
      console.error('Failed to toggle API key', error);
      enqueueToast({
        message: 'Failed to toggle API key',
        type: ToastType.ERROR
      });
    }
  });
  const deleteApiKeyMutation = nextTrpc.apiKeys.remove.useMutation({
    onSuccess: () => {
      refetchApiKeys();
      enqueueToast({
        message: 'API key successfully deleted',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to delete API key', error);
      enqueueToast({
        message: 'Failed to delete API key',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setKeyToDelete('');
    }
  });
  const handleCreateNewApiKey = () => {
    createApiKeyMutation.mutate({
      name: newKeyName
    });
  };
  const handleToggleApiKey = (id: string, enabled: boolean) => toggleApiKeyMutation.mutate({
    id,
    enabled
  });
  const handleDeleteApiKey = () => {
    deleteApiKeyMutation.mutate({
      id: keyToDelete
    });
  };
  return <>
      <SettingsSection description="Manage your API keys for accessing Kindo's public API." title="API Keys">
        <Table columns={[{
        title: 'Name',
        width: {
          "width": "33.333333%"
        }
      }, {
        title: 'Key',
        width: {
          "flex": "1 1 0%"
        }
      }, {
        title: 'Status',
        width: {
          "width": "8rem"
        }
      }]} noRowsText="No API keys found." rows={apiKeys.map(apiKey => ({
        key: apiKey.id,
        cells: [apiKey.name, `********-****-****-****-********${apiKey.endingChars}`, <div css={{
          "display": "flex",
          "flexDirection": "row",
          "alignItems": "center",
          "gap": "0.5rem"
        }} data-testid="status-delete-container" key={apiKey.id}>
                <Toggle checked={apiKey.enabled} disabled={!shouldHaveAPIAccess} key={apiKey.id} onToggle={enabled => handleToggleApiKey(apiKey.id, enabled)} size={Size.SMALL} />
                <IconButton color={TextIconColor.PRIMARY} highlightOnHover icon={Icon.TRASH} key={apiKey.id} onClick={() => setKeyToDelete(apiKey.id)} type={IconButtonType.NONE} />
              </div>]
      }))} />
        <div css={{
        "display": "flex",
        "flexDirection": "row",
        "alignItems": "center",
        "gap": "0.5rem",
        "paddingTop": "2.5rem",
        "paddingBottom": "2.5rem"
      }} data-testid="create-new-key-container">
          {!isCreatingNewApiKey && <Button disabled={!shouldHaveAPIAccess} endIcon={Icon.PLUS} label="Create new API key" onClick={() => setIsCreatingNewApiKey(true)} tooltip={!shouldHaveAPIAccess ? 'API keys are only available for Enterprise users' : undefined} type={ButtonType.SOLID_COLOR} />}
          {isCreatingNewApiKey && <>
              <TextField fullWidth onChange={setNewKeyName} placeholder="Enter a name for your API key" type={TextFieldType.SIMPLE} value={newKeyName} />
              <Button label="Cancel" onClick={() => {
            setIsCreatingNewApiKey(false);
            setNewKeyName('');
          }} size={Size.SMALL} type={ButtonType.SOLID} />
              <Button label="Create" loading={createApiKeyMutation.isLoading} onClick={handleCreateNewApiKey} size={Size.SMALL} type={ButtonType.SOLID} />
            </>}
        </div>
      </SettingsSection>
      <ConfirmationModal confirmButtonLabel="Delete" header="Are you sure you want to delete this API key?" onCancel={() => setKeyToDelete('')} onConfirm={handleDeleteApiKey} open={!!keyToDelete} subtext="You can't undo this action." />
      <ApiKeyModal apiKey={apiKeyToCopy} header="Save your API key" onConfirm={() => setApiKeyToCopy('')} open={!!apiKeyToCopy} subtext="Keep this key secure. You won't be able to view it again." />
    </>;
};
export default ApiKeyManagement;