import { assertUnreachable } from '@kindo/universal';
import { TabNavigator, TabOption } from '../core';
import { WorkflowBuilder } from '../WorkflowBuilder';
import { WorkflowFactory } from '../WorkflowFactory';
import { WorkflowLibrary } from '../WorkflowLibrary';
import { WorkflowNavTab, WORKFLOWS_NAV_TAB_DISPLAY_NAMES, WORKFLOWS_NAV_TAB_ROUTES } from './WorkflowNavScreen.consts';
import { Layout } from 'components/Layout';
import { WORKFLOWS_ROUTE } from '~/constants';
import { useParseWorkflowUrlSlug, useTabUrlNavigation } from '~/hooks';
const DEFAULT_ACTIVE_TAB = WorkflowNavTab.LIBRARY;
const WorkflowNavScreen = () => {
  const {
    workflowId
  } = useParseWorkflowUrlSlug();
  const {
    activeTab,
    getTabRoute
  } = useTabUrlNavigation<WorkflowNavTab>({
    defaultActiveTab: DEFAULT_ACTIVE_TAB,
    tabsWithRoutes: WORKFLOWS_NAV_TAB_ROUTES,
    baseRoute: WORKFLOWS_ROUTE
  });
  const tabOptions: TabOption<WorkflowNavTab>[] = [WorkflowNavTab.LIBRARY, WorkflowNavTab.CREATE].map(tab => ({
    label: WORKFLOWS_NAV_TAB_DISPLAY_NAMES[tab],
    value: tab,
    href: getTabRoute(tab)
  }));
  const renderActiveTab = () => {
    switch (activeTab) {
      case WorkflowNavTab.LIBRARY:
        return <WorkflowLibrary />;
      case WorkflowNavTab.CREATE:
        return workflowId ? <WorkflowBuilder /> : <WorkflowFactory />;
      default:
        return assertUnreachable(activeTab);
    }
  };
  return <Layout>
      <div css={{
      "display": "flex",
      "flex": "1 1 0%",
      "flexDirection": "column",
      "overflow": "hidden"
    }} data-testid="workflow-screen-container">
        {!workflowId && <div css={{
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "gap": "1rem",
        "paddingBottom": "0.5rem",
        "paddingTop": "1.5rem"
      }} data-testid="tab-navigator-container">
            <TabNavigator activeTab={activeTab} tabOptions={tabOptions} />
          </div>}
        <div css={{
        "display": "flex",
        "flex": "1 1 0%",
        "flexDirection": "column",
        "alignItems": "center",
        "overflow": "hidden"
      }} data-testid="workflow-nav-tab-container">
          <div css={{
          "display": "flex",
          "height": "100%",
          "width": "100%",
          "minWidth": "800px",
          "maxWidth": "50%",
          "flexDirection": "column",
          "overflowY": "auto",
          "padding": "1px",
          "paddingTop": "1rem"
        }} data-testid="tab-content-container">
            {renderActiveTab()}
          </div>
        </div>
      </div>
    </Layout>;
};
export default WorkflowNavScreen;