import { WorkflowListAccessScopeFilter, WorkflowListScopeFilter, WorkflowListTagScopeFilter, WorkflowListTypeFilter } from '@kindo/universal';
export const WORKFLOW_TYPE_FILTER_TO_TAB_LABEL: Record<WorkflowListTypeFilter, string> = {
  [WorkflowListTypeFilter.TRIGGERED]: 'Trigger Agents',
  [WorkflowListTypeFilter.MANUAL]: 'Workflow Agents',
  [WorkflowListTypeFilter.CHATBOT]: 'Chatbots',
  [WorkflowListTypeFilter.ALL]: 'All Agents'
};
export const WORKFLOW_TABLE_SOURCE_LABELS: Record<WorkflowListScopeFilter, string> = {
  // WorkflowListAccessScopeFilter
  [WorkflowListAccessScopeFilter.CREATOR_SCOPE]: 'Created by Me',
  [WorkflowListAccessScopeFilter.SHARED_SCOPE]: 'Shared With Me',
  [WorkflowListAccessScopeFilter.ORG_SCOPE]: 'Organization',
  // WorkflowListTagScopeFilter
  [WorkflowListTagScopeFilter.KINDO_TAG_SCOPE]: 'Kindo',
  [WorkflowListTagScopeFilter.COMMUNITY_TAG_SCOPE]: 'Community'
};