import { Integration } from '@kindo/universal';
import { IntegrationAccessTable } from '~/components/SettingsModal/SecuritySettings';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface UserGroupIntegrationAccessProps {
  userGroupId: string;
}
const UserGroupIntegrationAccess: React.FC<UserGroupIntegrationAccessProps> = ({
  userGroupId
}) => {
  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchIntegrationList
  } = nextTrpc.adminSecurityConfig.listUserGroupIntegrationSecurityConfigs.useQuery({
    userGroupId
  });
  const {
    mutate: updateUserGroupIntegrationAccessEnabled
  } = nextTrpc.adminSecurityConfig.updateUserGroupIntegrationAccessEnabled.useMutation<{
    enabled: boolean;
    integration: Integration;
    userGroupId: string;
  }>({
    onSuccess: () => {
      enqueueToast({
        message: 'Successfully updated integration access',
        type: ToastType.SUCCESS
      });
      refetchIntegrationList();
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to update integration access',
        type: ToastType.ERROR
      });
    }
  });
  const userGroupIntegrationSecurityConfigs = data?.items ?? [];
  const handleIntegrationToggle = (integration: Integration, isChecked: boolean) => {
    updateUserGroupIntegrationAccessEnabled({
      enabled: isChecked,
      integration,
      userGroupId
    });
  };
  return <div css={{
    "marginTop": "1rem",
    "marginBottom": "1rem"
  }} data-testid="user-group-integration-access-container">
      <IntegrationAccessTable data-testid="integration-access-table" handleIntegrationToggle={handleIntegrationToggle} integrationConfigs={userGroupIntegrationSecurityConfigs} />
    </div>;
};
export default UserGroupIntegrationAccess;