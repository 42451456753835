import SettingsSection from '../../SettingsSection';
import { CalloutType, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import { useMarkdownFile } from '~/hooks';
const ApiReference = () => {
  const {
    markdown: errorHandling
  } = useMarkdownFile('/docs/ApiSettings/ApiErrorHandling.md');
  const {
    markdown: apiOverview
  } = useMarkdownFile('/docs/ApiSettings/ApiOverview.md');
  const {
    markdown: apiRequest
  } = useMarkdownFile('/docs/ApiSettings/ApiRequestExample.md');
  const {
    markdown: apiRequestParameters
  } = useMarkdownFile('/docs/ApiSettings/ApiRequestParameters.md');
  const {
    markdown: apiBlockquote
  } = useMarkdownFile('/docs/ApiSettings/ApiBlockquote.md');
  return <SettingsSection title="API Reference">
      <div css={{
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem",
      "display": "flex",
      "width": "100%",
      "flexDirection": "column",
      "gap": "0.5rem",
      "borderRadius": "0.375rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "padding": "1.75rem"
    }} data-testid="api-reference-overview">
        <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Overview
        </Typography>
        <Markdown textColor={TextIconColor.SECONDARY}>{apiOverview}</Markdown>
      </div>
      <div css={{
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem",
      "display": "flex",
      "width": "100%",
      "flexDirection": "column",
      "gap": "0.5rem",
      "borderRadius": "0.375rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "padding": "1.75rem"
    }} data-testid="api-reference-available-apis">
        <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Available APIs
        </Typography>
        <div css={{
        "marginTop": "0.5rem",
        "marginBottom": "0.5rem",
        "display": "flex",
        "flexDirection": "column",
        "gap": "1.25rem"
      }} data-testid="main-section-container">
          <Markdown calloutType={CalloutType.INFO}>{apiBlockquote}</Markdown>
          <Markdown styleDocsComponents textColor={TextIconColor.SECONDARY}>
            {apiRequestParameters}
          </Markdown>

          <Markdown styleDocsComponents textColor={TextIconColor.PRIMARY}>
            {apiRequest}
          </Markdown>
        </div>
      </div>
      <div css={{
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem",
      "display": "flex",
      "width": "100%",
      "flexDirection": "column",
      "gap": "0.5rem",
      "borderRadius": "0.375rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "padding": "1.75rem"
    }} data-testid="api-reference-error-handling">
        <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Error Handling
        </Typography>
        <div css={{
        "marginTop": "0.5rem",
        "marginBottom": "0.5rem",
        "display": "flex",
        "flexDirection": "column",
        "gap": "1.25rem"
      }} data-testid="main-section-container">
          <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
            Kindo API uses standard HTTP status codes to indicate the success or
            failure of a request. In general, 2xx status codes indicate success,
            4xx indicate errors with data provided by the user (e.g. invalid
            input), and 5xx indicate Kindo server errors.
            <br />
            <br />
            For some 4xx and 5xx responses, Kindo will also return a code and
            message indicating the error.
          </Typography>

          <Markdown styleDocsComponents textColor={TextIconColor.PRIMARY}>
            {errorHandling}
          </Markdown>
        </div>
      </div>
    </SettingsSection>;
};
export default ApiReference;