import { FileType } from '@kindo/universal';
import { ZodType, z } from 'zod';

export enum ContentListSort {
  CREATED_AT = 'CREATED_AT',
  FILE_NAME = 'FILE_NAME',
  FILE_TYPE = 'FILE_TYPE',
  INTEGRATION = 'INTEGRATION_NAME'
}

export interface ContentListFilter {
  fileTypes?: FileType[] | undefined;
  filename?: string | undefined;
  integrationName?: string | undefined;
  textSearch?: string | undefined;
}

export interface ContentListArgs {
  filter: ContentListFilter | undefined;
  limit: number;
  skip: number;
  sort: ContentListSort | undefined;
  sortDesc: boolean | undefined;
  userId: string;
}

export const ZContentListFilter: ZodType<ContentListFilter> = z.lazy(() =>
  z.object({
    integrationName: z.string().optional(),
    filename: z.string().optional(),
    fileTypes: z.nativeEnum(FileType).array().optional(),
    textSearch: z.string().optional()
  })
);

export type ContentReturnType = {
  createdAt: Date;
  createdByName: string;
  fileId: string | null;
  filename: string;
  gcsUri: string | null;
  id: string;
  integrationName: string;
  llamaIndexedAt: string | null;
  mimeType: string;
  plaintextKey: string | undefined;
  s3Key: string;
  sizeBytes: number | null;
};
