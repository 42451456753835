import { FeatureFlags, OrgPaymentTier } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import React, { useState } from 'react';
import { ApiTab } from './ApiSettings';
import { PluginsTab } from './Plugins';
import { OrgSecuritySettingsTabs } from './SecuritySettings';
import { SettingsStructureItem, SettingsStructureSubItem, SettingsTab } from './SettingsModal.types';
import { ALL_TAB_COMPONENTS, DEFAULT_TAB_COMPONENT, getSettingsTabs } from './SettingsModal.utils';
import { Icon, Icons, Modal, ModalWidth, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { useAppSelector, useCanAccessSecuritySettings } from '~/hooks';
type SettingsModalProps = {
  initialTab: SettingsTab;
  onClose: () => void;
};
export const SettingsModal = ({
  onClose,
  initialTab
}: SettingsModalProps) => {
  const [activeTab, setActiveTab] = useState<SettingsTab | ApiTab | OrgSecuritySettingsTabs | PluginsTab>(initialTab);
  const [focusedTab, setFocusedTab] = useState<SettingsTab>(initialTab);
  const [isFocused, setIsFocused] = useState(false);
  const isUserGroupsEnabled = useFlag(FeatureFlags.USER_GROUPS);
  const {
    org
  } = useAppSelector(state => state.user);
  const isEnterprise = org?.paymentTier === OrgPaymentTier.ENTERPRISE;
  const {
    userCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const settingsStructure = getSettingsTabs(userCanAccessSecuritySettings, isEnterprise, isUserGroupsEnabled);

  // Simply look up the component directly from our unified mapping
  const activeComponent = ALL_TAB_COMPONENTS[activeTab] || DEFAULT_TAB_COMPONENT;
  const handleSettingsTabClick = (tab: SettingsStructureItem) => {
    setIsFocused(prevFocused => !(prevFocused && tab.tab === focusedTab));
    setFocusedTab(tab.tab);
    if (tab.hasComponent) {
      setActiveTab(tab.tab);
    }
  };
  const handleSettingsSubTabClick = (subTab: SettingsStructureSubItem) => {
    setActiveTab(subTab.tab);
  };
  return <Modal noContentPadding onClose={onClose} open overflowAuto={false} width={ModalWidth.TWELVE_HUNDRED}>
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexDirection": "row"
    }} data-testid="settings-wrapper">
        <div css={{
        "display": "flex",
        "width": "12rem",
        "flexDirection": "column",
        "gap": "0.25rem",
        "backgroundColor": "#06061DA3",
        "paddingLeft": "10px",
        "paddingRight": "10px",
        "paddingTop": "1.25rem",
        "paddingBottom": "1.25rem"
      }} data-testid="settings-sidebar-container">
          {Object.values(settingsStructure).map(tab => <React.Fragment key={tab.tab}>
              <div css={{
            "display": "flex",
            "cursor": "pointer",
            "flexDirection": "row",
            "alignItems": "center",
            "borderBottomWidth": "1px",
            "borderColor": "#e6c2ff1a",
            "paddingBottom": "6px",
            "paddingTop": "0.25rem"
          }} data-testid={`settings-sidebar-item-${tab.tab}`} onClick={() => {
            handleSettingsTabClick(tab);
          }}>
                <Typography casing={TypographyCasing.UPPERCASE} color={focusedTab === tab.tab ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} font={TypographyFont.INTERACTIVE} key={tab.tab} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD}>
                  {tab.displayName}
                </Typography>
                {tab.subTabs.length > 0 && <Icons color={focusedTab === tab.tab ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} icon={focusedTab === tab.tab && isFocused ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} />}
              </div>
              {tab.subTabs.length > 0 && focusedTab === tab.tab && isFocused && <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "2px",
            "paddingLeft": "15px",
            "paddingRight": "15px",
            "paddingTop": "6px"
          }} data-testid={`settings-sidebar-subtab-container-${tab.tab}`}>
                    {tab.subTabs.map(subTab => <div css={{
              "display": "flex",
              "cursor": "pointer",
              "flexDirection": "row",
              "alignItems": "center",
              "paddingLeft": "2px",
              "paddingRight": "2px",
              "paddingTop": "0.25rem",
              "paddingBottom": "0.25rem"
            }} data-testid={`settings-sidebar-subtab-${subTab.tab}`} key={subTab.tab} onClick={() => {
              handleSettingsSubTabClick(subTab);
            }}>
                        <Typography casing={TypographyCasing.UPPERCASE} color={activeTab === subTab.tab ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} font={TypographyFont.INTERACTIVE} key={subTab.tab} size={TypographySize.X_SMALL} weight={TypographyWeight.SEMI_BOLD}>
                          {subTab.displayName}
                        </Typography>
                      </div>)}
                  </div>}
            </React.Fragment>)}
        </div>
        <div css={{
        "position": "relative",
        "zIndex": "0",
        "display": "flex",
        "height": "80vh",
        "flex": "1 1 0%",
        "flexDirection": "column",
        "overflowY": "auto",
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem",
        "paddingTop": "1rem",
        "paddingBottom": "1rem"
      }} data-testid="settings-page-container">
          {activeComponent}
        </div>
      </div>
    </Modal>;
};
export default SettingsModal;