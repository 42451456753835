import { WorkflowType } from '@kindo/universal';
import { useRouter } from 'next/router';

import { WORKFLOW_TYPE_TO_BUILDER_SLUG } from '~/components/WorkflowFactory';

interface UseParseWorkflowUrlSlugReturn {
  workflowId: string | undefined;
  workflowType: WorkflowType | undefined;
}

/**
 * Parse the URL slug for WorkflowFactory views and flows
 *
 * URL patterns:
 * - During creation: /agents/builder/[workflow-type]
 * - After creation: /agents/builder/[workflow-id]
 *
 * @returns {UseParseWorkflowUrlSlugReturn} Object containing the parsed workflowId and workflowType
 * @example
 * const { workflowId, workflowType } = useParseWorkflowUrlSlug();
 */
const useParseWorkflowUrlSlug = (): UseParseWorkflowUrlSlugReturn => {
  const router = useRouter();

  const slugSegments = Array.isArray(router.query.slug)
    ? router.query.slug
    : [];

  const [_baseRoute, secondSegment] = slugSegments;

  // First check if the second segment is a workflow type (during creation)
  const workflowType = Object.values(WorkflowType).find((type) => {
    const builderSlug = WORKFLOW_TYPE_TO_BUILDER_SLUG[type];
    return builderSlug && slugSegments.includes(builderSlug);
  });

  // If it's not a workflow type, it must be a workflow ID
  const workflowId = !workflowType ? secondSegment?.trim() : undefined;

  return {
    workflowId,
    workflowType
  };
};

export default useParseWorkflowUrlSlug;
