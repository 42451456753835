import { TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '../core';
interface SettingsSectionProps {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  description?: string;
  headerEndElement?: React.ReactNode;
}
const SettingsSection = ({
  title,
  children,
  description,
  headerEndElement
}: SettingsSectionProps) => <div css={{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
}} data-testid="settings-section-container">
    <div css={{
    "display": "flex",
    "width": "100%",
    "flexDirection": "column"
  }} data-testid="settings-section-header">
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      {(description || !!headerEndElement) && <div css={{
      "marginTop": "1.5rem",
      "display": "flex",
      "width": "100%",
      "alignItems": "center",
      "justifyContent": "space-between"
    }} data-testid="settings-section-header-bottom">
          {description && <Typography color={TextIconColor.SECONDARY} italic size={TypographySize.MEDIUM}>
              {description}
            </Typography>}
          {headerEndElement}
        </div>}
    </div>
    {children}
  </div>;
export default SettingsSection;