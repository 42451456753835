import { OrgSetting, OrgSettingsConfig } from '@kindo/universal';

import { ToastType, useOrgSettings, useToast } from '~/hooks';

// For updating org share settings with pre-defined success and error callbacks
const useOrgShareSettings = <K extends OrgSetting>() => {
  const { enqueueToast } = useToast();
  const { orgSettings, updateOrgSetting, refetchOrgSettings, isLoading } =
    useOrgSettings();

  const updateOrgShareSetting = (setting: K, value: OrgSettingsConfig[K]) =>
    updateOrgSetting(
      setting,
      value,
      async () => {
        enqueueToast({
          message: 'Sharing permissions updated.',
          type: ToastType.SUCCESS
        });
        await refetchOrgSettings();
      },
      (error) => {
        console.error(error);
        enqueueToast({
          message:
            error.data?.kindoErrorMessage ||
            'Failed to update sharing permissions.',
          type: ToastType.ERROR
        });
      }
    );

  return { orgSettings, updateOrgShareSetting, isLoading };
};

export default useOrgShareSettings;
