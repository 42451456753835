import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';
import AddFileModal, { FileResource } from '~/components/Chat/AddFileModal';
import { Button, ButtonType, Icon } from '~/components/core';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { DEFAULT_WORKFLOW_STEP_MODEL, workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { isLlmWorkflowBuilderStep } from '~/types';
interface WorkflowStaticContentNodeComponentProps {
  contentId: string;
  contentName: string;
  setContentIdAndName: (contentId: string, contentName: string) => void;
}

/**
 * This component renders the decorator node "WorkflowStaticContentNode".
 *
 * It allows the user to select the output of another step from a dropdown.
 */
const WorkflowStaticContentNodeComponent: React.FC<WorkflowStaticContentNodeComponentProps> = ({
  contentId,
  contentName,
  setContentIdAndName
}) => {
  // State
  const [showAddFileModal, setShowAddFileModal] = useState<boolean>(false);

  // Redux
  const dispatch = useAppDispatch();
  const {
    activeStep
  } = useAppSelector(state => state.workflowBuilder);

  // Get lexical editor
  const [editor] = useLexicalComposerContext();

  // // When user selects a file
  const handleFileSelect = (files: FileResource[]) => {
    const file = files[0];
    if (files.length !== 1 || !file) {
      console.error('Exactly one file can be selected at a time');
      return;
    }

    // Add to the step inputs array
    dispatch(workflowBuilderActions.addStaticContent({
      id: file.id,
      fileName: file.name
    }));

    // Update lexical node (which is source of truth for the value)
    editor.update(() => {
      setContentIdAndName(file.id, file.name);
    });
  };
  const label = contentName || contentId || 'Unknown File';
  const isEmpty = !contentId;
  const model = isLlmWorkflowBuilderStep(activeStep) ? activeStep.model : DEFAULT_WORKFLOW_STEP_MODEL;
  return <>
      <Button label={isEmpty ? 'File' : label} onClick={() => setShowAddFileModal(true)} startIcon={isEmpty ? Icon.PLUS : Icon.FILE} type={isEmpty ? ButtonType.OUTLINED_COLOR : ButtonType.OUTLINED} />
      {showAddFileModal && <AddFileModal activeModel={model} initialSelectedFiles={[]} onClose={() => setShowAddFileModal(false)} onSelect={handleFileSelect} singleSelection />}
    </>;
};
export default WorkflowStaticContentNodeComponent;