import _styled from "styled-components";
import Segment, { SegmentOption } from './Segment';
import { SegmentedControlType } from './SegmentedControl.consts';
import { StyledSegmentedControl } from './SegmentedControl.styles';
const SegmentContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%"
});
interface SegmentedControlProps<SegmentValue extends string> {
  segmentOptions: SegmentOption<SegmentValue>[];
  type: SegmentedControlType;
  value: SegmentValue;
  onClick?: (value: SegmentValue) => void;
}
const SegmentedControl = <SegmentValue extends string,>({
  segmentOptions,
  type,
  value,
  onClick
}: SegmentedControlProps<SegmentValue>) => <StyledSegmentedControl $type={type}>
    {segmentOptions.map((segmentOption, index) => <SegmentContainer key={segmentOption.value}>
        <Segment<SegmentValue> isSelected={value === segmentOption.value} numSegments={segmentOptions.length} onClick={onClick} segment={segmentOption} segmentIndex={index} type={type} />
      </SegmentContainer>)}
  </StyledSegmentedControl>;
export default SegmentedControl;