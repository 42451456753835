import { Integration, INTEGRATION_TO_CATEGORIES, IntegrationCategory, IntegrationWorkflowInputType, isSupportedIntegrationAndCategory, isWorkflowSupportedIntegration, TICKETING_INTEGRATION_TICKET_FIELDS, TicketingIntegrationTicketField, WorkflowInputType, WorkflowSupportedIntegration } from '@kindo/universal';
import { Icon } from '~/components/core';

// TODO: [ENG-3778] Need to clean up/reorganize once necessary updates to
// integrations, workflow input integrations are made kindo universal

// Constants
export const TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES: Record<TicketingIntegrationTicketField, string> = {
  [TicketingIntegrationTicketField.ASSIGNEES]: 'Assignees',
  [TicketingIntegrationTicketField.CREATOR]: 'Creator',
  [TicketingIntegrationTicketField.DESCRIPTION]: 'Description',
  [TicketingIntegrationTicketField.INTEGRATION_NAME]: 'Integration Name',
  [TicketingIntegrationTicketField.TICKET_ID]: 'Ticket ID',
  [TicketingIntegrationTicketField.MERGE_ID]: 'Merge ID',
  [TicketingIntegrationTicketField.TITLE]: 'Title',
  [TicketingIntegrationTicketField.URL]: 'URL',
  [TicketingIntegrationTicketField.COMPLETED_AT]: 'Completed At',
  [TicketingIntegrationTicketField.CREATED_AT]: 'Created At',
  [TicketingIntegrationTicketField.DUE_DATE]: 'Due Date',
  [TicketingIntegrationTicketField.MODIFIED_AT]: 'Modified At',
  [TicketingIntegrationTicketField.PRIORITY]: 'Priority',
  [TicketingIntegrationTicketField.STATUS]: 'Status'
};
export const INTEGRATION_INPUT_TYPE_TO_FIELDS = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]: TICKETING_INTEGRATION_TICKET_FIELDS
} as const satisfies Record<IntegrationWorkflowInputType, string[]>;
export const INTEGRATION_INPUT_TYPE_DISPLAY_NAMES: Record<IntegrationWorkflowInputType, string> = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]: 'Ticket'
};
export const WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON: Record<WorkflowSupportedIntegration, Icon> = {
  [Integration.SERVICENOW]: Icon.SERVICENOW,
  [Integration.LINEAR]: Icon.LINEAR,
  [Integration.JIRA]: Icon.JIRA,
  [Integration.AZURE_DEVOPS]: Icon.AZURE_DEVOPS,
  [Integration.GITHUB_ISSUES]: Icon.GITHUB_ISSUES
};
export function getIntegrationIcon(integration: Integration): Icon {
  return isWorkflowSupportedIntegration(integration) ? WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON[integration] : Icon.CLOSE;
}
export function getSupportedIntegrationCategoriesForIntegration(integration: Integration): IntegrationCategory[] {
  return isWorkflowSupportedIntegration(integration) ? INTEGRATION_TO_CATEGORIES[integration].filter(category => isSupportedIntegrationAndCategory(integration, category)) : [];
}