import { useState } from 'react';
import SettingsSection from '../../SettingsSection';
import { Icon, TextIconColor, Typography, TypographyCasing, TypographySize } from '~/components/core';
import Button from '~/components/core/Button/Button';
import { ButtonType } from '~/components/core/Button/Button.consts';
import IconButton from '~/components/core/IconButton/IconButton';
import { IconButtonType } from '~/components/core/IconButton/IconButton.consts';
import TextField from '~/components/core/TextField/TextField';
import { TextFieldType } from '~/components/core/TextField/TextField.consts';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface SecretCreationProps {
  refetchSecrets: () => void;
  secrets: {
    secretId: string;
    secretLabel: string;
  }[];
}
const SecretCreation = ({
  refetchSecrets,
  secrets
}: SecretCreationProps) => {
  const [secretLabel, setSecretLabel] = useState<string>('');
  const [secretValue, setSecretValue] = useState<string>('');
  const [showSecretValue, setShowSecretValue] = useState<boolean>(false);
  const {
    enqueueToast
  } = useToast();
  const handleClearForm = () => {
    setSecretValue('');
    setSecretLabel('');
  };
  const createSecretMutation = nextTrpc.secrets.createSecret.useMutation({
    onSuccess: () => {
      refetchSecrets();
      handleClearForm();
      enqueueToast({
        message: 'Secret created successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to create secret:', error);
      enqueueToast({
        message: 'Failed to create secret',
        type: ToastType.ERROR
      });
    }
  });
  const handleSaveSecret = () => {
    if (!secretValue || !secretLabel) return;
    createSecretMutation.mutate({
      label: secretLabel,
      plainTextSecret: secretValue
    });
  };
  const isSecretLabelValid = () => {
    const trimmedSecretLabel = secretLabel.trim();
    if (trimmedSecretLabel === '') {
      return false;
    }
    return trimmedSecretLabel && secrets.every(secret => secret.secretLabel !== trimmedSecretLabel);
  };
  const renderStartIcon = () => {
    const trimmedSecretLabel = secretLabel.trim();
    if (trimmedSecretLabel === '') {
      return undefined;
    }
    if (isSecretLabelValid()) {
      return Icon.CHECK;
    }
    return Icon.CLOSE;
  };
  return <div css={{
    "display": "flex",
    "height": "auto",
    "minHeight": "160px",
    "borderRadius": "0.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
    "padding": "1.5rem"
  }} data-testid="add-secret-container">
      <SettingsSection title="Add Secret">
        <div css={{
        "display": "flex",
        "width": "100%",
        "flexDirection": "column",
        "gap": "1rem",
        "@media (min-width: 1024px)": {
          "flexDirection": "row",
          "alignItems": "flex-end",
          "justifyContent": "space-between"
        }
      }} data-testid="secret-form-container">
          <div css={{
          "display": "flex",
          "flex": "1 1 0%",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="secret-label-container">
            <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} size={TypographySize.X_SMALL}>
              LABEL
            </Typography>
            <TextField fullWidth onChange={setSecretLabel} placeholder="Create a name for your secret" size={TypographySize.SMALL} startIcon={renderStartIcon()} startIconFocusColor={renderStartIcon() === Icon.CHECK ? TextIconColor.HIGHLIGHT : TextIconColor.ERROR} type={TextFieldType.DEFAULT} value={secretLabel} />
          </div>

          <div css={{
          "display": "flex",
          "flex": "1 1 0%",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="secret-value-container">
            <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} size={TypographySize.X_SMALL}>
              VALUE
            </Typography>
            <div css={{
            "display": "flex",
            "width": "100%",
            "alignItems": "center",
            "gap": "0.5rem"
          }} data-testid="secret-value-row">
              <TextField fullWidth isPassword={!showSecretValue} onChange={setSecretValue} placeholder="Enter your secret value" size={TypographySize.SMALL} startIcon={secretValue ? Icon.CHECK : undefined} startIconFocusColor={TextIconColor.HIGHLIGHT} type={TextFieldType.DEFAULT} value={secretValue} />
              <IconButton icon={Icon.EYE} onClick={() => setShowSecretValue(!showSecretValue)} type={IconButtonType.SIMPLE} />
            </div>
          </div>

          <Button disabled={!secretValue || !secretLabel || createSecretMutation.isLoading || !isSecretLabelValid()} endIcon={Icon.LOCK} label={createSecretMutation.isLoading ? 'SAVING...' : 'SAVE'} onClick={handleSaveSecret} type={ButtonType.SOLID_COLOR} />
        </div>
      </SettingsSection>
    </div>;
};
export default SecretCreation;