import { assertUnreachable, FeatureFlags, WORKFLOW_PUBLIC_ROLES, WorkflowOrgRole, WorkflowPublicRole, WorkflowRole, WorkflowUser, WorkflowUserGroupRole, WorkflowUserRole } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { useState } from 'react';
import SharedPermissionRow from '../shared/SharedPermissionRow';
import ShareStaticContentConfirmation from './ShareStaticContentConfirmation';
import { Button, ButtonType, Expand, Icon, Modal, Select, SelectOptionType, Size, TextField, TextFieldType, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { CopyButton } from '~/components/shared';
import { BASE_NEXT_URL, getWorkstationRoute } from '~/constants';
import { ToastType, useAppSelector, useCanAccessSecuritySettings, useCanShareWorkflow, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { Content, isLlmWorkflowStep } from '~/types';
import { capitalizeFirstLetter } from '~/utils';
interface ShareWorkflowModalProps {
  onClose: () => void;
  workflowId: string;
}
const getWorkflowRoleOptionDescription = (role: WorkflowRole): string => {
  switch (role) {
    case WorkflowRole.OWNER:
      return 'Can run, edit, delete, and share this agent.';
    case WorkflowRole.EDITOR:
      return 'Can run and edit this agent.';
    case WorkflowRole.VIEWER:
      return 'Can only run this agent.';
    default:
      return assertUnreachable(role);
  }
};
const getWorkflowPublicAccessLevelDescription = (role: WorkflowPublicRole | null): string => {
  switch (role) {
    case null:
      return 'Only people who are shared can access this agent.';
    case WorkflowRole.EDITOR:
      return 'Anyone with the link can edit this agent.';
    case WorkflowRole.VIEWER:
      return 'Anyone with the link can view and run this agent.';
    default:
      assertUnreachable(role);
      return '';
  }
};
const getWorkflowOrgAccessLevelDescription = (role: WorkflowOrgRole | null): string => {
  switch (role) {
    case null:
      return 'Only people who are shared can access this agent.';
    case WorkflowRole.EDITOR:
      return 'Anyone in the organization can edit this agent.';
    case WorkflowRole.OWNER:
      return 'Anyone in the organization can manage and edit this agent.';
    case WorkflowRole.VIEWER:
      return 'Anyone in the organization can view and run this agent.';
    default:
      assertUnreachable(role);
      return '';
  }
};
const ShareWorkflowModal: React.FC<ShareWorkflowModalProps> = ({
  onClose,
  workflowId
}) => {
  // State
  const [inviteUserInput, setInviteUserInput] = useState<string>('');
  const [inputErrorMessage, setInputErrorMessage] = useState<string>('');

  // Feature flags
  const isUserGroupsEnabled = useFlag(FeatureFlags.USER_GROUPS);

  /**
   * When a user is sharing a workflow with static content,
   * we need to inform them that the contents of the files/data can
   * be exposed, and get their confirmation before sharing.
   *
   * If the callback exists, it will display the confirmation step
   * and call it when the user confirms.
   */
  const [shareStaticContentCallback, setShareStaticContentCallback] = useState<null | (() => void)>(null);

  // Redux
  const {
    userEmail
  } = useAppSelector(state => state.user);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    canShareWorkflowWithPublic
  } = useCanShareWorkflow(workflowId);
  const {
    userCanManageOrganization
  } = useCanAccessSecuritySettings();

  // Queries
  const {
    data,
    refetch: refetchWorkflow
  } = nextTrpc.workflows.get.useQuery({
    workflowId
  });
  const workflow = data?.item;
  const {
    data: authorizedUsers,
    refetch: refetchAuthorizedUsers
  } = nextTrpc.workflows.getWorkflowAuthorizedUsers.useQuery({
    workflowId
  });
  const {
    data: usersUserGroups
  } = nextTrpc.userGroups.list.useQuery();
  const {
    data: userGroupShareOptionsData
  } = nextTrpc.workflows.getWorkflowUserGroupShareOptions.useQuery({
    workflowId
  }, {
    enabled: isUserGroupsEnabled
  });
  const userGroupShareOptions = userGroupShareOptionsData?.items ?? [];

  // Mutations
  const updateWorkflowPublicRoleMutation = nextTrpc.workflows.updatePublicRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated agent public access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update agent public access',
        type: ToastType.ERROR
      });
      console.error('Failed to update agent public access', error);
    }
  });
  const updateWorkflowOrgRoleMutation = nextTrpc.workflows.updateOrgRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated agent org access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update agent org access',
        type: ToastType.ERROR
      });
      console.error('Failed to update agent org access', error);
    }
  });
  const updateWorkflowUserRoleMutation = nextTrpc.workflows.updateUserRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated user agent access',
        type: ToastType.SUCCESS
      });
      await refetchAuthorizedUsers();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update user agent access',
        type: ToastType.ERROR
      });
      console.error('Failed to update user agent access', error);
    }
  });
  const removeWorkflowUserRoleMutation = nextTrpc.workflows.removeUserRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully removed user agent access',
        type: ToastType.SUCCESS
      });
      await refetchAuthorizedUsers();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to remove user agent access',
        type: ToastType.ERROR
      });
      console.error('Failed to remove user agent access', error);
    }
  });
  const updateWorkflowUserGroupRoleMutation = nextTrpc.workflows.updateUserGroupRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated user group agent access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    }
  });
  const removeWorkflowUserGroupRoleMutation = nextTrpc.workflows.removeUserGroupRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully removed user group agent access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    }
  });

  // Handlers
  const handleUpdatePublicRole = (role: WorkflowPublicRole | null) => {
    setShareStaticContentCallback(null);
    updateWorkflowPublicRoleMutation.mutate({
      workflowId,
      publicRole: role
    });
  };
  const handleUpdateOrgRole = (role: WorkflowOrgRole | null) => {
    setShareStaticContentCallback(null);
    updateWorkflowOrgRoleMutation.mutate({
      workflowId,
      orgRole: role
    });
  };
  const handleUpdateUserPermission = (userRole: WorkflowUser, role: WorkflowUserRole | null) => {
    setShareStaticContentCallback(null);

    // Remove user if role is null
    if (role === null) {
      removeWorkflowUserRoleMutation.mutate({
        workflowId,
        userId: userRole.id
      });
      return;
    }

    // Update user role
    updateWorkflowUserRoleMutation.mutate({
      workflowId,
      email: userRole.email,
      workflowRole: role
    });
  };
  const handleAddUser = async () => {
    setShareStaticContentCallback(null);
    if (!inviteUserInput) {
      setInputErrorMessage('Please enter an email.');
      return;
    }
    try {
      await updateWorkflowUserRoleMutation.mutateAsync({
        workflowId,
        email: inviteUserInput,
        workflowRole: WorkflowRole.VIEWER
      });
      setInviteUserInput('');
      setInputErrorMessage('');
    } catch (error: any) {
      const errorMessage = error.data.kindoErrorMessage || 'Failed to add user to agent.';
      console.error(errorMessage, error);
      setInputErrorMessage(errorMessage);
    }
  };
  const handleUpdateUserGroupRole = ({
    userGroupId,
    role
  }: {
    role: WorkflowUserGroupRole | null;
    userGroupId: string;
  }) => {
    setShareStaticContentCallback(null);

    // Remove user group if role is null
    if (role === null) {
      removeWorkflowUserGroupRoleMutation.mutate({
        workflowId,
        userGroupId
      });
      return;
    }
    updateWorkflowUserGroupRoleMutation.mutate({
      workflowId,
      userGroupId,
      role
    });
  };
  const renderSectionTitle = (title: string) => <Typography color={TextIconColor.PRIMARY} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
      {title}
    </Typography>;
  const workflowStepStaticContent: Content[] = workflow?.steps?.filter(isLlmWorkflowStep).flatMap(step => step.staticContent) ?? [];
  const shouldShowConfirmShareStaticContentStage = !workflow?.publicRole && !!workflowStepStaticContent.length;

  // TODO: Render something different if user cannot share

  // Workflow.publicRole options
  const publicAccessRoleOptions: SelectOptionType<WorkflowPublicRole | null>[] = [{
    label: 'None',
    value: null
  }, ...WORKFLOW_PUBLIC_ROLES.map(role => ({
    label: capitalizeFirstLetter(role.toLowerCase()),
    value: role,
    description: getWorkflowRoleOptionDescription(role)
  }))];
  const baseRoleOptions: SelectOptionType<WorkflowRole | null>[] = [WorkflowRole.VIEWER, WorkflowRole.EDITOR, WorkflowRole.OWNER].map(role => ({
    label: capitalizeFirstLetter(role.toLowerCase()),
    value: role,
    description: getWorkflowRoleOptionDescription(role)
  }));
  // Workflow.orgRole options
  const orgAccessRoleOptions: SelectOptionType<WorkflowOrgRole | null>[] = [{
    label: 'None',
    value: null
  }, ...baseRoleOptions];
  // Workflow.userRole options
  const userRoleOptions: SelectOptionType<WorkflowUserRole | null>[] = [...baseRoleOptions, {
    label: 'Remove',
    value: null,
    destructive: true
  }];

  // Workflow.userGroupRole options
  const userGroupRoleOptions: SelectOptionType<WorkflowUserGroupRole | null>[] = [...baseRoleOptions, {
    label: 'Remove',
    value: null,
    destructive: true
  }];

  // List of WorkflowUserGroupRoles currently associated with this workflow
  const existingWorkflowUserGroupRoles = workflow?.userGroupRoles ?? [];
  const availableUserGroups = userGroupShareOptions.filter(group => !existingWorkflowUserGroupRoles.some(role => role.id === group.id));
  return <Modal onClose={onClose} open>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "1rem"
    }} data-testid="share-workflow-modal-container">
        {!!shareStaticContentCallback && <ShareStaticContentConfirmation onClose={() => setShareStaticContentCallback(null)} onConfirm={shareStaticContentCallback} staticContent={workflowStepStaticContent} />}
        {!shareStaticContentCallback && <>
            {/* Title */}
            <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3}>
              Share Agent
              {workflow?.displayName ? ` "${workflow.displayName}"` : ''}
            </Typography>

            {/* WorkflowUserRole section */}
            <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="share-workflow-user-permissions-container">
              {renderSectionTitle('User Permissions')}
              {/* Invite users input */}
              <div css={{
            "display": "flex",
            "gap": "0.5rem",
            "paddingTop": "0.5rem",
            "paddingBottom": "0.5rem"
          }} data-testid="invite-user-container">
                <TextField error={!!inputErrorMessage} errorMessage={inputErrorMessage} fullWidth onChange={value => {
              setInputErrorMessage('');
              setInviteUserInput(value);
            }} onEnter={shouldShowConfirmShareStaticContentStage ? () => setShareStaticContentCallback(() => handleAddUser) : handleAddUser} placeholder="Enter email of a Kindo user to share" type={TextFieldType.HIGHLIGHT} value={inviteUserInput} />
                <Button label="Share" onClick={shouldShowConfirmShareStaticContentStage ? () => setShareStaticContentCallback(() => handleAddUser) : handleAddUser} type={ButtonType.SOLID} />
              </div>
              <div css={{
            "display": "flex",
            "width": "100%",
            "paddingTop": "0.25rem",
            "paddingBottom": "0.25rem"
          }} data-testid="expand-container-1">
                <Expand disabled={authorizedUsers?.length === 0} trigger={<Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                      {authorizedUsers?.length === 0 ? 'This agent has not been shared with any users' : `Users this agent has been shared with (${authorizedUsers?.length})`}
                    </Typography>}>
                  {/* List of shared users */}
                  {authorizedUsers && <div css={{
                "display": "flex",
                "flexDirection": "column",
                "> :not([hidden]) ~ :not([hidden])": {
                  "--tw-divide-y-reverse": "0",
                  "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
                  "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
                  "borderColor": "rgb(255 255 255 / 0.1)"
                },
                "paddingLeft": "1.5rem"
              }} id="share-workflow-user-permission-rows-container">
                      {authorizedUsers.map(userRole => <SharedPermissionRow<WorkflowUserRole> disabled={userRole.email === userEmail} key={userRole.id} onChange={newRole => handleUpdateUserPermission(userRole, newRole)} options={userRoleOptions} permission={userRole.role} subtitle={userRole.email} title={userRole.name} />)}
                    </div>}
                </Expand>
              </div>
            </div>
            {/* WorkflowUserGroupRole section */}
            {isUserGroupsEnabled && <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="share-workflow-user-permissions-container">
                <div css={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "space-between",
            "borderTopWidth": "1px",
            "borderColor": "rgb(255 255 255 / 0.2)",
            "paddingTop": "1rem"
          }} id="share-modal-access-title-and-action-container">
                  {renderSectionTitle('User Group Permissions')}
                  {/* Select user group */}
                  <Dropdown closeOnClick data-id="workflow_user_group_select" trigger={<Button disabled={availableUserGroups.length === 0} endIcon={Icon.PLUS} label="Add Group" onClick={() => {}} size={Size.MEDIUM} tooltip={availableUserGroups.length === 0 ? 'All possible user groups are already added' : ''} type={ButtonType.SOLID_COLOR} typographySize={TypographySize.X_SMALL} />}>
                    {availableUserGroups.length > 0 ? availableUserGroups.map(userGroup => <div key={userGroup.id} role="menuitem">
                            <DropdownItem key={userGroup.id} onClick={() => handleUpdateUserGroupRole({
                  userGroupId: userGroup.id,
                  role: WorkflowRole.VIEWER
                })} title={userGroup.name} />
                          </div>) : null}
                  </Dropdown>
                </div>
                {/* List of shared user groups */}
                <div css={{
            "display": "flex",
            "width": "100%",
            "paddingTop": "0.25rem",
            "paddingBottom": "0.25rem"
          }} data-testid="expand-container">
                  <Expand disabled={existingWorkflowUserGroupRoles?.length === 0} trigger={<Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                        {existingWorkflowUserGroupRoles?.length === 0 ? 'This agent has not been shared with any user groups' : `User groups this agent has been shared with (${existingWorkflowUserGroupRoles?.length})`}
                      </Typography>}>
                    <div css={{
                "display": "flex",
                "flexDirection": "column",
                "> :not([hidden]) ~ :not([hidden])": {
                  "--tw-divide-y-reverse": "0",
                  "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
                  "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
                  "borderColor": "rgb(255 255 255 / 0.1)"
                },
                "paddingLeft": "1.5rem"
              }} id="share-workflow-user-permission-rows-container">
                      {!!existingWorkflowUserGroupRoles && existingWorkflowUserGroupRoles.map(userGroup => {
                  const isMember = !!usersUserGroups?.items?.find(ug => ug.id === userGroup.id);
                  return <SharedPermissionRow<WorkflowUserGroupRole> disabled={!(isMember || userCanManageOrganization)} key={userGroup.id} onChange={newRole => {
                    handleUpdateUserGroupRole({
                      userGroupId: userGroup.id,
                      role: newRole
                    });
                  }} options={userGroupRoleOptions} permission={userGroup.role} subtitle={isMember ? 'You are a member of this user group.' : ''} title={userGroup.name} />;
                })}
                    </div>
                  </Expand>
                </div>
              </div>}
            {/* Workflow orgRole section */}
            {workflow && <div css={{
          "display": "flex",
          "flexDirection": "column"
        }} id="section-title-and-description">
                <div css={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "space-between",
            "borderTopWidth": "1px",
            "borderColor": "rgb(255 255 255 / 0.2)",
            "paddingTop": "1rem"
          }} id="share-modal-access-title-and-action-container">
                  {renderSectionTitle('Organization Access')}
                  <Select<WorkflowOrgRole | null> data-id="workflow_access_status" onChange={value => shouldShowConfirmShareStaticContentStage ? setShareStaticContentCallback(() => () => handleUpdateOrgRole(value)) : handleUpdateOrgRole(value)} options={orgAccessRoleOptions} placeholderLabel="Select Access Level" value={workflow.orgRole} />
                </div>
                <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                  {getWorkflowOrgAccessLevelDescription(workflow.orgRole)}
                </Typography>
              </div>}
            {/* Workflow publicRole section */}
            {workflow && canShareWorkflowWithPublic && <div css={{
          "display": "flex",
          "flexDirection": "column"
        }} id="section-title-and-description">
                <div css={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "space-between",
            "borderTopWidth": "1px",
            "borderColor": "rgb(255 255 255 / 0.2)",
            "paddingTop": "1rem"
          }} id="share-modal-access-title-and-action-container">
                  {renderSectionTitle('Public Access')}
                  <Select<WorkflowPublicRole | null> data-id="workflow_access_status" onChange={value => shouldShowConfirmShareStaticContentStage ? setShareStaticContentCallback(() => () => handleUpdatePublicRole(value)) : handleUpdatePublicRole(value)} options={publicAccessRoleOptions} placeholderLabel="Select Access Level" value={workflow.publicRole} />
                </div>
                <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                  {getWorkflowPublicAccessLevelDescription(workflow.publicRole)}
                </Typography>
              </div>}
            <CopyButton data-id="workflow_link_copy" label="Copy Agent Link" text={BASE_NEXT_URL + getWorkstationRoute({
          workflowId
        })} tooltip="Share links only work for users who already have access to this agent via the above controls" />
          </>}
      </div>
    </Modal>;
};
export default ShareWorkflowModal;