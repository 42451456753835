import _styled from "styled-components";
import { ApiAuthorizationType, GeneratedApiHeaders, GeneratedApiValuesWithFormattedHeaders, isSecretHeaderValue } from '@kindo/universal';
import { useEffect, useState } from 'react';
import { Button, ButtonType, Icon, Modal, ModalWidth, TextField, TextFieldType, TextIconColor, Typography, TypographyAlignment, TypographyFont, TypographySize } from '../core';
import { JsonViewer } from '~/components/shared/JsonViewer';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const ModalContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1.5rem"
});
const HeaderContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ObjectiveContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ConfigPreviewContainer = _styled.div({
  "marginTop": "1rem",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ButtonContainer = _styled.div({
  "display": "flex",
  "justifyContent": "flex-end",
  "gap": "0.5rem"
});
const Divider = _styled.hr({
  "marginTop": "0.5rem",
  "marginBottom": "0.5rem",
  "borderColor": "rgb(150 148 181 / 0.2)"
});
interface ApiStepGenerationModalProps {
  onClose: () => void;
  onGenerate: (generatedValues: GeneratedApiValuesWithFormattedHeaders) => void;
  open: boolean;
  // Initial API step values if they exist
  initialValues?: GeneratedApiValuesWithFormattedHeaders | null;
}

/**
 * Formats the API config for display purposes by simplifying header values
 * For preview only - does not modify the actual data structure needed for the API
 */
const formatConfigForDisplay = (config: GeneratedApiValuesWithFormattedHeaders) => {
  if (!config) return config;
  const displayHeaders: GeneratedApiHeaders = {};
  Object.entries(config.headers ?? {}).forEach(([key, value]) => {
    displayHeaders[key] = 'secretId' in value ? '<SECRET>' : value.text;
  });
  if (config.auth) {
    const prefix = config.auth.authorizationType === ApiAuthorizationType.BEARER ? 'Bearer ' : '';
    displayHeaders['Authorization'] = 'secretId' in config.auth.value ? `${prefix}<SECRET>` : `${prefix}${config.auth.value.text}`;
  }
  const {
    auth: _auth,
    ...rest
  } = config;
  return {
    ...rest,
    headers: displayHeaders
  };
};
const ApiStepGenerationModal: React.FC<ApiStepGenerationModalProps> = ({
  open,
  onClose,
  onGenerate,
  initialValues
}) => {
  const [objective, setObjective] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [apiConfigData, setApiConfigData] = useState(initialValues);
  const [hasNewlyGeneratedConfig, setHasNewlyGeneratedConfig] = useState(false);
  const {
    enqueueToast
  } = useToast();

  // Reset state when modal opens with initialValues
  useEffect(() => {
    if (open) {
      setApiConfigData(initialValues);
      // Only reset the config indicator when opening with new initialValues
      setHasNewlyGeneratedConfig(false);
    }
  }, [open, initialValues]);

  // TRPC mutation for API step generation
  const generateApiActionMutation = nextTrpc.workflows.generateApiActionStepValuesFromUserObjective.useMutation({
    onSuccess: data => {
      if (data) {
        setApiConfigData(data);
        setHasNewlyGeneratedConfig(true);
      }
      if (data === null) {
        enqueueToast({
          message: 'Could not find an API based on description. Please try again.',
          type: ToastType.WARNING
        });
      }
      setIsGenerating(false);
    },
    onError: error => {
      console.error('Failed to generate API step:', error);
      enqueueToast({
        message: 'Failed to generate API configuration. Please try again.',
        type: ToastType.ERROR
      });
      setIsGenerating(false);
    }
  });
  const handleGenerate = () => {
    if (!objective.trim()) {
      enqueueToast({
        message: 'Please enter an objective',
        type: ToastType.ERROR
      });
      return;
    }
    setIsGenerating(true);
    generateApiActionMutation.mutate({
      userObjective: objective.trim()
    });
  };
  const handleApply = () => {
    if (!apiConfigData) {
      enqueueToast({
        message: 'Please generate configuration first',
        type: ToastType.ERROR
      });
      return;
    }
    onGenerate(apiConfigData);
    onClose();
  };
  const handleCancel = () => {
    // Reset all state to initial values
    setObjective('');
    setApiConfigData(initialValues);
    setHasNewlyGeneratedConfig(false);
    onClose();
  };

  // Only show preview when a new configuration has been generated
  const showPreview = hasNewlyGeneratedConfig && apiConfigData !== undefined && apiConfigData !== null;

  // Determine if this is first generation or regeneration
  const isFirstGeneration = !hasNewlyGeneratedConfig;

  // Determine if the generated configuration uses any user secrets
  const usedUserSecrets = apiConfigData && (Object.values(apiConfigData.headers ?? {}).some(isSecretHeaderValue) || apiConfigData.auth && isSecretHeaderValue(apiConfigData.auth.value));
  return <Modal onClose={handleCancel} open={open} width={ModalWidth.XL}>
      <ModalContainer>
        <HeaderContainer>
          <Typography alignment={TypographyAlignment.CENTER} font={TypographyFont.HEADING} size={TypographySize.H4}>
            Generate API Step
          </Typography>
          <Typography alignment={TypographyAlignment.CENTER} color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
            Describe your API objective and we'll generate the required
            configuration
          </Typography>
        </HeaderContainer>

        <ObjectiveContainer>
          <TextField fullWidth minRows={4} multiline onChange={value => {
          setObjective(value);
        }} placeholder="I want to fetch a user's profile from the GitHub API..." type={TextFieldType.DEFAULT} value={objective} />
        </ObjectiveContainer>

        {showPreview && <>
            <Divider />
            <ConfigPreviewContainer>
              <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.LARGE}>
                Generated API Configuration
              </Typography>
              {usedUserSecrets && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                  {`At least one of your secrets, represented by "<SECRET>", was used in the configuration. 
                  You can review the secrets used and make any necessary adjustments after applying.`}
                </Typography>}
              <JsonViewer data={formatConfigForDisplay(apiConfigData)} />
            </ConfigPreviewContainer>
          </>}

        <ButtonContainer>
          <Button label="Cancel" onClick={handleCancel} type={ButtonType.OUTLINED} />
          <Button disabled={!objective.trim()} endIcon={Icon.SPARKLES} label={isFirstGeneration ? 'Generate' : 'Regenerate'} loading={isGenerating} onClick={handleGenerate} type={ButtonType.OUTLINED_COLOR} />
          <Button disabled={!showPreview} endIcon={Icon.CHECK} label="Apply" onClick={handleApply} type={ButtonType.SOLID_COLOR} />
        </ButtonContainer>
      </ModalContainer>
    </Modal>;
};
export default ApiStepGenerationModal;