import _styled from "styled-components";
import { isValidElement } from 'react';
import { Filters, FiltersProps } from '../../shared/Filters';
import SortHeader, { CsvHeader, Header, TableSort } from '../../SortHeader/SortHeader';
import { Button, ButtonType } from '../Button';
import { TextIconColor, Size } from '../constants';
import { DateTimeRangePicker, DateTimeRangePickerProps } from '../DateTimeRangePicker';
import { withLink } from '../hocs';
import { Icon, Icons } from '../Icon';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { TabNavigator } from '../TabNavigator';
import { TabNavigatorType } from '../TabNavigator/TabNavigator.consts';
import { TabOption } from '../TabNavigator/TabNavigatorOption';
import { ToolTip } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight, TypographyWrap } from '../Typography';
import { StyledTableRow, TableCell, TableCellTypographyWrapper, TableCheckboxInnerContainer, TableCheckboxOuterContainer, TableCheckboxTd, TableContainer, TableHead, TableHeader, TableHeaderRow, TableNoRowsTd, TableRoot } from './Table.styles';
const DEFAULT_ROW_HEIGHT = {
  "height": "4rem"
};
function renderTableHeader<SortKey extends string>(col: TableColumn<SortKey>, sortProps?: {
  activeSort: TableSort<SortKey>;
  setActiveSort: (value: TableSort<SortKey>) => void;
}, isCsv = false) {
  const canSort = !!sortProps && !!col.sortKey;
  if (canSort) {
    return <SortHeader<SortKey> activeSort={sortProps.activeSort} color={TextIconColor.SECONDARY} setSortKey={newKey => sortProps.setActiveSort(newKey)} sortKey={col.sortKey ?? '' as SortKey} title={col.title} />;
  }
  if (isCsv) {
    return <CsvHeader title={col.title} />;
  }
  return <Header color={TextIconColor.SECONDARY} title={col.title} />;
}
export interface TableColumn<SortKey extends string = string> {
  title: string;
  sortKey?: SortKey;
  tooltipEnabled?: boolean;
  width?: number | TwStyle;
  wrap?: TypographyWrap;
}
export interface TableRow<TRowKey extends string = string> {
  cells: React.ReactNode[];
  key: TRowKey;
  disabled?: boolean;
  disabledTextColor?: TextIconColor | undefined;
  height?: TwStyle;
  href?: string;
  onClick?: () => void;
  textColor?: TextIconColor;
  toolTip?: string | undefined;
}
export interface TableSelectionProps<TRowKey> {
  selectedItems: TRowKey[];
  setSelectedItems: (keys: TRowKey[]) => void;
  singleSelection?: boolean;
}
interface ClearFilterProps {
  hasFilters: boolean;
  label: string;
  onClick: () => void;
}
export interface TabViewProps<TabValue extends string> {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
  tabOptions: TabOption<TabValue>[];
}
export type TableProps<TRowKey extends string, FilterKey extends string, SortKey extends string, TabValue extends string> = {
  columns: TableColumn<SortKey>[];
  noRowsText: string;
  rows: (TableRow<TRowKey> | React.ReactElement)[];
  clearFilterProps?: ClearFilterProps;
  dateRangePickerProps?: DateTimeRangePickerProps;
  filterProps?: FiltersProps<FilterKey> | undefined;
  isCsv?: boolean;
  loading?: boolean;
  selectionProps?: TableSelectionProps<TRowKey>;
  showColumnHeaderBorder?: boolean;
  showHeader?: boolean;
  sortProps?: {
    activeSort: TableSort<SortKey>;
    setActiveSort: (value: TableSort<SortKey>) => void;
  } | undefined;
  tabViewProps?: TabViewProps<TabValue> | undefined;
  title?: string;
};
const FilterContainer = _styled.div({
  "display": "flex",
  "gap": "0.5rem"
});
const ClearFilterButtonContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "justifyContent": "flex-end"
});
const TabsAndFiltersContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
function Table<TKey extends string, FilterKey extends string = string, SortKey extends string = string, TabValue extends string = string>({
  columns,
  rows,
  selectionProps,
  noRowsText,
  clearFilterProps,
  dateRangePickerProps,
  filterProps,
  loading = false,
  showColumnHeaderBorder = false,
  sortProps,
  title,
  showHeader = true,
  tabViewProps,
  isCsv = false
}: TableProps<TKey, FilterKey, SortKey, TabValue>) {
  const {
    selectedItems,
    setSelectedItems,
    singleSelection
  } = selectionProps ?? {};
  const handleToggleSelection = (key: TKey) => {
    if (!setSelectedItems || !selectedItems) return;
    if (selectedItems?.includes(key)) {
      setSelectedItems(selectedItems.filter(item => item !== key));
    } else {
      setSelectedItems(singleSelection ? [key] : [...selectedItems, key]);
    }
  };

  // Leave space in the header for checkboxes if selection is enabled
  const columnsWithSelect: TableColumn<SortKey>[] = selectionProps ? [{
    title: '',
    sortKey: '' as SortKey,
    width: {
      "width": "8.333333%"
    }
  }, ...columns] : columns;
  const showFilters = filterProps || dateRangePickerProps;
  const {
    tableCellFont,
    tableCellSize
  } = isCsv ? {
    tableCellFont: TypographyFont.PARAGRAPH,
    tableCellSize: TypographySize.MEDIUM
  } : {
    tableCellFont: TypographyFont.PARAGRAPH,
    tableCellSize: TypographySize.SMALL
  };
  return <TableContainer>
      {title && <Typography casing={TypographyCasing.CAPITALIZE} color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          {title}
        </Typography>}
      {(tabViewProps || showFilters) && <TabsAndFiltersContainer>
          {tabViewProps && <TabNavigator activeTab={tabViewProps.activeTab} onClick={tabViewProps.setActiveTab} orientation={TabNavigatorType.HORIZONTAL} size={Size.SMALL} tabOptions={tabViewProps.tabOptions} />}
          {showFilters && <FilterContainer>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {filterProps && <Filters {...filterProps} />}
              {dateRangePickerProps &&
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DateTimeRangePicker {...dateRangePickerProps} />}
              {clearFilterProps?.hasFilters && <ClearFilterButtonContainer>
                  <Button label={clearFilterProps.label} onClick={clearFilterProps.onClick} type={ButtonType.OUTLINED} />
                </ClearFilterButtonContainer>}
            </FilterContainer>}
        </TabsAndFiltersContainer>}
      <TableRoot>
        {showHeader && <TableHead>
            <TableHeaderRow $showBorder={showColumnHeaderBorder}>
              {columnsWithSelect.map(col => <TableHeader key={col.title} $width={col.width}>
                  {renderTableHeader(col, sortProps, isCsv)}
                </TableHeader>)}
            </TableHeaderRow>
          </TableHead>}
        <tbody>
          {rows.map(row => {
          const rowIsElement = isValidElement(row);
          if (rowIsElement) return row;
          const {
            key,
            disabled,
            disabledTextColor,
            toolTip,
            height = DEFAULT_ROW_HEIGHT,
            onClick: customOnClick,
            href,
            textColor = TextIconColor.PRIMARY
          } = row as TableRow;
          const isSelected = selectedItems?.includes(key as TKey) ?? false;
          const isDisabled = disabled ?? false;
          const toggleRowSelectionIfEnabled = () => {
            if (disabled) return;
            handleToggleSelection(key as TKey);
          };
          const handleRowClick = () => {
            if (isDisabled) return;
            if (customOnClick) {
              customOnClick();
            }
            toggleRowSelectionIfEnabled();
          };
          const rowIsClickable = !!selectionProps || !!customOnClick || !!href;
          const rowTextColor = disabled ? disabledTextColor || TextIconColor.DISABLED : textColor;
          return <ToolTip key={row.key} content={toolTip}>
                <StyledTableRow $clickable={rowIsClickable} $disabled={isDisabled} $height={height} $hideBorder={isCsv} className="group" onClick={handleRowClick}>
                  {selectionProps && <TableCheckboxTd onClick={toggleRowSelectionIfEnabled}>
                      <TableCheckboxOuterContainer $isSelected={isSelected}>
                        {isSelected && <TableCheckboxInnerContainer>
                            <Icons color={TextIconColor.BLACK} icon={Icon.CHECK} size={Size.MEDIUM} />
                          </TableCheckboxInnerContainer>}
                      </TableCheckboxOuterContainer>
                    </TableCheckboxTd>}
                  {(row as TableRow).cells.map((cell, index) => {
                const column = columns[index];
                const {
                  width,
                  wrap,
                  tooltipEnabled
                } = column ?? {};
                const typographyMaxWidth = typeof width === 'number' ? width : undefined;
                return <TableCell
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${row.key}-cell-${index}`} $height={height} $width={width}>
                        {withLink(<>
                            {isValidElement(cell) && cell}
                            {!isValidElement(cell) && <ToolTip content={cell?.toString()} disabled={!tooltipEnabled} placement={TooltipPlacement.TOP_START}>
                                <TableCellTypographyWrapper>
                                  <Typography color={rowTextColor} font={tableCellFont} maxWidth={typographyMaxWidth ? `max-w-[${typographyMaxWidth}px]` : undefined} size={tableCellSize} wrap={wrap}>
                                    {cell}
                                  </Typography>
                                </TableCellTypographyWrapper>
                              </ToolTip>}
                          </>, {
                    href,
                    fullSize: true
                  })}
                      </TableCell>;
              })}
                </StyledTableRow>
              </ToolTip>;
        })}
          {!rows.length && !loading && <tr>
              <TableNoRowsTd colSpan={7}>
                <Typography color={TextIconColor.SECONDARY} italic>
                  {noRowsText}
                </Typography>
              </TableNoRowsTd>
            </tr>}
        </tbody>
      </TableRoot>
      {loading && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
    </TableContainer>;
}
export default Table;