import useParseWorkflowUrlSlug from './useParseWorkflowUrlSlug';

import { nextTrpc } from '~/trpc';

/**
 * Fetch workflow for the active workflowId in the URL slug
 */
const useGetWorkflowFromUrlSlug = () => {
  const { workflowId } = useParseWorkflowUrlSlug();

  const res = nextTrpc.workflows.get.useQuery(
    {
      workflowId: workflowId || ''
    },
    {
      enabled: !!workflowId
    }
  );

  return res;
};

export default useGetWorkflowFromUrlSlug;
