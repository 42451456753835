import _styled from "styled-components";
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size, TextField, TextFieldType, TextIconColor } from '../../../../core';
export const KeyValueContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "gap": "1rem"
});
export const KeyValuePairContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem"
});
export interface KeyValuePairProps<T> {
  disabled: boolean;
  index: number;
  key: string;
  onKeyChange: (newKey: string) => void;
  onRemove: () => void;
  onValueChange: (newValue: T) => void;
  renderValueInput: (props: {
    index: number;
    onChange: (value: T) => void;
    value: T;
  }) => React.ReactNode;
  value: T;
}
export const renderKeyValuePair = <T,>({
  disabled,
  key,
  value,
  index,
  onKeyChange,
  onValueChange,
  onRemove,
  renderValueInput
}: KeyValuePairProps<T>) => <KeyValueContainer key={index}>
    <TextField disabled={disabled} fullWidth onChange={onKeyChange} placeholder="Key" type={key ? TextFieldType.DEFAULT : TextFieldType.HIGHLIGHT} value={key} />
    {renderValueInput({
    value,
    onChange: onValueChange,
    index
  })}
    <IconButton color={TextIconColor.PRIMARY} disabled={disabled} icon={Icon.TRASH} onClick={onRemove} size={Size.SMALL} type={IconButtonType.OUTLINED} />
  </KeyValueContainer>;
export interface KeyValueSectionProps<T> {
  disabled: boolean;
  entries: [string, T][];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onUpdate: (index: number, key: string, value: T) => void;
  renderValueInput: (props: {
    index: number;
    onChange: (value: T) => void;
    value: T;
  }) => React.ReactNode;
}
const WorkflowApiActionStepEditorKeyValueSection = <T,>({
  disabled,
  entries,
  onUpdate,
  onRemove,
  onAdd,
  renderValueInput
}: KeyValueSectionProps<T>) => <KeyValuePairContainer>
    {entries.map(([key, value], index) => renderKeyValuePair({
    key,
    value,
    index,
    onKeyChange: newKey => onUpdate(index, newKey, value),
    onValueChange: newValue => onUpdate(index, key, newValue),
    onRemove: () => onRemove(index),
    renderValueInput,
    disabled
  }))}
    <Button disabled={disabled} endIcon={Icon.PLUS} fullWidth label="Add Another" onClick={onAdd} type={ButtonType.SOLID} />
  </KeyValuePairContainer>;
export default WorkflowApiActionStepEditorKeyValueSection;