import { getObjectKeysTyped } from '@kindo/universal';
import { useRouter } from 'next/router';

interface UseTabUrlNavigationArgs<
  Tab extends string,
  TabSubTabs extends Record<Tab, string>
> {
  baseRoute: string;
  defaultActiveTab: Tab;
  tabsWithRoutes: Record<Tab, string>;
  subTabs?: {
    defaultSubTabs: { [K in Tab]?: TabSubTabs[K] };
    subTabsWithRoutes: { [K in Tab]: Record<TabSubTabs[K], string> };
  };
}

interface UseTabUrlNavigationReturn<
  Tab extends string,
  TabSubTabs extends Record<Tab, string>,
  ActiveTab extends Tab = Tab
> {
  activeSubTab: TabSubTabs[ActiveTab] | undefined;
  activeTab: ActiveTab;
  getSubTabRoute: (subtab: TabSubTabs[Tab]) => string;
  getTabRoute: (tab: Tab) => string;
}

const useTabUrlNavigation = <
  Tab extends string,
  TabSubTabs extends Record<Tab, string> = Record<Tab, never>
>({
  defaultActiveTab,
  tabsWithRoutes,
  baseRoute,
  subTabs
}: UseTabUrlNavigationArgs<Tab, TabSubTabs>): UseTabUrlNavigationReturn<
  Tab,
  TabSubTabs
> => {
  const router = useRouter();

  const { defaultSubTabs, subTabsWithRoutes } = subTabs || {};

  // Get the current path segments after the baseRoute
  const currentPath = router.asPath || '';
  const [pathWithoutQuery = ''] = currentPath.split('?');
  const baseRouteParts = baseRoute.split('/').filter(Boolean);
  const pathParts = pathWithoutQuery.split('/').filter(Boolean);
  const routeParts = pathParts.slice(baseRouteParts.length);

  // Use the route parts to determine the active tab and subtab
  const [activeTabRoute = tabsWithRoutes[defaultActiveTab], activeSubTabRoute] =
    routeParts;

  const activeTab: Tab =
    getObjectKeysTyped(tabsWithRoutes).find(
      (tab) => tabsWithRoutes[tab as Tab] === activeTabRoute
    ) || defaultActiveTab;

  const activeSubTab: TabSubTabs[Tab] | undefined = (getObjectKeysTyped(
    subTabsWithRoutes?.[activeTab] ?? []
  ).find(
    (subTab) =>
      subTabsWithRoutes?.[activeTab][subTab as TabSubTabs[Tab]] ===
      activeSubTabRoute
  ) || defaultSubTabs?.[activeTab]) as TabSubTabs[Tab] | undefined;

  const getTabRoute = (tab: Tab) => {
    const href = `${baseRoute}/${tabsWithRoutes[tab]}`;
    return href;
  };

  const getSubTabRoute = (subtab: TabSubTabs[Tab]) => {
    if (!subTabsWithRoutes) {
      console.error(
        'Failed to get subtab props, subtabsWithRoutes is undefined'
      );
      return '#';
    }

    const href = `${baseRoute}/${tabsWithRoutes[activeTab]}/${subTabsWithRoutes[activeTab][subtab]}`;
    return href;
  };

  return {
    activeTab,
    activeSubTab,
    getTabRoute,
    getSubTabRoute
  };
};

export default useTabUrlNavigation;
