import { tv } from 'tailwind-variants';
import { createEnumVariants } from '../../../utils/commonTailwindVariants.utils';
import { Size } from '../constants/size.consts';
import { ButtonType } from './Button.consts';

// Create a specific ButtonSize type that limits to only the sizes supported by the Button component
export type ButtonSize = Extract<Size, Size.X_SMALL | Size.SMALL | Size.MEDIUM | Size.LARGE>;
export const buttonStyles = tv({
  base: 'flex items-center justify-center whitespace-nowrap rounded py-[10px]',
  variants: {
    buttonType: createEnumVariants<typeof ButtonType>({
      [ButtonType.OUTLINED]: 'border border-white/20 bg-transparent enabled:hover:border-white/50',
      [ButtonType.OUTLINED_COLOR]: 'border-primary-bright enabled:hover:border-primary-bright-hover border bg-transparent enabled:hover:shadow-[0px_-1px_46.1px_0px_#3C00FF]',
      [ButtonType.SOLID]: 'bg-white/10 enabled:hover:bg-white/20',
      [ButtonType.SOLID_COLOR]: 'bg-primary-bright enabled:hover:bg-primary-bright-hover enabled:hover:shadow-[0px_4px_45px_0px_#3C00FF] disabled:bg-white/10',
      [ButtonType.SOLID_GRADIENT]: 'from-solid-gradient-start to-solid-gradient-end bg-gradient-to-r enabled:hover:opacity-90 enabled:hover:shadow-[0px_4px_45px_0px_#5900DE] disabled:bg-white/10',
      [ButtonType.TEXT]: 'border-none bg-transparent enabled:hover:opacity-50 disabled:border-none',
      [ButtonType.NONE]: 'border-none bg-transparent',
      [ButtonType.INPUT_BUTTON]: 'bg-primary-border-alt rounded-l-none border-none'
    }),
    size: createEnumVariants<typeof Size, ButtonSize>({
      [Size.X_SMALL]: 'h-6 gap-1 px-[10px]',
      [Size.SMALL]: 'h-7 gap-1.5 px-[10px]',
      [Size.MEDIUM]: 'h-8 gap-2 px-[15px]',
      [Size.LARGE]: 'h-10 gap-3 px-[20px]'
    }),
    fullWidth: {
      true: 'w-full',
      false: 'w-fit'
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50'
    },
    hasHoverBorder: {
      true: 'hover:border-blue-navy'
    },
    hasStartIcon: {
      true: ''
    },
    hasEndIcon: {
      true: ''
    }
  },
  compoundVariants: [
  // Compound variants for disabled states
  {
    buttonType: [ButtonType.OUTLINED, ButtonType.OUTLINED_COLOR],
    disabled: true,
    className: 'border-dashed border-[rgba(255,255,255,0.20)]'
  }, {
    buttonType: [ButtonType.SOLID, ButtonType.SOLID_COLOR, ButtonType.SOLID_GRADIENT],
    disabled: true,
    className: 'border-dashed border-[rgba(255,255,255,0.20)] bg-white/10'
  },
  // X-Small size padding adjustments
  {
    size: Size.X_SMALL,
    hasStartIcon: true,
    hasEndIcon: false,
    className: 'pl-[8px] pr-[10px]'
  }, {
    size: Size.X_SMALL,
    hasStartIcon: false,
    hasEndIcon: true,
    className: 'pl-[10px] pr-[8px]'
  }, {
    size: Size.X_SMALL,
    hasStartIcon: true,
    hasEndIcon: true,
    className: 'px-[8px]'
  },
  // Small size padding adjustments
  {
    size: Size.SMALL,
    hasStartIcon: true,
    hasEndIcon: false,
    className: 'pl-[8px] pr-[10px]'
  }, {
    size: Size.SMALL,
    hasStartIcon: false,
    hasEndIcon: true,
    className: 'pl-[10px] pr-[8px]'
  }, {
    size: Size.SMALL,
    hasStartIcon: true,
    hasEndIcon: true,
    className: 'px-[8px]'
  },
  // Medium size padding adjustments
  {
    size: Size.MEDIUM,
    hasStartIcon: true,
    hasEndIcon: false,
    className: 'pl-[13px] pr-[15px]'
  }, {
    size: Size.MEDIUM,
    hasStartIcon: false,
    hasEndIcon: true,
    className: 'pl-[15px] pr-[13px]'
  }, {
    size: Size.MEDIUM,
    hasStartIcon: true,
    hasEndIcon: true,
    className: 'px-[13px]'
  },
  // Large size padding adjustments
  {
    size: Size.LARGE,
    hasStartIcon: true,
    hasEndIcon: false,
    className: 'pl-[10px] pr-[20px]'
  }, {
    size: Size.LARGE,
    hasStartIcon: false,
    hasEndIcon: true,
    className: 'pl-[20px] pr-[10px]'
  }, {
    size: Size.LARGE,
    hasStartIcon: true,
    hasEndIcon: true,
    className: 'px-[10px]'
  }]
});