import { tv } from 'tailwind-variants';
import { Size } from '../constants';
import { TEXT_COLOR_CLASSES } from '../constants/colors.consts';
export const iconSizeVariants = {
  [Size.X_SMALL]: '[&>svg]:h-2 [&>svg]:w-2',
  [Size.SMALL]: '[&>svg]:h-3 [&>svg]:w-3',
  [Size.MEDIUM]: '[&>svg]:h-4 [&>svg]:w-4',
  [Size.LARGE]: '[&>svg]:h-5 [&>svg]:w-5',
  [Size.X_LARGE]: '[&>svg]:h-6 [&>svg]:w-6'
};
export const iconColorVariants = TEXT_COLOR_CLASSES;
export const iconStyles = tv({
  base: 'transition-transform duration-300 inline-flex items-center justify-center',
  variants: {
    size: iconSizeVariants,
    invert: {
      true: '-rotate-180'
    },
    color: iconColorVariants
  }
});