import { Icon, IconButton, IconButtonType, Size, TextIconColor } from '~/components/core';
import { withLink } from '~/components/core/hocs';
interface NavButtonProps {
  icon: Icon;
  route: string;
  'data-id'?: string;
  hideActive?: boolean;
  isCurrentRoute?: boolean;
}
const NavButton: React.FC<NavButtonProps> = ({
  isCurrentRoute,
  icon,
  route,
  'data-id': dataId
}) => withLink(<div css={{
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "&:hover svg": {
    "--tw-text-opacity": "1",
    "color": "rgb(141 118 255 / var(--tw-text-opacity))"
  }
}} data-testid="nav-button-container">
      <IconButton color={isCurrentRoute ? TextIconColor.HIGHLIGHT : TextIconColor.PRIMARY} data-id={dataId ?? ''} href={route} icon={icon} size={Size.LARGE} type={IconButtonType.NONE} />
    </div>, {
  href: route
});
export default NavButton;