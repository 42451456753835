import { z } from 'zod';

export enum HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export const SUPPORTED_HTTP_METHODS = [
  HttpMethod.DELETE,
  HttpMethod.GET,
  HttpMethod.PATCH,
  HttpMethod.POST,
  HttpMethod.PUT
] as const;

export type SupportedHttpMethod = (typeof SUPPORTED_HTTP_METHODS)[number];

export const REQUEST_BODY_SUPPORTED_HTTP_METHODS = [
  HttpMethod.POST,
  HttpMethod.PUT,
  HttpMethod.PATCH
] as const;

export type RequestBodySupportedHttpMethod =
  (typeof REQUEST_BODY_SUPPORTED_HTTP_METHODS)[number];

export const BLOCKED_DOMAINS = ['api.kindo.ai', 'app.kindo.ai'];

export enum ApiAuthorizationType {
  BASIC = 'BASIC',
  BEARER = 'BEARER'
}

export const API_AUTHORIZATION_DISPLAY_NAME_MAP = {
  [ApiAuthorizationType.BASIC]: 'Basic',
  [ApiAuthorizationType.BEARER]: 'Bearer'
};

/** Zod Schema Definitions */

export const zApiActionHeaderValue = z.union([
  z.object({ text: z.string() }),
  z.object({ secretId: z.string().uuid() })
]);
export const zApiActionHeaders = z.record(z.string(), zApiActionHeaderValue);
export const zApiActionParameters = z.record(z.string(), z.coerce.string());
export const zApiActionBody = z.record(z.string(), z.any());

export type ApiActionHeaders = z.infer<typeof zApiActionHeaders>;
export type ApiActionParameters = z.infer<typeof zApiActionParameters>;
export type ApiActionBody = z.infer<typeof zApiActionBody>;
export type ApiActionHeaderValue = z.infer<typeof zApiActionHeaderValue>;

export function isSupportedHttpMethod(
  method: string
): method is SupportedHttpMethod {
  return SUPPORTED_HTTP_METHODS.includes(method as SupportedHttpMethod);
}

export function isRequestBodySupportedHttpMethod(
  method: string
): method is RequestBodySupportedHttpMethod {
  return REQUEST_BODY_SUPPORTED_HTTP_METHODS.includes(
    method as RequestBodySupportedHttpMethod
  );
}

export function isSecretHeaderValue(
  header: ApiActionHeaderValue | undefined
): header is { secretId: string } {
  return !!header && 'secretId' in header;
}

export function isTextHeaderValue(
  header: ApiActionHeaderValue | undefined
): header is { text: string } {
  return !!header && 'text' in header;
}

export function isValidApiAuthorizationType(
  type: string
): type is ApiAuthorizationType {
  return Object.values(ApiAuthorizationType).includes(
    type as ApiAuthorizationType
  );
}
