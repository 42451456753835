import { Llm } from '@kindo/universal';
import { useState } from 'react';
import { GiantButton, GiantButtonType, Icon, TextField, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '../../core';
import { AddFileModal, FileResource } from '~/components/Chat/AddFileModal';
import { ContentReference } from '~/components/shared';
const inputsContainerStyles = 'flex flex-col gap-4';
type DescriptionProps = {
  description: string;
  onDescriptionChange: (value: string) => void;
} | {
  description?: never;
  onDescriptionChange?: never;
};
type WorkflowBuilderConfigurationEditorProps = DescriptionProps & {
  knowledgeStoreContent: FileResource[];
  onKnowledgeStoreContentChange: (files: FileResource[]) => void;
  onSystemPromptChange: (value: string) => void;
  systemPrompt: string;
  isAutoGenerating?: boolean;
  onAutoGenerate?: (prompt: string) => void;
};
const WorkflowBuilderConfigurationEditor: React.FC<WorkflowBuilderConfigurationEditorProps> = ({
  description,
  onDescriptionChange,
  systemPrompt,
  onSystemPromptChange,
  knowledgeStoreContent,
  onKnowledgeStoreContentChange,
  onAutoGenerate,
  isAutoGenerating
}) => {
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [autoGenerationPrompt, setAutoGenerationPrompt] = useState('');
  const handleContentSelect = (newSelectedFiles: FileResource[]) => {
    onKnowledgeStoreContentChange(newSelectedFiles);
  };
  const handleRemoveContent = (fileId: string) => {
    onKnowledgeStoreContentChange(knowledgeStoreContent.filter(file => file.id !== fileId));
  };
  const handleCloseFileModal = () => {
    setIsAddFileModalOpen(false);
  };
  const renderSectionHeader = (label: string, subtitle?: string) => <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.25rem"
  }}>
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
        {label}
      </Typography>
      {subtitle && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
          {subtitle}
        </Typography>}
    </div>;
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "2rem"
  }} data-testid="workflow-builder-sections-container">
      {description !== undefined && onDescriptionChange !== undefined && <div className={inputsContainerStyles} data-testid="workflow-description-inputs-container">
          {renderSectionHeader('DESCRIPTION', "Describe your agent to let other users know what it can do (does not affect the agent's behavior).")}
          <TextField fullWidth maxRows={3} minRows={2} multiline onChange={value => onDescriptionChange(value)} placeholder="This agent helps users understand their assigned issues in Jira by providing detailed explanations, summaries, and guidance on prioritization and resolution." value={description} />
        </div>}
      <div className={inputsContainerStyles} data-testid="workflow-instructions-inputs-container">
        {renderSectionHeader('INSTRUCTIONS', 'Guidance for every agent response. These instructions are included in each LLM step and follow-up chat message as a system prompt.')}
        <TextField fullWidth maxRows={10} minRows={8} multiline onChange={value => onSystemPromptChange(value)} placeholder="You are an expert cybersecurity analyst helping users understand and implement security best practices. When responding:
1. Provide detailed technical explanations of security concepts with real-world examples
2. Break down complex security recommendations into clear, actionable steps" value={systemPrompt} />
      </div>
      <div className={inputsContainerStyles} data-testid="workflow-knowledge-store-inputs-container">
        {renderSectionHeader('KNOWLEDGE STORE', 'Add files that your agent can search through to answer questions.')}
        {knowledgeStoreContent.length > 0 && <div css={{
        "display": "grid",
        "gridTemplateColumns": "repeat(5, minmax(0, 1fr))",
        "gap": "1rem"
      }} data-testid="workflow-knowledge-grid">
            {knowledgeStoreContent.map(file => <ContentReference contentId={file.id} fileName={file.name} key={file.id} onClick={() => handleRemoveContent(file.id)} showRemoveIcon source={file.source} />)}
          </div>}
        <GiantButton endIcon={Icon.PLUS} label="Add Knowledge Store" onClick={() => setIsAddFileModalOpen(true)} type={GiantButtonType.OUTLINED} />
        {isAddFileModalOpen && <AddFileModal activeModel={Llm.GPT_4O} initialSelectedFiles={knowledgeStoreContent} isKnowledgeStore onClose={handleCloseFileModal} onSelect={handleContentSelect} selectedFiles={knowledgeStoreContent} />}
      </div>
      {onAutoGenerate && <div className={inputsContainerStyles} data-testid="workflow-auto-generate-inputs-container">
          {renderSectionHeader('Do It For Me', 'Describe what you want your agent to do and we will generate new steps, instructions, name, and description. It cannot currently generate triggers or action steps. This will override existing steps and configuration.')}
          <TextField fullWidth maxRows={10} minRows={8} multiline onChange={setAutoGenerationPrompt} placeholder="Analyze and triage security vulnerabilities. It should:
1. Take vulnerability scan results as input
2. Analyze the severity and potential impact
3. Generate a detailed assessment with remediation recommendations" value={autoGenerationPrompt} />
          <GiantButton disabled={!onAutoGenerate || !autoGenerationPrompt} label="Generate" loading={!!isAutoGenerating} onClick={() => onAutoGenerate?.(autoGenerationPrompt)} type={GiantButtonType.SOLID} />
        </div>}
    </div>;
};
export default WorkflowBuilderConfigurationEditor;