import _styled from "styled-components";
import Tippy from '@tippyjs/react';
export enum ToolTipColor {
  BLACK = 'BLACK',
}

// https://atomiks.github.io/tippyjs/v6/all-props/#placement
export enum TooltipPlacement {
  AUTO = 'auto',
  AUTO_END = 'auto-end',
  AUTO_START = 'auto-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
}
const TOOL_TIP_COLOR_STYLES: Record<ToolTipColor, TwStyle> = {
  [ToolTipColor.BLACK]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  }
};
const StyledTippy = _styled(Tippy)<{
  $color: ToolTipColor;
}>(({
  $color
}) => [{
  "maxWidth": "250px",
  "whiteSpace": "pre-line",
  "borderRadius": "0.5rem",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "fontFamily": "var(--font-ibmPlexSans)",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem"
}, TOOL_TIP_COLOR_STYLES[$color]]);
const ClickableContent = _styled.div({
  "cursor": "pointer",
  "&:hover": {
    "opacity": "0.85"
  }
});
interface ToolTipProps {
  children: React.ReactElement;
  content: string | undefined;
  color?: ToolTipColor;
  disabled?: boolean;
  interactive?: boolean;
  offset?: [number, number];
  onClick?: (() => void) | undefined;
  placement?: TooltipPlacement;
}
const ToolTip: React.FC<ToolTipProps> = ({
  content,
  children,
  disabled = false,
  interactive = false,
  placement = TooltipPlacement.TOP,
  offset = [0, 1],
  onClick,
  color = ToolTipColor.BLACK
}) => <StyledTippy $color={color} content={onClick ? <ClickableContent onClick={onClick}>{content}</ClickableContent> : content} disabled={disabled || !content} duration={[null, 0]} interactive={interactive} offset={offset} placement={placement}>
    {children}
  </StyledTippy>;
export default ToolTip;