import {
  getPromptTemplateTag,
  PromptTemplateTagType,
  WithRequired
} from '@kindo/universal';

import { useAppDispatch } from '../typedReduxHooks';
import useToast, { ToastType } from '../useToast';

import { ValidationResult } from './useActiveApiActionStep';

import {
  NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE,
  NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE,
  workflowBuilderActions
} from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import {
  BuilderWorkflowLlmStep,
  BuilderWorkflowStatus,
  BuilderWorkflowStepStaticContent,
  isIntegrationWorkflowBuilderStepInput,
  isPromptTemplateWorkflowBuilderStepInput
} from '~/types';

export interface UseActiveLlmStepReturn {
  canSave: (step: BuilderWorkflowLlmStep) => ValidationResult;
  isSaving: boolean;
  saveLlmStep: (step: BuilderWorkflowLlmStep) => Promise<void>;
  updateModel: (value: string) => void; // DO NOT REMOVE COMMENT: SupportedLlm
  updatePrompt: (value: string) => void;
}

const useActiveLlmStep = (workflowId: string): UseActiveLlmStepReturn => {
  const dispatch = useAppDispatch();
  const { enqueueToast } = useToast();

  const addLlmStepMutation = nextTrpc.workflows.addLlmStep.useMutation();
  const editLlmStepMutation = nextTrpc.workflows.editLlmStep.useMutation();

  const filterContentIds = (
    activeStepStaticContent: BuilderWorkflowStepStaticContent[],
    activeStepPrompt: string
  ) =>
    activeStepStaticContent
      .filter((content) =>
        activeStepPrompt.includes(
          getPromptTemplateTag(content.id, PromptTemplateTagType.STATIC_CONTENT)
        )
      )
      .map((content) => content.id);

  const saveLlmStep = async (step: BuilderWorkflowLlmStep) => {
    const { promptTemplate, model, staticContent, status, stepNumber, inputs } =
      step;

    const filteredUserInputs = inputs
      .filter(isPromptTemplateWorkflowBuilderStepInput)
      .filter((input) =>
        promptTemplate.includes(
          getPromptTemplateTag(
            input.templateResolutionName,
            PromptTemplateTagType.USER_INPUT
          )
        )
      )
      .map((input) => ({
        templateResolutionName: input.templateResolutionName,
        displayName: input.displayName
      }));

    const integrationInputs = inputs
      .filter(isIntegrationWorkflowBuilderStepInput)
      .filter(
        (input): input is WithRequired<typeof input, 'config'> =>
          input.config !== null
      )
      .map((input) => ({
        inputId: input.id,
        config: input.config
      }));

    const filteredStaticContentIds = filterContentIds(
      staticContent,
      promptTemplate
    );

    try {
      if (status === BuilderWorkflowStatus.NEW) {
        await addLlmStepMutation.mutateAsync({
          prompt: promptTemplate,
          model,
          stepNumber,
          inputs: [...filteredUserInputs, ...integrationInputs],
          staticContentIds: filteredStaticContentIds,
          workflowId
        });
      } else if (status === BuilderWorkflowStatus.MODIFIED) {
        await editLlmStepMutation.mutateAsync({
          prompt: promptTemplate,
          model,
          stepNumber,
          inputs: [...filteredUserInputs, ...integrationInputs],
          staticContentIds: filteredStaticContentIds,
          id: step.id
        });
      }
    } catch (error) {
      console.error('Failed to save LLM step:', error);
      enqueueToast({
        message: 'Failed to save LLM step. Please try again.',
        type: ToastType.ERROR
      });
      throw error;
    }
  };

  const updatePrompt = (value: string) => {
    dispatch(workflowBuilderActions.updateActiveStepPrompt(value));
  };

  // DO NOT REMOVE COMMENT: SupportedLlm
  const updateModel = (value: string) => {
    dispatch(workflowBuilderActions.updateActiveStepModel(value));
  };

  const canSave = (step: BuilderWorkflowLlmStep): ValidationResult => {
    if (!step.promptTemplate) {
      return { isValid: false, message: 'Prompt cannot be empty' };
    }

    const hasEmptyInputNode =
      step.promptTemplate?.includes(NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE) ||
      step.promptTemplate?.includes(NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE);

    if (hasEmptyInputNode) {
      return {
        isValid: false,
        message: 'Please configure all input and file nodes in the prompt'
      };
    }

    return { isValid: true };
  };

  return {
    saveLlmStep,
    updatePrompt,
    updateModel,
    canSave,
    isSaving: addLlmStepMutation.isLoading || editLlmStepMutation.isLoading
  };
};

export default useActiveLlmStep;
