import { isExternalLlm } from '@kindo/universal';
import SettingsSection from '../../SettingsSection';
import { ContinueConfigType, generateContinueConfig } from './OrgConfig.utils';
import { Markdown } from '~/components/Markdown';
import { AVAILABLE_MODELS_AND_TYPES, ModelType } from '~/components/SettingsModal/ApiSettings/AvailableModels/AvailableModels';
import useGetEnabledModels from '~/hooks/useGetEnabledModels';
const OrgConfig = () => {
  // Queries
  const {
    enabledModels: orgEnabledModels
  } = useGetEnabledModels();

  // Get all models in correct format for Continue config
  const availableModels: ContinueConfigType[] = orgEnabledModels ? orgEnabledModels.map(model => ({
    title: model.displayName,
    provider: 'kindo',
    model: model.identifier,
    apiKey: '<KINDO_API_KEY>',
    // TODO: [ENG-5136] Move model type info to api.
    // SMM will not display in Continue plugin page.
    tabAutocompleteModel: isExternalLlm(model.identifier) && AVAILABLE_MODELS_AND_TYPES[model.identifier].includes(ModelType.TAB_AUTOCOMPLETE),
    chatModel: isExternalLlm(model.identifier) && AVAILABLE_MODELS_AND_TYPES[model.identifier].includes(ModelType.CHAT)
  })) : [];
  const continueConfig = generateContinueConfig(availableModels);
  const configDescriptionMarkdown = '> The following configuration includes the allowed models and providers for your organization. If you are the only user in your organization, you can update your available models and providers in the **Security tab**.';
  const configInstructions = 'Copy and paste the following configuration into your config file:';
  const configJsonBlock = `
  \`\`\`json
  ${continueConfig}
  \`\`\``;
  return <SettingsSection title="Your Configuration">
      <div css={{
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem",
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.75rem"
    }} data-testid="plugins-org-config-container">
        <Markdown data-testid="plugins-config-description" styleDocsComponents>
          {configDescriptionMarkdown}
        </Markdown>
        <Markdown data-testid="plugins-config-instructions" styleDocsComponents>
          {configInstructions}
        </Markdown>
        <Markdown data-testid="plugins-config-json-block" styleDocsComponents>
          {configJsonBlock}
        </Markdown>
      </div>
    </SettingsSection>;
};
export default OrgConfig;