import { Llm, SupportedLlm } from './llm.consts';

export enum Provider {
  ANTHROPIC = 'ANTHROPIC',
  AZURE = 'AZURE',
  COHERE = 'COHERE',
  GOOGLE = 'GOOGLE',
  GROQ = 'GROQ',
  HUGGING_FACE = 'HUGGING_FACE',
  IBM = 'IBM',
  KINDO = 'KINDO',
  SELF_MANAGED = 'SELF_MANAGED',
  TOGETHER_AI = 'TOGETHER_AI'
}

export function isProvider(value: unknown): value is Provider {
  return Object.values(Provider).includes(value as Provider);
}

export const PROVIDER_DISPLAY_NAMES: Record<Provider, string> = {
  [Provider.ANTHROPIC]: 'Anthropic',
  [Provider.AZURE]: 'Azure',
  [Provider.COHERE]: 'Cohere',
  [Provider.GOOGLE]: 'Google',
  [Provider.GROQ]: 'Groq',
  [Provider.HUGGING_FACE]: 'Hugging Face',
  [Provider.IBM]: 'IBM',
  [Provider.KINDO]: 'Kindo',
  [Provider.TOGETHER_AI]: 'Together AI',
  [Provider.SELF_MANAGED]: 'Self-Managed'
};

export enum ModelCreator {
  ALIBABA = 'ALIBABA',
  ANTHROPIC = 'ANTHROPIC',
  COHERE = 'COHERE',
  DEEPSEEK = 'DEEPSEEK',
  EQUALL = 'EQUALL',
  GOOGLE = 'GOOGLE',
  HUGGING_FACE = 'HUGGING_FACE',
  IBM = 'IBM',
  KINDO = 'KINDO',
  META = 'META',
  MISTRALAI = 'MISTRALAI',
  OPENAI = 'OPENAI',
  SELF_MANAGED = 'SELF_MANAGED'
}

export const MODEL_CREATOR_DISPLAY_NAMES: Record<ModelCreator, string> = {
  [ModelCreator.ALIBABA]: 'Alibaba',
  [ModelCreator.ANTHROPIC]: 'Anthropic',
  [ModelCreator.COHERE]: 'Cohere',
  [ModelCreator.GOOGLE]: 'Google',
  [ModelCreator.EQUALL]: 'Equall',
  [ModelCreator.META]: 'Meta',
  [ModelCreator.HUGGING_FACE]: 'Hugging Face',
  [ModelCreator.DEEPSEEK]: 'DeepSeek',
  [ModelCreator.IBM]: 'IBM',
  [ModelCreator.KINDO]: 'Kindo',
  [ModelCreator.MISTRALAI]: 'Mistral AI',
  [ModelCreator.OPENAI]: 'OpenAI',
  [ModelCreator.SELF_MANAGED]: 'Self-Managed'
};

export const LLM_TO_PROVIDER: Record<SupportedLlm, Provider> = {
  [Llm.CLAUDE_3_5_SONNET]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_7_SONNET]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_HAIKU]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_OPUS]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_5_HAIKU]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_SONNET]: Provider.ANTHROPIC,
  [Llm.COMMAND_R]: Provider.COHERE,
  [Llm.GEMINI_1_5_FLASH]: Provider.GOOGLE,
  [Llm.GEMINI_1_5_PRO]: Provider.GOOGLE,
  [Llm.GEMINI_2_0_FLASH]: Provider.GOOGLE,
  [Llm.GEMMA_2_9B_IT]: Provider.GROQ,
  [Llm.GPT_3_5_TURBO]: Provider.AZURE,
  [Llm.GPT_4O]: Provider.AZURE,
  [Llm.GPT_4O_MINI]: Provider.AZURE,
  [Llm.GPT_4_TURBO]: Provider.AZURE,
  [Llm.GRANITE_13B_CHAT_V2]: Provider.IBM,
  [Llm.GRANITE_3_8B_INSTRUCT]: Provider.IBM,
  /** Start internal LLMs */
  [Llm.INTERNAL_AUTO_GENERATION]: Provider.KINDO,
  [Llm.INTERNAL_INGESTION_EXTRACTION]: Provider.KINDO,
  [Llm.INTERNAL_LARGE_WORKER]: Provider.KINDO,
  [Llm.INTERNAL_SMALL_WORKER]: Provider.KINDO,
  /** End internal LLMs */
  [Llm.LLAMA_3_3_70B]: Provider.GROQ,
  [Llm.LLAMA_3_70B]: Provider.GROQ,
  [Llm.LLAMA_3_1_70B_INTERNAL]: Provider.KINDO,
  [Llm.LLAMA_3_1_8B]: Provider.KINDO,
  [Llm.LLAMA_3_1_8B_INSTANT]: Provider.GROQ,
  [Llm.O1]: Provider.AZURE,
  [Llm.O1_MINI]: Provider.AZURE,
  [Llm.O3_MINI]: Provider.AZURE,
  [Llm.QWEN2_72B_INSTRUCT]: Provider.TOGETHER_AI,
  [Llm.QWEN2_5_72B_INSTRUCT_TURBO]: Provider.TOGETHER_AI,
  [Llm.SAUL_INSTRUCT_V1]: Provider.HUGGING_FACE,
  [Llm.DEEPSEEK_R1]: Provider.TOGETHER_AI,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: Provider.KINDO,
  [Llm.WHITERABBITNEO_33B]: Provider.KINDO,
  [Llm.WHITERABBITNEO_R1_32B]: Provider.KINDO
};

export const LLM_TO_CREATOR: Record<SupportedLlm, ModelCreator> = {
  [Llm.CLAUDE_3_5_SONNET]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_7_SONNET]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_HAIKU]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_OPUS]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_5_HAIKU]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_SONNET]: ModelCreator.ANTHROPIC,
  [Llm.COMMAND_R]: ModelCreator.COHERE,
  [Llm.DEEPSEEK_R1]: ModelCreator.DEEPSEEK,
  [Llm.GEMINI_1_5_FLASH]: ModelCreator.GOOGLE,
  [Llm.GEMINI_1_5_PRO]: ModelCreator.GOOGLE,
  [Llm.GEMINI_2_0_FLASH]: ModelCreator.GOOGLE,
  [Llm.GEMMA_2_9B_IT]: ModelCreator.GOOGLE,
  [Llm.GPT_3_5_TURBO]: ModelCreator.OPENAI,
  [Llm.GPT_4O]: ModelCreator.OPENAI,
  [Llm.GPT_4O_MINI]: ModelCreator.OPENAI,
  [Llm.GPT_4_TURBO]: ModelCreator.OPENAI,
  [Llm.GRANITE_13B_CHAT_V2]: ModelCreator.IBM,
  [Llm.GRANITE_3_8B_INSTRUCT]: ModelCreator.IBM,
  /** Start internal LLMs */
  [Llm.INTERNAL_AUTO_GENERATION]: ModelCreator.KINDO,
  [Llm.INTERNAL_INGESTION_EXTRACTION]: ModelCreator.KINDO,
  [Llm.INTERNAL_LARGE_WORKER]: ModelCreator.KINDO,
  [Llm.INTERNAL_SMALL_WORKER]: ModelCreator.KINDO,
  /** End internal LLMs */
  [Llm.LLAMA_3_3_70B]: ModelCreator.META,
  [Llm.LLAMA_3_70B]: ModelCreator.META,
  [Llm.LLAMA_3_1_70B_INTERNAL]: ModelCreator.META,
  [Llm.LLAMA_3_1_8B]: ModelCreator.META,
  [Llm.LLAMA_3_1_8B_INSTANT]: ModelCreator.META,
  [Llm.O1]: ModelCreator.OPENAI,
  [Llm.O1_MINI]: ModelCreator.OPENAI,
  [Llm.O3_MINI]: ModelCreator.OPENAI,
  [Llm.QWEN2_72B_INSTRUCT]: ModelCreator.ALIBABA,
  [Llm.QWEN2_5_72B_INSTRUCT_TURBO]: ModelCreator.ALIBABA,
  [Llm.SAUL_INSTRUCT_V1]: ModelCreator.EQUALL,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: ModelCreator.KINDO,
  [Llm.WHITERABBITNEO_33B]: ModelCreator.KINDO,
  [Llm.WHITERABBITNEO_R1_32B]: ModelCreator.KINDO
};
