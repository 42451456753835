import { WorkflowListAccessScopeFilter, WorkflowListSort } from '@kindo/universal';
import { cn } from '../../utils/tailwind.utils';
import { baseWorkflowCardContainerStyles, darkGlowWorkflowCardContainerStyles, defaultWorkflowCardContainerStyles } from '../WorkflowNavScreen/WorkflowNav.styles';
import { AllWorkflowsTable, WorkflowsTable } from './WorkflowsTable';
import { nextTrpc } from '~/trpc';
const WorkflowLibrary = () => {
  // Queries
  // Recently Used Workflows
  const {
    data: recentWorkflows,
    isLoading,
    refetch: refetchRecentWorkflows
  } = nextTrpc.workflows.list.useQuery({
    workflowScopeFilter: WorkflowListAccessScopeFilter.CREATOR_SCOPE,
    sort: WorkflowListSort.RECENTLY_USED,
    limit: 3
  });
  const displayedRecentWorkflows = recentWorkflows?.items || [];
  return <div css={{
    "display": "flex",
    "flex": "1 1 0%",
    "flexDirection": "column",
    "alignItems": "center",
    "gap": "2rem"
  }} data-testid="workflow-library-container">
      <div className={cn(baseWorkflowCardContainerStyles, darkGlowWorkflowCardContainerStyles())} data-testid="workflow-library-dark-glow-container">
        {/* Recently Used Workflows */}
        <WorkflowsTable isLoading={isLoading} refetchWorkflows={refetchRecentWorkflows} tableTitle="Recently Used Agents" workflows={displayedRecentWorkflows} />
      </div>
      {/* All Workflows with search and filters */}
      <div className={cn(baseWorkflowCardContainerStyles, defaultWorkflowCardContainerStyles)} data-testid="workflow-library-default-container">
        <AllWorkflowsTable />
      </div>
    </div>;
};
export default WorkflowLibrary;