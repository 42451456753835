import {
  ApiActionHeaderValue,
  ApiActionParameters,
  ApiAuthorizationType,
  INTEGRATION_WORKFLOW_INPUT_TYPES,
  IntegrationWorkflowInputType,
  OmitStrict,
  PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES,
  PromptTemplateWorkflowInputType,
  TicketingIntegrationTicketTriggerConditionField,
  TicketingIntegrationTicketTriggerConditionValue,
  WorkflowActionStepConfig,
  WorkflowIntegrationActionStepType,
  WorkflowStepType,
  WorkflowSupportedIntegration,
  WorkflowTriggerConditionType
} from '@kindo/universal';

import {
  IntegrationWorkflowStepInput,
  isApiActionWorkflowStep,
  isIntegrationActionWorkflowStep,
  isLlmWorkflowStep,
  PromptTemplateWorkflowStepInput,
  WorkflowLlmStep,
  WorkflowStep,
  WorkflowStepInput,
  WorkflowTrigger
} from '~/types';

/**
 * Workflow Builder types are similar to those in ./workflow.types.ts,
 * but are slightly modified due to the builder supporting things like
 * unsaved inputs, unsaved steps etc, that do not have all the
 * same properties as their saved counterparts.
 */

export enum BuilderWorkflowStatus {
  MODIFIED = 'MODIFIED',
  NEW = 'NEW',
  SAVED = 'SAVED'
}

/** WORKFLOW TRIGGERS
 *
 * The following types are used to support new triggers and conditions that
 * have not yet been saved to the workflow.
 */

type ExistingWorkflowTrigger = OmitStrict<
  WorkflowTrigger,
  'conditions' | 'integrationName'
> & {
  conditions: WorkflowTriggerConditionBuilder[];
  integrationName: WorkflowSupportedIntegration;
  status: BuilderWorkflowStatus;
};

// New triggers have not yet been saved to the workflow
export type NewWorkflowTrigger = OmitStrict<
  WorkflowTrigger,
  'id' | 'conditions' | 'integrationName'
> & {
  conditions: WorkflowTriggerConditionBuilder[];
  integrationName: WorkflowSupportedIntegration | null;
  status: BuilderWorkflowStatus;
};

export type WorkflowTriggerBuilder =
  | NewWorkflowTrigger
  | ExistingWorkflowTrigger;

export function transformWorkflowTriggerToWorkflowTriggerBuilder(
  trigger: WorkflowTrigger,
  status: BuilderWorkflowStatus
): WorkflowTriggerBuilder {
  return {
    ...trigger,
    status,
    conditions: trigger.conditions.map((condition, index) => ({
      ...condition,
      index
    }))
  };
}

// Draft represents a potentially new condition that is being edited and may be incomplete,
// with optional field and type properties that can be undefined
export type WorkflowTriggerConditionBuilderDraft = {
  field: TicketingIntegrationTicketTriggerConditionField | undefined;
  index: number | null;
  type: WorkflowTriggerConditionType | undefined;
  value: TicketingIntegrationTicketTriggerConditionValue<TicketingIntegrationTicketTriggerConditionField>;
};

// This is a complete, valid condition where field and type
// are guaranteed to be defined (non-null). It extends the Draft type
// but makes those properties required.
export interface WorkflowTriggerConditionBuilder
  extends WorkflowTriggerConditionBuilderDraft {
  field: NonNullable<WorkflowTriggerConditionBuilderDraft['field']>;
  type: NonNullable<WorkflowTriggerConditionBuilderDraft['type']>;
}

export function isWorkflowTriggerConditionBuilder(
  condition: WorkflowTriggerConditionBuilderDraft
): condition is WorkflowTriggerConditionBuilder {
  return condition.field !== undefined && condition.type !== undefined;
}

/** WORKFLOW STEP INPUTS */

// New workflow inputs have not yet been saved to the workflow
export type NewWorkflowStepInput = OmitStrict<
  WorkflowStepInput,
  'id' | 'workflowId'
>;

export type NewPromptTemplateWorkflowStepInput = Extract<
  NewWorkflowStepInput,
  { type: PromptTemplateWorkflowInputType }
>;

// TODO: Remove this redundancy
// Keeping for now for now for clarity-sake during implementation
export type IntegrationWorkflowBuilderStepInput = IntegrationWorkflowStepInput;

export type WorkflowBuilderStepInput = WorkflowStepInput | NewWorkflowStepInput;

export type PromptTemplateWorkflowBuilderStepInput =
  | NewPromptTemplateWorkflowStepInput
  | PromptTemplateWorkflowStepInput;

/** WORKFLOW STEPS */

type BaseBuilderWorkflowStep = OmitStrict<
  WorkflowStep,
  'workflowId' | 'inputs'
> & {
  inputs: WorkflowBuilderStepInput[];
  status: BuilderWorkflowStatus;
};

export type BuilderWorkflowStepStaticContent = Pick<
  WorkflowLlmStep['staticContent'][number],
  'id' | 'fileName'
>;

export type BuilderWorkflowLlmStep = OmitStrict<
  Extract<
    BaseBuilderWorkflowStep,
    {
      type: WorkflowStepType.LLM;
    }
  >,
  'staticContent'
> & {
  integrationInputActiveInEditor: IntegrationWorkflowBuilderStepInput | null;
  staticContent: BuilderWorkflowStepStaticContent[];
};

export type BuilderWorkflowIntegrationActionStep = OmitStrict<
  Extract<
    BaseBuilderWorkflowStep,
    {
      type: WorkflowStepType.INTEGRATION_ACTION;
    }
  >,
  'action' | 'integration' | 'config'
> & {
  action: WorkflowIntegrationActionStepType | null;
  config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType> | null;
  integration: WorkflowSupportedIntegration | null;
};

export type BuilderWorkflowApiActionStep = OmitStrict<
  Extract<BaseBuilderWorkflowStep, { type: WorkflowStepType.API_ACTION }>,
  'body' | 'parameters'
> & {
  auth: {
    authorizationType: ApiAuthorizationType;
    value: ApiActionHeaderValue;
  } | null;
  body: string | null;
  endpoint: string | null;
  parameters: ApiActionParameters | null;
};

export type BuilderWorkflowStep =
  | BuilderWorkflowLlmStep
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  | BuilderWorkflowIntegrationActionStep
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  | BuilderWorkflowApiActionStep;

export function transformWorkflowStepToBuilderWorkflowStep(
  step: WorkflowStep,
  status: BuilderWorkflowStatus
): BuilderWorkflowStep {
  if (isLlmWorkflowStep(step)) {
    return {
      ...step,
      staticContent: step.staticContent.map((content) => ({
        fileName: content.fileName || 'Unknown File Name',
        id: content.id
      })),
      integrationInputActiveInEditor: null,
      status
    };
  }

  if (isIntegrationActionWorkflowStep(step)) {
    return {
      ...step,
      status
    };
  }

  if (isApiActionWorkflowStep(step)) {
    const {
      [ApiAuthorizationType.BASIC]: basicAuth,
      [ApiAuthorizationType.BEARER]: bearerAuth,
      ...headersWithoutAuth
    } = step.headers || {};

    const authValue = basicAuth || bearerAuth;

    return {
      ...step,
      auth: authValue
        ? {
            authorizationType: basicAuth
              ? ApiAuthorizationType.BASIC
              : ApiAuthorizationType.BEARER,
            value: authValue
          }
        : null,
      headers: headersWithoutAuth,
      body: step.body ? JSON.stringify(step.body) : null,
      status
    };
  }

  throw new Error('Invalid workflow step type');
}

/** Type Guards */
export function isLlmWorkflowBuilderStep(
  step: BuilderWorkflowStep | null
): step is BuilderWorkflowLlmStep {
  return step?.type === WorkflowStepType.LLM;
}

export function isIntegrationActionWorkflowBuilderStep(
  step: BuilderWorkflowStep | null
): step is BuilderWorkflowIntegrationActionStep {
  return step?.type === WorkflowStepType.INTEGRATION_ACTION;
}

export function isApiActionWorkflowBuilderStep(
  step: BuilderWorkflowStep | null
): step is BuilderWorkflowApiActionStep {
  return step?.type === WorkflowStepType.API_ACTION;
}

export function isPromptTemplateWorkflowBuilderStepInput(
  input: WorkflowBuilderStepInput | null
): input is PromptTemplateWorkflowBuilderStepInput {
  return PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES.includes(
    input?.type as PromptTemplateWorkflowInputType
  );
}

export function isIntegrationWorkflowBuilderStepInput(
  input: WorkflowBuilderStepInput | null
): input is IntegrationWorkflowBuilderStepInput {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    input?.type as IntegrationWorkflowInputType
  );
}

export function isExistingWorkflowTrigger(
  trigger: WorkflowTriggerBuilder | null
): trigger is ExistingWorkflowTrigger {
  return trigger?.status !== BuilderWorkflowStatus.NEW;
}
