import { isProviderSecurityConfig, isSupportedLlm, LLM_TO_CREATOR, LLM_TO_PROVIDER, MODEL_CREATOR_DISPLAY_NAMES, ModelCreator, ModelSecurityConfig, Provider, PROVIDER_DISPLAY_NAMES, ProviderSecurityConfig } from '@kindo/universal';
import React from 'react';
import { Button, ButtonType, Icon, Size, Table, TableRow, Toggle, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { useCanAccessSecuritySettings, useGetAllAvailableOrgModels } from '~/hooks';
import { ModelOrProvider } from '~/redux/reducers/modalSlice';
interface ModelProviderSecurityControlsTableProps {
  canAccessSecuritySettings: boolean;
  isModelAccessLoading: Record<string, boolean>; // DO NOT REMOVE COMMENT: was Record<Llm, boolean>
  isModelDlpLoading: Record<string, boolean>; // DO NOT REMOVE COMMENT: was Record<Llm, boolean>
  // Loading states
  isProviderAccessLoading: Record<Provider, boolean>;
  isProviderDlpLoading: Record<Provider, boolean>;
  modelSecurityConfigs: ModelSecurityConfig[];
  onConfirmCustomConfig: () => void;
  onCustomDlpConfigClick: (modelOrProvider: ModelOrProvider, hasCustomDlpFiltersConfig: boolean) => void;
  onModelAccessToggle: (model: string, isChecked: boolean) => void; // DO NOT REMOVE COMMENT: was (model: Llm, isChecked: boolean)
  onModelDlpToggle: (model: string, isChecked: boolean) => void; // DO NOT REMOVE COMMENT: was (model: Llm, isChecked: boolean)
  onProviderAccessToggle: (provider: Provider, isChecked: boolean) => void;
  onProviderDlpToggle: (provider: Provider, isChecked: boolean) => void;
  onSetPendingCustomConfigTarget: (configTarget: {
    modelOrProvider: ModelOrProvider;
  } | null) => void;
  pendingCustomConfigTarget: {
    modelOrProvider: ModelOrProvider;
  } | null;
  providerSecurityConfigs: ProviderSecurityConfig[];
}
type ModelSecurityConfigWithProvider = ModelSecurityConfig & {
  providerSecurityConfig: ProviderSecurityConfig;
};
const ModelProviderSecurityControlsTable: React.FC<ModelProviderSecurityControlsTableProps> = ({
  canAccessSecuritySettings,
  providerSecurityConfigs,
  modelSecurityConfigs,
  onConfirmCustomConfig,
  onCustomDlpConfigClick,
  onProviderAccessToggle,
  onModelAccessToggle,
  onProviderDlpToggle,
  onModelDlpToggle,
  pendingCustomConfigTarget,
  onSetPendingCustomConfigTarget,
  isProviderAccessLoading,
  isModelAccessLoading,
  isProviderDlpLoading,
  isModelDlpLoading
}) => {
  // Mapping of model identifier to the display name
  const {
    modelIdentifierToDisplayName
  } = useGetAllAvailableOrgModels();
  const {
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  // Build merged list of Provider and Model configs,
  // but all models associated with a Provider after the Provider
  // The Self Managed Provider is the first in the list
  const orderedProviderSecurityConfigs = [...providerSecurityConfigs.filter(providerConfig => providerConfig.provider === Provider.SELF_MANAGED), ...providerSecurityConfigs.filter(providerConfig => providerConfig.provider !== Provider.SELF_MANAGED)];
  const providerWithModelConfigs: Array<ProviderSecurityConfig | ModelSecurityConfigWithProvider> = orderedProviderSecurityConfigs.reduce<(ProviderSecurityConfig | ModelSecurityConfigWithProvider)[]>((acc, providerConfig) => {
    const modelConfigs = modelSecurityConfigs.filter(modelConfig =>
    // If the provider is self-managed, then we need to filter out models that are not supported LLMs since
    // we don't have a mapping of all the SMM models to the Self Managed Provider like with LLM_TO_PROVIDER
    providerConfig.provider === Provider.SELF_MANAGED ? !isSupportedLlm(modelConfig.model) : isSupportedLlm(modelConfig.model) && LLM_TO_PROVIDER[modelConfig.model] === providerConfig.provider).map(modelConfig => ({
      ...modelConfig,
      providerSecurityConfig: providerConfig
    }));

    // For self managed providers, we only show the provider if the org is enterprise and it has either a custom DLP config or model.
    // If the user already has a custom DLP config for SMM provider, we don't want to hide the SMM provider.
    const isSelfManagedProvider = providerConfig.provider === Provider.SELF_MANAGED;
    const providerHasModels = modelConfigs.length > 0;
    const providerHasCustomDlpConfig = providerConfig.hasCustomDlpFiltersConfig;
    const shouldShowProvider = isSelfManagedProvider ? orgCanAccessSecuritySettings && (providerHasModels || providerHasCustomDlpConfig) : true;
    return shouldShowProvider ? [...acc, providerConfig, ...modelConfigs] : acc;
  }, []);
  const getProviderRow = ({
    provider,
    accessEnabled,
    dlpEnabled,
    hasCustomDlpFiltersConfig
  }: ProviderSecurityConfig): TableRow => {
    const disableAccessToggle = !canAccessSecuritySettings;
    const disableDlpToggle = !accessEnabled || disableAccessToggle;
    const disableCustomDlpConfigButton = !dlpEnabled || disableDlpToggle;
    return {
      key: provider,
      cells: [<Typography data-testid={`provider-name-${provider}`} key={`${provider}-provider-name`} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD}>
          {PROVIDER_DISPLAY_NAMES[provider]}
        </Typography>, null,
      // Creator
      // Access enabled
      <Toggle checked={accessEnabled} data-testid={`provider-access-toggle-${provider}`} disabled={disableAccessToggle} key={`${provider}-provider-access-toggle`} loading={isProviderAccessLoading[provider]} onToggle={(isChecked: boolean) => onProviderAccessToggle(provider, isChecked)} size={Size.SMALL} />,
      // DLP Enabled
      <Toggle checked={dlpEnabled} data-testid={`provider-dlp-toggle-${provider}`} disabled={disableDlpToggle} key={`${provider}-provider-dlp-toggle`} loading={isProviderDlpLoading[provider]} onToggle={(isChecked: boolean) => onProviderDlpToggle(provider, isChecked)} size={Size.SMALL} />,
      // Add/Edit Custom DLP Config
      <Button data-testid={`provider-dlp-config-button-${provider}`} disabled={disableCustomDlpConfigButton} endIcon={hasCustomDlpFiltersConfig ? Icon.EDIT : Icon.PLUS} key={`${provider}-provider-custom-dlp-config-button`} label={hasCustomDlpFiltersConfig ? 'Edit Custom DLP Config' : 'Create Custom DLP Config'} onClick={() => onCustomDlpConfigClick({
        provider
      }, hasCustomDlpFiltersConfig)} size={Size.X_SMALL} type={ButtonType.OUTLINED} />]
    };
  };
  const getModelRow = ({
    model,
    accessEnabled,
    dlpEnabled,
    hasCustomDlpFiltersConfig,
    providerSecurityConfig
  }: ModelSecurityConfigWithProvider): TableRow => {
    const disableAccessToggle = !canAccessSecuritySettings || !providerSecurityConfig.accessEnabled;
    const disableDlpToggle = !accessEnabled || disableAccessToggle || !providerSecurityConfig.dlpEnabled;
    const disableCustomDlpConfigButton = !dlpEnabled || disableDlpToggle;
    return {
      key: model,
      cells: [<div css={{
        "paddingLeft": "1.5rem"
      }} data-testid={`model-name-wrapper-${model}`} key={`${model}-model-name`}>
          <Typography data-testid={`model-name-${model}`} size={TypographySize.SMALL}>
            {modelIdentifierToDisplayName[model]}
          </Typography>
        </div>, <Typography data-testid={`model-creator-${model}`} key={`${model}-model-creator`} size={TypographySize.SMALL}>
          {MODEL_CREATOR_DISPLAY_NAMES[isSupportedLlm(model) ? LLM_TO_CREATOR[model] : ModelCreator.SELF_MANAGED]}
        </Typography>,
      // Access enabled
      <Toggle checked={accessEnabled && !disableAccessToggle} data-testid={`model-access-toggle-${model}`} disabled={disableAccessToggle} key={`${model}-model-access-toggle`} loading={isModelAccessLoading[model] ?? false} onToggle={(isChecked: boolean) => onModelAccessToggle(model, isChecked)} size={Size.SMALL} />,
      // DLP Enabled
      <Toggle checked={dlpEnabled && !disableDlpToggle} data-testid={`model-dlp-toggle-${model}`} disabled={disableDlpToggle} key={`${model}-model-dlp-toggle`} loading={isModelDlpLoading[model] ?? false} onToggle={(isChecked: boolean) => onModelDlpToggle(model, isChecked)} size={Size.SMALL} />,
      // Add/Edit Custom DLP Config
      <Button data-testid={`model-dlp-config-button-${model}`} disabled={disableCustomDlpConfigButton} endIcon={hasCustomDlpFiltersConfig ? Icon.EDIT : Icon.PLUS} key={`${model}-model-custom-dlp-config-button`} label={hasCustomDlpFiltersConfig ? 'Edit Custom DLP Config' : 'Create Custom DLP Config'} onClick={() => onCustomDlpConfigClick({
        model
      }, hasCustomDlpFiltersConfig)} size={Size.X_SMALL} type={ButtonType.OUTLINED} />]
    };
  };
  const renderCreateConfigConfirmationModal = () => {
    if (!pendingCustomConfigTarget) {
      return null;
    }
    const {
      model,
      provider
    } = pendingCustomConfigTarget.modelOrProvider;
    const displayName = model ? modelIdentifierToDisplayName[model] : provider && PROVIDER_DISPLAY_NAMES[provider];
    return <ConfirmationModal data-testid="create-config-confirmation-modal" header={`Create a custom DLP config for ${displayName}?`} onCancel={() => onSetPendingCustomConfigTarget(null)} onConfirm={onConfirmCustomConfig} open subtext={`This configuration will be combined with your user group's base config when using ${model ? `${displayName}.` : `${displayName}'s models. If a ${displayName} model has a custom DLP config, that will be combined.`}`} />;
  };
  return <>
      <Table columns={[{
      title: 'Provider/Model',
      width: {
        "width": "25%"
      }
    }, {
      title: 'Creator'
    }, {
      title: 'Enabled',
      width: {
        "width": "12.5%"
      } // 1/8
    }, {
      title: 'DLP Filters',
      width: {
        "width": "12.5%"
      } // 1/8
    }, {
      title: '',
      width: {
        "width": "16.666667%"
      }
    }]} data-testid="model-provider-security-controls-table" noRowsText="No provider settings available at this time." rows={providerWithModelConfigs.map(securityConfig => isProviderSecurityConfig(securityConfig) ? getProviderRow(securityConfig) : getModelRow(securityConfig))} />
      {renderCreateConfigConfirmationModal()}
    </>;
};
export default ModelProviderSecurityControlsTable;