import {
  INTEGRATION_WORKFLOW_INPUT_TYPES,
  IntegrationWorkflowInputType,
  PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES,
  PromptTemplateWorkflowInputType,
  WorkflowStepType
} from '@kindo/universal';

import { trpc } from '~/trpc';

export type Workflow = Awaited<
  ReturnType<(typeof trpc)['workflows']['get']['query']>
>['item'];

export type WorkflowInput = Workflow['inputs'][number];

export type WorkflowStep = Workflow['steps'][number];

export type WorkflowStepInput = WorkflowStep['inputs'][number];

export type WorkflowTrigger = Workflow['triggers'][number];

export type WorkflowTriggerCondition = WorkflowTrigger['conditions'][number];

// Subtypes of WorkflowStepType
export type WorkflowLlmStep = Extract<
  WorkflowStep,
  { type: WorkflowStepType.LLM }
>;
export type WorkflowIntegrationActionStep = Extract<
  WorkflowStep,
  { type: WorkflowStepType.INTEGRATION_ACTION }
>;
export type WorkflowApiActionStep = Extract<
  WorkflowStep,
  { type: WorkflowStepType.API_ACTION }
>;

// Subtypes of WorkflowInput
export type IntegrationWorkflowInput = Extract<
  WorkflowInput,
  { type: IntegrationWorkflowInputType }
>;

// Subtypes of WorkflowStepInput
export type PromptTemplateWorkflowStepInput = Extract<
  WorkflowStepInput,
  { type: PromptTemplateWorkflowInputType }
>;

export type IntegrationWorkflowStepInput = Extract<
  WorkflowStepInput,
  { type: IntegrationWorkflowInputType }
>;

export type WorkflowWithPermissions = Awaited<
  ReturnType<(typeof trpc)['workflows']['list']['query']>
>['items'][number];

/** Type Guards */
export function isLlmWorkflowStep(
  step: WorkflowStep | null
): step is WorkflowLlmStep {
  return step?.type === WorkflowStepType.LLM;
}

export function isIntegrationActionWorkflowStep(
  step: WorkflowStep | null
): step is WorkflowIntegrationActionStep {
  return step?.type === WorkflowStepType.INTEGRATION_ACTION;
}

export function isApiActionWorkflowStep(
  step: WorkflowStep | null
): step is WorkflowApiActionStep {
  return step?.type === WorkflowStepType.API_ACTION;
}

export function isPromptTemplateWorkflowStepInput(
  input: WorkflowInput
): input is PromptTemplateWorkflowStepInput {
  return PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES.includes(
    input.type as PromptTemplateWorkflowInputType
  );
}

export function isIntegrationWorkflowInput(
  input: WorkflowInput
): input is IntegrationWorkflowInput {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    input.type as IntegrationWorkflowInputType
  );
}

export function isIntegrationWorkflowStepInput(
  input: WorkflowStepInput
): input is IntegrationWorkflowStepInput {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    input.type as IntegrationWorkflowInputType
  );
}
