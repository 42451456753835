import _styled from "styled-components";
import { TicketingIntegrationTicketField, WorkflowInputType } from '@kindo/universal';
import { useEffect, useState } from 'react';
import { getIntegrationInputFields } from '../WorkflowIntegrationInput.utils';
import IntegrationInputFieldSelector from './IntegrationInputFieldSelector';
import { Button, ButtonType, Icon, IconButton, IconButtonType, TextIconColor, Typography, TypographyCasing, TypographySize, TypographyWeight } from '~/components/core';
import useActiveBuilderStepIntegrationInput from '~/hooks/useActiveBuilderStepIntegrationInput';
import { BuilderWorkflowLlmStep, IntegrationWorkflowBuilderStepInput, IntegrationWorkflowInput, isIntegrationWorkflowBuilderStepInput } from '~/types';
const IntegrationInputBuilderContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem",
  "borderRadius": "0.5rem",
  "padding": "1.5rem",
  "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
});
const InputBuilderHeaderContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem"
});
const InputBuilderHeaderTextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "gap": "0.5rem"
});
const InputFieldSelectionContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "flex-start",
  "gap": "0.5rem"
});
const FooterContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "flex-end",
  "justifyContent": "space-between",
  "paddingTop": "0.5rem"
});
const SaveCancelButtonsContainer = _styled.div({
  "display": "flex",
  "alignItems": "flex-end",
  "gap": "0.5rem"
});
const SelectAllButton = _styled.button({
  "display": "flex",
  "cursor": "pointer",
  "transitionProperty": "opacity",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  "&:hover": {
    "opacity": "0.8"
  }
});
interface WorkflowIntegrationInputEditorProps {
  activeStep: BuilderWorkflowLlmStep;
  integrationInputActiveInEditor: IntegrationWorkflowBuilderStepInput | null;
  onClose: () => void;
  workflowIntegrationInput: IntegrationWorkflowInput;
}
const WorkflowIntegrationInputEditor: React.FC<WorkflowIntegrationInputEditorProps> = ({
  activeStep,
  onClose,
  workflowIntegrationInput,
  integrationInputActiveInEditor
}) => {
  const [selectedFields, setSelectedFields] = useState<TicketingIntegrationTicketField[]>([]);
  const {
    addIntegrationInput,
    removeIntegrationInput
  } = useActiveBuilderStepIntegrationInput({
    activeStep
  });
  const [selectedWorkflowInputId, setSelectedWorkflowInputId] = useState<string>(workflowIntegrationInput.id);
  const workflowInputFieldOptions = getIntegrationInputFields(WorkflowInputType.TICKETING_INTEGRATION_TICKET).filter(field => field !== TicketingIntegrationTicketField.MERGE_ID);
  const workflowStepExistingInput = activeStep.inputs.filter(isIntegrationWorkflowBuilderStepInput).find(input => input.id === selectedWorkflowInputId);
  useEffect(() => {
    if (workflowStepExistingInput) {
      setSelectedFields(workflowStepExistingInput.config?.specifiedFields ?? []);
    }
  }, [workflowStepExistingInput]);
  useEffect(() => {
    if (integrationInputActiveInEditor) {
      setSelectedWorkflowInputId(integrationInputActiveInEditor.id);
      setSelectedFields(integrationInputActiveInEditor.config?.specifiedFields ?? []);
    }
  }, [integrationInputActiveInEditor]);
  const handleAddOrUpdateIntegrationInput = () => {
    addIntegrationInput({
      ...workflowIntegrationInput,
      config: {
        specifiedFields: selectedFields
      }
    });
    onClose();
  };
  const canSaveIntegrationInput = selectedFields.length !== 0 && selectedWorkflowInputId && !(workflowStepExistingInput && workflowStepExistingInput.config?.specifiedFields === selectedFields);
  return <IntegrationInputBuilderContainer>
      <InputFieldSelectionContainer>
        <InputBuilderHeaderContainer>
          {/* Title */}
          <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
            fields
          </Typography>
          <InputBuilderHeaderTextContainer>
            {/* Select all/deselect all */}
            <SelectAllButton>
              <Typography color={TextIconColor.PRIMARY_HIGHLIGHT} italic onClick={selectedFields.length === 0 ? () => setSelectedFields(workflowInputFieldOptions) : () => setSelectedFields([])} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_LIGHT}>
                {selectedFields.length === 0 ? 'Select all' : 'Deselect all'}
              </Typography>
            </SelectAllButton>
            {/* Selected fields */}
            <Typography color={TextIconColor.SECONDARY} italic size={TypographySize.SMALL} weight={TypographyWeight.SEMI_LIGHT}>
              {`${selectedFields.length} selected`}
            </Typography>
          </InputBuilderHeaderTextContainer>
        </InputBuilderHeaderContainer>
        <IntegrationInputFieldSelector<TicketingIntegrationTicketField> integrationInputFields={workflowInputFieldOptions} integrationInputType={WorkflowInputType.TICKETING_INTEGRATION_TICKET} selectedInputFields={selectedFields} setSelectedInputFields={setSelectedFields} />
      </InputFieldSelectionContainer>

      <FooterContainer>
        <IconButton color={TextIconColor.PRIMARY} disabled={!workflowStepExistingInput} hidden={!workflowStepExistingInput} icon={Icon.TRASH} onClick={() => {
        removeIntegrationInput(selectedWorkflowInputId);
        onClose();
      }} tooltip="Remove input from workflow step" type={IconButtonType.OUTLINED} />
        <SaveCancelButtonsContainer>
          <Button label="Cancel" onClick={onClose} type={ButtonType.OUTLINED} />
          <Button disabled={!canSaveIntegrationInput} endIcon={canSaveIntegrationInput ? Icon.CHECK : undefined} label={workflowStepExistingInput ? 'Update Input' : 'Add Input'} onClick={handleAddOrUpdateIntegrationInput} tooltip={workflowStepExistingInput ? '' : 'Add input to workflow step'} type={ButtonType.SOLID_GRADIENT} />
        </SaveCancelButtonsContainer>
      </FooterContainer>
    </IntegrationInputBuilderContainer>;
};
export default WorkflowIntegrationInputEditor;