import { tv } from 'tailwind-variants';
import { BadgeColor, BadgeType } from './Badge.consts';
export const badgeStyles = tv({
  base: 'inline-flex w-fit items-center px-2 py-0.5',
  variants: {
    // Base type definitions
    type: {
      [BadgeType.SOLID]: {},
      [BadgeType.OUTLINE]: 'border bg-white/10'
    },
    // Base color definitions (empty, just for prop typing)
    color: {
      [BadgeColor.DEFAULT]: {},
      [BadgeColor.ERROR]: {},
      [BadgeColor.HIGHLIGHT]: {},
      [BadgeColor.PRIMARY_HIGHLIGHT]: {},
      [BadgeColor.WARNING]: {},
      [BadgeColor.PRIMARY_BRIGHT]: {}
    },
    // Active state directly affects appearance
    active: {
      true: {},
      false: 'bg-white/10 border-white/10'
    },
    // Rounded corners control
    semiRounded: {
      true: 'rounded-t-[3px] rounded-b-none',
      false: 'rounded-sm'
    }
  },
  compoundVariants: [
  // SOLID + COLOR combinations
  {
    type: BadgeType.SOLID,
    color: BadgeColor.DEFAULT,
    active: true,
    class: 'bg-white/20'
  }, {
    type: BadgeType.SOLID,
    color: BadgeColor.ERROR,
    active: true,
    class: 'bg-fuchsia-error'
  }, {
    type: BadgeType.SOLID,
    color: BadgeColor.HIGHLIGHT,
    active: true,
    class: 'bg-highlight'
  }, {
    type: BadgeType.SOLID,
    color: BadgeColor.PRIMARY_HIGHLIGHT,
    active: true,
    class: 'bg-primary-highlight'
  }, {
    type: BadgeType.SOLID,
    color: BadgeColor.WARNING,
    active: true,
    class: 'bg-yellow-warning'
  }, {
    type: BadgeType.SOLID,
    color: BadgeColor.PRIMARY_BRIGHT,
    active: true,
    class: 'bg-primary-bright'
  },
  // OUTLINE + COLOR combinations
  {
    type: BadgeType.OUTLINE,
    color: BadgeColor.DEFAULT,
    active: true,
    class: 'border-white/20'
  }, {
    type: BadgeType.OUTLINE,
    color: BadgeColor.ERROR,
    active: true,
    class: 'border-fuchsia-error'
  }, {
    type: BadgeType.OUTLINE,
    color: BadgeColor.HIGHLIGHT,
    active: true,
    class: 'border-highlight'
  }, {
    type: BadgeType.OUTLINE,
    color: BadgeColor.PRIMARY_HIGHLIGHT,
    active: true,
    class: 'border-primary-highlight'
  }, {
    type: BadgeType.OUTLINE,
    color: BadgeColor.WARNING,
    active: true,
    class: 'border-yellow-warning'
  }, {
    type: BadgeType.OUTLINE,
    color: BadgeColor.PRIMARY_BRIGHT,
    active: true,
    class: 'border-primary-bright'
  }]
});