import _styled from "styled-components";
import { useState } from 'react';
import { renderObjectValue } from '../AuditLog.utils';
import { Button, ButtonType, TextIconColor, Icon, Size, Typography, TypographySize, TypographyWeight } from '~/components/core';
const ObjectViewerContainer = _styled.div({
  "marginLeft": "2rem",
  "marginRight": "0.5rem",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ObjectViewerItem = _styled.div({});
const ObjectViewerPlainItem = _styled.div({
  "display": "flex",
  "gap": "0.5rem",
  "paddingLeft": "1.25rem"
});
const HorizontalRule = _styled.hr({
  "marginBottom": "0.5rem",
  "marginTop": "0.5rem",
  "borderColor": "#095A7C58"
});
type ElementType = string | number | Object;

// TODO: Discuss if this component is necessary. If we choose to show
// raw JSON to users, this component may not be needed.
const ObjectViewer = ({
  data,
  level = 0
}: {
  data: Record<string, any>;
  level?: number;
}) => {
  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());
  const handleKeyExpandToggle = (key: string) => {
    if (expandedKeys.has(key)) {
      setExpandedKeys(prev => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    } else {
      setExpandedKeys(prev => new Set([...prev, key]));
    }
  };
  return <ObjectViewerContainer>
      {Object.keys(data).map(key => {
      const value = data[key];
      const isValueArray = Array.isArray(value);
      const isValueStrictlyObject = typeof value === 'object' && !isValueArray && value !== null;
      const showExpandButton = isValueStrictlyObject || isValueArray;
      const isExpanded = expandedKeys.has(key);
      return <ObjectViewerItem key={key}>
            {showExpandButton && <Button label={key} onClick={() => handleKeyExpandToggle(key)} size={Size.SMALL} startIcon={isExpanded ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} type={ButtonType.TEXT} />}
            {isExpanded && isValueStrictlyObject && <ObjectViewer data={value} level={level + 1} />}
            {isExpanded && isValueArray && <div css={{
          "marginLeft": "2rem"
        }}>
                {value.map((item: ElementType) => {
            const isObject = typeof item === 'object';
            return <span key={item.toString()}>
                      <HorizontalRule />
                      {isObject && <ObjectViewer data={item} level={level + 1} />}
                      {!isObject && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                          {item}
                        </Typography>}
                      <HorizontalRule />
                    </span>;
          })}
              </div>}
            {!isValueStrictlyObject && !isValueArray && value !== null && <ObjectViewerPlainItem>
                <Typography color={TextIconColor.SECONDARY} fitContent size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD}>
                  {key + ':'}
                </Typography>
                <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  {renderObjectValue(value)}
                </Typography>
              </ObjectViewerPlainItem>}
          </ObjectViewerItem>;
    })}
    </ObjectViewerContainer>;
};
export default ObjectViewer;