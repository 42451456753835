import { isModelAccessErrorCode, ManageModelErrorCode } from '@kindo/universal';
import { isManageModelErrorCode } from '@kindo/universal';
import { KINDO_ERROR_MESSAGES } from '@kindo/universal';
import { useState } from 'react';
import { Icon, TextField, TextFieldType, TextIconColor } from '~/components/core';
import ConfirmationModal from '~/components/shared/ConfirmationModal/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface ConfirmModelDeleteModalProps {
  modelDisplayName: string;
  modelIdentifier: string;
  onClose: () => void;
}
const DELETE_CONFIRMATION_TEXT = 'delete';
const ConfirmModelDeleteModal: React.FC<ConfirmModelDeleteModalProps> = ({
  onClose,
  modelDisplayName,
  modelIdentifier
}) => {
  // States
  const [enableDeleteButton, setEnableDeleteButton] = useState(false);
  const [textFieldInput, setTextFieldInput] = useState('');

  // Custom hook
  const {
    enqueueToast
  } = useToast();

  // Mutations
  const nextTrpcUtils = nextTrpc.useUtils();
  const deleteModelMutation = nextTrpc.models.deleteModel.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Model deleted successfully',
        type: ToastType.SUCCESS
      });
      void nextTrpcUtils.models.listAllAvailableOrgModelsAndMetadata.invalidate();
      void nextTrpcUtils.adminSecurityConfig.listOrgEnabledModels.invalidate();
      onClose();
    },
    onError: (error: {
      message: string;
    }) => {
      if (isManageModelErrorCode(error.message) || isModelAccessErrorCode(error.message)) {
        enqueueToast({
          message: KINDO_ERROR_MESSAGES[error.message],
          type: ToastType.ERROR
        });
      } else {
        enqueueToast({
          message: KINDO_ERROR_MESSAGES[ManageModelErrorCode.MODEL_MANAGEMENT_DELETION_FAILED],
          type: ToastType.ERROR
        });
      }
    }
  });
  const handleDeleteTextFieldChange = (value: string) => {
    setTextFieldInput(value);
    setEnableDeleteButton(value === DELETE_CONFIRMATION_TEXT);
  };
  const handleDeleteButtonClick = () => {
    if (textFieldInput === DELETE_CONFIRMATION_TEXT) {
      deleteModelMutation.mutate({
        modelIdentifier
      });
      return;
    }
    enqueueToast({
      message: 'Please type "delete" to confirm',
      type: ToastType.ERROR
    });
  };
  return <ConfirmationModal confirmDisabled={textFieldInput !== DELETE_CONFIRMATION_TEXT} header={`Are you sure you want to remove ${modelDisplayName}?`} onCancel={onClose} onConfirm={handleDeleteButtonClick} open={true} subtext={'All existing Chats and Agents using this model will be affected. This action cannot be undone.'}>
      <TextField fullWidth iconColor={TextIconColor.HIGHLIGHT} onChange={handleDeleteTextFieldChange} placeholder={`Type "${DELETE_CONFIRMATION_TEXT}"`} showEndIcon={false} startIcon={enableDeleteButton ? Icon.CHECK : undefined} type={TextFieldType.DEFAULT} value={textFieldInput} />
    </ConfirmationModal>;
};
export default ConfirmModelDeleteModal;