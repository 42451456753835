/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { DecoratorNode, LexicalNode, NodeKey, SerializedLexicalNode } from 'lexical';
import { ReactElement } from 'react';
import { WorkflowLexicalNodeType } from '../workflowLexicalNodes.consts';
import WorkflowUserInputNodeComponent from './WorkflowUserInputNodeComponent';
export interface SerializedWorkflowUserInputNode extends SerializedLexicalNode {
  displayName: string;
  templateResolutionName: string;
}

// https://github.com/facebook/lexical/blob/main/examples/decorators.md
// https://lexical.dev/docs/concepts/nodes#extending-decoratornode
export class WorkflowUserInputNode extends DecoratorNode<ReactElement> {
  private __templateResolutionName: string;
  private __displayName: string;
  public constructor(templateResolutionName: string, displayName: string, key?: NodeKey) {
    super(key);
    this.__templateResolutionName = templateResolutionName;
    this.__displayName = displayName;
  }
  public override createDOM(): HTMLElement {
    const span = document.createElement('span');
    span.style.display = 'inline-flex';
    span.style.verticalAlign = 'middle';
    span.style.marginInline = '4px';
    span.style.maxWidth = '100%';
    return span;
  }
  public override decorate(): ReactElement {
    return <WorkflowUserInputNodeComponent setUserInputNames={args => this.setUserInputNames(args)} userInputName={this.__templateResolutionName} />;
  }
  public override exportJSON(): SerializedWorkflowUserInputNode {
    return {
      templateResolutionName: this.__templateResolutionName,
      displayName: this.__displayName,
      type: this.getType(),
      version: 1
    };
  }
  public importJSON(json: SerializedWorkflowUserInputNode): void {
    this.__templateResolutionName = json.templateResolutionName;
    this.__displayName = json.displayName;
  }
  public setUserInputNames({
    displayName,
    templateResolutionName
  }: {
    displayName: string;
    templateResolutionName: string;
  }): void {
    const writable = this.getWritable();
    writable.__templateResolutionName = templateResolutionName;
    writable.__displayName = displayName;
  }
  public override updateDOM(): boolean {
    return false;
  }
  public static override clone(node: WorkflowUserInputNode): WorkflowUserInputNode {
    return new WorkflowUserInputNode(node.__templateResolutionName, node.__displayName, node.__key);
  }
  public static override getType(): string {
    return WorkflowLexicalNodeType.WORKFLOW_USER_INPUT;
  }
}
export function $createWorkflowUserInputNode({
  templateResolutionName,
  displayName
}: {
  displayName: string;
  templateResolutionName: string;
}): WorkflowUserInputNode {
  return new WorkflowUserInputNode(templateResolutionName, displayName);
}
export function $isWorkflowUserInputNode(node: LexicalNode | null | undefined): node is WorkflowUserInputNode {
  return node instanceof WorkflowUserInputNode;
}