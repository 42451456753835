import _styled from "styled-components";
import { LoadingSpinner, Modal, ModalWidth, Typography, TypographyFont, TypographySize, TypographyWeight } from '../../../../core';
import { JsonViewer } from '../../../../shared/JsonViewer';
import { ApiActionStepExecutionInfo } from '~/hooks/useExecuteApiActionStep';
const ModalContent = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "2rem"
});
const ResultsContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1.5rem"
});
interface WorkflowApiActionStepTestModalProps {
  executionInfo: ApiActionStepExecutionInfo | null;
  isLoading: boolean;
  onClose: () => void;
}
const WorkflowApiActionStepTestModal: React.FC<WorkflowApiActionStepTestModalProps> = ({
  onClose,
  isLoading,
  executionInfo
}) => <Modal onClose={onClose} open width={ModalWidth.NINE_TWENTY}>
    <ModalContent>
      <Typography font={TypographyFont.HEADING} size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        API Test Results
      </Typography>
      {isLoading ? <div css={{
      "display": "flex",
      "justifyContent": "center"
    }}>
          <LoadingSpinner />
        </div> : executionInfo && <ResultsContainer>
            <JsonViewer data={executionInfo.request} title="Request" />
            <JsonViewer data={executionInfo.response} title="Response" />
          </ResultsContainer>}
    </ModalContent>
  </Modal>;
export default WorkflowApiActionStepTestModal;