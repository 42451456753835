import {
  WorkflowActionStepConfig,
  WorkflowIntegrationActionStepType,
  WorkflowStepType
} from '@kindo/universal';

import { useAppDispatch } from '../typedReduxHooks';
import useIsSharedWorkflow from '../useIsSharedWorkflow';
import useToast, { ToastType } from '../useToast';

import useActiveApiActionStep, {
  ValidationResult
} from './useActiveApiActionStep';
import useActiveIntegrationActionStep from './useActiveIntegrationActionStep';
import useActiveLlmStep from './useActiveLlmStep';

import { useGetWorkflowFromUrlSlug } from '~/hooks';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import {
  BuilderWorkflowStatus,
  BuilderWorkflowStep,
  isApiActionWorkflowBuilderStep,
  isIntegrationActionWorkflowBuilderStep,
  isLlmWorkflowBuilderStep,
  transformWorkflowStepToBuilderWorkflowStep,
  Workflow
} from '~/types';

interface UseActiveBuilderStepArgs {
  activeStep: BuilderWorkflowStep | null;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
}

interface UseActiveBuilderStepReturnObject {
  canSave: boolean;
  clearActiveStep: () => void;
  isNewStep: boolean;
  isSavingActiveStep: boolean;
  requiresStaticContentConfirmation: boolean;
  saveActiveStep: (options?: {
    clearActiveStep?: boolean;
  }) => Promise<BuilderWorkflowStep | null>;
  updateActiveStepAction: (value: WorkflowIntegrationActionStepType) => void;
  updateActiveStepActionConfig: (
    config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>
  ) => void;
  updateActiveStepModel: (value: string) => void; // DO NOT REMOVE COMMENT: SupportedLlm
  updateActiveStepPrompt: (value: string) => void;
  saveValidationMessage?: string | undefined;
}

/**
 * Custom hook for performing actions or returning data on
 * the active step in the workflow builder
 */
const useActiveBuilderStep = ({
  activeStep,
  workflow,
  refetchWorkflow
}: UseActiveBuilderStepArgs): UseActiveBuilderStepReturnObject => {
  // Queries
  const { data: authorizedUsers } =
    nextTrpc.workflows.getWorkflowAuthorizedUsers.useQuery({
      workflowId: workflow.id
    });

  // Custom Hooks
  const { enqueueToast } = useToast();
  const { isSharedWorkflow } = useIsSharedWorkflow({
    workflow,
    authorizedUsers: authorizedUsers ?? []
  });
  const dispatch = useAppDispatch();

  // Step-specific hooks
  const llmStepHook = useActiveLlmStep(workflow.id);
  const apiActionStepHook = useActiveApiActionStep(workflow.id);
  const integrationActionStepHook = useActiveIntegrationActionStep(workflow.id);

  const clearActiveStep = () => {
    dispatch(workflowBuilderActions.clearActiveStep());
  };

  const isNewStep = activeStep?.status === BuilderWorkflowStatus.NEW;

  /**
   * Saves the active workflow step
   * @returns {Promise<BuilderWorkflowStep | null>} Returns null if the step is not saved or it is cleared,
   * and the new saved step if it is saved and not cleared
   */
  const saveActiveStep = async ({
    clearActiveStep: shouldClearActiveStep = true
  }: {
    clearActiveStep?: boolean;
  } = {}): Promise<BuilderWorkflowStep | null> => {
    if (!activeStep) {
      console.error('No active step to save');
      enqueueToast({
        message: 'No active step to save.',
        type: ToastType.ERROR
      });
      return null;
    }

    try {
      if (isLlmWorkflowBuilderStep(activeStep)) {
        await llmStepHook.saveLlmStep(activeStep);
      } else if (isIntegrationActionWorkflowBuilderStep(activeStep)) {
        await integrationActionStepHook.saveIntegrationActionStep(activeStep);
      } else if (isApiActionWorkflowBuilderStep(activeStep)) {
        await apiActionStepHook.saveApiActionStep(activeStep);
      } else {
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          `Unknown step type: ${(activeStep as BuilderWorkflowStep).type}`
        );
      }

      enqueueToast({
        message: 'Step saved successfully.',
        type: ToastType.SUCCESS
      });

      // If we're clearing the active step, we just clear it and refetch the workflow
      if (shouldClearActiveStep) {
        clearActiveStep();
        refetchWorkflow();
        return null;
      }

      // If we're not clearing the active step, we need to refetch the workflow, find the updated step, and set it as the active step
      const { data: updatedWorkflowData } = await refetchWorkflow();

      if (!updatedWorkflowData) {
        console.error('Failed to refetch saved step');
        enqueueToast({
          message: 'Failed to refetch saved step',
          type: ToastType.ERROR
        });
        return null;
      }

      const updatedStep = updatedWorkflowData.item.steps.find(
        (step) => step.stepNumber === activeStep.stepNumber
      );

      if (!updatedStep) {
        console.error('Failed to retrieve updated step');
        enqueueToast({
          message: 'Failed to retrieve updated step',
          type: ToastType.ERROR
        });
        return null;
      }

      dispatch(
        workflowBuilderActions.markActiveStepAsSaved({ stepId: updatedStep.id })
      );

      return transformWorkflowStepToBuilderWorkflowStep(
        updatedStep,
        BuilderWorkflowStatus.SAVED
      );
    } catch (error) {
      console.error('Failed to save active step:', error);
      // Error toasts are handled in the individual step hooks
      return null;
    }
  };

  const getCanSave = (step: BuilderWorkflowStep | null): ValidationResult => {
    if (!step || step.status === BuilderWorkflowStatus.SAVED) {
      return { isValid: false, message: 'Step is already saved' };
    }

    if (isLlmWorkflowBuilderStep(step)) {
      return llmStepHook.canSave(step);
    }
    if (isIntegrationActionWorkflowBuilderStep(step)) {
      return integrationActionStepHook.canSave(step);
    }
    if (isApiActionWorkflowBuilderStep(step)) {
      return apiActionStepHook.canSave(step);
    }
    return { isValid: false, message: 'Unknown step type' };
  };

  const validationResult = getCanSave(activeStep);
  const canSave = validationResult.isValid;
  const saveValidationMessage = validationResult.message;

  const isSavingActiveStep =
    llmStepHook.isSaving ||
    apiActionStepHook.isSaving ||
    integrationActionStepHook.isSaving;

  const savedStep =
    activeStep?.stepNumber !== undefined
      ? workflow?.steps[activeStep.stepNumber - 1]
      : null;

  const newStaticContentAdded =
    savedStep?.type === WorkflowStepType.LLM &&
    isLlmWorkflowBuilderStep(activeStep) &&
    activeStep.staticContent.some(
      (content) =>
        !savedStep.staticContent.some(
          (savedContent) => savedContent.id === content.id
        )
    );

  const requiresStaticContentConfirmation =
    isSharedWorkflow && newStaticContentAdded;

  return {
    canSave,
    saveValidationMessage,
    requiresStaticContentConfirmation,
    isSavingActiveStep,
    isNewStep,
    clearActiveStep,
    saveActiveStep,
    updateActiveStepPrompt: llmStepHook.updatePrompt,
    updateActiveStepModel: llmStepHook.updateModel,
    updateActiveStepAction: integrationActionStepHook.updateAction,
    updateActiveStepActionConfig: integrationActionStepHook.updateActionConfig
  };
};

export default useActiveBuilderStep;
