import { DEFAULT_WORKFLOW_NAME, WorkflowListSort, WorkflowListTypeFilter } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { useRouter } from 'next/router';
import { Icon, IconButton, IconButtonType, Size, Table, TextIconColor, Toggle, Typography, TypographyFont, TypographySize } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { TabViewProps } from '~/components/core/Table/Table';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { FiltersProps, WorkflowFilter } from '~/components/shared/Filters';
import { TableSort } from '~/components/SortHeader/SortHeader';
import { getWorkflowBuilderRoute, getWorkstationRoute, WORKFLOW_TYPE_TO_LABEL } from '~/constants';
import { ToastType, useToast } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import useWorkflowDropdownItems from '~/hooks/useWorkflowDropdownItems';
import { nextTrpc } from '~/trpc';
import { WorkflowWithPermissions } from '~/types';
interface WorkflowTableSortProps {
  activeSort: TableSort<WorkflowListSort>;
  setActiveSort: (sort: TableSort<WorkflowListSort>) => void;
}
interface WorkflowsTableProps {
  isLoading: boolean;
  refetchWorkflows: () => void;
  tableTitle: string;
  workflows: WorkflowWithPermissions[];
  tableFilterProps?: FiltersProps<WorkflowFilter>;
  tableSortProps?: WorkflowTableSortProps;
  tableTabViewProps?: TabViewProps<WorkflowListTypeFilter>;
}
const WorkflowsTable: React.FC<WorkflowsTableProps> = ({
  workflows,
  isLoading,
  refetchWorkflows,
  tableFilterProps,
  tableSortProps,
  tableTabViewProps,
  tableTitle
}) => {
  // Custom hooks
  const {
    getWorkflowDropdownItems
  } = useWorkflowDropdownItems();
  const {
    enqueueToast
  } = useToast();
  const {
    user
  } = useUserInfo();

  // Mutations
  const updateTriggerMutation = nextTrpc.workflows.updateTrigger.useMutation({
    onSuccess: () => {
      void refetchWorkflows();
    },
    onError: error => {
      console.error('Failed to update trigger:', error);
      enqueueToast({
        message: 'Failed to update trigger.',
        type: ToastType.ERROR
      });
    }
  });
  const handleToggleTrigger = (triggerId: string, enabled: boolean) => updateTriggerMutation.mutate({
    triggerId,
    enabled
  }, {
    onSuccess: () => {
      enqueueToast({
        message: `Trigger ${enabled ? 'enabled' : 'disabled'}.`,
        type: ToastType.SUCCESS
      });
    }
  });
  const router = useRouter();
  const getWorkflowClickRoute = (workflowId: string, hasTriggers: boolean) => hasTriggers ? getWorkflowBuilderRoute(workflowId) : getWorkstationRoute({
    workflowId
  });
  const handleWorkflowClick = (workflowId: string, hasTriggers: boolean) => {
    const route = getWorkflowClickRoute(workflowId, hasTriggers);
    void router.push(route);
  };
  const handleWorkflowEdit = (workflowId: string) => {
    void router.push(getWorkflowBuilderRoute(workflowId));
  };
  const workflowsCount = workflows.length;
  const titleWithCount = `${tableTitle} ${workflowsCount ? `(${workflowsCount})` : ''}`;
  return <Table<string, WorkflowFilter, WorkflowListSort, WorkflowListTypeFilter> columns={[{
    title: 'NAME',
    sortKey: WorkflowListSort.NAME,
    width: {
      "width": "50%"
    }
  }, {
    title: 'TYPE',
    sortKey: WorkflowListSort.TYPE,
    width: {
      "width": "8.333333%"
    }
  }, {
    title: '',
    width: {
      "width": "16.666667%"
    }
  }, {
    title: 'LAST UPDATED',
    sortKey: WorkflowListSort.UPDATED_AT,
    width: {
      "width": "16.666667%"
    }
  }, {
    title: '',
    width: {
      "width": "16.666667%"
    }
  }]} filterProps={tableFilterProps} loading={isLoading} noRowsText="No agents found" rows={workflows.map(workflow => {
    const trigger = workflow.triggers[0];
    const dropdownItems = getWorkflowDropdownItems({
      workflowId: workflow.id,
      workflowTitle: workflow.displayName || DEFAULT_WORKFLOW_NAME,
      permissions: workflow.permissions
    });
    const hasTriggers = workflow.triggers.length > 0;
    return {
      key: workflow.id,
      height: {
        "height": "3rem"
      },
      cells: [
      // Name
      <Typography font={TypographyFont.HEADING} href={getWorkflowClickRoute(workflow.id, hasTriggers)} key={workflow.id} size={TypographySize.SMALL}>
              <div>{workflow.displayName || DEFAULT_WORKFLOW_NAME}</div>
            </Typography>,
      // Type
      <Badge active={false} key={workflow.id} label={WORKFLOW_TYPE_TO_LABEL[workflow.type]} />, trigger && workflow.creator.id === user?.id && <div css={{
        "marginLeft": "0.25rem",
        "marginTop": "0.25rem"
      }} data-testid="workflow-toggle-wrapper" key={trigger.id} onClick={event => event.stopPropagation()}>
                <Toggle checked={trigger.enabled} onToggle={isChecked => handleToggleTrigger(trigger.id, isChecked)} />
              </div>,
      // Last updated
      <Typography color={TextIconColor.SECONDARY} key={workflow.id} size={TypographySize.X_SMALL}>
              {workflow.updatedAt ? formatDistanceToNowStrict(new Date(workflow.updatedAt), {
          addSuffix: true
        }) : 'N/A'}
            </Typography>,
      // Workflow Actions
      <div css={{
        "display": "flex",
        "alignItems": "center",
        "gap": "0.5rem"
      }} data-testid="workflow-actions-container" key={workflow.id}>
              <Dropdown disabled={!dropdownItems.length} highlightTriggerOnHover key={workflow.id} trigger={Icon.MORE}>
                {dropdownItems.map(option => <DropdownItem destructive={option.destructive} icon={option.icon} key={option.title} onClick={option.onClick} title={option.title} />)}
              </Dropdown>
              <IconButton color={TextIconColor.PRIMARY} disabled={!workflow.permissions?.edit} icon={Icon.EDIT} onClick={() => handleWorkflowEdit(workflow.id)} size={Size.LARGE} type={IconButtonType.SIMPLE} />
              <IconButton color={TextIconColor.PRIMARY} disabled={workflow.triggers.length > 0} icon={Icon.PLAY} onClick={() => handleWorkflowClick(workflow.id, hasTriggers)} size={Size.LARGE} type={IconButtonType.SIMPLE} />
            </div>]
    };
  })} showColumnHeaderBorder sortProps={tableSortProps} tabViewProps={tableTabViewProps} title={titleWithCount} />;
};
export default WorkflowsTable;