import { Secret } from '@kindo/universal';
import { useEffect, useState } from 'react';
import { Button, ButtonType, Icon, Table, TextField, TextFieldType, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import IconButton from '~/components/core/IconButton/IconButton';
import { IconButtonType } from '~/components/core/IconButton/IconButton.consts';
import ConfirmationModal from '~/components/shared/ConfirmationModal/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
interface SecretTableProps {
  isLoadingSecrets: boolean;
  refetchSecrets: () => void;
  secrets: Secret[];
}
const SecretTable = ({
  secrets,
  isLoadingSecrets,
  refetchSecrets
}: SecretTableProps) => {
  const [secretToDelete, setSecretToDelete] = useState<Secret | null>(null);
  const [secretToEdit, setSecretToEdit] = useState<Secret | null>(null);
  const [editedLabel, setEditedLabel] = useState<string | null>(null);
  const {
    enqueueToast
  } = useToast();
  useEffect(() => {
    setEditedLabel(secretToEdit ? secretToEdit.label : null);
  }, [secretToEdit]);
  const nextTrpcUtils = nextTrpc.useUtils();

  // Delete Secret
  const deleteSecretMutation = nextTrpc.secrets.deleteSecret.useMutation({
    onSuccess: () => {
      refetchSecrets();
      // Invalidate workflows get to ensure removed secret isn't displayed in builder
      // that has workflow open that referenced the secret
      void nextTrpcUtils.workflows.get.invalidate();
      enqueueToast({
        message: 'Secret deleted successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to delete secret:', error);
      enqueueToast({
        message: 'Failed to delete secret',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setSecretToDelete(null);
    }
  });
  const handleDeleteSecret = () => {
    if (!secretToDelete) {
      console.error('No secret to delete');
      enqueueToast({
        message: 'No secret to delete',
        type: ToastType.ERROR
      });
      return;
    }
    deleteSecretMutation.mutate({
      secretId: secretToDelete.id
    });
  };

  // Edit Secret
  const editSecretMutation = nextTrpc.secrets.editSecret.useMutation({
    onSuccess: () => {
      refetchSecrets();
      enqueueToast({
        message: 'Secret label updated successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to update secret label:', error);
      enqueueToast({
        message: 'Failed to update secret',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setSecretToEdit(null);
    }
  });
  const handleUpdateSecret = () => {
    if (!secretToEdit) {
      console.error('Cannot find secret to edit');
      enqueueToast({
        message: 'Cannot find secret to edit',
        type: ToastType.ERROR
      });
      return;
    }
    if (!editedLabel || editedLabel.trim() === '') {
      console.error('Label cannot be empty');
      enqueueToast({
        message: 'Label cannot be empty',
        type: ToastType.ERROR
      });
      setEditedLabel(secretToEdit.label);
      return;
    }
    if (secrets.find(secret => secret.label === editedLabel)) {
      console.error('Secret with this label already exists');
      enqueueToast({
        message: 'Secret with this label already exists',
        type: ToastType.ERROR
      });
      setEditedLabel(secretToEdit.label);
      return;
    }
    editSecretMutation.mutate({
      secretId: secretToEdit.id,
      label: editedLabel
    });
  };

  // Delete secret confirmation message
  const getWorkflowStepsWarning = () => !secretToDelete?.workflowSteps.length ? <Typography color={TextIconColor.SECONDARY}>
        This action cannot be undone.
      </Typography> : <div css={{
    "marginTop": "1rem",
    "display": "flex",
    "flexDirection": "column",
    "gap": "1rem"
  }} data-testid="workflow-steps-container">
        <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
          The header fields that reference this secret will be removed from the
          following Agent API Action Steps:
        </Typography>
        <div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.5rem"
    }} data-testid="workflow-steps-list">
          {secretToDelete.workflowSteps.map(step => <Typography color={TextIconColor.PRIMARY} href={`/agents/builder/${step.workflowId}`} key={step.id} openLinkInNewTab size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
              • {step.workflowName || 'Unnamed Agent'} - Step {step.stepNumber}
              {step.displayName ? `: ${step.displayName}` : ''}
            </Typography>)}
        </div>
        <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
          Would you like to proceed with deleting this secret?
        </Typography>
      </div>;

  // Edit Secret Row
  const renderEditRow = (secretItem: Secret) => <tr css={{
    "height": "4rem",
    "borderBottomWidth": "0.063rem",
    "borderColor": "#e6c2ff1a",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(30 33 65 / var(--tw-bg-opacity))"
  }} data-testid={`edit-row-${secretItem.id}`} key={secretItem.id}>
      <td css={{
      "paddingLeft": "1rem",
      "paddingRight": "1rem"
    }} colSpan={4}>
        <div css={{
        "display": "flex",
        "alignItems": "center",
        "gap": "0.5rem"
      }} data-testid="edit-action-container">
          <TextField fullWidth onChange={setEditedLabel} size={TypographySize.SMALL} type={TextFieldType.DEFAULT} value={editedLabel ?? ''} />
          <div css={{
          "display": "flex",
          "alignItems": "center",
          "gap": "0.5rem"
        }} data-testid="edit-buttons-container">
            <Button label="CANCEL" onClick={() => setSecretToEdit(null)} type={ButtonType.OUTLINED} />
            <Button disabled={editedLabel?.trim() === secretToEdit?.label || editSecretMutation.isLoading} label="SAVE" loading={editSecretMutation.isLoading} onClick={handleUpdateSecret} type={ButtonType.SOLID_COLOR} />
          </div>
        </div>
      </td>
    </tr>;
  return <>
      <div css={{
      "maxHeight": "500px",
      "overflowY": "auto"
    }} data-testid="table-scroll-container">
        <Table columns={[{
        title: 'LABEL'
      }, {
        title: 'CREATED'
      }, {
        title: ''
      }]} loading={isLoadingSecrets} noRowsText="No secrets" rows={secrets.map(secretItem => {
        if (secretToEdit?.id === secretItem.id) {
          return renderEditRow(secretItem);
        }
        return {
          key: secretItem.id,
          cells: [<Typography font={TypographyFont.PARAGRAPH} key={`${secretItem.id}-name`} size={TypographySize.SMALL}>
                  {secretItem.label}
                </Typography>, <Typography font={TypographyFont.PARAGRAPH} key={`${secretItem.id}-modified`} size={TypographySize.SMALL}>
                  {secretItem.createdAt.toLocaleDateString()}
                </Typography>, <div css={{
            "display": "flex",
            "flexDirection": "row",
            "alignItems": "center",
            "justifyContent": "flex-end",
            "gap": "0.5rem"
          }} data-testid={`secret-actions-${secretItem.id}`} key={`${secretItem.id}-actions`}>
                  <IconButton highlightOnHover icon={Icon.EDIT} key={`${secretItem.id}-edit`} onClick={() => setSecretToEdit(secretItem)} type={IconButtonType.NONE} />
                  <IconButton highlightOnHover icon={Icon.TRASH} key={`${secretItem.id}-delete`} onClick={() => setSecretToDelete(secretItem)} type={IconButtonType.NONE} />
                </div>]
        };
      })} showColumnHeaderBorder />
      </div>
      <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={deleteSecretMutation.isLoading} header={`Are you sure you want to delete "${secretToDelete?.label}"?`} onCancel={() => setSecretToDelete(null)} onConfirm={handleDeleteSecret} open={!!secretToDelete} subtext={getWorkflowStepsWarning()} />
    </>;
};
export default SecretTable;