import { isManageModelErrorCode, KINDO_ERROR_MESSAGES, ManageModelErrorCode } from '@kindo/universal';
import { useState } from 'react';
import SettingsSection from '../../SettingsSection';
import { Button, ButtonType, Icon, IconButton, Size, TextField, TextFieldType, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { IconButtonType } from '~/components/core/IconButton/IconButton.consts';
import { useToast } from '~/hooks';
import { ToastType } from '~/hooks/useToast';
import { nextTrpc } from '~/trpc';
const JSON_FIELD_EXAMPLE = `{
  "model": "azure/gpt-4o-mini",
  "api_base": "https://your-endpoint.openai.azure.com/"
}`;
const FieldWrapper = ({
  label,
  description,
  children
}: {
  children: React.ReactNode;
  description: string;
  label: string;
}) => <div css={{
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "gap": "0.5rem"
}} data-testid="add-model-form-section">
    <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.5rem"
  }} data-testid="add-model-form-label">
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
        {label}
      </Typography>
      <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
        {description}
      </Typography>
    </div>
    {children}
  </div>;
const AddModel = ({
  onCloseAddModel
}: {
  onCloseAddModel: () => void;
}) => {
  const [name, setName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [modelData, setModelData] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);

  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Mutations
  const nextTrpcUtils = nextTrpc.useUtils();
  const addModelMutation = nextTrpc.models.addModel.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Model created successfully',
        type: ToastType.SUCCESS
      });
      void nextTrpcUtils.models.listAllAvailableOrgModelsAndMetadata.invalidate();
      void nextTrpcUtils.adminSecurityConfig.listOrgEnabledModels.invalidate();
      onCloseAddModel();
    },
    onError: (error: {
      message: string;
    }) => {
      if (isManageModelErrorCode(error.message)) {
        enqueueToast({
          message: KINDO_ERROR_MESSAGES[error.message],
          type: ToastType.ERROR
        });
      } else {
        enqueueToast({
          message: KINDO_ERROR_MESSAGES[ManageModelErrorCode.MODEL_MANAGEMENT_CREATION_FAILED],
          type: ToastType.ERROR
        });
      }
    }
  });
  const handleAddModel = () => {
    if (name === '' || apiKey === '' || modelData === '') {
      enqueueToast({
        message: KINDO_ERROR_MESSAGES[ManageModelErrorCode.MODEL_MANAGEMENT_FIELD_MISSING],
        type: ToastType.ERROR
      });
      return;
    }
    addModelMutation.mutate({
      displayName: name,
      apiKey,
      modelData
    });
  };
  const handleNameChange = (value: string) => {
    setName(value);
  };
  const handleApiKeyChange = (value: string) => {
    setApiKey(value);
  };
  const handleModelDataChange = (value: string) => {
    setModelData(value);
  };
  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };
  return <SettingsSection title="Add A New Model">
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.5rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem"
    }} data-testid="add-model-form-container">
        <div css={{
        "display": "flex",
        "gap": "1.5rem"
      }} data-testid="add-model-form-row">
          <FieldWrapper description="This is the display name of your chat model" label="NAME">
            <TextField data-testid="add-model-name-input" fullWidth iconColor={TextIconColor.HIGHLIGHT} onChange={handleNameChange} placeholder="Enter model name" startIcon={name ? Icon.CHECK : undefined} type={TextFieldType.DEFAULT} value={name} />
          </FieldWrapper>

          <FieldWrapper description="This is the API key for your chat model" label="API KEY">
            <div css={{
            "display": "flex",
            "width": "100%",
            "alignItems": "center",
            "gap": "0.5rem"
          }} data-testid="add-model-input-row">
              <TextField data-testid="add-model-api-key-input" fullWidth iconColor={TextIconColor.HIGHLIGHT} isPassword={!showApiKey} onChange={handleApiKeyChange} placeholder="Enter API key" startIcon={apiKey ? Icon.CHECK : undefined} type={TextFieldType.DEFAULT} value={apiKey} />
              <IconButton data-testid="add-model-toggle-api-key-visibility" icon={Icon.EYE} onClick={toggleApiKeyVisibility} type={IconButtonType.SIMPLE} />
            </div>
          </FieldWrapper>
        </div>

        <FieldWrapper description="Paste the required parameters of your chat model in JSON format" label="MODEL DATA">
          <TextField data-testid="add-model-model-data-input" fullWidth minRows={10} multiline onChange={handleModelDataChange} placeholder={JSON_FIELD_EXAMPLE} type={TextFieldType.DEFAULT} value={modelData} />
        </FieldWrapper>
        <div css={{
        "display": "flex",
        "justifyContent": "space-between",
        "gap": "1rem",
        "borderTopWidth": "0.1px",
        "--tw-border-opacity": "0.2",
        "borderTopColor": "rgb(150 148 181 / var(--tw-border-opacity))",
        "paddingTop": "1.5rem",
        "paddingBottom": "1.5rem"
      }} data-testid="add-model-buttons-container">
          <Button data-testid="add-model-cancel-button" label="CANCEL" onClick={onCloseAddModel} size={Size.LARGE} type={ButtonType.OUTLINED} />
          <Button data-testid="add-model-add-button" label="ADD MODEL" onClick={handleAddModel} size={Size.LARGE} type={ButtonType.SOLID_COLOR} />
        </div>
      </div>
    </SettingsSection>;
};
export default AddModel;