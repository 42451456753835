import { Button, ButtonType, Modal, Size, TextField, TextFieldType, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { CopyButton } from '~/components/shared';
interface ApiKeyModalProps {
  apiKey: string;
  header: string;
  onConfirm: () => void;
  open: boolean;
  subtext: string;
}
const ApiKeyModal: React.FC<ApiKeyModalProps> = ({
  open,
  header,
  subtext,
  onConfirm,
  apiKey
}) => <Modal open={open}>
    <div css={{
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "space-between",
    "gap": "1.5rem"
  }} data-testid="api-key-modal-container">
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center",
      "justifyContent": "center",
      "gap": "0.5rem"
    }} data-testid="header-subtext-container">
        <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          {header}
        </Typography>
        {subtext && <Typography>{subtext}</Typography>}
      </div>
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "center",
      "gap": "0.5rem"
    }} data-testid="api-key-copy-container">
        <TextField fullWidth onChange={() => {}} type={TextFieldType.HIGHLIGHT} value={apiKey} />
        <CopyButton size={Size.SMALL} text={apiKey} />
      </div>
      <div css={{
      "display": "flex",
      "width": "100%",
      "alignItems": "center",
      "justifyContent": "flex-end",
      "gap": "0.5rem"
    }} data-testid="buttons-container">
        <Button label="Done" onClick={onConfirm} type={ButtonType.SOLID} />
      </div>
    </div>
  </Modal>;
export default ApiKeyModal;