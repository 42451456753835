export enum FeatureFlags {
  CODE_SANDBOX = 'CODE_SANDBOX',
  CONNECTABLE_INTEGRATIONS = 'CONNECTABLE_INTEGRATIONS',
  DEEPGRAM_SDK = 'DEEPGRAM_SDK',
  DUAL_STORAGE = 'DUAL_STORAGE',
  FEATURE_FLAGGED_LLMS = 'FEATURE_FLAGGED_LLMS',
  GOOGLE_CLOUD_MARKETPLACE = 'GOOGLE_CLOUD_MARKETPLACE',
  HIDE_PUBLIC_WORKFLOWS = 'HIDE_PUBLIC_WORKFLOWS',
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  MERGE_WEBHOOK_INGESTION = 'MERGE_WEBHOOK_INGESTION',
  PUSHER_STREAMING = 'PUSHER_STREAMING',
  SELF_MANAGED_MODELS = 'SELF_MANAGED_MODELS',
  SELF_MANAGED_MODELS_CRUD = 'SELF_MANAGED_MODELS_CRUD',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  STRIPE = 'STRIPE',
  SYSLOG_AUDIT_LOG = 'SYSLOG_AUDIT_LOG',
  TRIGGER_AUTOMATED_WORKFLOW_ASYNC = 'TRIGGER_AUTOMATED_WORKFLOW_ASYNC',
  UNLIMITED_CREDIT_USAGE = 'UNLIMITED_CREDIT_USAGE',
  URL_SCRAPE_TOOL = 'URL_SCRAPE_TOOL',
  USER_GROUPS = 'USER_GROUPS',
  WEB_SEARCH_TOOL = 'WEB_SEARCH_TOOL',
  YOUTUBE_DOWNLOAD = 'YOUTUBE_DOWNLOAD'
}
