import { ALL_WORKFLOW_LIST_SCOPE_FILTERS, FeatureFlags, isWorkflowCategory, isWorkflowListScopeFilter, WorkflowCategory, WorkflowListAccessScopeFilter, WorkflowListScopeFilter, WorkflowListSort, WorkflowListTypeFilter } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { useState } from 'react';
import { WORKFLOW_TABLE_SOURCE_LABELS, WORKFLOW_TYPE_FILTER_TO_TAB_LABEL } from './AllWorkflowsTable.consts';
import WorkflowsTable from './WorkflowsTable';
import { FiltersProps, WorkflowFilter, WorkflowFiltersValues } from '~/components/shared/Filters';
import { TableSort } from '~/components/SortHeader/SortHeader';
import { nextTrpc } from '~/trpc';
const DEFAULT_WORKFLOW_FILTER_LABEL = 'All';
const AllWorkflowsTable: React.FC = () => {
  // State
  const [activeTableFilters, setActiveTableFilters] = useState<WorkflowFiltersValues>({
    [WorkflowFilter.CATEGORY]: undefined,
    // WorkflowCategory
    [WorkflowFilter.SCOPE]: undefined // WorkflowListScopeFilter
  });
  const [tableSearchValue, setTableSearchValue] = useState<string>('');
  const [activeSort, setActiveSort] = useState<TableSort<WorkflowListSort>>({
    key: WorkflowListSort.CREATED_AT,
    direction: 'desc'
  });
  const [activeTabView, setActiveTabView] = useState<WorkflowListTypeFilter>(WorkflowListTypeFilter.ALL);
  const activeScopeFilter: WorkflowListScopeFilter | undefined = activeTableFilters.SCOPE && isWorkflowListScopeFilter(activeTableFilters.SCOPE) ? activeTableFilters.SCOPE : undefined;
  const activeTableFilterCategory: WorkflowCategory | undefined = activeTableFilters.CATEGORY && isWorkflowCategory(activeTableFilters.CATEGORY) ? activeTableFilters.CATEGORY : undefined;

  // Queries
  const {
    data: workflowsData,
    isLoading,
    refetch: refetchWorkflows
  } = nextTrpc.workflows.list.useQuery({
    sort: activeSort.key,
    sortDesc: activeSort.direction === 'desc',
    workflowScopeFilter: activeScopeFilter,
    category: activeTableFilterCategory,
    workflowTypeFilter: activeTabView
  });
  const workflows = workflowsData?.items ?? [];

  // TODO: Implement backend search
  // Filter table workflows by search value on title and creator
  const filteredWorkflows = workflows.filter(workflow => workflow.displayName?.toLowerCase().includes(tableSearchValue.toLowerCase()) || workflow.creator.name?.toLowerCase().includes(tableSearchValue.toLowerCase())) ?? [];
  const getWorkflowTableTitle = () => {
    if (activeTabView) {
      return `${WORKFLOW_TYPE_FILTER_TO_TAB_LABEL[activeTabView]}`;
    }
    return `${activeScopeFilter ? WORKFLOW_TABLE_SOURCE_LABELS[activeScopeFilter] : DEFAULT_WORKFLOW_FILTER_LABEL} agents`;
  };

  // Feature request from GT Edge
  // Hide public workflows for feature flagged orgs
  const hidePublicWorkflows = useFlag(FeatureFlags.HIDE_PUBLIC_WORKFLOWS);
  const workflowSourceOptions = hidePublicWorkflows ? Object.values(WorkflowListAccessScopeFilter) : ALL_WORKFLOW_LIST_SCOPE_FILTERS;
  const filterProps: FiltersProps<WorkflowFilter> = {
    filters: [{
      defaultLabel: 'All',
      filterKey: WorkflowFilter.SCOPE,
      options: workflowSourceOptions.map(filter => ({
        label: WORKFLOW_TABLE_SOURCE_LABELS[filter],
        value: filter
      })),
      placeholder: 'Source',
      prefix: 'Source: '
    }, {
      defaultLabel: 'All',
      filterKey: WorkflowFilter.CATEGORY,
      options: Object.values(WorkflowCategory).map(category => ({
        label: category,
        value: category
      })),
      placeholder: 'Category',
      prefix: 'Category: '
    }],
    filtersValues: activeTableFilters,
    searchValue: tableSearchValue,
    setFilters: setActiveTableFilters,
    setSearchValue: setTableSearchValue
  };
  const tabViewProps = {
    activeTab: activeTabView,
    setActiveTab: setActiveTabView,
    tabOptions: Object.values(WorkflowListTypeFilter).map(type => ({
      label: WORKFLOW_TYPE_FILTER_TO_TAB_LABEL[type],
      value: type
    }))
  };
  return <WorkflowsTable isLoading={isLoading} refetchWorkflows={refetchWorkflows} tableFilterProps={filterProps} tableSortProps={{
    activeSort,
    setActiveSort
  }} tableTabViewProps={tabViewProps} tableTitle={getWorkflowTableTitle()} workflows={filteredWorkflows} />;
};
export default AllWorkflowsTable;