import { Button, ButtonType, Icon, Icons, TextIconColor, Typography, TypographySize } from '~/components/core';
import { Content } from '~/types';
interface ShareStaticContentConfirmationProps {
  onClose: () => void;
  onConfirm: () => void;
  staticContent: Pick<Content, 'fileName'>[];
}
const ShareStaticContentConfirmation: React.FC<ShareStaticContentConfirmationProps> = ({
  staticContent,
  onClose,
  onConfirm
}) => <>
    <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3}>
      Share agent with included files
    </Typography>
    <Typography color={TextIconColor.SECONDARY}>
      The agent you are about to share includes the following files:
    </Typography>
    <div css={{
    "display": "grid",
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
  }} data-testid="share-static-content-items">
      {staticContent.map(content => <div css={{
      "display": "flex",
      "alignItems": "center",
      "gap": "0.25rem"
    }} data-testid="share-static-content-icon-and-name" key={content.fileName}>
          <Icons color={TextIconColor.PRIMARY} icon={Icon.FILE} />
          <Typography color={TextIconColor.PRIMARY}>
            {content.fileName}
          </Typography>
        </div>)}
    </div>
    <Typography color={TextIconColor.SECONDARY}>
      It is possible that the contents of these files could be exposed to a
      runner of this agent.
    </Typography>
    <Typography color={TextIconColor.SECONDARY}>
      Would you like to continue sharing this agent?
    </Typography>
    <div css={{
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "flex-end",
    "gap": "0.5rem"
  }} data-testid="share-static-content-buttons-container">
      <Button label="Cancel" onClick={onClose} type={ButtonType.OUTLINED} />
      <Button label="Share" onClick={onConfirm} type={ButtonType.SOLID} />
    </div>
  </>;
export default ShareStaticContentConfirmation;