import _styled from "styled-components";
import { Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Markdown } from '~/components/Markdown';
const Container = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
interface JsonViewerProps {
  data: string | object;
  isError?: boolean;
  title?: string;
}
const formatJson = (data: string | object): string => {
  try {
    if (typeof data === 'string') {
      // Try to parse the string as JSON
      const parsed = JSON.parse(data);
      return '```json\n' + JSON.stringify(parsed, null, 2) + '\n```';
    }
    // If it's an object, stringify it directly
    return '```json\n' + JSON.stringify(data, null, 2) + '\n```';
  } catch {
    // If parsing fails for a string, return it in a code block,
    // (we know it's a string and not an object since it failed to parse)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return '```\n' + (data as string) + '\n```';
  }
};
const JsonViewer: React.FC<JsonViewerProps> = ({
  title,
  data
}) => <Container>
    {title && <Typography font={TypographyFont.HEADING} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
        {title}
      </Typography>}
    <Markdown codeBlockCopyButton={false}>{formatJson(data)}</Markdown>
  </Container>;
export default JsonViewer;