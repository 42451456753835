import { Role } from '@kindo/universal';
import { useState } from 'react';
import SettingsSection from '../../../../SettingsSection';
import { ROLE_DISPLAY_NAMES, STATUS_DISPLAY_NAMES } from './OrgUserManagement.consts';
import { OrgMember, OrgUserFilter, OrgUserFiltersValues, UserStatus } from './OrgUserManagement.types';
import { Button, ButtonType, CalloutType, Icon, Icons, Select, Size, Table, TableRow, TextIconColor, ToolTip, Typography, TypographySize } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { BadgeColor, BadgeType } from '~/components/core/Badge/Badge.consts';
import InputButton from '~/components/core/InputButton/InputButton';
import Markdown from '~/components/Markdown/Markdown';
import { Filters, FiltersProps } from '~/components/shared';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants';
import { ToastType, useAppSelector, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { capitalizeFirstLetter } from '~/utils';
type RequiredField<T, K extends keyof T> = T & { [P in K]-?: T[P] };
const OrgUserManagement: React.FC = () => {
  // State
  const [inviteInputValue, setInviteInputValue] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState('');
  const [activeFilters, setActiveFilters] = useState<OrgUserFiltersValues>({
    [OrgUserFilter.ROLE]: undefined,
    [OrgUserFilter.STATUS]: undefined
  });

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    userCanManageOrganization,
    userCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();

  // Redux
  const {
    org,
    userId
  } = useAppSelector(state => state.user);

  // Queries
  const {
    data: orgMemberData,
    refetch: refetchOrgMembers,
    isLoading: loadingOrgMembers
  } = userCanManageOrganization ? nextTrpc.orgs.listUsersWithUserGroups.useQuery() : nextTrpc.orgs.listUsers.useQuery();
  const orgMembers = orgMemberData?.items ?? [];
  const {
    data: invitesData,
    refetch: refetchInvites,
    isLoading: sendingInvite
  } = nextTrpc.orgs.listInvites.useQuery();
  const pendingInvites = invitesData ?? [];

  // Mutations
  // TODO: Handle diff error types
  const inviteMutation = nextTrpc.orgs.invite.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Invite sent.',
        type: ToastType.SUCCESS
      });
      void refetchInvites();
      setInviteInputValue('');
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: error.data?.kindoErrorMessage || 'Failed to send one or more invites.',
        type: ToastType.ERROR
      });
    }
  });
  const updateUserRoleMutation = nextTrpc.orgs.updateUserRole.useMutation({
    onSuccess: () => {
      void refetchOrgMembers();
      enqueueToast({
        message: 'Member role updated.',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to update member role.',
        type: ToastType.ERROR
      });
    }
  });
  const updateInviteRoleMutation = nextTrpc.orgs.updateInvite.useMutation({
    onSuccess: () => {
      void refetchInvites();
      enqueueToast({
        message: 'Invite role updated.',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to update invite role.',
        type: ToastType.ERROR
      });
    }
  });
  const removeUserMutation = nextTrpc.orgs.removeUser.useMutation({
    onSuccess: () => {
      void refetchOrgMembers();
      enqueueToast({
        message: 'Member removed.',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to remove member.',
        type: ToastType.ERROR
      });
    }
  });
  const deleteInviteMutation = nextTrpc.orgs.deleteInvite.useMutation({
    onSuccess: () => {
      void refetchInvites();
      enqueueToast({
        message: 'Invite deleted.',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to delete invite.',
        type: ToastType.ERROR
      });
    }
  });

  // Handlers
  const handleSendInvite = () => {
    if (!inviteInputValue) {
      enqueueToast({
        message: 'Please enter an email address.',
        type: ToastType.ERROR
      });
      return;
    }

    /*
    Email validation logic:
      1. ^[^\s@]+: matches any char sequence that doesn't contain whitespace or @ at the beginning
      2. @[^\s@]+: matches @ followed by characters that aren't whitespace or @
      3. \.[^\s@]+$: ensures a dot (.) followed by at least one char at the end
    */
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Split comma-separated emails into an array, trim whitespace, validate emails, and deduplicate
    const emails = [...new Set(inviteInputValue.split(',').map(email => email.trim()).filter(email => validEmailRegex.test(email)))];
    if (emails.length === 0) {
      enqueueToast({
        message: 'Please enter at least one valid email address.',
        type: ToastType.ERROR
      });
      return;
    }
    const orgMembersEmails: string[] = [];
    emails.forEach(email => {
      if (orgMembers.some(member => member.email === email)) {
        orgMembersEmails.push(email);
        return;
      }
      inviteMutation.mutate({
        email,
        role: Role.Member
      });
    });
    if (orgMembersEmails.length > 0) {
      enqueueToast({
        message: `The following email addresses belong to existing organization members: ${orgMembersEmails.join(', ')}`,
        type: ToastType.ERROR
      });
    }
  };
  const handleUpdateUserRole = (memberUserId: string, role: Role) => {
    updateUserRoleMutation.mutate({
      userId: memberUserId,
      role
    });
  };
  const handleUpdateInviteRole = (inviteId: string, role: Role) => {
    updateInviteRoleMutation.mutate({
      inviteId,
      role
    });
  };
  const handleRemoveUser = (memberUserId: string) => {
    removeUserMutation.mutate({
      userId: memberUserId
    });
  };
  const handleDeleteInvite = (inviteId: string) => {
    deleteInviteMutation.mutate({
      inviteId
    });
  };
  const userRoleSelectOptions = (memberUserId: string) => [...Object.values(Role).map(role => ({
    value: role,
    label: capitalizeFirstLetter(role.toLowerCase())
  })), {
    label: 'Remove',
    value: 'REMOVE',
    destructive: true,
    onClick: () => handleRemoveUser(memberUserId)
  }];
  const inviteRoleSelectOptions = (inviteId: string) => [...Object.values(Role).map(role => ({
    value: role,
    label: capitalizeFirstLetter(role.toLowerCase())
  })), {
    label: 'Delete',
    value: 'DELETE',
    destructive: true,
    onClick: () => handleDeleteInvite(inviteId)
  }];
  const orgMemberRows: TableRow[] = orgMembers.map((member: OrgMember) => ({
    key: `org-member-row-${member.id}`,
    cells: [member.name ?? 'N/A', member.email, ...(userCanAccessSecuritySettings ? [<ToolTip content={member.userGroups?.sort().join(', ') || 'Not in any user group'} key={`org-member-group-${member.id}`}>
              <div css={{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "gap": "0.5rem"
      }} data-testid={`member-groups-icon-${member.id}`}>
                <Icons color={TextIconColor.SECONDARY} icon={Icon.PROFILE} />
              </div>
            </ToolTip>] : []), <Select buttonSize={Size.SMALL} disabled={!userCanManageOrganization || member.id === userId} key={`org-member-role-select-${member.id}`}
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={value => handleUpdateUserRole(member.id, value as Role)} options={userRoleSelectOptions(member.id)} placeholderLabel="Role" typographySize={TypographySize.X_SMALL} value={member.orgRole} />, <Badge color={BadgeColor.HIGHLIGHT} key={`org-member-badge-${member.id}`} label="ACTIVE" type={BadgeType.OUTLINE} />]
  }));
  const paywallInviteMembers = org?.paymentTierConfig.maxMembers === 1;

  // Check if any filters are active
  const hasFilters = Object.values(activeFilters).some(value => value) || !!searchFilter;

  // Apply filters
  const filteredOrgMemberRows = orgMemberRows.filter(row => {
    // Name search filter
    const nameMatches = !searchFilter || row.cells[0] && typeof row.cells[0] === 'string' && row.cells[0].toLowerCase().includes(searchFilter.toLowerCase());
    if (!nameMatches) return false;

    // Role filter
    if (activeFilters[OrgUserFilter.ROLE]) {
      const roleCell = row.cells[2];
      if (!roleCell || typeof roleCell !== 'object') return false;

      // Extract the value prop from the Select component
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const roleValue = (roleCell as React.ReactElement).props.value;
      if (roleValue !== activeFilters[OrgUserFilter.ROLE]) return false;
    }

    // Status filter - for members, always ACTIVE
    if (activeFilters[OrgUserFilter.STATUS] && activeFilters[OrgUserFilter.STATUS] !== UserStatus.ACTIVE) {
      return false;
    }
    return true;
  });
  const filteredPendingInviteRows = pendingInvites.map(invite => ({
    key: `pending-invite-row-${invite.id}`,
    textColor: TextIconColor.SECONDARY,
    cells: ['N/A', invite.email, ...(userCanAccessSecuritySettings ? [null] : []), <Select buttonSize={Size.SMALL} disabled={!userCanManageOrganization} key={`pending-invite-role-select-${invite.id}`}
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={value => handleUpdateInviteRole(invite.id, value as Role)} options={inviteRoleSelectOptions(invite.id)} placeholderLabel="Role" typographySize={TypographySize.X_SMALL} value={invite.role} />, <Badge color={BadgeColor.WARNING} key={`pending-invite-badge-${invite.id}`} label="PENDING" type={BadgeType.OUTLINE} />]
  })).filter(row => {
    // Apply role filter to pending invites
    if (activeFilters[OrgUserFilter.ROLE]) {
      const roleCell = row.cells[2];
      if (!roleCell || typeof roleCell !== 'object') return false;

      // Extract the value prop from the Select component
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const roleValue = (roleCell as React.ReactElement).props.value;
      if (roleValue !== activeFilters[OrgUserFilter.ROLE]) return false;
    }

    // Status filter
    if (activeFilters[OrgUserFilter.STATUS] && activeFilters[OrgUserFilter.STATUS] !== UserStatus.PENDING) {
      return false;
    }

    // Don't show pending invites when searching by name
    return !searchFilter;
  });
  const rows: TableRow[] = [...filteredPendingInviteRows, ...filteredOrgMemberRows];
  const isSsoEnabled = !!org?.workosOrgId;
  const paywallInviteMembersText = '> Upgrade your plan to invite additional users to your organization. To inquire about upgrading, please contact sales.';

  // User count message logic
  const totalUserCount = orgMembers.length;
  const displayedUserCount = rows.length;
  const userCountMessage = hasFilters ? `${displayedUserCount} ${displayedUserCount === 1 ? 'user' : 'users'} displayed` : `${totalUserCount} ${totalUserCount === 1 ? 'user' : 'users'} total`;

  // Filter options
  const filterProps: RequiredField<FiltersProps<OrgUserFilter>, 'setSearchValue'> = {
    filters: [{
      prefix: 'Role: ',
      defaultLabel: 'All',
      filterKey: OrgUserFilter.ROLE,
      options: Object.values(Role).map(role => ({
        label: ROLE_DISPLAY_NAMES[role],
        value: role
      })),
      placeholder: ''
    }, {
      prefix: 'Status: ',
      defaultLabel: 'All',
      filterKey: OrgUserFilter.STATUS,
      options: Object.values(UserStatus).map(status => ({
        label: STATUS_DISPLAY_NAMES[status],
        value: status
      })),
      placeholder: ''
    }],
    filtersValues: activeFilters,
    searchValue: searchFilter,
    setFilters: setActiveFilters,
    setSearchValue: setSearchFilter
  };
  return <SettingsSection title={`Organization Members (${totalUserCount})`}>
      {!!paywallInviteMembers && <Markdown calloutType={CalloutType.INFO} endElement={<Button href={MARKETING_WEBSITE_CONTACT_URL} label="Contact Sales" type={ButtonType.SOLID} />}>
          {paywallInviteMembersText}
        </Markdown>}

      <div css={{
      "marginBottom": "1rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      ">p": {
        "marginRight": "1rem",
        "flexGrow": "1"
      }
    }} data-testid="org-users-description">
        <Typography>
          {isSsoEnabled ? 'Active members of the organization. SSO is enabled for your organization. Please contact your SSO administrator to manage organization members.' : 'Active and invited members of the organization.'}
        </Typography>

        {!isSsoEnabled && <InputButton buttonLabel="Send Invite" disabled={paywallInviteMembers || !userCanManageOrganization} loading={sendingInvite} onChange={setInviteInputValue} onSubmit={handleSendInvite} placeholder={userCanManageOrganization ? 'Enter an email to invite...' : 'You do not have permission to invite...'} value={inviteInputValue} />}
      </div>

      <div css={{
      "marginBottom": "1rem"
    }} data-testid="org-users-search">
        <Filters filters={filterProps.filters} filtersValues={filterProps.filtersValues} searchPlaceholder="Search by name" searchValue={filterProps.searchValue} setFilters={filterProps.setFilters} setSearchValue={filterProps.setSearchValue} />
      </div>

      <div css={{
      "marginTop": "1rem",
      "display": "flex",
      "flexDirection": "column"
    }} data-testid="org-members-table">
        <Table clearFilterProps={{
        hasFilters,
        label: 'Clear all filters',
        onClick: () => {
          setActiveFilters({
            [OrgUserFilter.ROLE]: undefined,
            [OrgUserFilter.STATUS]: undefined
          });
          setSearchFilter('');
        }
      }} columns={[{
        title: 'Name'
      }, {
        title: 'Email'
      }, ...(userCanAccessSecuritySettings ? [{
        title: 'Groups'
      }] : []), {
        title: 'Role'
      }, {
        title: 'Status'
      }]} loading={loadingOrgMembers} noRowsText="No members found." rows={rows} />
        <div css={{
        "marginTop": "0.5rem",
        "display": "flex",
        "justifyContent": "flex-end"
      }} data-testid="org-users-count">
          <Typography color={TextIconColor.SECONDARY} italic size={TypographySize.X_SMALL}>
            {userCountMessage}
          </Typography>
        </div>
      </div>
    </SettingsSection>;
};
export default OrgUserManagement;