import {
  ApiActionHeaderValue,
  isSecretHeaderValue,
  WorkflowStepType
} from '@kindo/universal';

import {
  isIntegrationActionWorkflowStep,
  isLlmWorkflowStep,
  Workflow,
  WorkflowStep
} from '~/types';

/**
 * Check if a workflow contains API steps with secrets that will be replaced with placeholders when duplicated
 */
export const checkForApiSecretsInWorkflow = (workflow: Workflow): boolean => {
  if (!workflow.steps) return false;

  return workflow.steps.some((step) => {
    if (step.type !== WorkflowStepType.API_ACTION) return false;
    return Object.values(step.headers).some(isSecretHeaderValue);
  });
};

/**
 * Checks if a step's headers contain SECRET_PLACEHOLDER values
 */
export const hasSecretPlaceholdersInHeaders = (
  headers: Record<string, ApiActionHeaderValue | null> | null
): boolean => {
  if (!headers) return false;

  return Object.values(headers).some((value) => {
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some(
        (innerValue) => innerValue === '[SECRET_PLACEHOLDER]'
      );
    }
    return value === '[SECRET_PLACEHOLDER]';
  });
};

/**
 * Checks if an API action step contains SECRET_PLACEHOLDER values
 */
export const isApiStepWithSecretPlaceholders = (step: WorkflowStep): boolean =>
  step.type === WorkflowStepType.API_ACTION &&
  hasSecretPlaceholdersInHeaders(step.headers);

/**
 * Check if a workflow contains steps with SECRET_PLACEHOLDER values
 */
export const checkForSecretPlaceholders = (workflow: Workflow): boolean => {
  if (!workflow.steps) return false;
  return workflow.steps.some(isApiStepWithSecretPlaceholders);
};

/**
 * Get steps that contain SECRET_PLACEHOLDER values
 */
export const getStepsWithSecretPlaceholders = (
  workflow: Workflow
): number[] => {
  if (!workflow.steps) return [];

  return workflow.steps
    .filter(isApiStepWithSecretPlaceholders)
    .map((step) => step.stepNumber);
};

/**
 * Check if a workflow contains static content (files)
 */
export const hasStaticContent = (workflow: Workflow): boolean => {
  // Check for workflow-level content
  if (!!workflow.knowledgeStoreContent?.length) {
    return true;
  }

  // Check for step-level static content
  return workflow.steps.some(
    (step) => isLlmWorkflowStep(step) && !!step.staticContent.length
  );
};

/**
 * Check if the current user has all required integrations for a workflow
 * @param workflow The workflow to check
 * @param connectedIntegrations List of integrations connected by the user
 * @param isLoadingIntegrations Whether integrations are still loading
 * @returns true if the user has all required integrations, false otherwise
 */
export const checkIntegrationRequirements = (
  workflow: Workflow | undefined,
  connectedIntegrations: { integrationName: string }[] | undefined,
  isLoadingIntegrations: boolean
): boolean => {
  if (!workflow || !connectedIntegrations || isLoadingIntegrations)
    return false;

  const triggerIntegrationNames = new Set<string>(
    (workflow.triggers || []).map((trigger) => trigger.integrationName)
  );

  const integrationNames = new Set<string>(
    workflow.steps
      .filter(isIntegrationActionWorkflowStep)
      .map((step) => step.integration)
  );

  const userIntegrationNames = new Set<string>(
    connectedIntegrations.map((integration) => integration.integrationName)
  );

  const missingTriggerIntegrations = [...triggerIntegrationNames].filter(
    (name) => !userIntegrationNames.has(name)
  );

  const missingActionIntegrations = [...integrationNames].filter(
    (name) => !userIntegrationNames.has(name)
  );

  return (
    missingTriggerIntegrations.length === 0 &&
    missingActionIntegrations.length === 0
  );
};
