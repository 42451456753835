/* eslint-disable react/jsx-props-no-spreading */
import { SUPPORTED_EXECUTABLE_LANGUAGES } from '@kindo/universal';
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';
import { Button, ButtonType, Callout, CalloutType, Icon, Size, TextIconColor, Typography, TypographySize, TypographyWeight } from '../core';
import { TypographyCasing, TypographyFont, TypographyWrap } from '../core/Typography';
import { CopyButton } from '../shared';
import { kindoCodeTheme } from './Markdown.styles';
import { convertTableToTSV, saveTableAsCSV } from './Markdown.utils';
import { RunCodeButton } from './RunCodeButton';
import { ThinkContentSection } from './ThinkContentSection';
type MarkdownProps = {
  children: string;
  calloutType?: CalloutType;
  chatMessageId?: string;
  codeBlockCopyButton?: boolean;
  codeBlockTitle?: string;
  endElement?: React.ReactNode;
  isStreaming?: boolean;
  styleDocsComponents?: boolean;
  textColor?: TextIconColor;
};
const getMarkdownComponents = ({
  chatMessageId,
  codeBlockTitle,
  textColor,
  styleDocsComponents,
  isStreaming,
  calloutType,
  endElement,
  codeBlockCopyButton
}: {
  calloutType: CalloutType;
  chatMessageId: string | undefined;
  codeBlockCopyButton: boolean;
  codeBlockTitle: string | undefined;
  endElement: React.ReactNode | undefined;
  isStreaming: boolean;
  styleDocsComponents: boolean;
  textColor: TextIconColor | undefined;
}): Partial<Components> => ({
  p: ({
    ...props
  }) => <Typography {...props as any} color={TextIconColor.SECONDARY} size={TypographySize.SMALL} />,
  // Treat H4-H6 as medium text (same as H3), the LLM is instructed not to use H4-H6
  h6: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD} />,
  h5: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD} />,
  h4: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  // The LLM has been instructed to return H1-H3 for headings
  h3: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h2: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.LARGE} weight={TypographyWeight.SEMI_BOLD} />,
  h1: ({
    ...props
  }) => <Typography {...props as any} color={textColor} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} />,
  ul: ({
    ...props
  }) => <ul {...props as any} css={{
    "listStylePosition": "outside",
    "listStyleType": "disc",
    "paddingLeft": "0.5rem"
  }} />,
  ol: ({
    ...props
  }) => <ol {...props as any} css={{
    "listStylePosition": "outside",
    "listStyleType": "decimal",
    "paddingLeft": "0.5rem"
  }} />,
  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-shadow
  li: ({
    children,
    ...props
  }) => <li css={{
    "marginBottom": "0.25rem",
    "marginLeft": "1.5rem",
    "&::marker": {
      "fontSize": "0.75rem",
      "lineHeight": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgb(150 148 181 / var(--tw-text-opacity))"
    }
  }} {...props as any}>
      <Typography color={textColor} size={TypographySize.SMALL}>
        {children}
      </Typography>
    </li>,
  // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  code: ({
    node,
    className,
    children,
    ref,
    ...props
  }) => {
    const codeIndex = 'data-code-index' in props && typeof props['data-code-index'] === 'number' ? props['data-code-index'] : undefined;
    const codeLanguage = 'data-code-language' in props && typeof props['data-code-language'] === 'string' ? props['data-code-language'] : undefined;
    if (codeLanguage === 'think-tag' && typeof children === 'string') {
      return <ThinkContentSection isStreaming={isStreaming} thinkContent={children} />;
    }

    // If the className is undefined, it is a generic inline code snippet
    if (!className) {
      if (typeof children === 'string' && children.includes('\n')) {
        return <code css={{
          "display": "block",
          "overflowX": "auto",
          "borderRadius": "0.25rem",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
          "padding": "0.5rem",
          "--tw-text-opacity": "1",
          "color": "rgb(146 193 251 / var(--tw-text-opacity))"
        }} data-testid="generic-inline-code" {...props}>
            {children}
          </code>;
      }
      return <code css={{
        "marginLeft": "0.125rem",
        "marginRight": "0.125rem",
        "display": "inline-block",
        "borderRadius": "0.25rem",
        "borderWidth": "1px",
        "borderStyle": "dashed",
        "borderColor": "#e6c2ff1a",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
        "padding": "0.375rem",
        "--tw-text-opacity": "1",
        "color": "rgb(146 193 251 / var(--tw-text-opacity))"
      }} data-testid="markdown-inline-code">
          {children}
        </code>;
    }
    const title = codeBlockTitle || codeLanguage;

    // If the className is defined, pass the language to the syntax highlighter
    const match = /language-(\w+)/.exec(className || '');
    return <>
        <div css={{
        "display": "flex",
        "flexDirection": "row",
        "alignItems": "center",
        "justifyContent": "space-between",
        "gap": "10px",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-x-reverse": "0",
          "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
          "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
          "borderStyle": "dashed",
          "--tw-divide-opacity": "1",
          "borderColor": "rgb(52 45 137 / var(--tw-divide-opacity))"
        },
        "borderTopLeftRadius": "0.25rem",
        "borderTopRightRadius": "0.25rem",
        "borderWidth": "1px",
        "borderStyle": "dashed",
        "--tw-border-opacity": "1",
        "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))"
      }} data-testid="markdown-code-header">
          {title && !styleDocsComponents && <div css={{
          "display": "flex",
          "paddingLeft": "0.5rem",
          "paddingRight": "0.5rem"
        }} data-testid="markdown-code-header-title-container">
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                {title}
              </Typography>
            </div>}
          <div css={{
          "display": "flex",
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-divide-x-reverse": "0",
            "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
            "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
            "borderStyle": "dashed",
            "--tw-divide-opacity": "1",
            "borderColor": "rgb(52 45 137 / var(--tw-divide-opacity))"
          }
        }} data-testid="markdown-code-header-buttons-container">
            <div css={{
            "display": "flex",
            "gap": "0.5rem",
            "paddingLeft": "0.5rem",
            "paddingRight": "0.5rem",
            "paddingTop": "0.25rem",
            "paddingBottom": "0.25rem"
          }} data-testid="markdown-code-header-button-container">
              {codeBlockCopyButton && <CopyButton buttonType={ButtonType.TEXT} disabled={isStreaming} getText={() => typeof children === 'string' ? children : ''} size={Size.SMALL} textIconColor={TextIconColor.SECONDARY_HIGHLIGHT} typographySize={styleDocsComponents ? TypographySize.X_SMALL : TypographySize.SMALL} />}
            </div>

            <RunCodeButton chatMessageId={chatMessageId} codeIndex={codeIndex} disabled={isStreaming} textIconColor={TextIconColor.SECONDARY_HIGHLIGHT} />
          </div>
        </div>
        <SyntaxHighlighter {...props} PreTag="div" data-testid="markdown-syntax-highlighter" language={match ? match[1] : undefined} ref={ref as any} style={kindoCodeTheme}>
          {typeof children === 'string' ? children.replace(/\n$/, '') : ''}
        </SyntaxHighlighter>
      </>;
  },
  a: ({
    children,
    href
  }) => <Typography color={TextIconColor.HIGHLIGHT} href={href ?? ''} size={TypographySize.SMALL} underline>
      {children}
    </Typography>,
  // Markdown table styling
  table: ({
    node,
    children
  }) => styleDocsComponents ? <table css={{
    "overflow": "hidden",
    "borderRadius": "0.375rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))"
  }} data-testid="markdown-styled-table">
        {children}
      </table> : <div css={{
    "borderRadius": "0.25rem",
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))"
  }} data-testid="markdown-table-container">
        <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "space-between",
      "gap": "10px",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-x-reverse": "0",
        "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
        "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
        "borderStyle": "dashed",
        "--tw-divide-opacity": "1",
        "borderColor": "rgb(52 45 137 / var(--tw-divide-opacity))"
      },
      "borderTopLeftRadius": "0.25rem",
      "borderTopRightRadius": "0.25rem",
      "borderBottomWidth": "1px",
      "borderStyle": "dashed",
      "--tw-border-opacity": "1",
      "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))"
    }} data-testid="table-markdown-header-container">
          <div css={{
        "display": "flex",
        "paddingLeft": "0.5rem",
        "paddingRight": "0.5rem"
      }} data-testid="table-header-title-container">
            <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
              CSV
            </Typography>
          </div>
          <div css={{
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-x-reverse": "0",
          "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
          "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
          "borderStyle": "dashed",
          "--tw-divide-opacity": "1",
          "borderColor": "rgb(52 45 137 / var(--tw-divide-opacity))"
        }
      }} data-testid="table-header-buttons-container">
            <div css={{
          "display": "flex",
          "gap": "0.5rem",
          "paddingLeft": "0.5rem",
          "paddingRight": "0.5rem",
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        }} data-testid="table-header-button-container-copy">
              <CopyButton buttonType={ButtonType.TEXT} disabled={isStreaming} getText={() => convertTableToTSV(node)} label="Copy" size={Size.SMALL} textIconColor={TextIconColor.SECONDARY_HIGHLIGHT} />
            </div>
            <div css={{
          "display": "flex",
          "gap": "0.5rem",
          "paddingLeft": "0.5rem",
          "paddingRight": "0.5rem",
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        }} data-testid="table-header-button-container-download">
              <Button disabled={isStreaming} endIcon={Icon.DOWNLOAD} label="Download CSV" onClick={() => saveTableAsCSV(convertTableToTSV(node))} size={Size.SMALL} textIconColor={TextIconColor.SECONDARY_HIGHLIGHT} type={ButtonType.TEXT} />
            </div>
          </div>
        </div>
        <div css={{
      "overflowX": "auto",
      "borderRadius": "0.25rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem"
    }} data-testid="markdown-table-container">
          <table css={{
        "width": "100%"
      }}>{children}</table>
        </div>
      </div>,
  th: ({
    children
  }) => styleDocsComponents ? <th css={{
    "borderBottomWidth": "1px",
    "borderRightWidth": "1px",
    "borderColor": "#e6c2ff1a",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "&:last-child": {
      "borderRightWidth": "0px"
    }
  }}>
        <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM} wrap={TypographyWrap.NO_WRAP}>
          {children}
        </Typography>
      </th> : <th css={{
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "textAlign": "left"
  }}>
        <Typography>{children}</Typography>
      </th>,
  td: ({
    children
  }) => styleDocsComponents ? <td css={{
    "borderBottomWidth": "1px",
    "borderRightWidth": "1px",
    "borderColor": "#e6c2ff1a",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(13 14 25 / var(--tw-bg-opacity))",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "&:last-child": {
      "borderRightWidth": "0px"
    },
    "tr:last-child>&": {
      "borderBottomWidth": "0px"
    }
  }}>
        <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
          {children}
        </Typography>
      </td> : <td css={{
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "--tw-text-opacity": "1",
    "color": "rgb(150 148 181 / var(--tw-text-opacity))"
  }}>{children}</td>,
  blockquote: ({
    children
  }) => <Callout endElement={endElement} type={calloutType}>
      {children}
    </Callout>,
  div: ({
    children,
    ...props
  }) => <div {...props}>{children}</div>
});
const Markdown: React.FC<MarkdownProps> = ({
  chatMessageId,
  children,
  textColor,
  codeBlockTitle,
  styleDocsComponents = false,
  isStreaming = false,
  calloutType = CalloutType.INFO,
  endElement = undefined,
  codeBlockCopyButton = true
}) => {
  const thinkReplacedChildren = children.replace(/<think>/g, '```think-tag\n').replace(/<\/think>/g, '\n```');
  return <div data-testid="markdown-component">
      <ReactMarkdown components={getMarkdownComponents({
      calloutType,
      codeBlockTitle,
      textColor,
      styleDocsComponents,
      chatMessageId,
      isStreaming,
      endElement,
      codeBlockCopyButton
    })} rehypePlugins={[function modifyCodeBlock() {
      let currentIndex = 0;
      return tree => {
        visit(tree, {
          tagName: 'code'
        }, node => {
          const properties = node.properties || {};
          const className = properties.className || [];
          const language = className.find((cls: string) => cls.startsWith('language-'))?.replace('language-', '');
          properties['data-code-language'] = language;
          const isSupportedLanguage = language && SUPPORTED_EXECUTABLE_LANGUAGES.includes(language);
          if (isSupportedLanguage) {
            properties['data-code-index'] = currentIndex;
            currentIndex += 1;
          }
          Object.assign(node, {
            properties
          });
        });
      };
    }]} remarkPlugins={[remarkGfm]}>
        {thinkReplacedChildren}
      </ReactMarkdown>
    </div>;
};
export default Markdown;