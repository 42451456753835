import { ChatErrorCode } from '../errorCodes.consts';

import { isLlm, Llm, SupportedLlm } from './llm.consts';

/**
 * Categorizations of LLMs by their primary use cases and characteristics
 * We need to determine Kindo's actual recommendations and update them over time
 */
export enum ModelCategory {
  CYBERSECURITY_FOCUSED = 'CYBERSECURITY_FOCUSED',
  GENERAL_PURPOSE = 'GENERAL_PURPOSE',
  LONG_CONTEXT = 'LONG_CONTEXT',
  MULTIMODAL = 'MULTIMODAL',
  REASONING = 'REASONING'
}

// General purpose models suitable for a wide range of tasks
export const GENERAL_PURPOSE_LLMS = [
  Llm.CLAUDE_3_7_SONNET,
  Llm.CLAUDE_3_5_SONNET,
  Llm.GPT_4O,
  Llm.LLAMA_3_3_70B
] as const satisfies readonly SupportedLlm[];

// Models with extended context length (32k+ tokens)
export const LONG_CONTEXT_LLMS = [
  Llm.GEMINI_2_0_FLASH,
  Llm.GEMINI_1_5_PRO,
  Llm.CLAUDE_3_OPUS
] as const satisfies readonly SupportedLlm[];

// Reasoning models
export const REASONING_LLMS = [
  Llm.O3_MINI,
  Llm.DEEPSEEK_R1,
  Llm.O1
] as const satisfies readonly SupportedLlm[];

// Models specialized for code and security analysis
export const CYBERSECURITY_FOCUSED_LLMS = [
  Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  Llm.WHITERABBITNEO_33B
] as const satisfies readonly SupportedLlm[];

// Models capable of multimodal interactions (text + images)
export const MULTIMODAL_LLMS = [
  Llm.GEMINI_2_0_FLASH,
  Llm.GEMINI_1_5_PRO
] as const satisfies readonly SupportedLlm[];

// Type definitions for the categories
export type GeneralPurposeLlm = (typeof GENERAL_PURPOSE_LLMS)[number];
export type LongContextLlm = (typeof LONG_CONTEXT_LLMS)[number];
export type ReasoningLlm = (typeof REASONING_LLMS)[number];
export type CybersecurityFocusedLlm =
  (typeof CYBERSECURITY_FOCUSED_LLMS)[number];
export type MultimodalLlm = (typeof MULTIMODAL_LLMS)[number];

// Helper functions to check if an LLM belongs to a category
export function isGeneralPurposeLlm(llm: string): llm is GeneralPurposeLlm {
  return isLlm(llm) && GENERAL_PURPOSE_LLMS.includes(llm as GeneralPurposeLlm);
}

export function isLongContextLlm(llm: string): llm is LongContextLlm {
  return isLlm(llm) && LONG_CONTEXT_LLMS.includes(llm as LongContextLlm);
}

export function isReasoningLlm(llm: string): llm is ReasoningLlm {
  return isLlm(llm) && REASONING_LLMS.includes(llm as ReasoningLlm);
}

export function isCybersecurityFocusedLlm(
  llm: string
): llm is CybersecurityFocusedLlm {
  return (
    isLlm(llm) &&
    CYBERSECURITY_FOCUSED_LLMS.includes(llm as CybersecurityFocusedLlm)
  );
}

export function isMultimodalLlm(llm: string): llm is MultimodalLlm {
  return isLlm(llm) && MULTIMODAL_LLMS.includes(llm as MultimodalLlm);
}

export function isModelCategory(value: string): value is ModelCategory {
  return Object.values(ModelCategory).includes(value as ModelCategory);
}

export const getRecommendedModelForError = (
  errorCode: string,
  currentModel: string,
  enabledModelIdentifiers: string[],
  isKindoError: boolean
): SupportedLlm | null => {
  // Don't recommend the same model
  const filterCurrentModel = <T extends Llm>(models: readonly T[]) =>
    models.filter(
      (model) =>
        model !== currentModel && enabledModelIdentifiers.includes(model)
    );

  if (!isKindoError) {
    const availableModels = filterCurrentModel(GENERAL_PURPOSE_LLMS);
    return availableModels[0] || null;
  }

  // Handle specific Kindo error codes
  if (errorCode === ChatErrorCode.CHAT_CONTEXT_WINDOW_EXCEEDED) {
    const availableModels = filterCurrentModel(LONG_CONTEXT_LLMS);
    return availableModels[0] || null;
  }

  if (errorCode === ChatErrorCode.CHAT_RATE_LIMITED) {
    const availableModels = filterCurrentModel(GENERAL_PURPOSE_LLMS);
    return availableModels[0] || null;
  }

  if (errorCode === ChatErrorCode.CHAT_CONTENT_FILTERED) {
    // For content filtered, for now try a different general purpose model
    // In the future we may want an uncensored category
    const availableModels = filterCurrentModel(GENERAL_PURPOSE_LLMS);
    return availableModels[0] || null;
  }

  if (errorCode === ChatErrorCode.CHAT_UNSUPPORTED_FILE_TYPE) {
    const availableModels = filterCurrentModel(MULTIMODAL_LLMS);
    return availableModels[0] || null;
  }

  if (errorCode === ChatErrorCode.CHAT_INSUFFICIENT_CREDITS) {
    // For content filtered, for now try a different general purpose model
    // Later this could be chosen from a new category, small models
    const availableModels = filterCurrentModel(GENERAL_PURPOSE_LLMS);
    return availableModels[0] || null;
  }

  const defaultModels = filterCurrentModel(GENERAL_PURPOSE_LLMS);
  return defaultModels[0] || null;
};
