import { tv } from 'tailwind-variants';

// Simple styles defined and exported here bc they're used in multiple components
export const baseWorkflowCardContainerStyles = 'flex min-w-[750px] flex-col items-start self-stretch rounded-[9px] px-10 pb-8 pt-6';
export const defaultWorkflowCardContainerStyles = 'bg-primary-modal-bg border border-white/10 overflow-y-auto';
export const darkGlowWorkflowCardContainerStyles = tv({
  base: [baseWorkflowCardContainerStyles, 'relative bg-black before:absolute before:-inset-[1px] before:-z-10 before:rounded-[9px] before:transition-opacity before:duration-300 before:bg-diagonal-corners before:from-primary-glow'],
  variants: {
    useOnHoverOnly: {
      true: 'border border-white/10 transition-[border] duration-300 before:opacity-0 hover:border-0 hover:before:opacity-100'
    }
  },
  compoundVariants: [{
    useOnHoverOnly: true,
    isActive: true,
    class: 'border-0 before:opacity-100'
  }]
});