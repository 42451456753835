import { OrgPaymentTier } from '@kindo/universal';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { PaymentTierCard } from './PaymentTierCard';
import ConfirmationModal from '~/components/shared/ConfirmationModal/ConfirmationModal';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants/routes.consts';
import { ToastType, useAppSelector, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const PaymentTiers: React.FC<{
  userCanManageOrganization: boolean | undefined;
}> = ({
  userCanManageOrganization
}) => {
  const router = useRouter();
  const {
    org
  } = useAppSelector(state => state.user);
  const activeTier = org?.paymentTier || OrgPaymentTier.FREE;
  const [showDowngradeConfirmation, setShowDowngradeConfirmation] = useState(false);
  const {
    enqueueToast
  } = useToast();
  const createOrgUpgradeCheckoutSession = nextTrpc.payments.createOrgUpgradeCheckoutSession.useMutation({
    onSuccess: session => router.push(session.checkoutSessionUrl),
    onError: e => {
      enqueueToast({
        type: ToastType.ERROR,
        message: e.data?.kindoErrorMessage || 'Failed to create checkout session. Please try again and contact support if it continues.'
      });
    }
  });
  const cancelOrgSubscriptionAndDowngrade = nextTrpc.payments.cancelOrgSubscriptionAndDowngrade.useMutation({
    onSuccess: () => {
      enqueueToast({
        type: ToastType.SUCCESS,
        message: "Successfully cancelled subscription. You'll be downgraded at the end of your current cycle."
      });
    },
    onError: e => {
      enqueueToast({
        type: ToastType.ERROR,
        message: e.data?.kindoErrorMessage || 'Failed to downgrade. Please try again and contact support if it continues.'
      });
    }
  });
  const handleGetUpgradeCheckoutSession = () => {
    createOrgUpgradeCheckoutSession.mutate({
      newPaymentTier: OrgPaymentTier.PRO
    });
  };
  const handleCancelOrgSubscriptionAndDowngrade = () => {
    cancelOrgSubscriptionAndDowngrade.mutate();
    setShowDowngradeConfirmation(false);
  };
  const handleOrgPaymentTierSelect = (selectedTier: OrgPaymentTier): void => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (true) {
      /** If Org is already on the Enterprise tier  */
      // Redirect to contact page since no self-service downgrade is available
      case activeTier === OrgPaymentTier.ENTERPRISE || selectedTier === OrgPaymentTier.ENTERPRISE:
        void router.push(MARKETING_WEBSITE_CONTACT_URL);
        break;

      /** Otherwise, Org is on either Free or Pro tier */

      // If they are trying to downgrade to Free (from Pro),
      // show confirmation popup then redirect to cancel subscription page
      case selectedTier === OrgPaymentTier.FREE:
        setShowDowngradeConfirmation(true);
        break;

      // If they are trying to upgrade to Pro (from Free), redirect to checkout
      case selectedTier === OrgPaymentTier.PRO:
        handleGetUpgradeCheckoutSession();
        break;
      default:
        console.error('Unhandled case in handleOrgPaymentTierSelect', activeTier, selectedTier);
    }
  };
  const orderedTiers = [OrgPaymentTier.FREE, OrgPaymentTier.PRO, OrgPaymentTier.ENTERPRISE];
  return <div css={{
    "display": "flex",
    "width": "100%",
    "gap": "2rem",
    "paddingLeft": "6rem",
    "paddingRight": "6rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  }} data-testid="payment-tiers-container">
      <ConfirmationModal header="Are you sure you want to downgrade?" onCancel={() => setShowDowngradeConfirmation(false)} onConfirm={handleCancelOrgSubscriptionAndDowngrade} open={showDowngradeConfirmation} subtext="Your subscription will remain active until the end of the month. At that time you'll be downgraded to our Free tier." />
      {orderedTiers.map(tier =>
    // If the user is not on Pro, don't show the pro tier card
    !(activeTier !== OrgPaymentTier.PRO && tier === OrgPaymentTier.PRO) && <PaymentTierCard activeTier={activeTier} key={tier} onSelect={() => {
      void handleOrgPaymentTierSelect(tier);
    }} tier={tier} userCanManageOrganization={userCanManageOrganization} />)}
    </div>;
};
export default PaymentTiers;