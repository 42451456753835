import { Llm, WorkflowStepType } from '@kindo/universal';
import React from 'react';
import { tv } from 'tailwind-variants';
import { WORKFLOW_STEP_TYPE_BADGE_LABELS } from '../WorkflowBuilderCanvas.utils';
import { Icon, IconButton, IconButtonType, Icons, Size, TextField, TextFieldType, TextIconColor, ToolTip, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { TypographyWrap } from '~/components/core/Typography';
import { ApiStepGenerationModal } from '~/components/modals';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useAppDispatch, useGetEnabledModels, useGetWorkflowFromUrlSlug, useToast } from '~/hooks';
import useDeleteWorkflowStep from '~/hooks/useDeleteWorkflowStep';
import useUserInfo from '~/hooks/useUserInfo';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import { BuilderWorkflowStatus, BuilderWorkflowStep, Workflow, WorkflowStep } from '~/types';
import { isApiActionWorkflowBuilderStep } from '~/types/workflowBuilder.types';
const WORKFLOW_STEP_TYPE_NEW_STEP_NAME = {
  [WorkflowStepType.LLM]: 'New LLM Step',
  [WorkflowStepType.INTEGRATION_ACTION]: 'New Action Step',
  [WorkflowStepType.API_ACTION]: 'New API Action Step'
};
const EqualSign = () => <div css={{
  "position": "relative",
  "display": "flex",
  "height": "1.25rem",
  "width": "1.25rem",
  "flexDirection": "column",
  "justifyContent": "center",
  "gap": "0.375rem"
}} data-testid="equal-sign-symbol">
    <div css={{
    "height": "2px",
    "width": "100%",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(150 148 181 / var(--tw-bg-opacity))"
  }} />
    <div css={{
    "height": "2px",
    "width": "100%",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(150 148 181 / var(--tw-bg-opacity))"
  }} />
  </div>;
const workflowStepHeaderContainerStyles = tv({
  base: 'relative flex w-full items-center justify-between gap-3',
  variants: {
    isDisabled: {
      true: 'cursor-not-allowed opacity-70'
    }
  },
  compoundVariants: [{
    isDisabled: false,
    className: 'cursor-pointer'
  }]
});
const isWorkflowStep = (step: WorkflowStep | BuilderWorkflowStep): step is WorkflowStep => 'workflowId' in step;
interface WorkflowStepHeaderProps {
  activeStep: BuilderWorkflowStep | null;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  step: WorkflowStep | BuilderWorkflowStep;
  workflow: Workflow;
  hasSecretPlaceholders?: boolean;
  warningTooltipText?: string;
}
const WorkflowStepHeader: React.FC<WorkflowStepHeaderProps> = ({
  activeStep,
  step,
  refetchWorkflow,
  workflow,
  hasSecretPlaceholders = false,
  warningTooltipText
}) => {
  // Destructure props
  const {
    stepNumber
  } = step;
  const totalSteps = workflow.steps.length;
  const isFirstStep = stepNumber === 1;
  const isLastStep = activeStep?.status === BuilderWorkflowStatus.NEW ? stepNumber === totalSteps + 1 : stepNumber === totalSteps;
  const isCurrentStepActiveStep = activeStep?.id === step.id;
  const hasOtherActiveStep = activeStep !== null && !isCurrentStepActiveStep;
  const isNewStep = isCurrentStepActiveStep && activeStep?.status === BuilderWorkflowStatus.NEW;
  const defaultNewStepName = WORKFLOW_STEP_TYPE_NEW_STEP_NAME[step.type];
  const stepDisplayName = isNewStep ? defaultNewStepName : step.displayName || 'Untitled step';

  // State
  const [displayNameValue, setDisplayNameValue] = React.useState<string>(stepDisplayName);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState<boolean>(false);
  const [showApiGenerationModal, setShowApiGenerationModal] = React.useState<boolean>(false);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    user
  } = useUserInfo();
  const {
    deleteWorkflowStep,
    isDeleting
  } = useDeleteWorkflowStep(step.id, refetchWorkflow);

  // Queries
  const {
    enabledModels
  } = useGetEnabledModels();
  const isApiActionStep = step.type === WorkflowStepType.API_ACTION;
  const isCreator = user?.id === workflow.creator.id;
  const isEditDisabled = isApiActionStep && !isCreator;
  const canUseGenerationModal = isApiActionStep && enabledModels.some(model => model.identifier === Llm.CLAUDE_3_7_SONNET);

  // Redux
  const dispatch = useAppDispatch();
  const editStep = (stepToEdit: WorkflowStep) => dispatch(workflowBuilderActions.setExistingStepActive(stepToEdit));
  const handleMoveStepUp = () => {
    if (isFirstStep || hasOtherActiveStep) return;
    // Make step active before moving it
    if (!isCurrentStepActiveStep && isWorkflowStep(step)) {
      editStep(step);
    }
    dispatch(workflowBuilderActions.updateActiveStepStepNumber({
      stepNumber: step.stepNumber - 1
    }));
  };
  const handleMoveStepDown = () => {
    if (isLastStep || hasOtherActiveStep) return;
    // Make step active before moving it
    if (!isCurrentStepActiveStep && isWorkflowStep(step)) {
      editStep(step);
    }
    dispatch(workflowBuilderActions.updateActiveStepStepNumber({
      stepNumber: step.stepNumber + 1
    }));
  };

  // TRPC Mutations
  const editLlmStepMutation = nextTrpc.workflows.editLlmStep.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'LLM step name updated.',
        type: ToastType.SUCCESS
      });
      refetchWorkflow();
    },
    onError: error => {
      console.error('Failed to update LLM step name: ', error);
      enqueueToast({
        message: 'Failed to update LLM step name.',
        type: ToastType.ERROR
      });
    }
  });
  const editIntegrationActionStepMutation = nextTrpc.workflows.editIntegrationActionStep.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Action step name updated.',
        type: ToastType.SUCCESS
      });
      refetchWorkflow();
    },
    onError: error => {
      console.error('Failed to update action step name: ', error);
      enqueueToast({
        message: 'Failed to update action step name.',
        type: ToastType.ERROR
      });
    }
  });
  const editApiActionStepMutation = nextTrpc.workflows.editApiActionStep.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'API Action step name updated.',
        type: ToastType.SUCCESS
      });
      refetchWorkflow();
    },
    onError: error => {
      console.error('Failed to update API Action step name: ', error);
      enqueueToast({
        message: 'Failed to update API Action step name.',
        type: ToastType.ERROR
      });
    }
  });
  const saveStepDisplayName = () => {
    if (isNewStep) {
      enqueueToast({
        message: 'Please save the step before editing the display name.',
        type: ToastType.ERROR
      });
      setDisplayNameValue(stepDisplayName);
      return;
    }

    // Trimmed display name or previous display name if empty
    const trimmedDisplayName = displayNameValue.trim() || stepDisplayName;
    if (trimmedDisplayName !== stepDisplayName) {
      if (step.type === WorkflowStepType.LLM) {
        editLlmStepMutation.mutate({
          id: step.id,
          displayName: trimmedDisplayName
        });
      }
      if (step.type === WorkflowStepType.INTEGRATION_ACTION) {
        editIntegrationActionStepMutation.mutate({
          id: step.id,
          displayName: trimmedDisplayName
        });
      }
      if (step.type === WorkflowStepType.API_ACTION) {
        editApiActionStepMutation.mutate({
          id: step.id,
          displayName: trimmedDisplayName
        });
      }
    }
    setDisplayNameValue(trimmedDisplayName);
  };
  const handleDeleteStep = async () => {
    await deleteWorkflowStep();
    setShowConfirmDeleteModal(false);
  };
  const handleActiveBuilderStepToggle = (currentStep: WorkflowStep | BuilderWorkflowStep) => {
    if (!isWorkflowStep(currentStep)) {
      return;
    }
    if (isCurrentStepActiveStep) {
      dispatch(workflowBuilderActions.clearActiveStep());
      return;
    }
    // This needs a saved step because it's used for expansion/collapse
    editStep(currentStep);
  };
  const getApiGenerationModalTooltip = () => {
    if (!isCreator) {
      return 'Only the agent creator can edit API Action steps';
    }
    if (!canUseGenerationModal) {
      return 'API generation requires Claude 3.7 Sonnet to be enabled for your organization';
    }
    return 'Generate API request values with a description';
  };
  return <>
      <ToolTip content={isEditDisabled ? 'Only the agent creator can edit API Action steps' : undefined}>
        <div className={workflowStepHeaderContainerStyles()} data-testid="workflow-step-header-container" onClick={() => handleActiveBuilderStepToggle(step)}>
          <div css={{
          "display": "flex",
          "width": "100%",
          "flexDirection": "row",
          "alignItems": "center",
          "gap": "1rem"
        }} data-testid="step-number-and-display-name-container">
            <div css={{
            "display": "flex",
            "alignItems": "center",
            "gap": "1rem"
          }} data-testid="step-number-container">
              {/* Step controls */}
              <div css={{
              "display": "flex",
              "flexDirection": "column",
              "alignItems": "center"
            }} data-testid="step-controls-container">
                <IconButton color={TextIconColor.PRIMARY} disabled={isFirstStep || hasOtherActiveStep} highlightOnHover icon={Icon.CHEVRON_UP} onClick={handleMoveStepUp} size={Size.SMALL} type={IconButtonType.SIMPLE} />
                <EqualSign />
                <IconButton color={TextIconColor.PRIMARY} disabled={isLastStep || hasOtherActiveStep} highlightOnHover icon={Icon.CHEVRON_DOWN} onClick={handleMoveStepDown} size={Size.SMALL} type={IconButtonType.SIMPLE} />
              </div>
              {/* Workflow step number */}
              <Typography color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.LARGE} weight={TypographyWeight.SEMI_BOLD} wrap={TypographyWrap.NO_WRAP}>
                {stepNumber.toString()}
              </Typography>
            </div>
            {/* Workflow step type */}
            <Badge active={false} label={WORKFLOW_STEP_TYPE_BADGE_LABELS[step.type]} />
            {/* Warning icon for steps with SECRET_PLACEHOLDER values */}
            {hasSecretPlaceholders && <div css={{
            "marginLeft": "0.25rem"
          }}>
                <ToolTip content={warningTooltipText || 'This step contains secret placeholders that need to be replaced'}>
                  <span>
                    <Icons color={TextIconColor.WARNING} icon={Icon.WARNING} size={Size.MEDIUM} />
                  </span>
                </ToolTip>
              </div>}
            {/* Workflow step display name */}
            {/* Wrapper prevents text field clicking from toggling open/close */}
            <div css={{
            "width": "75%"
          }} data-testid="text-field-container" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <TextField animateStartIcon disabled={isEditDisabled} fullWidth onBlur={saveStepDisplayName} onChange={setDisplayNameValue} showEndIcon={false} showFocusBottomBorder size={TypographySize.MEDIUM} startIcon={!isNewStep ? Icon.EDIT : undefined} type={TextFieldType.SIMPLE} value={displayNameValue} />
            </div>
          </div>
          {isApiActionStep && isCurrentStepActiveStep && <IconButton disabled={!isCreator || !canUseGenerationModal} icon={Icon.SPARKLES} onClick={() => {
          setShowApiGenerationModal(true);
        }} size={Size.MEDIUM} tooltip={getApiGenerationModalTooltip()} type={IconButtonType.SOLID_COLOR} />}
          {isWorkflowStep(step) && <IconButton color={TextIconColor.PRIMARY} highlightOnHover icon={isCurrentStepActiveStep ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} onClick={() => handleActiveBuilderStepToggle(step)} size={Size.LARGE} type={IconButtonType.SIMPLE} />}
        </div>
      </ToolTip>
      <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={isDeleting} header="Are you sure you want to delete this step?" onCancel={() => setShowConfirmDeleteModal(false)} onConfirm={handleDeleteStep} open={showConfirmDeleteModal} subtext="A deleted step can not be recovered." />
      {isApiActionStep && <ApiStepGenerationModal initialValues={isCurrentStepActiveStep && isApiActionWorkflowBuilderStep(activeStep) ? {
      method: activeStep.method,
      endpoint: activeStep.endpoint,
      headers: activeStep.headers,
      parameters: activeStep.parameters ?? undefined,
      body: activeStep.body ? JSON.parse(activeStep.body) : undefined
    } : null} onClose={() => setShowApiGenerationModal(false)} onGenerate={generatedValues => {
      if (!isCurrentStepActiveStep) {
        enqueueToast({
          message: 'Please select this step before updating it.',
          type: ToastType.ERROR
        });
        setShowApiGenerationModal(false);
        return;
      }
      dispatch(workflowBuilderActions.updateApiActionStep(generatedValues));
    }} open={showApiGenerationModal} />}
    </>;
};
export default WorkflowStepHeader;