import _styled from "styled-components";
import { isIntegrationWorkflowInputType } from '@kindo/universal';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { $insertNodes, COMMAND_PRIORITY_LOW, COPY_COMMAND, DecoratorNode, EditorState } from 'lexical';
import { useEffect } from 'react';
import { $createWorkflowStaticContentNode, $createWorkflowUserInputNode, WorkflowStaticContentNode, WorkflowUserInputNode } from './WorkflowLexicalNodes';
import { isSerializedWorkflowStaticContentNode, isSerializedWorkflowUserInputNode, parseEditorStateToPromptMustacheTemplate, parsePromptMustacheTemplateToEditorState, SerializedWorkflowStepPromptEditorLeafNode, WorkflowStepPromptEditorRoot } from './WorkflowStepPromptEditor.utils';
import { TextIconColor, Typography, TypographySize } from '~/components/core';
import { useAppSelector } from '~/hooks';
import { NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE, NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE } from '~/redux/reducers/workflowBuilderSlice';
import { BuilderWorkflowStepStaticContent, PromptTemplateWorkflowBuilderStepInput } from '~/types';
const WorkflowStepPromptEditorContainer = _styled.div({
  "position": "relative",
  "width": "100%"
});
const StyledContentEditable = _styled(ContentEditable)({
  "position": "relative",
  "zIndex": "1",
  "width": "100%",
  "paddingLeft": "0.25rem",
  "paddingRight": "0.25rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
});
const Placeholder = _styled.span({
  "position": "absolute",
  "left": "0.25rem",
  "top": "0.25rem",
  "zIndex": "0"
});
interface WorkflowStepPromptEditorProps {
  prompt: string;
  setPrompt: (value: string) => void;
  staticContent: BuilderWorkflowStepStaticContent[];
  userInputs: PromptTemplateWorkflowBuilderStepInput[];
}

/**
 * This plugin monitors the redux store for changes to the prompt,
 * and updates the editor accordingly.
 */
const LexicalUpdateOnReduxChangePlugin = ({
  prompt
}: {
  prompt: string;
}): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  /**
   * This function creates a new empty node for all empty mustache templates in the prompt
   * that match the given regex and do not already have a node in the editor.
   */
  const createNodesForEmptyTemplates = <Node extends DecoratorNode<React.ReactElement>,>(templateRegex: RegExp, isEmptyNode: (node: SerializedWorkflowStepPromptEditorLeafNode) => boolean, createNode: () => Node) => {
    //  Get all empty mustache templates in the prompt
    const emptyMustacheTemplates = prompt?.match(templateRegex) ?? [];
    const emptyMustacheTemplateCount = emptyMustacheTemplates.length;
    const root = editor.getEditorState().toJSON().root as WorkflowStepPromptEditorRoot;

    // Get all empty nodes in the editor
    const existingEmptyNodes = root.children[0]?.children?.filter(node => isEmptyNode(node));

    // Add new empty node if needed
    if (existingEmptyNodes && existingEmptyNodes.length < emptyMustacheTemplateCount) {
      editor.update(() => {
        const newEmptyNode = createNode();
        $insertNodes([newEmptyNode]);
      });
    }
  };

  /**
   * This use effect creates a newly added node for UserInput and StaticContent
   *
   * When a user presses the "Add User Input" button,
   * it adds a new empty mustache template to the prompt to be created here.
   * The same goes for Static Content.
   */
  useEffect(() => {
    // User Input
    createNodesForEmptyTemplates(new RegExp(`{{\\s*?${NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE}\\s*?}}`, 'g'), serializedNode => isSerializedWorkflowUserInputNode(serializedNode) && !serializedNode.templateResolutionName, () => $createWorkflowUserInputNode({
      templateResolutionName: '',
      displayName: ''
    }));

    // Static Content
    createNodesForEmptyTemplates(new RegExp(`{{\\s*?${NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE}\\s*?}}`, 'g'), serializedNode => isSerializedWorkflowStaticContentNode(serializedNode) && !serializedNode.contentId, () => $createWorkflowStaticContentNode({
      contentId: '',
      contentName: ''
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt]);
  return null;
};

/**
 * This plugin performs a custom copy command that copies the selected text to the clipboard.
 * Implemented as a solution for [ENG-3928]
 * Refs:
 * https://lexical.dev/docs/concepts/commands
 * https://lexical.dev/docs/api/modules/lexical#copy_command
 */
const CustomCopyPlugin = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => editor.registerCommand(COPY_COMMAND, () => {
    const selection = window.getSelection();
    if (selection) {
      const content = selection.toString();
      navigator.clipboard.writeText(content).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
    return true;
  }, COMMAND_PRIORITY_LOW), [editor]);
  return null;
};

/**
 * This component is build on top of the text-editor library lexical.
 * Before changing, please read the documentation below:
 *
 * https://lexical.dev/docs/getting-started/react
 *
 * Additional helpful tutorial:
 *  - https://www.youtube.com/watch?v=qIqxvk2qcmo
 */
const WorkflowStepPromptEditor: React.FC<WorkflowStepPromptEditorProps> = ({
  prompt,
  setPrompt,
  staticContent,
  userInputs
}) => {
  const {
    activeStep
  } = useAppSelector(state => state.workflowBuilder);
  const hasIntegrationInput = activeStep?.inputs.some(input => isIntegrationWorkflowInputType(input.type));

  // Parse editor state to prompt mustache template and send to update prop
  function handleEditorChange(editorState: EditorState) {
    const updatedPrompt = parseEditorStateToPromptMustacheTemplate(editorState);
    setPrompt(updatedPrompt);
  }

  // Lexical initial config
  const initialConfig = {
    namespace: 'WorkflowStepPromptEditor',
    onError: (error: Error) => {
      console.error(error);
    },
    theme: {},
    editorState: () => parsePromptMustacheTemplateToEditorState(prompt || '', userInputs, staticContent),
    nodes: [WorkflowUserInputNode, WorkflowStaticContentNode]
  };
  return <WorkflowStepPromptEditorContainer>
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextPlugin ErrorBoundary={LexicalErrorBoundary} contentEditable={<StyledContentEditable />} placeholder={<Placeholder>
              <Typography color={TextIconColor.DISABLED} size={TypographySize.SMALL}>
                {hasIntegrationInput ? 'Ask AI to analyze this input...' : 'Start typing a prompt...'}
              </Typography>
            </Placeholder>} />
        {/* https://lexical.dev/docs/react/plugins#lexicalonchangeplugin */}
        <OnChangePlugin onChange={state => handleEditorChange(state)} />
        <LexicalUpdateOnReduxChangePlugin prompt={prompt} />
        <AutoFocusPlugin />
        <CustomCopyPlugin />
        <HistoryPlugin />
      </LexicalComposer>
    </WorkflowStepPromptEditorContainer>;
};
export default WorkflowStepPromptEditor;