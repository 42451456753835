import { FiltersValues } from '~/components/shared/Filters/Filters';
export interface AuditEventSummaryRow {
  key: string;
  link?: {
    label: string;
    route: string;
    type: AuditLogLinkType;
  };
  metadataDisplay?: React.ReactNode;
  value?: string;
}
export enum AuditLogLinkType {
  AGENT_BUILDER = 'AGENT_BUILDER',
  CHAT = 'CHAT',
}
export enum AuditLogFilter {
  EVENT_TYPE = 'EVENT_TYPE',
  USER_ID = 'USER_ID',
}
export type AuditLogFiltersValues = FiltersValues<AuditLogFilter>;