import { CYBERSECURITY_FOCUSED_LLMS, ExternalLlm, GENERAL_PURPOSE_LLMS, LONG_CONTEXT_LLMS, ModelCategory, MULTIMODAL_LLMS, REASONING_LLMS } from '@kindo/universal';

// Map model categories to their display titles
export const MODEL_CATEGORY_TITLES: Record<ModelCategory, string> = {
  [ModelCategory.GENERAL_PURPOSE]: 'General Purpose',
  [ModelCategory.LONG_CONTEXT]: 'Longer Context',
  [ModelCategory.REASONING]: 'Reasoning',
  [ModelCategory.CYBERSECURITY_FOCUSED]: 'Cybersecurity Focused',
  [ModelCategory.MULTIMODAL]: 'Multimodal'
};

// Map model categories to their descriptions
export const MODEL_CATEGORY_DESCRIPTIONS: Record<ModelCategory, string> = {
  [ModelCategory.GENERAL_PURPOSE]: "Kindo's recommended default models. Best for everyday tasks and conversations.",
  [ModelCategory.LONG_CONTEXT]: 'Handle extensive conversations and large documents. If general purpose models are struggling after a long back and forth, try these models.',
  [ModelCategory.REASONING]: 'Models that use chain-of-thought reasoning. Slower and use more tokens, but good for complex tasks that require deep thinking and problem-solving.',
  [ModelCategory.CYBERSECURITY_FOCUSED]: "WhiteRabbitNeo, Kindo's top-of-the-line series of cybersecurity models. Specialized in security and code analysis.",
  [ModelCategory.MULTIMODAL]: 'Models capable of understanding both text and images.'
};

// Map model categories to their available models
export const MODEL_CATEGORY_TO_MODELS: Record<ModelCategory, readonly ExternalLlm[]> = {
  [ModelCategory.GENERAL_PURPOSE]: GENERAL_PURPOSE_LLMS,
  [ModelCategory.LONG_CONTEXT]: LONG_CONTEXT_LLMS,
  [ModelCategory.REASONING]: REASONING_LLMS,
  [ModelCategory.CYBERSECURITY_FOCUSED]: CYBERSECURITY_FOCUSED_LLMS,
  [ModelCategory.MULTIMODAL]: MULTIMODAL_LLMS
};