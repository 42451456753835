import { tv } from 'tailwind-variants';
import { TEXT_COLOR_CLASSES, TextIconColor } from '~/components/core';
import { cn } from '~/utils/tailwind.utils';
export const cardContainerStyles = tv({
  base: 'py-6 transition-all duration-300 ease-in-out group',
  variants: {
    isExpanded: {
      true: 'py-9',
      false: 'hover:py-9'
    }
  }
});
export const learnMoreButtonStyles = ({
  isExpanded
}: {
  isExpanded?: boolean;
}) => cn(`hover:text-highlight ${TEXT_COLOR_CLASSES[TextIconColor.PRIMARY]} flex cursor-pointer items-center gap-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100`, isExpanded && 'opacity-100');
export const expandableContentStyles = tv({
  base: 'overflow-hidden transition-all duration-300 ease-in-out',
  variants: {
    isExpanded: {
      true: 'max-h-[500px] opacity-100',
      false: 'pointer-events-none max-h-0 opacity-0'
    }
  }
});