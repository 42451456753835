import _styled from "styled-components";
import { Popper } from '@mui/base/Popper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Using Day.js adapter
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonType } from '../Button';
import { TextIconColor } from '../constants';
import { Typography } from '../Typography';
const Container = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "borderRadius": "0.5rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
  "padding": "1rem",
  "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
});
const InputContainer = _styled.div({
  "marginTop": "0.75rem",
  "display": "flex",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(1rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(1rem * calc(1 - var(--tw-space-x-reverse)))"
  }
}); // Flexbox to align the inputs side by side
const ButtonGroup = _styled.div({
  "marginTop": "1rem",
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
  }
});
const ButtonContainer = _styled.div({
  "display": "flex",
  "gap": "0.5rem"
});
export interface DateTimeRange {
  endDate?: Date; // Optional Date, defaults to undefined
  startDate?: Date; // Optional Date, defaults to undefined
}
export interface DateTimeRangePickerProps {
  onDateRangeChange: (dateRange: DateTimeRange) => void;
  value: DateTimeRange | undefined;
}
const dateTimePickerStyles = {
  '& .MuiInputBase-input': {
    color: 'white',
    fontFamily: 'var(--font-oxanium)'
  },
  '& .MuiInputLabel-root': {
    color: 'white',
    fontFamily: 'var(--font-oxanium)'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray'
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white'
  },
  '& .MuiSvgIcon-root': {
    color: 'white'
  },
  '& .MuiPickersPopper-root': {
    zIndex: 1002,
    '& .MuiTypography-root': {
      fontFamily: 'var(--font-oxanium)'
    },
    '& .MuiPickersDay-root': {
      fontFamily: 'var(--font-oxanium)'
    }
  }
};
const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  onDateRangeChange,
  value
}) => {
  const [validationError, setValidationError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayText, setDisplayText] = useState<string>('Select a time range');
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const validate = () => {
    let error = '';
    const startDate = startDateRef.current?.value ? new Date(startDateRef.current.value) : undefined;
    const endDate = endDateRef.current?.value ? new Date(endDateRef.current.value) : undefined;
    if (startDate && endDate && startDate > endDate) {
      error = 'End date cannot be before start date';
    }
    setValidationError(error);
    return error.length === 0;
  };
  const updateDisplayText = (dateRange: DateTimeRange) => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (true) {
      case !!(dateRange.startDate && dateRange.endDate):
        setDisplayText(`${dateRange.startDate.toLocaleString()} to ${dateRange.endDate.toLocaleString()}`);
        break;
      case !!(dateRange.startDate && !dateRange.endDate):
        setDisplayText(`${dateRange.startDate.toLocaleString()} to now`);
        break;
      case !!(!dateRange.startDate && dateRange.endDate):
        setDisplayText(`All events until ${dateRange.endDate.toLocaleString()}`);
        break;
      default:
        setDisplayText('Select a time range');
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;
    const dateRange: DateTimeRange = {
      ...(startDateRef.current?.value && {
        startDate: new Date(startDateRef.current.value)
      }),
      ...(endDateRef.current?.value && {
        endDate: new Date(endDateRef.current.value)
      })
    };
    updateDisplayText(dateRange);
    onDateRangeChange(dateRange);
    setOpen(false);
  };
  const handleClear = () => {
    if (startDateRef.current) {
      startDateRef.current.value = '';
    }
    if (endDateRef.current) {
      endDateRef.current.value = '';
    }
    setValidationError('');
    setDisplayText('Select a time range');
    setOpen(false);
    onDateRangeChange({});
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (value) {
      updateDisplayText(value);
    }
  }, [value]);
  return <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div css={{
      "zIndex": "1000"
    }}>
        <Button label={displayText} onClick={handleButtonClick} type={ButtonType.OUTLINED} />
        <Popper anchorEl={anchorEl} open={open} placement="bottom-start" style={{
        zIndex: 1001
      }}>
          <Container>
            <InputContainer>
              <DateTimePicker defaultValue={value?.startDate ? dayjs(value.startDate) : null} inputRef={startDateRef} label="Start Date" maxDate={dayjs()} sx={dateTimePickerStyles} />
              <DateTimePicker defaultValue={value?.endDate ? dayjs(value.endDate) : null} inputRef={endDateRef} label="End Date" maxDate={dayjs()} sx={dateTimePickerStyles} />
            </InputContainer>

            {validationError && <Typography color={TextIconColor.DESTRUCTIVE}>
                {validationError}
              </Typography>}

            <ButtonGroup>
              {/* Close Button */}
              <Button label="Close" onClick={handleClose} type={ButtonType.OUTLINED} />

              <ButtonContainer>
                {/* Clear Button */}
                <Button label="Clear" onClick={handleClear} type={ButtonType.OUTLINED} />

                {/* Apply Button */}
                <Button label="Apply" onClick={handleSubmit} type={ButtonType.SOLID} />
              </ButtonContainer>
            </ButtonGroup>
          </Container>
        </Popper>
      </div>
    </LocalizationProvider>;
};
export default DateTimeRangePicker;