import { cn } from '../../../utils/tailwind.utils';
import { Size, TextIconColor } from '../constants';
import { Icon, ICON_SVGS } from './Icon.consts';
import { iconStyles } from './Icons.styles';
export interface IconProps {
  color: TextIconColor | undefined; // Undefined color uses the original svg fill,
  icon: Icon;
  invert?: boolean;
  size?: Size | number | undefined;
}
const Icons: React.FC<IconProps> = ({
  icon,
  color,
  size = Size.MEDIUM,
  invert = false
}) => {
  const IconSVG = ICON_SVGS[icon];
  const iconClasses = iconStyles({
    size: typeof size === 'number' ? undefined : size,
    invert,
    color
  });
  const customSizeClasses = typeof size === 'number' ? {
    [`[&>svg]:w-[${size}px] [&>svg]:h-[${size}px]`]: true
  } : {};
  return <span className={cn(iconClasses, customSizeClasses)} id="icon-container">
      <IconSVG />
    </span>;
};
export default Icons;