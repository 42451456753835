import { filterExists } from '@kindo/universal';
import React from 'react';
import { getIntegrationInputFieldDisplayName, WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON } from '../../WorkflowIntegrationInput';
import WorkflowIntegrationInput from '../../WorkflowIntegrationInput/WorkflowIntegrationInput';
import { WorkflowStepPromptEditor } from '../WorkflowStepPromptEditor';
import { Button, ButtonType, Chip, Icon } from '~/components/core';
import { ModelSelectionModal } from '~/components/modals';
import { useAppDispatch } from '~/hooks';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { BuilderWorkflowLlmStep, IntegrationWorkflowBuilderStepInput, isIntegrationWorkflowBuilderStepInput, isIntegrationWorkflowInput, isPromptTemplateWorkflowBuilderStepInput, WorkflowInput } from '~/types';
interface WorkflowLlmStepEditorProps {
  setIntegrationInputActiveInEditor: (value: string) => void;
  step: BuilderWorkflowLlmStep;
  updateActiveStepModel: (model: string) => void; // DO NOT REMOVE COMMENT: model: SupportedLlm
  updateActiveStepPrompt: (prompt: string) => void;
  workflowInputs: WorkflowInput[];
}
const WorkflowLlmStepEditor: React.FC<WorkflowLlmStepEditorProps> = ({
  step,
  updateActiveStepPrompt,
  updateActiveStepModel,
  setIntegrationInputActiveInEditor,
  workflowInputs
}) => {
  // Redux
  const dispatch = useAppDispatch();
  const insertStaticContent = () => {
    dispatch(workflowBuilderActions.insertNewStaticContent());
  };
  const insertUserInput = () => {
    dispatch(workflowBuilderActions.insertNewUserInput());
  };

  // Currently, we only support one integration input per workflow
  const workflowIntegrationInput = workflowInputs.filter(isIntegrationWorkflowInput)[0] ?? null;
  const isTriggeredWorkflow = !!workflowIntegrationInput;
  const workflowStepIntegrationInputs: IntegrationWorkflowBuilderStepInput[] = step.inputs.filter(isIntegrationWorkflowBuilderStepInput).map(input => ({
    id: input.id,
    type: input.type,
    workflowId: input.workflowId,
    displayName: input.displayName,
    config: input.config,
    integrationName: input.integrationName
  }));
  const getFieldsTextForInputChip = (input: IntegrationWorkflowBuilderStepInput): string => {
    const specifiedFields = input.config?.specifiedFields;
    const specifiedFieldsLength = specifiedFields?.length;
    if (!specifiedFieldsLength) return '';
    switch (specifiedFieldsLength) {
      // Ex: ": Name" or ": Name and Email"
      case 1:
      case 2:
        {
          const fields = specifiedFields.slice(0, 2).filter(filterExists);
          return `: ${fields.map(field => getIntegrationInputFieldDisplayName(input.type, field)).join(' and ')}`;
        }
      // Ex: ": 3 fields"
      default:
        return `: ${specifiedFieldsLength} fields`;
    }
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1.5rem"
  }} data-testid="llm-step-editor-container">
      <div css={{
      "display": "flex",
      "justifyContent": "space-between"
    }} data-testid="header-container">
        {/* Workflow step model select */}
        <ModelSelectionModal chatModelIdentifier={step.model} onChange={updateActiveStepModel} placeholderLabel="Select AI Model" prefix="AI Model: " />
      </div>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "flex-start",
      "gap": "0.625rem",
      "alignSelf": "stretch",
      "borderRadius": "0.5rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))",
      "backgroundColor": "rgba(9, 9, 13, 0.50)",
      "padding": "1rem"
    }} data-testid="workflow-builder-input-container">
        <WorkflowStepPromptEditor prompt={step.promptTemplate} setPrompt={updateActiveStepPrompt} staticContent={step.staticContent} userInputs={step.inputs.filter(isPromptTemplateWorkflowBuilderStepInput)} />
        {/* Workflow step integration inputs */}
        {workflowStepIntegrationInputs.length > 0 && <div css={{
        "display": "flex",
        "height": "100%",
        "flexDirection": "row",
        "justifyContent": "flex-start",
        "gap": "0.25rem"
      }} data-testid="integration-input-chips-container">
            {workflowStepIntegrationInputs.map(input => <Chip key={input.id} label={`${input.displayName}${getFieldsTextForInputChip(input)}`} onClick={() => setIntegrationInputActiveInEditor(input.id)} startIcon={WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON[input.integrationName]} />)}
          </div>}
      </div>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "justifyContent": "space-between",
      "gap": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "@media (min-width: 768px)": {
        "flexDirection": "row"
      }
    }} data-testid="footer-container">
        <div css={{
        "display": "flex",
        "flexDirection": "column",
        "gap": "1rem",
        "@media (min-width: 768px)": {
          "flexDirection": "row"
        }
      }} data-testid="workflow-tools-container">
          {/* Add file */}
          <Button endIcon={Icon.UPLOAD} label="Add File" onClick={insertStaticContent} tooltip="Add a file to every run of the agent" type={ButtonType.SOLID} />
          {/* Integration input or human input */}
          {isTriggeredWorkflow ?
        // If a triggered workflow (i.e., an integration input is defined on the workflow),
        // then option to add integration input.
        <WorkflowIntegrationInput activeStep={step} workflowIntegrationInput={workflowIntegrationInput} /> :
        // Else if a manual workflow, then option to add user (i.e., human) input
        <Button endIcon={Icon.PLUS} label="Human input" onClick={insertUserInput} tooltip="Text or a file provided during a manual run of the agent by whoever is executing it." type={ButtonType.OUTLINED} />}
        </div>
      </div>
    </div>;
};
export default WorkflowLlmStepEditor;