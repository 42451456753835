import { WorkflowType } from '@kindo/universal';
import { useState } from 'react';
import { cn } from '../../../utils/tailwind.utils';
import { Button, ButtonType, Icon, Icons, Size, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../../core';
import { HugeIcons } from '../../core/HugeIcon';
import { TypographyWrap } from '../../core/Typography';
import { darkGlowWorkflowCardContainerStyles } from '../../WorkflowNavScreen/WorkflowNav.styles';
import { getFeatureStrListByWorkflowType, WORKFLOW_FEATURE_TO_DISPLAY_NAME, WORKFLOW_TYPE_TO_DESCRIPTION, WORKFLOW_TYPE_TO_ICON, WORKFLOW_TYPE_TO_USE_CASES } from './WorkflowCatalogue.consts';
import { cardContainerStyles, expandableContentStyles, learnMoreButtonStyles } from './WorkflowCatalogueCard.styles';
import { WORKFLOW_TYPE_TO_LABEL } from '~/constants/workflow.consts';
interface WorkflowCatalogueCardProps {
  onBuildClick: (workflowType: WorkflowType) => void;
  workflowType: WorkflowType;
}
const WorkflowCatalogueCard: React.FC<WorkflowCatalogueCardProps> = ({
  workflowType,
  onBuildClick
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    supported,
    unsupported
  } = getFeatureStrListByWorkflowType(workflowType);
  return <div className={cn(darkGlowWorkflowCardContainerStyles({
    useOnHoverOnly: true
  }), cardContainerStyles({
    isExpanded
  }))} data-testid="workflow-catalogue-card-container">
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexDirection": "row",
      "gap": "1.5rem"
    }} data-testid="workflow-catalogue-main-content">
        <HugeIcons icon={WORKFLOW_TYPE_TO_ICON[workflowType]} size={Size.LARGE} />
        <div css={{
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "space-between"
      }} data-testid="workflow-catalogue-text-button-container">
          <div css={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="workflow-catalogue-text-container">
            <Typography casing={TypographyCasing.CAPITALIZE} color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.H3} weight={TypographyWeight.NORMAL}>
              {WORKFLOW_TYPE_TO_LABEL[workflowType]} Agent
            </Typography>
            <Typography casing={TypographyCasing.NONE} color={TextIconColor.SECONDARY} font={TypographyFont.PARAGRAPH} size={TypographySize.MEDIUM} weight={TypographyWeight.NORMAL}>
              {WORKFLOW_TYPE_TO_DESCRIPTION[workflowType]}
            </Typography>
            <div className={learnMoreButtonStyles({
            isExpanded
          })} data-testid="workflow-catalogue-learn-more-button" onClick={() => setIsExpanded(!isExpanded)}>
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} size={TypographySize.X_SMALL} weight={TypographyWeight.SEMI_LIGHT}>
                Learn more
              </Typography>
              <Icons color={undefined} icon={Icon.DIRECTION_DOWN} invert={isExpanded} size={Size.MEDIUM} />
            </div>
          </div>
          <Button disabled={false} endIcon={Icon.BOLT} label="Build Agent" onClick={() => onBuildClick(workflowType)} type={ButtonType.OUTLINED} />
        </div>
      </div>
      <div className={expandableContentStyles({
      isExpanded
    })} data-testid="workflow-catalogue-expandable-content">
        <div css={{
        "display": "flex",
        "padding": "0.5rem",
        "paddingTop": "1rem"
      }} data-testid="workflow-catalogue-additional-specs">
          <div css={{
          "display": "flex",
          "width": "66.666667%",
          "flexDirection": "column",
          "gap": "0.25rem",
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-divide-y-reverse": "0",
            "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
            "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
            "borderColor": "rgb(255 255 255 / 0.1)"
          }
        }} data-testid="workflow-catalogue-possible-uses">
            <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
              Possible Uses
            </Typography>
            <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "0.25rem",
            "paddingTop": "1rem",
            "paddingBottom": "0.5rem"
          }}>
              {WORKFLOW_TYPE_TO_USE_CASES[workflowType].map(useCase => <Typography color={TextIconColor.SECONDARY} key={useCase.title} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                  {useCase.title}
                  <Typography color={TextIconColor.SECONDARY} key={useCase.description} size={TypographySize.SMALL}>
                    {useCase.description}
                  </Typography>
                </Typography>)}
            </div>
          </div>
          <div css={{
          "display": "flex",
          "width": "33.333333%",
          "flexDirection": "column",
          "gap": "0.25rem",
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-divide-y-reverse": "0",
            "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
            "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
            "borderColor": "rgb(255 255 255 / 0.1)"
          }
        }} data-testid="workflow-catalogue-attributes">
            <div css={{
            "display": "flex",
            "alignItems": "center",
            "gap": "0.5rem",
            "paddingLeft": "2rem"
          }}>
              <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} wrap={TypographyWrap.NORMAL}>
                Attributes
              </Typography>
            </div>
            <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "0.25rem",
            "paddingTop": "1rem",
            "paddingBottom": "0.5rem"
          }}>
              {supported.map(feature => <div css={{
              "display": "flex",
              "alignItems": "center",
              "gap": "0.5rem",
              "paddingLeft": "2rem"
            }} key={feature}>
                  <Icons color={TextIconColor.PRIMARY} icon={Icon.PLUS} size={Size.SMALL} />
                  <Typography casing={TypographyCasing.CAPITALIZE} color={TextIconColor.PRIMARY} key={feature} size={TypographySize.SMALL}>
                    {WORKFLOW_FEATURE_TO_DISPLAY_NAME[feature]}
                  </Typography>
                </div>)}
              {unsupported.map(feature => <div css={{
              "display": "flex",
              "alignItems": "center",
              "gap": "0.5rem",
              "paddingLeft": "2rem"
            }} key={feature}>
                  <Icons color={TextIconColor.SECONDARY} icon={Icon.MINUS} size={Size.SMALL} />
                  <Typography casing={TypographyCasing.CAPITALIZE} color={TextIconColor.SECONDARY} key={feature} size={TypographySize.X_SMALL}>
                    {WORKFLOW_FEATURE_TO_DISPLAY_NAME[feature]}
                  </Typography>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default WorkflowCatalogueCard;