import { WorkflowSupportedIntegration } from '@kindo/universal';

import { useAppDispatch } from './typedReduxHooks';

import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import {
  BuilderWorkflowStep,
  IntegrationWorkflowBuilderStepInput,
  isLlmWorkflowBuilderStep
} from '~/types';

interface UseActiveBuilderStepIntegrationInputArgs {
  activeStep: BuilderWorkflowStep | null;
}

interface UseActiveBuilderStepIntegrationInputReturnObject {
  addIntegrationInput: (value: IntegrationWorkflowBuilderStepInput) => void;
  clearIntegrationInputActiveInEditor: () => void;
  integrationInputActiveInEditor: IntegrationWorkflowBuilderStepInput | null;
  removeIntegrationInput: (value: string) => void;
  setIntegrationInputActiveInEditor: (value: string) => void;
  updateActiveStepIntegration: (value: WorkflowSupportedIntegration) => void;
}

/**
 * Custom hook for performing actions on or returning data about
 * integration step inputs associated with the active step in the workflow builder
 */
const useActiveBuilderStepIntegrationInput = ({
  activeStep
}: UseActiveBuilderStepIntegrationInputArgs): UseActiveBuilderStepIntegrationInputReturnObject => {
  // Redux
  const dispatch = useAppDispatch();

  // Exported functions
  // Functions for workflow action steps
  const updateActiveStepIntegration = (value: WorkflowSupportedIntegration) => {
    dispatch(
      workflowBuilderActions.updateActiveStepIntegration({
        integration: value
      })
    );
  };

  // Functions for workflow llm steps
  const setIntegrationInputActiveInEditor = (value: string) =>
    dispatch(
      workflowBuilderActions.setIntegrationInputActiveInEditor({
        inputId: value
      })
    );

  const clearIntegrationInputActiveInEditor = () =>
    dispatch(workflowBuilderActions.clearIntegrationInputActiveInEditor());

  const addIntegrationInput = (value: IntegrationWorkflowBuilderStepInput) =>
    dispatch(workflowBuilderActions.addIntegrationInput({ input: value }));

  const removeIntegrationInput = (value: string) =>
    dispatch(workflowBuilderActions.removeIntegrationInput({ inputId: value }));

  return {
    integrationInputActiveInEditor: isLlmWorkflowBuilderStep(activeStep)
      ? activeStep.integrationInputActiveInEditor
      : null,
    setIntegrationInputActiveInEditor,
    clearIntegrationInputActiveInEditor,
    updateActiveStepIntegration,
    addIntegrationInput,
    removeIntegrationInput
  };
};

export default useActiveBuilderStepIntegrationInput;
