import { ORG_SETTING_DISPLAY_NAMES, Role } from '@kindo/universal';
import { ButtonType, Select, Size, Table, TableRow, TextIconColor, Toggle, Typography, TypographySize } from '~/components/core';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { useCanAccessSecuritySettings, useOrgShareSettings } from '~/hooks';
const OrgSharingPermissions: React.FC = () => {
  // Custom hooks
  const {
    orgSettings,
    updateOrgShareSetting,
    isLoading
  } = useOrgShareSettings();
  const {
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const orgSharingRows: TableRow[] = orgSettings ? [{
    key: 'externalSharingEnabled',
    cells: [<div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.25rem"
    }} data-testid="external-sharing-text-container" key="externalSharingEnabled">
              <Typography>
                {ORG_SETTING_DISPLAY_NAMES.externalSharingEnabled}
              </Typography>
              <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                Control whether your organization's data can be shared with
                users outside of your organization.
              </Typography>
            </div>, <Toggle checked={orgSettings?.externalSharingEnabled === true} data-testid="external-sharing-toggle" disabled={!orgCanAccessSecuritySettings} key="externalSharingEnabled" onToggle={() => updateOrgShareSetting('externalSharingEnabled', !orgSettings?.externalSharingEnabled)} size={Size.SMALL} />]
  }, {
    key: 'minimumRoleForSharing',
    cells: [<div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.25rem"
    }} data-testid="minimum-role-text-container" key="minimumRoleForSharing">
              <Typography>
                {ORG_SETTING_DISPLAY_NAMES.minimumRoleForSharing}
              </Typography>
              <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                Control which users in your organization are allowed to share
                agents + chats.
              </Typography>
            </div>, <Select buttonSize={Size.X_SMALL} data-testid="minimum-role-select" hideChevron={false} key="minimumRoleForSharing" onChange={value => updateOrgShareSetting('minimumRoleForSharing', value)} options={[{
      value: Role.Admin,
      label: Role.Admin
    }, {
      value: Role.Member,
      label: Role.Member
    }]} placeholderLabel="Setting" type={ButtonType.OUTLINED} value={orgSettings?.minimumRoleForSharing ?? null} />]
  }] : [];
  return <div css={{
    "width": "100%"
  }} data-testid="org-sharing-permissions-container">
      <SettingsSection data-testid="sharing-permissions-section" description="Control the access and sharing of your organization's data." title="Sharing Permissions">
        <Table<string> columns={[{
        // Setting name
        title: ''
      }, {
        // Setting value
        title: '',
        width: {
          "width": "20%"
        }
      }]} data-testid="org-sharing-permissions-table" loading={isLoading} noRowsText={isLoading ? 'Loading...' : 'No settings found'} rows={orgSharingRows} />
      </SettingsSection>
    </div>;
};
export default OrgSharingPermissions;