import { tv } from 'tailwind-variants';
import { createEnumVariants } from '../../../utils/commonTailwindVariants.utils';
import { Size } from '../constants';
import { ToggleSize } from './Toggle.consts';
type ToggleSlots = 'root' | 'display' | 'innerBox' | 'loadingSpinner';
export const toggleStyles = tv({
  slots: {
    root: 'relative inline-flex cursor-pointer items-center',
    display: 'relative flex items-center overflow-hidden border bg-transparent transition-colors duration-100 border-white/10 hover:border-white/50',
    innerBox: 'flex items-center justify-center duration-toggle-transform transform transition-all ease-in-out',
    loadingSpinner: 'absolute top-0.5 flex h-5 w-5 items-center justify-center transition-all [&_span]:(animation[spin_1s_linear_infinite] z-1) @keyframes[spin]:(0%[transform:rotate(360deg)] 100%[transform:rotate(0deg)])'
  },
  variants: {
    checked: {
      true: {
        display: 'border-highlight hover:border-white',
        innerBox: 'bg-highlight shadow-toggle-active duration-toggle-shadow translate-x-[33px]',
        loadingSpinner: 'right-[2px]'
      },
      false: {
        innerBox: 'bg-white/10',
        loadingSpinner: 'right-[22px]'
      }
    },
    disabled: {
      true: {
        root: 'cursor-not-allowed',
        display: 'cursor-not-allowed border border-dashed border-white/10 hover:border-white/10'
      }
    },
    loading: {
      true: {
        display: 'after:flex after:items-center after:justify-center'
      }
    },
    size: createEnumVariants<typeof Size, ToggleSize, ToggleSlots>({
      [Size.SMALL]: {
        display: 'h-[22px] w-[74px] p-0.5 rounded',
        innerBox: 'h-4 w-[41px] rounded-sm'
      },
      [Size.MEDIUM]: {
        display: 'h-7 w-20 p-0.5 rounded-md',
        innerBox: 'h-[22px] w-[41px] rounded'
      },
      [Size.LARGE]: {
        display: 'h-10 w-20 p-0.5 rounded-md',
        innerBox: 'h-[34px] w-[41px] rounded'
      }
    })
  },
  compoundVariants: [{
    checked: true,
    disabled: true,
    class: {
      innerBox: 'bg-highlight/20 shadow-toggle-disabled'
    }
  }, {
    checked: true,
    size: Size.SMALL,
    class: {
      innerBox: 'translate-x-[27px]'
    }
  }, {
    checked: true,
    size: Size.MEDIUM,
    class: {
      innerBox: 'translate-x-[33px]'
    }
  }, {
    checked: true,
    size: Size.LARGE,
    class: {
      innerBox: 'translate-x-[33px]'
    }
  }]
});