import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import AddWorkflowStepButton from '../AddWorkflowStepButton';
import { WorkflowStepEditor } from '../WorkflowStepEditor';
import WorkflowStepHeader from '../WorkflowStepHeader/WorkflowStepHeader';
import { TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { useExecuteApiActionStep, useGetWorkflowFromUrlSlug } from '~/hooks';
import { BuilderWorkflowStep, Workflow, WorkflowStep } from '~/types';
const workflowStepHeaderAndEditorContainerStyles = tv({
  base: 'flex w-full flex-col gap-2 rounded-lg px-4 py-2',
  variants: {
    isActive: {
      true: 'bg-primary-modal-bg'
    }
  }
});
interface WorkflowTimelineProps {
  activeStep: BuilderWorkflowStep | null;
  isEnterpriseOrg: boolean;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
  stepsWithSecretPlaceholders?: number[];
}
const WorkflowTimeline: React.FC<WorkflowTimelineProps> = ({
  activeStep,
  refetchWorkflow,
  workflow,
  isEnterpriseOrg,
  stepsWithSecretPlaceholders = []
}) => {
  const [localStepsWithSecretPlaceholders, setLocalStepsWithSecretPlaceholders] = useState<number[]>(stepsWithSecretPlaceholders);
  useEffect(() => {
    setLocalStepsWithSecretPlaceholders(stepsWithSecretPlaceholders);
  }, [stepsWithSecretPlaceholders, workflow]);

  // This hook is rendered here and not in the WorkflowStepEditor component
  // because the WorkflowStepEditor component is re-rendered when the active step changes,
  // (i.e. when a new step is saved), which un-renders the hook.
  const {
    executeApiActionStepTest
  } = useExecuteApiActionStep();
  const workflowSteps = workflow?.steps ?? [];

  // Filter active step out of steps (if it is an existing step)
  const stepsWithoutActiveStep = workflowSteps.filter(step => step.id !== activeStep?.id);

  // Insert active step (if there is one)
  const stepsToRender = activeStep ? [...stepsWithoutActiveStep.slice(0, activeStep.stepNumber - 1), activeStep, ...stepsWithoutActiveStep.slice(activeStep.stepNumber - 1)] : [...workflowSteps];
  const isCurrentStepActiveStep = (step: WorkflowStep | BuilderWorkflowStep) => activeStep?.id === step.id || 'status' in step;
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "0px"
  }} data-testid="workflow-timeline-container">
      <div css={{
      "display": "flex",
      "height": "2rem",
      "width": "fit-content",
      "alignItems": "center",
      "justifyContent": "center",
      "gap": "0.5rem",
      "whiteSpace": "nowrap",
      "borderBottomRightRadius": "0px",
      "borderBottomLeftRadius": "0px",
      "borderTopLeftRadius": "3px",
      "borderTopRightRadius": "3px",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(72 38 253 / var(--tw-bg-opacity))",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem"
    }} data-testid="workflow-timeline-tag">
        <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
          Agent Steps
        </Typography>
      </div>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center",
      "gap": "1rem",
      "borderRadius": "0px 8px 8px 8px",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgb(37 39 69 / var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem",
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem"
    }} data-testid="workflow-steps-container">
        {!stepsToRender.length && <div css={{
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "gap": "0.5rem",
        "padding": "0.5rem"
      }} data-testid="empty-steps-message">
            <Typography color={TextIconColor.SECONDARY} italic>
              Click the + icon to begin adding steps.
            </Typography>
          </div>}
        <AddWorkflowStepButton disabled={!!activeStep} isEnterpriseOrg={isEnterpriseOrg} newStepNumber={1} workflow={workflow} />
        {!!stepsToRender.length && stepsToRender.map(step => <div css={{
        "display": "contents"
      }} key={`step-${step.id}-${step.stepNumber}`}>
              <div className={workflowStepHeaderAndEditorContainerStyles({
          isActive: !!activeStep && isCurrentStepActiveStep(step)
        })} data-testid={`workflow-step-header-editor-container-${step.id}`}>
                <WorkflowStepHeader activeStep={activeStep} hasSecretPlaceholders={localStepsWithSecretPlaceholders.includes(step.stepNumber)} refetchWorkflow={refetchWorkflow} step={step} warningTooltipText={localStepsWithSecretPlaceholders.includes(step.stepNumber) ? 'This step contains secret placeholders that need to be replaced' : ''} workflow={workflow} />
                {activeStep && isCurrentStepActiveStep(step) && <WorkflowStepEditor executeApiActionStep={(step: BuilderWorkflowStep) => executeApiActionStepTest(step)} refetchWorkflow={refetchWorkflow} step={activeStep} workflow={workflow} />}
              </div>
              <AddWorkflowStepButton disabled={!!activeStep} isEnterpriseOrg={isEnterpriseOrg} newStepNumber={step.stepNumber + 1} workflow={workflow} />
            </div>)}
      </div>
    </div>;
};
export default WorkflowTimeline;