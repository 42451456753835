import _styled from "styled-components";
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import CHEVRON_LEFT_SVG from '~/components/core/Icon/assets/chevron-left.icon.svg';
import CHEVRON_RIGHT_SVG from '~/components/core/Icon/assets/chevron-right.icon.svg';
const Paginate: React.ComponentType<ReactPaginateProps> = ReactPaginate;
const PaginationContainer = _styled.div({
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
});
interface PaginationProps {
  initialPage: number;
  onPageActive: (page: number) => void;
  onPageChange: (page: number) => void;
  totalPages: number;
}
export const Pagination = ({
  initialPage,
  totalPages,
  onPageActive,
  onPageChange
}: PaginationProps) => {
  const btnClassName = 'font-oxanium flex items-center';
  if (totalPages <= 1) return null;
  const nextArrow = <span css={{
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  }}>
      NEXT
      <CHEVRON_RIGHT_SVG css={{
      "height": "1rem",
      "width": "1rem"
    }} />
    </span>;
  const nextArrowDisabledStyle = initialPage === totalPages - 1 ? '[&_*]:cursor-not-allowed opacity-50' : '';
  const previousArrow = <span className="[&_*]:cursor-inherit" css={{
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  }}>
      <CHEVRON_LEFT_SVG css={{
      "height": "1rem",
      "width": "1rem"
    }} />
      PREVIOUS
    </span>;
  const previousArrowDisabledStyle = initialPage === 0 ? '[&_*]:cursor-not-allowed opacity-50' : '';
  return <PaginationContainer>
      <Paginate activeClassName="[&>a]:hover:text-black-raisin [&>a]:hover:!text-black-raisin flex items-center" activeLinkClassName="bg-highlight text-black-raisin px-2 rounded" breakClassName={`${btnClassName} text-sm`} breakLabel="..." containerClassName="flex flex-row grow space-x-2 justify-center items-center" initialPage={initialPage} nextClassName={nextArrowDisabledStyle} nextLabel={nextArrow} nextLinkClassName={`${btnClassName} text-[10px] ml-2`} onPageActive={(item: any) => onPageActive(item.selected)} onPageChange={(item: any) => onPageChange(item.selected)} pageClassName="" pageCount={totalPages} pageLinkClassName={`${btnClassName} rounded-md hover:text-highlight text-sm mx-1.5`} previousClassName={previousArrowDisabledStyle} previousLabel={previousArrow} previousLinkClassName={`${btnClassName} text-[10px] mr-2`} renderOnZeroPageCount={null} />
    </PaginationContainer>;
};
export default Pagination;