import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingSpinner, TextIconColor, Typography, TypographySize } from '../core';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import WorkflowViewLayout from '../WorkflowFactory/shared/WorkflowViewLayout/WorkflowViewLayout';
import { WorkflowBuilderCanvas } from './WorkflowBuilderCanvas';
import WorkflowBuilderHeader from './WorkflowBuilderHeader/WorkflowBuilderHeader';
import { WORKFLOWS_LIBRARY_ROUTE } from '~/constants';
import { ToastType, useAppSelector, useDeleteWorkflow, useGetWorkflowFromUrlSlug, useOnComponentUnmount, useParseWorkflowUrlSlug, useToast, useWorkflowHydration } from '~/hooks';
import { workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { nextTrpc } from '~/trpc';
import { checkForSecretPlaceholders, getStepsWithSecretPlaceholders } from '~/utils/workflow.utils';
const WorkflowBuilder: React.FC = () => {
  const [isDuplicatedWorkflow, setIsDuplicatedWorkflow] = useState(false);
  const [showSecretPlaceholderNotice, setShowSecretPlaceholderNotice] = useState(false);
  const [stepsWithSecretPlaceholders, setStepsWithSecretPlaceholders] = useState<number[]>([]);

  // Redux
  const {
    workflowConfig
  } = useAppSelector(state => state.workflowBuilder);
  const {
    description,
    instructions: systemPrompt,
    name
  } = workflowConfig;
  const dispatch = useDispatch();

  // Custom hooks
  const router = useRouter();
  const {
    workflowType: workflowTypeBySlug
  } = useParseWorkflowUrlSlug();
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchWorkflow,
    isLoading
  } = useGetWorkflowFromUrlSlug();
  const workflow = data?.item;
  const {
    hydrateWorkflowConfigAndKnowledgeStore
  } = useWorkflowHydration({
    workflow
  });

  // Get workflow type
  const workflowType = workflowTypeBySlug ?? workflow?.type;

  // Workflow steps
  const workflowSteps = workflow?.steps ?? [];

  // Knowledge store content
  const knowledgeStoreContent = workflow?.knowledgeStoreContent ?? [];

  // Check for placeholders when workflow loads or changes
  useEffect(() => {
    if (workflow) {
      const hasPlaceholders = checkForSecretPlaceholders(workflow);
      // Always update the steps with placeholders when workflow changes
      // to ensure UI is accurate if a user attaches a secret
      setStepsWithSecretPlaceholders(getStepsWithSecretPlaceholders(workflow));

      // Only show the notice if there are placeholders and it hasn't been dismissed
      setShowSecretPlaceholderNotice(hasPlaceholders);
    }
  }, [workflow]);

  // Check if this is a freshly duplicated workflow
  useEffect(() => {
    const fromDuplicate = router.query.fromDuplicate === 'true';
    if (fromDuplicate && workflow && !isDuplicatedWorkflow) {
      setIsDuplicatedWorkflow(true);

      // Remove the query parameter to avoid showing the notice again on refresh
      const newQuery = {
        ...router.query
      };
      delete newQuery.fromDuplicate;
      void router.replace({
        pathname: router.pathname,
        query: newQuery
      }, undefined, {
        shallow: true
      });
    }
  }, [router, workflow, isDuplicatedWorkflow]);

  // Helper functions
  const handleReturnToLibrary = () => {
    void router.push({
      pathname: WORKFLOWS_LIBRARY_ROUTE
    });
  };
  // Handler for retrying to fetch the workflow
  const handleRetryWorkflowFetch = () => {
    void refetchWorkflow();
  };
  // Custom hooks
  // WorkflowBuilder only auto-deletes empty agents, and
  // since we only want to show the deletion toast when the user explicitly deletes it,
  // we do not show the toast for deletion here
  const {
    deleteWorkflow
  } = useDeleteWorkflow(workflow?.id ?? '', false);

  // Mutations
  const nextTrpcUtils = nextTrpc.useUtils();
  const updateWorkflowNameMutation = nextTrpc.workflows.update.useMutation({
    onSuccess: () => {
      void nextTrpcUtils.workflows.list.invalidate();
      void refetchWorkflow();
      enqueueToast({
        message: 'Agent name updated',
        type: ToastType.SUCCESS
      });
    }
  });
  const generateMissingWorkflowMetadataMutation = nextTrpc.workflows.generateMissingMetadata.useMutation({
    onSuccess: () => {
      // Invalidate cache to update name in lists immediately
      void nextTrpcUtils.workflows.list.invalidate();
      void refetchWorkflow();
    },
    onError: error => {
      console.error('Failed to generate agent info', error);
    }
  });
  const updateWorkflowDisplayName = (value: string) => {
    dispatch(workflowBuilderActions.updateWorkflowConfigName(value));
  };
  const handleNameBlur = () => {
    const trimmedName = name?.trim();
    if (!workflow?.id || trimmedName === workflow.displayName) {
      return;
    }
    if (!trimmedName) {
      dispatch(workflowBuilderActions.updateWorkflowConfigName(workflow.displayName ?? ''));
      return;
    }
    updateWorkflowNameMutation.mutate({
      workflowId: workflow.id,
      displayName: trimmedName
    });
  };

  // Redux
  /**
   * On unmount, we want to do a few things:
   *   1. Delete the workflow if it has no saved steps AND no system prompt
   *   2. Generate the workflow info if it has saved
   *      - Generates a name/description/category
   *   3. Clear the active step
   */
  useOnComponentUnmount(() => {
    // Clear the active step
    dispatch(workflowBuilderActions.clearActiveStep());
    // Clear the active trigger and conditions
    dispatch(triggerActions.clearTrigger());
    if (!workflow) return;

    // If the workflow does not have saved steps and no workflow config i.e.
    // name, description, knowledge store, and instruction (system prompt), delete it
    // TODO: Handle triggers
    if (!description && !knowledgeStoreContent.length && !name && !systemPrompt && !workflowSteps.length) {
      void deleteWorkflow();
      return;
    }

    // If the workflow is not being deleted, kick off the metadata generation
    // (the API does nothing if the metadata already exists)
    generateMissingWorkflowMetadataMutation.mutate({
      workflowId: workflow.id
    });
  }, [description, knowledgeStoreContent.length, name, systemPrompt, workflow?.id, workflowSteps.length]);

  // Hydrate the workflow config and knowledge store content
  // in the redux store when a new workflow is loaded
  useEffect(() => {
    if (workflow) {
      hydrateWorkflowConfigAndKnowledgeStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow?.id]);
  const handleCloseSecretPlaceholderNotice = () => {
    setShowSecretPlaceholderNotice(false);
  };

  // Create message for secret placeholder steps
  const secretPlaceholderMessage = useMemo(() => {
    if (stepsWithSecretPlaceholders.length === 0) return '';
    const stepsList = stepsWithSecretPlaceholders.sort((a, b) => a - b).map(step => `Step ${step}`).join(', ');
    return `The following steps contain placeholder secrets that need to be configured: ${stepsList}`;
  }, [stepsWithSecretPlaceholders]);
  return <WorkflowViewLayout headerChildren={workflow ? [<WorkflowBuilderHeader hasPlaceholderSecrets={!!stepsWithSecretPlaceholders.length} key="header" refetchWorkflow={refetchWorkflow} workflow={workflow} />] : undefined} name={name} onNameBlur={handleNameBlur} onNameChange={updateWorkflowDisplayName} workflowType={workflowType}>
      <>
        {isLoading && !workflow && <div css={{
        "display": "flex",
        "height": "100%",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "center"
      }} data-testid="workflow-builder-loading-container">
            <LoadingSpinner />
            <Typography color={TextIconColor.PRIMARY} size={TypographySize.H4}>
              Loading agent...
            </Typography>
          </div>}
        {!isLoading && !workflow && <ConfirmationModal cancelButtonLabel="Retry" confirmButtonLabel="Return to library" header="Agent not found." onCancel={handleRetryWorkflowFetch} onConfirm={handleReturnToLibrary} open subtext="Try selecting another agent." />}
        {workflow && <WorkflowBuilderCanvas isLoading={isLoading} refetchWorkflow={refetchWorkflow} stepsWithSecretPlaceholders={stepsWithSecretPlaceholders} workflow={workflow} />}

        {workflow && showSecretPlaceholderNotice && stepsWithSecretPlaceholders.length > 0 && <ConfirmationModal cancelButtonLabel="Cancel" confirmButtonLabel="Got it" header="This agent contains placeholder secrets" onCancel={handleCloseSecretPlaceholderNotice} onConfirm={handleCloseSecretPlaceholderNotice} open subtext={secretPlaceholderMessage} />}
      </>
    </WorkflowViewLayout>;
};
export default WorkflowBuilder;