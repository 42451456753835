import _styled from "styled-components";
import { Popper } from '@mui/base/Popper';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Icons, TextIconColor } from '../core';
import { DropdownPlacement } from './Dropdown.consts';
import { DropdownTriggerButtonProps, isButton, isIcon } from './Dropdown.types';
const DropdownTriggerContainer = _styled.button<{
  disabled: boolean;
  highlightOnHover: boolean;
}>(({
  disabled,
  highlightOnHover
}) => [{
  "position": "relative",
  "display": "inline-block",
  "cursor": "pointer",
  "borderRadius": "0.375rem",
  "textAlign": "left"
}, disabled && {
  "cursor": "not-allowed",
  "opacity": "0.5"
}, highlightOnHover && {
  "borderRadius": "0.375rem",
  "padding": "0.25rem",
  "&:hover": {
    "backgroundColor": "rgb(255 255 255 / 0.1)"
  }
}]);
const DropdownContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "maxHeight": "200px",
  "minWidth": "180px",
  "flexDirection": "column",
  "gap": "0.25rem",
  "overflowY": "auto",
  "borderRadius": "5px",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "borderColor": "rgb(255 255 255 / 0.2)",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "paddingLeft": "0.25rem",
  "paddingRight": "0.25rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "--tw-shadow": "0px 13px 26.5px 0px rgba(0,0,0,0.54)",
  "--tw-shadow-colored": "0px 13px 26.5px 0px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "--tw-backdrop-blur": "blur(5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
});

// The dropdown trigger can be one of:
// 1. An Icon - rendered as an Icons component
// 2. A DropdownTriggerButtonProps object - rendered as a Button component
// 3. A React.ReactNode - rendered directly
type DropdownTrigger = Icon | DropdownTriggerButtonProps | React.ReactNode;
interface DropdownProps {
  children: React.ReactNode;
  trigger: DropdownTrigger;
  closeOnClick?: boolean;
  'data-id'?: string | undefined;
  disabled?: boolean;
  highlightTriggerOnHover?: boolean;
  placement?: DropdownPlacement;
}

// TODO: Move this component to core
export const Dropdown: React.FC<DropdownProps> = ({
  trigger,
  children,
  'data-id': dataId,
  disabled = false,
  highlightTriggerOnHover = false,
  placement = DropdownPlacement.BOTTOM_START,
  closeOnClick = false
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const renderTrigger = () => {
    if (isIcon(trigger)) {
      return <Icons color={TextIconColor.PRIMARY} icon={trigger} />;
    }
    if (isButton(trigger)) {
      const {
        hideChevron,
        buttonProps
      } = trigger;
      const getEndIcon = () => {
        if (buttonProps.endIcon) return buttonProps.endIcon;
        return hideChevron ? undefined : Icon.CHEVRON_DOWN;
      };
      return <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps} endIcon={getEndIcon()} invertEndIcon={buttonProps.endIcon ? false : !!anchorEl} onClick={() => {}} />;
    }
    return trigger;
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (containerRef.current && popperRef.current && !containerRef.current.contains(event.target as Node) && !popperRef.current.contains(event.target as Node)) {
      setAnchorEl(null);
    }
  }, []);
  const handleChildClick = (event: React.MouseEvent<HTMLElement>) => {
    // Don't close if clicking the container itself
    if (event.target === event.currentTarget) return;

    // Close if closeOnClick is true and we clicked a menuitem
    // This works with DropdownItem and SelectOption which both use role="menuitem"
    // to indicate they are clickable items that should trigger the dropdown to close
    const target = event.target as Element;
    if (closeOnClick && target.closest('[role="menuitem"]')) {
      setAnchorEl(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  const open = Boolean(anchorEl);
  const disableTriggerButton = disabled || !children || isArray(children) && !children.length;
  return <div ref={containerRef}>
      <DropdownTriggerContainer aria-expanded={open} data-id={dataId} disabled={disableTriggerButton} highlightOnHover={highlightTriggerOnHover} onClick={handleClick}>
        {renderTrigger()}
      </DropdownTriggerContainer>
      <Popper anchorEl={anchorEl} open={open} placement={placement} ref={popperRef} slotProps={{
      root: {
        className: 'z-50 font-sans-3 pb-[10px]'
      }
    }}>
        <DropdownContainer aria-labelledby="menu-button" aria-orientation="vertical" onClick={handleChildClick} role="menu">
          {children}
        </DropdownContainer>
      </Popper>
    </div>;
};
export default Dropdown;