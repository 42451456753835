import SettingsSection from '../../SettingsSection';
import { CalloutType, TextIconColor } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import { useMarkdownFile } from '~/hooks';
const SetupInstructions = () => {
  const {
    markdown: continueSetupMarkdown
  } = useMarkdownFile('/docs/Continue/ContinueSetup.md');
  const {
    markdown: continueExamplesMarkdown
  } = useMarkdownFile('/docs/Continue/ContinueExamples.md');
  const {
    markdown: continueTroubleshootingMarkdown
  } = useMarkdownFile('/docs/Continue/ContinueTroubleshooting.md');
  return <SettingsSection title="Setup Instructions">
      <div css={{
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem",
      "display": "flex",
      "flexDirection": "column"
    }} data-testid="plugins-setup-container">
        <div css={{
        "marginBottom": "3rem",
        "display": "flex",
        "flexDirection": "column",
        "gap": "1rem",
        "borderRadius": "0.5rem",
        "--tw-bg-opacity": "0.3",
        "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
        "padding": "30px"
      }} data-testid="plugins-setup-block-container">
          <Markdown># Setting up Kindo as a Provider in Continue</Markdown>
          <Markdown calloutType={CalloutType.SECURITY} textColor={TextIconColor.SECONDARY}>
            {continueSetupMarkdown}
          </Markdown>
        </div>
        <div css={{
        "marginBottom": "3rem",
        "marginLeft": "1rem",
        "display": "flex",
        "flexDirection": "column",
        "gap": "1rem"
      }} data-testid="plugins-examples-container">
          <Markdown># Examples</Markdown>
          <Markdown styleDocsComponents textColor={TextIconColor.SECONDARY}>
            {continueExamplesMarkdown}
          </Markdown>
        </div>
        <div css={{
        "marginBottom": "3rem",
        "display": "flex",
        "flexDirection": "column",
        "gap": "1rem",
        "borderRadius": "0.5rem",
        "--tw-bg-opacity": "0.3",
        "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
        "padding": "30px"
      }} data-testid="plugins-troubleshooting-container">
          <Markdown># Troubleshooting</Markdown>
          <Markdown textColor={TextIconColor.SECONDARY}>
            {continueTroubleshootingMarkdown}
          </Markdown>
        </div>
      </div>
    </SettingsSection>;
};
export default SetupInstructions;