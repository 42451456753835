import { TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES } from '@kindo/universal';
import React from 'react';
import { WORKFLOW_TRIGGER_CONDITION_TYPE_DISPLAY_NAMES } from '../../../WorkflowTrigger.utils';
import WorkflowTriggerConditionEditor from './WorkflowTriggerConditionEditor';
import { Icon, IconButton, IconButtonType, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { isWorkflowTriggerConditionBuilder, WorkflowTriggerConditionBuilder, WorkflowTriggerConditionBuilderDraft } from '~/types';
interface WorkflowTriggerConditionProps {
  condition: WorkflowTriggerConditionBuilderDraft;
  onCancelEdit: () => void;
  onDelete: (condition: WorkflowTriggerConditionBuilder) => void;
  onEdit: (condition: WorkflowTriggerConditionBuilder) => void;
  onSaveCondition: (condition: WorkflowTriggerConditionBuilderDraft) => Promise<void>;
  savedCondition: WorkflowTriggerConditionBuilder | null;
  activeTriggerCondition?: WorkflowTriggerConditionBuilderDraft | null;
  isDeleting?: boolean;
  isSaving?: boolean;
}
const WorkflowTriggerCondition: React.FC<WorkflowTriggerConditionProps> = ({
  condition,
  onDelete,
  onEdit,
  onSaveCondition,
  onCancelEdit,
  activeTriggerCondition,
  savedCondition,
  isDeleting = false,
  isSaving = false
}) => {
  const isExpanded = activeTriggerCondition?.index === condition.index;
  const handleExpandClick = () => {
    if (!isExpanded && isWorkflowTriggerConditionBuilder(condition)) {
      onEdit(condition);
    } else {
      onCancelEdit();
    }
  };
  const handleDelete = () => {
    if (isWorkflowTriggerConditionBuilder(condition)) {
      onDelete(condition);
    } else {
      // If it's a new draft condition, we just want to cancel the edit
      onCancelEdit();
    }
  };
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "borderRadius": "0.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(29 30 54 / var(--tw-bg-opacity))",
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }} data-testid="trigger-condition-container">
      <div css={{
      "display": "flex",
      "cursor": "pointer",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem"
    }} data-testid="trigger-condition-preview" onClick={handleExpandClick}>
        <div css={{
        "display": "flex",
        "alignItems": "center",
        "gap": "0.75rem"
      }} data-testid="trigger-condition-preview-badge-and-text-container">
          <Badge label={condition.index !== null ? `Condition ${condition.index + 1}` : 'New Condition'} />
          {isWorkflowTriggerConditionBuilder(condition) && <div css={{
          "display": "flex",
          "alignItems": "center",
          "gap": "6px"
        }} data-testid="trigger-condition-preview-text">
              <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
                {TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES[condition.field]}{' '}
                {WORKFLOW_TRIGGER_CONDITION_TYPE_DISPLAY_NAMES[condition.type]}
                {': '}
              </Typography>
              <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                {condition.value}
              </Typography>
            </div>}
        </div>
        <IconButton color={TextIconColor.PRIMARY} disabled={isDeleting} icon={isExpanded ? Icon.CHEVRON_DOWN : Icon.CHEVRON_RIGHT} onClick={handleExpandClick} type={IconButtonType.SIMPLE} />
      </div>
      {isExpanded && <WorkflowTriggerConditionEditor activeCondition={activeTriggerCondition} isSaving={isSaving} onCancel={onCancelEdit} onDelete={handleDelete} onSaveCondition={onSaveCondition} savedCondition={savedCondition} />}
    </div>;
};
export default WorkflowTriggerCondition;