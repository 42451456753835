export const DEFAULT_WORKFLOW_NAME = 'Unnamed Agent';

export enum WorkflowType {
  // Agent/workflow with 0 steps, only configuration specs defined
  // (e.g., name, description, instructions)
  CHATBOT = 'CHATBOT',
  // Agent/workflow with 1+ llm steps
  MANUAL = 'MANUAL',
  // Agent/workflow with 1+ triggers defined on it
  TRIGGERED = 'TRIGGERED'
}

export enum WorkflowStepType {
  API_ACTION = 'API_ACTION',
  INTEGRATION_ACTION = 'INTEGRATION_ACTION',
  LLM = 'LLM'
}

export enum WorkflowInputType {
  TEXT_OR_CONTENT = 'TEXT_OR_CONTENT',
  TICKETING_INTEGRATION_TICKET = 'TICKETING_INTEGRATION_TICKET'
}

export enum WorkflowIntegrationActionStepType {
  CREATE_COMMENT_ON_TICKET = 'CREATE_COMMENT_ON_TICKET',
  UPDATE_TICKET_ASSIGNEE = 'UPDATE_TICKET_ASSIGNEE',
  UPDATE_TICKET_PRIORITY = 'UPDATE_TICKET_PRIORITY'
}

export const PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES = [
  WorkflowInputType.TEXT_OR_CONTENT
] as const satisfies Readonly<WorkflowInputType[]>;

export const INTEGRATION_WORKFLOW_INPUT_TYPES = [
  WorkflowInputType.TICKETING_INTEGRATION_TICKET
] as const satisfies Readonly<WorkflowInputType[]>;

export type PromptTemplateWorkflowInputType =
  (typeof PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES)[number];

export type IntegrationWorkflowInputType =
  (typeof INTEGRATION_WORKFLOW_INPUT_TYPES)[number];

export enum WorkflowRole {
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  VIEWER = 'VIEWER'
}

export const WORKFLOW_ORG_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.OWNER,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowOrgRole = (typeof WORKFLOW_ORG_ROLES)[number];

export const WORKFLOW_USER_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.OWNER,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowUserRole = (typeof WORKFLOW_USER_ROLES)[number];

export const WORKFLOW_PUBLIC_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowPublicRole = (typeof WORKFLOW_PUBLIC_ROLES)[number];

export const WORKFLOW_USER_GROUP_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.OWNER,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowUserGroupRole = (typeof WORKFLOW_USER_GROUP_ROLES)[number];

export enum WorkflowCategory {
  COACHING_AND_HR = 'Coaching and HR',
  CONTENT_CREATION = 'Content Creation',
  CUSTOMER_SUPPORT = 'Customer Support',
  GENERAL = 'General',
  MARKETING_AND_SALES = 'Marketing and Sales',
  PRODUCT_AND_OPERATIONS = 'Product and Operations'
}

export function isWorkflowCategory(
  category: string
): category is WorkflowCategory {
  return Object.values(WorkflowCategory).includes(category as WorkflowCategory);
}

export enum WorkflowListSort {
  CATEGORY = 'CATEGORY',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  RECENTLY_USED = 'RECENTLY_USED',
  TYPE = 'TYPE',
  UPDATED_AT = 'UPDATED_AT'
}

// Scope filters for workflows list endpoint
export enum WorkflowListAccessScopeFilter {
  CREATOR_SCOPE = 'CREATOR_SCOPE',
  ORG_SCOPE = 'ORG_SCOPE',
  SHARED_SCOPE = 'SHARED_SCOPE'
}

// NOTE: Currently, community and kindo tags are a legacy feature and are supported for
// public workflows only, s.t. tag scope filter is XOR'd with the access scope filter
// Going forward, we envision a more flexible tagging system + TagFilter,
// which would be separate from and combinable-with access scope filter,
// similar to how WorkflowListScopeFilter and WorkflowListTypeFilter are
export enum WorkflowListTagScopeFilter {
  COMMUNITY_TAG_SCOPE = 'COMMUNITY_TAG_SCOPE',
  KINDO_TAG_SCOPE = 'KINDO_TAG_SCOPE'
}

export const ALL_WORKFLOW_LIST_SCOPE_FILTERS = [
  ...Object.values(WorkflowListAccessScopeFilter),
  ...Object.values(WorkflowListTagScopeFilter)
] as const satisfies Readonly<
  (WorkflowListAccessScopeFilter | WorkflowListTagScopeFilter)[]
>;

export type WorkflowListScopeFilter =
  (typeof ALL_WORKFLOW_LIST_SCOPE_FILTERS)[number];

export function isWorkflowListScopeFilter(
  filter: string
): filter is WorkflowListScopeFilter {
  return ALL_WORKFLOW_LIST_SCOPE_FILTERS.includes(
    filter as WorkflowListScopeFilter
  );
}

export enum WorkflowListTypeFilter {
  ALL = 'ALL',
  CHATBOT = 'CHATBOT',
  MANUAL = 'MANUAL',
  TRIGGERED = 'TRIGGERED'
}

export enum WorkflowTriggerOperator {
  AND = 'AND',
  OR = 'OR'
}

export enum WorkflowTriggerConditionType {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  NOT_CONTAINS = 'NOT CONTAINS',
  NOT_EQUALS = 'NOT EQUALS',
  REGEX = 'REGEX'
}

export interface WorkflowListArgs {
  category: string | undefined;
  limit: number | undefined;
  orgId: string;
  orgRole: string;
  sort: WorkflowListSort | undefined;
  sortDesc: boolean | undefined;
  userId: string;
  workflowScopeFilter: WorkflowListScopeFilter | undefined;
  workflowTypeFilter: WorkflowListTypeFilter | undefined;
}

export interface WorkflowUser {
  email: string;
  id: string;
  name: string | null;
  role: WorkflowUserRole;
}

/** Type Guards */

export function isWorkflowOrgRole(role: string): role is WorkflowOrgRole {
  return Object.values(WORKFLOW_ORG_ROLES).includes(role as WorkflowOrgRole);
}

export function isWorkflowUserRole(role: string): role is WorkflowUserRole {
  return Object.values(WORKFLOW_USER_ROLES).includes(role as WorkflowUserRole);
}

export function isWorkflowPublicRole(role: string): role is WorkflowPublicRole {
  return Object.values(WORKFLOW_PUBLIC_ROLES).includes(
    role as WorkflowPublicRole
  );
}

export function isWorkflowUserGroupRole(
  role: string | undefined
): role is WorkflowUserGroupRole {
  return Object.values(WORKFLOW_USER_GROUP_ROLES).includes(
    role as WorkflowUserGroupRole
  );
}

export function isWorkflowIntegrationActionStepType(
  type: string
): type is WorkflowIntegrationActionStepType {
  return Object.values(WorkflowIntegrationActionStepType).includes(
    type as WorkflowIntegrationActionStepType
  );
}

export function isWorkflowInputType(type: string): type is WorkflowInputType {
  return Object.values(WorkflowInputType).includes(type as WorkflowInputType);
}

export function isWorkflowTriggerOperator(
  operator: string
): operator is WorkflowTriggerOperator {
  return Object.values(WorkflowTriggerOperator).includes(
    operator as WorkflowTriggerOperator
  );
}

export function isWorkflowTriggerConditionType(
  type: string
): type is WorkflowTriggerConditionType {
  return Object.values(WorkflowTriggerConditionType).includes(
    type as WorkflowTriggerConditionType
  );
}

export function isIntegrationWorkflowInputType(
  type: string
): type is IntegrationWorkflowInputType {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    type as IntegrationWorkflowInputType
  );
}

export enum WorkflowPermissionTypes {
  DELETE = 'delete',
  EDIT = 'edit',
  SHARE = 'share'
}

export interface WorkflowPermissions {
  [WorkflowPermissionTypes.DELETE]: boolean;
  [WorkflowPermissionTypes.EDIT]: boolean;
  [WorkflowPermissionTypes.SHARE]: boolean;
}
