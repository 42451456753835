import { TextIconColor, Size } from '../constants';
import { withLink } from '../hocs';
import { Icon, Icons } from '../Icon';
import { Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../Typography';
import { SegmentedControlType } from './SegmentedControl.consts';
import { StyledSegment } from './SegmentedControl.styles';
export interface SegmentOption<SegmentValue extends string> {
  label: string;
  value: SegmentValue;
  href?: string;
  icon?: Icon;
}
type SegmentProps<SegmentValue extends string> = {
  isSelected: boolean;
  numSegments: number;
  segment: SegmentOption<SegmentValue>;
  segmentIndex: number;
  type: SegmentedControlType;
  onClick?: ((value: SegmentValue) => void) | undefined;
};
const Segment = <SegmentValue extends string,>({
  segment,
  isSelected,
  onClick,
  segmentIndex,
  numSegments,
  type
}: SegmentProps<SegmentValue>) => {
  const segmentContent = <StyledSegment $isSelected={isSelected} $numSegments={numSegments} $segmentIndex={segmentIndex} $type={type} onClick={() => onClick?.(segment.value)}>
      <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={type === SegmentedControlType.SOLID ? TypographySize.X_SMALL : TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
        {segment.label}
      </Typography>
      {!!segment.icon && <Icons color={TextIconColor.PRIMARY} icon={segment.icon} size={Size.MEDIUM} />}
    </StyledSegment>;
  return withLink(segmentContent, {
    href: segment.href
  });
};
export default Segment;