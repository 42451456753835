import { AuditLog } from './AuditLog';
import { OrgIntegrationAccess } from './OrgIntegrationAccess';
import { OrgSecurityControls } from './OrgSecurityControls';
import { OrgSharingPermissions } from './OrgSharingPermissions';

/** Security Settings Tabs */
export enum SecuritySettingsTabs {
  ORG_SECURITY = 'ORG_SECURITY',
}
export const SECURITY_SETTINGS_TAB_DISPLAY_NAMES: Record<SecuritySettingsTabs, string> = {
  [SecuritySettingsTabs.ORG_SECURITY]: 'Organization Security'
};
export const SECURITY_SETTINGS_TAB_ROUTES: Record<SecuritySettingsTabs, string> = {
  [SecuritySettingsTabs.ORG_SECURITY]: 'org-security'
};
export type SecuritySettingsTabSubTabs = {
  [SecuritySettingsTabs.ORG_SECURITY]: OrgSecuritySettingsTabs;
};

/** Subtab 1 - Organization Security Tab */
export enum OrgSecuritySettingsTabs {
  AUDIT_LOG = 'AUDIT_LOG',
  INTEGRATION_ACCESS = 'INTEGRATION_ACCESS',
  MODEL_ACCESS_AND_DLP = 'MODEL_ACCESS_AND_DLP',
  SHARING_PERMISSIONS = 'SHARING_PERMISSIONS',
}
export const ORG_SECURITY_SETTINGS_TAB_DISPLAY_NAMES: Record<OrgSecuritySettingsTabs, string> = {
  [OrgSecuritySettingsTabs.AUDIT_LOG]: 'Audit Log',
  [OrgSecuritySettingsTabs.INTEGRATION_ACCESS]: 'Integration Access',
  [OrgSecuritySettingsTabs.MODEL_ACCESS_AND_DLP]: 'Model Access + DLP',
  [OrgSecuritySettingsTabs.SHARING_PERMISSIONS]: 'Sharing Permissions'
};
export const ORG_SECURITY_SETTINGS_TAB_ROUTES: Record<OrgSecuritySettingsTabs, string> = {
  [OrgSecuritySettingsTabs.AUDIT_LOG]: 'audit-log',
  [OrgSecuritySettingsTabs.INTEGRATION_ACCESS]: 'integration-access',
  [OrgSecuritySettingsTabs.MODEL_ACCESS_AND_DLP]: 'model-access-and-dlp',
  [OrgSecuritySettingsTabs.SHARING_PERMISSIONS]: 'sharing-permissions'
};
export interface SecuritySettingsTabSubTabRoutes {
  [SecuritySettingsTabs.ORG_SECURITY]: Record<OrgSecuritySettingsTabs, string>;
}

/** Security Settings Subtab Routes */
export const SECURITY_SETTINGS_TAB_SUBTABS_WITH_ROUTES: SecuritySettingsTabSubTabRoutes = {
  [SecuritySettingsTabs.ORG_SECURITY]: ORG_SECURITY_SETTINGS_TAB_ROUTES
};
export const SECURITY_SETTINGS_TAB_DEFAULT_SUBTABS: SecuritySettingsTabSubTabs = {
  [SecuritySettingsTabs.ORG_SECURITY]: OrgSecuritySettingsTabs.MODEL_ACCESS_AND_DLP
};
export const SECURITY_SETTINGS_TAB_COMPONENTS: Record<OrgSecuritySettingsTabs, React.ReactNode> = {
  [OrgSecuritySettingsTabs.MODEL_ACCESS_AND_DLP]: <OrgSecurityControls />,
  [OrgSecuritySettingsTabs.INTEGRATION_ACCESS]: <OrgIntegrationAccess />,
  [OrgSecuritySettingsTabs.SHARING_PERMISSIONS]: <OrgSharingPermissions />,
  [OrgSecuritySettingsTabs.AUDIT_LOG]: <AuditLog />
};