import _styled from "styled-components";
import { Button, ButtonType, Size, TextField, TextFieldType, TypographySize } from '~/components/core';
const InputButtonContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center"
});
const TextFieldContainer = _styled.div({
  "display": "flex",
  "width": "100%"
});
interface InputButtonProps {
  buttonLabel: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  placeholder: string;
  value: string;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
}
const InputButton: React.FC<InputButtonProps> = ({
  disabled = false,
  loading = false,
  buttonLabel,
  placeholder,
  value,
  onChange,
  onSubmit,
  width = 'w-1/2 max-w-[320px]'
}) => <InputButtonContainer className={width}>
    <TextFieldContainer>
      <TextField disabled={disabled} fullWidth onChange={onChange} onEnter={onSubmit} placeholder={placeholder} type={TextFieldType.INPUT_BUTTON} value={value} />
    </TextFieldContainer>
    <Button disabled={disabled || !value} label={buttonLabel} loading={loading} onClick={onSubmit} size={Size.SMALL} type={ButtonType.INPUT_BUTTON} typographySize={TypographySize.X_SMALL} />
  </InputButtonContainer>;
export default InputButton;