/* eslint-disable no-param-reassign */
import {
  ApiActionHeaders,
  ApiActionHeaderValue,
  ApiAuthorizationType,
  GeneratedApiValuesWithFormattedHeaders,
  HttpMethod,
  Llm,
  SupportedHttpMethod,
  WorkflowActionStepConfig,
  WorkflowIntegrationActionStepType,
  WorkflowStepType,
  WorkflowSupportedIntegration
} from '@kindo/universal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FileResource } from '~/components/Chat/AddFileModal';
import { WorkflowStep } from '~/types';
import {
  BuilderWorkflowApiActionStep,
  BuilderWorkflowIntegrationActionStep,
  BuilderWorkflowLlmStep,
  BuilderWorkflowStatus,
  BuilderWorkflowStep,
  BuilderWorkflowStepStaticContent,
  IntegrationWorkflowBuilderStepInput,
  isApiActionWorkflowBuilderStep,
  isIntegrationActionWorkflowBuilderStep,
  isIntegrationWorkflowBuilderStepInput,
  isLlmWorkflowBuilderStep,
  isPromptTemplateWorkflowBuilderStepInput,
  PromptTemplateWorkflowBuilderStepInput,
  transformWorkflowStepToBuilderWorkflowStep
} from '~/types/workflowBuilder.types';

// -----------------------------------------------------------------------------
// Constants & Initial State
// -----------------------------------------------------------------------------

export interface BuilderWorkflowConfig {
  description: string;
  instructions: string;
  name: string;
  status: BuilderWorkflowStatus;
}

export interface BuilderWorkflowKnowledgeStore {
  content: FileResource[];
  status: BuilderWorkflowStatus;
}

export const DEFAULT_WORKFLOW_STEP_MODEL = Llm.CLAUDE_3_5_SONNET;

// Mustache template values – reserved words for user input/static content.
export const NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE = 'newUserInput';
export const NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE = 'newStaticContent';

// New empty step templates
const NEW_EMPTY_WORKFLOW_LLM_STEP: BuilderWorkflowLlmStep = {
  type: WorkflowStepType.LLM,
  id: '',
  model: DEFAULT_WORKFLOW_STEP_MODEL,
  promptTemplate: '',
  status: BuilderWorkflowStatus.NEW,
  stepNumber: 0,
  staticContent: [],
  inputs: [],
  displayName: null,
  integrationInputActiveInEditor: null
};

const NEW_EMPTY_WORKFLOW_ACTION_STEP: BuilderWorkflowIntegrationActionStep = {
  type: WorkflowStepType.INTEGRATION_ACTION,
  id: '',
  status: BuilderWorkflowStatus.NEW,
  stepNumber: 0,
  integration: null,
  displayName: null,
  action: null,
  inputs: [],
  config: null
};

const NEW_EMPTY_WORKFLOW_API_ACTION_STEP: BuilderWorkflowApiActionStep = {
  type: WorkflowStepType.API_ACTION,
  id: '',
  status: BuilderWorkflowStatus.NEW,
  stepNumber: 0,
  method: HttpMethod.GET,
  endpoint: '',
  displayName: null,
  inputs: [],
  headers: {},
  parameters: null,
  body: null,
  auth: null
};

const NEW_EMPTY_WORKFLOW_CONFIG: BuilderWorkflowConfig = {
  description: '',
  instructions: '',
  name: '',
  status: BuilderWorkflowStatus.NEW
};

const NEW_EMPTY_KNOWLEDGE_STORE: BuilderWorkflowKnowledgeStore = {
  content: [],
  status: BuilderWorkflowStatus.NEW
};

interface WorkflowBuilderState {
  activeStep: BuilderWorkflowStep | null;
  knowledgeStore: BuilderWorkflowKnowledgeStore;
  workflowConfig: BuilderWorkflowConfig;
}

const initialState: WorkflowBuilderState = {
  activeStep: null,
  workflowConfig: NEW_EMPTY_WORKFLOW_CONFIG,
  knowledgeStore: NEW_EMPTY_KNOWLEDGE_STORE
};

// -----------------------------------------------------------------------------
// Helper Functions
// -----------------------------------------------------------------------------

/**
 * Marks the active step as MODIFIED if its status was previously SAVED.
 */
const markAsModifiedIfSaved = (activeStep: BuilderWorkflowStep) => {
  if (activeStep.status === BuilderWorkflowStatus.SAVED) {
    activeStep.status = BuilderWorkflowStatus.MODIFIED;
  }
};

/**
 * Generic updater for active steps with an optional type check.
 *
 * @param state - The current workflow builder state
 * @param typeGuard - Type guard function that checks if the active step is of type T
 * @param updater - Function that updates the active step if type check passes
 *
 * This is a helper function used by reducers to safely update the active workflow step.
 * It performs the following:
 * 1. Checks if there is an active step
 * 2. Validates the step is of the expected type using the predicate
 * 3. Applies the update function if checks pass
 * 4. Marks the step as modified if it was previously saved
 *
 * The generic type T ensures type safety when updating specific step types
 * like LLM steps or API action steps.
 */
const updateActiveStep = <T extends BuilderWorkflowStep>(
  state: WorkflowBuilderState,
  typeGuard: (step: BuilderWorkflowStep) => step is T,
  updater: (step: T) => void
) => {
  if (!state.activeStep || !typeGuard(state.activeStep)) return;
  updater(state.activeStep);
  markAsModifiedIfSaved(state.activeStep);
};

// -----------------------------------------------------------------------------
// Redux Slice
// -----------------------------------------------------------------------------

export const workflowBuilderSlice = createSlice({
  name: 'workflowBuilder',
  initialState,
  reducers: {
    // =========================================================================
    // Active Step Updates
    // =========================================================================

    // Marks the active step as saved, and updates the stepId (in case the step was newly created)
    markActiveStepAsSaved: (
      state,
      action: PayloadAction<{ stepId: string }>
    ) => {
      if (state.activeStep) {
        state.activeStep.status = BuilderWorkflowStatus.SAVED;
        state.activeStep.id = action.payload.stepId;
      }
    },

    // -----------------------------------
    // LLM Step Updates
    // -----------------------------------
    updateActiveStepPrompt: (state, action: PayloadAction<string>) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.promptTemplate = action.payload;
      });
    },

    // DO NOT REMOVE COMMENT: SupportedLlm
    updateActiveStepModel: (state, action: PayloadAction<string>) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.model = action.payload;
      });
    },

    // Inserting mustache templates into LLM prompt
    insertNewUserInput: (state) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.promptTemplate += `{{${NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE}}}`;
      });
    },

    insertNewStaticContent: (state) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.promptTemplate += `{{${NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE}}}`;
      });
    },

    // Input & Content management for LLM steps
    addUserInput: (
      state,
      action: PayloadAction<PromptTemplateWorkflowBuilderStepInput>
    ) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        const duplicate = step.inputs
          .filter(isPromptTemplateWorkflowBuilderStepInput)
          .some(
            (input) =>
              input.templateResolutionName ===
              action.payload.templateResolutionName
          );
        if (!duplicate) {
          step.inputs.push(action.payload);
        }
      });
    },

    addStaticContent: (
      state,
      action: PayloadAction<BuilderWorkflowStepStaticContent>
    ) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        const duplicate = step.staticContent.some(
          (content) => content.id === action.payload.id
        );
        if (!duplicate) {
          step.staticContent.push(action.payload);
        }
      });
    },

    // Integration input management (applies to LLM steps)
    removeIntegrationInput: (
      state,
      action: PayloadAction<{ inputId: string }>
    ) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.inputs = step.inputs.filter(
          (input) =>
            !isIntegrationWorkflowBuilderStepInput(input) ||
            input.id !== action.payload.inputId
        );
      });
    },

    setIntegrationInputActiveInEditor: (
      state,
      action: PayloadAction<{ inputId: string }>
    ) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        const newActive = step.inputs
          .filter(isIntegrationWorkflowBuilderStepInput)
          .find((input) => input.id === action.payload.inputId);
        if (!newActive) {
          console.error(
            `No integration input found with id: ${action.payload.inputId}`
          );
          return;
        }
        step.integrationInputActiveInEditor = newActive;
      });
    },

    clearIntegrationInputActiveInEditor: (state) => {
      updateActiveStep(state, isLlmWorkflowBuilderStep, (step) => {
        step.integrationInputActiveInEditor = null;
      });
    },

    // -----------------------------------
    // Integration Action Step Updates
    // -----------------------------------
    updateActiveStepIntegration: (
      state,
      action: PayloadAction<{ integration: WorkflowSupportedIntegration }>
    ) => {
      updateActiveStep(
        state,
        isIntegrationActionWorkflowBuilderStep,
        (step) => {
          step.integration = action.payload.integration;
        }
      );
    },

    updateActiveStepAction: (
      state,
      action: PayloadAction<{
        integrationAction: WorkflowIntegrationActionStepType;
      }>
    ) => {
      updateActiveStep(
        state,
        isIntegrationActionWorkflowBuilderStep,
        (step) => {
          step.action = action.payload.integrationAction;
        }
      );
    },

    updateActiveStepActionConfig: (
      state,
      action: PayloadAction<{
        config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>;
      }>
    ) => {
      updateActiveStep(
        state,
        isIntegrationActionWorkflowBuilderStep,
        (step) => {
          step.config = action.payload.config;
        }
      );
    },

    // Integration input for Integration Action steps (only one allowed)
    addIntegrationInput: (
      state,
      action: PayloadAction<{ input: IntegrationWorkflowBuilderStepInput }>
    ) => {
      if (!state.activeStep) return;
      if (isIntegrationActionWorkflowBuilderStep(state.activeStep)) {
        state.activeStep.inputs = [action.payload.input];
        markAsModifiedIfSaved(state.activeStep);
        return;
      }
      // For LLM steps, handle as before
      if (isLlmWorkflowBuilderStep(state.activeStep)) {
        const existing = state.activeStep.inputs
          .filter(isIntegrationWorkflowBuilderStepInput)
          .find((input) => input.id === action.payload.input.id);
        if (existing) {
          const currentFields =
            action.payload.input.config?.specifiedFields ?? [];
          const existingFields = existing.config?.specifiedFields ?? [];
          if (
            currentFields.length === existingFields.length &&
            currentFields.every((field) => existingFields.includes(field))
          ) {
            return;
          }
          state.activeStep.inputs = state.activeStep.inputs.filter(
            (inp) =>
              !isIntegrationWorkflowBuilderStepInput(inp) ||
              inp.id !== action.payload.input.id
          );
        }
        state.activeStep.inputs.push(action.payload.input);
        markAsModifiedIfSaved(state.activeStep);
      }
    },

    // -----------------------------------
    // API Action Step Updates
    // -----------------------------------
    updateApiActionStep: (
      state,
      action: PayloadAction<GeneratedApiValuesWithFormattedHeaders>
    ) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        // Update all the fields from the generated API step
        step.method = action.payload.method ?? null;
        step.endpoint = action.payload.endpoint ?? null;
        step.auth = action.payload.auth ?? null;
        step.headers = action.payload.headers ?? {};
        step.parameters = action.payload.parameters ?? null;
        step.body = action.payload.body
          ? JSON.stringify(action.payload.body)
          : null;
      });
    },

    updateApiActionStepMethod: (
      state,
      action: PayloadAction<SupportedHttpMethod>
    ) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.method = action.payload;
      });
    },

    updateApiActionStepEndpoint: (state, action: PayloadAction<string>) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.endpoint = action.payload;
      });
    },

    updateApiActionStepBody: (state, action: PayloadAction<string | null>) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.body = action.payload;
      });
    },

    // API Action Step Headers
    addApiActionStepHeader: (state) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.headers = { ...step.headers, '': { text: '' } };
      });
    },

    updateApiActionStepHeader: (
      state,
      action: PayloadAction<{
        index: number;
        key: string;
        value: ApiActionHeaders[string];
      }>
    ) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        const { index, key, value } = action.payload;
        const entries = Object.entries(step.headers);
        entries[index] = [key, value];
        step.headers = Object.fromEntries(entries);
      });
    },

    removeApiActionStepHeader: (state, action: PayloadAction<number>) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        const entries = Object.entries(step.headers);
        entries.splice(action.payload, 1);
        step.headers = Object.fromEntries(entries);
      });
    },

    // API Action Step Auth
    addApiActionStepAuth: (state) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        if (step.auth) {
          return;
        }
        step.auth = {
          authorizationType: ApiAuthorizationType.BASIC,
          value: { text: '' }
        };
      });
    },

    updateApiActionStepAuth: (
      state,
      action: PayloadAction<{
        authorizationType: ApiAuthorizationType;
        value: ApiActionHeaderValue;
      }>
    ) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.auth = {
          authorizationType: action.payload.authorizationType,
          value: action.payload.value
        };
      });
    },

    removeApiActionStepAuth: (state) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        step.auth = null;
      });
    },

    // API Action Step Parameters
    addApiActionStepParameter: (state) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        const currentParameters = step.parameters || {};
        step.parameters = { ...currentParameters, '': '' };
      });
    },

    updateApiActionStepParameter: (
      state,
      action: PayloadAction<{ index: number; key: string; value: string }>
    ) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        const { index, key, value } = action.payload;
        const entries = Object.entries(step.parameters || {});
        entries[index] = [key, value];
        step.parameters = Object.fromEntries(entries);
      });
    },

    removeApiActionStepParameter: (state, action: PayloadAction<number>) => {
      updateActiveStep(state, isApiActionWorkflowBuilderStep, (step) => {
        const entries = Object.entries(step.parameters || {});
        entries.splice(action.payload, 1);
        step.parameters = Object.fromEntries(entries);
      });
    },

    // -------------------------------------------------------------------------
    // Shared Active Step Updates
    // -------------------------------------------------------------------------
    updateActiveStepStepNumber: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      if (!state.activeStep) return;
      state.activeStep.stepNumber = action.payload.stepNumber;
      markAsModifiedIfSaved(state.activeStep);
    },

    // Setting the active step for editing or duplicating
    setExistingStepActive: (state, action: PayloadAction<WorkflowStep>) => {
      state.activeStep = transformWorkflowStepToBuilderWorkflowStep(
        action.payload,
        BuilderWorkflowStatus.SAVED
      );
    },

    createNewLlmStep: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      state.activeStep = {
        ...NEW_EMPTY_WORKFLOW_LLM_STEP,
        stepNumber: action.payload.stepNumber
      };
    },

    createNewActionStep: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      state.activeStep = {
        ...NEW_EMPTY_WORKFLOW_ACTION_STEP,
        stepNumber: action.payload.stepNumber
      };
    },

    createNewApiActionStep: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      state.activeStep = {
        ...NEW_EMPTY_WORKFLOW_API_ACTION_STEP,
        stepNumber: action.payload.stepNumber
      };
    },

    createNewPrePopulatedStep: (
      state,
      action: PayloadAction<{ prePopulatedStep: WorkflowStep }>
    ) => {
      state.activeStep = transformWorkflowStepToBuilderWorkflowStep(
        action.payload.prePopulatedStep,
        BuilderWorkflowStatus.NEW
      );
    },

    // =========================================================================
    // Workflow Config & Knowledge Store Updates
    // =========================================================================
    updateWorkflowConfigDescription: (state, action: PayloadAction<string>) => {
      state.workflowConfig.description = action.payload;
      if (state.workflowConfig.status !== BuilderWorkflowStatus.MODIFIED) {
        state.workflowConfig.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateWorkflowConfigInstructions: (
      state,
      action: PayloadAction<string>
    ) => {
      state.workflowConfig.instructions = action.payload;
      if (state.workflowConfig.status !== BuilderWorkflowStatus.MODIFIED) {
        state.workflowConfig.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateWorkflowConfigName: (state, action: PayloadAction<string>) => {
      state.workflowConfig.name = action.payload;
    },

    updateKnowledgeStoreContent: (
      state,
      action: PayloadAction<FileResource[]>
    ) => {
      state.knowledgeStore.content = action.payload;
      if (state.knowledgeStore.status !== BuilderWorkflowStatus.MODIFIED) {
        state.knowledgeStore.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    hydrateWorkflowConfigAndKnowledgeStore: (
      state,
      action: PayloadAction<{
        knowledgeStore: Omit<BuilderWorkflowKnowledgeStore, 'status'>;
        workflowConfig: Omit<BuilderWorkflowConfig, 'status'>;
      }>
    ) => {
      state.workflowConfig = {
        ...action.payload.workflowConfig,
        status: BuilderWorkflowStatus.SAVED
      };
      state.knowledgeStore = {
        ...action.payload.knowledgeStore,
        status: BuilderWorkflowStatus.SAVED
      };
    },

    createNewWorkflowConfig: (state) => {
      state.workflowConfig = NEW_EMPTY_WORKFLOW_CONFIG;
    },

    markWorkflowConfigSaved: (state) => {
      state.workflowConfig.status = BuilderWorkflowStatus.SAVED;
    },

    markKnowledgeStoreSaved: (state) => {
      state.knowledgeStore.status = BuilderWorkflowStatus.SAVED;
    },

    // =========================================================================
    // Cancel / Clear Actions
    // =========================================================================
    clearActiveStep: (state) => {
      state.activeStep = null;
    }
  }
});

export const workflowBuilderActions = workflowBuilderSlice.actions;
