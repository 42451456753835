import { IntegrationsTab } from './IntegrationSettings';
import { ModelSettings } from './ModelSettings';
import { OrgMemberSettings } from './OrgSettings/OrgMemberSettings';
import { UserGroups } from './OrgSettings/UserGroups';
import { SecretVaultSettingsTab } from './SecretVaultSettings';
import { SettingsTab } from './SettingsModal.types';
import { SubscriptionSettings } from './SubscriptionSettings';

/** Top-level Settings Tabs */
export const SETTINGS_TAB_DISPLAY_NAMES: Record<SettingsTab, string> = {
  [SettingsTab.USER_GROUPS]: 'User Groups',
  [SettingsTab.API]: 'Kindo API',
  [SettingsTab.INTEGRATIONS]: 'Integrations',
  [SettingsTab.MANAGE_SUBSCRIPTION]: 'Manage Plan',
  [SettingsTab.MODELS]: 'Models',
  [SettingsTab.ORG]: 'Organization',
  [SettingsTab.PLUGINS]: 'Plugins',
  [SettingsTab.SECRETS_VAULT]: 'Secrets Vault',
  [SettingsTab.SECURITY]: 'Security'
};
export const SETTINGS_TAB_COMPONENTS: Record<SettingsTab, React.ReactNode | null> = {
  [SettingsTab.USER_GROUPS]: <UserGroups />,
  [SettingsTab.API]: null,
  [SettingsTab.INTEGRATIONS]: <IntegrationsTab />,
  [SettingsTab.MANAGE_SUBSCRIPTION]: <SubscriptionSettings />,
  [SettingsTab.MODELS]: <ModelSettings />,
  [SettingsTab.ORG]: <OrgMemberSettings />,
  [SettingsTab.PLUGINS]: null,
  [SettingsTab.SECRETS_VAULT]: <SecretVaultSettingsTab />,
  [SettingsTab.SECURITY]: null
};
export const ORDERED_SETTINGS_TABS: SettingsTab[] = [SettingsTab.MODELS, SettingsTab.INTEGRATIONS, SettingsTab.ORG, SettingsTab.USER_GROUPS, SettingsTab.MANAGE_SUBSCRIPTION, SettingsTab.SECURITY, SettingsTab.API, SettingsTab.SECRETS_VAULT, SettingsTab.PLUGINS];