import { PaymentTiers } from './PaymentTiers';
import { Typography } from '~/components/core';
import { useCanAccessSecuritySettings } from '~/hooks';
const SubscriptionSettings = () => {
  const {
    userCanManageOrganization
  } = useCanAccessSecuritySettings();
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1.5rem"
  }} data-testid="subscription-settings-container">
      <PaymentTiers userCanManageOrganization={userCanManageOrganization} />
      {!userCanManageOrganization && <div css={{
      "display": "flex",
      "width": "100%",
      "justifyContent": "center"
    }} data-testid="contact-admin-message">
          <Typography italic>
            To upgrade, contact your organization's administrator.
          </Typography>
        </div>}
    </div>;
};
export default SubscriptionSettings;