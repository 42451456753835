import { DefaultOrgControls } from './DefaultOrgControls';
import { OrgBaseDlpFiltersConfig } from './DlpFilters/OrgBaseDlpFiltersConfig';
import { ProviderControls } from './ProviderControls';
import { Button, ButtonType, CalloutType } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants';
import { useCanAccessSecuritySettings } from '~/hooks';
const OrgSecurityControls: React.FC = () => {
  const {
    userCanAccessSecuritySettings,
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();
  const calloutAccessibleText = orgCanAccessSecuritySettings ? '> Model Access and DLP Controls are only available to account admins' : '> This feature is limited for Free and Pro users. To inquire about upgrading, please contact sales.';
  return <div css={{
    "display": "flex",
    "width": "100%",
    "flexDirection": "column",
    "gap": "4rem"
  }} data-testid="privacy-filters-container">
      <SettingsSection data-testid="model-access-section" description="Control which providers and models can be used by your organization and are affected by data loss prevention filters." title="Model Access and DLP Controls">
        <div css={{
        "marginTop": "0.5rem",
        "marginBottom": "0.5rem",
        "display": "flex",
        "flexDirection": "column"
      }} data-testid="callout-container">
          {!userCanAccessSecuritySettings && <Markdown calloutType={orgCanAccessSecuritySettings ? CalloutType.SECURITY : CalloutType.INFO} data-testid="access-callout" endElement={!orgCanAccessSecuritySettings ? <Button data-testid="contact-sales-button" href={MARKETING_WEBSITE_CONTACT_URL} label="Contact Sales" type={ButtonType.SOLID} /> : undefined}>
              {calloutAccessibleText}
            </Markdown>}
        </div>
        <ProviderControls canAccessSecuritySettings={userCanAccessSecuritySettings} />
      </SettingsSection>
      <DefaultOrgControls />
      <OrgBaseDlpFiltersConfig canAccessSecuritySettings={userCanAccessSecuritySettings} />
    </div>;
};
export default OrgSecurityControls;