import _styled from "styled-components";
import { Integration, isSupportedIntegration } from '@kindo/universal';
import React from 'react';
import { IntegrationAccessTable } from './IntegrationAccessTable';
import { Button, ButtonType, CalloutType } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants';
import { ToastType, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const OrgIntegrationAccessContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "4rem"
});
const CalloutContainer = _styled.div({
  "marginTop": "0.5rem",
  "marginBottom": "0.5rem",
  "display": "flex",
  "flexDirection": "column"
});
const OrgIntegrationAccess: React.FC = () => {
  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    userCanAccessSecuritySettings,
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();

  // Queries
  const {
    data,
    refetch: listOrgIntegrationSecurityConfigs
  } = nextTrpc.adminSecurityConfig.listOrgIntegrationSecurityConfigs.useQuery();
  const orgIntegrationSecurityConfigs = data?.items ?? [];

  // sort orgIntegrationSecurityConfig so that supported integrations are at the top
  const sortedOrgIntegrationSecurityConfigs = orgIntegrationSecurityConfigs.sort((a, b) => Number(isSupportedIntegration(b.integration)) - Number(isSupportedIntegration(a.integration)));

  // Mutations
  const updateOrgIntegrationAccessEnabledMutation = nextTrpc.adminSecurityConfig.updateOrgIntegrationAccessEnabled.useMutation({
    onSuccess: () => listOrgIntegrationSecurityConfigs(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update integration access.',
        type: ToastType.ERROR
      });
    }
  });
  const handleIntegrationToggle = (integration: Integration, enabled: boolean) => updateOrgIntegrationAccessEnabledMutation.mutate({
    integration,
    enabled
  });
  const calloutAccessibleText = orgCanAccessSecuritySettings ? '> Integration Access settings are only available to account admins' : '> This feature is limited for Free and Pro users. To inquire about upgrading, please contact sales.';
  return <OrgIntegrationAccessContainer>
      <SettingsSection description={userCanAccessSecuritySettings ? 'Control which integrations can be used by your organization.' : 'Current integration access for your organization.'} title="Integration Access">
        <CalloutContainer>
          {!userCanAccessSecuritySettings && <Markdown calloutType={orgCanAccessSecuritySettings ? CalloutType.SECURITY : CalloutType.INFO} endElement={!orgCanAccessSecuritySettings ? <Button href={MARKETING_WEBSITE_CONTACT_URL} label="Contact Sales" type={ButtonType.SOLID} /> : undefined}>
              {calloutAccessibleText}
            </Markdown>}
        </CalloutContainer>
        <IntegrationAccessTable handleIntegrationToggle={handleIntegrationToggle} integrationConfigs={sortedOrgIntegrationSecurityConfigs} />
      </SettingsSection>
    </OrgIntegrationAccessContainer>;
};
export default OrgIntegrationAccess;