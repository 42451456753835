import { css as _css } from "styled-components";
import _styled from "styled-components";
import { Input as MuiInput } from '@mui/base/Input';
import { InputAdornment } from '@mui/material';
import { useRef, useState } from 'react';
import { Size, TextIconColor } from '../constants';
import { Icon, Icons } from '../Icon';
import { Typography, TypographyFont, TypographySize, TypographyWeight } from '../Typography';
import { StyledInput, StyledRoot, TextArea, TextFieldType } from './TextField.consts';
const animationStyles = _css`
  .MuiInputAdornment-root {
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }

  .MuiInput-input {
    transform: translateX(-24px);
    transition: transform 0.2s ease-out;
  }

  &:hover,
  &:focus-within {
    .MuiInputAdornment-root {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }

    .MuiInput-input {
      transform: translateX(0);
    }
  }
`;
const focusBorderStyles = _css`
  .MuiInput-input {
    border-bottom: 1px solid transparent;
  }

  &:focus-within {
    .MuiInput-input {
      border-bottom: 1px solid #8d76ff;
    }
  }
`;
export const TextFieldAndErrorContainer = _styled.div<{
  $fullWidth: boolean;
  $width: TwStyle | undefined;
  $animateStartIcon?: boolean;
  $showFocusBottomBorder?: boolean;
}>(({
  $fullWidth,
  $width,
  $animateStartIcon,
  $showFocusBottomBorder
}) => [{
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
}, $fullWidth && {
  "width": "100%"
}, $width, $animateStartIcon && animationStyles, $showFocusBottomBorder && focusBorderStyles]);
const StyledInputAdornment = _styled(InputAdornment)({
  "display": "flex",
  "alignItems": "center"
});
interface TextFieldProps {
  onChange: (value: string) => void;
  value: string;
  animateStartIcon?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  endIcon?: Icon | undefined;
  error?: boolean;
  errorMessage?: string;
  font?: TypographyFont;
  fullWidth?: boolean;
  iconColor?: TextIconColor;
  isPassword?: boolean;
  maxRows?: number | undefined;
  minRows?: number | undefined;
  multiline?: boolean;
  onBlur?: (() => void) | undefined;
  onEnter?: (() => void) | undefined;
  placeholder?: string;
  prefix?: string;
  showEndIcon?: boolean;
  showFocusBottomBorder?: boolean;
  size?: TypographySize;
  startIcon?: Icon | undefined;
  startIconFocusColor?: TextIconColor;
  textWeight?: TypographyWeight;
  type?: TextFieldType;
  width?: TwStyle;
}
const TextField: React.FC<TextFieldProps> = ({
  onChange,
  type = TextFieldType.DEFAULT,
  value,
  autoFocus = false,
  disabled = false,
  error = false,
  errorMessage,
  maxRows,
  minRows,
  multiline = false,
  onEnter,
  fullWidth = false,
  onBlur,
  placeholder = '',
  size = TypographySize.X_SMALL,
  width,
  endIcon,
  startIcon,
  font = TypographyFont.HEADING,
  startIconFocusColor = TextIconColor.PRIMARY,
  iconColor = TextIconColor.PRIMARY,
  showEndIcon = true,
  animateStartIcon = false,
  showFocusBottomBorder = false,
  isPassword = false,
  prefix = ''
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter();
      ref.current?.blur();
    }
  };
  const renderEndAdornment = () => {
    if (isFocused && value !== '' && showEndIcon) {
      return <StyledInputAdornment onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
        onChange('');
      }} position="end" sx={{
        cursor: 'pointer'
      }}>
          <Icons color={iconColor} icon={Icon.CLOSE} size={Size.SMALL} />
        </StyledInputAdornment>;
    }
    if (endIcon) {
      return <StyledInputAdornment position="end">
          <Icons color={iconColor} icon={endIcon} />
        </StyledInputAdornment>;
    }
    return null;
  };
  return <TextFieldAndErrorContainer $animateStartIcon={animateStartIcon && !!startIcon && !disabled} $fullWidth={fullWidth} $showFocusBottomBorder={showFocusBottomBorder} $width={width}>
      <Typography font={font} fullWidth size={size}>
        {multiline ? <TextArea $error={error} $type={type} disabled={disabled} maxRows={maxRows || 35} minRows={minRows || 2} onBlur={onBlur} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)} placeholder={placeholder} value={value} /> : <MuiInput autoFocus={autoFocus} disabled={disabled} endAdornment={renderEndAdornment()} inputRef={ref} onBlur={handleBlur} onChange={e => onChange(e.target.value)} onFocus={handleFocus} placeholder={placeholder} slotProps={{
        root: {
          // @ts-expect-error
          $error: error,
          $type: type,
          disabled
        },
        input: {
          disabled,
          onKeyDown: handleKeyDown
        }
      }} slots={{
        root: StyledRoot,
        input: StyledInput
      }} startAdornment={(startIcon || prefix) && <StyledInputAdornment position="start" sx={{
        cursor: 'pointer'
      }}>
                  {startIcon && <Icons color={isFocused ? startIconFocusColor : iconColor} icon={startIcon} />}
                  {prefix && <Typography font={font} size={size}>
                      {prefix}
                    </Typography>}
                </StyledInputAdornment>} type={isPassword ? 'password' : 'text'} value={value} />}
      </Typography>
      {errorMessage && <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.X_SMALL}>
          {errorMessage}
        </Typography>}
    </TextFieldAndErrorContainer>;
};
export default TextField;