import ChatHugeIcon from './assets/chat.huge-icon.svg';
import TriggerHugeIcon from './assets/trigger.huge-icon.svg';
import WorkflowHugeIcon from './assets/workflow.huge-icon.svg';
export enum HugeIcon {
  CHAT = 'CHAT',
  TRIGGER = 'TRIGGER',
  WORKFLOW = 'WORKFLOW',
}
export const HUGE_ICON_SVGS = {
  [HugeIcon.CHAT]: ChatHugeIcon,
  [HugeIcon.TRIGGER]: TriggerHugeIcon,
  [HugeIcon.WORKFLOW]: WorkflowHugeIcon
} as const;