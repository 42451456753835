import { RequireOnlyOne } from '@kindo/universal';
import React from 'react';
import { Size, TextIconColor } from '../constants';
import { withLink } from '../hocs';
import { Icon } from '../Icon/Icon.consts';
import Icons from '../Icon/Icons';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { ToolTip } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { IconButtonType } from './IconButton.consts';
import { IconButtonSize } from './IconButton.styles';
import { iconButtonStyles } from './IconButton.styles';
interface BaseIconButtonProps {
  href: string;
  icon: Icon;
  onClick: () => void;
  type: IconButtonType;
  color?: TextIconColor;
  'data-id'?: string;
  disabled?: boolean;
  growOnHover?: boolean;
  hidden?: boolean;
  highlightOnHover?: boolean;
  invert?: boolean;
  loading?: boolean;
  size?: IconButtonSize;
  title?: string;
  tooltip?: string;
}

// Ensures that either onClick or href is provided
type IconButtonProps = RequireOnlyOne<BaseIconButtonProps, 'onClick' | 'href'>;
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type,
  onClick,
  href,
  color = TextIconColor.PRIMARY,
  'data-id': dataId,
  disabled = false,
  hidden = false,
  invert = false,
  loading = false,
  size = Size.MEDIUM,
  growOnHover = false,
  title,
  tooltip,
  highlightOnHover = false
}) => {
  const getIconColor = () => {
    if (disabled) return TextIconColor.DISABLED;
    return color;
  };
  const iconColor = getIconColor();
  const disableOnClick = disabled || loading;
  const handleOnClick = (e: React.MouseEvent) => {
    if (disableOnClick) return;

    // Do NOT stop propagation if it's a link,
    // it will break next routing from working properly
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return withLink(<ToolTip content={tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <button className={iconButtonStyles({
      buttonType: type,
      size,
      disabled: disabled || hidden,
      hidden,
      growOnHover,
      highlightOnHover
    })} data-id={dataId} disabled={disabled || hidden} onClick={handleOnClick} title={title}>
        {!loading && <Icons color={iconColor} icon={icon} invert={invert} size={size} />}
        {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
      </button>
    </ToolTip>, {
    href
  });
};
export default IconButton;