import { CustomDlpFilterType, DlpFilterPolicy, insertIf, isValidRegex } from '@kindo/universal';
import { useState } from 'react';
import { CustomDlpFilter } from '../DlpFilters.types';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Select, Size, Table, TableColumn, TextField, TextFieldType } from '~/components/core';
interface CustomDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  createCustomDlpFilter: (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => void;
  customDlpFilters: CustomDlpFilter[];
  deleteCustomDlpFilter: (value: string) => void;
}
const CustomDlpFilters: React.FC<CustomDlpFiltersProps> = ({
  canAccessSecuritySettings,
  deleteCustomDlpFilter,
  customDlpFilters,
  createCustomDlpFilter
}) => {
  // State
  const [newFilterInputValue, setNewFilterInputValue] = useState<string>('');
  const [newFilterInputError, setNewFilterInputError] = useState<string>('');
  const [newFilterType, setNewFilterType] = useState<CustomDlpFilterType>(CustomDlpFilterType.LITERAL);
  const newFilterPlaceholder = newFilterType === CustomDlpFilterType.LITERAL ? 'Private phrase' : 'Private.*phrase';
  const createNewFilter = () => {
    const trimmedInput = newFilterInputValue.trim();
    if (!trimmedInput) {
      setNewFilterInputError('Filter cannot be empty.');
      return;
    }

    // Validate regex
    if (newFilterType === CustomDlpFilterType.REGEX && !isValidRegex(trimmedInput)) {
      setNewFilterInputError('Invalid regex entered.');
    }
    createCustomDlpFilter(DlpFilterPolicy.REDACT, newFilterType, trimmedInput);
  };
  return <div css={{
    "marginBottom": "0.75rem",
    "display": "flex",
    "width": "100%",
    "flexDirection": "column",
    "gap": "1rem"
  }} data-testid="custom-privacy-filters-container">
      {canAccessSecuritySettings && <div css={{
      "display": "flex",
      "gap": "1rem"
    }} data-testid="create-new-filter-container">
          <div css={{
        "marginTop": "0.5rem",
        "marginBottom": "0.5rem",
        "display": "flex",
        "width": "70%",
        "maxWidth": "32rem",
        "gap": "0.5rem"
      }} data-testid="new-filter-type-and-input">
            <Select buttonSize={Size.SMALL} onChange={value => setNewFilterType(value as CustomDlpFilterType)} options={[{
          label: 'Phrase',
          value: CustomDlpFilterType.LITERAL
        }, {
          label: 'Regex',
          value: CustomDlpFilterType.REGEX
        }]} placeholderLabel="Type" value={newFilterType} />
            <TextField error={!!newFilterInputError} errorMessage={newFilterInputError} fullWidth onChange={value => {
          setNewFilterInputError('');
          setNewFilterInputValue(value);
        }} onEnter={createNewFilter} placeholder={newFilterPlaceholder} type={TextFieldType.DEFAULT} value={newFilterInputValue} />
            <Button label="Create" onClick={createNewFilter} size={Size.SMALL} type={ButtonType.SOLID} />
          </div>
        </div>}
      <Table columns={[{
      title: 'Value'
    }, {
      title: 'Type',
      width: {
        "width": "16.666667%"
      }
    }, ...insertIf<TableColumn>(canAccessSecuritySettings, {
      title: '',
      width: {
        "width": "8.333333%"
      }
    })]} noRowsText="No custom filters created." rows={customDlpFilters.map(customDlpFilter => ({
      key: customDlpFilter.value,
      cells: [customDlpFilter.value, customDlpFilter.type, ...insertIf(canAccessSecuritySettings, <IconButton highlightOnHover icon={Icon.TRASH} onClick={() => deleteCustomDlpFilter(customDlpFilter.value)} type={IconButtonType.NONE} />)]
    }))} />
    </div>;
};
export default CustomDlpFilters;