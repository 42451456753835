import { insertIf } from '@kindo/universal';

import { useAppDispatch } from './typedReduxHooks';

import { Icon } from '~/components/core';
import { DropdownItemProps } from '~/components/Dropdown/DropdownItem';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { WorkflowWithPermissions } from '~/types';

interface UseWorkflowDropdownItemsReturn {
  getWorkflowDropdownItems: (args: {
    permissions: WorkflowWithPermissions['permissions'];
    workflowId: string;
    workflowTitle: string;
  }) => DropdownItemProps[];
}

const useWorkflowDropdownItems = (): UseWorkflowDropdownItemsReturn => {
  // Redux
  const dispatch = useAppDispatch();
  const openShareWorkflowModal = (workflowId: string) =>
    dispatch(
      modalActions.openModal({
        type: Modal.SHARE_WORKFLOW,
        workflowId
      })
    );
  const openDeleteWorkflowModal = (workflowId: string, workflowTitle: string) =>
    dispatch(
      modalActions.openModal({
        type: Modal.DELETE_WORKFLOW,
        workflowId,
        workflowTitle
      })
    );
  const openDuplicateWorkflowModal = (
    workflowId: string,
    workflowTitle: string
  ) =>
    dispatch(
      modalActions.openModal({
        type: Modal.DUPLICATE_WORKFLOW,
        workflowId,
        workflowTitle
      })
    );

  const getWorkflowDropdownItems = ({
    workflowId,
    workflowTitle,
    permissions
  }: {
    permissions: WorkflowWithPermissions['permissions'];
    workflowId: string;
    workflowTitle: string;
  }): DropdownItemProps[] => [
    {
      title: 'Duplicate',
      icon: Icon.COPY,
      onClick: () => openDuplicateWorkflowModal(workflowId, workflowTitle)
    },

    ...insertIf(permissions?.share, {
      title: 'Share',
      icon: Icon.SHARE,
      onClick: () => openShareWorkflowModal(workflowId)
    }),

    ...insertIf(permissions?.delete, {
      title: 'Delete',
      icon: Icon.TRASH,
      onClick: () => openDeleteWorkflowModal(workflowId, workflowTitle),
      destructive: true
    })
  ];

  return { getWorkflowDropdownItems };
};

export default useWorkflowDropdownItems;
