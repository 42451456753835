export type ContinueConfigType = {
  apiKey: string;
  chatModel: boolean;
  model: string;
  provider: string;
  tabAutocompleteModel: boolean;
  title: string;
};
export const generateContinueConfig = (availableModels: ContinueConfigType[]) => {
  const chatModels = availableModels.filter(model => model.chatModel).map(model => ({
    title: model.title,
    provider: 'kindo',
    model: model.model,
    apiKey: '<KINDO_API_KEY>',
    template: 'none'
  }));
  const tabAutocompleteModels = availableModels.filter(model => model.tabAutocompleteModel).map(model => ({
    title: model.title,
    provider: 'kindo',
    model: model.model,
    apiKey: '<KINDO_API_KEY>'
  }));
  const config = {
    allowAnonymousTelemetry: false,
    models: chatModels,
    tabAutocompleteModel: tabAutocompleteModels,
    customCommands: [{
      name: 'test',
      prompt: "{{{ input }}}\n\nWrite a comprehensive set of unit tests for the selected code. It should setup, run tests that check for correctness including important edge cases, and teardown. Ensure that the tests are complete and sophisticated. Give the tests just as chat output, don't edit any file.",
      description: 'Write unit tests for highlighted code'
    }],
    contextProviders: [{
      name: 'code',
      params: {}
    }, {
      name: 'docs',
      params: {}
    }, {
      name: 'diff',
      params: {}
    }, {
      name: 'terminal',
      params: {}
    }, {
      name: 'problems',
      params: {}
    }, {
      name: 'folder',
      params: {}
    }, {
      name: 'codebase',
      params: {}
    }],
    slashCommands: [{
      name: 'edit',
      description: 'Edit selected code'
    }, {
      name: 'comment',
      description: 'Write comments for the selected code'
    }, {
      name: 'share',
      description: 'Export the current chat session to markdown'
    }, {
      name: 'cmd',
      description: 'Generate a shell command'
    }, {
      name: 'commit',
      description: 'Generate a git commit message'
    }]
  };

  // Return config JSON string with correct formatting and indentation
  return JSON.stringify(config, null, 4);
};