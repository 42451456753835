import _styled from "styled-components";
import { Popper } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON } from './WorkflowIntegrationInput.consts';
import { getAddIntegrationInputButtonLabel } from './WorkflowIntegrationInput.utils';
import { WorkflowIntegrationInputEditor } from './WorkflowIntegrationInputEditor';
import { Button, ButtonType, Icon } from '~/components/core';
import useActiveBuilderStepIntegrationInput from '~/hooks/useActiveBuilderStepIntegrationInput';
import { BuilderWorkflowLlmStep, IntegrationWorkflowInput } from '~/types';
const DropdownChildContainer = _styled.div({
  "width": "400px",
  "borderRadius": "0.5rem",
  "borderWidth": "1px",
  "borderColor": "#6761fa5e",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))",
  "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
});
interface WorkflowIntegrationInputProps {
  activeStep: BuilderWorkflowLlmStep;
  workflowIntegrationInput: IntegrationWorkflowInput;
}
const WorkflowIntegrationInput: React.FC<WorkflowIntegrationInputProps> = ({
  activeStep,
  workflowIntegrationInput
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    integrationInputActiveInEditor,
    clearIntegrationInputActiveInEditor
  } = useActiveBuilderStepIntegrationInput({
    activeStep
  });
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (containerRef.current && popperRef.current && !containerRef.current.contains(event.target as Node) && !popperRef.current.contains(event.target as Node)) {
      setAnchorEl(null);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  const handleTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleCloseEditor = () => {
    setAnchorEl(null);
    clearIntegrationInputActiveInEditor();
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (integrationInputActiveInEditor) {
      setAnchorEl(containerRef.current);
    }
  }, [integrationInputActiveInEditor]);
  return <div ref={containerRef}>
      <Button endIcon={Icon.PLUS} label={getAddIntegrationInputButtonLabel(workflowIntegrationInput)} onClick={handleTriggerClick} startIcon={WORKFLOW_SUPPORTED_INTEGRATION_TO_ICON[workflowIntegrationInput.integrationName]} type={open ? ButtonType.SOLID_COLOR : ButtonType.OUTLINED} />
      <Popper anchorEl={anchorEl} modifiers={[{
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    }, {
      name: 'preventOverflow',
      enabled: true,
      options: {
        boundariesElement: 'viewport',
        padding: 8
      }
    }]} open={open} placement="bottom-start" ref={popperRef} sx={{
      zIndex: 5
    }}>
        <DropdownChildContainer>
          <WorkflowIntegrationInputEditor activeStep={activeStep} integrationInputActiveInEditor={integrationInputActiveInEditor} onClose={handleCloseEditor} workflowIntegrationInput={workflowIntegrationInput} />
        </DropdownChildContainer>
      </Popper>
    </div>;
};
export default WorkflowIntegrationInput;