import { TextIconColor } from '../constants/colors.consts';
import { Typography } from '../Typography';
import { TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../Typography/Typography.consts';
import { BadgeColor, BadgeType } from './Badge.consts';
import { badgeStyles } from './Badge.styles';
interface BadgeProps {
  label: string;
  active?: boolean;
  color?: BadgeColor;
  semiRounded?: boolean;
  type?: BadgeType;
}
const Badge = ({
  label,
  type = BadgeType.SOLID,
  semiRounded = false,
  active = true,
  color = BadgeColor.DEFAULT
}: BadgeProps) => {
  // Apply styles using tailwind-variants with explicit values
  const className = badgeStyles({
    type,
    color,
    active,
    semiRounded
  });
  return <div className={className} id="badge-container">
      <Typography casing={TypographyCasing.UPPERCASE} color={color === BadgeColor.WARNING && type === BadgeType.SOLID ? TextIconColor.BLACK : TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
        {label}
      </Typography>
    </div>;
};
export default Badge;