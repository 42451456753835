import { ConnectedWorkflowSupportedIntegration, getIntegrationDisplayName, isWorkflowSupportedIntegration, OrgPaymentTier, WORKFLOW_SUPPORTED_INTEGRATIONS, WorkflowInputType } from '@kindo/universal';
import React, { useState } from 'react';
import { WorkflowBuilderConfiguration } from '../WorkflowBuilderConfiguration';
import { WORKFLOW_TRIGGER_ENTERPRISE_TOOLTIP } from './WorkflowBuilderCanvas.utils';
import { WorkflowTimeline } from './WorkflowTimeline';
import { WorkflowTrigger } from './WorkflowTrigger';
import { Button, ButtonType, Icon, Size } from '~/components/core';
import { ToolTip } from '~/components/core/ToolTip';
import { SettingsTab } from '~/components/SettingsModal/SettingsModal.types';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { useSettingsModal } from '~/contexts/SettingsModalContext';
import { useAppDispatch, useAppSelector, useGetWorkflowFromUrlSlug } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { nextTrpc } from '~/trpc';
import { isPromptTemplateWorkflowBuilderStepInput, Workflow } from '~/types';
type WorkflowBuilderCanvasProps = {
  isLoading: boolean;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
  stepsWithSecretPlaceholders?: number[];
};
const WorkflowBuilderCanvas: React.FC<WorkflowBuilderCanvasProps> = ({
  workflow,
  isLoading,
  refetchWorkflow,
  stepsWithSecretPlaceholders = []
}) => {
  const {
    user
  } = useUserInfo();
  const canEditTriggers = user?.id === workflow.creator.id;
  const isEnterpriseOrg = user?.org.paymentTier === OrgPaymentTier.ENTERPRISE;
  const {
    data: connectedIntegrations
  } = nextTrpc.integrations.listConnectedIntegrations.useQuery();
  const availableIntegrations: ConnectedWorkflowSupportedIntegration[] = connectedIntegrations?.filter((integration): integration is ConnectedWorkflowSupportedIntegration => isWorkflowSupportedIntegration(integration.integrationName)) ?? [];
  const hasAvailableIntegrations = availableIntegrations.length > 0;
  const [showCreateNewTriggerModal, setShowCreateNewTriggerModal] = useState(false);

  // Redux
  const {
    activeStep
  } = useAppSelector(state => state.workflowBuilder);
  const {
    activeTrigger
  } = useAppSelector(state => state.workflowBuilderTrigger);
  const dispatch = useAppDispatch();
  const {
    openSettingsModal
  } = useSettingsModal();
  const handleCreateNewTrigger = () => {
    const stepsWithUserInputs = workflow.steps.filter(step => step.inputs.some(input => input.type === WorkflowInputType.TEXT_OR_CONTENT));
    if (stepsWithUserInputs.length > 0 || activeStep?.inputs.some(input => input.type === WorkflowInputType.TEXT_OR_CONTENT)) {
      setShowCreateNewTriggerModal(true);
      return;
    }
    dispatch(triggerActions.createNewTrigger({
      workflowId: workflow.id
    }));
  };
  const addTriggerModalSubtext = () => {
    const stepsWithUserInputs = workflow.steps.filter(step => step.inputs.some(input => input.type === WorkflowInputType.TEXT_OR_CONTENT)) ?? [];
    const activeStepUserInputs = activeStep?.inputs.filter(isPromptTemplateWorkflowBuilderStepInput) ?? [];
    return stepsWithUserInputs.map(step => activeStep?.stepNumber === step.stepNumber ? `Active step - ${activeStepUserInputs.map(input => `"${input.displayName}"`).join(', ')}` : `Step ${step.stepNumber} - ${step.inputs.filter(input => isPromptTemplateWorkflowBuilderStepInput(input)).map(input => `"${input.displayName}"`).join(', ')}`).join(', ');
  };

  // We currently only support one trigger per workflow
  const triggerToRender = workflow.triggers[0];
  const showWorkflowTrigger = activeTrigger || triggerToRender;
  const handleIntegrationsClick = () => {
    openSettingsModal(SettingsTab.INTEGRATIONS);
  };
  const getTriggerTooltip = (): string | undefined => {
    if (!isEnterpriseOrg) {
      return WORKFLOW_TRIGGER_ENTERPRISE_TOOLTIP;
    }
    if (!canEditTriggers) {
      return 'Only the creator of the agent can add triggers';
    }
    if (!hasAvailableIntegrations) {
      return `No supported trigger integration connected.\nTriggers are available for the following integrations: ${WORKFLOW_SUPPORTED_INTEGRATIONS.map(getIntegrationDisplayName).join(', ')}.\n\nClick here to view integrations settings`;
    }
    return undefined;
  };
  return <>
      {!isLoading && <>
          <div css={{
        "display": "flex",
        "height": "100%",
        "flexDirection": "column",
        "gap": "1rem",
        "overflowY": "auto"
      }} data-testid="workflow-builder-shared-overflow-container">
            {showWorkflowTrigger ? <WorkflowTrigger canEditTriggers={canEditTriggers} connectedIntegrations={availableIntegrations} refetchWorkflow={refetchWorkflow} savedTrigger={triggerToRender} workflowId={workflow.id} /> : <div css={{
          "display": "flex",
          "flexShrink": "0",
          "justifyContent": "center"
        }} data-testid="add-workflow-trigger-button-container">
                <div css={{
            "display": "inline-block",
            "width": "100%"
          }} data-testid="tooltip-wrapper">
                  <ToolTip content={getTriggerTooltip()} interactive offset={[0, 8]} onClick={!hasAvailableIntegrations && isEnterpriseOrg ? handleIntegrationsClick : undefined}>
                    <span>
                      <Button disabled={!hasAvailableIntegrations || !isEnterpriseOrg || !canEditTriggers} endIcon={Icon.PLUS} fullWidth label="Add Trigger" onClick={handleCreateNewTrigger} size={Size.LARGE} type={ButtonType.OUTLINED} />
                    </span>
                  </ToolTip>
                </div>
              </div>}
            <WorkflowTimeline activeStep={activeStep} isEnterpriseOrg={isEnterpriseOrg} refetchWorkflow={refetchWorkflow} stepsWithSecretPlaceholders={stepsWithSecretPlaceholders} workflow={workflow} />
          </div>
          <WorkflowBuilderConfiguration refetchWorkflow={refetchWorkflow} workflow={workflow} />
        </>}
      <ConfirmationModal confirmButtonLabel="Ok" header="Agents with triggers do not support user input variables on LLM steps" onCancel={() => setShowCreateNewTriggerModal(false)} onConfirm={() => setShowCreateNewTriggerModal(false)} open={showCreateNewTriggerModal} subtext={`Remove the saved user inputs on the following LLM steps before creating a trigger: ${addTriggerModalSubtext()}`} />
    </>;
};
export default WorkflowBuilderCanvas;