import _styled from "styled-components";
import React, { useState } from 'react';
import { WorkflowApiActionStepEditor } from './WorkflowApiActionStepEditor';
import { WorkflowIntegrationActionStepEditor } from './WorkflowIntegrationActionStepEditor';
import { WorkflowLlmStepEditor } from './WorkflowLlmStepEditor';
import { Button, ButtonType, Icon, IconButton, IconButtonType } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useActiveBuilderStep, useDeleteWorkflowStep, useGetWorkflowFromUrlSlug, useToast } from '~/hooks';
import useActiveBuilderStepIntegrationInput from '~/hooks/useActiveBuilderStepIntegrationInput';
import useUserInfo from '~/hooks/useUserInfo';
import { BuilderWorkflowStatus, BuilderWorkflowStep, isApiActionWorkflowBuilderStep, isIntegrationActionWorkflowBuilderStep, isLlmWorkflowBuilderStep, Workflow } from '~/types';
const WorkflowStepEditorContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem",
  "paddingLeft": "1.5rem",
  "paddingRight": "1rem"
});
const HeaderAndEditor = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const FooterContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
});
const CancelSaveButtonsContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});
interface WorkflowStepEditorProps {
  executeApiActionStep: (step: BuilderWorkflowStep) => void;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  step: BuilderWorkflowStep;
  workflow: Workflow;
}
const WorkflowStepEditor: React.FC<WorkflowStepEditorProps> = ({
  executeApiActionStep,
  refetchWorkflow,
  step,
  workflow
}) => {
  // State
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showConfirmStaticContentShare, setShowConfirmStaticContentShare] = useState<boolean>(false);

  // Custom hooks
  const {
    deleteWorkflowStep,
    isDeleting
  } = useDeleteWorkflowStep(step.id, refetchWorkflow);
  const {
    user
  } = useUserInfo();
  const {
    canSave,
    saveValidationMessage,
    requiresStaticContentConfirmation,
    isSavingActiveStep,
    isNewStep,
    clearActiveStep,
    saveActiveStep,
    updateActiveStepAction,
    updateActiveStepPrompt,
    updateActiveStepModel,
    updateActiveStepActionConfig
  } = useActiveBuilderStep({
    activeStep: step,
    refetchWorkflow,
    workflow
  });
  const isLlmStep = isLlmWorkflowBuilderStep(step);
  const isIntegrationActionStep = isIntegrationActionWorkflowBuilderStep(step);
  const isApiActionStep = isApiActionWorkflowBuilderStep(step);
  const isCreator = user?.id === workflow.creator.id;
  const isEditDisabled = isApiActionStep && !isCreator;
  const {
    addIntegrationInput,
    setIntegrationInputActiveInEditor,
    updateActiveStepIntegration
  } = useActiveBuilderStepIntegrationInput({
    activeStep: step
  });
  const {
    enqueueToast
  } = useToast();

  // Handlers
  const handleDeleteStep = async () => {
    await deleteWorkflowStep();
    setShowConfirmDeleteModal(false);
  };
  const {
    inputs: workflowInputs
  } = workflow;
  const handleNonEditStepDelete = () => setShowConfirmDeleteModal(true);
  const handleSave = () => {
    if (isLlmStep && requiresStaticContentConfirmation) {
      setShowConfirmStaticContentShare(true);
    } else {
      saveActiveStep();
    }
  };
  const handleTestApiActionStep = async () => {
    if (!canSave) {
      executeApiActionStep(step);
      return;
    }
    const savedStep = await saveActiveStep({
      clearActiveStep: false
    });
    if (!savedStep) {
      enqueueToast({
        message: 'Failed to start test after saving, please try testing again.',
        type: ToastType.WARNING
      });
      console.error('No saved step found after calling saveActiveStep.');
      return;
    }
    executeApiActionStep(savedStep);
  };
  const isUnsavedAndInvalid = !canSave && step.status !== BuilderWorkflowStatus.SAVED;
  return <>
      <WorkflowStepEditorContainer>
        <HeaderAndEditor>
          {isLlmStep && <WorkflowLlmStepEditor setIntegrationInputActiveInEditor={setIntegrationInputActiveInEditor} step={step} updateActiveStepModel={updateActiveStepModel} updateActiveStepPrompt={updateActiveStepPrompt} workflowInputs={workflowInputs} />}
          {isIntegrationActionStep && <WorkflowIntegrationActionStepEditor step={step} updateActiveStepAction={updateActiveStepAction} updateActiveStepActionConfig={updateActiveStepActionConfig} updateActiveStepInput={addIntegrationInput} updateActiveStepIntegration={updateActiveStepIntegration} workflow={workflow} workflowInputs={workflowInputs} />}
          {isApiActionStep && <WorkflowApiActionStepEditor disabled={isEditDisabled} step={step} />}
        </HeaderAndEditor>
        <FooterContainer>
          <IconButton disabled={isEditDisabled} icon={Icon.TRASH} onClick={isNewStep ? clearActiveStep : handleNonEditStepDelete} type={IconButtonType.OUTLINED} />
          <CancelSaveButtonsContainer>
            <Button disabled={isEditDisabled} label={step.status === BuilderWorkflowStatus.SAVED ? 'Close' : 'Cancel'} onClick={clearActiveStep} type={ButtonType.TEXT} />
            {isApiActionStep && <Button disabled={isUnsavedAndInvalid || isEditDisabled} label={canSave ? 'Save and Test' : 'Test'} onClick={handleTestApiActionStep} tooltip={isUnsavedAndInvalid ? 'Step must be able to be saved before testing: ' + saveValidationMessage : undefined} type={ButtonType.OUTLINED_COLOR} />}
            <Button disabled={!canSave} label="Save" loading={isSavingActiveStep} onClick={handleSave} tooltip={!canSave && saveValidationMessage ? saveValidationMessage : undefined} type={ButtonType.SOLID_COLOR} />
          </CancelSaveButtonsContainer>
        </FooterContainer>
      </WorkflowStepEditorContainer>

      {/* Confirm workflow step deletion */}
      <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={isDeleting} header="Are you sure you want to delete this step?" onCancel={() => setShowConfirmDeleteModal(false)} onConfirm={handleDeleteStep} open={showConfirmDeleteModal} subtext="A deleted step can not be recovered." />

      {/* Confirm addition of new files to shared workflow */}
      <ConfirmationModal confirmButtonLabel="Save and share" confirmLoading={isSavingActiveStep} header="Add new files to shared agent." onCancel={() => setShowConfirmStaticContentShare(false)} onConfirm={saveActiveStep} open={showConfirmStaticContentShare} subtext="It is possible that the contents of these files could be exposed to a runner of this agent. Would you like to continue adding these files?" />
    </>;
};
export default WorkflowStepEditor;