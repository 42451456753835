import React from 'react';
import { TailwindMaxWidthClass } from '../../../utils/tailwind.types';
import { TextIconColor } from '../constants';
import { withLink } from '../hocs';
import { TypographyAlignment, TypographyCasing, TypographyFont, TypographyLetterSpacing, TypographySize, TypographyTransition, TypographyWeight, TypographyWrap } from './Typography.consts';
import { typographyStyles } from './Typography.styles';
type LinkProps = {
  href: string;
  openLinkInNewTab?: boolean;
};
type NonLinkProps = {
  href?: never;
  openLinkInNewTab?: never;
};
type BaseTypographyProps = {
  children: React.ReactNode;
  alignment?: TypographyAlignment;
  casing?: TypographyCasing;
  color?: TextIconColor | undefined;
  fitContent?: boolean;
  font?: TypographyFont;
  fullWidth?: boolean;
  italic?: boolean;
  letterSpacing?: TypographyLetterSpacing;
  maxWidth?: TailwindMaxWidthClass | undefined;
  onClick?: () => void;
  size?: TypographySize;
  transition?: TypographyTransition;
  underline?: boolean;
  // Number = pixels
  weight?: TypographyWeight;
  wrap?: TypographyWrap | undefined;
};
type TypographyProps = BaseTypographyProps & (LinkProps | NonLinkProps);
const Typography: React.FC<TypographyProps> = ({
  children,
  alignment = TypographyAlignment.LEFT,
  casing = TypographyCasing.NONE,
  color = TextIconColor.PRIMARY,
  fullWidth = false,
  href,
  openLinkInNewTab = false,
  italic = false,
  letterSpacing = TypographyLetterSpacing.NORMAL,
  fitContent = false,
  font = TypographyFont.PARAGRAPH,
  maxWidth,
  onClick,
  size = TypographySize.MEDIUM,
  underline = false,
  weight = TypographyWeight.NORMAL,
  wrap = TypographyWrap.NORMAL,
  transition = TypographyTransition.NONE
}) => {
  const className = typographyStyles({
    alignment,
    casing,
    color,
    fitContent,
    font,
    fullWidth,
    isLink: !!href,
    italic,
    letterSpacing,
    size,
    transition,
    underline,
    weight,
    wrap,
    className: maxWidth
  });
  return withLink(<span className={className} id="typography-container" onClick={onClick}
  // Caused by issue with SSR and local storage for chat modal selected
  // TODO: Find a better solution and remove this
  suppressHydrationWarning>
      {children}
    </span>, {
    href,
    newTab: openLinkInNewTab
  });
};
export default Typography;