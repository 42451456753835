import { useRouter } from 'next/router';
import React from 'react';
import { useSettingsModal } from '../../contexts/SettingsModalContext';
import TextLogoSvg from '../assets/logo-text.svg';
import { withLink } from '../core/hocs';
import { SegmentedControl } from '../core/SegmentedControl';
import { SegmentedControlType } from '../core/SegmentedControl/SegmentedControl.consts';
import { NAV_SEGMENT_DISPLAY_NAMES, NAV_SEGMENT_ROUTES, NavSegment } from './Nav.consts';
import { NavButton } from './NavButton';
import { ProfileButton } from './ProfileButton';
import { Icon, IconButton, IconButtonType, Size } from '~/components/core';
import { ROOT_ROUTE, SUPPORT_ROUTE, WORKFLOWS_ROUTE } from '~/constants';
const Nav = () => {
  const router = useRouter();
  const {
    openSettingsModal
  } = useSettingsModal();

  // Initialize navSegment based on current route
  const getCurrentSegment = (): NavSegment => {
    if (router.pathname.includes(WORKFLOWS_ROUTE)) return NavSegment.AGENTS;
    return NavSegment.CHAT;
  };
  return <nav css={{
    "position": "relative",
    "display": "flex",
    "height": "3.5rem",
    "width": "100%",
    "flexDirection": "row",
    "alignItems": "center",
    "justifyContent": "space-between",
    "borderBottomWidth": "1px",
    "borderColor": "#6761fa5e",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "@media (min-width: 640px)": {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    }
  }} data-testid="nav-container">
      <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "gap": "0.125rem",
      "@media (min-width: 640px)": {
        "gap": "0.25rem"
      },
      "@media (min-width: 768px)": {
        "gap": "0.5rem"
      }
    }} data-testid="nav-logo-group">
        {/* New Chat (Home) */}
        {withLink(<span css={{
        "width": "8rem",
        "--tw-text-opacity": "1",
        "color": "rgb(255 255 255 / var(--tw-text-opacity))"
      }} data-testid="nav-logo-container">
            <TextLogoSvg />
          </span>, {
        href: ROOT_ROUTE,
        fullSize: true
      })}
      </div>
      <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "gap": "0.125rem",
      "@media (min-width: 640px)": {
        "gap": "0.25rem"
      },
      "@media (min-width: 768px)": {
        "gap": "0.5rem"
      }
    }} data-testid="nav-center-group">
        <div css={{
        "position": "absolute",
        "left": "50%",
        "top": "50%",
        "width": "auto",
        "minWidth": "0px",
        "--tw-translate-x": "-50%",
        "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
        "--tw-translate-y": "-50%"
      }} data-testid="nav-segmented-control">
          <SegmentedControl segmentOptions={[{
          label: NAV_SEGMENT_DISPLAY_NAMES[NavSegment.CHAT],
          value: NavSegment.CHAT,
          icon: Icon.CHAT,
          href: NAV_SEGMENT_ROUTES[NavSegment.CHAT]
        }, {
          label: NAV_SEGMENT_DISPLAY_NAMES[NavSegment.AGENTS],
          value: NavSegment.AGENTS,
          icon: Icon.BOLT,
          href: NAV_SEGMENT_ROUTES[NavSegment.AGENTS]
        }]} type={SegmentedControlType.SOLID_HIGHLIGHT} value={getCurrentSegment()} />
        </div>
      </div>
      <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "gap": "0.125rem",
      "@media (min-width: 640px)": {
        "gap": "0.25rem"
      },
      "@media (min-width: 768px)": {
        "gap": "0.5rem"
      }
    }} data-testid="nav-actions-group">
        {/* Settings */}
        <div css={{
        "&:hover svg": {
          "--tw-text-opacity": "1",
          "color": "rgb(141 118 255 / var(--tw-text-opacity))"
        }
      }} data-testid="nav-settings-button">
          <IconButton data-id="nav_settings" icon={Icon.SETTINGS} onClick={() => openSettingsModal()} size={Size.LARGE} type={IconButtonType.NONE} />
        </div>
        {/* Support */}
        <NavButton data-id="nav_support" icon={Icon.SUPPORT} isCurrentRoute={router.pathname.includes(SUPPORT_ROUTE)} route={SUPPORT_ROUTE} />
        {/* Profile */}
        <ProfileButton />
      </div>
    </nav>;
};
export default React.memo(Nav);