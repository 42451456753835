import { isCybersecurityFocusedLlm, isGeneralPurposeLlm, isLongContextLlm, isModelCategory, isMultimodalLlm, isReasoningLlm, ModelCategory, SupportedLlm } from '@kindo/universal';
import { useState } from 'react';
import { tv } from 'tailwind-variants';
import { MODEL_CATEGORY_DESCRIPTIONS, MODEL_CATEGORY_TITLES, MODEL_CATEGORY_TO_MODELS } from './ModelSelectionModal.consts';
import { Button, ButtonType, Modal, ModalWidth, Select, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { COST_TIER_INDICATOR_TEXT, MODEL_COST_TIERS } from '~/components/SettingsModal/ModelSettings/ModelChoice/ModelChoice.consts';
import { LocalStorageKey } from '~/constants/localStorage.consts';
import { useGetEnabledModels } from '~/hooks';
import useLocalStorage from '~/hooks/useLocalStorage';

// Styles
const categoryCardStyles = tv({
  base: 'flex w-full cursor-pointer flex-col justify-between gap-4 rounded border-2 border-transparent p-4 text-left transition-all duration-200',
  variants: {
    isSelected: {
      true: 'bg-primary-active hover:border-primary-panel',
      false: 'bg-primary-panel hover:border-primary-active'
    }
  }
});
interface ModelSelectionModalProps {
  chatModelIdentifier: string; // DO NOT REMOVE COMMENT: SupportedLlm
  onChange: (modelIdentifier: string) => void; // DO NOT REMOVE COMMENT: modelIdentifier: SupportedLlm
  placeholderLabel: string;
  prefix: string;
}
const ModelSelectionModal: React.FC<ModelSelectionModalProps> = ({
  onChange,
  chatModelIdentifier,
  prefix,
  placeholderLabel
}) => {
  // Custom Hooks
  const [selectedModelCategory, setSelectedModelCategory] = useLocalStorage(LocalStorageKey.MODEL_CATEGORY);
  const [selectedGeneralPurposeModel, setSelectedGeneralPurposeModel] = useLocalStorage(LocalStorageKey.GENERAL_PURPOSE_MODEL);
  const [selectedLongContextModel, setSelectedLongContextModel] = useLocalStorage(LocalStorageKey.LONG_CONTEXT_MODEL);
  const [selectedReasoningModel, setSelectedReasoningModel] = useLocalStorage(LocalStorageKey.REASONING_MODEL);
  const [selectedCybersecurityModel, setSelectedCybersecurityModel] = useLocalStorage(LocalStorageKey.CYBERSECURITY_MODEL);
  const [selectedMultimodalModel, setSelectedMultimodalModel] = useLocalStorage(LocalStorageKey.MULTIMODAL_MODEL);

  // State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ModelCategory | null>(selectedModelCategory);

  // Map from category to selected model in localStorage
  const CATEGORY_SELECTED_MODEL: Record<ModelCategory, SupportedLlm> = {
    [ModelCategory.GENERAL_PURPOSE]: selectedGeneralPurposeModel,
    [ModelCategory.LONG_CONTEXT]: selectedLongContextModel,
    [ModelCategory.REASONING]: selectedReasoningModel,
    [ModelCategory.CYBERSECURITY_FOCUSED]: selectedCybersecurityModel,
    [ModelCategory.MULTIMODAL]: selectedMultimodalModel
  };
  const {
    enabledModels,
    modelIdentifierToDisplayName
  } = useGetEnabledModels({
    onChange,
    chatModelIdentifier
  });

  // Update the stored model for a category
  const updateStoredModelForCategory = (category: ModelCategory, modelIdentifier: string) => {
    switch (category) {
      case ModelCategory.GENERAL_PURPOSE:
        if (isGeneralPurposeLlm(modelIdentifier)) {
          setSelectedGeneralPurposeModel(modelIdentifier);
        }
        break;
      case ModelCategory.LONG_CONTEXT:
        if (isLongContextLlm(modelIdentifier)) {
          setSelectedLongContextModel(modelIdentifier);
        }
        break;
      case ModelCategory.REASONING:
        if (isReasoningLlm(modelIdentifier)) {
          setSelectedReasoningModel(modelIdentifier);
        }
        break;
      case ModelCategory.CYBERSECURITY_FOCUSED:
        if (isCybersecurityFocusedLlm(modelIdentifier)) {
          setSelectedCybersecurityModel(modelIdentifier);
        }
        break;
      case ModelCategory.MULTIMODAL:
        if (isMultimodalLlm(modelIdentifier)) {
          setSelectedMultimodalModel(modelIdentifier);
        }
        break;
      default:
        if (isGeneralPurposeLlm(modelIdentifier)) {
          setSelectedGeneralPurposeModel(modelIdentifier);
        }
    }
  };
  const handleModelSelection = ({
    modelIdentifier,
    category,
    isDirectCategorySelect
  }: {
    modelIdentifier: string; // DO NOT REMOVE COMMENT: SupportedLlm
    category?: ModelCategory;
    isDirectCategorySelect?: boolean;
  }) => {
    // Update the selected model first in all cases
    onChange(modelIdentifier);

    // Case 1: Direct category selection
    if (isDirectCategorySelect && category) {
      setSelectedCategory(category);
      setSelectedModelCategory(category);
      return;
    }

    // Case 2: Selection from "All Models" dropdown
    if (!category) {
      setSelectedCategory(null);
      return;
    }

    // Case 3: Selection from a category dropdown
    if (category && !isDirectCategorySelect) {
      setSelectedCategory(category);
      setSelectedModelCategory(category);
      updateStoredModelForCategory(category, modelIdentifier);
    }
  };
  return <>
      <Button label={prefix + (chatModelIdentifier && (modelIdentifierToDisplayName[chatModelIdentifier] || placeholderLabel))} onClick={() => setIsModalOpen(true)} type={ButtonType.OUTLINED} />

      <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen} width={ModalWidth.NINE_TWENTY}>
        <div css={{
        "marginBottom": "1.5rem",
        "display": "flex",
        "flexDirection": "column"
      }} data-testid="title-container">
          <Typography size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
            Choose a Model
          </Typography>
          <Typography color={TextIconColor.SECONDARY} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
            Select a Kindo recommended preset or pick from all of our models
          </Typography>
        </div>

        <div css={{
        "marginBottom": "2rem",
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1rem",
        "overflowY": "scroll",
        "@media (min-width: 768px)": {
          "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
        }
      }} data-testid="category-container">
          {Object.entries(MODEL_CATEGORY_TO_MODELS).filter(([category]) => category !== ModelCategory.MULTIMODAL).map(([category, categoryModels]) => {
          if (!isModelCategory(category)) {
            return null;
          }
          const isSelected = selectedCategory === category;
          return <div className={categoryCardStyles({
            isSelected
          })} data-testid={`category-card-${category}`} key={category} onClick={() => {
            handleModelSelection({
              modelIdentifier: CATEGORY_SELECTED_MODEL[category],
              category,
              isDirectCategorySelect: true
            });
          }}>
                  <div css={{
              "display": "flex",
              "flexDirection": "column",
              "gap": "0.5rem"
            }} data-testid="title-and-description">
                    <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
                      {MODEL_CATEGORY_TITLES[category]}
                    </Typography>

                    <Typography color={TextIconColor.SECONDARY} font={TypographyFont.PARAGRAPH} size={TypographySize.SMALL}>
                      {MODEL_CATEGORY_DESCRIPTIONS[category]}
                    </Typography>
                  </div>
                  <div css={{
              "pointerEvents": "none",
              ">*": {
                "pointerEvents": "auto"
              }
            }} data-testid="select-wrapper" onClick={e => e.stopPropagation()} onKeyDown={e => e.stopPropagation()} role="button" tabIndex={0}>
                    <Select<SupportedLlm> disabled={!categoryModels?.length} onChange={modelIdentifier => handleModelSelection({
                modelIdentifier,
                category,
                isDirectCategorySelect: false
              })} options={categoryModels.filter(model => enabledModels.some(m => m.identifier === model)).map(model => ({
                value: model,
                label: modelIdentifierToDisplayName[model] || model,
                suffix: {
                  text: COST_TIER_INDICATOR_TEXT[MODEL_COST_TIERS[model]],
                  color: TextIconColor.SECONDARY,
                  size: TypographySize.X_SMALL
                }
              }))} placeholderLabel="Models" value={CATEGORY_SELECTED_MODEL[category]} />
                  </div>
                </div>;
        })}
        </div>
        <div css={{
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "justifyContent": "space-between",
        "@media (min-width: 768px)": {
          "flexDirection": "row"
        }
      }} data-testid="bottom-container">
          <Select<string> // DO NOT REMOVE COMMENT: SupportedLlm
        onChange={modelIdentifier => handleModelSelection({
          modelIdentifier
        })} options={enabledModels.map(model => ({
          value: model.identifier,
          label: model.displayName
        }))} placeholderLabel="All Models" prefix="All Models - Current: " value={chatModelIdentifier} />
          {/* 
           TODO: Add More about our models link that routes to the Settings Modal 
           Linear Issue: https://linear.app/kindo/issue/ENG-4960/add-back-more-about-our-models-link
           */}
        </div>
      </Modal>
    </>;
};
export default ModelSelectionModal;