import { useState } from 'react';
import { Icon, TextField, TextFieldType, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { ToastType, useAppDispatch, useAppSelector, useCanAccessSecuritySettings, useToast } from '~/hooks';
import { userActions } from '~/redux/reducers/userSlice';
import { nextTrpc } from '~/trpc';
const OrgInfo: React.FC = () => {
  // Redux
  const {
    org
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const updateReduxOrgName = (orgName: string) => dispatch(userActions.updateOrgName({
    orgName
  }));

  // State
  const [orgNameInput, setOrgNameInput] = useState<string>(org?.name ?? '');

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    userCanManageOrganization
  } = useCanAccessSecuritySettings();

  // Mutations
  const updateOrgNameMutation = nextTrpc.orgs.updateName.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Organization name updated.',
        type: ToastType.SUCCESS
      });
      updateReduxOrgName(orgNameInput);
    },
    onError: () => {
      enqueueToast({
        message: 'Failed to update organization name.',
        type: ToastType.ERROR
      });
    }
  });

  // Handlers
  const handleUpdateOrgName = () => {
    const trimmedOrgName = orgNameInput.trim();
    if (!trimmedOrgName) {
      enqueueToast({
        message: 'Organization name cannot be empty.',
        type: ToastType.ERROR
      });
      setOrgNameInput(org?.name ?? '');
      return;
    }
    if (trimmedOrgName !== org?.name) {
      updateOrgNameMutation.mutate({
        name: orgNameInput
      });
    }
    setOrgNameInput(trimmedOrgName);
  };
  return <SettingsSection title="Organization">
      <div css={{
      "marginTop": "1rem"
    }} data-testid="org-info-badge">
        <Badge label={(org?.paymentTier ?? '') + ' Plan'} />
      </div>
      <div css={{
      "marginTop": "0.5rem",
      "borderBottomWidth": "0.063rem",
      "borderBottomColor": "#e6c2ff1a"
    }} data-test-id="org-name-editor">
        <TextField animateStartIcon disabled={!userCanManageOrganization} font={TypographyFont.INTERACTIVE} onBlur={() => handleUpdateOrgName()} onChange={newValue => setOrgNameInput(newValue)} placeholder={userCanManageOrganization ? 'Name your organization...' : 'You do not have permission to change the organization name.'} size={TypographySize.H3} startIcon={Icon.EDIT} textWeight={TypographyWeight.MEDIUM} type={TextFieldType.SIMPLE} value={orgNameInput} />
      </div>
    </SettingsSection>;
};
export default OrgInfo;