import { WorkflowType } from '@kindo/universal';
import { useState } from 'react';
import WorkflowBuilderConfigurationEditor from '../../WorkflowBuilder/WorkflowBuilderConfiguration/WorkflowBuilderConfigurationEditor';
import WorkflowViewLayout from '../shared/WorkflowViewLayout/WorkflowViewLayout';
import { FileResource } from '~/components/Chat/AddFileModal';
import { Button, ButtonType } from '~/components/core';
interface WorkflowChatbotToolboxProps {
  onCreateWorkflow: (params: {
    knowledgeStoreContent: FileResource[];
    systemPrompt: string;
  }) => void;
}
const WorkflowChatbotToolbox: React.FC<WorkflowChatbotToolboxProps> = ({
  onCreateWorkflow
}) => {
  const [systemPrompt, setSystemPrompt] = useState('');
  const [knowledgeStoreContent, setKnowledgeStoreContent] = useState<FileResource[]>([]);
  const handleCreate = () => {
    onCreateWorkflow({
      systemPrompt,
      knowledgeStoreContent
    });
  };
  const isValid = !!systemPrompt.trim() || knowledgeStoreContent.length > 0;
  return <WorkflowViewLayout workflowType={WorkflowType.CHATBOT}>
      <div css={{
      "display": "flex",
      "flexDirection": "column",
      "gap": "2rem",
      "borderRadius": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "rgb(255 255 255 / 0.1)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
      "padding": "1.5rem"
    }} data-testid="chatbot-section-container">
        <WorkflowBuilderConfigurationEditor knowledgeStoreContent={knowledgeStoreContent} onKnowledgeStoreContentChange={setKnowledgeStoreContent} onSystemPromptChange={setSystemPrompt} systemPrompt={systemPrompt} />
        <div css={{
        "display": "flex",
        "justifyContent": "flex-end"
      }} data-testid="create-chatbot-button-container">
          <Button disabled={!isValid} label="Create Agent" onClick={handleCreate} type={ButtonType.SOLID_COLOR} />
        </div>
      </div>
    </WorkflowViewLayout>;
};
export default WorkflowChatbotToolbox;