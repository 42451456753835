import { useAppDispatch } from './typedReduxHooks';
import useToast, { ToastType } from './useToast';

import { FileResource } from '~/components/Chat/AddFileModal';
import { useGetWorkflowFromUrlSlug } from '~/hooks';
import {
  BuilderWorkflowKnowledgeStore,
  workflowBuilderActions
} from '~/redux/reducers/workflowBuilderSlice';
import { nextTrpc } from '~/trpc';
import { BuilderWorkflowStatus, Workflow } from '~/types';

interface UseKnowledgeStoreArgs {
  knowledgeStore: BuilderWorkflowKnowledgeStore | null;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
}

interface UseKnowledgeStoreReturnObject {
  canSaveKnowledgeStore: boolean;
  isSavingKnowledgeStore: boolean;
  markKnowledgeStoreSaved: () => void;
  saveKnowledgeStoreContent: () => Promise<void>;
  updateKnowledgeStoreContent: (value: FileResource[]) => void;
}

const useKnowledgeStore = ({
  knowledgeStore,
  refetchWorkflow,
  workflow
}: UseKnowledgeStoreArgs): UseKnowledgeStoreReturnObject => {
  // Custom Hooks
  const { enqueueToast } = useToast();

  // Redux
  const dispatch = useAppDispatch();

  // TRPC Mutations
  const addKnowledgeStoreContentMutation =
    nextTrpc.workflows.addKnowledgeStoreContent.useMutation();

  const removeKnowledgeStoreContentMutation =
    nextTrpc.workflows.removeKnowledgeStoreContent.useMutation();

  // Exported functions
  const isSavingKnowledgeStore =
    addKnowledgeStoreContentMutation.isLoading ||
    removeKnowledgeStoreContentMutation.isLoading;

  const canSaveKnowledgeStore =
    knowledgeStore?.status === BuilderWorkflowStatus.MODIFIED;

  const markKnowledgeStoreSaved = () => {
    dispatch(workflowBuilderActions.markKnowledgeStoreSaved());
  };

  const saveKnowledgeStoreContent = async (): Promise<void> => {
    if (!knowledgeStore) {
      return;
    }

    const newContent = knowledgeStore.content.filter(
      (item) =>
        !workflow.knowledgeStoreContent.some(
          (existingItem) => existingItem.id === item.id
        )
    );

    try {
      if (newContent.length > 0) {
        await addKnowledgeStoreContentMutation.mutateAsync({
          workflowId: workflow.id,
          contentIds: newContent.map((file) => file.id)
        });

        refetchWorkflow();
        enqueueToast({
          message: 'Knowledge added to agent successfully.',
          type: ToastType.SUCCESS
        });
      }
    } catch (error) {
      console.error('Failed to add knowledge to agent.', error);
      enqueueToast({
        message: 'Failed to add knowledge to agent.',
        type: ToastType.ERROR
      });
    }

    const removedContent = workflow.knowledgeStoreContent.filter(
      (item) =>
        !knowledgeStore?.content.some(
          (currentItem) => currentItem.id === item.id
        )
    );

    try {
      if (removedContent.length > 0) {
        await removeKnowledgeStoreContentMutation.mutateAsync({
          workflowId: workflow.id,
          contentIds: removedContent.map((item) => item.id)
        });

        refetchWorkflow();
        enqueueToast({
          message: 'Knowledge removed from agent successfully.',
          type: ToastType.SUCCESS
        });
      }
    } catch (error) {
      console.error('Failed to remove knowledge from agent.', error);
      enqueueToast({
        message: 'Failed to remove knowledge from agent.',
        type: ToastType.ERROR
      });
    }

    dispatch(workflowBuilderActions.markKnowledgeStoreSaved());
  };

  const updateKnowledgeStoreContent = (value: FileResource[]) => {
    dispatch(workflowBuilderActions.updateKnowledgeStoreContent(value));
  };

  return {
    canSaveKnowledgeStore,
    isSavingKnowledgeStore,
    markKnowledgeStoreSaved,
    updateKnowledgeStoreContent,
    saveKnowledgeStoreContent
  };
};

export default useKnowledgeStore;
