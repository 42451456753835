import { assertUnreachable, Integration } from '@kindo/universal';
import { DeleteIntegrationModal, DeleteWorkflowModal, DuplicateWorkflowModal, ShareChatModal, ShareWorkflowModal } from '..';
import { DlpFiltersConfigModal } from '../../SettingsModal/SecuritySettings/OrgSecurityControls/DlpFiltersConfig/DlpFiltersConfigModal';
import { PaywallModal } from '../../shared';
import { WorkflowApiActionStepTestModal } from '../../WorkflowBuilder/WorkflowBuilderCanvas/WorkflowStepEditor/WorkflowApiActionStepEditor';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
const ActiveModal: React.FC = () => {
  // Redux
  const {
    activeModal
  } = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };
  if (!activeModal) return null;
  const {
    type
  } = activeModal;
  switch (type) {
    case Modal.CONFIGURE_DLP_FILTERS:
      return <DlpFiltersConfigModal canAccessSecuritySettings={activeModal.canAccessSecuritySettings} modelOrProvider={activeModal.modelOrProvider} onClose={closeModal} userGroupId={activeModal.userGroupId} />;
    case Modal.DELETE_INTEGRATION:
      return <DeleteIntegrationModal integration={activeModal.integrationName as Integration} integrationId={activeModal.integrationId} onClose={closeModal} onSuccess={activeModal.onSuccess} />;
    case Modal.DELETE_WORKFLOW:
      return <DeleteWorkflowModal onClose={closeModal} redirectRoute={activeModal.redirectRoute} workflowId={activeModal.workflowId} workflowTitle={activeModal.workflowTitle} />;
    case Modal.SHARE_WORKFLOW:
      return <ShareWorkflowModal onClose={closeModal} workflowId={activeModal.workflowId} />;
    case Modal.SHARE_CHAT:
      return <ShareChatModal chatId={activeModal.chatId} onClose={closeModal} />;
    case Modal.PAYWALL:
      return <PaywallModal onClose={closeModal} payload={activeModal} />;
    case Modal.TEST_API_ACTION_STEP:
      return <WorkflowApiActionStepTestModal executionInfo={activeModal.executionInfo} isLoading={activeModal.isLoading} onClose={closeModal} />;
    case Modal.DUPLICATE_WORKFLOW:
      return <DuplicateWorkflowModal workflowId={activeModal.workflowId} workflowTitle={activeModal.workflowTitle} />;
    default:
      // Ensures exhaustiveness of switch
      assertUnreachable(type);
      return null;
  }
};
export default ActiveModal;