/**
 * This file contains Tailwind variant enums and their type guards.
 * These are used with tailwind-variants (tv) for consistent styling across components.
 */

/**
 * MaxWidthVariant defines the standard width variants for components.
 * Used with variants like Tailwind max-w-* classes.
 */
export enum MaxWidthVariant {
  FULL = 'full',
  LG = 'lg',
  MD = 'md',
  NONE = 'none',
  SM = 'sm',
  XL = 'xl',
  XS = 'xs',
  XXL = '2xl',
}
export function isMaxWidthVariant(value: unknown): value is MaxWidthVariant {
  return typeof value === 'string' && Object.values(MaxWidthVariant).includes(value as MaxWidthVariant);
}