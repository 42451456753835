import { getPromptTemplateVariableTemplateResolutionNameFromDisplayName, WorkflowInputType, WorkflowStepType } from '@kindo/universal';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ButtonType, Icon, Select } from '~/components/core';
import { useAppDispatch, useAppSelector, useGetWorkflowFromUrlSlug } from '~/hooks';
import { NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE, NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE, workflowBuilderActions } from '~/redux/reducers/workflowBuilderSlice';
import { isPromptTemplateWorkflowBuilderStepInput, isPromptTemplateWorkflowStepInput, PromptTemplateWorkflowBuilderStepInput } from '~/types';
interface WorkflowUserInputNodeComponentProps {
  setUserInputNames: (args: {
    displayName: string;
    templateResolutionName: string;
  }) => void;
  userInputName: string;
}

/**
 * This component renders the decorator node "WorkflowUserInputNode".
 *
 * It allows the user to select a user input from a dropdown, or create a new one.
 */
const WorkflowUserInputNodeComponent: React.FC<WorkflowUserInputNodeComponentProps> = ({
  userInputName,
  setUserInputNames: setUserInputName
}) => {
  const {
    activeStep
  } = useAppSelector(state => state.workflowBuilder);
  const dispatch = useAppDispatch();

  // Hooks
  const {
    data: workflow
  } = useGetWorkflowFromUrlSlug();

  // Get lexical editor
  const [editor] = useLexicalComposerContext();
  if (activeStep?.type !== WorkflowStepType.LLM) {
    return null;
  }
  const existingWorkflowInputs = workflow?.item.inputs.filter(isPromptTemplateWorkflowStepInput) ?? [];
  const activeStepInputs = activeStep.inputs.filter(isPromptTemplateWorkflowBuilderStepInput);
  const unsavedInputs = activeStepInputs.filter(input => !existingWorkflowInputs.some(existingInput => existingInput.templateResolutionName === input.templateResolutionName));
  const inputOptions: PromptTemplateWorkflowBuilderStepInput[] = [...unsavedInputs, ...existingWorkflowInputs];

  //   When user selects an input
  const handleSelectChange = (value: string) => {
    // Matching input on the step currently in redux
    const matchingInputOnReduxStep = activeStepInputs.find(input => input.templateResolutionName === value);

    // Matching input saved on the entire workflow
    const matchingInputOnSavedWorkflow = existingWorkflowInputs.find(input => input.templateResolutionName === value);

    // If it's an existing input option already in redux on the step,
    // update the lexical node with that input without adding to redux
    if (matchingInputOnReduxStep) {
      editor.update(() => {
        setUserInputName({
          templateResolutionName: matchingInputOnReduxStep.templateResolutionName,
          displayName: matchingInputOnReduxStep.displayName
        });
      });
      return;
    }

    // If it's an existing input option already in the workflow,
    // add to step in redux
    if (matchingInputOnSavedWorkflow) {
      dispatch(workflowBuilderActions.addUserInput(matchingInputOnSavedWorkflow));
      editor.update(() => {
        setUserInputName({
          templateResolutionName: matchingInputOnSavedWorkflow.templateResolutionName,
          displayName: matchingInputOnSavedWorkflow.displayName
        });
      });
      return;
    }

    // If no existing input is found, create a new one
    const templateResolutionName = getPromptTemplateVariableTemplateResolutionNameFromDisplayName(value);

    // Add to step inputs
    dispatch(workflowBuilderActions.addUserInput({
      config: null,
      displayName: value,
      templateResolutionName,
      type: WorkflowInputType.TEXT_OR_CONTENT
    }));

    // Update lexical node (which is source of truth for the value)
    editor.update(() => {
      setUserInputName({
        templateResolutionName,
        displayName: value
      });
    });
  };
  const isEmpty = !userInputName;
  return <Select allowInput hideChevron inputPlaceholder="New input name" onChange={value => handleSelectChange(value)} options={inputOptions.map(({
    displayName,
    templateResolutionName
  }) => ({
    value: templateResolutionName,
    label: displayName
  }))} placeholderLabel="Input" reservedValues={[NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE, NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE]} startIcon={isEmpty ? Icon.PLUS : undefined} type={isEmpty ? ButtonType.OUTLINED_COLOR : ButtonType.OUTLINED} value={userInputName} />;
};
export default WorkflowUserInputNodeComponent;