import { Provider, typedFromEntries } from '@kindo/universal';
import React, { useState } from 'react';
import { ModelProviderSecurityControlsTable } from './ModelProviderSecurityControlsTable';
import { ToastType, useAppDispatch, useGetAllAvailableOrgModels, useToast } from '~/hooks';
import { Modal, modalActions, ModelOrProvider } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
interface ProviderControlsProps {
  canAccessSecuritySettings: boolean;
}
const ProviderControls: React.FC<ProviderControlsProps> = ({
  canAccessSecuritySettings
}) => {
  // State
  const {
    allAvailableOrgModels
  } = useGetAllAvailableOrgModels();
  const [pendingCustomConfigTarget, setPendingCustomConfigTarget] = useState<{
    modelOrProvider: ModelOrProvider;
  } | null>(null);
  const [loadingProviderAccess, setLoadingProviderAccess] = useState<Record<Provider, boolean>>(typedFromEntries(Object.values(Provider).map(provider => [provider, false])));
  const [loadingModelAccess, setLoadingModelAccess] = useState<Record<string, boolean> /* DO NOT REMOVE COMMENT: was Record<Llm, boolean>*/>(typedFromEntries(allAvailableOrgModels.map(model => [model.identifier, false])));
  const [loadingProviderDlp, setLoadingProviderDlp] = useState<Record<Provider, boolean>>(typedFromEntries(Object.values(Provider).map(provider => [provider, false])));
  const [loadingModelDlp, setLoadingModelDlp] = useState<Record<string, boolean> /* DO NOT REMOVE COMMENT: was Record<Llm, boolean>*/>(typedFromEntries(allAvailableOrgModels.map(model => [model.identifier, false])));

  // Custom Hooks
  const {
    enqueueToast
  } = useToast();

  // Redux
  const dispatch = useAppDispatch();
  const openDlpFiltersConfigModal = (modelOrProvider: ModelOrProvider) => {
    dispatch(modalActions.openModal({
      type: Modal.CONFIGURE_DLP_FILTERS,
      canAccessSecuritySettings,
      modelOrProvider,
      userGroupId: null
    }));
  };

  // Queries

  const {
    data: providerSecurityConfigsData,
    refetch: refetchProviderConfig
  } = nextTrpc.adminSecurityConfig.listOrgProviderSecurityConfigs.useQuery();
  const providerSecurityConfigs = providerSecurityConfigsData?.items ?? [];
  const {
    data: modelSecurityConfigsData,
    refetch: refetchModelSecurityConfigs
  } = nextTrpc.adminSecurityConfig.listOrgModelSecurityConfigs.useQuery();
  const modelSecurityConfigs = modelSecurityConfigsData?.items ?? [];

  // Mutations

  // Create configs
  const createOrgProviderDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.createOrgProviderDlpFiltersConfig.useMutation({
    onSuccess: () => refetchProviderConfig(),
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom DLP filters config.',
        type: ToastType.ERROR
      });
    }
  });
  const createOrgModelDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.createOrgModelDlpFiltersConfig.useMutation({
    onSuccess: () => refetchModelSecurityConfigs(),
    onError: () => {
      enqueueToast({
        message: 'Failed to create custom DLP filters config.',
        type: ToastType.ERROR
      });
    }
  });

  // Access enabled
  const updateOrgProviderAccessEnabledMutation = nextTrpc.adminSecurityConfig.updateOrgProviderAccessEnabled.useMutation({
    onSuccess: () => refetchProviderConfig(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update provider setting.',
        type: ToastType.ERROR
      });
    }
  });
  const updateOrgModelAccessEnabledMutation = nextTrpc.adminSecurityConfig.updateOrgModelAccessEnabled.useMutation({
    onSuccess: () => refetchModelSecurityConfigs(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update model provider access enabled setting.',
        type: ToastType.ERROR
      });
    }
  });

  // DLP enabled
  const updateOrgProviderDlpEnabledMutation = nextTrpc.adminSecurityConfig.updateOrgProviderDlpEnabled.useMutation({
    onSuccess: () => refetchProviderConfig(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update provider DLP enabled setting.',
        type: ToastType.ERROR
      });
    }
  });
  const updateOrgModelDlpEnabledMutation = nextTrpc.adminSecurityConfig.updateOrgModelDlpEnabled.useMutation({
    onSuccess: () => refetchModelSecurityConfigs(),
    onError: () => {
      enqueueToast({
        message: 'Failed to update model DLP enabled setting.',
        type: ToastType.ERROR
      });
    }
  });
  const handleProviderAccessToggle = (providerName: Provider, enabled: boolean) => {
    setLoadingProviderAccess(prev => ({
      ...prev,
      [providerName]: true
    }));
    updateOrgProviderAccessEnabledMutation.mutate({
      provider: providerName,
      enabled
    }, {
      onSettled: () => {
        setLoadingProviderAccess(prev => ({
          ...prev,
          [providerName]: false
        }));
      }
    });
  };

  // DO NOT REMOVE COMMENT: was (model: Llm, enabled: boolean)
  const handleModelAccessToggle = (model: string, enabled: boolean) => {
    setLoadingModelAccess(prev => ({
      ...prev,
      [model]: true
    }));
    updateOrgModelAccessEnabledMutation.mutate({
      model,
      enabled
    }, {
      onSettled: () => {
        setLoadingModelAccess(prev => ({
          ...prev,
          [model]: false
        }));
      }
    });
  };
  const handleProviderDlpToggle = (provider: Provider, enabled: boolean) => {
    setLoadingProviderDlp(prev => ({
      ...prev,
      [provider]: true
    }));
    updateOrgProviderDlpEnabledMutation.mutate({
      provider,
      enabled
    }, {
      onSettled: () => {
        setLoadingProviderDlp(prev => ({
          ...prev,
          [provider]: false
        }));
      }
    });
  };

  // DO NOT REMOVE COMMENT: was (model: Llm, enabled: boolean)
  const handleModelDlpToggle = (model: string, enabled: boolean) => {
    setLoadingModelDlp(prev => ({
      ...prev,
      [model]: true
    }));
    updateOrgModelDlpEnabledMutation.mutate({
      model,
      enabled
    }, {
      onSettled: () => {
        setLoadingModelDlp(prev => ({
          ...prev,
          [model]: false
        }));
      }
    });
  };
  const handleEditOrCreateCustomDlpFiltersConfig = (modelOrProvider: ModelOrProvider, hasCustomDlpFiltersConfig: boolean) => {
    const {
      model,
      provider
    } = modelOrProvider;
    if (hasCustomDlpFiltersConfig) {
      openDlpFiltersConfigModal(modelOrProvider);
      return;
    }
    if (model) {
      createOrgModelDlpFiltersConfigMutation.mutate({
        model
      }, {
        onSuccess: () => openDlpFiltersConfigModal(modelOrProvider)
      });
    } else if (provider) {
      createOrgProviderDlpFiltersConfigMutation.mutate({
        provider
      }, {
        onSuccess: () => openDlpFiltersConfigModal(modelOrProvider)
      });
    }
  };
  const handleCustomDlpConfigClick = (modelOrProvider: ModelOrProvider, hasCustomDlpFiltersConfig: boolean) => {
    // If creating a new config, show confirmation modal
    if (!hasCustomDlpFiltersConfig) {
      setPendingCustomConfigTarget({
        modelOrProvider
      });
      return;
    }
    handleEditOrCreateCustomDlpFiltersConfig(modelOrProvider, hasCustomDlpFiltersConfig);
  };
  const handleConfirmCustomConfig = () => {
    if (!pendingCustomConfigTarget) {
      console.error('No pending custom config target');
      return;
    }
    handleEditOrCreateCustomDlpFiltersConfig(pendingCustomConfigTarget.modelOrProvider, false);
    setPendingCustomConfigTarget(null);
  };
  const handleSetPendingCustomConfigTarget = (configTarget: {
    modelOrProvider: ModelOrProvider;
  } | null) => {
    setPendingCustomConfigTarget(configTarget);
  };
  return <ModelProviderSecurityControlsTable canAccessSecuritySettings={canAccessSecuritySettings} isModelAccessLoading={loadingModelAccess} isModelDlpLoading={loadingModelDlp} isProviderAccessLoading={loadingProviderAccess} isProviderDlpLoading={loadingProviderDlp} modelSecurityConfigs={modelSecurityConfigs} onConfirmCustomConfig={handleConfirmCustomConfig} onCustomDlpConfigClick={handleCustomDlpConfigClick} onModelAccessToggle={handleModelAccessToggle} onModelDlpToggle={handleModelDlpToggle} onProviderAccessToggle={handleProviderAccessToggle} onProviderDlpToggle={handleProviderDlpToggle} onSetPendingCustomConfigTarget={handleSetPendingCustomConfigTarget} pendingCustomConfigTarget={pendingCustomConfigTarget} providerSecurityConfigs={providerSecurityConfigs} />;
};
export default ProviderControls;