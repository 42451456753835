import _styled from "styled-components";
import { useState } from 'react';
import { getAuditEventDisplayMessage } from '../AuditLog.utils';
import AuditEventSummary from './AuditEventSummary';
import ObjectViewer from './ObjectViewer';
import { LoadingSpinner, LoadingSpinnerSize, TabNavigator, TabOption, TextIconColor, Typography, TypographySize } from '~/components/core';
import { nextTrpc } from '~/trpc';
const AuditEventModalContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1.5rem"
});
const AuditEventModalTabNavContainer = _styled.div({
  "display": "flex",
  "gap": "1.5rem"
});
const AuditEventModalTabContentContainer = _styled.div({
  "maxHeight": "60vh",
  "overflow": "auto"
});
const Header = _styled.div({
  "display": "flex",
  "gap": "1rem"
});
export enum AuditLogDetailModalTab {
  RAW_DATA = 'RAW_DATA',
  SUMMARY = 'SUMMARY',
}
export const AUDIT_LOG_DETAIL_MODAL_TAB_DISPLAY_NAMES: Record<AuditLogDetailModalTab, string> = {
  [AuditLogDetailModalTab.RAW_DATA]: 'Raw Data',
  [AuditLogDetailModalTab.SUMMARY]: 'Summary'
};
const AuditEventModal = ({
  auditEventId,
  handleSetUserFilter
}: {
  auditEventId: string;
  handleSetUserFilter: (userId: string) => void;
}) => {
  const [currentTab, setCurrentTab] = useState<AuditLogDetailModalTab>(AuditLogDetailModalTab.SUMMARY);
  const {
    data: auditEvent,
    isLoading
  } = nextTrpc.auditLog.get.useQuery({
    auditEventId
  });
  const tabOptions: TabOption<AuditLogDetailModalTab>[] = [AuditLogDetailModalTab.SUMMARY, AuditLogDetailModalTab.RAW_DATA].map(tab => ({
    label: AUDIT_LOG_DETAIL_MODAL_TAB_DISPLAY_NAMES[tab],
    value: tab
  }));
  return <AuditEventModalContainer>
      {isLoading && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
      {!isLoading && auditEvent && <>
          <Header>
            <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3}>
              {getAuditEventDisplayMessage({
            type: auditEvent.type,
            // TODO: Remove typecasting after zod schema is established and parsed by API
            userMetadata: (auditEvent as any as {
              userMetadata: Record<string, any>;
            }).userMetadata
          })}
            </Typography>
          </Header>
          <AuditEventModalTabNavContainer>
            <TabNavigator<AuditLogDetailModalTab> activeTab={currentTab} onClick={tab => setCurrentTab(tab)} tabOptions={tabOptions} />
          </AuditEventModalTabNavContainer>
          <AuditEventModalTabContentContainer>
            {currentTab === AuditLogDetailModalTab.RAW_DATA && <ObjectViewer data={auditEvent} />}
            {currentTab === AuditLogDetailModalTab.SUMMARY && <AuditEventSummary auditEvent={auditEvent} handleSetUserFilter={handleSetUserFilter} />}
          </AuditEventModalTabContentContainer>
        </>}
      {!isLoading && !auditEvent && <Typography>
          Unable to get the audit event details, please try again.
        </Typography>}
    </AuditEventModalContainer>;
};
export default AuditEventModal;