import _styled from "styled-components";
import { isWorkflowSupportedIntegration, WORKFLOW_INTEGRATION_ACTIONS_REQUIRING_ADDITIONAL_CONTEXT, WorkflowActionStepConfig, WorkflowIntegrationActionStepType, WorkflowStepType, WorkflowSupportedIntegration } from '@kindo/universal';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { Expand, Select, TextField, TextFieldType, TextIconColor, ToolTip, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { TooltipPlacement } from '~/components/core/ToolTip/ToolTip';
import { ACTION_STEP_TO_COMMENT_EXAMPLE, isIntegrationInWorkflowIntegrationToActionMap, WORKFLOW_ACTION_LABELS, WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES, WORKFLOW_INTEGRATION_TO_ACTION } from '~/constants';
import { BuilderWorkflowIntegrationActionStep, IntegrationWorkflowBuilderStepInput, isIntegrationWorkflowBuilderStepInput, isIntegrationWorkflowInput, Workflow, WorkflowInput } from '~/types';
const IntegrationActionEditorContainer = _styled.div({
  "display": "flex",
  "maxHeight": "600px",
  "width": "100%",
  "flexDirection": "column",
  "flexWrap": "wrap",
  "gap": "0.75rem",
  "overflowY": "auto",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const AdditionalConfigurationContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const CheckboxContainer = _styled.div({
  "display": "flex",
  "cursor": "pointer",
  "flexDirection": "row",
  "alignItems": "center"
});
const CheckboxAndTextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column"
});
const CommentExampleContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "flex-start"
});
const ExpandedAdditionalContextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "borderRadius": "0.375rem",
  "backgroundColor": "#25224575",
  "padding": "0.75rem"
});
const StyledCheckbox = _styled(Checkbox)({
  "&.Mui-checked": {
    "--tw-text-opacity": "1 !important",
    "color": "rgb(84 254 209 / var(--tw-text-opacity)) !important"
  },
  "&.MuiCheckbox-root": {
    "--tw-text-opacity": "1 !important",
    "color": "rgb(84 254 209 / var(--tw-text-opacity)) !important"
  }
});
interface WorkflowIntegrationActionStepEditorProps {
  step: BuilderWorkflowIntegrationActionStep;
  updateActiveStepAction: (action: WorkflowIntegrationActionStepType) => void;
  updateActiveStepActionConfig: (config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>) => void;
  updateActiveStepInput: (input: IntegrationWorkflowBuilderStepInput) => void;
  updateActiveStepIntegration: (integration: WorkflowSupportedIntegration) => void;
  workflow: Workflow;
  workflowInputs: WorkflowInput[];
}
const WorkflowIntegrationActionStepEditor: React.FC<WorkflowIntegrationActionStepEditorProps> = ({
  step,
  workflow,
  workflowInputs,
  updateActiveStepIntegration,
  updateActiveStepAction,
  updateActiveStepInput,
  updateActiveStepActionConfig
}) => {
  const [includeChatHistoryContext, setIncludeChatHistoryContext] = useState<boolean>(step.config?.includeChatHistory ?? false);
  const [commentActionOutput, setCommentActionOutput] = useState<boolean>(true);
  const integrationWorkflowInputs = workflowInputs.filter(isIntegrationWorkflowInput);
  const availableIntegrations = integrationWorkflowInputs.map(input => input.integrationName).filter(isWorkflowSupportedIntegration);

  // TODO: The type guard is a temporary solution until all the Integration types
  // are updated to the new WorkflowSupportedIntegration type
  // [ENG-3827] Update Integration type to WorkflowSupportedIntegration
  const availableActions = step.integration && isIntegrationInWorkflowIntegrationToActionMap(step.integration) ? WORKFLOW_INTEGRATION_TO_ACTION[step.integration] : [];
  const requiredInputTypes = step.action && WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES[step.action];

  // This is to get the available inputs for the action. Here, it's mainly assumed that
  // an action has one input type (CREATE_COMMENT_ON_TICKET requires TICKETING_INTEGRATION_TICKET),
  // but we may need to adjust how we select inputs if there are more than one required
  // input types for an action.
  const availableInputs = requiredInputTypes ? integrationWorkflowInputs.filter(input => requiredInputTypes.includes(input.type)) : [];
  const handleIntegrationChange = (integrationName: WorkflowSupportedIntegration | null) => {
    if (!integrationName) return;
    updateActiveStepIntegration(integrationName);
  };

  // If there's only one available integration,
  // set the integration to the only available integration
  // When we have multiple triggers, we will need Select components to handle this
  if (availableIntegrations.length === 1 && availableIntegrations[0]) {
    handleIntegrationChange(availableIntegrations[0]);
  }
  const handleActionChange = (integrationAction: WorkflowIntegrationActionStepType | null) => {
    if (!integrationAction) return;
    updateActiveStepAction(integrationAction);
  };
  const handleInputChange = (inputId: string) => {
    const input = availableInputs.find(availableInput => availableInput.id === inputId);
    if (!input) {
      console.error('Failed to find input for integration action step');
      return;
    }
    updateActiveStepInput({
      ...input,
      config: {
        specifiedFields: []
      }
    });
  };
  const handleUpdateIncludeChatHistoryContext = () => {
    setIncludeChatHistoryContext(!includeChatHistoryContext);
    updateActiveStepActionConfig({
      additionalContext: step.config?.additionalContext ?? '',
      includeChatHistory: !includeChatHistoryContext,
      commentActionOutput
    });
  };
  const handleUpdateAdditionalContext = (value: string) => {
    updateActiveStepActionConfig({
      additionalContext: value,
      includeChatHistory: includeChatHistoryContext,
      commentActionOutput
    });
  };
  const handleUpdateCommentActionOutput = () => {
    setCommentActionOutput(!commentActionOutput);
    updateActiveStepActionConfig({
      additionalContext: step.config?.additionalContext ?? '',
      includeChatHistory: includeChatHistoryContext,
      commentActionOutput: !commentActionOutput
    });
  };
  const stepInputs = step.inputs.filter(isIntegrationWorkflowBuilderStepInput);

  // If there's only one available input and the step doesn't have an input set,
  // set the input to the only available input
  // When we have multiple triggers, we will need Select components to handle this
  if (availableInputs.length === 1 && availableInputs[0] && !stepInputs[0]) {
    handleInputChange(availableInputs[0].id);
  }
  const hasPrecedingLlmStep = workflow.steps.slice(0, step.stepNumber - 1).some(workflowStep => workflowStep.type === WorkflowStepType.LLM);
  return <IntegrationActionEditorContainer>
      {availableActions && availableActions.length > 0 && <Select<WorkflowIntegrationActionStepType | null> onChange={handleActionChange} options={availableActions.map(option => ({
      label: WORKFLOW_ACTION_LABELS[option],
      value: option,
      disabled: option === WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET && !hasPrecedingLlmStep,
      tooltip: option === WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET && !hasPrecedingLlmStep ? 'An LLM step must precede this action step' : ''
    }))} placeholderLabel="Select Action" prefix={step.action ? 'Action: ' : ''} value={step.action} />}
      {step.action && WORKFLOW_INTEGRATION_ACTIONS_REQUIRING_ADDITIONAL_CONTEXT.includes(step.action) && <AdditionalConfigurationContainer>
            <Expand trigger={<Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  additional configuration
                </Typography>}>
              <ExpandedAdditionalContextContainer>
                <CheckboxContainer>
                  <StyledCheckbox checked={commentActionOutput} onChange={() => setCommentActionOutput(!commentActionOutput)} value={commentActionOutput} />
                  <CheckboxAndTextContainer>
                    <Typography color={TextIconColor.PRIMARY} onClick={handleUpdateCommentActionOutput} size={TypographySize.SMALL}>
                      Comment Reasoning on Ticket
                    </Typography>
                    <ToolTip content={`"${ACTION_STEP_TO_COMMENT_EXAMPLE[step.action]}"`} offset={[0, 10]} placement={TooltipPlacement.RIGHT}>
                      <CommentExampleContainer>
                        <Typography color={TextIconColor.SECONDARY} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
                          See comment example
                        </Typography>
                      </CommentExampleContainer>
                    </ToolTip>
                  </CheckboxAndTextContainer>
                </CheckboxContainer>
                <CheckboxContainer>
                  <StyledCheckbox checked={includeChatHistoryContext} onChange={handleUpdateIncludeChatHistoryContext} value={includeChatHistoryContext} />
                  <Typography color={TextIconColor.PRIMARY} onClick={handleUpdateIncludeChatHistoryContext} size={TypographySize.SMALL}>
                    Include Chat History in Context
                  </Typography>
                </CheckboxContainer>
                <TextField minRows={3} multiline onChange={handleUpdateAdditionalContext} placeholder="(Optional) Additional instructions..." size={TypographySize.SMALL} type={TextFieldType.DEFAULT} value={step.config?.additionalContext ?? ''} />
              </ExpandedAdditionalContextContainer>
            </Expand>
          </AdditionalConfigurationContainer>}
    </IntegrationActionEditorContainer>;
};
export default WorkflowIntegrationActionStepEditor;