import { API_SUB_TABS_COMPONENTS, API_TAB_DISPLAY_NAMES, ApiTab, ORDERED_API_TABS } from './ApiSettings';
import { ModelSettings } from './ModelSettings';
import { ORDERED_PLUGINS_TABS, PLUGINS_TAB_COMPONENTS, PLUGINS_TAB_DISPLAY_NAMES, PluginsTab } from './Plugins';
import { ORG_SECURITY_SETTINGS_TAB_DISPLAY_NAMES, OrgSecuritySettingsTabs, SECURITY_SETTINGS_TAB_COMPONENTS } from './SecuritySettings';
import { ORDERED_SETTINGS_TABS, SETTINGS_TAB_COMPONENTS, SETTINGS_TAB_DISPLAY_NAMES } from './SettingsModal.consts';
import { SettingsStructureItem, SettingsStructureSubItem, SettingsTab } from './SettingsModal.types';
type TabType = ApiTab | PluginsTab | OrgSecuritySettingsTabs;
type DisplayNameMapping<T extends TabType> = { [K in T]: string };

// Create a unified mapping of all tabs to their components
export type AllTabTypes = SettingsTab | ApiTab | PluginsTab | OrgSecuritySettingsTabs;

// This is the new unified mapping that maps any tab type to its component
export const ALL_TAB_COMPONENTS: Record<AllTabTypes, React.ReactNode> = {
  // Main settings tabs
  ...SETTINGS_TAB_COMPONENTS,
  // API subtabs
  ...API_SUB_TABS_COMPONENTS,
  // Plugin subtabs
  ...PLUGINS_TAB_COMPONENTS,
  // Security subtabs
  ...SECURITY_SETTINGS_TAB_COMPONENTS
};

// Default component to use if no matching tab is found
export const DEFAULT_TAB_COMPONENT = <ModelSettings />;

// Helper function to check if a tab has a component
export const hasTabComponent = (tab: AllTabTypes): boolean => !!ALL_TAB_COMPONENTS[tab];
const getSubTabs = <T extends TabType,>(tabs: T[], displayNames: DisplayNameMapping<T>): SettingsStructureSubItem[] => tabs.map(tab => ({
  tab,
  displayName: displayNames[tab]
}));
export const getPluginsSubTabs = (): SettingsStructureSubItem[] => getSubTabs(ORDERED_PLUGINS_TABS, PLUGINS_TAB_DISPLAY_NAMES);
export const getApiSubTabs = (): SettingsStructureSubItem[] => getSubTabs(ORDERED_API_TABS, API_TAB_DISPLAY_NAMES);
export const getSecuritySettingsTabSubTabs = (userCanAccessSecuritySettings: boolean): SettingsStructureSubItem[] => {
  const baseTabs = [OrgSecuritySettingsTabs.MODEL_ACCESS_AND_DLP, OrgSecuritySettingsTabs.INTEGRATION_ACCESS];
  const additionalTabs = userCanAccessSecuritySettings ? [OrgSecuritySettingsTabs.AUDIT_LOG, OrgSecuritySettingsTabs.SHARING_PERMISSIONS] : [];
  return getSubTabs([...baseTabs, ...additionalTabs], ORG_SECURITY_SETTINGS_TAB_DISPLAY_NAMES);
};
export const getSettingsTabSubTabs = (userCanAccessSecuritySettings: boolean): Record<SettingsTab, SettingsStructureSubItem[]> => ({
  [SettingsTab.API]: getApiSubTabs(),
  [SettingsTab.SECRETS_VAULT]: [],
  [SettingsTab.MODELS]: [],
  [SettingsTab.ORG]: [],
  [SettingsTab.USER_GROUPS]: [],
  [SettingsTab.MANAGE_SUBSCRIPTION]: [],
  [SettingsTab.INTEGRATIONS]: [],
  [SettingsTab.PLUGINS]: getPluginsSubTabs(),
  [SettingsTab.SECURITY]: getSecuritySettingsTabSubTabs(userCanAccessSecuritySettings)
});
export const getSettingsTabs = (userCanAccessSecuritySettings: boolean, isEnterprise: boolean, isUserGroupsEnabled: boolean): SettingsStructureItem[] => ORDERED_SETTINGS_TABS.filter(tab => !(isEnterprise && tab === SettingsTab.MANAGE_SUBSCRIPTION))
// Remove this filter after we remove user groups feature flag
.filter(tab => isUserGroupsEnabled || tab !== SettingsTab.USER_GROUPS).map(tab => ({
  tab,
  hasComponent: hasTabComponent(tab),
  displayName: SETTINGS_TAB_DISPLAY_NAMES[tab],
  subTabs: getSettingsTabSubTabs(userCanAccessSecuritySettings)[tab]
}));