import { BooleanKeys, OrgSettingsConfig } from '@kindo/universal';
import { TRPCClientErrorLike } from '@trpc/client';
import { useState } from 'react';
import { Size, Toggle } from '~/components/core';
import { ToastType, useOrgSettings, useToast } from '~/hooks';
type OrgSettingToggleProps = {
  fieldName: BooleanKeys<OrgSettingsConfig>;
  orgSettings: OrgSettingsConfig;
  disabled?: boolean;
};
const OrgSettingToggle: React.FC<OrgSettingToggleProps> = ({
  orgSettings,
  fieldName,
  disabled = false
}) => {
  const {
    enqueueToast
  } = useToast();
  const [enabled, setEnabled] = useState<boolean>(orgSettings[fieldName]);
  const [isPending, setIsPending] = useState(false);
  const {
    updateOrgSetting
  } = useOrgSettings();
  const onSuccess = () => {
    setIsPending(false);
    enqueueToast({
      message: 'Default behavior updated.',
      type: ToastType.SUCCESS
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (error: TRPCClientErrorLike<any>) => {
    console.error(error);
    setEnabled(prevEnabled => !prevEnabled);
    setIsPending(false);
    enqueueToast({
      message: error.data?.kindoErrorMessage || 'Failed to update configuration.',
      type: ToastType.ERROR
    });
  };
  return <Toggle checked={enabled} data-testid="org-setting-toggle" disabled={disabled || isPending} loading={isPending} onToggle={(isChecked: boolean) => {
    // Set pending state and indicate the future state of the toggle
    setIsPending(true);
    setEnabled(isChecked);
    updateOrgSetting(fieldName, isChecked, onSuccess, onError);
  }} size={Size.SMALL} />;
};
export default OrgSettingToggle;