import OrgInfo from './OrgInfo/OrgInfo';
import { OrgUserManagement } from './OrgUserManagement';
const OrgInfoAndUsers: React.FC = () => <div css={{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1rem"
}} data-testid="org-info-users-container">
    <OrgInfo />
    <OrgUserManagement />
  </div>;
export default OrgInfoAndUsers;