import { WorkflowType } from '@kindo/universal';

/**
 * For the initial creation of a workflow, url slug includes
 * the workflow type to customize the initial builder view
 * E.g. /agents/builder/triggered-agent -> /agents/builder/triggered-agent/<workflowId>
 *      /agents/builder/chatbot -> /agents/builder/chatbot/<workflowId>
 *      /agents/builder/agent -> /agents/builder/agent/<workflowId>
 *
 * After initial creation, the slug is just the workflowId
 * and workflowType is determined by the workflow object
 * ex: /agents/builder/<workflowId>
 */
export const WORKFLOW_TYPE_TO_BUILDER_SLUG: Record<WorkflowType, string | null> = {
  [WorkflowType.CHATBOT]: 'chatbot',
  [WorkflowType.TRIGGERED]: 'triggered-agent',
  [WorkflowType.MANUAL]: 'agent'
};