import React from 'react';
import { Size } from '../../constants';
import { sortIconStyles } from './SortIcon.styles';
interface SortIconProps {
  isActive?: boolean;
  isDesc?: boolean;
  size?: Size | number | undefined;
}

/**
 * SortIcon component that renders sort arrows with appropriate coloring
 */
const SortIcon: React.FC<SortIconProps> = ({
  size = Size.LARGE,
  isActive = false,
  isDesc = false
}) => {
  // Handle sizing with tailwind-variants
  const iconClasses = sortIconStyles({
    size: typeof size === 'number' ? undefined : size
  });

  // Handle custom numeric sizing
  const sizeStyle = typeof size === 'number' ? {
    width: `${size}px`,
    height: `${size}px`
  } : undefined;

  // Get the up and down arrow classes based on active state
  const upArrowClass = isActive && !isDesc ? 'text-highlight' : 'text-primary-dull';
  const downArrowClass = isActive && isDesc ? 'text-highlight' : 'text-primary-dull';
  return <span className={iconClasses} id="sort-icon-container" style={sizeStyle}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path className={upArrowClass} d="M12 7L9 4L6 7h6z" fill="currentColor" />
        <path className={downArrowClass} d="M6 11h6L9 14 6 11z" fill="currentColor" />
      </svg>
    </span>;
};
export default SortIcon;