import { Icon, Icons, Size, TextIconColor } from '..';
import { cn } from '../../../utils/tailwind.utils';
import { CalloutType } from './Callout.consts';

// Map each callout type to its corresponding Tailwind color
const CALLOUT_COLOR_MAP: Record<CalloutType, string> = {
  [CalloutType.SECURITY]: 'primary-active',
  [CalloutType.ERROR]: 'fuchsia-error',
  [CalloutType.INFO]: 'gray',
  [CalloutType.WARNING]: 'yellow-warning'
};
interface CalloutProps {
  children: React.ReactNode;
  type: CalloutType;
  endElement?: React.ReactNode;
}
const getCalloutIcon = (type: CalloutType) => {
  switch (type) {
    case CalloutType.ERROR:
      return Icon.ERROR;
    case CalloutType.INFO:
      return Icon.INFO;
    case CalloutType.WARNING:
      return Icon.WARNING;
    case CalloutType.SECURITY:
      return Icon.LOCK;
    default:
      return Icon.INFO;
  }
};
const Callout: React.FC<CalloutProps> = ({
  type,
  children,
  endElement
}) => {
  const borderAndBackgroundColor = CALLOUT_COLOR_MAP[type];
  return <div className={cn('border-1 box-border flex flex-row items-stretch rounded-lg', `border-${borderAndBackgroundColor}`)} id="callout-container">
      <div className={cn('flex items-center justify-center rounded-l px-[14px]', `bg-${borderAndBackgroundColor}`)} id="callout-icon-container">
        <Icons color={type === CalloutType.WARNING ? TextIconColor.BLACK : TextIconColor.PRIMARY} icon={getCalloutIcon(type)} size={Size.LARGE} />
      </div>
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingRight": "0.5rem"
    }} id="callout-content-container">
        <div css={{
        "flex": "1 1 0%",
        "paddingLeft": "0.625rem",
        "paddingRight": "0.625rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem",
        ">span": {
          "--tw-text-opacity": "1",
          "color": "rgb(255 255 255 / var(--tw-text-opacity))"
        }
      }} id="callout-content">
          {children}
        </div>
        {endElement}
      </div>
    </div>;
};
export default Callout;