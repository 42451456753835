import { getIntegrationDisplayName, IntegrationCategory, isWorkflowSupportedIntegration, OrgPaymentTier, WORKFLOW_SUPPORTED_INTEGRATIONS, WorkflowType } from '@kindo/universal';
import WorkflowViewLayout from '../shared/WorkflowViewLayout/WorkflowViewLayout';
import { TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize } from '~/components/core';
import { AvailableIntegrationWithDisplayName, ConnectedIntegrationWithDisplayName, isAvailableIntegration, isConnectedIntegration } from '~/components/SettingsModal/IntegrationSettings/IntegrationSettings.types';
import { IntegrationsTable } from '~/components/SettingsModal/IntegrationSettings/IntegrationsTable';
import { baseWorkflowCardContainerStyles, darkGlowWorkflowCardContainerStyles, defaultWorkflowCardContainerStyles } from '~/components/WorkflowNavScreen';
import { useAppSelector, useIntegrationConnect } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { cn } from '~/utils/tailwind.utils';
interface WorkflowIntegrationSelectProps {
  onIntegrationSelect: (integrationId: string) => void;
}
const WorkflowIntegrationSelect = ({
  onIntegrationSelect
}: WorkflowIntegrationSelectProps) => {
  const {
    data: connectedIntegrations,
    refetch: refetchConnectedIntegrations
  } = nextTrpc.integrations.listConnectedIntegrations.useQuery();
  const {
    connect: connectIntegration
  } = useIntegrationConnect();
  const {
    org
  } = useAppSelector(state => state.user);
  const isFreeTier = org?.paymentTier === OrgPaymentTier.FREE;
  const handleIntegrationInputSelect = (integration: AvailableIntegrationWithDisplayName | ConnectedIntegrationWithDisplayName) => {
    if (!isConnectedIntegration(integration)) {
      console.error('Expected connected integration');
      return;
    }
    onIntegrationSelect(integration.id);
  };
  const availableWorkflowIntegrations: AvailableIntegrationWithDisplayName[] = WORKFLOW_SUPPORTED_INTEGRATIONS.filter(integration => !connectedIntegrations?.find(input => input.integrationName === integration)).map(integration => ({
    integrationName: integration,
    integrationCategory: IntegrationCategory.TICKETING,
    enabled: true,
    displayName: getIntegrationDisplayName(integration)
  }));
  const connectedWorkflowIntegrations = connectedIntegrations?.filter(({
    integrationName
  }) => isWorkflowSupportedIntegration(integrationName)).map(integration => ({
    ...integration,
    displayName: getIntegrationDisplayName(integration.integrationName)
  }));
  return <WorkflowViewLayout workflowType={WorkflowType.TRIGGERED}>
      <div css={{
      "display": "flex",
      "width": "100%",
      "flexDirection": "column",
      "gap": "1.5rem"
    }} data-testid="integration-connection-view-container">
        <div className={cn(baseWorkflowCardContainerStyles, darkGlowWorkflowCardContainerStyles())} data-testid="integration-dark-glow-container">
          <div css={{
          "display": "flex",
          "width": "100%",
          "flexDirection": "column",
          "gap": "0.5rem"
        }} data-testid="trigger-builder-container">
            <Typography font={TypographyFont.HEADING} size={TypographySize.LARGE}>
              Begin by selecting an integration
            </Typography>
            <Typography color={TextIconColor.SECONDARY} font={TypographyFont.PARAGRAPH} size={TypographySize.SMALL}>
              Triggers require a connected integration to function
            </Typography>
            <div css={{
            "display": "flex",
            "width": "100%",
            "flexDirection": "column",
            "gap": "0.25rem",
            "paddingTop": "1.5rem"
          }} data-testid="connected-integrations-container">
              <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.INTERACTIVE} size={TypographySize.X_SMALL}>
                Active integrations
              </Typography>
              <IntegrationsTable integrations={connectedWorkflowIntegrations ?? []} onAction={handleIntegrationInputSelect} workflowBuilderPage />
            </div>
          </div>
        </div>
        <div className={cn(baseWorkflowCardContainerStyles, defaultWorkflowCardContainerStyles)} data-testid="integration-default-container">
          <div css={{
          "display": "flex",
          "width": "100%",
          "flexDirection": "column",
          "gap": "0.25rem",
          "paddingTop": "0.5rem"
        }} data-testid="available-integrations-container">
            <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.INTERACTIVE} size={TypographySize.X_SMALL}>
              Available integrations
            </Typography>
            <IntegrationsTable disableConnectButton={isFreeTier} integrations={availableWorkflowIntegrations} onAction={async integration => {
            if (!isAvailableIntegration(integration)) {
              console.error('Expected available integration');
              return;
            }
            await connectIntegration(integration);
            await refetchConnectedIntegrations();
          }} workflowBuilderPage />
          </div>
        </div>
      </div>
    </WorkflowViewLayout>;
};
export default WorkflowIntegrationSelect;