import { ModelWithMetadata } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useGetAllAvailableOrgModels = (): {
  allAvailableOrgModels: ModelWithMetadata[];
  modelIdentifierToDisplayName: Record<string, string>;
} => {
  const { data: models } =
    nextTrpc.models.listAllAvailableOrgModelsAndMetadata.useQuery();

  const allAvailableOrgModels = models?.items ?? [];
  const modelIdentifierToDisplayName = allAvailableOrgModels.reduce<
    Record<string, string>
  >((acc, model) => {
    acc[model.identifier] = model.displayName;
    return acc;
  }, {});

  return {
    allAvailableOrgModels,
    modelIdentifierToDisplayName
  };
};

export default useGetAllAvailableOrgModels;
