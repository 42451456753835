import { WorkflowTriggerOperator } from '@kindo/universal';
import React, { useEffect } from 'react';
import { getTriggerExplanation } from '../WorkflowTrigger.utils';
import { WorkflowTriggerConditionsList } from './WorkflowTriggerConditions';
import WorkflowTriggerConditionOperatorSelect from './WorkflowTriggerConditions/WorkflowTriggerConditionComponents/WorkflowTriggerConditionOperatorSelect';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size, TextIconColor, Typography, TypographyAlignment } from '~/components/core';
import { useAppDispatch, useAppSelector, useGetWorkflowFromUrlSlug, useOnComponentUnmount } from '~/hooks';
import { triggerActions } from '~/redux/reducers/workflowBuilderTriggerSlice';
import { BuilderWorkflowStatus, WorkflowTrigger, WorkflowTriggerBuilder } from '~/types';
interface WorkflowTriggerEditorProps {
  activeTrigger: WorkflowTriggerBuilder;
  isSavingTrigger: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSaveTrigger: () => Promise<void>;
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflowId: string;
  trigger?: WorkflowTrigger | undefined;
}
const WorkflowTriggerEditor: React.FC<WorkflowTriggerEditorProps> = ({
  isSavingTrigger,
  trigger,
  activeTrigger,
  onSaveTrigger,
  onDelete,
  onClose,
  refetchWorkflow,
  workflowId
}) => {
  // Redux
  const dispatch = useAppDispatch();

  // Set the existing trigger active
  useEffect(() => {
    if (trigger) {
      dispatch(triggerActions.setExistingTriggerActive(trigger));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger?.id]);
  const {
    activeTriggerCondition
  } = useAppSelector(state => state.workflowBuilderTrigger);
  const isActiveConditionValid = !activeTriggerCondition || activeTriggerCondition.value.trim().length > 0 && activeTriggerCondition.field !== undefined && activeTriggerCondition.type !== undefined;
  const handleDelete = () => {
    if (activeTrigger.status === BuilderWorkflowStatus.NEW) {
      dispatch(triggerActions.clearTrigger());
      return;
    }
    onDelete();
  };
  const onCancel = () => {
    dispatch(triggerActions.clearTrigger());
  };
  const handleUpdateOperator = (operator: WorkflowTriggerOperator) => {
    dispatch(triggerActions.updateTriggerOperator(operator));
  };
  const isTriggerStatusSaved = activeTrigger.status === BuilderWorkflowStatus.SAVED;
  const isSaveDisabled = isTriggerStatusSaved || !activeTrigger.integrationId || !isActiveConditionValid;
  useOnComponentUnmount(() => {
    dispatch(triggerActions.clearActiveTriggerCondition());
  }, [activeTrigger]);
  return <div css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "1rem",
    "borderTopWidth": "1px",
    "borderColor": "rgb(255 255 255 / 0.1)",
    "paddingTop": "1rem"
  }} data-testid="editor-container">
      <Typography alignment={TypographyAlignment.CENTER} color={TextIconColor.SECONDARY} italic>
        {getTriggerExplanation(activeTrigger)}
      </Typography>
      {activeTrigger?.conditions?.length > 1 && <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "gap": "0.5rem"
    }} data-testid="integration-and-operator-container">
          <WorkflowTriggerConditionOperatorSelect operator={activeTrigger.operator} updateOperator={handleUpdateOperator} />
        </div>}
      <WorkflowTriggerConditionsList activeTrigger={activeTrigger} refetchWorkflow={refetchWorkflow} workflowId={workflowId} />
      <div css={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "space-between",
      "gap": "0.5rem"
    }} data-testid="action-buttons">
        <IconButton color={TextIconColor.PRIMARY} data-data-testid="workflow_trigger_delete" icon={Icon.TRASH} onClick={handleDelete} size={Size.MEDIUM} type={IconButtonType.OUTLINED} />
        <div css={{
        "display": "flex",
        "flexDirection": "row",
        "alignItems": "center",
        "gap": "0.5rem"
      }} data-testid="save-and-cancel-container">
          <Button label={isTriggerStatusSaved ? 'Close' : 'Cancel'} onClick={() => {
          onCancel();
          onClose();
        }} type={ButtonType.TEXT} />
          <Button disabled={isSaveDisabled} label="Save Trigger" loading={isSavingTrigger} onClick={onSaveTrigger} tooltip={!activeTrigger.integrationId ? 'Select an integration to save' : undefined} type={ButtonType.SOLID_COLOR} />
        </div>
      </div>
    </div>;
};
export default WorkflowTriggerEditor;