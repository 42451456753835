import { useRouter } from 'next/router';
import { useState } from 'react';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useAppDispatch, useToast } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import { modalActions } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
import { checkForApiSecretsInWorkflow, checkIntegrationRequirements, hasStaticContent } from '~/utils/workflow.utils';
interface DuplicateWorkflowModalProps {
  workflowId: string;
  workflowTitle: string;
}
const DuplicateWorkflowModal: React.FC<DuplicateWorkflowModalProps> = ({
  workflowId,
  workflowTitle
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    enqueueToast
  } = useToast();
  const {
    user
  } = useUserInfo();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const {
    data: connectedIntegrations,
    isLoading: isLoadingIntegrations
  } = nextTrpc.integrations.listConnectedIntegrations.useQuery();
  const {
    data: workflowData
  } = nextTrpc.workflows.get.useQuery({
    workflowId
  }, {
    // Only fetch the workflow data once when the modal opens
    refetchOnWindowFocus: false
  });
  const workflow = workflowData?.item;
  const duplicateWorkflowMutation = nextTrpc.workflows.duplicate.useMutation({
    onSuccess: data => {
      enqueueToast({
        message: 'Agent duplicated successfully',
        type: ToastType.SUCCESS
      });
      dispatch(modalActions.closeModal());

      // Navigate to the duplicated workflow
      void router.push({
        pathname: `/agents/builder/${data.item.id}`,
        query: {
          fromDuplicate: 'true'
        }
      });
    },
    onError: error => {
      enqueueToast({
        message: `Failed to duplicate agent: ${error.message}`,
        type: ToastType.ERROR
      });
      setIsDuplicating(false);
    },
    onSettled: () => {
      setIsDuplicating(false);
    }
  });
  const hasRequiredIntegrations = checkIntegrationRequirements(workflow, connectedIntegrations, isLoadingIntegrations);
  const handleCancel = () => {
    dispatch(modalActions.closeModal());
  };
  const handleConfirm = () => {
    if (!hasRequiredIntegrations) {
      enqueueToast({
        message: "You don't have all the integrations required by this agent",
        type: ToastType.ERROR
      });
      return;
    }
    setIsDuplicating(true);
    duplicateWorkflowMutation.mutate({
      workflowId
    });
  };
  const isNotCreator = workflow && user && workflow.creator?.id !== user.id;
  const workflowHasStaticContent = workflow ? hasStaticContent(workflow) : false;
  const getDuplicationConfirmationMessage = (): string => {
    if (!workflow) return 'Are you sure you want to duplicate this agent?';
    const hasSecrets = checkForApiSecretsInWorkflow(workflow);
    const staticContentWarning = isNotCreator && workflowHasStaticContent ? '• Attached files will not be duplicated. You will need to re-upload and attach them to the duplicated agent.\n' : '';

    // Show secret warning only if the user is not the creator and the workflow has secrets
    const secretWarning = isNotCreator && hasSecrets ? '• Secret values will be replaced with placeholders that need to be reconfigured\n' : '';
    return `Are you sure you want to duplicate this agent?
${isNotCreator && hasSecrets || isNotCreator && workflowHasStaticContent ? 'Please note that you will need to reconfigure the following in the duplicated agent:\n\n' + secretWarning + staticContentWarning : ''}`;
  };
  return <ConfirmationModal confirmButtonLabel="Duplicate" confirmLoading={isDuplicating} header={`Duplicate agent: ${workflowTitle}`} onCancel={handleCancel} onConfirm={handleConfirm} open subtext={getDuplicationConfirmationMessage()} />;
};
export default DuplicateWorkflowModal;