import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { tv, type VariantProps } from 'tailwind-variants';

/**
 * Make specific variant props required
 * Adapted from: https://www.tailwind-variants.org/docs/typescript
 */
export type RequiredVariantProps<
  T extends ReturnType<typeof tv>,
  K extends keyof VariantProps<T>
> = Omit<VariantProps<T>, K> & Required<Pick<VariantProps<T>, K>>;

/**
 * Utility for combining class names with Tailwind CSS
 * Uses clsx for conditionally combining classes and tailwind-merge to handle conflicting Tailwind classes
 *
 * @param inputs - Class values to be combined (strings, objects, arrays)
 * @returns A single, merged className string with conflicts resolved
 *
 * @example
 * // Basic usage
 * <div className={cn('text-red-500', 'bg-blue-500')}>
 *
 * @example
 * // With conditional classes
 * <div className={cn('text-red-500', isActive && 'bg-blue-500')}>
 *
 * @example
 * // Resolving conflicts (the p-4 will override p-2)
 * <div className={cn('p-2', condition && 'p-4')}>
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
