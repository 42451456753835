import { tv } from 'tailwind-variants';
import { createEnumVariants } from '../../../utils/commonTailwindVariants.utils';
import { Size } from '../constants/size.consts';
import { IconButtonType } from './IconButton.consts';
export type IconButtonSize = Extract<Size, Size.SMALL | Size.MEDIUM | Size.LARGE>;
export const iconButtonStyles = tv({
  base: 'flex items-center justify-center whitespace-nowrap rounded-md',
  variants: {
    buttonType: createEnumVariants<typeof IconButtonType>({
      [IconButtonType.NONE]: 'border-none bg-transparent',
      [IconButtonType.OUTLINED]: 'border border-white/20 bg-transparent enabled:hover:border-white/50',
      [IconButtonType.OUTLINED_COLOR]: 'border-primary-bright enabled:hover:border-primary-bright-hover border bg-transparent enabled:hover:shadow-[0px_-1px_46.1px_0px_#3C00FF]',
      [IconButtonType.SOLID]: 'bg-white/10 enabled:hover:bg-white/20',
      [IconButtonType.SOLID_COLOR]: 'bg-primary-bright enabled:hover:bg-primary-bright-hover enabled:hover:shadow-[0px_4px_45px_0px_#3C00FF] disabled:bg-white/10',
      [IconButtonType.SOLID_GRADIENT]: 'from-solid-gradient-start to-solid-gradient-end bg-gradient-to-r enabled:hover:opacity-90 enabled:hover:shadow-[0px_4px_45px_0px_#5900DE] disabled:bg-white/10',
      [IconButtonType.SIMPLE]: 'border-none bg-transparent pl-0 enabled:hover:opacity-50 disabled:border-none'
    }),
    size: createEnumVariants<typeof Size, IconButtonSize>({
      [Size.SMALL]: 'h-7 min-h-7 w-7 min-w-7',
      [Size.MEDIUM]: 'h-8 min-h-8 w-8 min-w-8',
      [Size.LARGE]: 'h-10 min-h-10 w-10 min-w-10'
    }),
    disabled: {
      true: 'cursor-not-allowed opacity-80 disabled:cursor-not-allowed disabled:border-dashed disabled:border-white/20'
    },
    hidden: {
      true: 'pointer-events-none opacity-0',
      false: 'pointer-events-auto'
    },
    growOnHover: {
      true: '[&:hover_svg]:(scale-125 transition) [&:hover_svg]:(text-white) duration-300 ease-out enabled:hover:opacity-100'
    },
    highlightOnHover: {
      true: '[&:hover_svg]:text-highlight'
    }
  },
  compoundVariants: [{
    highlightOnHover: true,
    disabled: true,
    class: '[&:hover_svg]:text-current'
  }, {
    disabled: false,
    class: 'hover:border-blue-navy'
  }]
});