import {
  ChatListAccessScopeFilter,
  ChatListWorkflowRunTypeFilter
} from '@kindo/universal';

export const UNTITLED_CHAT_NAME = 'New Chat';

export const CHAT_WORKFLOW_RUN_TYPE_TO_LABEL: Record<
  ChatListWorkflowRunTypeFilter,
  string
> = {
  [ChatListWorkflowRunTypeFilter.ALL]: 'All',
  [ChatListWorkflowRunTypeFilter.CHATBOT]: 'Chatbot',
  [ChatListWorkflowRunTypeFilter.MANUAL]: 'Workflow',
  [ChatListWorkflowRunTypeFilter.TRIGGERED]: 'Trigger'
};

export const CHAT_ACCESS_SCOPE_FILTER_TO_LABEL: Record<
  ChatListAccessScopeFilter,
  string
> = {
  [ChatListAccessScopeFilter.ANY_SCOPE]: 'All',
  [ChatListAccessScopeFilter.CREATOR_SCOPE]: 'Created by me',
  [ChatListAccessScopeFilter.SHARED_SCOPE]: 'Shared with me'
};
