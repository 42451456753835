import { GENERAL_PURPOSE_LLMS, ModelWithMetadata } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useGetEnabledModels = ({
  onChange,
  chatModelIdentifier
}: {
  chatModelIdentifier?: string;
  onChange?: (modelIdentifier: string) => void;
} = {}): {
  enabledModels: ModelWithMetadata[];
  modelIdentifierToDisplayName: Record<string, string>;
} => {
  const { data: modelData } =
    nextTrpc.adminSecurityConfig.listOrgEnabledModels.useQuery(undefined, {
      onSuccess: (data) => {
        const modelIdentifiers = data.map((model) => model.identifier);
        const enabledGeneralPurposeModels = GENERAL_PURPOSE_LLMS.filter(
          (model) => modelIdentifiers.includes(model)
        );

        if (
          onChange &&
          chatModelIdentifier &&
          !modelIdentifiers.includes(chatModelIdentifier)
        ) {
          onChange(enabledGeneralPurposeModels[0] || '');
        }
      }
    });

  const enabledModels = modelData ?? [];

  const modelIdentifierToDisplayName = enabledModels.reduce<
    Record<string, string>
  >((acc, model: ModelWithMetadata) => {
    acc[model.identifier] = model.displayName;
    return acc;
  }, {});

  return {
    enabledModels,
    modelIdentifierToDisplayName
  };
};

export default useGetEnabledModels;
