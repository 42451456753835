import _styled from "styled-components";
import { ContentIcon } from '../ContentIcon';
import { TextIconColor, Icon, Icons, Size, ToolTip, Typography, TypographySize } from '~/components/core';
import { withLink } from '~/components/core/hocs';
import { TypographyWrap } from '~/components/core/Typography';
import { getContentDirectFetchUrl } from '~/constants';
import { ContentSource } from '~/types';
import { TailwindMaxWidthClass } from '~/utils/tailwind.types';
const ContentReferenceContainer = _styled.div<{
  $isClickable: boolean;
  $showRemoveIcon: boolean;
}>`
  ${({
  $isClickable,
  $showRemoveIcon
}) => [{
  "display": "flex",
  "cursor": "default",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "1rem",
  "borderRadius": "0.25rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}, $showRemoveIcon ? {
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem"
} : {
  "paddingLeft": "0.75rem",
  "paddingRight": "1rem"
}, $isClickable && {
  "backgroundColor": "rgb(255 255 255 / 0.1)",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "&:hover": {
    "backgroundColor": "rgb(255 255 255 / 0.2)"
  }
}]}
`;
const StartIconAndTextContainer = _styled.div({
  "display": "flex",
  "gap": "0.25rem"
});
const CloseIconContainer = _styled.div({
  "display": "flex",
  "borderRadius": "0.125rem",
  "backgroundColor": "rgb(255 255 255 / 0.3)"
});
interface ContentReferenceProps {
  contentId: string;
  fileName: string;
  source: ContentSource;
  canDownload?: boolean;
  nameMaxWidth?: TailwindMaxWidthClass;
  onClick?: () => void;
  showRemoveIcon?: boolean;
  tooltip?: string | undefined;
  url?: string;
}
const ContentReference = ({
  contentId,
  fileName,
  source,
  canDownload = false,
  url,
  nameMaxWidth,
  onClick,
  showRemoveIcon,
  tooltip
}: ContentReferenceProps) => <ToolTip content={tooltip}>
    {withLink(
  // TODO: Dedupe with Button, need a way to handle non-clickable ones though
  <ContentReferenceContainer $isClickable={!!onClick || canDownload || !!url} $showRemoveIcon={!!showRemoveIcon} onClick={onClick}>
        <StartIconAndTextContainer>
          <ContentIcon contentSource={source} size={Size.MEDIUM} />
          {/* Not all file names are encoded, but decode just in case */}
          <Typography color={TextIconColor.PRIMARY} maxWidth={nameMaxWidth} size={TypographySize.X_SMALL} wrap={nameMaxWidth ? TypographyWrap.NO_WRAP : TypographyWrap.NORMAL}>
            {decodeURIComponent(fileName)}
          </Typography>
        </StartIconAndTextContainer>
        {showRemoveIcon && <CloseIconContainer>
            <Icons color={TextIconColor.PRIMARY} icon={Icon.CLOSE} size={Size.MEDIUM} />
          </CloseIconContainer>}
      </ContentReferenceContainer>, {
    href: canDownload ? getContentDirectFetchUrl({
      contentId,
      isDownload: true,
      isPlaintext: false
    }) : url,
    disabled: !canDownload && !url,
    newTab: true
  })}
  </ToolTip>;
export default ContentReference;