import _styled from "styled-components";
import MUISlider from '@mui/material/Slider';
import React from 'react';
import { TextIconColor } from '../constants';
import Typography from '../Typography/Typography';
import { TypographyFont, TypographySize, TypographyWeight } from '../Typography/Typography.consts';
const SliderContainer = _styled.div({
  "display": "flex",
  "minWidth": "4rem",
  "flexDirection": "column"
});
const LabelContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const Label = ({
  children
}: {
  children: React.ReactNode;
}) => <Typography color={TextIconColor.CLEAR_MEDIUM} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
    {children}
  </Typography>;
const ValueLabelComponent = ({
  value
}: {
  value: number;
}) => <Typography color={TextIconColor.BLACK} font={TypographyFont.INTERACTIVE} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
    {value}
  </Typography>;
export interface SliderProps {
  onChange: (value: number) => void;
  value: number;
  leftLabel?: string;
  max?: number;
  min?: number;
  rightLabel?: string;
  step?: number;
}
export const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  leftLabel,
  rightLabel,
  min = 0,
  max = 100,
  step = 1
}) => {
  // MUI Slider provides number[] type for range mode, but we only use single value mode
  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onChange(newValue);
    }
  };
  const formatLabel = (v: number) => <ValueLabelComponent value={v} />;
  return <SliderContainer>
      <div css={{
      "position": "relative"
    }}>
        <MUISlider max={max} min={min} onChange={handleChange} step={step} sx={{
        height: 10,
        '& .MuiSlider-rail': {
          backgroundColor: '#FFFFFF'
        },
        '& .MuiSlider-track': {
          background: 'linear-gradient(90deg, #050035 0%, #2000C8 100%)',
          border: 'none'
        },
        '& .MuiSlider-thumb': {
          width: 22,
          height: 22,
          backgroundColor: '#FFFFFF',
          borderRadius: '3px',
          '&:before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
          },
          '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none'
          },
          '&.Mui-active': {
            cursor: 'grabbing'
          }
        },
        '& .MuiSlider-valueLabel': {
          backgroundColor: 'transparent',
          padding: 0,
          transform: 'translate(0%, 0) scale(1) !important',
          top: '0%'
        }
      }} value={value} valueLabelDisplay="on" valueLabelFormat={formatLabel} />
      </div>
      {(leftLabel || rightLabel) && <LabelContainer>
          <Label>{leftLabel}</Label>
          <Label>{rightLabel}</Label>
        </LabelContainer>}
    </SliderContainer>;
};
export default Slider;