import { Size, TextIconColor } from '../constants';
import { Icon, Icons } from '../Icon';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { ToggleSize, ToggleState } from './Toggle.consts';
import { toggleStyles } from './Toggle.styles';
interface ToggleProps {
  checked: boolean;
  onToggle: (isChecked: boolean) => void;
  disabled?: boolean;
  loading?: boolean;
  size?: ToggleSize;
}
const Toggle: React.FC<ToggleProps> = ({
  checked,
  disabled = false,
  loading = false,
  size = Size.MEDIUM,
  onToggle
}) => {
  // Apply styles using tailwind-variants
  const {
    root,
    display,
    innerBox,
    loadingSpinner
  } = toggleStyles({
    checked,
    disabled,
    loading,
    size
  });
  return <label className={root()} id="toggle-container">
      <input checked={checked} css={{
      "position": "absolute",
      "height": "0px",
      "width": "0px",
      "opacity": "0"
    }} disabled={disabled} id="toggle-input" onChange={e => !disabled && !loading && onToggle(e.target.checked)} type="checkbox" />
      <div aria-checked={checked} className={display()} id="toggle-display" role="switch">
        <div className={innerBox()} id="toggle-inner-box">
          <Typography color={checked ? TextIconColor.BLACK : TextIconColor.PRIMARY} size={TypographySize.XX_SMALL} weight={TypographyWeight.MEDIUM}>
            {checked ? ToggleState.ACTIVE : ToggleState.OFF}
          </Typography>
        </div>
        {loading && <div className={loadingSpinner()} id="toggle-loading-spinner">
            <Icons color={TextIconColor.SECONDARY} icon={Icon.LOADING} size={Size.SMALL} />
          </div>}
      </div>
    </label>;
};
export default Toggle;