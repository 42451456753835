/**
 * IMPORTANT: This maps to the LLM enum in LlamaIndexer,
 * if you update this then you need to update that as well
 *
 * Anthropic SDK https://github.com/anthropics/anthropic-sdk-typescript/blob/f639ebd1ef07f97c222022bdf10da0f888e3805f/src/resources/completions.ts#L66-L76
 * OpenAI SDK https://github.com/openai/openai-node/blob/45fad1ba633214b89f2c35fd01867e30b90985a4/src/resources/chat/completions.ts#L313
 */
export enum Llm {
  CLAUDE_1 = 'CLAUDE_1',
  CLAUDE_2 = 'CLAUDE_2',
  CLAUDE_3_5_HAIKU = 'CLAUDE_3_5_HAIKU',
  CLAUDE_3_5_SONNET = 'CLAUDE_3_5_SONNET',
  CLAUDE_3_7_SONNET = 'CLAUDE_3_7_SONNET',
  CLAUDE_3_HAIKU = 'CLAUDE_3_HAIKU',
  CLAUDE_3_OPUS = 'CLAUDE_3_OPUS',
  CLAUDE_3_SONNET = 'CLAUDE_3_SONNET',
  COMMAND_R = 'COMMAND_R',
  DEEPSEEK_CODER_33B_INSTRUCT = 'DEEPSEEK_CODER_33B_INSTRUCT',
  DEEPSEEK_R1 = 'DEEPSEEK_R1',
  GEMINI_1_5_FLASH = 'GEMINI_1_5_FLASH',
  GEMINI_1_5_PRO = 'GEMINI_1_5_PRO',
  GEMINI_1_5_PRO_PREVIEW = 'GEMINI_1_5_PRO_PREVIEW',
  GEMINI_2_0_FLASH = 'GEMINI_2_0_FLASH',
  GEMINI_2_0_FLASH_EXP = 'GEMINI_2_0_FLASH_EXP',
  GEMMA_2_9B_IT = 'GEMMA_2_9B_IT',
  GPT_3_5_TURBO = 'GPT_3_5_TURBO',
  GPT_4 = 'GPT_4',
  GPT_4O = 'GPT_4O',
  GPT_4O_MINI = 'GPT_4O_MINI',
  GPT_4_TURBO = 'GPT_4_TURBO',
  GRANITE_13B_CHAT_V2 = 'GRANITE_13B_CHAT_V2',
  GRANITE_3_8B_INSTRUCT = 'GRANITE_3_8B_INSTRUCT',
  // Start: Abstract models corresponding to model_list defined in litellm/models/internal.yaml
  INTERNAL_AUTO_GENERATION = 'INTERNAL_AUTO_GENERATION',
  INTERNAL_INGESTION_EXTRACTION = 'INTERNAL_INGESTION_EXTRACTION',
  INTERNAL_LARGE_WORKER = 'INTERNAL_LARGE_WORKER',
  INTERNAL_SMALL_WORKER = 'INTERNAL_SMALL_WORKER',
  // End: Internal LLMs
  LLAMA_3_1_70B = 'LLAMA_3_1_70B',
  // NOTE: LLAMA_3_1_70B_INTERNAL is (effectively) the same model as LLAMA_3_1_70B, but with a
  // different provider. **Do not** follow this convention for future models - this is an
  // exceptional case as `LLAMA_3_1_70B_INTERNAL` is an internal Kindo hosted model. We need to
  // come up with a better convention for handling same model but different provider case.
  LLAMA_3_1_70B_INTERNAL = 'LLAMA_3_1_70B_INTERNAL',
  LLAMA_3_1_8B = 'LLAMA_3_1_8B',
  LLAMA_3_1_8B_INSTANT = 'LLAMA_3_1_8B_INSTANT',
  LLAMA_3_2_3B = 'LLAMA_3_2_3B',
  LLAMA_3_3_70B = 'LLAMA_3_3_70B',
  LLAMA_3_70B = 'LLAMA_3_70B',
  MIXTRAL_GROQ = 'MIXTRAL_GROQ',
  O1 = 'O1',
  O1_MINI = 'O1_MINI',
  O1_PREVIEW = 'O1_PREVIEW',
  O3_MINI = 'O3_MINI',
  QWEN2_5_72B_INSTRUCT_TURBO = 'QWEN2_5_72B_INSTRUCT_TURBO',
  QWEN2_72B_INSTRUCT = 'QWEN2_72B_INSTRUCT',
  SAUL_INSTRUCT_V1 = 'SAUL_INSTRUCT_V1',
  WHITERABBITNEO_2_5_QWEN_2_5_32B = 'WHITERABBITNEO_2_5_QWEN_2_5_32B',
  WHITERABBITNEO_33B = 'WHITERABBITNEO_33B',
  WHITERABBITNEO_R1_32B = 'WHITERABBITNEO_R1_32B'
}

export const STREAMING_UNSUPPORTED_MODELS = [
  Llm.O1,
  Llm.O1_PREVIEW
] as const satisfies readonly Llm[];

// DO NOT REMOVE COMMENT: Llm
export function isStreamingSupported(llm: string): boolean {
  if (isLlm(llm)) {
    return !(STREAMING_UNSUPPORTED_MODELS as readonly Llm[]).includes(llm);
  }
  // If a customer adds a self-managed model that does not support streaming,
  // all that will happen is that the end user will just have to wait for the response.
  return true;
}

export function isLlm(value: string | null): value is Llm {
  return Object.values(Llm).includes(value as Llm);
}

/**
 * LLMs that we do not support for new usage, but cannot remove from the Enum
 * because they are still used in the database.
 *
 * IMPORTANT: When a new LLM is no longer supported and added here, workflow steps should be
 * manually updated to no longer use the deprecated LLM.
 */
export const PREVIOUSLY_SUPPORTED_LLMS = [
  Llm.CLAUDE_1,
  Llm.CLAUDE_2,
  Llm.GEMINI_1_5_PRO_PREVIEW,
  Llm.GEMINI_2_0_FLASH_EXP,
  Llm.GPT_4,
  Llm.DEEPSEEK_CODER_33B_INSTRUCT,
  Llm.LLAMA_3_1_70B,
  Llm.LLAMA_3_2_3B,
  Llm.MIXTRAL_GROQ,
  Llm.O1_PREVIEW
] as const satisfies readonly Llm[];

/**
 * Internal LLMs are models that are hosted by Kindo or are performing an internal task,
 * and used for internal tasks like title generation, argentic behavior, etc.
 * Internal LLMs are included in SUPPORTED_LLMS.
 *
 * To disable use of an internal LLM, it should be removed from this array
 * and added to PREVIOUSLY_SUPPORTED_LLMS.
 *
 * Internal Llm's should all have their provider listed as Kindo.
 *
 * IMPORTANT: Since internal LLMs can be used for internal generation/worker tasks,
 * they are not able to have their access disabled or DLP filters applied
 * (although we could let admins remove them as a chat option).
 * For sales reason, we are not including WhiteRabbitNeo in this list now,
 * since we promote the usage of it with DLP.
 *
 * We will need to solve for this case, and decide from the product perspective
 * how to handle security controls with internally hosted LLMs.
 */
export const INTERNAL_LLMS = [
  Llm.LLAMA_3_1_8B,
  Llm.LLAMA_3_1_70B_INTERNAL,
  // Models defined in litellm/models/internal.yaml
  Llm.INTERNAL_AUTO_GENERATION,
  Llm.INTERNAL_INGESTION_EXTRACTION,
  Llm.INTERNAL_LARGE_WORKER,
  Llm.INTERNAL_SMALL_WORKER
] as const satisfies readonly Llm[];

export type InternalLlm = (typeof INTERNAL_LLMS)[number];

export function isInternalLlm(value: string): value is InternalLlm {
  return (
    isLlm(value) && Object.values(INTERNAL_LLMS).includes(value as InternalLlm)
  );
}

/**
 * Supported LLMs are LLMs that are supported for usage through
 * the Kindo platform. This includes external (from another provider)
 * and internal (Kindo-hosted) LLMs.
 */
export const SUPPORTED_LLMS: Exclude<
  Llm,
  (typeof PREVIOUSLY_SUPPORTED_LLMS)[number]
>[] = Object.values(Llm).filter(isSupportedLlm);

export type SupportedLlm = (typeof SUPPORTED_LLMS)[number];

export function isSupportedLlm(llm: string): llm is SupportedLlm {
  return isLlm(llm) && !PREVIOUSLY_SUPPORTED_LLMS.includes(llm as any);
}

/**
 * Llms that are hosted by an external providers.
 */
export type ExternalLlm = Exclude<SupportedLlm, InternalLlm>;

export const EXTERNAL_LLMS: ExternalLlm[] = SUPPORTED_LLMS.filter(
  (llm: SupportedLlm) => !INTERNAL_LLMS.includes(llm as InternalLlm)
) as ExternalLlm[];

export function isExternalLlm(llm: string): llm is ExternalLlm {
  return isLlm(llm) && EXTERNAL_LLMS.includes(llm as ExternalLlm);
}

export const LLM_DISPLAY_NAMES: Record<Llm, string> = {
  [Llm.CLAUDE_1]: 'Claude 1',
  [Llm.CLAUDE_2]: 'Claude 2',
  [Llm.CLAUDE_3_5_SONNET]: 'Claude 3.5 Sonnet',
  [Llm.CLAUDE_3_HAIKU]: 'Claude 3 Haiku',
  [Llm.CLAUDE_3_OPUS]: 'Claude 3 Opus',
  [Llm.CLAUDE_3_5_HAIKU]: 'Claude 3.5 Haiku',
  [Llm.CLAUDE_3_SONNET]: 'Claude 3 Sonnet',
  [Llm.CLAUDE_3_7_SONNET]: 'Claude 3.7 Sonnet',
  [Llm.COMMAND_R]: 'Command R',
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: 'DeepSeek Coder 33B',
  [Llm.DEEPSEEK_R1]: 'DeepSeek-R1',
  [Llm.GEMINI_1_5_FLASH]: 'Gemini 1.5 Flash',
  [Llm.GEMINI_1_5_PRO]: 'Gemini 1.5 Pro',
  [Llm.GEMINI_1_5_PRO_PREVIEW]: 'Gemini 1.5 Pro (Preview)',
  [Llm.GEMINI_2_0_FLASH]: 'Gemini 2.0 Flash',
  [Llm.GEMINI_2_0_FLASH_EXP]: 'Gemini 2.0 Flash Experimental',
  [Llm.GEMMA_2_9B_IT]: 'Gemma 2 9B IT',
  [Llm.GPT_3_5_TURBO]: 'GPT-3.5 Turbo',
  [Llm.GPT_4]: 'GPT-4 32k',
  [Llm.GPT_4O]: 'GPT-4o',
  [Llm.GPT_4O_MINI]: 'GPT-4o mini',
  [Llm.GPT_4_TURBO]: 'GPT-4 Turbo',
  [Llm.GRANITE_13B_CHAT_V2]: 'Granite 13B Chat v2',
  [Llm.GRANITE_3_8B_INSTRUCT]: 'Granite 3.0 8B Instruct',
  /** Start internal LLMs */
  [Llm.INTERNAL_AUTO_GENERATION]: 'Auto Generation LLM',
  [Llm.INTERNAL_INGESTION_EXTRACTION]: 'Ingestion Extraction LLM',
  [Llm.INTERNAL_LARGE_WORKER]: 'Large Worker LLM',
  [Llm.INTERNAL_SMALL_WORKER]: 'Small Worker LLM',
  /** End internal LLMs */
  [Llm.LLAMA_3_1_70B]: 'Llama 3.1 70B',
  [Llm.LLAMA_3_1_70B_INTERNAL]: 'Llama 3.1 70B Internal',
  [Llm.LLAMA_3_1_8B]: 'Llama 3.1 8B Internal',
  [Llm.LLAMA_3_1_8B_INSTANT]: 'Llama 3.1 8B Instant',
  [Llm.LLAMA_3_2_3B]: 'Llama 3.2 3B',
  [Llm.LLAMA_3_3_70B]: 'Llama 3.3 70B',
  [Llm.LLAMA_3_70B]: 'Llama 3 70B',
  [Llm.MIXTRAL_GROQ]: 'Mixtral',
  [Llm.O1]: 'o1',
  [Llm.O1_MINI]: 'o1-mini',
  [Llm.O1_PREVIEW]: 'o1-preview',
  [Llm.O3_MINI]: 'o3-mini',
  [Llm.QWEN2_72B_INSTRUCT]: 'Qwen 2 72B Instruct',
  [Llm.QWEN2_5_72B_INSTRUCT_TURBO]: 'Qwen 2.5 72B',
  [Llm.SAUL_INSTRUCT_V1]: 'Saul Instruct V1',
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: 'WhiteRabbitNeo 2.5 32B (Beta)',
  [Llm.WHITERABBITNEO_33B]: 'WhiteRabbitNeo 33B v1.7',
  [Llm.WHITERABBITNEO_R1_32B]: 'WhiteRabbitNeo R1 32B'
};
