import { OrgPaymentTier } from '@kindo/universal';
import { Button, ButtonType } from '~/components/core';
// import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants/routes.consts';

interface SelectPaymentTierButtonProps {
  activeTier: OrgPaymentTier;
  onSelect: () => void;
  tier: OrgPaymentTier;
}
const SelectPaymentTierButton: React.FC<SelectPaymentTierButtonProps> = ({
  activeTier,
  onSelect,
  tier
}) => {
  const isActive = activeTier === tier;
  const getButtonLabel = () => {
    if (isActive) return 'Active';
    const contactSalesLabel = 'Contact Sales';
    switch (tier) {
      case OrgPaymentTier.FREE:
        return activeTier === OrgPaymentTier.ENTERPRISE ? contactSalesLabel : 'Downgrade';
      case OrgPaymentTier.PRO:
        return activeTier === OrgPaymentTier.ENTERPRISE ? contactSalesLabel : 'Upgrade';
      case OrgPaymentTier.ENTERPRISE:
      default:
        return contactSalesLabel;
    }
  };
  return <Button disabled={isActive} label={getButtonLabel()} onClick={onSelect} type={ButtonType.SOLID} />;
};
export default SelectPaymentTierButton;