import { isExternalLlm, LLM_DISPLAY_NAMES, LLM_TO_CREATOR, LLM_TO_PROVIDER, MODEL_CREATOR_DISPLAY_NAMES, ModelCreator, ModelWithMetadata, Provider, PROVIDER_DISPLAY_NAMES } from '@kindo/universal';
import { useState } from 'react';
import SettingsSection from '../../SettingsSection';
import ConfirmModelDeleteModal from './ConfirmModelDeleteModal';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Icons, Size, Table, TextIconColor, ToolTip, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { BadgeType } from '~/components/core/Badge/Badge.consts';
import { TooltipPlacement } from '~/components/core/ToolTip/ToolTip';
import MODELS, { COST_TIER_INDICATOR_TEXT, MODEL_COST_TIERS, PROVIDER_SECURITY_LEVELS, SECURITY_TIER_BADGE_STATE } from '~/components/SettingsModal/ModelSettings/ModelChoice/ModelChoice.consts';
import { useCanAccessSecuritySettings, useGetAllAvailableOrgModels } from '~/hooks';
const ModelChoice = ({
  onShowAddModel
}: {
  onShowAddModel: () => void;
}) => {
  // State
  const [modelToDelete, setModelToDelete] = useState<ModelWithMetadata | null>(null);

  // Custom Hooks
  const {
    userCanAccessSecuritySettings: userCanManageModels
  } = useCanAccessSecuritySettings();
  const {
    allAvailableOrgModels: models
  } = useGetAllAvailableOrgModels();
  const getModelInfo = (model: ModelWithMetadata) => {
    const modelIdentifier = model.identifier;
    const isGlobal = isExternalLlm(modelIdentifier);
    if (isGlobal) {
      return {
        modelInfo: MODELS[modelIdentifier],
        displayName: LLM_DISPLAY_NAMES[modelIdentifier],
        provider: LLM_TO_PROVIDER[modelIdentifier],
        creator: LLM_TO_CREATOR[modelIdentifier],
        costTier: MODEL_COST_TIERS[modelIdentifier],
        providerSecurityTier: PROVIDER_SECURITY_LEVELS[LLM_TO_PROVIDER[modelIdentifier]],
        isGlobal: true
      };
    }
    return {
      modelInfo: {
        tags: [],
        type: 'Text Generation',
        description: '',
        usageTag: 'Chat + Agents',
        link: ''
      },
      displayName: model.displayName,
      provider: Provider.SELF_MANAGED,
      creator: ModelCreator.SELF_MANAGED,
      isGlobal: false
    };
  };
  const handleDeleteButtonClick = (model: ModelWithMetadata) => {
    setModelToDelete(model);
  };
  const renderModels = (model: ModelWithMetadata) => {
    const {
      modelInfo,
      displayName,
      provider,
      creator,
      costTier,
      providerSecurityTier,
      isGlobal
    } = getModelInfo(model);

    // Only Admins can delete Self-Managed Models
    const shouldShowDeleteButton = userCanManageModels && !isGlobal;
    return {
      key: `${model.identifier}-info-row`,
      height: {
        "height": "8rem",
        "paddingTop": "1.25rem",
        "paddingBottom": "1.25rem",
        "verticalAlign": "top"
      },
      cells: [
      // Tags
      modelInfo.tags?.map(tag => tag === 'Popular' ? <div css={{
        "display": "flex",
        "flexDirection": "row",
        "paddingTop": "0.375rem"
      }} data-testid="hot-icon-container" key={`${model.identifier}-info-row-tag-${tag}`}>
              <Icons color={TextIconColor.SECONDARY_HIGHLIGHT} icon={Icon.HOT} size={Size.SMALL} />
            </div> : null),
      // Name, description, status tag
      <div css={{
        "display": "flex",
        "flexDirection": "column",
        "gap": "0.5rem"
      }} data-testid="name-description-container" key={`${model.identifier}-info-row-name-description`}>
          <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} href={modelInfo.link} size={TypographySize.SMALL}>
            {displayName}
          </Typography>
          <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
            {modelInfo.description}
          </Typography>
          {modelInfo.usageTag && <Typography color={TextIconColor.DISABLED} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.SEMI_BOLD}>
              {modelInfo.usageTag}
            </Typography>}
        </div>,
      // Type
      modelInfo.type ? <div css={{
        "display": "flex",
        "flexDirection": "column",
        "gap": "0.375rem"
      }} data-testid="model-types-container" key={`${model.identifier}-info-row-type`}>
            {modelInfo.type.split('+').map(type => <Badge key={`${type}`} label={type.trim()} />)}
          </div> : null,
      // Provider
      <Typography key={`${model.identifier}-info-row-provider`} size={TypographySize.SMALL}>
          {PROVIDER_DISPLAY_NAMES[provider]}
        </Typography>,
      // Creator
      <Typography key={`${model.identifier}-info-row-creator`} size={TypographySize.SMALL}>
          {MODEL_CREATOR_DISPLAY_NAMES[creator]}
        </Typography>,
      // Cost
      costTier ? <ToolTip content={`${costTier.charAt(0) + costTier.substring(1).toLowerCase()} cost`} key={`${model.identifier}-info-row-cost`} offset={[0, 8]} placement={TooltipPlacement.TOP}>
            <div css={{
          "display": "flex",
          "flexDirection": "row",
          "placeContent": "center",
          "alignContent": "center",
          "verticalAlign": "middle"
        }} data-testid="indicator-wrapper">
              <Typography size={TypographySize.SMALL}>
                {COST_TIER_INDICATOR_TEXT[costTier]}
              </Typography>
            </div>
          </ToolTip> : null,
      // Security
      providerSecurityTier ? <ToolTip content={`${providerSecurityTier.charAt(0) + providerSecurityTier.substring(1).toLowerCase()} security`} key={`${model.identifier}-info-row-security`} offset={[0, 8]} placement={TooltipPlacement.TOP}>
            <div css={{
          "display": "flex",
          "flexDirection": "row",
          "placeContent": "center",
          "alignContent": "center",
          "verticalAlign": "middle"
        }} data-testid="indicator-wrapper">
              <Badge color={SECURITY_TIER_BADGE_STATE[providerSecurityTier]} label={providerSecurityTier} type={BadgeType.OUTLINE} />
            </div>
          </ToolTip> : null, shouldShowDeleteButton ? <IconButton data-testid="delete-model-button" highlightOnHover icon={Icon.TRASH} key={`${model.identifier}-info-row-delete`} onClick={() => handleDeleteButtonClick(model)} size={Size.MEDIUM} type={IconButtonType.NONE} /> : null]
    };
  };
  return <SettingsSection data-testid="model-choice-section" description=" " headerEndElement={userCanManageModels && <Button data-testid="add-model-button" endIcon={Icon.PLUS} label="Add Model" onClick={onShowAddModel} size={Size.MEDIUM} type={ButtonType.SOLID_COLOR} typographySize={TypographySize.X_SMALL} />} title="Available Models">
      <Table columns={[{
      title: 'Hot'
    }, {
      title: 'Model'
    }, {
      title: 'Type'
    }, {
      title: 'Provider'
    }, {
      title: 'Creator'
    }, {
      title: 'Cost'
    }, {
      title: 'Security'
    }, ...(userCanManageModels ? [{
      title: ''
    }] : [])]} data-testid="models-table" noRowsText="No models currently available." rows={models.map(renderModels)} />
      {modelToDelete && <ConfirmModelDeleteModal modelDisplayName={modelToDelete.displayName} modelIdentifier={modelToDelete.identifier} onClose={() => setModelToDelete(null)} />}
    </SettingsSection>;
};
export default ModelChoice;