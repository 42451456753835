export enum TextIconColor {
  BLACK = 'BLACK',
  CLEAR_MEDIUM = 'CLEAR_MEDIUM',
  DESTRUCTIVE = 'DESTRUCTIVE',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  GRAY = 'GRAY',
  HIGHLIGHT = 'HIGHLIGHT',
  PRIMARY = 'PRIMARY',
  PRIMARY_HIGHLIGHT = 'PRIMARY_HIGHLIGHT',
  SECONDARY = 'SECONDARY',
  SECONDARY_HIGHLIGHT = 'SECONDARY_HIGHLIGHT',
  WARNING = 'WARNING',
}

/**
 * Twin.macro styles for text/icon colors
 * Use this with twin.macro styled components
 */
export const TEXT_COLORS: Record<TextIconColor, TwStyle> = {
  [TextIconColor.BLACK]: {
    "--tw-text-opacity": "1",
    "color": "rgb(22 25 37 / var(--tw-text-opacity))"
  },
  [TextIconColor.DESTRUCTIVE]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 51 108 / var(--tw-text-opacity))"
  },
  [TextIconColor.DISABLED]: {
    "color": "rgb(255 255 255 / 0.5)"
  },
  [TextIconColor.ERROR]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 11 80 / var(--tw-text-opacity))"
  },
  [TextIconColor.HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(84 254 209 / var(--tw-text-opacity))"
  },
  [TextIconColor.PRIMARY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  },
  [TextIconColor.PRIMARY_HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(141 118 255 / var(--tw-text-opacity))"
  },
  [TextIconColor.SECONDARY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(150 148 181 / var(--tw-text-opacity))"
  },
  [TextIconColor.SECONDARY_HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(120 116 254 / var(--tw-text-opacity))"
  },
  [TextIconColor.GRAY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(68 68 96 / var(--tw-text-opacity))"
  },
  [TextIconColor.WARNING]: {
    "--tw-text-opacity": "1",
    "color": "rgb(253 235 144 / var(--tw-text-opacity))"
  },
  [TextIconColor.CLEAR_MEDIUM]: {
    "color": "rgb(255 255 255 / 0.3)"
  }
};

/**
 * Plain Tailwind class strings for text/icon colors
 * Use this with tailwind-variants or anywhere needing string classes
 */
export const TEXT_COLOR_CLASSES: Record<TextIconColor, string> = {
  [TextIconColor.BLACK]: 'text-black-raisin',
  [TextIconColor.DESTRUCTIVE]: 'text-fuchsia-destructive',
  [TextIconColor.DISABLED]: 'text-white/50',
  [TextIconColor.ERROR]: 'text-fuchsia-error',
  [TextIconColor.HIGHLIGHT]: 'text-highlight',
  [TextIconColor.PRIMARY]: 'text-white',
  [TextIconColor.PRIMARY_HIGHLIGHT]: 'text-primary-highlight',
  [TextIconColor.SECONDARY]: 'text-primary-dull',
  [TextIconColor.SECONDARY_HIGHLIGHT]: 'text-markdown-periwinkle',
  [TextIconColor.GRAY]: 'text-gray',
  [TextIconColor.WARNING]: 'text-yellow-warning',
  [TextIconColor.CLEAR_MEDIUM]: 'text-white/30'
};