import {
  API_AUTHORIZATION_DISPLAY_NAME_MAP,
  SupportedHttpMethod
} from '@kindo/universal';

import { nextTrpc } from '../trpc';
import {
  BuilderWorkflowApiActionStep,
  BuilderWorkflowStep,
  isApiActionWorkflowBuilderStep
} from '../types';

import { useAppDispatch } from './typedReduxHooks';
import useToast, { ToastType } from './useToast';

import { Modal, modalActions } from '~/redux/reducers/modalSlice';

export interface ApiActionStepExecutionInfo {
  request: {
    method: SupportedHttpMethod;
    url: string;
    body?: Record<string, unknown>;
    headers?: Record<string, string>;
    parameters?: Record<string, string>;
  };
  response:
    | string
    | { error: { code: string; message: string; details?: string } };
}

const getHeaderTextOrSecretPlaceholder = (
  header: BuilderWorkflowApiActionStep['headers'][string]
) => {
  if ('text' in header) {
    return header.text;
  }
  return 'SECRET';
};

const formatRequestData = (
  step: BuilderWorkflowApiActionStep
): ApiActionStepExecutionInfo['request'] => {
  const headers = {
    ...Object.fromEntries(
      Object.entries(step.headers).map(([key, value]) => [
        key,
        getHeaderTextOrSecretPlaceholder(value)
      ])
    ),
    ...(step.auth && {
      Authorization: `${API_AUTHORIZATION_DISPLAY_NAME_MAP[step.auth.authorizationType]} ${getHeaderTextOrSecretPlaceholder(step.auth.value)}`
    })
  };

  return {
    method: step.method,
    url: step.endpoint,
    ...(step.parameters && Object.keys(step.parameters).length > 0
      ? { parameters: step.parameters }
      : {}),
    ...(Object.keys(headers).length > 0 ? { headers } : {}),
    ...(step.body ? { body: JSON.parse(step.body) } : {})
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatErrorData = (error: any) => ({
  error: {
    message: error.message,
    code: error.data?.code,
    ...(error.data?.kindoErrorMessage
      ? { details: error.data.kindoErrorMessage }
      : {})
  }
});

const useExecuteApiActionStep = () => {
  const { enqueueToast } = useToast();

  const executeApiActionStepMutation =
    nextTrpc.workflows.executeApiActionStep.useMutation();

  const dispatch = useAppDispatch();

  const openTestApiActionStepModal = () => {
    dispatch(
      modalActions.openModal({
        type: Modal.TEST_API_ACTION_STEP,
        executionInfo: null,
        isLoading: true
      })
    );
  };

  const populateModalWithExecutionInfo = (
    executionInfo: ApiActionStepExecutionInfo | null
  ) => {
    dispatch(
      modalActions.openModal({
        type: Modal.TEST_API_ACTION_STEP,
        executionInfo,
        isLoading: false
      })
    );
  };

  const executeApiActionStepTest = (step: BuilderWorkflowStep) => {
    if (!isApiActionWorkflowBuilderStep(step)) {
      enqueueToast({
        message: 'Cannot execute non-API action step',
        type: ToastType.ERROR
      });
      return;
    }

    // Open modal to display loading state
    openTestApiActionStepModal();

    executeApiActionStepMutation.mutate(
      { workflowStepId: step.id },
      {
        onSuccess: (data) => {
          populateModalWithExecutionInfo({
            request: formatRequestData(step),
            response: data.response
          });
        },
        onError: (error) => {
          populateModalWithExecutionInfo({
            request: formatRequestData(step),
            response: formatErrorData(error)
          });
          enqueueToast({
            message: 'Failed to execute API action',
            type: ToastType.ERROR
          });
        }
      }
    );
  };

  return {
    executeApiActionStepTest
  };
};

export default useExecuteApiActionStep;
