import _styled from "styled-components";
import { AuditEventType, AuditEventTypeGroupFilter, FeatureFlags, typedEntries } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { formatDistanceToNow } from 'date-fns';
import router, { NextRouter } from 'next/router';
import { useState } from 'react';
import { AUDIT_LOG_EVENT_TYPE_DISPLAY_NAMES } from './AuditLog.consts';
import { AuditLogFilter, AuditLogFiltersValues } from './AuditLog.types';
import { getAuditEventDisplayMessage } from './AuditLog.utils';
import { AuditEventModal } from './components';
import { Button, ButtonType, DateTimeRange, Modal, ModalWidth, Table, TextField, TextFieldType, ToolTip, Typography, TypographySize, TypographyWeight } from '~/components/core';
import SettingsSection from '~/components/SettingsModal/SettingsSection';
import { FiltersProps, PaywallText } from '~/components/shared';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { AUDIT_LOG_EVENT_ID_QUERY_PARAM } from '~/constants';
import { ToastType, useCanAccessSecuritySettings, useToast, useURLParams } from '~/hooks';
import { nextTrpc } from '~/trpc';
const AuditLogContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column"
});
const LoadMoreButtonContainer = _styled.div({
  "marginBottom": "2rem",
  "marginTop": "1rem",
  "display": "flex",
  "justifyContent": "center"
});
const DownloadButtonsContainer = _styled.div({
  "display": "flex",
  "gap": "1rem"
});

// Map feature flags to their associated event types
const FEATURE_FLAG_AUDIT_EVENTS: Record<FeatureFlags.CODE_SANDBOX | FeatureFlags.USER_GROUPS, AuditEventType[]> = {
  [FeatureFlags.CODE_SANDBOX]: [AuditEventType.CODE_EXECUTED],
  [FeatureFlags.USER_GROUPS]: [AuditEventType.USER_GROUP_CREATED, AuditEventType.USER_GROUP_DELETED, AuditEventType.USER_GROUP_UPDATED, AuditEventType.USER_GROUP_USER_ADDED, AuditEventType.USER_GROUP_USER_REMOVED, AuditEventType.CHAT_SHARED_TO_USER_GROUP, AuditEventType.CHAT_UNSHARED_FROM_USER_GROUP, AuditEventType.WORKFLOW_SHARED_TO_USER_GROUP, AuditEventType.WORKFLOW_UNSHARED_FROM_USER_GROUP]
};
type FeatureFlagsWithAuditEvents = keyof typeof FEATURE_FLAG_AUDIT_EVENTS;
function isValidAuditEventTypeOrGroupFilter(value: any): value is AuditEventType | AuditEventTypeGroupFilter {
  return Object.values(AuditEventType).includes(value) || Object.values(AuditEventTypeGroupFilter).includes(value);
}
const AuditLog: React.FC = () => {
  const [activeTableFilters, setActiveTableFilters] = useState<AuditLogFiltersValues>({
    [AuditLogFilter.EVENT_TYPE]: AuditEventTypeGroupFilter.USER_INITIATED,
    [AuditLogFilter.USER_ID]: undefined
  });
  const [dateRangeFilter, setDateRangeFilter] = useState<DateTimeRange>({});
  const [showDownloadEventsModal, setShowDownloadEventsModal] = useState<boolean>(false);
  const [customDelimiter, setCustomDelimiter] = useState<string>('');
  // URL Params
  const params = useURLParams<{
    [AUDIT_LOG_EVENT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [AUDIT_LOG_EVENT_ID_QUERY_PARAM]: urlAuditEventId
  } = params;

  //  Redux
  const {
    orgCanAccessSecuritySettings
  } = useCanAccessSecuritySettings();

  // Feature flags
  const isCodeSandboxEnabled = useFlag(FeatureFlags.CODE_SANDBOX);
  const isUserGroupsEnabled = useFlag(FeatureFlags.USER_GROUPS);

  // Helper function to filter events based on feature flags
  const filterFeatureFlaggedEventTypes = (eventType: AuditEventType): boolean => {
    // Map of each feature flag to its enabled state
    const featureFlagEnabledStates: Record<FeatureFlagsWithAuditEvents, boolean> = {
      [FeatureFlags.CODE_SANDBOX]: isCodeSandboxEnabled,
      [FeatureFlags.USER_GROUPS]: isUserGroupsEnabled
    };

    // Return false if the event type is associated with any disabled feature flags
    return typedEntries(FEATURE_FLAG_AUDIT_EVENTS).every(([flag, eventTypes]) => {
      const isEnabled = featureFlagEnabledStates[flag];
      return isEnabled ? true : !eventTypes.includes(eventType);
    });
  };

  // Audit Event Query
  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch
  } = nextTrpc.auditLog.list.useInfiniteQuery({
    filters: {
      eventType: isValidAuditEventTypeOrGroupFilter(activeTableFilters.EVENT_TYPE) ? activeTableFilters.EVENT_TYPE : undefined,
      userId: activeTableFilters.USER_ID,
      startDate: dateRangeFilter.startDate?.toISOString(),
      endDate: dateRangeFilter.endDate?.toISOString()
    }
  }, {
    getNextPageParam: lastPage => lastPage.nextCursor
  });
  const {
    data: users
  } = nextTrpc.orgs.listUsers.useQuery();
  const auditEvents = data?.pages.flatMap(page => page.items) ?? [];
  const hasAuditEvents = auditEvents.length > 0;
  const {
    enqueueToast
  } = useToast();

  // Mutation
  const downloadAllEvents = nextTrpc.auditLog.requestAuditEventCSV.useMutation({
    onSuccess: () => {
      enqueueToast({
        type: ToastType.SUCCESS,
        message: 'Audit log download request was sent successfully. You will receive an email with a download link once the CSV is ready.'
      });
    },
    onError: e => {
      enqueueToast({
        type: ToastType.ERROR,
        message: e.data?.kindoErrorMessage || 'Failed to download all audit events. Please try again and contact support if it continues.'
      });
    }
  });
  const filterProps: FiltersProps<AuditLogFilter> = {
    filters: [{
      defaultLabel: 'All users',
      filterKey: AuditLogFilter.USER_ID,
      options: (users?.items || []).sort((a, b) => a.name.localeCompare(b.name)).map(user => ({
        label: user.name ?? user.email ?? '',
        value: user.id
      })),
      placeholder: ''
    }, {
      defaultLabel: 'All event types',
      filterKey: AuditLogFilter.EVENT_TYPE,
      options: [{
        label: 'User-initiated events only',
        value: AuditEventTypeGroupFilter.USER_INITIATED
      }, ...Object.values(AuditEventType).filter(filterFeatureFlaggedEventTypes).map(eventType => ({
        label: AUDIT_LOG_EVENT_TYPE_DISPLAY_NAMES[eventType],
        value: eventType
      })).sort((a, b) => a.label.localeCompare(b.label))],
      placeholder: ''
    }],
    filtersValues: activeTableFilters,
    searchValue: '',
    setFilters: filters => {
      setActiveTableFilters(filters);
      refetch();
    }
  };
  const updateUrlWithAuditEventId = (auditEventId: string | undefined) => {
    const routerWithAuditEventId: NextRouter = {
      ...router,
      query: {
        ...router.query,
        [AUDIT_LOG_EVENT_ID_QUERY_PARAM]: auditEventId
      }
    };
    void router.push(routerWithAuditEventId, undefined, {
      shallow: true
    });
  };
  const handleFilterUpdate = <K extends AuditLogFilter,>(filterKey: K, value: AuditLogFiltersValues[K]) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setActiveTableFilters(prev => ({
      ...prev,
      [filterKey]: value
    }));
  };
  const handleSetUserFilter = (userId: string) => {
    setActiveTableFilters(prev => ({
      ...prev,
      [AuditLogFilter.USER_ID]: userId
    }));
    updateUrlWithAuditEventId(undefined);
  };
  const renderColumn = ({
    auditEventId,
    filterKey,
    value,
    displayValue
  }: {
    auditEventId: string;
    displayValue: string;
    filterKey: AuditLogFilter;
    value: string | undefined;
  }) => {
    const currentFilterValue = activeTableFilters[filterKey];
    if (currentFilterValue === value) {
      return displayValue;
    }
    return <Button key={auditEventId} label={displayValue} onClick={handleFilterUpdate(filterKey, value)} type={ButtonType.TEXT} />;
  };
  const hasDateRangeFilter = !!dateRangeFilter.startDate || !!dateRangeFilter.endDate;
  const hasFilters = hasDateRangeFilter || Object.values(activeTableFilters).some(value => value);
  return <AuditLogContainer>
      {!orgCanAccessSecuritySettings && <PaywallText subtitle="To view audit logs, please contact sales." title="This feature is limited for Free and Pro users." />}
      <SettingsSection description="View event from your team's users here." headerEndElement={<DownloadButtonsContainer>
            <Button disabled={!orgCanAccessSecuritySettings || !hasAuditEvents} label={`Download ${hasFilters ? 'Filtered' : 'All'} Events`} onClick={() => {
        setShowDownloadEventsModal(true);
      }} type={ButtonType.SOLID} />
          </DownloadButtonsContainer>} title="Audit Log">
        {/* TODO: Show a different view for free tier users */}
        <Table clearFilterProps={{
        hasFilters,
        label: 'Clear all filters',
        onClick: () => {
          setActiveTableFilters({
            [AuditLogFilter.EVENT_TYPE]: undefined,
            [AuditLogFilter.USER_ID]: undefined
          });
          setDateRangeFilter({});
        }
      }} columns={[{
        title: 'Event'
      }, {
        title: 'User'
      }, {
        title: 'Event Type'
      }, {
        title: 'Time'
      }]} dateRangePickerProps={{
        onDateRangeChange: (newRange: DateTimeRange) => {
          setDateRangeFilter(newRange);
          refetch();
        },
        value: dateRangeFilter
      }} filterProps={filterProps} loading={isLoading} noRowsText="No audit log events found." rows={auditEvents.map(auditEvent => ({
        key: auditEvent.id,
        onClick: () => {
          updateUrlWithAuditEventId(auditEvent.id);
        },
        cells: [getAuditEventDisplayMessage({
          type: auditEvent.type,
          userMetadata: auditEvent.userMetadata ?? {}
        }), auditEvent.userMetadata ? renderColumn({
          auditEventId: auditEvent.id,
          filterKey: AuditLogFilter.USER_ID,
          value: auditEvent.userMetadata.userId,
          displayValue: auditEvent.userMetadata.name ?? ''
        }) : null, renderColumn({
          auditEventId: auditEvent.id,
          filterKey: AuditLogFilter.EVENT_TYPE,
          value: auditEvent.type,
          displayValue: AUDIT_LOG_EVENT_TYPE_DISPLAY_NAMES[auditEvent.type]
        }), <ToolTip content={new Date(auditEvent.initiatedAt).toLocaleString()} key={auditEvent.id}>
                <div>
                  <Typography size={TypographySize.SMALL} weight={TypographyWeight.LIGHT}>
                    {formatDistanceToNow(new Date(auditEvent.initiatedAt), {
                addSuffix: true
              })}
                  </Typography>
                </div>
              </ToolTip>]
      }))} />
        {hasNextPage && <LoadMoreButtonContainer>
            <Button disabled={isFetchingNextPage} label="Load more" loading={isFetchingNextPage} onClick={() => fetchNextPage()} type={ButtonType.OUTLINED} />
          </LoadMoreButtonContainer>}
      </SettingsSection>
      {urlAuditEventId && <Modal onClose={() => updateUrlWithAuditEventId(undefined)} open={!!urlAuditEventId} width={ModalWidth['3XL']}>
          <AuditEventModal auditEventId={urlAuditEventId} handleSetUserFilter={handleSetUserFilter} />
        </Modal>}
      {showDownloadEventsModal && <ConfirmationModal confirmButtonLabel="Send me the download link" confirmLoading={downloadAllEvents.isLoading} header={hasFilters ? 'Download filtered audit events' : 'Download all audit events'} onCancel={() => setShowDownloadEventsModal(false)} onConfirm={() => {
      downloadAllEvents.mutate({
        customDelimiter: customDelimiter || undefined,
        filters: hasFilters ? {
          eventType: isValidAuditEventTypeOrGroupFilter(activeTableFilters.EVENT_TYPE) ? activeTableFilters.EVENT_TYPE : undefined,
          userId: activeTableFilters.USER_ID,
          startDate: dateRangeFilter.startDate?.toISOString(),
          endDate: dateRangeFilter.endDate?.toISOString()
        } : undefined
      });
      setShowDownloadEventsModal(false);
    }} open subtext={`This will download ${hasFilters ? 'filtered' : 'all'} audit events for your organization. You will receive an email with a download link once the CSV is ready.`}>
          <TextField fullWidth maxRows={1} onChange={value => setCustomDelimiter(value)} placeholder='(Optional) Custom Delimiter [e.g. " *|* "]' type={TextFieldType.HIGHLIGHT} value={customDelimiter} />
        </ConfirmationModal>}
    </AuditLogContainer>;
};
export default AuditLog;