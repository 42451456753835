import { ExternalLlm, isExternalLlm, Llm, MODEL_TO_LITELLM_MODELS } from '@kindo/universal';
import SettingsSection from '../../SettingsSection';
import { Table, Typography, TypographySize } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { useGetAllAvailableOrgModels } from '~/hooks';
export enum ModelType {
  CHAT = 'CHAT',
  TAB_AUTOCOMPLETE = 'TAB_AUTOCOMPLETE',
}
const MODEL_TYPE_TO_DISPLAY_NAME = {
  [ModelType.CHAT]: 'Chat',
  [ModelType.TAB_AUTOCOMPLETE]: 'Tab Autocomplete'
} as const satisfies Record<ModelType, string>;
export const AVAILABLE_MODELS_AND_TYPES: Record<ExternalLlm, ModelType[]> = {
  [Llm.CLAUDE_3_HAIKU]: [ModelType.CHAT],
  [Llm.CLAUDE_3_OPUS]: [ModelType.CHAT],
  [Llm.CLAUDE_3_5_HAIKU]: [ModelType.CHAT],
  [Llm.CLAUDE_3_5_SONNET]: [ModelType.CHAT],
  [Llm.CLAUDE_3_7_SONNET]: [ModelType.CHAT],
  [Llm.CLAUDE_3_SONNET]: [ModelType.CHAT],
  [Llm.COMMAND_R]: [ModelType.CHAT],
  [Llm.DEEPSEEK_R1]: [ModelType.CHAT],
  [Llm.GEMINI_1_5_FLASH]: [ModelType.CHAT],
  [Llm.GEMINI_1_5_PRO]: [ModelType.CHAT],
  [Llm.GEMINI_2_0_FLASH]: [ModelType.CHAT],
  [Llm.GEMMA_2_9B_IT]: [ModelType.CHAT],
  [Llm.GPT_3_5_TURBO]: [ModelType.CHAT],
  [Llm.GPT_4O]: [ModelType.CHAT],
  [Llm.GPT_4_TURBO]: [ModelType.CHAT],
  [Llm.GPT_4O_MINI]: [ModelType.CHAT],
  [Llm.GRANITE_13B_CHAT_V2]: [ModelType.CHAT],
  [Llm.GRANITE_3_8B_INSTRUCT]: [ModelType.CHAT],
  [Llm.LLAMA_3_1_8B_INSTANT]: [ModelType.CHAT],
  [Llm.LLAMA_3_3_70B]: [ModelType.CHAT],
  [Llm.LLAMA_3_70B]: [ModelType.CHAT],
  [Llm.O1]: [ModelType.CHAT],
  [Llm.O1_MINI]: [ModelType.CHAT],
  [Llm.O3_MINI]: [ModelType.CHAT],
  [Llm.QWEN2_72B_INSTRUCT]: [ModelType.CHAT],
  [Llm.QWEN2_5_72B_INSTRUCT_TURBO]: [ModelType.CHAT],
  [Llm.SAUL_INSTRUCT_V1]: [ModelType.CHAT],
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: [ModelType.CHAT],
  [Llm.WHITERABBITNEO_33B]: [ModelType.CHAT, ModelType.TAB_AUTOCOMPLETE],
  [Llm.WHITERABBITNEO_R1_32B]: [ModelType.CHAT]
};
const AvailableModels = () => {
  const {
    allAvailableOrgModels: models
  } = useGetAllAvailableOrgModels();
  return <SettingsSection title="Available Models & Model Types">
      <div css={{
      "marginTop": "1.5rem",
      "marginBottom": "1.5rem",
      "display": "flex",
      "flexDirection": "column"
    }} data-testid="available-models-container">
        <Table columns={[{
        title: 'Name',
        width: {
          "width": "25%"
        }
      }, {
        title: 'API Model Name',
        width: {
          "width": "33.333333%"
        }
      }, {
        title: 'Config Type',
        width: {
          "width": "25%"
        }
      }]} noRowsText="No models currently available." rows={models.map(model => {
        const modelDisplayName = model.displayName;
        const modelIdentifier = model.identifier;
        const isGlobal = isExternalLlm(modelIdentifier);
        const modelType = isGlobal ? AVAILABLE_MODELS_AND_TYPES[modelIdentifier] : [ModelType.CHAT];
        return {
          key: `${modelIdentifier}-info-row`,
          cells: [
          // Model Title
          <div css={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "0.5rem"
          }} data-testid="model-name-container" key={`${modelIdentifier}-title`}>
                  <Typography size={TypographySize.SMALL}>
                    {modelDisplayName}
                  </Typography>
                </div>,
          // Model Name
          <Typography key={`${modelIdentifier}-name`} size={TypographySize.SMALL}>
                  {isGlobal ? MODEL_TO_LITELLM_MODELS[modelIdentifier] : modelIdentifier}
                </Typography>,
          // Configuration type
          <div css={{
            "display": "flex",
            "flexDirection": "row",
            "gap": "0.5rem"
          }} data-testid="model-tags-container" key={`${modelIdentifier}-configuration-type`}>
                  {modelType.map((type: ModelType) => <Badge key={`${type}-config-type`} label={MODEL_TYPE_TO_DISPLAY_NAME[type]} />)}
                </div>]
        };
      })} />
      </div>
    </SettingsSection>;
};
export default AvailableModels;