// IMPORTANT: This maps to the Tool enum in llama_indexer

import { FeatureFlags } from './featureFlags.consts';

// If you update here be sure to update the corresponding enum in llama_indexer
export enum Tool {
  LIBRARY_SEARCH = 'LIBRARY_SEARCH',
  URL_SCRAPE = 'URL_SCRAPE',
  WEB_SEARCH = 'WEB_SEARCH'
}

export function toTool(tool: string): Tool {
  if (Object.values(Tool).includes(tool as Tool)) {
    return tool as Tool;
  }
  throw new Error(`Invalid tool: ${tool}`);
}

export const CHAT_AGENT_TOOLS = [
  Tool.LIBRARY_SEARCH,
  Tool.URL_SCRAPE,
  Tool.WEB_SEARCH
];

export const TOOL_DISPLAY_NAMES: Record<Tool, string> = {
  [Tool.LIBRARY_SEARCH]: 'Library Search',
  [Tool.URL_SCRAPE]: 'URL Search',
  [Tool.WEB_SEARCH]: 'Web Search'
};

export const TOOL_DESCRIPTIONS: Record<Tool, string> = {
  [Tool.LIBRARY_SEARCH]:
    'Search files and integration data in your Kindo library',
  [Tool.URL_SCRAPE]: 'Search urls that are part of your message',
  [Tool.WEB_SEARCH]: 'Search the web'
};

// Tools that map to null indicate that there is no feature flag that controls the tool
export const TOOL_FEATURE_FLAGS: Record<Tool, FeatureFlags | null> = {
  [Tool.LIBRARY_SEARCH]: null,
  [Tool.URL_SCRAPE]: FeatureFlags.URL_SCRAPE_TOOL,
  [Tool.WEB_SEARCH]: FeatureFlags.WEB_SEARCH_TOOL
};
