import { useState } from 'react';
import { Button, ButtonType, Icon, IconButton, IconButtonType, Size } from '../../core';
import { TextIconColor } from '../../core/constants';
import Typography from '../../core/Typography/Typography';
import { TypographyFont, TypographySize, TypographyWeight } from '../../core/Typography/Typography.consts';
import { configurationHeaderStyles, contentContainerStyles } from './WorkflowBuilderConfiguration.styles';
import WorkflowBuilderConfigurationEditor from './WorkflowBuilderConfigurationEditor';
import { FileResource } from '~/components/Chat/AddFileModal';
import { ToastType, useAppSelector, useGetWorkflowFromUrlSlug, useKnowledgeStore, useToast, useWorkflowConfig, useWorkflowHydration } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
type WorkflowBuilderConfigurationFooterProps = {
  refetchWorkflow: ReturnType<typeof useGetWorkflowFromUrlSlug>['refetch'];
  workflow: Workflow;
};
const WorkflowBuilderConfiguration: React.FC<WorkflowBuilderConfigurationFooterProps> = ({
  refetchWorkflow,
  workflow
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    workflowConfig,
    knowledgeStore
  } = useAppSelector(state => state.workflowBuilder);
  const {
    enqueueToast
  } = useToast();
  const {
    hydrateWorkflowConfigAndKnowledgeStore
  } = useWorkflowHydration({
    workflow
  });
  const {
    canSaveWorkflowConfig,
    isSavingWorkflowConfig,
    saveWorkflowConfig,
    updateWorkflowConfigInstructions,
    updateWorkflowConfigDescription,
    updateWorkflowConfigName,
    markWorkflowConfigSaved
  } = useWorkflowConfig({
    workflowConfig,
    refetchWorkflow,
    workflowId: workflow.id
  });
  const {
    canSaveKnowledgeStore,
    isSavingKnowledgeStore,
    saveKnowledgeStoreContent,
    updateKnowledgeStoreContent
  } = useKnowledgeStore({
    knowledgeStore,
    refetchWorkflow,
    workflow
  });
  const nextTrpcUtils = nextTrpc.useUtils();
  const refetchAndSetUpdatedWorkflowData = async () => {
    const {
      data: updatedWorkflow
    } = await refetchWorkflow();
    void nextTrpcUtils.workflows.list.invalidate();
    const updatedSystemPrompt = updatedWorkflow?.item?.systemPrompt ?? undefined;
    if (updatedWorkflow) {
      updateWorkflowConfigName(updatedWorkflow.item.displayName ?? '');
      updateWorkflowConfigDescription(updatedWorkflow.item.description ?? '');
      updateWorkflowConfigInstructions(updatedSystemPrompt ?? '');
    }
    markWorkflowConfigSaved();
  };
  const workflowGeneratorMutation = nextTrpc.workflows.generateAndUpdateWorkflowFromUserDescription.useMutation({
    onSuccess: () => {
      void refetchAndSetUpdatedWorkflowData();
      enqueueToast({
        message: 'Agent generated successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: error => {
      console.error('Failed to generate agent', error);
      enqueueToast({
        message: 'Failed to generate agent',
        type: ToastType.ERROR
      });
    }
  });
  const handleDescriptionChange = (value: string) => {
    updateWorkflowConfigDescription(value);
  };
  const handleSystemPromptChange = (value: string) => {
    updateWorkflowConfigInstructions(value);
  };
  const handleKnowledgeStoreContentChange = (files: FileResource[]) => {
    updateKnowledgeStoreContent(files);
  };
  const handleAutoGenerate = (prompt: string) => {
    workflowGeneratorMutation.mutate({
      workflowGeneratorUserDescription: prompt,
      workflowId: workflow.id
    });
  };
  const handleSave = async () => {
    try {
      if (canSaveWorkflowConfig) {
        await saveWorkflowConfig();
      }
      if (canSaveKnowledgeStore) {
        await saveKnowledgeStoreContent();
      }
      enqueueToast({
        message: 'Configuration saved successfully.',
        type: ToastType.SUCCESS
      });
    } catch (error) {
      console.error('Failed to save configuration:', error);
      enqueueToast({
        message: 'Failed to save configuration.',
        type: ToastType.ERROR
      });
    }
  };
  const handleReset = () => {
    try {
      const success = hydrateWorkflowConfigAndKnowledgeStore();
      if (success) {
        enqueueToast({
          message: 'Configuration reset to last saved state.',
          type: ToastType.SUCCESS
        });
      }
    } catch (error) {
      console.error('Failed to reset configuration:', error);
      enqueueToast({
        message: 'Failed to reset configuration.',
        type: ToastType.ERROR
      });
    }
  };
  const canSave = canSaveWorkflowConfig || canSaveKnowledgeStore;
  const isSaving = isSavingWorkflowConfig || isSavingKnowledgeStore;
  return <div css={{
    "position": "sticky",
    "bottom": "0.5rem",
    "left": "0px",
    "right": "0px",
    "zIndex": "10",
    "width": "100%"
  }} data-testid="workflow-builder-config-container">
      <div className={configurationHeaderStyles({
      isExpanded
    })} data-testid="workflow-builder-configuration-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div css={{
        "display": "flex",
        "width": "100%",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "1rem",
        "paddingRight": "0.5rem"
      }} data-testid="workflow-builder-text-icon-container">
          <Typography color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
            AGENT CONFIGURATION
          </Typography>
          <div css={{
          "display": "flex",
          "alignItems": "center",
          "gap": "0.5rem"
        }} data-testid="workflow-builder-buttons-container">
            <Button disabled={!canSave} label="Reset" onClick={e => {
            e.stopPropagation();
            void handleReset();
          }} size={Size.SMALL} type={ButtonType.OUTLINED} />
            <Button disabled={!canSave} label="Save" loading={isSaving} onClick={e => {
            e.stopPropagation();
            void handleSave();
          }} size={Size.SMALL} type={canSave ? ButtonType.SOLID_COLOR : ButtonType.SOLID} />
            <IconButton color={TextIconColor.PRIMARY} icon={Icon.DIRECTION_DOWN} invert={!isExpanded} onClick={() => setIsExpanded(!isExpanded)} size={Size.LARGE} type={IconButtonType.SIMPLE} />
          </div>
        </div>
      </div>
      <div className={contentContainerStyles({
      isExpanded
    })} data-testid="workflow-builder-content-container">
        <WorkflowBuilderConfigurationEditor description={workflowConfig.description} isAutoGenerating={workflowGeneratorMutation.isLoading} knowledgeStoreContent={knowledgeStore.content} onAutoGenerate={handleAutoGenerate} onDescriptionChange={handleDescriptionChange} onKnowledgeStoreContentChange={handleKnowledgeStoreContentChange} onSystemPromptChange={handleSystemPromptChange} systemPrompt={workflowConfig.instructions} />
      </div>
    </div>;
};
export default WorkflowBuilderConfiguration;