import {
  ApiActionBody,
  ApiActionHeaders,
  BLOCKED_DOMAINS,
  isRequestBodySupportedHttpMethod,
  SupportedHttpMethod
} from '@kindo/universal';
import validator from 'validator';

import useToast, { ToastType } from '../useToast';

import { nextTrpc } from '~/trpc';
import { BuilderWorkflowApiActionStep, BuilderWorkflowStatus } from '~/types';

export interface ValidationResult {
  isValid: boolean;
  message?: string;
}

export interface UseActiveApiActionStepReturn {
  canSave: (step: BuilderWorkflowApiActionStep) => ValidationResult;
  isSaving: boolean;
  saveApiActionStep: (step: BuilderWorkflowApiActionStep) => Promise<void>;
}

const useActiveApiActionStep = (
  workflowId: string
): UseActiveApiActionStepReturn => {
  const { enqueueToast } = useToast();
  const addApiActionStepMutation =
    nextTrpc.workflows.addApiActionStep.useMutation();
  const editApiActionStepMutation =
    nextTrpc.workflows.editApiActionStep.useMutation();

  const isValidKeyValuePairs = (obj: Record<string, string>): boolean =>
    Object.entries(obj).every(([key, value]) => !!key.trim() && !!value.trim());

  const isValidHeaders = (headers: ApiActionHeaders): boolean =>
    Object.entries(headers).every(([key, value]) => {
      if (!key.trim()) return false;
      if ('text' in value) return !!value.text.trim();
      if ('secretId' in value) return !!value.secretId.trim();
      return false;
    });

  const isValidBody = (
    body: string | null,
    method: SupportedHttpMethod
  ): boolean => {
    // If method doesn't support body, validation always passes since we'll send null
    if (!isRequestBodySupportedHttpMethod(method)) {
      return true;
    }

    // For methods that support body, empty body is valid
    if (!body) return true;

    try {
      const parsed = JSON.parse(body);
      return typeof parsed === 'object' && parsed !== null;
    } catch {
      return false;
    }
  };

  const isValidEndpoint = (urlString: string): boolean => {
    try {
      const hasValidDomain = validator.isURL(urlString, {
        host_blacklist: BLOCKED_DOMAINS
      });
      return hasValidDomain;
    } catch {
      return false;
    }
  };

  const isValidProtocol = (urlString: string): boolean =>
    validator.isURL(urlString, {
      protocols: ['http', 'https'],
      require_protocol: false
    });

  const saveApiActionStep = async (step: BuilderWorkflowApiActionStep) => {
    const {
      endpoint,
      method,
      headers,
      parameters,
      body,
      stepNumber,
      status,
      auth
    } = step;

    if (!endpoint) {
      throw new Error('Endpoint is required');
    }

    // Ensure endpoint has protocol
    const finalEndpoint = endpoint.match(/^https?:\/\//)
      ? endpoint
      : `https://${endpoint}`;

    // Always send null body for methods that don't support it
    const sendNullBody = !body || !isRequestBodySupportedHttpMethod(method);

    // Parse body if present and method supports body
    let parsedBody: ApiActionBody | null = null;

    if (!sendNullBody) {
      try {
        const parsed = JSON.parse(body);
        if (typeof parsed !== 'object' || parsed === null) {
          throw new Error('Body must be a valid JSON object');
        }

        parsedBody = parsed;
      } catch (error) {
        console.error('Invalid JSON body:', error);
        throw new Error('Please provide a valid JSON object for the body.');
      }
    }

    const finalHeaders = {
      ...headers,
      ...(auth && {
        [auth.authorizationType]: auth.value
      })
    };

    // Handle empty parameters by setting to null
    const finalParameters =
      parameters && Object.keys(parameters).length > 0 ? parameters : null;

    try {
      if (status === BuilderWorkflowStatus.NEW) {
        await addApiActionStepMutation.mutateAsync({
          endpoint: finalEndpoint,
          method,
          headers: finalHeaders,
          parameters: finalParameters,
          body: sendNullBody ? null : parsedBody,
          stepNumber,
          workflowId
        });
      } else if (status === BuilderWorkflowStatus.MODIFIED) {
        await editApiActionStepMutation.mutateAsync({
          endpoint: finalEndpoint,
          method,
          headers: finalHeaders,
          parameters: finalParameters,
          body: sendNullBody ? null : parsedBody,
          id: step.id,
          stepNumber,
          workflowId
        });
      }
    } catch (error) {
      console.error('Failed to save API action step:', error);
      enqueueToast({
        message: 'Failed to save API action step. Please try again.',
        type: ToastType.ERROR
      });
      throw error;
    }
  };

  const canSave = (step: BuilderWorkflowApiActionStep): ValidationResult => {
    if (!step.endpoint) {
      return { isValid: false, message: 'URL cannot be empty' };
    }

    if (!isValidProtocol(step.endpoint)) {
      return {
        isValid: false,
        message: 'Invalid protocol - must be http or https'
      };
    }

    if (!isValidEndpoint(step.endpoint)) {
      return {
        isValid: false,
        message: 'Endpoint is invalid or includes a blocked domain'
      };
    }

    if (!step.method) {
      return { isValid: false, message: 'HTTP method must be selected' };
    }
    if (!isValidHeaders(step.headers)) {
      return {
        isValid: false,
        message: 'Invalid headers, keys and values must be non-empty'
      };
    }
    if (step.parameters && !isValidKeyValuePairs(step.parameters)) {
      return {
        isValid: false,
        message: 'Invalid parameters, keys and values must be non-empty'
      };
    }
    if (!isValidBody(step.body, step.method)) {
      return {
        isValid: false,
        message: 'Invalid body, must be a valid JSON object'
      };
    }
    return { isValid: true };
  };

  return {
    saveApiActionStep,
    canSave,
    isSaving:
      addApiActionStepMutation.isLoading || editApiActionStepMutation.isLoading
  };
};

export default useActiveApiActionStep;
