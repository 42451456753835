import { useRouter } from 'next/router';
import { ConfirmationModal } from '../../shared/ConfirmationModal';
import { useDeleteWorkflow } from '~/hooks';
interface DeleteWorkflowModalProps {
  onClose: () => void;
  redirectRoute: string | undefined;
  workflowId: string;
  workflowTitle: string;
}
const DeleteWorkflowModal: React.FC<DeleteWorkflowModalProps> = ({
  workflowId,
  workflowTitle,
  onClose,
  redirectRoute
}) => {
  const router = useRouter();
  const {
    deleteWorkflow,
    isDeleting
  } = useDeleteWorkflow(workflowId);
  const handleDeleteWorkflow = async () => {
    await deleteWorkflow();
    onClose();
    if (redirectRoute) {
      void router.push({
        pathname: redirectRoute
      });
    }
  };
  const handleConfirm = () => {
    void handleDeleteWorkflow();
  };
  return <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={isDeleting} header={`Are you sure you want to delete ${workflowTitle}?`} onCancel={onClose} onConfirm={handleConfirm} open subtext="A deleted agent cannot be recovered." />;
};
export default DeleteWorkflowModal;